import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import 'moment/locale/fr';
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const ClientWasteConv = (props) => {
    const { t } = useTranslation();
    const getMassConv = (analyzes) => {
        let mass = 0;
        let trays = 0;
        let min = 1000000000;
        let max = 0;
        for (const analysis of analyzes) {
            mass = parseFloat(mass) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
            trays = parseInt(trays) + parseInt(analysis.trays);

            let massConv = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseInt(analysis.trays);
            if (massConv < min) min = massConv;
            if (massConv > max) max = massConv;
        }
        return {
            massConv: trays > 0 ? mass / trays : 0,
            min: trays > 0 ? min : 0,
            max: trays > 0 ? max : 0
        };
    }
    const getValues = () => {
        let wasteConvRestaurant = 0;
        let wasteConvRestaurantMin = 0;
        let wasteConvRestaurantMax = 0;
        let wasteConvKitchen = 0;
        let wasteConvKitchenMin = 0;
        let wasteConvKitchenMax = 0;
        let wasteConvTotal = 0;


        let max = props.max;
        let dates = props.clientAnalyzesDates.find(a => parseInt(a.analysisNumber) === max);
        let selectedAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
            moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
            &&
            moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
        );
        let selectedAnalyzesKitchen = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
            moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
            &&
            moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
        );

        let restaurantWaste = getMassConv(selectedAnalyzes);
        wasteConvRestaurant = restaurantWaste.massConv && restaurantWaste.massConv !== "NaN" ? restaurantWaste.massConv : 0;
        wasteConvRestaurantMax = restaurantWaste.max;
        wasteConvRestaurantMin = restaurantWaste.min;

        let kitchenWaste = getMassConv(selectedAnalyzesKitchen);
        wasteConvKitchen = kitchenWaste.massConv;
        wasteConvKitchenMax = kitchenWaste.max;
        wasteConvKitchenMin = kitchenWaste.min;

        wasteConvTotal = wasteConvKitchen + wasteConvRestaurant;
        return {
            wasteConvRestaurant: t('mass', { value: { "mass": wasteConvRestaurant, "unit": "grammes", fixed: 0 } }),
            wasteConvRestaurantMin: t('mass', { value: { "mass": wasteConvRestaurantMin, "unit": "g", fixed: 0 } }),
            wasteConvRestaurantMax: t('mass', { value: { "mass": wasteConvRestaurantMax, "unit": "g", fixed: 0 } }),
            wasteConvKitchen: t('mass', { value: { "mass": wasteConvKitchen, "unit": "grammes", fixed: 0 } }),
            wasteConvKitchenMin: t('mass', { value: { "mass": wasteConvKitchenMin, "unit": "g", fixed: 0 } }),
            wasteConvKitchenMax: t('mass', { value: { "mass": wasteConvKitchenMax, "unit": "g", fixed: 0 } }),
            wasteConvTotal: t('mass', { value: { "mass": wasteConvTotal, "unit": "grammes", fixed: 0 } }),
        }
    }

    return (
        <CustomBlock width={'100%'} height={'100%'} paddingTop={"2%"} paddingBottom={"2%"} paddingLeft={"2%"} paddingRight={"2%"}>
            <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
                <div className="dashboard-block-title" style={{ height: "10%" }}>
                    {t('components.wasteState')}
                </div>
                <div style={{ width: "100%", height: "10%", display: 'flex', flexDirection: "row", alignItems: "center", color: "#666666" }}>
                    {t('components.averageWasteConv')}
                </div>
                <div style={{ height: "5%" }} />
                <div style={{ height: "75%", width: "80%", alignSelf: "center" }}>
                    <div style={{ width: "100%", height: "33%", display: "flex", flexDirection: "row" }}>
                        <div style={{ height: "80%", width: "20%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", color: "#666666" }}>
                            {t('components.restaurantWasteConv')}
                        </div>
                        <div style={{ width: "10%" }} />
                        <div style={{ height: "80%", width: "70%", border: "solid 3px #666666", borderRadius: "15px", paddingTop: "2%", color: "#666666", fontWeight: "bold" }}>
                            <div style={{ width: "100%", height: "30%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {getValues().wasteConvRestaurant}
                            </div>
                            <div style={{ width: "100%", height: "70%", display: "flex", alignItems: "center", justifyContent: "center", color: "#666666", fontWeight: "lighter" }}>
                                {t('components.minMaxWaste', { valueMin: getValues().wasteConvRestaurantMin, valueMax: getValues().wasteConvRestaurantMax })}
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "0.5%" }} />
                    <div style={{ width: "100%", height: "33%", display: "flex", flexDirection: "row" }}>
                        <div style={{ height: "80%", width: "20%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", color: "#666666" }}>
                            {t('components.kitchenWasteConv')}
                        </div>
                        <div style={{ width: "10%" }} />
                        <div style={{ height: "80%", width: "70%", border: "solid 3px #666666", borderRadius: "15px", paddingTop: "2%", color: "#666666", fontWeight: "bold" }}>
                            <div style={{ width: "100%", height: "30%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {getValues().wasteConvKitchen}
                            </div>
                            <div style={{ width: "100%", height: "70%", display: "flex", alignItems: "center", justifyContent: "center", color: "#666666", fontWeight: "lighter" }}>
                                {t('components.minMaxWaste', { valueMin: getValues().wasteConvKitchenMin, valueMax: getValues().wasteConvKitchenMax })}
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "0.5%" }} />
                    <div style={{ width: "100%", height: "33%", display: "flex", flexDirection: "row" }}>
                        <div style={{ height: "80%", width: "20%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", color: "#68B4E3" }}>
                            {t('components.totalWasteConv')}
                        </div>
                        <div style={{ width: "10%" }} />
                        <div style={{ height: "80%", width: "70%", border: "solid 3px #666666", borderColor: "#68B4E3", backgroundColor: "#68B4E399", borderRadius: "15px", color: "#fff", fontWeight: "bold" }}>
                            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {getValues().wasteConvTotal}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomBlock>
    )
}
export default ClientWasteConv;