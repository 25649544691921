import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomButton from "../../../customComponents/CustomButton";
import { setCurrentPageOnBoarding } from "../../../../actions_reducers/actions/files.action";
import CustomCheckbox from "../../../customComponents/CustomCheckbox";
import CustomTextField from "../../../customComponents/CustomTextField";
import { setAllowedRefill, setNumberVege, setPortionType, setVegeType, setWasteManagementMethodOnBoarding, setWhenRefill, setWhyNotRefill } from "../../../../actions_reducers/actions/onBoarding.action";
import React, { useState } from 'react';

const year = "2023-2024"

export const ThirdPage = (props) => {
    const [error_portions, seterror_portions] = useState(true);
    const [error_vege, seterror_vege] = useState(true);
    const [error_waste, seterror_waste] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const canBeModified = props.on_boarding_done;

    const hasAllPortions = () => {
        let onBoardingValues = props.onBoardingState;
        if (onBoardingValues.portionType === "")
            return false;
        else if (onBoardingValues.canHaveMore === "")
            return false;
        else if (onBoardingValues.canHaveMore === true && onBoardingValues.whenRefill === "")
            return false;
        else if (onBoardingValues.canHaveMore === false && onBoardingValues.whyNotRefill === "")
            return false;
        else return true;
    }

    const hasAllVege = () => {
        let onBoardingValues = props.onBoardingState;
        if (onBoardingValues.numberVege === "")
            return false;
        else if (onBoardingValues.vegeType === "")
            return false;
        else return true;
    }

    const hasAllWaste = () => {
        let onBoardingValues = props.onBoardingState;
        if (onBoardingValues.wasteManagementMethod === "")
            return false;
        else return true;
    }

    const nextPage = () => {
        if (hasAllPortions() === false)
            seterror_portions(false);
        else
            seterror_portions(true);

        if (hasAllVege() === false)
            seterror_vege(false);
        else
            seterror_vege(true);

        if (hasAllWaste() === false)
            seterror_waste(false);
        else
            seterror_waste(true);

        if (hasAllPortions() && hasAllVege() && hasAllWaste())
            dispatch(setCurrentPageOnBoarding(4))

    }

    const previousPage = () => {
        dispatch(setCurrentPageOnBoarding(2))
    }

    const getPortions = () => {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ height: "15%" }}></div>
                <div style={{ width: "100%", height: "15%", color: "#68B4E3", fontSize: "1.2vw", fontWeight: "bold" }}>
                    {t('onBoarding.portions')}
                </div>
                <div style={{ height: "4%" }}></div>
                <div style={{ width: "100%", height: "60%", color: "#666666", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", height: "100%" }}>
                        <div style={{ height: "30%", display: "flex", alignItems: "center", fontWeight: "bold" }}>{t('onBoarding.isPortion')}</div>
                        <div style={{ height: "30%", width: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "5.6%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"4vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setPortionType(t('onBoarding.designed')))}
                                    checked={props.onBoardingState.portionType === t('onBoarding.designed')} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", paddingTop: "0.4%" }}>
                                {t('onBoarding.designed')}
                            </div>
                        </div>
                        <div style={{ height: "7%" }}></div>

                        <div style={{ height: "35%", width: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "75%", lineHeight: "90%" }}>
                                <div style={{ width: "5.6%", height: "100%", display: "flex", alignItems: "center", paddingBottom: "2%" }}>
                                    <CustomCheckbox
                                        disabled={canBeModified}
                                        height={"2vh"}
                                        width={"4vh"}
                                        boxShadow={"none"}
                                        backgroundColor={"#D9D9D9"}
                                        onChange={(e) => dispatch(setPortionType(t('onBoarding.standardized')))}
                                        checked={props.onBoardingState.portionType === t('onBoarding.standardized')} />
                                </div>
                                <p>
                                    <div style={{ paddingTop: "0.5%" }}></div>
                                    {t('onBoarding.standardized')}&nbsp;
                                    <span style={{ fontSize: "0.8vw" }}>
                                        {t('onBoarding.standardizedDesc')}
                                    </span >
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "50%", height: "100%" }}>
                        <div style={{ width: "100%", height: "24%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "75%", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                                {t('onBoarding.canHaveMore')}
                            </div>
                            <div style={{ width: "17.5%", display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center" }}>
                                    <CustomCheckbox
                                        disabled={canBeModified}
                                        height={"2vh"}
                                        width={"2vh"}
                                        boxShadow={"none"}
                                        backgroundColor={"#D9D9D9"}
                                        onChange={(e) => {
                                            dispatch(setAllowedRefill(true));
                                            dispatch(setWhyNotRefill(""))
                                        }}
                                        checked={props.onBoardingState.allowedRefill === true} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {t('onBoarding.yes')}
                                </div>
                            </div>
                            <div style={{ width: "17.5%", display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center" }}>
                                    <CustomCheckbox
                                        disabled={canBeModified}
                                        height={"2vh"}
                                        width={"2vh"}
                                        boxShadow={"none"}
                                        backgroundColor={"#D9D9D9"}
                                        onChange={(e) => {
                                            dispatch(setAllowedRefill(false));
                                            dispatch(setWhenRefill(""))
                                        }}
                                        checked={props.onBoardingState.allowedRefill === false} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {t('onBoarding.no')}
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "20%" }}></div>
                        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                            <div style={{ width: "49%", height: "60%", display: "flex", paddingLeft: "2%", alignItems: "flex-start" }}>
                                {t('onBoarding.ifYesMore')}
                            </div>
                            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", paddingLeft: "2%" }}>
                                <textarea
                                    style={{ position: "relative" }}
                                    disabled={canBeModified || props.onBoardingState.allowedRefill === false}
                                    className="on-boarding-text-area"
                                    onChange={(e) => dispatch(setWhenRefill(e.target.value))}
                                    value={props.onBoardingState.whenRefill} />
                            </div>
                        </div>
                        <div style={{ height: "20%" }}></div>
                        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                            <div style={{ width: "100%", height: "60%", display: "flex", paddingLeft: "2%", alignItems: "flex-start" }}>
                                {t('onBoarding.ifNoMore')}
                            </div>
                            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", paddingLeft: "2%" }}>
                                <textarea
                                    style={{ position: "relative" }}
                                    disabled={canBeModified || props.onBoardingState.allowedRefill === true}
                                    className="on-boarding-text-area"
                                    onChange={(e) => dispatch(setWhyNotRefill(e.target.value))}
                                    value={props.onBoardingState.whyNotRefill} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getVegetarian = () => {
        return (
            <div style={{ width: "100%", height: "100%", paddingBottom: "3%" }}>
                <div style={{ width: "100%", height: "20%", color: "#68B4E3", fontSize: "1.2vw", fontWeight: "bold" }}>
                    {t('onBoarding.vegeMeal')}
                </div>
                <div style={{ width: "100%", height: "85%", color: "#666666" }}>

                    <div style={{ width: "100%", height: "25%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "41%", height: "100%", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                            {t('onBoarding.numberVege')}
                        </div>
                        <div style={{ width: "68%", height: "100%", display: "flex", alignItems: "center", paddingLeft: "1.6%" }}>
                            <CustomTextField
                                disabled={canBeModified}
                                width={"10%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                textAlign={"center"}

                                type={"number"}
                                onChange={(e) => dispatch(setNumberVege(e.target.value))}
                                value={props.onBoardingState.numberVege} />
                        </div>
                    </div>
                    <div style={{ height: "19%", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                        {t('onBoarding.isVege')}
                    </div>
                    <div style={{ height: "25%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "2.8%", height: "100%", display: "flex", alignItems: "center" }}>
                            <CustomCheckbox
                                disabled={canBeModified}
                                height={"2vh"}
                                width={"4vh"}
                                boxShadow={"none"}
                                backgroundColor={"#D9D9D9"}
                                onChange={(e) => dispatch(setVegeType(t('onBoarding.uniqueMeal')))}
                                checked={props.onBoardingState.vegeType === t('onBoarding.uniqueMeal')} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {t('onBoarding.uniqueMeal')}
                        </div>
                    </div>
                    <div style={{ height: "18%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "2.8%", height: "100%", display: "flex", alignItems: "center" }}>
                            <CustomCheckbox
                                disabled={canBeModified}
                                height={"2vh"}
                                width={"4vh"}
                                boxShadow={"none"}
                                backgroundColor={"#D9D9D9"}
                                onChange={(e) => dispatch(setVegeType(t('onBoarding.optionMeal')))}
                                checked={props.onBoardingState.vegeType === t('onBoarding.optionMeal')} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {t('onBoarding.optionMeal')}
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    const getWasteManagement = () => {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ width: "100%", height: "54%", color: "#68B4E3", fontSize: "1.2vw", fontWeight: "bold" }}>
                    {t('onBoarding.wasteManagement')}
                </div>

                <div style={{ width: "100%", height: "85%", color: "#666666" }}>
                    <div style={{ height: "45%", display: "flex", alignItems: "center", fontWeight: "bold" }}>{t('Quelle est la méthode choisie pour traiter vos biodéchets ?')}</div>
                    <div style={{ height: "14%" }}></div>
                    <div style={{ height: "50%", width: "60%", display: "flex", flexDirection: "row" }}>

                        <div style={{ height: "100%", width: "34%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "15%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"4vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setWasteManagementMethodOnBoarding(t('wasteManagementMethods.incineration')))}
                                    checked={props.onBoardingState.wasteManagementMethod === t('wasteManagementMethods.incineration')} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {t('wasteManagementMethods.incineration')}
                            </div>
                        </div>
                        <div style={{ height: "100%", width: "33%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "15%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setWasteManagementMethodOnBoarding(t('wasteManagementMethods.composting')))}
                                    checked={props.onBoardingState.wasteManagementMethod === t('wasteManagementMethods.composting')} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {t('wasteManagementMethods.composting')}
                            </div>
                        </div>
                        <div style={{ height: "100%", width: "33%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "15%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setWasteManagementMethodOnBoarding(t('wasteManagementMethods.animalFeed')))}
                                    checked={props.onBoardingState.wasteManagementMethod === t('wasteManagementMethods.animalFeed')} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {t('wasteManagementMethods.animalFeed')}
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "50%", width: "60%", display: "flex", flexDirection: "row" }}>
                        <div style={{ height: "100%", width: "34%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "15%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"4vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setWasteManagementMethodOnBoarding(t('wasteManagementMethods.burial')))}
                                    checked={props.onBoardingState.wasteManagementMethod === t('wasteManagementMethods.burial')} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {t('wasteManagementMethods.burial')}
                            </div>
                        </div>
                        <div style={{ height: "100%", width: "33%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "15%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setWasteManagementMethodOnBoarding(t('wasteManagementMethods.methanization')))}
                                    checked={props.onBoardingState.wasteManagementMethod === t('wasteManagementMethods.methanization')} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {t('wasteManagementMethods.methanization')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    return (
        <div style={{ width: "100%", height: "100%", paddingLeft: "2%", paddingRight: "2%", }}>
            <div style={{ display: error_portions ? "none" : "block", position: "absolute", top: "11%", left: "2%", width: "46.39%", height: "23%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_portions ? "none" : "block", position: "absolute", top: "11%", left: "49%", width: "48%", height: "52.2%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_vege ? "none" : "block", position: "absolute", top: "35.2%", left: "2%", width: "46.39%", height: "28%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_waste ? "none" : "block", position: "absolute", top: "64.39%", left: "2%", width: "56.5%", height: "28%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_portions ? "none" : "block", position: "absolute", top: "21.5%", left: "2.1%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                {t('Veuillez selectionner au moins une réponse')}
            </div>
            <div style={{ display: error_portions ? "none" : "block", position: "absolute", top: "27.5%", left: "49.5%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                {t('Veuillez remplir le champ ci-dessous')}
            </div>
            <div style={{ display: error_portions ? "none" : "block", position: "absolute", top: "45.6%", left: "49.5%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                {t('Veuillez remplir le champ ci-dessous')}
            </div>
            <div style={{ display: error_vege ? "none" : "block", position: "absolute", top: "41.2%", left: "1.5%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                {t('Veuillez remplir le champ ci-dessous et selectionner au moins une réponse')}
            </div>
            <div style={{ display: error_waste ? "none" : "block", position: "absolute", top: "75%", left: "2.1%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                {t('Veuillez selectionner au moins une réponse')}
            </div>

            <div className="on-boarding-title-row">
                {t('onBoarding.title', { year: year, page: " - Page 3/4" })}
            </div>

            <div style={{ height: "3%" }} />
            <div style={{ width: "100%", height: "26%" }}>
                {getPortions()}
            </div>
            <div style={{ height: "3%" }} />
            <div style={{ width: "100%", height: "30%" }}>
                {getVegetarian()}
            </div>
            <div style={{ width: "100%", height: "10%" }}>
                {getWasteManagement()}
            </div>
            <div style={{ height: "13.9%" }} />
            <div style={{ width: "100%", height: "7%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <CustomButton
                    width={"15%"}
                    height={"100%"}
                    color={"#fff"}
                    backgroundColor={"#666666"}
                    onClick={() => previousPage()}
                >
                    {t('buttons.previous')}
                </CustomButton>
                <div style={{ width: "2%" }} />
                <CustomButton
                    width={"15%"}
                    height={"100%"}
                    color={"#fff"}
                    backgroundColor={"#90C261"}
                    onClick={() => nextPage()}
                >
                    {t('buttons.next')}
                </CustomButton>
            </div>

        </div >
    )
}