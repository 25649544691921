import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { ReactComponent as BreadSvg } from "../../../../assets/bread.svg";
import { useTranslation } from 'react-i18next';
import { ReactComponent as Information } from "../../../../assets/informationButton.svg";
import { useState } from "react";

const Bread = (props) => {
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false)

    const getInfo = (type) => {
        return (
            <Information fill={"#666666"} style={{ width: "2vw", height: "3vh", top: "4%", right: "1%", position: "absolute" }}
                onMouseLeave={() => setShowInfo(false)}
                onMouseEnter={() => setShowInfo(true)} />)
    }
    const getBreadMass = () => {
        return "50g"
    }
    const getValues = () => {
        let bread = 0;
        let breadKitchen = 0;
        let analysis = props.analysis;

        let baseBread = 0;
        let baseKitchBread = 0;
        let baseTrays = 0;
        let baseKitchTrays = 0;
        if (analysis) {
            for (const day of analysis.days) {
                for (const category of day.categories) {
                    if (category.category === "Pain") {
                        bread = parseFloat(bread) + parseFloat(category.mass)
                    }
                }
            }
            for (const day of props.baseAnalysis.days) {
                baseTrays += day.trays;
                for (const category of day.categories) {
                    if (category.category === "Pain") {
                        baseBread = parseFloat(baseBread) + parseFloat(category.mass)
                    }
                }
            }
        }
        if (props.has_kitchen && props.kitchenAnalysis) {
            for (const day of props.kitchenAnalysis.days) {
                for (const category of day.categories) {
                    if (category.category === "Pain") {
                        breadKitchen = parseFloat(breadKitchen) + parseFloat(category.mass)
                    }
                }
            }
            for (const day of props.baseAnalysisKitch.days) {
                baseKitchTrays += props.kitchenAnalysis.trays
                for (const category of day.categories) {
                    if (category.category === "Pain") {
                        baseKitchBread = parseFloat(baseKitchBread) + parseFloat(category.mass)
                    }
                }
            }

        }

        let breadConvTrays = baseTrays > 0 ? baseBread / baseTrays : 0;
        let breadConvKitchen = baseKitchTrays > 0 ? baseKitchBread / baseKitchTrays : 0;
        return {
            breadNumber: ((bread + breadKitchen) / 50).toFixed(0),
            breadConv: breadConvTrays + breadConvKitchen
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: "100%" }}>
            <CustomBlock width={"100%"} height={"100%"} hasHover={true} position={"relative"} backgroundColor={'#fff'} selected={true}>
                {getInfo("Pain")}
                {showInfo && (
                    <div style={{ width: "15vw", position: "absolute", bottom: "100%", right: "15%", backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px", fontWeight: "Light", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.breadHelp", { breadMass: getBreadMass() })}</div>
                )}
                <div className="dashboard-block-values" style={{ cursor: "default" }}>
                    <div className="dashboard-analysis-row-icon-value">
                        <div className="dashboard-block-values-icon-analysis">
                            <BreadSvg style={{ height: '100%', width: '100%', fill: "#666666" }} />
                        </div>
                        <div className="dashboard-block-values-analysis-value" style={{ color: "#666666" }}>
                            {`x ${getValues().breadNumber}`}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend" style={{ height: "10%", color: "#666666" }}>
                        {t('components.bread')}
                    </div>
                    <div className="dashboard-block-values-second-legend" style={{ color: "#6D6C7A", fontSize: '0.9vw', textAlign: "center", justifyContent: "center", alignItems: "center", display: "flex" }}>
                        <span style={{ fontWeight: "600",fontSize:"1.2vw" }}> {t('mass', { "value": { "mass": getValues().breadConv, "unit": "g", fixed: 2 } })} &nbsp; </span>
                        {t('synthesis.page2.byguest')}
                    </div>
                </div>
            </CustomBlock>
        </div>
    )
}

export default Bread
