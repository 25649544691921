import React from "react";
import { useTranslation } from 'react-i18next';
import CustomDropdown from "../../customComponents/CustomDropdown";
import { useDispatch } from "react-redux";
import { setExtrapolationValue } from "../../../actions_reducers/actions/dashboard.action";
import { extrapolateAnalyzesValues } from "../../customFunctions/extrapolation.function";
import { setExtrapolatedAnalyzes, setKitchenExtrapolatedAnalyzes } from "../../../actions_reducers/actions/datas.action";
import { setClientExtrapolatedAnalyzes, setClientKitchenExtrapolatedAnalyzes, setExtrapolationValueClient } from "../../../actions_reducers/actions/client.action";

const ExtrapolationButton = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const getDisplayedExtrapValue = () => {
        switch (props.state.extrapolationValue) {
            case ('day'):
                return t('extrapButton.dayExtrap');
            case ('week'):
                return t('extrapButton.weekExtrap');
            case ('month'):
                return t('extrapButton.monthExtrap');
            case ('year'):
                return t('extrapButton.yearExtrap');
            case ('analysis'):
                return t('extrapButton.analysisExtrap');
            default: return 'extrapButton.analysisExtrap'
        }
    }
    const getExtrapValue = (value) => {
        switch (value) {
            case (t('extrapButton.dayExtrap')):
                return 'day';
            case (t('extrapButton.weekExtrap')):
                return 'week';
            case (t('extrapButton.monthExtrap')):
                return 'month';
            case (t('extrapButton.yearExtrap')):
                return 'year';
            case t('extrapButton.analysisExtrap'):
                return 'analysis'
            default: return 'analysis';
        }
    }
    const values = [t('extrapButton.analysisExtrap'),
    t('extrapButton.dayExtrap'), t('extrapButton.weekExtrap'),
    t('extrapButton.monthExtrap'), t('extrapButton.yearExtrap')];
    const updateValue = (e) => {
        if (props.type !== "client") {
            console.log(e.target.id,t('extrapButton.day'),t('extrapButton.dayExtrap'))
            let analyzes = JSON.parse(JSON.stringify(props.analyzesDatas))
            analyzes = extrapolateAnalyzesValues(analyzes, getExtrapValue(e.target.id), 'restaurant', props.userState.averageGuests);
            let kitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas))
            kitchenAnalyzes = extrapolateAnalyzesValues(kitchenAnalyzes, getExtrapValue(e.target.id), 'restaurant', props.userState.averageGuests);
            dispatch(setKitchenExtrapolatedAnalyzes(kitchenAnalyzes));
            dispatch(setExtrapolatedAnalyzes(analyzes));
            dispatch(setExtrapolationValue(getExtrapValue(e.target.id)))
        }
        else {
            if (e.target.id !== "Sur l'analyse") {
                let analyzes = JSON.parse(JSON.stringify(props.analyzesDatas))
                analyzes = extrapolateAnalyzesValues(analyzes, getExtrapValue(e.target.id), "client", props.userState.clientAverageGuests);
                let kitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas))
                kitchenAnalyzes = extrapolateAnalyzesValues(kitchenAnalyzes, getExtrapValue(e.target.id), "client", props.userState.clientAverageGuests);
                dispatch(setClientKitchenExtrapolatedAnalyzes(kitchenAnalyzes))
                dispatch(setClientExtrapolatedAnalyzes(analyzes))
            }
            dispatch(setExtrapolationValueClient(getExtrapValue(e.target.id)))
        }
    }
    return (
        <CustomDropdown
            id={props.id}
            width={'100%'}
            height={'6vh'}
            backgroundColor={'#F2C37F'}
            displayedValue={getDisplayedExtrapValue()}
            selectedValue={t(`extrapButton.${props.state.extrapolationValue}`)}
            showSelected={true}
            values={values}
            onChange={updateValue}
            arrowColor={"#EA881F"}
        />
    )
}

export default ExtrapolationButton