import React, { useState } from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { ResponsiveBar } from '@nivo/bar';
import PieChart from "../../../charts/PieChart";


const Repartition = (props) => {
    const { t } = useTranslation();
    const getData = () => {
        let selectedAnalysisType = props.state.selectedAnalysisType;
        let selectedAnalysisNumber = props.state.selectedAnalysisNumber;
        let kitchenAnalysis = JSON.parse(JSON.stringify(props.kitchenDatas)).find(a => parseInt(a.analysisNumber) === parseInt(selectedAnalysisNumber));

        let analysis = JSON.parse(JSON.stringify(props.analyzesDatas)).find(a => parseInt(a.analysisNumber) === parseInt(selectedAnalysisNumber))

        let baseAnalysis = props.baseAnalysis && JSON.parse(JSON.stringify(props.baseAnalysis)).find(a => parseInt(a.analysisNumber) === parseInt(selectedAnalysisNumber));
        let baseKitchenAnalysis = props.baseAnalysisKitch && JSON.parse(JSON.stringify(props.baseAnalysisKitch)).find(a => parseInt(a.analysisNumber) === parseInt(selectedAnalysisNumber))

        let restaurant = 0;
        let kitchen = 0;
        let restaurantTooltip = '';
        let kitchenTooltip = '';
        if (analysis) {
            switch (selectedAnalysisType) {
                case "mass":
                    restaurant = (analysis.mass - analysis.inevitableMass);
                    restaurantTooltip = t('mass', { value: { "mass": restaurant, "unit": "kg" } });
                    break;
                case "cost":
                    restaurant = (analysis.cost - analysis.inevitableCost);
                    restaurantTooltip = t('currency', { value: { cost: restaurant } });
                    break;
                case "co2":
                    restaurant = (analysis.co2 - analysis.inevitableCo2);
                    restaurantTooltip = t('mass', { value: { "mass": restaurant, "unit": "kg" } });
                    break;
                case "massByConv":
                    restaurant = baseAnalysis && (baseAnalysis.mass - baseAnalysis.inevitableMass) / baseAnalysis.trays;
                    restaurantTooltip = t('mass', { value: { "mass": restaurant, "unit": "g" } });
                    break;
                default:
                    restaurant = (analysis.mass - analysis.inevitableMass);
                    restaurantTooltip = t('mass', { value: { "mass": restaurant, "unit": "kg" } });
                    break;

            }
        }
        if (kitchenAnalysis) {
            switch (selectedAnalysisType) {
                case "mass":
                    kitchen = (kitchenAnalysis.mass - kitchenAnalysis.inevitableMass);
                    kitchenTooltip = t('mass', { value: { "mass": kitchen, "unit": "kg" } });
                    break;
                case "cost":
                    kitchen = (kitchenAnalysis.cost - kitchenAnalysis.inevitableCost);
                    kitchenTooltip = t('currency', { value: { cost: kitchen } });
                    break;
                case "co2":
                    kitchen = (kitchenAnalysis.co2 - kitchenAnalysis.inevitableCo2);
                    kitchenTooltip = t('mass', { value: { "mass": kitchen, "unit": "kg" } });
                    break;
                case "massByConv":
                    kitchen = baseKitchenAnalysis && (baseKitchenAnalysis.mass - baseKitchenAnalysis.inevitableMass) / baseKitchenAnalysis.trays;
                    kitchenTooltip = t('mass', { value: { "mass": kitchen, "unit": "g" } });
                    break;
                default:
                    kitchen = (kitchenAnalysis.mass - kitchenAnalysis.inevitableMass);
                    kitchenTooltip = t('mass', { value: { "mass": kitchen, "unit": "kg" } });
                    break;

            }
        }
        let total = restaurant + kitchen;
        let result = [{
            id: "test1",
            id1: ((restaurant / total) * 100).toFixed(0) + t("components.percentWaste"),
            id2: t('components.unavoidable'),
            value: restaurant,
            color: "#90C261",
            labelColor: "#90C261",
            tooltip: restaurantTooltip
        }, {
            id: "test2",
            id1: ((kitchen / total) * 100).toFixed(0) + t("components.percentWaste"),
            id2: t('components.avoidable'),
            value: kitchen,
            color: "#86CAF1",
            labelColor: "#86CAF1",
            tooltip: kitchenTooltip
        }];
        let type = "kitchen"
        if (restaurant / total > 0.5)
            type = 'restaurant'
        let percent = ((type === "restaurant" ? restaurant : kitchen) / total * 100).toFixed(0)
        return { result: result, percent: percent, total: total, restaurant: restaurant, kitchen: kitchen, type: type };
    }
    const getFormattedValue = (value) => {
        let formattedValue = 0;
        switch (props.state.selectedAnalysisType) {
            case "mass":
                formattedValue = t('mass', { value: { "mass": value, "unit": "kg" } });
                break;
            case "cost":
                formattedValue = t('currency', { value: { cost: value } });
                break;
            case "co2":
                formattedValue = t('mass', { value: { "mass": value, "unit": "kg" } });
                break;
            case "massByConv":
                formattedValue = t('mass', { value: { "mass": value, "unit": "g" } });
                break;
            default:
                formattedValue = t('mass', { value: { "mass": value, "unit": "kg" } });
                break;

        }
        return formattedValue
    }
    return (
        <CustomBlock width={props.width || '40%'} height={'100%'}>
            <div className="dashboard-block-title" style={{ padding: '2%' }}>
                <div className="top-gaspi-title-div" style={{ width: "100%", paddingLeft: ".5%" }}>
                    {t('components.wasteRepartition')}
                </div>
            </div>
            <div style={{ height: "85%", width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                <div style={{ width: "40%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <div style={{ height: "50%", width: "80%" }}>
                        <div style={{ height: "30%" }}>
                            {t('components.totalValue', { value: getFormattedValue(getData().total) })}
                        </div>
                        <div style={{ height: "5%" }} />
                        <div style={{ height: "30%" }}>
                            {t('components.wasteRestaurant', { value: getFormattedValue(getData().restaurant) })}
                        </div>
                        <div style={{ height: "5%" }} />
                        <div style={{ height: "30%" }}>
                            {t('components.wasteKitchen', { value: getFormattedValue(getData().kitchen) })}
                        </div>
                    </div>
                </div>
                <div style={{ width: "60%", height: "100%" }}>
                    <PieChart data={getData().result}
                        innerRadius={0.7}
                        bottom={15} left={5} top={20} right={5} />
                </div>
            </div>
            <div style={{ width: "25%", height: "20%", top: "47%", left: "57%", position: "absolute", display: "flex", color: getData().type === "restaurant" ? "#90C261" : "#86CAF1", flexDirection: "column", justifyContent: "center", alignItems: "center", zIndex: 0 }}>
                <div style={{ width: "100%", textAlign: "center", fontWeight: "700", fontSize: "1.3vw" }}>
                    {getData().percent + "%"}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {getData().type === "restaurant" ? t('components.wasteInRestaurant') : t('components.wasteInKitchen')}
                </div>
            </div>
        </CustomBlock>
    )
}
export default Repartition;