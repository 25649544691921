import React from "react";
import { connect } from "react-redux"; // REDUX

const Stats = () => {
    return (
        <div className="main-page-main-div" style={{ height: "100vh" }}>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userState: state.userReducer,
    };
}

export default connect(mapStateToProps)(Stats);
