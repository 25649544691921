import { useTranslation } from 'react-i18next';
import loading from '../../assets/loading.gif'

const CustomLoading = () => {
    const { t } = useTranslation();
    return (
        <div
            style={{
                width: "100%", height: "85.8vh", justifyContent: 'center', display: "flex", alignItems: "center", flexDirection: "column", fontSize: "1.1vw",
                fontWeight: "bold", color: "#666666"
            }}
        >
            <img src={loading} className="animated-gif" />
            {t('components.dataLoading')}
        </div>
    )
}

export default CustomLoading;