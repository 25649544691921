import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';

const DayGap = (props) => {
    const { t } = useTranslation();
    const getValues = (valueDay, valueAnalysis, day, analysis) => {
        let selectedAnalysisType = props.state.selectedAnalysisType;
        switch (selectedAnalysisType) {
            case "mass":
                valueDay = (day.mass - day.inevitableMass);
                valueAnalysis = (analysis.mass - analysis.inevitableMass) / analysis.days.length;
                break;
            case "cost":
                valueDay = (day.cost - day.inevitableCost);
                valueAnalysis = (analysis.cost - analysis.inevitableCost) / analysis.days.length;
                break;
            case "co2":
                valueDay = (day.co2 - day.inevitableCo2);
                valueAnalysis = (analysis.co2 - analysis.inevitableCo2) / analysis.days.length;
                break;
            case "massByConv":
                valueDay = (day.mass - day.inevitableMass) / day.trays;
                valueAnalysis = (analysis.mass - analysis.inevitableMass) / analysis.trays;
                break;
            default:
                valueDay = (day.mass - day.inevitableMass);
                valueAnalysis = (analysis.mass - analysis.inevitableMass);
                break;
        }
        return {
            valueDay: valueDay,
            valueAnalysis: valueAnalysis
        }
    }
    const getValue = () => {
        let valueDay = 0;
        let valueAnalysis = 1;
        if (props.day && props.analysis) {
            valueDay = getValues(valueDay, valueAnalysis, props.day, props.analysis).valueDay;
            valueAnalysis = getValues(valueDay, valueAnalysis, props.day, props.analysis).valueAnalysis;
        }
        if (props.kitchenDay && props.kitchenAnalysis) {
            valueDay += getValues(valueDay, valueAnalysis, props.kitchenDay, props.kitchenAnalysis).valueDay;
            valueAnalysis += getValues(valueDay, valueAnalysis, props.kitchenDay, props.kitchenAnalysis).valueAnalysis;
        }
        let gapPercentage = (Math.abs((1 - (valueDay / valueAnalysis)) * 100)).toFixed(0);
        return (valueDay / valueAnalysis > 1 ? " + " : " - ") + gapPercentage + " %";
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: "100%" }}>
            <CustomBlock width={"100%"} height={"100%"} hasHover={true} backgroundColor={'#fff'} selected={true}>
                <div className="dashboard-block-values" style={{ cursor: "default", paddingTop: "4%", paddingLeft: "4%" }}>
                    <div className="dashboard-day-gap-title">
                        {t('components.gap')}
                    </div>
                    <div className="dashboard-day-gap-value">
                        {getValue()}
                    </div>
                </div>
            </CustomBlock>

        </div>
    )
}

export default DayGap