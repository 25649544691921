import React from "react";
import { useDispatch } from "react-redux"; // REDUX
import CustomBlock from "../../../customComponents/CustomBlock";
import { fr } from 'date-fns/esm/locale'
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import isSameMonth from "date-fns/isSameMonth";
import isSameDay from "date-fns/isSameDay";
import toDate from "date-fns/toDate";
import format from "date-fns/format";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import { useState } from "react";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { ReactComponent as Warning } from "../../../../assets/warning.svg";
import 'moment/locale/fr';
import { setSelectedDateOperations } from "../../../../actions_reducers/actions/operations.action";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);


const OperationsCalendar = (props) => {
    const dispatch = useDispatch();

    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const header = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="column col-start">
                    <div className="icon" onClick={prevMonth}>
                        chevron_left
                    </div>
                </div>
                <div className="column col-center">
                    <span>{moment(currentDate).format(dateFormat)}</span>
                </div>
                <div className="column col-end">
                    <div className="icon" onClick={nextMonth}>
                        chevron_right
                    </div>
                </div>
            </div>
        );
    };
    const nextMonth = () => {
        setCurrentDate(addMonths(currentDate, 1));
    };
    const prevMonth = () => {
        setCurrentDate(subMonths(currentDate, 1));
    };

    const daysOfWeek = () => {
        const dateFormat = "E";
        const days = [];
        const monthStart = startOfMonth(currentDate);
        let startDate = startOfWeek(monthStart);
        for (let i = 1; i < 8; i++) {
            days.push(
                <div className="column col-center" key={i}>
                    {format(addDays(startDate, i), dateFormat, { fr })}
                </div>
            );
        }
        return <div className="days row">{days}</div>;
    };
    const getInfos = (status) => {
        if (status) return <Check className="informations-check-style" style={{ width: "28%", paddingTop: "10%" }} />
        else return <Warning className="informations-warning-style" style={{ width: "25%", paddingTop: "10%" }} />
    }
    const getHasRestaurant = (restaurant_name) => {
        if (restaurant_name === "REDLANDS") {
            return false
        }
        else {
            let informations = props.informations.find(a => a.restaurantName === restaurant_name);
            return informations.hasRestaurant;
        }
    }
    const cells = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = addDays(startDate, 1);
        let formattedDate = "";
        let testDate = moment(new Date()).format('YYYY-MM-DD')
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                let tmpDay = moment(day).format("YYYY-MM-DD")
                let currentOperations = null;
                if (moment(tmpDay).isSameOrBefore(moment(testDate))) {
                    currentOperations = JSON.parse(JSON.stringify(props.state.operations)).filter(a =>
                        moment(a.date).format("YYYY-MM-DD") === moment(tmpDay).format("YYYY-MM-DD") && getHasRestaurant(a.restaurant_name)
                    );
                }
                let status = false;
                if (currentOperations && !currentOperations.find(a => a.checked === false || !a.checked)) status = true;
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`column cell ${!isSameMonth(day, monthStart)
                            ? "disabled"
                            : isSameDay(day, selectedDate)
                                ? "selected"
                                : ""
                            }`}
                        style={{ overflow: "visible" }}
                        key={day}
                        onClick={() => onDateClick(toDate(cloneDay))}
                    >
                        {(currentOperations) && getInfos(status)}
                        <div className="number" style={{ zIndex: 10 }}
                            onClick={() => {
                            }}>{formattedDate}</div>
                        <span className="bg">{formattedDate}</span>
                    </div>
                );

                day = addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    };
    const onDateClick = (date) => {
        setSelectedDate(date)
        dispatch(setSelectedDateOperations(moment(date).format("YYYY-MM-DD")))
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}
            paddingTop={"1%"}>
            <div className="calendar">
                <div style={{ color: "#666666" }}>{header()}</div>
                <div style={{ color: "#666666" }}>{daysOfWeek()}</div>
                <div style={{ color: "#666666" }}>{cells()}</div>
            </div>

        </CustomBlock>
    )
}

export default OperationsCalendar;
