
const CustomSwitch = (props) => {
    return (
        <div
            style={{ width: "100%", height: props.height || "100%", display: "flex", justifyContent: "center", flexDirection: "row" }}>
            <div style={{ width: "35%", height: "100%", textAlign: "center", fontSize: "0.8vw", fontWeight: 300, lineHeight: props.lineHeight }}>
                {props.leftText}
            </div>
            <div style={{ width: "30%", height: "100%" }}>
                <label className="toggle-switch" onChange={props.onClick}>
                    <input id={"switch-checkbox"} type="checkbox" defaultChecked={true} />
                    <span className="switch" />
                </label>
            </div>
            <div style={{ width: "25%", height: "100%", textAlign: "center", fontSize: "0.8vw", fontWeight: 300, lineHeight: props.lineHeight }}>
                {props.rightText}
            </div>
        </div>
    )
}

export default CustomSwitch;