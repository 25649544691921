import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { ReactComponent as Euro } from "../../../../assets/euro.svg";
import { ReactComponent as Kg } from "../../../../assets/kg.svg";
import { ReactComponent as Earth } from "../../../../assets/earth.svg";
import { getNumberDaysExtrap } from "../../../customFunctions/extrapolation.function";
import { useState } from "react";
import { ReactComponent as Information } from "../../../../assets/informationButton.svg";

const Evolution = (props) => {

    const { t } = useTranslation();

    const getMaxValue = () => {
        let max = 1;
        for (const analysis of props.analyzesDatas) {
            if (analysis.isOver) {
                if (analysis.analysisNumber > max) max = analysis.analysisNumber;
            }
        }
        for (const analysis of props.kitchenAnalyzesDatas) {
            if (analysis.isOver) {
                if (analysis.analysisNumber > max) max = analysis.analysisNumber;
            }
        }
        return max;
    }
    const [showInfo, setShowInfo] = useState(false)
    const getInfo = (type) => {
        return (
            <Information fill={"#666666"} style={{ width: "2vw", height: "3vh", zIndex: 10000000, top: "4%", right: "2%", position: "absolute" }}
                onMouseLeave={() => setShowInfo(false)}
                onMouseEnter={() => setShowInfo(true)} />)
    }
    const getValuesForAnalyzes = (analyzes, kitchenAnalyzes) => {
        let mass = 0;
        let cost = 0;
        let co2 = 0;
        let trays = 0;
        let massKitchen = 0;
        let costKitchen = 0;
        let co2Kitchen = 0;
        let traysKitchen = 0;
        let traysDayTest = 0;
        let nbDaysTest = 0;
        let traysFinal = 0;
        for (const analysis of analyzes) {
            mass = parseFloat(mass) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
            cost = parseFloat(cost) + parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost);
            co2 = parseFloat(co2) + parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2);
            trays = parseFloat(trays) + parseFloat(analysis.trays);

            traysDayTest = parseInt(traysDayTest) + parseInt(analysis.trays)
            nbDaysTest = parseInt(nbDaysTest) + parseInt(analysis.days.length)

            let kitchenAnalysis = kitchenAnalyzes.find(a => a.restaurant_name === analysis.restaurant_name);
            if (kitchenAnalysis) {
                massKitchen = parseFloat(massKitchen) + parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
                costKitchen = parseFloat(costKitchen) + parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost);
                co2Kitchen = parseFloat(co2Kitchen) + parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2);
                traysKitchen = parseFloat(traysKitchen) + parseFloat(kitchenAnalysis.trays);

                traysDayTest = parseInt(traysDayTest) + parseInt(kitchenAnalysis.trays);
                nbDaysTest = parseInt(nbDaysTest) + parseInt(kitchenAnalysis.days.length)
            }
            traysFinal = parseFloat(traysFinal) + parseInt(traysDayTest) / parseInt(nbDaysTest)
            traysDayTest = 0;
            nbDaysTest = 0;
        }
        for (const kitchenAnalysis of kitchenAnalyzes) {
            if (!analyzes.find(a => a.restaurant_name === kitchenAnalysis.restaurant_name)) {
                massKitchen = parseFloat(massKitchen) + parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
                costKitchen = parseFloat(costKitchen) + parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost);
                co2Kitchen = parseFloat(co2Kitchen) + parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2);
                traysKitchen = parseFloat(traysKitchen) + parseFloat(kitchenAnalysis.trays);

                traysDayTest = parseInt(traysDayTest) + parseInt(kitchenAnalysis.trays);
                nbDaysTest = parseInt(nbDaysTest) + parseInt(kitchenAnalysis.days.length)
                traysFinal = parseFloat(traysFinal) + parseInt(traysDayTest) / parseInt(nbDaysTest)
                traysDayTest = 0;
                nbDaysTest = 0;

            }
        }
        let massConv = trays !== 0 ? mass / trays : 0;
        let massConvKitchen = traysKitchen !== 0 ? massKitchen / traysKitchen : 0;
        let costConv = trays !== 0 ? cost / trays : 0;
        let costConvKitchen = traysKitchen !== 0 ? costKitchen / traysKitchen : 0;
        let co2Conv = trays !== 0 ? co2 / trays : 0;
        let co2ConvKitchen = traysKitchen !== 0 ? co2Kitchen / traysKitchen : 0;
        let massConvTot = parseFloat(massConv) + parseFloat(massConvKitchen);
        let costConvTot = parseFloat(costConv) + parseFloat(costConvKitchen);
        let co2ConvTot = parseFloat(co2Conv) + parseFloat(co2ConvKitchen);
        return {
            massConvTot: massConvTot,
            costConvTot: costConvTot,
            co2ConvTot: co2ConvTot,
            traysFinal: traysFinal,
        }
    }
    const getValues = () => {
        let max = getMaxValue();
        let daysAnalysis = 0;
        let currentAnalysis = JSON.parse(JSON.stringify(props.analyzesDatas)).filter(a => a.analysisNumber === max);
        let currentKitchenAnalysis = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas)).filter(a => a.analysisNumber === max)
        for (const analysis of currentAnalysis) {
            if (analysis.days.length > daysAnalysis) {
                daysAnalysis = analysis.days.length;
            }
        }
        for (const analysis of currentKitchenAnalysis) {
            if (analysis.days.length > daysAnalysis) {
                daysAnalysis = analysis.days.length;
            }
        }
        let numberDaysExtrap = getNumberDaysExtrap(props.extrapolationValue, daysAnalysis)
        let mass1 = 0;
        let cost1 = 0;
        let co21 = 0;
        let mass2 = 0;
        let cost2 = 0;
        let co22 = 0;
        let trays2 = 0;
        let firstAnalyzes = JSON.parse(JSON.stringify(props.analyzesDatas)).filter(a => a.analysisNumber === 1);
        let firstKitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas)).filter(a => a.analysisNumber === 1);
        if (firstAnalyzes.length > 0 || firstKitchenAnalyzes.length > 0) {
            let firstValues = getValuesForAnalyzes(firstAnalyzes, firstKitchenAnalyzes);
            mass1 = firstValues.massConvTot;
            cost1 = firstValues.costConvTot;
            co21 = firstValues.co2ConvTot;
        }
        let lastAnalyzes = JSON.parse(JSON.stringify(props.analyzesDatas)).filter(a => a.analysisNumber === max);
        let lastKitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas)).filter(a => a.analysisNumber === max);
        if (lastAnalyzes.length > 0 || lastKitchenAnalyzes.length > 0) {
            let lastValues = getValuesForAnalyzes(lastAnalyzes, lastKitchenAnalyzes);
            mass2 = lastValues.massConvTot;
            cost2 = lastValues.costConvTot;
            co22 = lastValues.co2ConvTot;
            trays2 = lastValues.traysFinal
        }
        let massEco = mass2 < mass1 ? (mass2 - mass1) * (props.userState.averageGuests ? props.userState.averageGuests : trays2) * numberDaysExtrap : 0
        let costEco = cost2 < cost1 ? (cost2 - cost1) * (props.userState.averageGuests ? props.userState.averageGuests : trays2) * numberDaysExtrap : 0
        let co2Eco = co22 < co21 ? (co22 - co21) * (props.userState.averageGuests ? props.userState.averageGuests : trays2) * numberDaysExtrap : 0
        return {
            mass: t('mass', { value: { "mass": massEco.toFixed(0), "unit": "kg" } }),
            cost: t('currency', { "value": { "cost": Math.abs(costEco).toFixed(0), "fixed": 0 } }),
            co2: t('mass', { value: { "mass": co2Eco.toFixed(0), "unit": "kg" } }),
        }
    }
    return (
        <CustomBlock width={'100%'} height={'40vh'} style={{ position: 'relative' }} r>
            {getInfo()}
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title" style={{ paddingBottom: "1%" }}>
                    {t('components.sinceFirst', { period: t(`periods.${props.extrapolationValue}`) })}
                </div>
                <div className="dashboard-block-since-first" style={{ display: "flex", alignItems: "center" }}>
                    <CustomBlock backgroundColor={"#CACACA"} height={"80%"} width={"27%"} marginLeft={"2%"}>
                        <div className="dashboard-block-since-first-value">
                            <Earth style={{ height: '100%', width: '30%', marginTop: "5%" }} />
                            {getValues().co2}
                        </div>
                        <div className="dashboard-block-since-first-legend">
                            {t('components.avoidedCo2')}
                        </div>
                    </CustomBlock>
                    <CustomBlock backgroundColor={"#CACACA"} height={"80%"} width={"27%"} marginLeft={"7.5%"}>
                        <div className="dashboard-block-since-first-value">
                            <Kg style={{ height: '90%', width: '30%', marginTop: "5%" }} />
                            {getValues().mass}
                        </div>
                        <div className="dashboard-block-since-first-legend">
                            {t('components.avoidedWaste')}
                        </div>

                    </CustomBlock>
                    <CustomBlock backgroundColor={"#CACACA"} height={"80%"} width={"27%"} marginLeft={"7.5%"}>
                        <div className="dashboard-block-since-first-value">
                            <Euro style={{ height: '100%', width: '30%', marginTop: "5%", marginRight: "1%" }} />
                            {getValues().cost}
                        </div>
                        <div className="dashboard-block-since-first-legend">
                            {t('components.avoidedCost')}
                        </div>
                    </CustomBlock>
                </div>
                {showInfo && (
                    <div className="window-info" style={{ width: "20%", position: "absolute", top: "5%", right: "5%", backgroundColor: "#FFFFFF", padding: "30px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.resultsOverTime")}</div>
                )}
            </div>
        </CustomBlock>
    )
}

export default Evolution;
