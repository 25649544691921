import React from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import CustomBlock from "../../customComponents/CustomBlock";
import RessourcesAlim from "./RessourcesAlim";
import RessourcesGaspi from "./RessourcesGaspi";
import RessourcesService from "./RessourcesService";
import RessourcesImpact from "./RessourcesImpact";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { setPaths, setSelectedPageRessources } from "../../../actions_reducers/actions/ressources.action";
import { useEffect } from "react";
import RessourcesKikleo from "./RessourcesKikleo";
import { getAllRessourcesFiles } from "../../../apiRoutes/files.routes";
import { updateArianneThread } from "../../arianneThread/ArianneThread";
const bell = require('../../../assets/cloche.png')
const wasteSupport = require('../../../assets/wasteSupport.png')
const impact = require('../../../assets/support.png')
const openBook = require('../../../assets/openBook.png')
const foods = require('../../../assets/foods.png')

const Ressources = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [hovered, setHovered] = useState(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        getFilesList()
    }, [])

    const getFilesList = async () => {
        let files = await getAllRessourcesFiles();
        let paths = [];
        for (const file of files) {
            let path = file.split('/')[0];
            let fileName = file.split('/')[1];
            if (fileName && fileName !== "") {
                let index = paths.findIndex(a => a.path === path);
                if (index === -1) {
                    let newFile = {
                        path: path,
                        files: [fileName]
                    };
                    paths.push(newFile);
                }
                else {
                    paths[index].files.push(fileName);
                }
            }
        }
        dispatch(setPaths(paths))
    }

    const getRessourcePage = () => {
        switch (props.selectedPage) {
            case 'alim':
                return <RessourcesAlim paths={props.paths} language={props.language} />;
            case 'gaspi':
                return <RessourcesGaspi paths={props.paths} language={props.language} />;
            case 'service':
                return <RessourcesService paths={props.paths} language={props.language} />;
            case 'impact':
                return <RessourcesImpact paths={props.paths} language={props.language} />;
            case 'kikleo':
                return <RessourcesKikleo paths={props.paths} language={props.language} />;
            default:
                return <RessourcesAlim paths={props.paths} language={props.language} />;
        }
    }

    const getHoveredColors = () => {
        return {
            textColor: "#68B4E3",
            circleColor: "#68B4E3A1",
            divColor: "#68B4E31A"
        }
    }

    const updateSelectedPage = (type) => {
        dispatch(setSelectedPageRessources(type))
        updateArianneThread(type, props.arianne, dispatch);
    }

    if (props.selectedPage == null)
        return (
            <div className="main-page-main-div" style={{ height: "85.8vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                <div style={{
                    width: "60vh", height: "60vh", borderRadius: "50%",
                    position: "absolute", backgroundColor: "#f0f0f0", zIndex: 100, display: "flex", justifyContent: "center", alignItems: "center",

                    boxShadow: "inset 0px 4px 4px 0px #00000040",
                }} />
                <div style={{ height: "60.1vh", width: "4.5%", backgroundColor: "#f0f0f0", position: "absolute", zIndex: 101, borderRadius: "0" }} />
                <div style={{ width: "60.2vh", height: "4.4%", backgroundColor: "#f0f0f0", position: "absolute", zIndex: 101, borderRadius: "4px" }} />
                <div style={{
                    width: "54vh", height: "54vh",
                    borderRadius: "50%",
                    backgroundColor: hovered === "kikleo" ? getHoveredColors().divColor : "#fff",
                    zIndex: 103,
                    position: "absolute"
                    , boxShadow: "0px 4px 4px 0px #00000040",
                    cursor: "pointer"
                }}
                    onMouseEnter={() => setHovered("kikleo")}
                    onMouseLeave={() => setHovered(null)}
                    onClick={() => updateSelectedPage("kikleo")}>
                    <div style={{ height: "60%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "20vh", height: "20vh", borderRadius: "50%", backgroundColor: hovered === "kikleo" ? getHoveredColors().circleColor : "#f0f0f0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img alt={"impact"} src={impact} style={{ width: "60%", height: "60%" }}
                                onClick={() => updateSelectedPage("kikleo")} />
                        </div>
                    </div>

                    <div style={{ height: "40%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop: "-8%", color: hovered === "kikleo" && getHoveredColors().textColor }}
                        onClick={() => updateSelectedPage("kikleo")}>
                        <div style={{ width: "70%", height: "50%", fontWeight: "bold", fontSize: "1.3vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {t("ressources.kikleo")}
                        </div>
                        <div style={{ width: "50%", height: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {t("ressources.kikleoDesc")}
                        </div>
                    </div>
                </div>

                <div style={{ height: "47.5%", width: "100%", display: "flex", flexDirection: "row" }}
                >
                    <CustomBlock width={"47.5%"} height={"100%"} backgroundColor={hovered === "impact" && getHoveredColors().divColor}
                        onMouseEnter={() => setHovered("impact")}
                        onMouseLeave={() => setHovered(null)}
                        cursor={"pointer"}
                        boxShadow={"0px 4px 4px 0px #00000040"}
                        onClick={() => updateSelectedPage("impact")}
                        paddingLeft={"1.5%"}
                        paddingTop={"0.8%"} >
                        <div style={{ height: "35%" }}
                            onClick={() => updateSelectedPage("impact")}>
                            <div style={{ paddingTop: "2%", height: "100%", width: "69%", display: "flex", alignItems: "center", textAlign: "center", flexDirection: "column", color: hovered === "impact" && getHoveredColors().textColor }}
                                onClick={() => updateSelectedPage("impact")}>
                                <div style={{ width: "70%", height: "50%", fontWeight: "bold", fontSize: "1.3vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {t("ressources.impact")}
                                </div>
                                <div style={{ width: "100%", height: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {t("ressources.impactDesc")}
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "65%", width: "69%" }}>
                            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                                onClick={() => updateSelectedPage("impact")}>
                                <div style={{ width: "20vh", height: "20vh", borderRadius: "50%", backgroundColor: hovered === "impact" ? getHoveredColors().circleColor : "#f0f0f0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img alt={"wasteSupport"} src={wasteSupport} style={{ width: "60%", height: "60%" }}
                                        onClick={() => updateSelectedPage("impact")} />
                                </div>
                            </div>
                        </div>
                    </CustomBlock>
                    <div style={{ width: "5%" }} />
                    <CustomBlock width={"47.5%"} height={"100%"} backgroundColor={hovered === "gaspi" && getHoveredColors().divColor}
                        onMouseEnter={() => setHovered("gaspi")}
                        onMouseLeave={() => setHovered(null)}
                        cursor={"pointer"}
                        boxShadow={"0px 4px 4px 0px #00000040"}
                        onClick={() => updateSelectedPage("gaspi")}
                        paddingRight={"1.5%"}
                        paddingTop={"0.8%"} >
                        <div style={{ height: "35%", width: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "31%" }} />

                            <div style={{ paddingTop: "2%", height: "100%", width: "69%", display: "flex", alignItems: "center", textAlign: "center", flexDirection: "column", color: hovered === "gaspi" && getHoveredColors().textColor }}
                                onClick={() => updateSelectedPage("gaspi")}>
                                <div style={{ width: "50%", height: "50%", fontWeight: "bold", fontSize: "1.3vw", display: "flex", alignItems: "center", justifyContent: "center" }}
                                    onClick={() => updateSelectedPage("gaspi")}>
                                    {t("ressources.general")}
                                </div>
                                <div style={{ width: "100%", height: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}
                                    onClick={() => updateSelectedPage("gaspi")}>
                                    {t("ressources.generalDesc")}
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "65%", width: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "31%" }} />
                            <div style={{ height: "100%", width: "69%" }}>
                                <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                                    onClick={() => updateSelectedPage("gaspi")}>
                                    <div style={{ width: "20vh", height: "20vh", borderRadius: "50%", backgroundColor: hovered === "gaspi" ? getHoveredColors().circleColor : "#f0f0f0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <img alt={"openBook"} src={openBook} style={{ width: "60%", height: "60%" }}
                                            onClick={() => updateSelectedPage("gaspi")} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </CustomBlock>
                </div>
                <div style={{ height: "5%" }} />
                <div style={{ height: "47.5%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <CustomBlock width={"47.5%"} height={"100%"} backgroundColor={hovered === "service" && getHoveredColors().divColor}
                        onMouseEnter={() => setHovered("service")}
                        onMouseLeave={() => setHovered(null)}
                        cursor={"pointer"}
                        boxShadow={"0px 4px 4px 0px #00000040"}
                        onClick={() => updateSelectedPage("service")}
                        paddingLeft={"1.5%"}
                        paddingBottom={"0.8%"}>
                        <div style={{ height: "65%", width: "69%" }}>
                            <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                                onClick={() => updateSelectedPage("service")}>
                                <div style={{ width: "20vh", height: "20vh", borderRadius: "50%", backgroundColor: hovered === "service" ? getHoveredColors().circleColor : "#f0f0f0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img alt={"bell"} src={bell} style={{ width: "70%", height: "70%" }}
                                        onClick={() => updateSelectedPage("service")} />
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "35%", width: "100%" }}
                            onClick={() => updateSelectedPage("service")}>

                            <div style={{ height: "100%", width: "69%", display: "flex", alignItems: "center", textAlign: "center", flexDirection: "column", color: hovered === "service" && getHoveredColors().textColor }}>
                                <div style={{ width: "55%", height: "50%", fontWeight: "bold", fontSize: "1.3vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {t("ressources.practices")}
                                </div>
                                <div style={{ width: "100%", height: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {t("ressources.practicesDesc")}
                                </div>
                            </div>
                        </div>
                    </CustomBlock>
                    <div style={{ width: "5%" }} />
                    <CustomBlock width={"47.5%"} height={"100%"} backgroundColor={hovered === "alim" && getHoveredColors().divColor}
                        onMouseEnter={() => setHovered("alim")}
                        onMouseLeave={() => setHovered(null)}
                        cursor={"pointer"}
                        boxShadow={"0px 4px 4px 0px #00000040"}
                        onClick={() => updateSelectedPage("alim")}
                        paddingRight={"1.5%"}
                        paddingBottom={".8%"}>
                        <div style={{ height: "65%", width: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "31%" }} />
                            <div style={{ height: "100%", width: "69%", display: "flex", justifyContent: "center", alignItems: "center" }}
                                onClick={() => updateSelectedPage("alim")}>
                                <div style={{ width: "20vh", height: "20vh", borderRadius: "50%", backgroundColor: hovered === "alim" ? getHoveredColors().circleColor : "#f0f0f0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img alt={"foods"} src={foods} style={{ width: "60%", height: "60%" }}
                                        onClick={() => updateSelectedPage("alim")} />
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", height: "35%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "31%" }} />
                            <div style={{ height: "100%", width: "69%", display: "flex", alignItems: "center", textAlign: "center", flexDirection: "column", color: hovered === "alim" && getHoveredColors().textColor }}
                                onClick={() => updateSelectedPage("alim")}>
                                <div style={{ width: "55%", height: "50%", fontWeight: "bold", fontSize: "1.3vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {t("ressources.foods")}
                                </div>
                                <div style={{ width: "100%", height: "50%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {t("ressources.foodsDesc")}
                                </div>
                            </div>

                        </div>
                    </CustomBlock>
                </div>
            </div >
        )
    else return getRessourcePage();
}
function mapStateToProps(state) {
    return {
        selectedPage: state.ressourcesReducer.selectedPageRessources,
        paths: state.ressourcesReducer.paths,
        arianne: state.navigationReducer.arianne,
        language: state.navigationReducer.language
    };
}

export default connect(mapStateToProps)(Ressources);