import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/planning`;

export function getAllTasks() {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getAllTasks")
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getSpecificTasks(restaurant_name, type, login) {
    console.log(restaurant_name)
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getSpecificTasks", {
                params: {
                    restaurant_name: restaurant_name,
                    type: type,
                    login: login
                }
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function createNewTask(newTask) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/createNewTask", {
                newTask: newTask
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function deleteTaskForRestaurant(task) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/deleteTaskForRestaurant", { task: task })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function updateTask(task) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/updateTask", {
                params: {
                    task: task,
                }
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}