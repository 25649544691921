import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import '../Admin.css';
import ClientListTable from "./ClientListTable";
import { getAllInformations } from "../../../../apiRoutes/informations.routes";
import { setAllAccounts, setAllClientAnalyzes, setAllInformations, setDemoAccounts, setSelectedClient, setUserIdsEmails } from "../../../../actions_reducers/actions/admin.action";
import ClientListSelection from "./ClientListSelection";
import { getAllAccounts, getAllDemoAccounts, getUsersLoginAndIds } from "../../../../apiRoutes/account.route";
import { getAllClientAnalyzes } from "../../../../apiRoutes/clientAnalyzes.routes";

const ClientList = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        updateInformations()
    }, []);
    const updateInformations = async () => {
        let informations = await getAllInformations();
        let demoAccounts = await getAllDemoAccounts();
        let accounts = await getAllAccounts();
        let allClientAnalyzes = await getAllClientAnalyzes();
        let userIdsMails = await getUsersLoginAndIds()
        dispatch(setUserIdsEmails(userIdsMails));
        dispatch(setAllClientAnalyzes(allClientAnalyzes))
        demoAccounts.sort((a, b) => a.emailAccount.localeCompare(b.emailAccount));
        dispatch(setAllAccounts(accounts));
        dispatch(setDemoAccounts(demoAccounts));
        dispatch(setAllInformations(informations));
        let clientList = [];
        for (const restaurant of informations) {
            if (!clientList.find(a => a === restaurant.clientName))
                clientList.push(restaurant.clientName);
        }
        clientList.sort((a, b) => a.localeCompare(b));
        if (props.selectedClient === null)
            dispatch(setSelectedClient(clientList[0]))
    }
    return (
        <div className="main-page-main-div">
            <div style={{ width: "100%", height: "6vh" }}>
                <ClientListSelection
                    informations={props.informations}
                    selectedClient={props.selectedClient}
                    arianne={props.arianne} />
            </div>
            <div style={{ height: "4vh" }} />
            <div style={{ height: "75.8vh", width: "100%" }}>
                <ClientListTable informations={props.informations} selectedClient={props.selectedClient} arianne={props.arianne} />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userState: state.userReducer,
        informations: state.adminReducer.allInformations,
        selectedClient: state.adminReducer.selectedClient,
        arianne: state.navigationReducer.arianne
    };
}

export default connect(mapStateToProps)(ClientList);
