import { ResponsiveLine } from '@nivo/line';
import './Chart.css'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedAnalysisNumber } from '../../actions_reducers/actions/dashboard.action';
import { setSelectedAnalysisNumberClientRestaurant } from '../../actions_reducers/actions/client.action';
import { useTranslation } from 'react-i18next';
const LineChart = (props) => {
    const data = props.data;
    const [hoveredItem, setHoveredItem] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const getSvgColor = (index, text) => {
        if (!text || props.selectedAnalysisNumber) {
            {
                if (parseInt(hoveredItem) === parseInt(index) || parseInt(index) === parseInt(props.selectedAnalysisNumber)) {
                    if (!props.barColor)
                        return props.color !== "transparent" ? props.color : "#68B4E3";
                    else {
                        return props.barColor !== "transparent" ? props.barColor : "#68B4E3";
                    }
                }
                else return "#CACACA";
            }
        }
        else if (text) {
            if (text.includes(t('dataTypes.kitchen')) && parseInt(hoveredItem) === parseInt(index)) return "#54B0E3"
            else if (text.includes(t('dataTypes.restaurant')) && parseInt(hoveredItem) === parseInt(index)) return "#90C261"
            else return props.color
        }

    }
    const updateAnalysisNumber = (value) => {
        if (getStacked())
            dispatch(setSelectedAnalysisNumber(value))
        else dispatch(setSelectedAnalysisNumberClientRestaurant(parseInt(value)))
    }
    const getLabel = (t) => {
        let test = t.points.filter(a => a.serieId === "test");
        let labels = t.points.map((item, index) => {
            let res = []
            if (getStacked() === true ? (index !== 0 && index !== t.points.length - 1) : (item.id !== test[0].id && item.id !== test[test.length - 1].id) && item.serieId === "test") {
                let id = item.data.x
                let color = getSvgColor(id, item.data.tooltip)
                res.push(
                    <svg key={item.data.x} width="100%" height={t.innerHeight} className="label-svg"
                        onMouseEnter={(e) => setHoveredItem(e.target.id)}
                        onMouseLeave={() => setHoveredItem(null)}
                        onClick={(e) => updateAnalysisNumber(e.target.id)}>
                        <text
                            id={id}
                            fill={color}
                            x={item.x}
                            y={item.y - 35} dominantBaseline="middle" textAnchor="middle">{item.data.name}</text>
                        <line className="vertical-line"
                            id={id}
                            stroke={color}
                            x1={item.x}
                            y1={item.y - 20}
                            y2={t.innerHeight}
                            x2={item.x} />
                    </svg>
                )
            }
            return res;
        })
        return <g>{labels}</g>
    }
    const getMax = () => {
        let max = 0;
        for (const data of props.data) {
            for (const data2 of data.data) {
                if (data2.y > max)
                    max = data2.y;
            }
        }
        return max + max * 15 / 100;
    }
    const getMarker = (t) => {
        if (props.markerValue && t) {
            let value = t.innerHeight - (t.innerHeight / getMax()) * props.markerValue;
            let marker = [
                <svg key={"marker-1"}>
                    <line className="vertical-line"
                        id={"index"}
                        stroke={"#EA881F"}
                        x1={0}
                        y1={value}
                        y2={value}
                        x2={t.innerWidth}
                    />
                    <circle cx={t.innerWidth} cy={value} r="4" fill={"#EA881F"} />
                </svg>
            ]
            return <g>{marker}</g>
        }
        else return null;
    }
    const getAxisLeft = () => {
        if (props.axisLeft != null) return props.axisLeft
        else
            return {
                tickValues: 8
            }
    }
    const getStacked = () => {
        if (props.stacked != null) return props.stacked
        else return true
    }
    const toolTipElement = (node) => {
        return (
            <div className="custom-tooltip-pie" style={{
                backgroundColor: (props.barColor && props.barColor !== "transparent") ? props.barColor : node.point.color,
                width: "130%"
            }}>
                {node.point.data.tooltip}
            </div>
        )
    }
    const getMargins = () => {
        let left = 40;
        let right = 20;
        let top = 40;
        let bottom = 20;
        if (props.left != null) left = props.left;
        if (props.right != null) right = props.right;
        if (props.bottom != null) bottom = props.bottom;
        if (props.top != null) top = props.top;
        return {
            left: left,
            right: right,
            bottom: bottom,
            top: top
        }
    }
    return (<ResponsiveLine
        id={props.id}
        data={data}
        margin={{ top: getMargins().top, right: getMargins().right, bottom: getMargins().bottom, left: getMargins().left }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 0,
            max: getMax(),
            stacked: getStacked(),
            reverse: false
        }}
        tooltip={(node) => toolTipElement(node)}
        yFormat=" >-.3f"
        curve="linear"
        axisTop={null}
        axisRight={null}
        axisBottom={props.axisBottom || null}
        axisLeft={getAxisLeft()}
        lineWidth={!getStacked() ? 3 : 0}
        defs={
            [{
                id: props.id,
                type: 'linearGradient',
                colors: [
                    { offset: 0, color: props.color, opacity: 0.7 },
                    { offset: 100, color: props.color, opacity: 0.1 },
                ],
            }]}
        fill={
            [
                { match: '*', id: props.id },
            ]
        }
        enableGridX={false}
        enableGridY={false}
        pointSize={0}
        enableArea={true}
        colors={d => d.color || d}
        areaOpacity={1}
        useMesh={true}
        animate={!props.animate}
        isInteractive={true}
        markers={getMarker()}
        layers={
            [
                "grid",
                "axes",
                "areas",
                "lines",
                "points",
                "slices",
                "mesh",
                "legends",
                getMarker,
                props.label || getLabel]}
    />)
}

export default LineChart;