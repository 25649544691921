import React from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import { downLoadRessourceFile } from "../../../apiRoutes/files.routes";
const breadMini = require('../../../assets/breadMini.png');
const breadMiniEN = require('../../../assets/breadMiniEN.png');

const breadFruitHorizMini = require('../../../assets/breadFruitHorizMini.png');
const breadFruitMini = require('../../../assets/breadFruitMini.png');
const smallBigHungerMini = require('../../../assets/smallBigHungerMini.png');
const smallBigHungerChildMini = require('../../../assets/smallBigHungerChildMini.png');
const endOfServiceChildMini = require('../../../assets/endOfServiceChildMini.png');

const breadFruitHorizMiniEN = require('../../../assets/breadFruitHorizMiniEN.png');
const breadFruitMiniEN = require('../../../assets/breadFruitMiniEN.png');
const smallBigHungerMiniEN = require('../../../assets/smallBigHungerMiniEN.png');
const smallBigHungerChildMiniEN = require('../../../assets/smallBigHungerChildMiniEN.png');
const endOfServiceChildMiniEN = require('../../../assets/endOfServiceChildMiniEN.png');

const RessourcesService = (props) => {
    const { t } = useTranslation();
    const getPath = () => {
        if (props.language === "fr")
            return "service";
        else
            return "serviceEN";
    }
    const getDescription = (name) => {
        if (name === 'Pain_amont.pdf' || name === 'Bread_service_support_Upstream.pdf')
            return {
                title: t('ressources.serviceFiles.bread'),
                desc: t('ressources.serviceFiles.breadDesc'),
                picture: <img alt={"breadMini"} src={props.language === "fr" ? breadMini : breadMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Pain_fruit_adultes_1.pdf' || name === 'End_of_Service_Sorting_Support_Adult_Horizontal_Version.pdf')
            return {
                title: t('ressources.serviceFiles.breadFruitHoriz'),
                desc: t('ressources.serviceFiles.breadFruitHorizDesc'),
                picture: <img alt={"breadFruitHorizMini"} src={props.language === "fr" ? breadFruitHorizMini : breadFruitHorizMiniEN} style={{ height: "62%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Pain_fruits_adultes.pdf' || name === 'End_of_Service_Sorting_Support_Adult_Vertical_Version.pdf')
            return {
                title: t('ressources.serviceFiles.breadFruit'),
                desc: t('ressources.serviceFiles.breadFruitDesc'),
                picture: <img alt={"breadFruitMini"} src={props.language === "fr" ? breadFruitMini : breadFruitMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'petite_faim_grande_faim.pdf' || name === 'assessing_hunger_adult_horizontal_version.pdf')
            return {
                title: t('ressources.serviceFiles.hunger'),
                desc: t('ressources.serviceFiles.hungerDesc'),
                picture: <img alt={"smallBigHungerMini"} src={props.language === "fr" ? smallBigHungerMini : smallBigHungerMiniEN} style={{ height: "62%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Pain_fruit_enfant.pdf' || name === 'assessing_hunger_Children_horizontal_version.pdf')
            return {
                title: t('ressources.serviceFiles.breadFruitChildHoriz'),
                desc: t('ressources.serviceFiles.hungerDesc'),
                picture: <img alt={"smallBigHungerChildMini"} src={props.language === "fr" ? smallBigHungerChildMini : smallBigHungerChildMiniEN} style={{ height: "62%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Tri_fin_service_enfant.pdf' || name === 'End_of_Service_Sorting_Support_Children_version.pdf')
            return {
                title: t('ressources.serviceFiles.endOfServiceChild'),
                desc: t('ressources.serviceFiles.endOfServiceChildDesc'),
                picture: <img alt={"smallBigHungerChildMini"} src={props.language === "fr" ? endOfServiceChildMini : endOfServiceChildMiniEN} style={{ height: "62%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else
            return {
                title: name,
                desc: name,
                picture: <img alt={"smallBigHungerMini"} src={smallBigHungerMini} style={{ height: "62%", boxShadow: "0px 4px 4px #666666" }} />
            };
    }

    const downloadFile = async (file) => {
        let basePath = getPath() + '/'
        await downLoadRessourceFile(basePath + file, file);
    }
    const getRows = () => {
        let path = props.paths.find(a => a.path === getPath());
        let result = [];
        if (path && path.files) {
            let types = path.files;
            for (const type of types) {
                result.push(
                    <div style={{ width: "100%", height: "30%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "80%", height: "80%", backgroundColor: "#fff", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {getDescription(type).picture}
                            </div>
                        </div>
                        <div style={{ width: "55%" }}>
                            <div style={{ width: "100%", height: "40%", fontWeight: "bold", fontSize: "1.2vw", display: "flex", alignItems: "flex-end" }}>
                                {getDescription(type).title}
                            </div>
                            <div style={{ width: "100%", height: "60%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                {getDescription(type).desc}
                            </div>
                        </div>
                        <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <button className='custom-button-gradient'
                                style={{
                                    height: "4vh",
                                    width: "9vw",
                                    background: "#5BB3E4B3",
                                    color: "#fff"
                                }}
                                onClick={() => downloadFile(type)}>
                                {t('buttons.download')}
                            </button>
                        </div>
                    </div>
                )
            }
        }
        return result;
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <div style={{ height: "8%", width: "100%", color: "#68B4E3", fontSize: "1.4vw", display: "flex" }}>
                {t('ressources.learnWaste')}
            </div>
            <CustomBlock width={"100%"} height={"92%"} display={"flex"} flexDirection={"row"} >
                <CustomScrollDiv update={2}>
                    {getRows()}
                </CustomScrollDiv>
            </CustomBlock>
        </div>
    )
}
export default RessourcesService;