import React from "react";
import ConnexionContainer from "./components/connexion/Connexion";
import GeneralContainer from "./components/container/GeneralContainer";
import { Routes, Route } from "react-router-dom";
import { LoginRoute } from "./routes/routeHelper";
import applicationStore from "./store/store";
import { checkTokenValidity } from "./apiRoutes/connexion.routes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { hardResetNavigation } from "./actions_reducers/actions/navigation.action";
import { hardResetUser } from "./actions_reducers/actions/user.action";

function App() {
  const dispatch = useDispatch();
  
  const checkTokenValidityTest = async (token) => {
    let tmp = await checkTokenValidity(token);
    if (tmp === "error") {
      dispatch(hardResetNavigation());
      dispatch(hardResetUser());
    }
  }
  useEffect(() => {
    let token = applicationStore.getState().userReducer.token;
    checkTokenValidityTest(token)
  }, [])
  return (
    <>
      <title>Kikleo</title>

      <style>
        {`@media print {@page {
      size: landscape;
    }}`}
      </style>
      <Routes>
        <Route exact path="/" element={<LoginRoute><ConnexionContainer /></LoginRoute>} />
        <Route exact path="/clientConsole" element={<GeneralContainer />} />
      </Routes>
    </>
  );
}

export default App;
