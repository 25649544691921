import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { getAlerts, getAllDatesAlerts } from "../../../apiRoutes/alerts.routes";
import { setAlertDone, setAlerts, setAllDatesAlerts, setSelectedAlert, setSelectedAnalysisAlerts, setUncheckedDates } from "../../../actions_reducers/actions/alerts.action";
import AlertsTop from "./AlertsTop";
import AlertsDetails from "./AlertsDetails";
import CustomLoading from "../../customComponents/CustomLoading";

const Alerts = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.dates.length === 0)
            getDatesAlerts()
    }, [])


    const getDatesAlerts = async () => {
        dispatch(setAlertDone(false))
        let allDates = await getAllDatesAlerts(props.restaurant_name);
        dispatch(setAllDatesAlerts(allDates.result));
        dispatch(setUncheckedDates(allDates.uncheckedDates))
        dispatch(setAlertDone(true))
    }


    useEffect(() => {
        if (props.selectedAnalysis == null) {
            let result = [];
            for (const analysis of props.analyzes) {
                if (!result.find(a => a === analysis.analysisNumber))
                    result.push(analysis.analysisNumber)
            }
            result.sort((a, b) => b - a)
            dispatch(setSelectedAnalysisAlerts(result[0]))
        }
    }, [props.selectedAnalysis])

    useEffect(() => {
        if (props.selectedDate != null && !props.alerts.find(a => a.date === props.selectedDate))
            getAlertsForDay()
    }, [props.selectedDate, props.selectedAnalysis])

    const getAlertsForDay = async () => {
        dispatch(setAlertDone(false))
        let alerts = await getAlerts(props.restaurant_name, props.selectedDate);
        let newAlert = {
            date: props.selectedDate,
            alerts: alerts
        }
        let currentAlerts = JSON.parse(JSON.stringify(props.alerts));
        currentAlerts.push(newAlert);
        dispatch(setAlerts(currentAlerts));
        dispatch(setAlertDone(true))
    }
    const getAnalyzes = () => {
        return JSON.parse(JSON.stringify(props.analyzes.filter(a => parseInt(a.analysisNumber) === parseInt(props.selectedAnalysis))))
    }
    if (props.alertDone)
        return (
            <div className="main-page-main-div" style={{ height: "85.8vh" }}
                onClick={(e) => {
                    if (props.selectedAlert !== null && e.target.id !== props.selectedImage && e.target.localName !== "path")
                        dispatch(setSelectedAlert(null))
                }}>
                <div style={{ height: "8%" }}>
                    <AlertsTop analyzes={props.analyzes} uncheckedDates={props.uncheckedDates} alerts={props.alerts} dates={props.dates} selectedAnalysis={props.selectedAnalysis} selectedDate={props.selectedDate} modifiedAlerts={props.modifiedAlerts} />
                </div>
                <div style={{ height: "3%" }} />
                <div style={{ height: "89%", width: "100%" }}>
                    <AlertsDetails alerts={props.alerts} food_list={props.food_list} selectedAnalysis={props.selectedAnalysis}
                        selectedDate={props.selectedDate} modifiedAlerts={props.modifiedAlerts} selectedAlert={props.selectedAlert}
                        analyzes={getAnalyzes()}
                        wasteManagementMethod={props.wasteManagementMethod}
                        allAnalyzes={props.analyzes}
                        newTrays={props.newTrays}
                        multipleCams={props.multipleCams}
                        camsNames={props.camsNames}
                        restaurant_name={props.restaurant_name}
                        userState={props.userState}
                        dashboardState={props.dashboardState} />
                </div>
            </div >
        )

    else
        return (
            <div className="main-page-main-div">
                <CustomLoading />
            </div>
        )
}
function mapStateToProps(state) {
    return {
        restaurant_name: state.userReducer.selected_restaurant,
        alertsState: state.alertsReducer,
        dates: state.alertsReducer.dates,
        selectedAnalysis: state.alertsReducer.selectedAnalysis,
        selectedDate: state.alertsReducer.selectedDate,
        alerts: state.alertsReducer.alerts,
        food_list: state.datasReducer.food_list,
        modifiedAlerts: state.alertsReducer.modifiedAlerts,
        selectedAlert: state.alertsReducer.selectedAlert,
        alertDone: state.alertsReducer.alertDone,
        uncheckedDates: state.alertsReducer.uncheckedDates,
        analyzes: state.datasReducer.initialAnalyzes,
        wasteManagementMethod: state.datasReducer.wasteManagementMethod,
        newTrays: state.datasReducer.trays_by_day,
        multipleCams: state.userReducer.multiple_cams,
        camsNames: state.userReducer.cams,
        userState: state.userReducer,
        dashboardState: state.dashboardReducer
    };
}

export default connect(mapStateToProps)(Alerts);