import React, { useState } from "react";
import { useDispatch, connect } from "react-redux"; // REDUX
import { useNavigate } from 'react-router-dom';
import backgroundImage from "../../assets/Back_Connexion.svg";
import { useTranslation } from 'react-i18next';
import { Formik } from "formik"; // FORMIK
import * as Yup from "yup"; // YUP

import axios from "axios";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import './Connexion.css';
import { changePassword, connexion } from "../../apiRoutes/connexion.routes";
import { setHasChangedPassword, setIsLoggedIn, setLogin, setToken, setUserId, setUserType } from "../../actions_reducers/actions/user.action";
import { getRedirectionPage } from "../../routes/routeHelper";
import CustomButton from "../customComponents/CustomButton";
import { setLanguage } from "../../actions_reducers/actions/navigation.action";

const Connexion = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [connexionError, setConnexionError] = useState(false);
    const [forgottenPassword, setForgottenPassword] = useState(false);


    const connectionValidationSchema = Yup.object().shape({
        // validation schema
        login: Yup.string().required(t('errors.mandatoryField')),
        password: Yup.string().required(t('errors.mandatoryField')),

    });

    const modifValidationSchema = Yup.object().shape({
        // validation schema
        email: Yup.string().required(t('errors.mandatoryField'))

    });
    const handleSubmitForm = async (values) => {
        let res = await connexion(values.login.toLowerCase().replace(/ /g, ''), values.password);
        if (res.token) {
            if (res.language) {
                dispatch(setLanguage(res.language))
                i18n.changeLanguage(res.language);
            }
            dispatch(setLogin(values.login.toLowerCase().replace(/ /g, '')))
            axios.defaults.headers.common['token'] = res.token;
            dispatch(setToken(res.token));
            dispatch(setIsLoggedIn(true));
            dispatch(setUserType(res.user_type));
            dispatch(setUserId(res.id));
            dispatch(setHasChangedPassword(res.hasChangedPassword))
            let redirectionProps = { user_type: res.user_type, restaurant_name: res.restaurant_name, client_name: res.client_name, navigate: navigate, associated_restaurants: res.associated_restaurants }
            getRedirectionPage(redirectionProps);
        }
        else {
            window.alert("Erreur: adresse mail ou mot de passe incorrect.")
            setConnexionError(true);
        }
    };

    const handleSubmitFormModif = async (values) => {
        let res = await changePassword(values.email);
        if (res === "error") {
            window.alert("Erreur: cette adresse n'est associée à aucun compte.")
        }
        else {
            window.alert("Votre mot de passe a bien été modifié. Un email avec le nouveau mot de passe vous a été envoyé.");
            setForgottenPassword(false);
        }
    }

    const [showPassword, setShowPassword] = React.useState(false); // Show password state

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <div className="main-div">
            <div className='svg-div'>
                <img
                    className='svg-img'
                    src={backgroundImage}
                    alt="KikleoWelcomeImage"
                />
            </div>
            {!forgottenPassword ?
                <div className='form-div'>
                    <div
                        className="justify-content-md-center"
                        style={{ paddingLeft: "3px", paddingBottom: "0px" }}
                    >
                        <div className='connexion-title-div'>
                            <h1 className='connexion-title'> {t('components.welcome')} </h1> {/* title */}
                            <p className='connexion-subtitle'>
                                {t('components.connexion')}
                            </p>
                        </div>
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={{ login: "", password: "" }}
                        validationSchema={connectionValidationSchema}
                        onSubmit={handleSubmitForm}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div style={{ height: "3%" }}></div>
                                    <div className='userName-div'
                                    >
                                        <TextField
                                            id="login"
                                            name="login"
                                            placeholder={t('components.userName')}
                                            value={values.login}
                                            helperText={
                                                errors.login && touched.login ? errors.login : ""
                                            }
                                            error={errors.login && touched.login ? true : false}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            className="input"
                                        />
                                    </div>
                                    <div style={{ height: "2vh" }} />
                                    <div
                                        className="password-div"
                                    >
                                        <TextField
                                            id="password"
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            placeholder={t('components.password')}
                                            value={values.password}
                                            helperText={
                                                errors.password && touched.password
                                                    ? errors.password
                                                    : ""
                                            }
                                            error={
                                                errors.password && touched.password ? true : false
                                            }
                                            onChange={handleChange}
                                            autoComplete=""
                                            onBlur={handleBlur}
                                            fullWidth
                                            className="input"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityIcon />
                                                            ) : (
                                                                <VisibilityOffIcon />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </div>

                                    {
                                        connexionError ? (
                                            <div
                                                className="row justify-content-center"
                                                style={{ fontFamily: "Muli,sans-serif" }}
                                            >
                                                <p style={{ color: "red" }}> {props.error} </p>
                                            </div>
                                        ) : (
                                            <> </>
                                        )
                                    }
                                    <div style={{ height: "1vh" }} />
                                    <div style={{ height: "5%", textAlign: "left", cursor: "pointer", textDecoration: "underline", color: "#54b0e3" }}
                                        onClick={() => setForgottenPassword(true)}>
                                        {t('buttons.forgottenPassword')}
                                    </div>
                                    <div style={{ height: "4vh" }} />

                                    <div
                                        className="row justify-content-center"
                                        style={{ fontFamily: "Muli,sans-serif", height: "18%" }}
                                    >
                                        <CustomButton
                                            backgroundColor={"#90c261"}
                                            width={"70%"}
                                            fontSize={"clamp(1rem, 1.4vw, 5rem)"}
                                            height={"100%"}
                                            color={"#fff"}
                                            type="submit"
                                            disabled={isSubmitting}
                                        >

                                            {t('buttons.connect')}
                                        </CustomButton>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik >
                </div > :
                <div className='form-div'>
                    <div
                        className="justify-content-md-center"
                        style={{ paddingLeft: "3px", paddingBottom: "0px" }}
                    >
                        <div className='connexion-title-div' style={{ display: "flex", flexDirection: "column" }}>
                            <h1 className='connexion-title' style={{ width: "70%", alignSelf: "center" }}> {t('buttons.forgottenPassword')} </h1> {/* title */}
                            <p className='connexion-subtitle' style={{ height: "5%", width: "100%", alignSelf: "center", fontSize: "clamp(1rem, 2vh, 5rem)" }}>
                                {t('components.forgottenPassword1')}
                            </p>
                            {/* <p className='connexion-subtitle' style={{ height: "5%", width: "100%", alignSelf: "center" }}>
                                {t('components.forgottenPassword2')}
                            </p> */}
                        </div>
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={{ email: "" }}
                        validationSchema={modifValidationSchema}
                        onSubmit={handleSubmitFormModif}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className='userName-div'>
                                        <TextField
                                            id="email"
                                            name="email"
                                            label={t('components.email')}
                                            value={values.email}
                                            helperText={
                                                errors.email ? errors.email : ""
                                            }
                                            error={errors.email ? true : false}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            className="input"
                                        />
                                    </div>
                                    <div style={{ height: "2vh" }} />

                                    <div
                                        className="row justify-content-center"
                                        style={{ fontFamily: "Muli,sans-serif", height: "18%" }}
                                    >
                                        <CustomButton
                                            backgroundColor={"#666666"}
                                            fontSize={"clamp(1rem, 1.4vw, 5rem)"}
                                            width={"40%"}
                                            height={"6vh"}
                                            color={"#fff"}
                                            type="submit"
                                            onClick={() => setForgottenPassword(false)}
                                        >

                                            {t('buttons.cancel')}
                                        </CustomButton>
                                        <div style={{ width: "2vw" }} />
                                        <CustomButton
                                            backgroundColor={"#90c261"}
                                            fontSize={"clamp(1rem, 1.4vw, 5rem)"}
                                            width={"40%"}
                                            height={"6vh"}
                                            color={"#fff"}
                                            type="submit"
                                            disabled={isSubmitting}
                                        >

                                            {t('buttons.confirm')}
                                        </CustomButton>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik >
                </div >}
        </div >
    )
}

function mapStateToProps(state) {
    return {
        userState: state.userReducer
    };
}

export default connect(mapStateToProps)(Connexion);