import React from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import { downLoadRessourceFile } from "../../../apiRoutes/files.routes";
const proteinImpactLongMini = require('../../../assets/proteinImpactLongMini.png');
const proteinImpactShortMini = require('../../../assets/proteinImpactShortMini.png');
const dietMini = require('../../../assets/dietMini.png');
const breadImpactMini = require('../../../assets/breadImpactMini.png');
const proteinImpactLongMiniEN = require('../../../assets/proteinImpactLongMiniEN.png');
const proteinImpactShortMiniEN = require('../../../assets/proteinImpactShortMiniEN.png');
const dietMiniEN = require('../../../assets/dietMiniEN.png');
const breadImpactMiniEN = require('../../../assets/breadImpactMiniEN.png');

const RessourcesAlim = (props) => {
    const { t } = useTranslation();
    const getPath = () => {
        if (props.language === "fr")
            return "foods"
        else
            return "foodsEN"
    }
    const getDescription = (name) => {
        if (name === 'Impact_des_protéines.pdf' || name === "The_impact_of_proteins_Detailed_version.pdf")
            return {
                title: t('ressources.foodsFiles.proteinImpactLong'),
                desc: t('ressources.foodsFiles.proteinImpactLongDesc'),
                picture: <img alt={"proteinImpactLongMini"} src={props.language === "fr" ? proteinImpactLongMini : proteinImpactLongMiniEN} style={{ height: "62%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Impact_protéines_v.courte.pdf' || name === "The_impact_of_proteins_Short_version.pdf")
            return {
                title: t('ressources.foodsFiles.proteinImpactShort'),
                desc: t('ressources.foodsFiles.proteinImpactShortDesc'),
                picture: <img alt={"proteinImpactShortMini"} src={props.language === "fr" ? proteinImpactShortMini : proteinImpactShortMiniEN} style={{ height: "62%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Régime_alimentaires.pdf' || name === "The_impact_of_dietary_choices.pdf")
            return {
                title: t('ressources.foodsFiles.diet'),
                desc: t('ressources.foodsFiles.dietDesc'),
                picture: <img alt={"dietMini"} src={props.language === "fr" ? dietMini : dietMiniEN} style={{ height: "80%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Impact_du_pain.pdf' || name === "Reducing_Bread_Waste.pdf")
            return {
                title: t('ressources.foodsFiles.breadImpact'),
                desc: t('ressources.foodsFiles.breadImpactDesc'),
                picture: <img alt={"breadImpactMini"} src={props.language === "fr" ? breadImpactMini : breadImpactMiniEN} style={{ height: "80%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else
            return {
                title: name,
                desc: "",
                picture: <img alt={"dietMini"} src={dietMini} style={{ height: "80%", boxShadow: "0px 4px 4px #666666" }} />
            };
    }

    const downloadFile = async (file) => {
        let basePath = getPath() + '/'
        await downLoadRessourceFile(basePath + file, file);
    }
    const getRows = () => {
        let path = props.paths.find(a => a.path === getPath());
        let result = [];
        if (path && path.files) {
            let types = path.files;
            for (const type of types) {
                result.push(
                    <div style={{ width: "100%", height: "30%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "80%", height: "80%", backgroundColor: "#fff", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {getDescription(type).picture}
                            </div>
                        </div>
                        <div style={{ width: "55%" }}>
                            <div style={{ width: "100%", height: "40%", fontWeight: "bold", fontSize: "1.2vw", display: "flex", alignItems: "flex-end" }}>
                                {getDescription(type).title}
                            </div>
                            <div style={{ width: "100%", height: "60%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                {getDescription(type).desc}
                            </div>
                        </div>
                        <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <button className='custom-button-gradient'
                                style={{
                                    height: "4vh",
                                    width: "9vw",
                                    background: "#5BB3E4B3",
                                    color: "#fff"
                                }}
                                onClick={() => downloadFile(type)}>
                                {t('buttons.download')}
                            </button>
                        </div>
                    </div>
                )
            }
        }
        return result;
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <div style={{ height: "8%", width: "100%", color: "#68B4E3", fontSize: "1.4vw", display: "flex" }}>
                {t('ressources.learnWaste')}
            </div>
            <CustomBlock width={"100%"} height={"92%"} display={"flex"} flexDirection={"row"} >
                <CustomScrollDiv update={2}>
                    {getRows()}
                </CustomScrollDiv>
            </CustomBlock>
        </div>
    )
}
export default RessourcesAlim;