import React, { useEffect } from "react";
import { useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { ReactComponent as Warning } from "../../../../assets/warning.svg";
import { ReactComponent as Pen } from "../../../../assets/operationPen.svg";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import CustomButton from "../../../customComponents/CustomButton";
import CustomTextField from "../../../customComponents/CustomTextField";
import { setOperations, setSelectedOperation } from "../../../../actions_reducers/actions/operations.action";
import { getDetailsPlanningForDay, updateDailyPlanning } from "../../../../apiRoutes/dailyPlanning.routes";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const OperationsDetails = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        updatePlannings()
    }, [])
    const updatePlannings = async () => {
        let operations = JSON.parse(JSON.stringify(props.state.operations))
        let currentOperations = operations.filter(a => moment(a.date).format("YYYY-MM-DD") === props.state.selectedDate);
        let newValues = await getDetailsPlanningForDay(currentOperations);
        for (const value of newValues) {
            let index = operations.findIndex(a => a.id === value.id);
            operations[index] = value;
        }
        dispatch(setOperations(operations))
    }
    const updateSelectedOperationChecked = (value) => {
        let selectedOperation = JSON.parse(JSON.stringify(props.state.selectedOperation))
        selectedOperation.checked = value;
        dispatch(setSelectedOperation(selectedOperation))
    }
    const updateSelectedOperationCommentary = (value) => {
        let selectedOperation = JSON.parse(JSON.stringify(props.state.selectedOperation))
        selectedOperation.commentary = value;
        dispatch(setSelectedOperation(selectedOperation))
    }
    const confirmChanges = async () => {
        let operations = JSON.parse(JSON.stringify(props.state.operations));
        let index = operations.findIndex(a => a._id === props.state.selectedOperation._id);
        operations[index] = props.state.selectedOperation;
        dispatch(setOperations(operations));
        await updateDailyPlanning(props.state.selectedOperation)
        dispatch(setSelectedOperation(null));
    }
    const getModalModifyOperation = () => {
        if (props.state.selectedOperation) {
            return (
                <CustomBlock
                    position={"absolute"}
                    width={"40%"}
                    height={"55%"}
                    left={"27%"}
                    top={"15%"}
                    zIndex={10}
                >
                    <div style={{ height: "15%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {props.state.selectedOperation.restaurant_name}
                    </div>
                    <div style={{ height: "32.5%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "40%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('table.state')}
                        </div>
                        <div style={{ width: "60%", height: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "40%", backgroundColor: props.state.selectedOperation.checked && "#D9D9D9", height: "50%", borderRadius: 20, display: "flex", justifyContent: "center", alignItems: "center" }}
                                    onClick={() => updateSelectedOperationChecked(true)}>
                                    <Check className="informations-check-style" style={{ width: "100%", paddingTop: "10%" }} />
                                </div>
                            </div>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "40%", backgroundColor: !props.state.selectedOperation.checked && "#D9D9D9", height: "50%", borderRadius: 20, display: "flex", justifyContent: "center", alignItems: "center" }}
                                    onClick={() => updateSelectedOperationChecked(false)}>
                                    <Warning className="informations-warning-style" style={{ width: "72%", paddingTop: "10%" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "32.5%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "40%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('table.commentary')}
                        </div>
                        <div style={{ width: "60%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomTextField filter={"none"}
                                width={"90%"}
                                height={"30%"}
                                backgroundColor={"#D9D9D9"}
                                borderRadius={"10px"}
                                onChange={(e) => updateSelectedOperationCommentary(e.target.value)}
                                value={props.state.selectedOperation.commentary} />
                        </div>
                    </div>
                    <div style={{ height: "20%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton width={"60%"} height={"70%"} backgroundColor={"#949494"} color={"#fff"} value={t('buttons.cancel')} onClick={() => dispatch(setSelectedOperation(null))} />
                        </div>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton width={"60%"} height={"70%"} backgroundColor={"#90c261"} color={"#fff"} value={t('buttons.confirm')} onClick={() => confirmChanges()} />
                        </div>
                    </div>
                </CustomBlock>
            )
        }
    }

    const getIconType = (operation, cam) => {
        if (cam) {
            if (cam.checked === true)
                return (<Check className="informations-check-style" style={{ width: "95%", paddingTop: "5%" }} />)
            else return (<Warning className="informations-warning-style" style={{
                width: "80%", paddingTop: "5%"
            }
            } />)
        }
        else {
            if (operation.checked === true)
                return (<Check className="informations-check-style" style={{ width: "58%", paddingTop: "10%" }} />)
            else return (<Warning className="informations-warning-style" style={{
                width: "50%", paddingTop: "10%"
            }
            } />)
        }

    }
    const unShowedRestaurants = [
        "CREIL",
        "DOUMERC",
        "FOCH",
        "LAGOUBRAN",
        "RA54",
        "MANTOUX"
    ]
    const getOperationsRows = () => {
        let result = [];
        let currentOperations = JSON.parse(JSON.stringify(props.state.operations)).filter(a => moment(a.date).format("YYYY-MM-DD") === props.state.selectedDate);

        if (currentOperations && currentOperations.length > 0) {
            let restaurants = JSON.parse(JSON.stringify(props.informations)).filter(a => a.clientName === props.state.selectedClient)
            if (process.env.REACT_APP_ENV_TYPE === "aws")
                currentOperations = currentOperations.filter(a => !unShowedRestaurants.find(b => b === a.restaurant_name))
            if (props.state.selectedClient !== null)
                currentOperations = currentOperations.filter(a => restaurants.find(b => b.restaurantName === a.restaurant_name))
            currentOperations.sort((a, b) => a.restaurant_name.localeCompare(b.restaurant_name));
            let i = 0;
            for (const [index, operation] of currentOperations.entries()) {
                let currentRestaurant = props.informations.find(a => a.restaurantName === operation.restaurant_name)
                let averageTrays = currentRestaurant.averageTrays;
                let percentage = (averageTrays && averageTrays !== 0) ? parseInt(parseInt(operation.nbPicturesUploaded) / 2 / parseInt(averageTrays) * 100) : 0;
                if (currentRestaurant.hasRestaurant) {
                    result.push(
                        <div style={{ width: "100%", height: "15%", display: "flex", flexDirection: "row", color: "#666666", backgroundColor: i % 2 === 0 && "#f0f0f0" }}>
                            <div style={{ width: "18%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {operation.restaurant_name}
                            </div>
                            <div style={{ width: "13%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {percentage + " %"}
                            </div>
                            <div style={{ width: "13%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {operation.uploadingStatus}
                            </div>
                            <div style={{ width: "11%" }}>
                                {
                                    operation.valueCams && operation.valueCams.length > 0 && operation.valueCams.map((cam) => {
                                        return (
                                            <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {cam.nameCam}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ width: "13%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                                {
                                    operation.valueCams && operation.valueCams.length > 0 ? operation.valueCams.map((cam) => {
                                        return (
                                            <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {cam.trays}
                                            </div>)
                                    })
                                        :
                                        <>{(parseInt(operation.nbPicturesUploaded) / 2).toFixed(0)}</>

                                }
                            </div>
                            <div style={{ width: "13%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                {
                                    operation.valueCams && operation.valueCams.length > 0 ? operation.valueCams.map((cam) => {
                                        return (
                                            <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {cam.checked ? "volume_done" : "to_process"}
                                            </div>)
                                    })
                                        :
                                        <>{operation.inferenceStatus}</>

                                }
                            </div>
                            <div style={{ width: "13%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                                onClick={() => dispatch(setSelectedOperation(operation))}>
                                {operation.commentary ?
                                    operation.commentary :
                                    <Pen width={"50%"} height={"50%"} />}
                            </div>
                            <div style={{ width: "6%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                {
                                    operation.valueCams && operation.valueCams.length > 0 ? operation.valueCams.map((cam) => {
                                        return (
                                            <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                {getIconType(operation, cam)}
                                            </div>)
                                    })
                                        :
                                        <>{getIconType(operation)}</>

                                }
                            </div>
                        </div>
                    )
                    i += 1
                }
            }
        }
        return result;
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}
            paddingTop={"1%"}
            paddingBottom={"1%"}>
            {getModalModifyOperation()}
            <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row", color: "#666666", fontWeight: "bold", fontSize: "1.1vw" }}>
                <div style={{ width: "18%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {t('table.restaurant')}
                </div>
                <div style={{ width: "13%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    {t('table.realForAverage')}
                </div>
                <div style={{ width: "13%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {t('table.status')}
                </div>
                <div style={{ width: "11%" }}>

                </div>
                <div style={{ width: "13%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    {t('table.nbUploaded')}
                </div>
                <div style={{ width: "13%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    {t('table.imageStatus')}
                </div>
                <div style={{ width: "13%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    {t('table.opsState')}
                </div>
                <div style={{ width: "6%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                </div>
            </div>
            <div style={{ height: "90%" }}>
                <CustomScrollDiv update={Math.random()}>
                    {getOperationsRows()}
                </CustomScrollDiv>

            </div>
        </CustomBlock>
    )
}

export default OperationsDetails;
