import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { useNavigate } from "react-router-dom";
import SideBar from '../navigationBars/SideBar';
import TopBar from '../navigationBars/TopBar';
import ApplicationContainer from "./ApplicationContainer";
import { setArianne, setCurrentPage } from "../../actions_reducers/actions/navigation.action";
import { setHasChangedPassword, softResetUser } from "../../actions_reducers/actions/user.action";
import { setSelectedCategory, setSelectedDay, setShowedAnalysis } from "../../actions_reducers/actions/dashboard.action";
import { setSelectedPageRessources } from "../../actions_reducers/actions/ressources.action";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { useState } from "react";
import { ReactComponent as Question } from "../../assets/questionButton.svg";
import backgroundImage from "../../assets/Back_Connexion.svg";
import CustomTextField from "../customComponents/CustomTextField";
import CustomButton from "../customComponents/CustomButton";
import { createNewPassword } from "../../apiRoutes/account.route";

const ConsoleContainer = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dropdownRef = useRef(null);
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [helpOpen, setHelpOpen] = useState(false);

    const changeHelpOpen = () => setHelpOpen(!helpOpen)

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setHelpOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const moveThroughArianne = (value) => {
        let index = props.arianne.findIndex(a => a.value === value);
        let newArianne = JSON.parse(JSON.stringify(props.arianne));
        newArianne.length = index;
        let newValue = newArianne[newArianne.length - 1].value;
        if (props.arianne[0].value === "dashboard") {
            if (newValue === 'dashboard') {
                dispatch(softResetUser());
                dispatch(setSelectedCategory(null));
                dispatch(setSelectedDay(null));
                dispatch(setShowedAnalysis(null));
            }
            else if (newValue === 'detailedAnalysis') {
                dispatch(setSelectedCategory(null));
                dispatch(setSelectedDay(null));
            }
        }
        else if (props.arianne[0].value === "ressources") {
            dispatch(setSelectedPageRessources(null))
        }
        dispatch(setArianne(newArianne));
        dispatch(setCurrentPage(newValue))

    }
    window.onpopstate = () => {
        let arianne = JSON.parse(JSON.stringify(props.arianne));
        if (arianne.length > 1) {
            let value = arianne[arianne.length - 1].value;

            moveThroughArianne(value)
        }
        navigate("/clientConsole");
    }
    useEffect(() => {
        if (!props.userState.is_logged_in)
            navigate('/');
    })

    const getHelp = () => {
        return (
            <>
                {helpOpen &&
                    <div ref={dropdownRef} style={{ height: "22vh", width: "28vw", position: "absolute", bottom: "5%", left: "1%", zIndex: 1000000000000000000 }}>
                        <div style={{ height: "20vh", width: "100%", display: "flex" }}>
                            {helpOpen &&
                                <div style={{ marginLeft: "7%", marginTop: "-2%", width: "100%", height: "100%", backgroundColor: "#fff", borderRadius: "15px", padding: "2%", boxShadow: "0px 4px 4px 0px #00000040" }}>
                                    <div style={{ height: "40%", width: "100%", paddingLeft: "2%", paddingTop: "1%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "left", fontWeight: "bold", color: "#666666" }}>
                                        {t('components.helpDescription')}
                                    </div>
                                    <div style={{ height: "30%", width: "100%", paddingLeft: "2%", paddingBottom: "2%", display: "flex", alignItems: "center", color: "#666666" }}>
                                        {t('components.byMail')}
                                    </div>
                                    <div style={{ height: "30%", width: "100%", paddingLeft: "2%", paddingBottom: "2%", display: "flex", alignItems: "center", color: "#666666" }}>

                                        {t('components.byPhone')}
                                    </div>
                                </div>}
                        </div>

                    </div>}
                <div style={{ height: "5vh", width: "5vh", position: "absolute", bottom: "0%", left: "1%", zIndex: 1000000000000000000 }}>
                    <Question style={{ cursor: "pointer", fill: "#fff" }} onClick={() => changeHelpOpen()} />
                </div>
            </>
        )
    }
    const confirmUpdatePassword = async () => {
        if (newPassword === confirmNewPassword) {
            await createNewPassword(props.userState.login, newPassword);
            dispatch(setHasChangedPassword(true));
        }
        else {
            window.alert(t('errors.wrongPasswords'))
        }
    }

    if (props.userState.hasChangedPassword)
        return (
            <div className="main-div">
                <SideBar current_page={props.current_page} arianne={props.arianne} />
                <TopBar />
                <ApplicationContainer />
            </div >
        )
    else
        return (
            <div className="main-div">
                <div className='svg-div'>
                    <img
                        className='svg-img'
                        src={backgroundImage}
                        alt="KikleoWelcomeImage"
                    />
                </div>
                <div className='form-div'>
                    <div
                        className="justify-content-md-center"
                        style={{ paddingLeft: "3px", paddingBottom: "0px" }}
                    >
                        <div className='connexion-title-div' style={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            <h1 className='connexion-title'> {t('components.welcome')} </h1> {/* title */}
                            <p className='connexion-subtitle' style={{ width: "70%" }}>
                                {t('components.firstConnexion', { email: props.userState.login })}
                            </p>
                        </div>
                        <div style={{ height: "30%" }} />
                        <div style={{ height: "25%", width: "80%", marginLeft: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {t('components.newPassword')}
                            </div>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomTextField filter={"none"}
                                    width={"100%"}
                                    height={"70%"}
                                    backgroundColor={"#D9D9D9"}
                                    borderRadius={"5px"}
                                    value={newPassword}
                                    type={"password"}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    paddingLeft={'3%'}
                                    paddingRight={'3%'} />
                            </div>
                        </div>
                        <div style={{ height: "10%" }} />
                        <div style={{ height: "25%", width: "80%", marginLeft: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {t('buttons.confirmPassword')}
                            </div>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomTextField filter={"none"}
                                    width={"100%"}
                                    height={"70%"}
                                    backgroundColor={"#D9D9D9"}
                                    borderRadius={"5px"}
                                    value={confirmNewPassword}
                                    type={"password"}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    paddingLeft={'3%'}
                                    paddingRight={'3%'} />
                            </div>
                        </div>
                        <div style={{ height: "15%" }} />
                        <div style={{ height: "25%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton
                                backgroundColor={"#90c261"}
                                fontSize={"clamp(1rem, 1.4vw, 5rem)"}
                                width={"40%"}
                                height={'100%'}
                                color={"#fff"} value={t('buttons.confirm')} onClick={() => confirmUpdatePassword()} />
                        </div>
                    </div>
                </div>
            </div >
        )
}

function mapStateToProps(state) {
    return {
        userState: state.userReducer,
        current_page: state.navigationReducer.current_page,
        arianne: state.navigationReducer.arianne
    };
}

export default connect(mapStateToProps)(ConsoleContainer);
