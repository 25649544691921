const initialState = {
    tasks: [],
    tasksClient: [],
    selectedTaskType: "all",
    addTaskOpen: false,
    isTaskSelected: false,
    selectedClientDropdown: null,
    selectedRestaurantDropdown: null,
    selectedTaskTypeDropdown: "all",
    selectedRestaurant: null,
    analysisType: "Classique",
    type: null,
    analysisNumber: null,
    assessmentNumber: null,
    hasSaturday: false,
    hasSunday: false,
    dateStart: null,
    dateEnd: null,
    hourStart: null,
    hourEnd: null,
    oldDateStart: null,
    oldDateEnd: null,
    oldHourStart: null,
    oldHourEnd: null
}


const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_TASK_TYPE':
            return {
                ...state,
                selectedTaskType: action.taskType
            }
        case 'SET_TASKS':
            return {
                ...state,
                tasks: action.tasks
            }
        case 'SET_TASKS_CLIENT':
            return {
                ...state,
                tasksClient: action.tasksClient
            }
        case 'SET_ADD_TASK_OPEN':
            return {
                ...state,
                addTaskOpen: action.addTaskOpen
            }
        case 'SET_IS_TASK_SELECTED':
            return {
                ...state,
                isTaskSelected: action.isTaskSelected
            }
        case 'SET_SELECTED_CLIENT_DROPDOWN_PLANNING':
            return {
                ...state,
                selectedClientDropdown: action.selectedClientDropdown
            }
        case 'SET_SELECTED_RESTAURANT_DROPDOWN_PLANNING':
            return {
                ...state,
                selectedRestaurantDropdown: action.selectedRestaurantDropdown
            }
        case 'SET_SELECTED_TASK_TYPE_DROPDOWN_PLANNING':
            return {
                ...state,
                selectedTaskTypeDropdown: action.selectedTaskTypeDropdown
            }
        case 'SET_SELECTED_RESTAURANT_PLANNING':
            return {
                ...state,
                selectedRestaurant: action.selectedRestaurant
            }
        case 'SET_ANALYSIS_TYPE_PLANNING':
            return {
                ...state,
                analysisType: action.analysisType
            }
        case 'SET_TYPE_PLANNING':
            return {
                ...state,
                type: action.typePlanning
            }
        case 'SET_ANALYSIS_NUMBER_PLANNING':
            return {
                ...state,
                analysisNumber: action.analysisNumber
            }
        case 'SET_ASSESSMENT_NUMBER':
            return {
                ...state,
                assessmentNumber: action.assessmentNumber
            }
        case 'SET_HAS_SATURDAY_PLANNING':
            return {
                ...state,
                hasSaturday: action.hasSaturday
            }
        case 'SET_HAS_SUNDAY_PLANNING':
            return {
                ...state,
                hasSunday: action.hasSunday
            }
        case 'SET_DATE_START_PLANNING':
            return {
                ...state,
                dateStart: action.dateStart
            }
        case 'SET_DATE_END_PLANNING':
            return {
                ...state,
                dateEnd: action.dateEnd
            }
        case 'SET_HOUR_START_PLANNING':
            return {
                ...state,
                hourStart: action.hourStart
            }
        case 'SET_HOUR_END_PLANNING':
            return {
                ...state,
                hourEnd: action.hourEnd
            }
        case 'SET_OLD_DATE_START':
            return {
                ...state,
                oldDateStart: action.oldDateStart
            }
        case 'SET_OLD_DATE_END':
            return {
                ...state,
                oldDateEnd: action.oldDateEnd
            }
        case 'SET_OLD_HOUR_START':
            return {
                ...state,
                oldHourStart: action.oldHourStart
            }
        case 'SET_OLD_HOUR_END':
            return {
                ...state,
                oldHourEnd: action.oldHourEnd
            }
        case 'HARD_RESET_PLANNING':
            return initialState;
        default:
            return state;
    }
}

export default adminReducer;