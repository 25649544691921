const getValues = (analysis, kitchenAnalysis, trays) => {
    let sorted = ((1 - trays.nonOrga / analysis.trays) * 100).toFixed(0);
    let empty = (trays.empty / analysis.trays * 100).toFixed(0);
    let capted = ((trays.trays / analysis.trays) * 100).toFixed(0);


    let mass = parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
    let traysTmp = parseInt(analysis.trays);

    let massKitchen = parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
    let traysKitchen = parseInt(kitchenAnalysis.trays);

    let massConv = traysTmp > 0 ? parseFloat(mass) / parseInt(traysTmp) : 0;
    let massConvKitchen = traysKitchen > 0 ? parseFloat(massKitchen) / parseFloat(traysKitchen) : 0;
    let ratioKitchen = (((massConvKitchen) / ((massConvKitchen) + massConv)) * 100).toFixed(1);
    let ratioTrays = ((massConv / (massConv + (massConvKitchen))) * 100).toFixed(1);

    return {
        massConv: massConv,
        massConvKitchen: massConvKitchen,
        ratioKitchen: ratioKitchen,
        ratioTrays: ratioTrays,
        sorted: sorted,
        empty: empty,
        capted: capted
    }
}
export const getRepartitionSlide = (pres, t, analysisNumber, analysis, kitchenAnalysis, trays, x) => {
    let slide = pres.addSlide();
    let values = getValues(analysis, kitchenAnalysis, trays);

    slide.addImage({ path: require('../assets/3/header.png'), x: "0%", y: "4%", w: "51%", h: "21%" })
    slide.addImage({ path: require('../assets/3/cards_bg.png'), x: "5%", y: "53%", w: "28%", h: "30%" })
    slide.addImage({ path: require('../assets/3/cards_bg.png'), x: "36%", y: "53%", w: "28%", h: "30%" })
    slide.addImage({ path: require('../assets/3/cards_bg.png'), x: "67%", y: "53%", w: "28%", h: "30%" })
    slide.addImage({ path: require('../assets/repartition/recycle.png'), x: "23%", y: "54%", w: "8%", h: "10.5%" })
    slide.addImage({ path: require('../assets/repartition/plate.png'), x: "54%", y: "52%", w: "9%", h: "15%" })
    slide.addImage({ path: require('../assets/repartition/check.png'), x: "86%", y: "53%", w: "8%", h: "13%" })
    slide.addImage({ path: require('../assets/3/logo_bg.png'), x: "0%", y: "86%", w: "15%", h: "14%" })
    slide.addImage({ path: require('../assets/1/logoNoText.png'), x: "1%", y: "89%", w: "11%", h: "9%" })


    slide.addText(t("synthesis.global.analysisrecap", { analysisNumber: analysisNumber }), { x: "2.5%", y: "12%", w: "30%", h: "5%",align:"left", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 25, bold: true });

    slide.addText(t("synthesis.repartition.traysWaste"), { x: "11%", y: "33%", w: values.ratioTrays * 78 / 100 + "%", h: "4%", align: "center", fontFace: 'Muli',  color: '666666', fontSize: 18, bold: true });
    slide.addText(t("synthesis.repartition.kitchenWaste"), { x: values.ratioTrays * 78 / 100 + 11 + "%", y: "33%", w: values.ratioKitchen * 78 / 100 + "%", h: "4%", align: "center", fontFace: 'Muli',  color: '666666', fontSize: 18, bold: true });
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
        x: "11%", y: "38%", w: values.ratioTrays * 78 / 100 + "%", h: "4%", fill: "90c261", rectRadius: 0.05,
        shadow: {
            blur: 5,
            offset: 0.5,
            color: "000000",
            opacity: .25
        }
    });
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
        x: values.ratioTrays * 78 / 100 + 11 + "%", y: "38%", w: values.ratioKitchen * 78 / 100 + "%", h: "4%", fill: "54b0e3", rectRadius: 0.05,
        shadow: {
            blur: 5,
            offset: 0.5,
            color: "000000",
            opacity: .25
        }
    });
    slide.addText(t("synthesis.repartition.percentWaste", { percent: values.ratioTrays, mass: t('mass', { value: { mass: values.massConv, unit: 'g', fixed: 0 } }) }), { x: "11%", y: "43%", w: values.ratioTrays * 78 / 100 + "%", h: "4%", align: "center", fontFace: 'Muli',  color: '666666', fontSize: 18 });
    slide.addText(t("synthesis.repartition.percentWaste", { percent: values.ratioKitchen, mass: t('mass', { value: { mass: values.massConvKitchen, unit: 'g', fixed: 0 } }) }), { x: values.ratioTrays * 78 / 100 + 11 + "%", y: "43%", w: values.ratioKitchen * 78 / 100 + "%", h: "4%", align: "center", fontFace: 'Muli',  color: '666666', fontSize: 18 });

    slide.addText(t("synthesis.repartition.percent", { value: values.sorted }), { x: "5.5%", y: "57%", w: "27%", h: "5%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 30, bold: true })
    slide.addText(t("synthesis.repartition.percent", { value: values.empty }), { x: "36.5%", y: "57%", w: "27%", h: "5%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 30, bold: true })
    slide.addText(t("synthesis.repartition.percent", { value: values.capted }), { x: "67.5%", y: "57%", w: "27%", h: "5%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 30, bold: true })

    slide.addText(t("synthesis.repartition.sortedTrays"), { x: "5.5%", y: "65%", w: "27%", h: "5%", align: "center", fontFace: 'Muli',  color: '666666', fontSize: 18, bold: true })
    slide.addText(t("synthesis.repartition.emptyTrays"), { x: "36.5%", y: "65%", w: "27%", h: "5%", align: "center", fontFace: 'Muli',  color: '666666', fontSize: 18, bold: true })
    slide.addText(t("synthesis.repartition.captedTrays"), { x: "67.5%", y: "65%", w: "27%", h: "5%", align: "center", fontFace: 'Muli',  color: '666666', fontSize: 18, bold: true })

    slide.addText(t("synthesis.repartition.sortedDesc"), { x: "5.5%", y: "73%", w: "27%", h: "5%", align: "center", fontFace: 'Muli',  color: '666666', fontSize: 14 })
    slide.addText(t("synthesis.repartition.emptyDesc"), { x: "36.5%", y: "73%", w: "27%", h: "5%", align: "center", fontFace: 'Muli',  color: '666666', fontSize: 14 })
    slide.addText(t("synthesis.repartition.captedDesc"), { x: "67.5%", y: "73%", w: "27%", h: "5%", align: "center", fontFace: 'Muli',  color: '666666', fontSize: 14 })

    slide.addText(x, { x: "97%", y: "96%", w: "3%", h: "3%", fontFace: 'Muli', align: "right",  color: '666666', fontSize: 15, bold: false });
    slide.background = { color: "#F8F8F3" }

    return pres;
}
