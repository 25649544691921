

export const getFirstSlide = (pres, t, analysisNumber, date,displayName) => {
    let slide = pres.addSlide();
    let month = t('date', { "value": { "date": date, "format": "MMMM" } });
    let monthFinal = month.charAt(0).toUpperCase() + month.slice(1)
    slide.addImage({ path: require('../assets/1/left_corner.png'), x: 0, y: 0, w: "40%", h: "35%" })
    slide.addImage({ path: require('../assets/1/right_corner.png'), x: "72%", y: "82%", w: "28%", h: "18%" })
    slide.addImage({ path: require('../assets/1/simple_logo.png'), x: "38%", y: "17%", w: "25%", h: "45%" })
    slide.addImage({ path: require('../assets/1/header.png'), x: "30%", y: "60%", w: "39%", h: "14%" })
    slide.addText(t('synthesis.global.analysisrecap', { analysisNumber: analysisNumber }), { x: "37%", y: "64%", w: "25%", h: "5%", align: "center", fontFace: 'Muli', color: '#FFFFFF', fontSize: 19, bold: true });
    slide.addText(displayName, { x: "32%", y: "78%", w: "35%", h: "5%", align: "center", fontFace: 'Muli', color: '#54b0e3', fontSize: 30, bold: true });
    slide.addText(monthFinal, { x: "89.5%", y: "3%", w: "10%", align: "right", h: "5%", fontFace: 'Muli', color: "#666666", fontSize: 17, bold: true });
    slide.addText(t('date', { "value": { "date": date, "format": "YYYY" } }), { x: "89.5%", y: "7%", w: "10%", align: "right", h: "5%", fontFace: 'Muli', color: "#666666", fontSize: 17, bold: true });
    slide.background = { color: "#F8F8F3" }

    return pres;
}