import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/dailyPlanning`;

export function createDailyPlanning(planning) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/createDailyPlanning", {
                params: { planning: planning }
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function deleteDailyPlanning(planning) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/deleteDailyPlanning", {
                planning: planning
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}

export function getDailyPlanning(date) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/dailyPlanning", {
                params: { date: date }
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}

export function getAllPlanningStatus() {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/dailyPlanningStatus")
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}
export function getDetailsPlanningForDay(plannings) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/getDetailsPlanningForDay", { params: { plannings: plannings } })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}
export function getDetailsWeighInsForDay(plannings) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/getDetailsWeighInsForDay", { params: { plannings: plannings } })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}

export function updateDailyPlanning(dailyPlanning) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateDailyPlanning", {
                dailyPlanning: dailyPlanning
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function updateDailyPlannings(restaurant_name, dateStart, dateEnd, oldDateStart, oldDateEnd) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/updateDailyPlannings", {
                params: {
                    restaurant_name: restaurant_name,
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                    oldDateStart: oldDateStart,
                    oldDateEnd: oldDateEnd,
                }
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}