const initialState = {
    dateStart: null,
    dateEnd: null,
    aggregate: "day",
    selectedFoods: [],
    selectedCategories: [],
    selectedGraphValue: 'mass',
    selectedRoom: "total",
    selectedService: "total",
    selectedCam: "total",
    cumulativeTotal: false
}


const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DATE_START_SEARCH':
            return {
                ...state,
                dateStart: action.dateStart
            }
        case 'SET_DATE_END_SEARCH':
            return {
                ...state,
                dateEnd: action.dateEnd
            }
        case 'SET_CUMULATIVE_TOTAL':
            return {
                ...state,
                cumulativeTotal: action.cumulativeTotal
            }
        case 'SET_AGGREGATE_SEARCH':
            return {
                ...state,
                aggregate: action.aggregate
            }
        case 'SET_SELECTED_FOODS_SEARCH':
            return {
                ...state,
                selectedFoods: action.selectedFoods
            }
        case 'SET_SELECTED_CATEGORIES_SEARCH':
            return {
                ...state,
                selectedCategories: action.selectedCategories
            }
        case 'SET_SELECTED_GRAPH_VALUE_SEARCH':
            return {
                ...state,
                selectedGraphValue: action.selectedGraphValue
            }
        case 'SET_SELECTED_ROOM_SEARCH':
            return {
                ...state,
                selectedRoom: action.selectedRoom
            }
        case 'SET_SELECTED_SERVICE_SEARCH':
            return {
                ...state,
                selectedService: action.selectedService
            }
        case 'SET_SELECTED_CAM_SEARCH':
            return {
                ...state,
                selectedCam: action.selectedCam
            }
        case 'HARD_RESET_SEARCH':
            return initialState;
        default:
            return state;
    }
}

export default searchReducer;