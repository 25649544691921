export function setClientName(clientName) {
    return {
        type: 'SET_CLIENT_NAME',
        clientName: clientName
    }
}
export function setLoadingClient(loadingClient) {
    return {
        type: 'SET_LOADING_CLIENT',
        loadingClient: loadingClient
    }
}

export function setSelectedAnalyzesExcelClient(selectedAnalyzesExcel) {
    return {
        type: 'SET_SELECTED_ANALYZES_EXCEL_CLIENT',
        selectedAnalyzesExcel: selectedAnalyzesExcel
    }
}

export function setClientAnalyzes(clientAnalyzes) {
    return {
        type: 'SET_CLIENT_ANALYZES',
        clientAnalyzes: clientAnalyzes
    }
}

export function setClientAnalyzesDates(clientAnalyzesDates) {
    return {
        type: 'SET_CLIENT_ANALYZES_DATES',
        clientAnalyzesDates: clientAnalyzesDates
    }
}

export function setDataTypeClient(dataTypeClient) {
    return {
        type: "SET_DATA_TYPE_CLIENT",
        dataTypeClient: dataTypeClient
    }
}

export function setHasLoadedClient(has_loaded_client) {
    return {
        type: 'SET_HAS_LOADED_CLIENT',
        has_loaded_client: has_loaded_client
    }
}

export function setSelectedAnalysisNumberClient(selectedAnalysisNumber) {
    return {
        type: "SET_SELECTED_ANALYSIS_NUMBER_CLIENT",
        selectedAnalysisNumber: selectedAnalysisNumber
    }
}

export function setSelectedRestaurantsClient(selectedRestaurants) {
    return {
        type: "SET_SELECTED_RESTAURANTS_CLIENT",
        selectedRestaurants: selectedRestaurants
    }
}

export function setSelectedRestaurantClient(selectedRestaurantClient) {
    return {
        type: "SET_SELECTED_RESTAURANT_CLIENT",
        selectedRestaurantClient: selectedRestaurantClient
    }
}

export function setSelectedAnalysisNumberClientRestaurant(selectedAnalysisNumberClientRestaurant) {
    return {
        type: "SET_SELECTED_ANALYSIS_NUMBER_CLIENT_RESTAURANT",
        selectedAnalysisNumberClientRestaurant: selectedAnalysisNumberClientRestaurant
    }
}

export function setAnalysisNumbersList(analysisNumbersList) {
    return {
        type: "SET_ANALYSIS_NUMBERS_LIST",
        analysisNumbersList: analysisNumbersList
    }
}
export function setExtrapolationValueClient(extrapolationValue) {
    return {
        type: "SET_EXTRAPOLATION_VALUE_CLIENT",
        extrapolationValue: extrapolationValue
    }
}

export function setClientExtrapolatedAnalyzes(clientExtrapolatedAnalyzes) {
    return {
        type: "SET_CLIENT_EXTRAPOLATED_ANALYZES",
        clientExtrapolatedAnalyzes: clientExtrapolatedAnalyzes
    }
}
export function setSelectedRestaurantPictures(selectedRestaurantPictures) {
    return {
        type: "SET_SELECTED_RESTAURANT_PICTURES",
        selectedRestaurantPictures: selectedRestaurantPictures
    }
}
export function setSelectedRestaurantAnalysisPictures(selectedRestaurantAnalysisPictures) {
    return {
        type: "SET_SELECTED_RESTAURANT_ANALYSIS_PICTURES",
        selectedRestaurantAnalysisPictures: selectedRestaurantAnalysisPictures
    }
}
export function setSelectedPicturesDate(selectedPicturesDate) {
    return {
        type: "SET_SELECTED_PICTURES_DATE",
        selectedPicturesDate: selectedPicturesDate
    }
}

export function setPicturesListClient(picturesListClient) {
    return {
        type: "SET_PICTURES_LIST_CLIENT",
        picturesListClient: picturesListClient
    }
}

export function setClientKitchenAnalyzes(clientKitchenAnalyzes) {
    return {
        type: "SET_CLIENT_KITCHEN_ANALYZES",
        clientKitchenAnalyzes: clientKitchenAnalyzes
    }
}

export function setClientKitchenExtrapolatedAnalyzes(clientKitchenExtrapolatedAnalyzes) {
    return {
        type: "SET_CLIENT_KITCHEN_EXTRAPOLATED_ANALYZES",
        clientKitchenExtrapolatedAnalyzes: clientKitchenExtrapolatedAnalyzes
    }
}

export function setRestaurantListDataType(restaurantListDataType) {
    return {
        type: 'SET_RESTAURANT_LIST_DATA_TYPE',
        restaurantListDataType: restaurantListDataType
    }
}
export function setDisplayNames(displayNames) {
    return {
        type: 'SET_DISPLAY_NAMES',
        displayNames: displayNames
    }
}
export function setHasAnalyzesClient(hasAnalyzes) {
    return {
        type: 'SET_HAS_ANALYZES_CLIENT',
        hasAnalyzes: hasAnalyzes
    }
}
export function hardResetClient() {
    return {
        type: 'HARD_RESET_CLIENT'
    }
}