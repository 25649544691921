import React from "react";
import './ArianneThread.css';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSelectedCategory, setSelectedDay, setShowedAnalysis } from "../../actions_reducers/actions/dashboard.action";
import { setArianne, setCurrentPage } from "../../actions_reducers/actions/navigation.action";
import { ReactComponent as ArrowDown } from "../../assets/arrowdown2.svg";
import { softResetUser } from "../../actions_reducers/actions/user.action";
import { setSelectedPageRessources } from "../../actions_reducers/actions/ressources.action";

const allArianne = [
    { title: 'clientList', thread: ['clientList', 'addClient', 'addRestaurant', 'settings', 'accounts', 'datas', 'account', 'demoAccounts', 'clientSettings'] },
    { title: 'clientDashboard', thread: ['clientDashboard', 'clientCompare', 'clientPictures', 'clientDetailed', 'account'] },
    { title: 'dashboard', thread: ['dashboard', 'datasRestaurant', 'dashboard', 'detailedAnalysis', 'day', 'account', 'datasRestaurant'] },
    { title: 'files', thread: ['files', 'account'] },
    { title: 'mails', thread: ['mails'] },
    { title: 'search', thread: ['search', 'account'] },
    { title: 'planning', thread: ['planning', 'account'] },
    { title: 'answers', thread: ['answers', 'account'] },
    { title: 'ressources', thread: ['ressources', 'impact', 'alim', 'gaspi', 'service', 'kikleo', 'account'] },
];
const checkArianne = (value, arianne) => {
    let arianneIndex = allArianne.findIndex(a => (arianne[0] && a.title === arianne[0].value) && a.thread.includes(value))
    if (arianneIndex !== -1 && arianne[0] && arianne[0].value === allArianne[arianneIndex].thread[0] && !arianne.find(a => a.value === value))
        return true;
    else return false;
}
export const updateArianneThread = (value, arianne, dispatch, complementValue) => {
    let newArianne = JSON.parse(JSON.stringify(arianne));
    if (checkArianne(value, arianne)) {
        newArianne.push({ value: value, complementValue: complementValue });
    }
    else newArianne = [{ value: value, complementValue: complementValue }];
    dispatch(setArianne(newArianne));
}


const ArianneThread = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const moveThroughArianne = (value) => {
        let index = props.arianne.findIndex(a => a.value === value);
        let newArianne = JSON.parse(JSON.stringify(props.arianne));
        newArianne.length = index + 1;
        if (props.arianne[props.arianne.length - 1].value === "account") {
            dispatch(setCurrentPage(props.arianne[props.arianne.length - 2].value))
        }
        if (props.arianne[0].value === "dashboard") {
            if (value === 'dashboard') {
                dispatch(setCurrentPage(value))
                dispatch(softResetUser());
                dispatch(setSelectedCategory(null));
                dispatch(setSelectedDay(null));
                dispatch(setShowedAnalysis(null));
            }
            else if (value === 'detailedAnalysis') {
                dispatch(setSelectedCategory(null));
                dispatch(setSelectedDay(null));
            }
        }
        else if (props.arianne[0].value === "ressources") {
            dispatch(setSelectedPageRessources(null))
        }
        else if (props.arianne[0].value === "client") { console.log("client") }
        else if (props.arianne[0].value === "clientList") {
            dispatch(setCurrentPage(value))
        }
        dispatch(setArianne(newArianne));

    }
    const getThread = () => {
        let result = [];
        for (const [index, arianne] of props.arianne.entries()) {
            result.push(
                <div
                    key={"arianne-" + arianne.value}
                    id={arianne.value} className="arianne-sub-div" onClick={(e) => moveThroughArianne(e.target.id)}>
                    <span
                        id={arianne.value}
                        key={index}
                        style={{
                            fontSize: index === props.arianne.length - 1 ? "1.4vw" : "1.1vw",
                            zIndex: 2000
                        }}
                    >{t(`arianne.${arianne.value}`, { complementValue: arianne.complementValue })}</span>
                    {
                        index !== props.arianne.length - 1 &&
                        <ArrowDown className="arianne-arrow" />
                    }
                </div>
            )
        }
        return result;
    }
    return (
        <div className="arianne-div">
            {getThread()}
        </div>
    )
}

export default ArianneThread;