import moment from 'moment';
import { addHorizontalCatsBars, getSensiSvgs, getSensiValues, getSensiValuesCommodity } from '../SensiFunctions';
import { getCatValues, getCo2Eq, getTravelValues } from '../SynthesisFunctions';


const getTravel = (values) => {
    let number = 0;
    let type = "";
    let travel = "";
    let name = ""
    if (values.planeNumber > 1) {
        number = values.planeNumber;
        type = values.planeType;
        travel = values.planeTravel;
        name = "plane"
    }
    else if (values.carNumber > 1) {
        number = values.carNumber;
        type = values.carType;
        travel = values.carTravel;
        name = "car"
    }
    else {
        number = values.trainNumber;
        type = values.trainType;
        travel = values.trainTravel;
        name = "train"
    }
    return {
        number: number,
        type: type,
        travel: travel,
        name: name
    }
}

export const getNewAdultSlide = (pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, type, valuesize, restaurant_name, food_list, food_list_kitchen, costType, averageTrays, displayName) => {
    let slide = pres.addSlide();
    let values;
    if (costType === "commodity_cost") values = getSensiValuesCommodity(t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, type, food_list, food_list_kitchen, costType, averageTrays);
    else values = getSensiValues(t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, type, averageTrays);

    let travelValues = getTravelValues(values.co2);
    let travels = getTravel(travelValues);
    /*let cats = getCatValues(analysis, kitchenAnalysis, 'mass');
    addHorizontalCatsBars(pres, slide, t, cats, 'mass');*/

    let dateStart;
    let dateEnd;
    let lastDate;
    let leftSide = travels.travel.split(" - ")[0];
    let rightSide = travels.travel.split(" - ")[1];

    if (analysis) {
        dateStart = moment(analysis.dateStart).format("DD/MM/YYYY");
        dateEnd = moment(analysis.dateEnd).format("DD/MM/YYYY");
    }
    else if (kitchenAnalysis) {
        dateStart = moment(kitchenAnalysis.dateStart).format("DD/MM/YYYY");
        dateEnd = moment(kitchenAnalysis.dateEnd).format("DD/MM/YYYY");
    }
    if (previousAnalysis) {
        lastDate = moment(previousAnalysis.dateStart).format("MMMM YYYY")
    }
    else if (previousKitchenAnalysis) {
        lastDate = moment(previousKitchenAnalysis.dateStart).format("MMMM YYYY")
    }

   /* let elem1 = document.getElementById('chartToImpress ' + analysis.analysisNumber)
    let svgElement = elem1.firstChild.firstChild.firstChild
    svgElement.setAttribute('style', 'fill: #fff;');
    const s = new XMLSerializer().serializeToString(svgElement)
    slide.addImage({ data: "data:image/svg+xml;base64," + btoa(s), x: "18%", y: "76%", w: "80%", h: "22%" })*/
    return pres;
}