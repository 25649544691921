import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSelectedAnalysisNumberClientRestaurant, setSelectedRestaurantClient } from "../../../../actions_reducers/actions/client.action";

const CompareSelection = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const getColor = (index) => {
        if (index === 0) return '#68B4E3';
        else if (index === 1) return '#90C261';
        else if (index === 2) return '#EA881F';
        else if (index === 3) return '#FDD11D';
        else if (index === 4) return '#AE8FE0';
    }
    const getName = (restaurant_name) => {
        let restaurant = props.displayNames.find(a => a.restaurant_name === restaurant_name);
        if (restaurant && restaurant.value) return restaurant.value;
        else return restaurant_name;
    }
    const getSelectedRestaurants = () => {
        let result = [];
        for (const [index, restaurant] of props.selectedRestaurants.entries()) {
            result.push(
                <div id={restaurant} style={{ width: "100%", height: "20%", flexDirection: "row", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div id={restaurant} style={{
                        width: "80%", height: "70%", display: "flex", flexDirection: "row",
                        paddingLeft: "3%",
                        paddingRight: "3%",
                        backgroundColor: props.selectedRestaurant === restaurant && "#F0F0F0",
                        borderRadius: props.selectedRestaurant === restaurant && "10px",
                        cursor: "pointer"
                    }}
                        onClick={e => dispatch(setSelectedRestaurantClient(e.target.id))}>
                        <div id={restaurant} style={{ height: "100%", width: "80%", display: "flex", alignItems: "center" }}>
                            {getName(restaurant)}
                        </div>
                        <div id={restaurant} style={{ height: "100%", width: "20%", display: "flex", alignItems: "center" }}>
                            <hr id={restaurant}
                                style={{
                                    color: getColor(index),
                                    backgroundColor: getColor(index),
                                    height: "5%",
                                    width: "70%",
                                    borderRadius: "25px"
                                }}
                            />
                        </div>
                    </div>
                </div >
            )
        }
        return result;
    }
    const getAnalyzes = () => {
        let result = [];
        let list = JSON.parse(JSON.stringify(props.analysisNumbersList)).sort((a, b) => a.number - b.number);
        let numberRow = parseInt((list.length / 2).toFixed(0));
        let divs = [];
        for (var i = 0; i < list.length; i += 2) {
            divs.push(
                <div id={list[i].number} style={{ height: "100%", width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div id={list[i].number} style={{
                        height: "4vh", width: "50%", display: "flex", justifyContent: "center", alignItems: "center",
                        backgroundColor: props.selectedAnalysis === list[i].number && "#F0F0F0",
                        borderRadius: props.selectedAnalysis === list[i].number && "10px",
                        cursor: "pointer"
                    }}
                        onClick={e => dispatch(setSelectedAnalysisNumberClientRestaurant(parseInt(e.target.id)))}>
                        {list[i].date}

                    </div>
                </div>
            )
            if (list[i + 1])
                divs.push(
                    <div id={list[i + 1].number} style={{ height: "100%", width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div id={list[i + 1].number} style={{
                            height: "4vh", width: "50%", display: "flex", justifyContent: "center", alignItems: "center",
                            backgroundColor: props.selectedAnalysis === list[i + 1].number && "#F0F0F0",
                            borderRadius: props.selectedAnalysis === list[i + 1].number && "10px",
                            cursor: "pointer"
                        }}
                            onClick={e => dispatch(setSelectedAnalysisNumberClientRestaurant(parseInt(e.target.id)))}>
                            {list[i + 1].date}
                        </div>
                    </div>
                )
            result.push(
                <div style={{ flexDirection: "row", width: "100%", height: 100 / numberRow + "%", display: "flex" }}>
                    {divs}
                </div>
            )
            divs = [];

        }
        return result;
    }
    return (
        <CustomBlock width={'100%'} height={'100%'}
            paddingTop={"2%"}
            paddingBottom={"2%"}
            paddingRight={"2%"}
            paddingLeft={"2%"}>
            <div style={{ height: "15%", width: "100%", flexDirection: "row", display: "flex" }} >
                <div style={{ height: "100%", width: "50%" }} className="dashboard-block-title">
                    {t('components.selectedRestaurants')}
                </div>
                <div style={{ height: "100%", width: "50%", display: "flex", justifyContent: "flex-end" }}>
                    {props.dropdown}
                </div>

            </div>
            <div style={{ height: "85%", width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ width: "59.5%", height: "100%" }}>
                    {getSelectedRestaurants()}
                </div>

                <hr
                    style={{
                        color: "#CACACA",
                        backgroundColor: "#CACACA",
                        height: "90%",
                        width: "0.5%",
                        borderRadius: "25px"
                    }}
                />
                <div style={{ width: "39.5%" }}>
                    {getAnalyzes()}
                </div>
            </div>
        </CustomBlock>
    )
}
export default CompareSelection;