import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/connexion`;

export function connexion(email, password) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/connect", { email: email, password: password },
            )
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else {
                    window.alert(`Une erreur s'est produite : ${err}`)
                    reject('error');
                }
            })
    });
}

export function changePassword(email) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/changePassword", {
                params: { email: email },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else {
                    window.alert(`Une erreur s'est produite : ${err}`)
                    reject('error');
                }
            })
    });
}

export function checkTokenValidity(token) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/checkTokenValidity", {
                params: { token: token },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}