import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import 'moment/locale/fr';
import CustomTextField from "../../../customComponents/CustomTextField";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import { setMenusList } from "../../../../actions_reducers/actions/datas.action";
import CustomButton from "../../../customComponents/CustomButton";
import { useRef } from "react";
import CustomCheckbox from "../../../customComponents/CustomCheckbox";
import { addMenuRestaurant } from "../../../../apiRoutes/menus.routes";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const MenusList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const [dropdownAlimsOpen, setDropdownAlimsOpen] = useState(null);
    const changeDropdownAlimsOpen = (id) => {
        if (dropdownAlimsOpen == null)
            setDropdownAlimsOpen(id);
        else setDropdownAlimsOpen(null);
    };

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && event.target.id === "") {
                setDropdownAlimsOpen(null)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const getMenusValues = (type) => {
        let menus = JSON.parse(JSON.stringify(props.menus_list))
        let currentMenu = menus.find(a => moment(a.date).format("YYYY-MM-DD") === moment(props.selectedDate, "DD-MM-YYYY").format('YYYY-MM-DD'))
        let result = [];
        if (currentMenu && currentMenu.dishesList && currentMenu.dishesList.length > 0) {
            let hour = props.selectedService.charAt(0).toUpperCase() + props.selectedService.slice(1);
            let dishes = currentMenu.dishesList[0];
            let dishesHour = dishes['dishes' + hour] || dishes['dishesh' + hour];
            if (dishesHour) {
                let dishesType = dishesHour[type];
                if (dishesType) {
                    for (const dish of dishesType) {
                        result.push(dish.name)
                    }
                }
            }
        }
        if (result.length === 0) result = [""];
        return result;
    }

    const updateName = (e, type, index) => {
        let menus = JSON.parse(JSON.stringify(props.menus_list))
        let currentMenu = menus.find(a => moment(a.date).format("YYYY-MM-DD") === moment(props.selectedDate, "DD-MM-YYYY").format('YYYY-MM-DD'));
        let hour = props.selectedService.charAt(0).toUpperCase() + props.selectedService.slice(1);
        if (currentMenu) {
            let dishes = currentMenu.dishesList[0];
            let dishesHour = dishes['dishes' + hour];
            if (dishesHour) {
                let dishesType = dishesHour[type];
                if (dishesType[index])
                    dishesType[index].name = e.target.value;
                else
                    dishesType.push({ name: e.target.value, foodType: [] })
            }
            else {
                dishes['dishes' + hour] = {
                    appetizers: [],
                    mainDishes: [],
                    sideDishes: [],
                    desserts: []
                };
                dishes['dishes' + hour][type].push({ name: e.target.value, foodType: [] })
            }
        }
        else {
            let newMenu = {
                date: moment(props.selectedDate, "DD-MM-YYYY").format('YYYY-MM-DD'),
                dishesList: [{
                    camName: "Total",
                    ['dishes' + hour]: {
                        appetizers: [],
                        mainDishes: [],
                        sideDishes: [],
                        desserts: []
                    }
                }],
                restaurant_name: props.restaurant_name
            }
            newMenu.dishesList[0]['dishes' + hour][type] = [{ name: e.target.value, foodType: [] }];
            menus.push(newMenu)
        }
        dispatch(setMenusList(menus));
    }
    const addItemToDishes = (type) => {
        let menus = JSON.parse(JSON.stringify(props.menus_list))
        let currentMenu = menus.find(a => moment(a.date).format("YYYY-MM-DD") === moment(props.selectedDate, "DD-MM-YYYY").format('YYYY-MM-DD'));
        let hour = props.selectedService.charAt(0).toUpperCase() + props.selectedService.slice(1);
        let dishes = currentMenu.dishesList[0];
        let dishesHour = dishes['dishes' + hour];
        let dishesType = dishesHour[type];
        dishesType.push({ name: "", foodType: [] });
        dispatch(setMenusList(menus));

    }
    const deleteItemFromDishes = (type, index) => {
        let menus = JSON.parse(JSON.stringify(props.menus_list))
        let currentMenu = menus.find(a => moment(a.date).format("YYYY-MM-DD") === moment(props.selectedDate, "DD-MM-YYYY").format('YYYY-MM-DD'));
        let hour = props.selectedService.charAt(0).toUpperCase() + props.selectedService.slice(1);
        let dishes = currentMenu.dishesList[0];
        let dishesHour = dishes['dishes' + hour];
        let dishesType = dishesHour[type];
        dishesType.splice(index, 1);
        dispatch(setMenusList(menus));

    }


    const getDropdownFoodsValues = () => {
        let values = [];
        for (const food of props.food_list) {
            values.push(food.nameFood);
        }
        values.sort((a, b) => a.localeCompare(b));
        return values;
    }

    const checkedFood = (food, type, index) => {
        let menus = JSON.parse(JSON.stringify(props.menus_list))
        let currentMenu = menus.find(a => moment(a.date).format("YYYY-MM-DD") === moment(props.selectedDate, "DD-MM-YYYY").format('YYYY-MM-DD'));
        let hour = props.selectedService.charAt(0).toUpperCase() + props.selectedService.slice(1);
        let dishes = currentMenu.dishesList[0];
        let dishesHour = dishes['dishes' + hour] || dishes['dishesh' + hour];
        let dishesType = dishesHour[type];
        if (dishesType.length > 0) {
            let foodTmp = dishesType[index];
            if (foodTmp.foodType && foodTmp.foodType.find(a => a === food))
                return true;
            else return false;
        }
        else return false;
    }

    const updateSelectedValues = (e, index, type) => {
        let menus = JSON.parse(JSON.stringify(props.menus_list))
        let currentMenu = menus.find(a => moment(a.date).format("YYYY-MM-DD") === moment(props.selectedDate, "DD-MM-YYYY").format('YYYY-MM-DD'));
        let hour = props.selectedService.charAt(0).toUpperCase() + props.selectedService.slice(1);
        let dishes = currentMenu.dishesList[0];
        let dishesHour = dishes['dishes' + hour];
        let dishesType = dishesHour[type];
        let food = dishesType[index];
        let foodIndex = food.foodType.findIndex(a => a === e.target.id);
        if (foodIndex === -1)
            food.foodType.push(e.target.id);
        else food.foodType.splice(foodIndex, 1);
        dispatch(setMenusList(menus))
    }

    const getDropdownStudiedAlims = (type, index) => {
        return (
            <div
                ref={dropdownRef}
                className='custom-dropdown'
                id={'general-dropdown-food' + index}
                style={{
                    height: "4vh",
                    width: "80%",
                    color: "#666666",
                    backgroundColor: "#E7F3FA",
                    zIndex: dropdownAlimsOpen === (type + '-' + index) ? 100000 : 1
                }}
                onClick={() => setDropdownAlimsOpen(type + '-' + index)}>
                {t('components.selectAlims')}
                < div className="custom-dropdown-choices"
                    style={{
                        height: "35vh",
                        display: dropdownAlimsOpen === (type + '-' + index) ? 'block' : 'none',
                        backgroundColor: '#E7F3FA',
                    }}>
                    <CustomScrollDiv update={2}>
                        {getDropdownFoodsValues().map((value) => {
                            return (
                                <div key={'dropdown-food-' + value} id={value} className="custom-dropdown-choice-div"
                                    style={{ display: "flex", flexDirection: "row", height: "4.5vh", backgroundColor: "#E7F3FA", color: "#666666" }}
                                >
                                    <div id={value} style={{ width: "10%", height: "100%" }} onClick={(e) => updateSelectedValues(e, index, type)}>
                                        <CustomCheckbox width={"2vh"} height={"2vh"} checked={checkedFood(value, type, index)} id={value} onChange={(e) => updateSelectedValues(e, index, type)} />
                                    </div>
                                    <div id={value} style={{ width: "80%", height: "100%" }} onClick={(e) => updateSelectedValues(e, index, type)}>
                                        {value}
                                    </div>
                                </div>
                            )
                        })
                        }
                    </CustomScrollDiv>
                </div>
            </div >
        )
    }
    const getDishesList = (type) => {
        let values = getMenusValues(type);
        return (
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                {values.map((item, index) => {
                    return (
                        <div style={{ height: "100%", marginTop: "1%", marginBottom: "1%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "40%", height: "100%" }}>
                                <CustomTextField
                                    width={"100%"}
                                    height={"4vh"}
                                    filter={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    paddingLeft={"1%"}
                                    borderRadius={"5px"}
                                    value={item} onChange={(e) => updateName(e, type, index)} />
                            </div>

                            <div style={{ width: "15%", height: "100%", display: "flex", justifyContent: "center", marginTop: "1%" }}>
                                {index === values.length - 1 && <CustomButton width={"80%"}
                                    color={"#fff"}
                                    backgroundColor={"#666666"}
                                    value={t('buttons.add')} onClick={() => addItemToDishes(type)} />}
                            </div>

                            < div style={{ width: "15%", height: "100%", display: "flex", justifyContent: "center", marginTop: "1%" }}>
                                {values.length - 1 !== 0 && <CustomButton width={"80%"}
                                    color={"#fff"}
                                    backgroundColor={"#ECAC68"}
                                    value={t('buttons.delete')} onClick={() => deleteItemFromDishes(type, index)} />
                                }
                            </div>
                            <div style={{ width: "30%", height: "100%", display: "flex", justifyContent: "center" }}>
                                {item !== "" && getDropdownStudiedAlims(type, index)}
                            </div>
                        </div>
                    )
                })
                }
            </div >
        )
    }
    const confirmMenu = async () => {
        let menus = JSON.parse(JSON.stringify(props.menus_list))
        let currentMenu = menus.find(a => moment(a.date).format("YYYY-MM-DD") === moment(props.selectedDate, "DD-MM-YYYY").format('YYYY-MM-DD'));
        await addMenuRestaurant(currentMenu)
    }
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <div style={{ width: "100%", height: "88%" }}>
                {props.selectedDate && <CustomScrollDiv update={1}>
                    <div style={{ width: "100%", minHeight: "20%", paddingLeft: "2%", paddingRight: "2%" }}>
                        <div style={{ height: "30%", width: "100%" }}>
                            {t('components.appetizers')}
                        </div>
                        <div style={{ height: "70%", width: "100%" }}>
                            {getDishesList('appetizers')}
                        </div>
                    </div>
                    <div style={{ height: "5%" }} />
                    <div style={{ width: "100%", minHeight: "20%", paddingLeft: "2%", paddingRight: "2%" }}>
                        <div style={{ height: "30%", width: "100%" }}>
                            {t('components.mainDishes')}
                        </div>
                        <div style={{ height: "70%", width: "100%" }}>
                            {getDishesList('mainDishes')}
                        </div>
                    </div>
                    <div style={{ height: "5%" }} />
                    <div style={{ width: "100%", minHeight: "20%", paddingLeft: "2%", paddingRight: "2%" }}>
                        <div style={{ height: "30%", width: "100%" }}>
                            {t('components.sideDishes')}
                        </div>
                        <div style={{ height: "70%", width: "100%" }}>
                            {getDishesList('sideDishes')}
                        </div>
                    </div>
                    <div style={{ height: "5%" }} />
                    <div style={{ width: "100%", minHeight: "20%", paddingLeft: "2%", paddingRight: "2%" }}>
                        <div style={{ height: "30%", width: "100%" }}>
                            {t('components.desserts')}
                        </div>
                        <div style={{ height: "70%", width: "100%" }}>
                            {getDishesList('desserts')}
                        </div>
                    </div>
                </CustomScrollDiv>}

            </div>
            <div style={{ height: "12%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "1%" }}>
                <CustomButton height={"80%"} width={"20%"} backgroundColor={"#89CD85"} color={"#fff"}
                    onClick={() => confirmMenu()}>{t('buttons.confirmMenu')}</CustomButton>
            </div>
        </div>
    )
}

export default MenusList;