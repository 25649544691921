export function setCurrentPage(current_page) {
    return {
        type: 'SET_CURRENT_PAGE',
        current_page: current_page
    }
}

export function setArianne(arianne) {
    return {
        type: 'SET_ARIANNE',
        arianne: arianne
    }
}
export function setLanguage(language) {
    return {
        type: 'SET_LANGUAGE',
        language: language
    }
}
export function hardResetNavigation() {
    return {
        type: 'HARD_RESET_NAVIGATION'
    }
}