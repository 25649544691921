import { setAnalyzesDatas, setExtrapolatedAnalyzes, setKitchenAnalyzesDatas, setKitchenExtrapolatedAnalyzes } from "../../actions_reducers/actions/datas.action";
import { extrapolateAnalyzesValues } from "./extrapolation.function";

const addValues = (baseValue, newValue, type, averageTrays) => {
    baseValue.mass = parseFloat(baseValue.mass) + parseFloat(newValue.mass);
    baseValue.cost = parseFloat(baseValue.cost) + parseFloat(newValue.cost);
    baseValue.co2 = parseFloat(baseValue.co2) + parseFloat(newValue.co2);
    baseValue.inevitableMass = parseFloat(baseValue.inevitableMass) + parseFloat(newValue.inevitableMass);
    baseValue.inevitableCost = parseFloat(baseValue.inevitableCost) + parseFloat(newValue.inevitableCost);
    baseValue.inevitableCo2 = parseFloat(baseValue.inevitableCo2) + parseFloat(newValue.inevitableCo2);
    if (type !== "kitchen")
        baseValue.trays = parseInt(baseValue.trays) + parseInt(newValue.trays);

    if (baseValue.trays === 0 || baseValue.trays === 1) baseValue.trays = averageTrays
    return baseValue;
}
const getBaseValues = (base, value, averageTrays) => {
    base.co2 = value.co2;
    base.cost = value.cost;
    base.inevitableCo2 = value.inevitableCo2;
    base.inevitableCost = value.inevitableCost;
    base.inevitableMass = value.inevitableMass;
    base.mass = value.mass;
    base.trays = (parseInt(value.trays) === 0 || parseInt(value.trays) === 1) ? averageTrays : value.trays;
    return base;
}
const getTotalValue = (analyzesDatas, showedAnalysis, averageTrays) => {
    let result = [];
    for (const analysis of analyzesDatas) {
        if (analysis.analysisNumber || (showedAnalysis && parseInt(showedAnalysis) === analysis.analysisNumber)) {
            let averageTraysAnalysis = averageTrays * analysis.days.length;
            let index = result.findIndex(a => analysis.analysisNumber === a.analysisNumber);
            if (index === -1) {
                let total = {
                    analysisNumber: analysis.analysisNumber,
                    dateEnd: analysis.dateEnd,
                    dateStart: analysis.dateStart,
                    days: analysis.days,
                    isOver: analysis.isOver,
                    hasMenus: analysis.hasMenus,
                    hasEffectives: analysis.hasEffectives
                };
                for (const day of analysis.days) {
                    if ((day.trays === 0 || day.trays === 1) && averageTrays > 0) day.trays = averageTrays;
                    for (const cat of day.categories) {
                        if ((cat.trays === 0 || cat.trays === 1) && averageTrays > 0) cat.trays = averageTrays;
                        for (const food of cat.foods) {
                            if ((food.trays === 0 || food.trays === 1) && averageTrays > 0) food.trays = averageTrays;
                        }
                    }
                }
                total = getBaseValues(total, analysis, averageTraysAnalysis);
                result.push(total);
            }
            else {
                let tot = result[index]
                tot = addValues(tot, analysis, null, averageTraysAnalysis);
                for (const day of analysis.days) {
                    let indexDay = tot.days.findIndex(a => a.date === day.date);
                    if (indexDay === -1) {
                        let newDay = {
                            date: day.date,
                            categories: day.categories
                        };
                        newDay = getBaseValues(newDay, day, averageTrays);
                        tot.days.push(newDay);
                    }
                    else {
                        let totDay = tot.days[indexDay];
                        totDay = addValues(totDay, day, null, averageTrays);
                        for (const cat of day.categories) {
                            let indexCat = totDay.categories.findIndex(a => a.category === cat.category);
                            if (indexCat === -1) {
                                let newCat = {
                                    category: cat.category,
                                    foods: cat.foods
                                };
                                newCat = getBaseValues(newCat, cat, averageTrays);
                                totDay.categories.push(newCat);
                            }
                            else {
                                let totCat = totDay.categories[indexCat];
                                totCat = addValues(totCat, cat, null, averageTrays);
                                for (const food of cat.foods) {
                                    let indexFood = totCat.foods.findIndex(a => a.nameFood === food.nameFood);
                                    if (indexFood === -1) {
                                        let newFood = {
                                            nameFood: food.nameFood
                                        };
                                        newFood = getBaseValues(newFood, food, averageTrays);
                                        totCat.foods.push(newFood);
                                    }
                                    else {
                                        let totFood = totCat.foods[indexFood];
                                        totFood = addValues(totFood, food, null, averageTrays);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
    return result;
}

const getSelectedHoursValue = (analyzes, selected_hours, showedAnalysis, averageTrays) => {
    if (selected_hours.length === 1) {
        let analysisIndex = analyzes.findIndex(a => a.hour === selected_hours[0]
            && parseInt(a.analysisNumber) === parseInt(showedAnalysis));
        return [analyzes[analysisIndex]];
    }
    else {
        if (selected_hours.length > 0) {
            let analyzesTmp = analyzes.filter(a => selected_hours.find(b => b === a.hour
                && parseInt(a.analysisNumber) === parseInt(showedAnalysis))
            )
            return getTotalValue(analyzesTmp, showedAnalysis, averageTrays);
        }
        else {
            let analyzesTmp = analyzes.filter(a => parseInt(a.analysisNumber) === parseInt(showedAnalysis))
            return getTotalValue(analyzesTmp, showedAnalysis, averageTrays);
        }
    }
}

const getSelectedCamsValue = (analyzes, selected_cams, showedAnalysis) => {
    if (selected_cams.length === 1) {
        let analyzesTmp = analyzes.filter(a => (a.camValue !== "" && a.camValue === selected_cams[0].camName
            && parseInt(a.analysisNumber) === parseInt(showedAnalysis)) || (a.camValue === "" && parseInt(a.analysisNumber) === parseInt(showedAnalysis)));
        return getTotalValue(analyzesTmp, showedAnalysis);
    }
    else {
        if (selected_cams.length > 0) {
            let analyzesTmp = analyzes.filter(a => (a.camValue !== "" && selected_cams.find(b => b.camName === a.camValue
                && parseInt(a.analysisNumber) === parseInt(showedAnalysis)) || (a.camValue === "" && parseInt(a.analysisNumber) === parseInt(showedAnalysis)))
            )
            return getTotalValue(analyzesTmp, showedAnalysis);
        }
        else {
            let analyzesTmp = analyzes.filter(a => parseInt(a.analysisNumber) === parseInt(showedAnalysis))
            return getTotalValue(analyzesTmp, showedAnalysis);
        }
    }
}
const getSelectedValue = (analyzes, selected_hours, selected_cams, showedAnalysis) => {
    let analyzesTmp = analyzes.filter(a => selected_cams.find(b =>
        b.camName === a.camName)
        && selected_hours.find(c => c === a.hour)
        && parseInt(a.analysisNumber) === parseInt(showedAnalysis));
    if (analyzesTmp.length > 0)
        return getTotalValue(analyzesTmp, showedAnalysis)
    else return getTotalValue(analyzes.filter(a => parseInt(a.analysisNumber) === parseInt(showedAnalysis)), showedAnalysis)
}
export const getDatas = (analyzesDatas, selected_hours, selected_cams, dashboardState, dispatch, averageTrays) => {
    let extrapolationValue = dashboardState.extrapolationValue;
    let showedAnalysis = dashboardState.showedAnalysis;

    let analyzes = JSON.parse(JSON.stringify(analyzesDatas));
    let hasTotalHours = selected_hours.find(a => a === "total");
    let hasTotalCams = selected_cams.find(a => a.camName === "total");
    let result = [];
    if (hasTotalHours && hasTotalCams) {
        result = getTotalValue(analyzes, showedAnalysis);
    }
    else if (!hasTotalHours && hasTotalCams) {
        result = getSelectedHoursValue(analyzes, selected_hours, showedAnalysis);
    }
    else if (hasTotalHours && !hasTotalCams) {
        result = getSelectedCamsValue(analyzes, selected_cams, showedAnalysis);
    }
    else if (!hasTotalHours && !hasTotalCams) {
        result = getSelectedValue(analyzes, selected_hours, selected_cams, showedAnalysis);
    }
    if (result && result[0] == null) result = [];
    let extrapolatedAnalyzes = extrapolateAnalyzesValues(JSON.parse(JSON.stringify(result)), extrapolationValue, averageTrays);
    dispatch(setAnalyzesDatas(result));
    dispatch(setExtrapolatedAnalyzes(extrapolatedAnalyzes));
}

export const getKitchenDatas = (analyzesDatas, selected_hours, dashboardState, dispatch, averageTrays) => {
    let extrapolationValue = dashboardState.extrapolationValue;
    let showedAnalysis = dashboardState.showedAnalysis;
    let analyzes = JSON.parse(JSON.stringify(analyzesDatas));
    let hasTotalHours = selected_hours.find(a => a === "total");
    let result = [];
    if (hasTotalHours) {
        result = getTotalValue(analyzes, showedAnalysis, averageTrays);
    }
    else {
        result = getSelectedHoursValue(analyzes, selected_hours, showedAnalysis);
    }
    let extrapolatedAnalyzes = extrapolateAnalyzesValues(JSON.parse(JSON.stringify(result)), extrapolationValue, averageTrays);
    dispatch(setKitchenAnalyzesDatas(result));
    dispatch(setKitchenExtrapolatedAnalyzes(extrapolatedAnalyzes));

}

export const updateClientValuesWithKitchen = (restaurantValues, kitchenValues) => {
    for (const value of kitchenValues) {
        let restaurantAnalysis = restaurantValues.find(a => parseInt(a.analysisNumber) === parseInt(value.analysisNumber) && a.restaurant_name === value.restaurant_name);
        if (restaurantAnalysis) {
            restaurantAnalysis = addValues(restaurantAnalysis, value, "kitchen");
            for (const category of value.categories) {
                let restaurantCategory = restaurantAnalysis.categories.find(a => a.category === category.category);
                if (restaurantCategory) {
                    restaurantCategory = addValues(restaurantCategory, category, "kitchen");
                }
                else {
                    restaurantAnalysis.categories.push(category);
                }
            }
        }
        else {
            restaurantValues.push(kitchenValues);
        }
    }

    return restaurantValues;
}