import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next"
import CustomBlock from "../../customComponents/CustomBlock"
import CustomTextField from "../../customComponents/CustomTextField";
import { hasMultipleCams, hasMultipleHours } from "../../customFunctions/user.function";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import { useDispatch } from "react-redux";
import { setTraysByDay } from "../../../actions_reducers/actions/datas.action";
import { addNewTrays, updateNewTrays } from "../../../apiRoutes/newTrays.route";
import CustomDropdown from "../../customComponents/CustomDropdown";
import { setSelectedCamFiles } from "../../../actions_reducers/actions/files.action";
import 'moment/locale/fr';
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

export const FilesNumberGuests = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [update, setUpdate] = useState(0);
    const [mouseOver, setMouseOver] = useState(false);

    const formatNumber = (num) => {
        if (num)
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
        else return num
    };
    useEffect(() => {
        setUpdate(old => old + 1)
    }, [props.selected_analysis, props.selected_cam]);


    const updateSelectedCam = (e) => {
        dispatch(setSelectedCamFiles(e.target.id))
    }
    const updateNbTrays = async (e) => {
        let value = parseInt(e.target.value);
        let infos = e.target.id.split('%');
        let date = infos[0];
        let nameCam = infos[1];
        let nameHour = infos[2];
        let tmpTrays = JSON.parse(JSON.stringify(props.trays_by_day));
        let trays = tmpTrays.find(a => a.date === date);
        let newHour = { hour: nameHour, nbTrays: value }
        let newCam = {
            nameCam: nameCam,
            trays: [newHour]
        }
        if (trays) {
            let cam = trays.traysCam.find(a => a.nameCam ? a.nameCam === nameCam : true);
            if (cam) {
                let hour = cam.trays.find(a => a.hour === nameHour);
                if (hour) {
                    hour.nbTrays = value;
                }
                else {
                    cam.trays.push(newHour);
                }
            }
            else {
                trays.traysCam.push(newCam);
            }
            await updateNewTrays(date, props.restaurant_name, trays.traysCam)
        }
        else {
            let newTraysDay = {
                date: date,
                nbTrays: value,
                restaurant_name: props.restaurant_name,
                traysCam: [
                    newCam
                ]
            }
            tmpTrays.push(newTraysDay);
            await addNewTrays(newTraysDay)
        }
        dispatch(setTraysByDay(tmpTrays));
    }
    const addDaysToResult = (result, days) => {
        let traysAnalysis = null;
        let analysisNumber = parseInt(props.selected_analysis);
        if (props.realTrays.length > 0)
            traysAnalysis = props.realTrays.find(a => a.analysisNumber === analysisNumber)

        for (const day of days) {
            let date = t('date', { "value": { "date": day.date, format: 'dddd DD MMMM YYYY' } });
            let dateToShow = moment(day.date).format('YYYY-MM-DD');
            let traysOfDay = props.trays_by_day.find(a => moment(a.date).format("YYYY-MM-DD") === moment(dateToShow).format("YYYY-MM-DD"));
            let cam = props.selected_cam || "Total";
            let valueCam = traysOfDay && traysOfDay.traysCam && traysOfDay.traysCam.find(a => a.nameCam ? a.nameCam === cam : true);
            let valueNoon = '';
            let valueEvening = '';
            if (valueCam) {
                let camNoon = valueCam.trays.find(a => a.hour === 'noon');
                if (camNoon) valueNoon = camNoon.nbTrays;
                let camEvening = valueCam.trays.find(a => a.hour === 'evening');
                if (camEvening) valueEvening = camEvening.nbTrays;
            }
            let realTraysDayNoon = null;
            let realTraysDayEvening = null;
            if (traysAnalysis && traysAnalysis.traysByDay.length > 0) {
                realTraysDayNoon = traysAnalysis.traysByDay.find(a => a.date === moment(day.date).format("YYYY-MM-DD") && a.hour === "noon" && a.camName === cam)
                realTraysDayEvening = traysAnalysis.traysByDay.find(a => a.date === moment(day.date).format("YYYY-MM-DD") && a.hour === "evening" && a.camName === cam)
            }
            result.push(
                <div key={dateToShow} className="files-analyzes-table-row" style={{ height: "13%" }}>
                    <div style={{ width: "34%" }}>
                        {date}
                    </div>
                    <div style={{ width: "66%", display: "flex" }}>
                        <div style={{ width: hasMultipleHours(props.userState) ? "50%" : "100%", display: "flex" }}>
                            <div style={{ width: "50%" }}>
                                <CustomTextField
                                    id={dateToShow + '%' + cam + '%noon'}
                                    width={"100%"}
                                    height={"100%"}
                                    placeholder={"Nombre de convives"}
                                    type={"number"}
                                    value={valueNoon}
                                    onChange={updateNbTrays}
                                    textAlign={"right"}
                                    paddingLeft={"1%"}
                                    paddingRight={"1%"}
                                    paddingBottom={"1%"}
                                    paddingTop={"1%"}
                                />
                            </div>

                            <div style={{ width: "50%" }}>
                                {realTraysDayNoon ? realTraysDayNoon.nbTrays : 0}
                            </div>
                        </div>
                        {hasMultipleHours(props.userState) && <div style={{ width: "50%", display: "flex" }}>
                            <div style={{ width: "50%" }}>
                                <CustomTextField
                                    id={dateToShow + '%' + cam + '%evening'}
                                    width={"100%"}
                                    height={"100%"}
                                    placeholder={"Nombre de convives"}
                                    type={"number"}
                                    value={valueEvening}
                                    onChange={updateNbTrays}
                                    textAlign={"right"}
                                    paddingLeft={"1%"}
                                    paddingRight={"1%"}
                                    paddingBottom={"1%"}
                                    paddingTop={"1%"}
                                />
                            </div>
                            <div style={{ width: "50%" }}>
                                {realTraysDayEvening ? realTraysDayEvening.nbTrays : 0}
                            </div>
                        </div>}
                    </div>
                </div>
            )
        }
        return result;
    }
    const getGuestsRows = () => {
        let result = [];
        if (props.selected_analysis) {
            let analysisNumber = parseInt(props.selected_analysis);
            let analysis = JSON.parse(JSON.stringify(props.analyzesDatas)).find(a => a.analysisNumber === analysisNumber);
            let kitchenAnalysis = JSON.parse(JSON.stringify(props.kitchenDatas)).find(a => parseInt(a.analysisNumber) === parseInt(analysisNumber));
            let days = [];
            if (analysis) {
                for (const day of analysis.days) {
                    if (!days.find(a => moment(a.date).format("YYYY-MM-DD") === moment(day.date).format("YYYY-MM-DD")))
                        days.push(day);
                }
            }
            if (kitchenAnalysis) {
                for (const day of kitchenAnalysis.days) {
                    if (!days.find(a => moment(a.date).format("YYYY-MM-DD") === moment(day.date).format("YYYY-MM-DD")))
                        days.push(day);
                }
            }
            days.sort((a, b) => {
                const isBefore = moment(a.date).isBefore(moment(b.date));
                return isBefore ? -1 : 1;
            });
            result = addDaysToResult(result, days)
        }
        return result;
    }
    const getCamsList = () => {
        let result = [];
        for (const cam of props.userState.cams) {
            if (cam.displayName !== "Total")
                result.push(cam.displayName);
        }
        return result;
    }
    useEffect(() => {
        hasMultipleCams(props.userState) && dispatch(setSelectedCamFiles(getCamsList()[0]))
    }, []);
    const getCaptedTraysPercent = () => {
        let realTrays = 0;
        let captedTrays = 0;
        let analysisNumber = props.selected_analysis && parseInt(props.selected_analysis);
        if (props.selected_analysis) {
            let analysis = JSON.parse(JSON.stringify(props.analyzesDatas)).find(a => a.analysisNumber === analysisNumber);
            if (analysis)
                realTrays = analysis.trays;
        }
        if (props.realTrays.length > 0) {
            let traysAnalysis = props.realTrays.find(a => a.analysisNumber === analysisNumber)
            if (traysAnalysis) {
                Object.keys(traysAnalysis.repartitionTrays).map(key => {
                    captedTrays += traysAnalysis.repartitionTrays[key]
                })
            }
        }
        return (
            <div className="dashboard-block-title" style={{ zIndex: 10000, width: "100%", position: "absolute", top: "2%", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
                {t('components.captedForReal') + formatNumber(captedTrays) + " / " + formatNumber(realTrays) + ` ( ${realTrays > 0 ? (captedTrays / realTrays * 100).toFixed(1) : 100}% )`}
            </div>
        )
    }
    const getDetailedCaptedTraysPercent = () => {
        let analysisNumber = props.selected_analysis && parseInt(props.selected_analysis);
        let traysAnalysis = props.realTrays.find(a => a.analysisNumber === analysisNumber);
        let repartitionTrays = []
        if (props.analyzesBaseDatas.length > 0 && traysAnalysis) {
            let analyzes = JSON.parse(JSON.stringify(props.analyzesBaseDatas)).filter(a => a.analysisNumber === analysisNumber);
            for (const analysis of analyzes) {
                let cam = analysis.camName;
                let hour = analysis.hour;
                let repartitionTray = {};
                repartitionTray.name = cam + " " + t(`hours.${hour}`)
                repartitionTray.real = analysis.trays;
                repartitionTray.capted = traysAnalysis.repartitionTrays[hour + cam];
                repartitionTrays.push(repartitionTray)
            }
        }
        repartitionTrays.sort((a, b) => a.name.localeCompare(b.name))
        return (
            <div style={{
                display: mouseOver ? "block" : "none",
                width: "21vw", backgroundColor: "#fff", borderRadius: 15, position: "absolute", bottom: "100%", left: "50%", boxShadow: '0px 4px 4px 0px #00000040',
                paddingLeft: "1%", paddingRight: "1%"
            }}>
                <div style={{ height: "2vh" }} />
                {repartitionTrays.map((tray => {
                    return (
                        <div style={{ height: "4vh" }}>
                            {tray.name + " : " + tray.capted + " / " + tray.real + ` ( ${tray.real > 0 ? (tray.capted / tray.real * 100).toFixed(1) : 100}% )`}
                        </div>
                    )
                }))}
                <div style={{ height: "2vh" }} />
            </div>
        )
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}
            backgroundColor={"#fff"}
            paddingTop={"1%"}
            paddingBottom={"3%"}
            paddingLeft={"1%"}
            paddingRight={"1%"}>
            {getCaptedTraysPercent()}
            {getDetailedCaptedTraysPercent()}
            <div className="dashboard-block-title">
                {t('components.numberGuestsFiles', { "analysis": props.selected_analysis })}
                {
                    hasMultipleCams(props.userState) &&
                    <CustomDropdown
                        width={"15%"}
                        height={"5vh"}
                        position={"absolute"}
                        right={0}
                        top={0}
                        backgroundColor={"#54b0e3"}
                        color={"#fff"}
                        fontSize={"1vw"}
                        fontWeight={300}
                        zIndex={1000000}
                        values={getCamsList()}
                        arrowColor={"#6D6C7A"}
                        displayedValue={props.selected_cam}
                        selectedValue={props.selected_cam}
                        dropdownUpdate={update}
                        onChange={updateSelectedCam} />
                }
            </div>

            <div className="files-analyzes-table-headers">
                <div style={{ width: "34%" }}>
                    {t('table.day')}
                </div>
                <div style={{ width: "66%", display: "flex" }}>
                    <div style={{ width: hasMultipleHours(props.userState) ? "50%" : "100%", display: "flex" }}>
                        <div style={{ width: "50%" }}>
                            {t('hours.noon')}
                        </div>

                        <div style={{ width: "50%" }}>
                            {t('table.captedTrays')}
                        </div>
                    </div>
                    {hasMultipleHours(props.userState) && <div style={{ width: "50%", display: "flex" }}>
                        <div style={{ width: "50%" }}>
                            {t('hours.evening')}
                        </div>
                        <div style={{ width: "50%" }}>
                            {t('table.captedTrays')}
                        </div>
                    </div>}
                </div>
            </div>
            <div className="files-analyzes-table-body">
                <CustomScrollDiv update={update}>
                    {getGuestsRows()}
                </CustomScrollDiv>
            </div>
        </CustomBlock >
    )
}