import React from "react";
import { connect } from "react-redux"; // REDUX
import './Containers.css'
import CustomScrollDiv from "../customComponents/CustomScrollBar";
import Dashboard from "../mainPages/dashboard/Dashboard";
import Planning from "../mainPages/planning/Planning";
import Ressources from "../mainPages/ressources/Ressources";
import DashboardClient from "../mainPages/client/DashboardClient";
import SamplesBinGestion from "../mainPages/SamplesBin/SamplesBinGestion";
import ClientList from "../mainPages/admin/clientList/ClientList";
import Stats from "../mainPages/admin/stats/Stats";
import Operations from "../mainPages/admin/operations/Operations";
import Planification from "../mainPages/admin/planification/Planification";
import Files from "../mainPages/files/Files";
import Alerts from "../mainPages/alerts/Alerts";
import ClientListRawDatas from "../mainPages/admin/clientList/ClientListRawDatas";
import Pictures from "../mainPages/pictures/Pictures";
import Search from "../mainPages/search/Search";
import DatasRestaurant from "../mainPages/datasRestaurant/DatasRestaurant";
import Account from "../mainPages/account/Account";
import ClientListNewClient from "../mainPages/admin/clientList/ClientListNewClient";
import ClientListNewRestaurant from "../mainPages/admin/clientList/ClientListNewRestaurant";
import Mails from "../mainPages/admin/mails/Mails";
import ClientListSettings from "../mainPages/admin/clientList/ClientListSettings";
import ClientListDemoAccounts from "../mainPages/admin/clientList/ClientListDemoAccounts";
import ClientListAccounts from "../mainPages/admin/clientList/ClientListAccounts";
import Surveys from "../mainPages/surveys/Surveys";
import ClientSettings from "../mainPages/admin/clientList/ClientSettings";
import ClientChat from '../mainPages/chat/ClientChat';
import AdminChat from '../mainPages/chat/AdminChat'
import env from "react-dotenv";
let env_type = env.REACT_APP_ENV_TYPE;

const ApplicationContainer = (props) => {
    const getPageToShow = () => {
        switch (props.navigationState.current_page) {
            case ('account'):
                return <Account />;
            case ('accounts'):
                return <ClientListAccounts />;
            case ('alerts'):
                return <Alerts />;
            case ('demoAccounts'):
                return <ClientListDemoAccounts />
            case ('mails'):
                return <Mails />;
            case ('clientList'):
                return <ClientList />;
            case ('settings'):
                return <ClientListSettings />;
            case ('clientSettings'):
                return <ClientSettings />;
            case ('addClient'):
                return <ClientListNewClient />
            case ('addRestaurant'):
                return <ClientListNewRestaurant />
            case ('datas'):
                return <ClientListRawDatas />
            case ('planification'):
                return <Planification />
            case ('operations'):
                return <Operations />
            case ('search'):
                return <Search />
            case ('stats'):
                return <Stats />
            case ('clientDashboard'):
                return <DashboardClient />
            case ('dashboard'):
                return <Dashboard />;
            case ('datasRestaurant'):
                return <DatasRestaurant />
            case ('planning'):
                return <Planning />;
            case ('pictures'):
                return <Pictures />;
            case ('files'):
                return <Files />
            case ('ressources'):
                return <Ressources />
            case ('answers'):
                return <Surveys />
            case ('samplesBinGestion'):
                return <SamplesBinGestion />
            case ('chat'):
                return <AdminChat />
            default:
                return <Dashboard />;
        }
    }

    return (
        <div className="application-main-div">
            <CustomScrollDiv id={"application-container"} update={Math.random()}>
                <div className="application-scroll-div">
                    {props.userState.is_logged_in && getPageToShow()}
                </div>
            </CustomScrollDiv>
            {props.userState.is_logged_in && props.userState.user_type !== "admin" && props.userState.has_loaded && env_type !== "ovh" && 
                <ClientChat
                    client_name={props.client_name} restaurant_name={props.restaurant_name}
                    login={props.userState.login} isChatOpen={props.isChatOpen} chatId={props.userState.userId} userId={props.userState.userId} />}
        </div >
    )
}
function mapStateToProps(state) {
    return {
        userState: state.userReducer,
        navigationState: state.navigationReducer,
        isChatOpen: state.userReducer.isChatOpen,
        restaurant_name: state.userReducer.selected_restaurant,
        client_name: state.clientReducer.clientName
    };
}

export default connect(mapStateToProps)(ApplicationContainer);
