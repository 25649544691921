import React from "react";
import { connect } from "react-redux"; // REDUX
import CustomBlock from "../../customComponents/CustomBlock";
import CustomButton from "../../customComponents/CustomButton";
import { useTranslation } from "react-i18next";

const Surveys = (props) => {
    const { t } = useTranslation();
    const goToAnswers = () => {
        window.open(props.linkToSurvey, '_blank')
    }

    return (
        <div className="main-page-main-div" >
            <div style={{ width: "100%", height: "85.8vh" }}>
                <CustomBlock width={"100%"} height={"30%"} paddingLeft={"2%"} paddingRight={"2%"}>
                    <div style={{ height: "50%", display: "flex", alignItems: "center", fontSize: "1.2vw", fontWeight: "bold" }}>
                        {t('components.diffuseSurvey')}
                    </div>
                    <div style={{ height: "50%", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.2vw" }}>
                        <CustomButton height={"70%"} width={"25%"} color={"#fff"} backgroundColor={"#72B9A2"} onClick={() => goToAnswers()} value={"Consulter les résultats du questionnaire"} />
                    </div>
                </CustomBlock>
            </div>
        </div >
    )
}
function mapStateToProps(state) {
    return {
        linkToSurvey: state.userReducer.linkToSurvey
    };
}

export default connect(mapStateToProps)(Surveys);