const initialState = {
    hasWeek: false,
    hasSaturday: false,
    hasSunday: false,
    hasWeekNoon: false,
    hasWeekEvening: false,
    hasSaturdayNoon: false,
    hasSaturdayEvening: false,
    hasSundayNoon: false,
    hasSundayEvening: false,
    weekNoonHours: "",
    weekEveningHours: "",
    saturdayNoonHours: "",
    saturdayEveningHours: "",
    sundayNoonHours: "",
    sundayEveningHours: "",
    averageGuests: "",
    cams: [],
    serviceType: "",
    reasonService: "",
    selfFoods: [],
    breadPosition: "",
    breadType: "",
    breadOrigin:"",
    breadMass: "",
    breadLimit: "",
    numberBread: "",
    breadRefill: "",
    breadMoment: "",
    portionType: "",
    allowedRefill: "",
    whenRefill: "",
    whyNotRefill: "",
    numberVege: "",
    vegeType: "",
    wasteManagementMethod: "",
    hasActions: "",
    typeActions: "",
    whyNotActions: "",
    teamMotivation: "",
    teamMotivationNote: "",
    stakeholders: "",
    projectContact: "",
    projectContactMail: "",
    projectContactPhone: "",
    projectReferent: "",
    projectReferentMail: "",
    projectReferentPhone: ""
}


const onBoardingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_HAS_WEEK_ON_BOARDING':
            return {
                ...state,
                hasWeek: action.hasWeek
            }
        case 'SET_HAS_SATURDAY_ON_BOARDING':
            return {
                ...state,
                hasSaturday: action.hasSaturday
            }
        case 'SET_HAS_SUNDAY_ON_BOARDING':
            return {
                ...state,
                hasSunday: action.hasSunday
            }
        case 'SET_HAS_WEEK_NOON':
            return {
                ...state,
                hasWeekNoon: action.hasWeekNoon
            }
        case 'SET_HAS_WEEK_EVENING':
            return {
                ...state,
                hasWeekEvening: action.hasWeekEvening
            }
        case 'SET_HAS_SATURDAY_NOON':
            return {
                ...state,
                hasSaturdayNoon: action.hasSaturdayNoon
            }
        case 'SET_HAS_SATURDAY_EVENING':
            return {
                ...state,
                hasSaturdayEvening: action.hasSaturdayEvening
            }
        case 'SET_HAS_SUNDAY_NOON':
            return {
                ...state,
                hasSundayNoon: action.hasSundayNoon
            }
        case 'SET_HAS_SUNDAY_EVENING':
            return {
                ...state,
                hasSundayEvening: action.hasSundayEvening
            }
        case 'SET_WEEK_NOON_HOURS':
            return {
                ...state,
                weekNoonHours: action.weekNoonHours
            }
        case 'SET_WEEK_EVENING_HOURS':
            return {
                ...state,
                weekEveningHours: action.weekEveningHours
            }
        case 'SET_SATURDAY_NOON_HOURS':
            return {
                ...state,
                saturdayNoonHours: action.saturdayNoonHours
            }
        case 'SET_SATURDAY_EVENING_HOURS':
            return {
                ...state,
                saturdayEveningHours: action.saturdayEveningHours
            }
        case 'SET_SUNDAY_NOON_HOURS':
            return {
                ...state,
                sundayNoonHours: action.sundayNoonHours
            }
        case 'SET_SUNDAY_EVENING_HOURS':
            return {
                ...state,
                sundayEveningHours: action.sundayEveningHours
            }
        case 'SET_AVERAGE_GUESTS_ON_BOARDING':
            return {
                ...state,
                averageGuests: action.averageGuests
            }
        case 'SET_CAMS_ON_BOARDING':
            return {
                ...state,
                cams: action.cams
            }
        case 'SET_SERVICE_TYPE':
            return {
                ...state,
                serviceType: action.serviceType
            }
        case 'SET_REASON_SERVICE':
            return {
                ...state,
                reasonService: action.reasonService
            }
        case 'SET_SELF_FOODS':
            return {
                ...state,
                selfFoods: action.selfFoods
            }
        case 'SET_BREAD_POSITION':
            return {
                ...state,
                breadPosition: action.breadPosition
            }
        case 'SET_BREAD_TYPE':
            return {
                ...state,
                breadType: action.breadType
            }
        case 'SET_BREAD_ORIGIN':
            return {
                ...state,
                breadOrigin: action.breadOrigin
            }
        case 'SET_BREAD_MASS':
            return {
                ...state,
                breadMass: action.breadMass
            }
        case 'SET_BREAD_LIMIT':
            return {
                ...state,
                breadLimit: action.breadLimit
            }
        case 'SET_NUMBER_BREAD':
            return {
                ...state,
                numberBread: action.numberBread
            }
        case 'SET_BREAD_REFILL':
            return {
                ...state,
                breadRefill: action.breadRefill
            }
        case 'SET_BREAD_MOMENT':
            return {
                ...state,
                breadMoment: action.breadMoment
            }
        case 'SET_PORTION_TYPE':
            return {
                ...state,
                portionType: action.portionType
            }
        case 'SET_ALLOWED_REFILL':
            return {
                ...state,
                allowedRefill: action.allowedRefill
            }
        case 'SET_WHEN_REFILL':
            return {
                ...state,
                whenRefill: action.whenRefill
            }
        case 'SET_WHY_NOT_REFILL':
            return {
                ...state,
                whyNotRefill: action.whyNotRefill
            }
        case 'SET_NUMBER_VEGE':
            return {
                ...state,
                numberVege: action.numberVege
            }
        case 'SET_VEGE_TYPE':
            return {
                ...state,
                vegeType: action.vegeType
            }
        case 'SET_WASTE_MANAGEMENT_METHOD_ON_BOARDING':
            return {
                ...state,
                wasteManagementMethod: action.wasteManagementMethod
            }
        case 'SET_HAS_ACTIONS':
            return {
                ...state,
                hasActions: action.hasActions
            }
        case 'SET_TYPE_ACTIONS':
            return {
                ...state,
                typeActions: action.typeActions
            }
        case 'SET_WHY_NOT_ACTIONS':
            return {
                ...state,
                whyNotActions: action.whyNotActions
            }
        case 'SET_TEAM_MOTIVATION':
            return {
                ...state,
                teamMotivation: action.teamMotivation
            }
        case 'SET_TEAM_MOTIVATION_NOTE':
            return {
                ...state,
                teamMotivationNote: action.teamMotivationNote
            }
        case 'SET_STAKEHOLDERS':
            return {
                ...state,
                stakeholders: action.stakeholders
            }
        case 'SET_PROJECT_CONTACT':
            return {
                ...state,
                projectContact: action.projectContact
            }
        case 'SET_PROJECT_CONTACT_MAIL':
            return {
                ...state,
                projectContactMail: action.projectContactMail
            }
        case 'SET_PROJECT_CONTACT_PHONE':
            return {
                ...state,
                projectContactPhone: action.projectContactPhone
            }
        case 'SET_PROJECT_REFERENT':
            return {
                ...state,
                projectReferent: action.projectReferent
            }
        case 'SET_PROJECT_REFERENT_MAIL':
            return {
                ...state,
                projectReferentMail: action.projectReferentMail
            }
        case 'SET_PROJECT_REFERENT_PHONE':
            return {
                ...state,
                projectReferentPhone: action.projectReferentPhone
            }

        case 'HARD_RESET_ON_BOARDING':
            return initialState;
        default:
            return state;
    }
}

export default onBoardingReducer;