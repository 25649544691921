import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import OperationsSelection from "./OperationsSelection";
import OperationsDetails from "./OperationsDetails";
import { getAllPlanningStatus } from "../../../../apiRoutes/dailyPlanning.routes";
import { setOperations } from "../../../../actions_reducers/actions/operations.action";
import 'moment/locale/fr';
import OperationsCalendar from "./OperationsCalendar";
import OperationsKitchenDetails from "./OperationsKitchenDetails";

const Operations = (props) => {
    const dispatch = useDispatch();


    useEffect(() => {
        if (props.operationsState.operations.length === 0)
            getDailyPlanningsForDate()
    }, []);

    const getDailyPlanningsForDate = async () => {
        let allOperationsDay = await getAllPlanningStatus();
        dispatch(setOperations(allOperationsDay))
        /*let operations = JSON.parse(JSON.stringify(props.operationsState.operations));
        let newOperations = {
            date: props.operationsState.selectedDate,
            operations: dailyPlannings
        }
        operations.push(newOperations);
        dispatch(setOperations(operations));*/
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <div style={{ width: "100%", height: "5%" }}>
                <OperationsSelection informations={props.informations} state={props.operationsState} operationViewType={props.operationViewType} />
            </div>
            <div style={{ height: "3%" }} />
            <div style={{ width: "100%", height: "92%" }}>
                {
                    props.operationsState.selectedDate ?
                        props.operationsState.operationViewType === "restaurant" ?
                            <OperationsDetails state={props.operationsState} informations={props.informations} />
                            :
                            <OperationsKitchenDetails state={props.operationsState} informations={props.informations} />
                        :
                        <OperationsCalendar state={props.operationsState}  informations={props.informations}/>
                }
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userState: state.userReducer,
        informations: state.adminReducer.allInformations,
        operationsState: state.operationsReducer,
        operationViewType: state.operationsReducer.operationViewType
    };
}

export default connect(mapStateToProps)(Operations);
