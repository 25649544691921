import { getNumberDaysExtrap } from '../../../customFunctions/extrapolation.function';
import { getTravelValues } from '../SynthesisFunctions';

const getValues = (analysis, kitchenAnalysis, averageTrays) => {
    let co2 = 0;
    let co2Kitchen = 0;
    let trays = 0;
    let traysKitchen = 0;

    let numberDaysExtrapWeek = getNumberDaysExtrap('week');
    let numberDaysExtrapYear = getNumberDaysExtrap('year');

    if (analysis) {
        co2 = parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2);
        trays = parseInt(analysis.trays)
    }
    if (kitchenAnalysis) {
        co2Kitchen = parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2);
        traysKitchen = parseInt(kitchenAnalysis.trays);
    }

    let co2Tot = parseFloat(co2) + parseFloat(co2Kitchen);

    let co2Conv = trays > 0 && parseFloat(co2) / parseInt(trays);
    let co2ConvKitchen = traysKitchen > 0 && parseFloat(co2Kitchen) / parseInt(traysKitchen);
    let co2ConvTot = co2Conv + co2ConvKitchen;

    let co2Week = co2Conv * averageTrays * numberDaysExtrapWeek;
    let co2KitchenWeek = co2ConvKitchen * averageTrays * numberDaysExtrapWeek;
    let co2Year = co2Conv * averageTrays * numberDaysExtrapYear;
    let co2KitchenYear = co2ConvKitchen * averageTrays * numberDaysExtrapYear;

    let co2TotWeek = co2Week + co2KitchenWeek;
    let co2TotYear = co2Year + co2KitchenYear;

    return {
        co2Tot: co2Tot,
        co2ConvTot: co2ConvTot,
        co2Week: co2TotWeek,
        co2Year: co2TotYear
    }

}
export const getSixthSlide = (pres, t, analysis, kitchenAnalysis, x, averageTrays) => {
    let slide = pres.addSlide();
    let values = getValues(analysis, kitchenAnalysis, averageTrays);
    let travelValues = getTravelValues(values.co2Year);

    slide.addImage({ path: require('../assets/6/Group 12.png'), x: "35%", y: "4%", w: "65%", h: "21%" })
    slide.addImage({ path: require('../assets/6/bubble1.png'), x: "48%", y: "30.5%", w: "22%", h: "29%" })
    slide.addImage({ path: require('../assets/6/bubble2.png'), x: "69%", y: "56%", w: "22%", h: "29%" })
    slide.addImage({ path: require('../assets/6/bubble3.png'), x: "45%", y: "64%", w: "24%", h: "31%" })
    slide.addImage({ path: require('../assets/6/car2.png'), x: "65%", y: "45%", w: "22%", h: "22%" })
    slide.addImage({ path: require('../assets/6/plane3.png'), x: "43.5%", y: "56.5%", w: "27%", h: "27%" })
    slide.addImage({ path: require('../assets/6/train.png'), x: "59.5%", y: "27%", w: "12.5%", h: "20%" })
    slide.addImage({ path: require('../assets/6/bluerect.png'), x: "72%", y: "80%", w: "23.7%", h: "9%" })
    slide.addImage({ path: require('../assets/6/textbox.png'), x: "15%", y: "23%", w: "23%", h: "17%" })
    slide.addImage({ path: require('../assets/6/textbox.png'), x: "15%", y: "44%", w: "23%", h: "17%" })
    slide.addImage({ path: require('../assets/6/textbox.png'), x: "15%", y: "65.5%", w: "23%", h: "17%" })
    slide.addImage({ path: require('../assets/3/logo_bg.png'), x: "0%", y: "86%", w: "15%", h: "14%" })
    slide.addImage({ path: require('../assets/1/logoNoText.png'), x: "1%", y: "89%", w: "11%", h: "9%" })

    slide.addText(t("synthesis.global.loosediagnostic"), { x: "50%", y: "6%", w: "45%", h: "10%", fontFace: 'Muli', align: "left",  color: 'FFFFFF', fontSize: 25, bold: true });
    slide.addText(t("synthesis.global.carboneimpact"), { x: "50%", y: "16.75%", w: "50%", h: "5%", fontFace: 'Muli', align: "left",  color: 'F8F8F3', fontSize: 22, bold: true });

    slide.addText(t("synthesis.global.guestimpact"), { x: "15.7%", y: "25%", w: "22%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t('components.co2', { value: t('mass', { value: { "mass": values.co2ConvTot, "unit": "g", fixed: 0 } }) }), { x: "15.7%", y: "33%", w: "22%", h: "5%", align: "center", fontFace: 'Muli',  color: '90C261', fontSize: 17, bold: true });
    slide.addText(t("synthesis.global.weekimpact"), { x: "15.7%", y: "46%", w: "22%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t('components.co2', { value: t('mass', { value: { "mass": values.co2Week, "unit": "kg", fixed: 0 } }) }), { x: "15.7%", y: "54%", w: "22%", h: "5%", align: "center", fontFace: 'Muli',  color: '90C261', fontSize: 17, bold: true });
    slide.addText(t("synthesis.global.yearimpact"), { x: "15.7%", y: "67.5%", w: "22%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t('components.co2', { value: t('mass', { value: { "mass": values.co2Year, "unit": "kg", fixed: 0 } }) }), { x: "15.7%", y: "75.5%", w: "22%", h: "5%", align: "center", fontFace: 'Muli',  color: '90C261', fontSize: 17, bold: true });

    slide.addText(t("synthesis.page6.yearequivalent"), { x: "72%", y: "81.8%", w: "24%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page6.arvalue", { number: travelValues.trainNumber, type: travelValues.trainType }), { x: "51%", y: "43%", w: "15%", h: "5%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page6.itinerary", { value: travelValues.trainTravel }), { x: "51%", y: "47%", w: "20%", h: "5%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 17, bold: true });

    slide.addText(t("synthesis.page6.arvalue", { number: travelValues.planeNumber, type: travelValues.planeType }), { x: "49%", y: "81%", w: "15%", h: "5%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page6.itinerary", { value: travelValues.planeTravel }), { x: "49%", y: "85%", w: "20%", h: "5%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page6.arvalue", { number: travelValues.carNumber, type: travelValues.carType }), { x: "71.5%", y: "69.5%", w: "15%", h: "5%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page6.itinerary", { value: travelValues.carTravel }), { x: "71.5%", y: "73.5%", w: "20%", h: "5%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.global.source"), { x: "72%", y: "91%", w: "24%", h: "5%", align: "right", fontFace: 'Muli',  color: '666666', fontSize: 10, bold: false });
    slide.addText(x, { x: "97%", y: "96%", w: "3%", h: "3%", fontFace: 'Muli', align: "right",  color: '666666', fontSize: 15, bold: false });
    slide.background = { color: "#F8F8F3" }

    return pres;
}
