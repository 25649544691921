import React, { useEffect, useState } from "react";
import CustomButton from "../../customComponents/CustomButton";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import 'moment/locale/fr';
import { setHasLoaded } from "../../../actions_reducers/actions/user.action";
import { setDaysListPictures, setLastSelectedDatePictures, setPictures, setPicturesToDelete, setSelectedDatePictures, setSelectedMonthPictures, setSelectedPicture } from "../../../actions_reducers/actions/pictures.action";
import CustomBlock from "../../customComponents/CustomBlock";
import CustomAutocomplete from "../../customComponents/CustomAutocomplete";
import { deleteTraysWithSamples, updateAlimForDay } from "../../../apiRoutes/trays.route";
import CustomDropdown from "../../customComponents/CustomDropdown";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const PicturesTop = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
    const [modalModifOpen, setModalModifOpen] = useState(false);
    const [dropdownCurrentFoodsOpen, setDropdownCurrentFoodsOpen] = useState(false);
    const [dropdownFoodsOpen, setDropdownFoodsOpen] = useState(false);
    const [selectedCurrentFood, setSelectedCurrentFood] = useState(null);
    const [selectedFood, setSelectedFood] = useState(null);

    const changeDropdownCurrentFoodsOpen = () => setDropdownCurrentFoodsOpen(!dropdownCurrentFoodsOpen);
    const changeDropdownFoodsOpen = () => setDropdownFoodsOpen(!dropdownFoodsOpen);

    const backToCalendar = () => {
        dispatch(setSelectedPicture(null));
        dispatch(setSelectedDatePictures(null));
    }

    const removeSelection = async () => {
        let date = props.selectedDate;
        let restaurant_name = props.restaurant_name;
        let allPictures = JSON.parse(JSON.stringify(props.pictures));
        let dayIndex = allPictures.findIndex(a => a.date === props.selectedDate);
        allPictures.splice(dayIndex, 1);
        await deleteTraysWithSamples(restaurant_name, date, props.picturesToDelete);
        dispatch(setHasLoaded(false))
        dispatch(setSelectedDatePictures(null))
        dispatch(setPictures(allPictures));
        setModalDeleteOpen(false);
        dispatch(setPicturesToDelete([]));
    }

    const confirmModification = async () => {
        let date = props.selectedDate;
        let restaurant_name = props.restaurant_name;
        console.log(props.food_list)
        let oldId = props.food_list.find(a => a.nameFood === selectedCurrentFood).idFood;
        let newId
        if (selectedFood !== t('foods.Non organique'))
            newId = props.food_list.find(a => a.nameFood === selectedFood).idFood;
        else newId = 231;
        let allPictures = JSON.parse(JSON.stringify(props.pictures));
        let dayIndex = allPictures.findIndex(a => a.date === props.selectedDate);
        allPictures.splice(dayIndex, 1);

        await updateAlimForDay(date, restaurant_name, oldId, newId)
        dispatch(setHasLoaded(false))
        dispatch(setSelectedDatePictures(null))
        dispatch(setPictures(allPictures));
        setSelectedFood(null);
        setSelectedCurrentFood(null);
        setModalModifOpen(false);
        setDropdownCurrentFoodsOpen(false);
        setDropdownFoodsOpen(false);
    }
    const getFoodName = (sample) => {
        let alim = props.food_list.find(a => parseInt(a.idFood) === parseInt(sample.aliment_id));
        if (alim)
            return t(`foods.${alim.nameFood}`)
    }
    const getFoods = () => {
        let currentFoods = [];
        let allFoods = [];
        let allPictures = props.pictures.find(a => a.date === props.selectedDate);
        if (allPictures) {
            for (const picture of allPictures.pictures) {
                for (const sample of picture.samples) {
                    if (!currentFoods.find(a => a === getFoodName(sample)))
                        currentFoods.push(getFoodName(sample))
                }
            }
        }
        currentFoods.sort((a, b) => a.localeCompare(b));
        for (const food of props.food_list) {
            allFoods.push(food.nameFood);
        }
        allFoods.push("Non organique")
        allFoods.sort((a, b) => a.localeCompare(b));
        return {
            currentFoods: currentFoods,
            allFoods: allFoods
        }

    }
    const updateSelectedFood = (e) => {
        setSelectedFood(e.target.id)
    }
    const updateSelectedCurrentFood = (e) => {
        setSelectedCurrentFood(e.target.id)
    }
    useEffect(() => {
        getDaysDropdown()
    }, [props.selectedAnalysis, props.selectedMonth]);
    const getDaysDropdown = (month) => {
        let result = [];
        let dates = JSON.parse(JSON.stringify(props.dates));
        result = dates.filter(a => props.selectedMonth === getDateMajFormat(moment(a.date).format("MMMM YYYY")));
        result = result.sort((a, b) => moment(new Date(a.date)).isSameOrAfter(moment(new Date(b.date))) ? 0 : -1);
        result = result.map(item => moment(item.date).format('DD-MM-YYYY'))
        if (props.restaurant_name === "DESCOBERTAS") result.unshift(t('components.allMonth'))
        dispatch(setDaysListPictures(result));
    }
    const getDisplayedDropdownValue = () => {
        if (props.selectedDate !== t('components.allMonth'))
            return getDateMajFormat(t(`date`, { "value": { "date": moment(props.selectedDate).format("YYYY-MM-DD"), "format": "DD MMMM YYYY" } }));
        else return t('components.allMonth')
    }
    const changeSelectedDay = (e) => {
        if (e.target.id !== t('components.allMonth')) {
            dispatch(setSelectedDatePictures(moment(e.target.id, "DD-MM-YYYY").format("YYYY-MM-DD")))
            dispatch(setLastSelectedDatePictures(moment(e.target.id, "DD-MM-YYYY").format("YYYY-MM-DD")))
        }
        else {
            dispatch(setSelectedDatePictures(t('components.allMonth')))
            dispatch(setLastSelectedDatePictures(moment(props.selectedMonth, "MMMM YYYY").startOf("month").format("YYYY-MM-DD")))
        }
    }
    const getDateMajFormat = (date) => {
        return date.charAt(0).toUpperCase() + date.slice(1)
    }
    const changeSelectedMonth = (e) => {
        let value = e.target.id;
        dispatch(setSelectedMonthPictures(value))
        let result = [];
        let dates = JSON.parse(JSON.stringify(props.dates));
        result = dates.filter(a => value === getDateMajFormat(moment(a.date).format("MMMM YYYY")));
        result = result.sort((a, b) => moment(new Date(a.date)).isSameOrAfter(moment(new Date(b.date))) ? 0 : -1);
        result = result.map(item => moment(item.date).format('DD-MM-YYYY'))
        dispatch(setSelectedDatePictures(moment(result[0], "DD-MM-YYYY").format("YYYY-MM-DD")))
        dispatch(setLastSelectedDatePictures(moment(result[0], "DD-MM-YYYY").format("YYYY-MM-DD")))
    }
    const getMonths = () => {
        let result = [];
        for (const date of props.dates) {
            if (!result.find(a => a === getDateMajFormat(t('date', { value: { date: date.date, format: "MMMM YYYY" } }))))
                result.push(getDateMajFormat(t('date', { value: { date: date.date, format: "MMMM YYYY" } })))
        }
        result.sort((a, b) => moment(new Date(b)).isSameOrAfter(moment(new Date(a))) ? 0 : -1)
        return result
    }
    const modifAlimModal = () => {
        return (
            <CustomBlock paddingBottom={"1%"} width={"60%"} height={"30%"} right={"20%"} top={'15%'} display={modalModifOpen ? "block" : "none"}
                position={"absolute"} zIndex={20000000000} color={"#666666"} paddingTop={"2%"}>
                <div style={{ height: "80%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", height: "100%" }}>
                        <div style={{ fontSize: "1.4vw", height: "20%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('components.alimToModif')}
                        </div>
                        <div style={{ height: "80%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomAutocomplete
                                width={"70%"}
                                height={"35%"}
                                color={"#666666"}
                                border={"1px solid #666666"}
                                boxShadow={"none"}
                                maxHeightDropdown={"40vh"}
                                displayedValue={selectedCurrentFood || t('components.alimName')}
                                values={getFoods().currentFoods}
                                isOpen={dropdownCurrentFoodsOpen}
                                onClick={changeDropdownCurrentFoodsOpen}
                                onChange={updateSelectedCurrentFood} />
                        </div>
                    </div>
                    <div style={{ width: "50%", height: "100%" }}>
                        <div style={{ fontSize: "1.4vw", height: "20%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('components.newAlim')}
                        </div>
                        <div style={{ height: "80%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomAutocomplete
                                width={"70%"}
                                height={"35%"}
                                color={"#666666"}
                                border={"1px solid #666666"}
                                boxShadow={"none"}
                                maxHeightDropdown={"40vh"}
                                displayedValue={selectedFood || t('components.alimName')}
                                values={getFoods().allFoods}
                                isOpen={dropdownFoodsOpen}
                                onClick={changeDropdownFoodsOpen}
                                onChange={updateSelectedFood} />
                        </div>
                    </div>
                </div>
                <div style={{ height: "20%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"50%"} height={"100%"} backgroundColor={"#90c261"} value={t('buttons.confirm')} color={"#fff"}
                            onClick={() => confirmModification()} />
                    </div>
                    <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"50%"} height={"100%"} backgroundColor={"#949494"} value={t('buttons.cancel')} color={"#fff"}
                            onClick={() => {
                                setSelectedCurrentFood(null);
                                setSelectedFood(null);
                                setModalModifOpen(false)
                            }} />
                    </div>
                </div>
            </CustomBlock>
        )
    }

    const confirmSuppressionModal = () => {
        return (
            <CustomBlock paddingBottom={"1%"} width={"60%"} height={"30%"} right={"20%"} top={'15%'} display={modalDeleteOpen ? "block" : "none"}
                position={"absolute"} zIndex={20000000000} color={"#666666"} paddingTop={"2%"}>
                <div style={{ height: "15%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.4vw" }}>
                    {t('components.warning')}
                </div>
                <div style={{ height: "65%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.2vw" }}>
                    {t('longTexts.confirmSuppression', { number: props.picturesToDelete.length })}
                </div>
                <div style={{ height: "20%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"50%"} height={"90%"} backgroundColor={"#ECAC68"} value={t('buttons.deleteSelection')} color={"#fff"}
                            onClick={() => removeSelection()} />
                    </div>
                    <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"50%"} height={"90%"} backgroundColor={"#949494"} value={t('buttons.cancel')} color={"#fff"}
                            onClick={() => setModalDeleteOpen(false)} />
                    </div>
                </div>
            </CustomBlock>
        )
    }
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
            {confirmSuppressionModal()}
            {modifAlimModal()}
            <div style={{ width: "15%", height: "100%" }}>
                <CustomButton width={"100%"} height={"80%"} backgroundColor={"#666666"} value={t('buttons.back')} color={"#fff"}
                    onClick={() => backToCalendar()} />
            </div>
            <div style={{ width: "46%", height: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ width: "2.5%" }} />
                <div style={{ width: "46.5%" }}>

                    <CustomDropdown width={"100%"} height={"80%"}
                        backgroundColor={'#68B4E3'}
                        displayedValue={t('buttons.selectMonth')}
                        values={getMonths()}
                        onChange={changeSelectedMonth}
                        maxHeightDropdown={getMonths().length > 8 && "50vh"}
                        arrowColor={"#fff"}
                        color={"#fff"}
                        border={"1px solid #68B4E3"} />

                </div>
                <div style={{ width: "2.5%" }} />
                <div style={{ width: "46.5%" }}>

                    <CustomDropdown width={"100%"} height={"80%"}
                        backgroundColor={'#68B4E3'}
                        displayedValue={getDisplayedDropdownValue()}
                        selectedValue={t(`date`, { "value": { "date": props.selectedDate, "format": "DD-MM-YYYY" } })}
                        values={props.daysList}
                        onChange={changeSelectedDay}
                        maxHeightDropdown={"50vh"}
                        arrowColor={"#fff"}
                        color={"#fff"}
                        border={"1px solid #68B4E3"} />

                </div>
            </div>
            <div style={{ width: "20%", height: "100%" }}>
                {props.userState.user_type === "admin" &&
                    <CustomButton width={"100%"} height={"80%"} backgroundColor={"#89CD85"} value={t('buttons.modifyAliment')} color={"#fff"}
                        onClick={() => setModalModifOpen(true)} />}
            </div>
            <div style={{ width: "1%" }} />
            <div style={{ width: "20%", height: "100%" }}>
                {props.userState.user_type === "admin" &&
                    <CustomButton width={"100%"} height={"80%"} backgroundColor={"#ECAC68"} value={t('buttons.deleteSelection')} color={"#fff"}
                        onClick={() => setModalDeleteOpen(true)} />
                }
            </div>
        </div>
    )
}

export default PicturesTop;