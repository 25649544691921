import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomButton from "../../../customComponents/CustomButton";
import CustomTextField from "../../../customComponents/CustomTextField";
import CustomCheckbox from "../../../customComponents/CustomCheckbox";
import { setCurrentPageOnBoarding, setOnBoardingDone, setShowOnBoarding } from "../../../../actions_reducers/actions/files.action";
import { hardResetOnBoarding, setAverageGuestsOnBoarding, setCamsOnBoarding, setHasSaturdayEvening, setHasSaturdayNoon, setHasSaturdayOnBoarding, setHasSundayEvening, setHasSundayNoon, setHasSundayOnBoarding, setHasWeekEvening, setHasWeekNoon, setHasWeekOnBoarding, setSaturdayEveningHours, setSaturdayNoonHours, setSundayEveningHours, setSundayNoonHours, setWeekEveningHours, setWeekNoonHours } from "../../../../actions_reducers/actions/onBoarding.action";
import React, { useState } from 'react';

const year = "2023-2024";

export const FirstPage = (props) => {
    const [error_cameras, seterror_cameras] = useState(true);
    const [error_services, seterror_services] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const canBeModified = props.on_boarding_done;
    const hasAllServices = () => {
        let onBoardingValues = props.onBoardingState;
        if (onBoardingValues.hasWeek) {
            if ((onBoardingValues.hasWeekNoon && onBoardingValues.weekNoonHours.replace(/ /g, '') !== "")
                || (onBoardingValues.hasWeekEvening && onBoardingValues.weekEveningHours.replace(/ /g, '') !== "")) {
                return true;
            } else {
                return false;
            }
        }
        else if (onBoardingValues.hasSaturday) {
            if ((onBoardingValues.hasSaturdayNoon && onBoardingValues.saturdayNoonHours.replace(/ /g, '') !== "")
                || (onBoardingValues.hasSaturdayEvening && onBoardingValues.saturdayEveningHours.replace(/ /g, '') !== "")) {
                return true;
            } else {
                return false;
            }
        }
        else if (onBoardingValues.hasSunday) {
            if ((onBoardingValues.hasSundayNoon && onBoardingValues.sundayNoonHours.replace(/ /g, '') !== "")
                || (onBoardingValues.hasSundayEvening && onBoardingValues.sundayEveningHours.replace(/ /g, '') !== "")) {
                return true;
            } else {
                return false;
            }
        }
        else return false;
    }
    const hasAllCameras = () => {
        let hasAllCams;
        let onBoardingValues = props.onBoardingState;
        if (onBoardingValues.averageGuests !== 0 && onBoardingValues.averageGuests !== "") {
            if (props.cams.length === 1) {
                if (onBoardingValues.cams.length === props.cams.length) {
                    hasAllCams = true;
                } else {
                    hasAllCams = false;

                }
            }
            else {
                if (onBoardingValues.cams.length === props.cams.length - 1) {
                    hasAllCams = true;
                } else {
                    hasAllCams = false;

                }

            }
        }
        else {
            seterror_cameras(false);
            hasAllCams = false;
        }

        return hasAllCams;
    }

    const nextPage = () => {
        if (hasAllServices() === false)
            seterror_services(false);
        else
            seterror_services(true);

        if (hasAllCameras() === false)
            seterror_cameras(false);
        else
            seterror_cameras(true);

        if (hasAllServices() && hasAllCameras()) {
            dispatch(setCurrentPageOnBoarding(2));
        }
    }

    const backClick = () => {
        dispatch(setShowOnBoarding(false));
        if (!props.on_boarding_done)
            dispatch(hardResetOnBoarding())
    }

    const getWeekDays = () => {
        let onBoardingValues = props.onBoardingState;
        return (
            <div style={{ width: "100%", height: "100%", position: "relative" }}>
                <div style={{ width: "100%", height: "25%", paddingLeft: "1.5%", display: "flex", alignItems: "center" }}>
                    {t('onBoarding.daysUp')}
                </div>
                <div style={{ width: "100%", height: "25%", paddingLeft: "3%", display: "flex", alignItems: "center", fontSize: "0.9vw" }}>
                    <div style={{ width: "10%", display: "flex", alignItems: "center" }}>
                        <CustomCheckbox
                            disabled={canBeModified}
                            height={"2vh"}
                            width={"2vh"}
                            boxShadow={"none"}
                            borderColor={"#FF0000"}
                            backgroundColor={"#D9D9D9"}
                            onChange={() => { dispatch(setHasWeekOnBoarding(!onBoardingValues.hasWeek)) }}
                            checked={onBoardingValues.hasWeek} />
                    </div>
                    <div style={{ width: "90%", display: "flex", alignItems: "center" }}>
                        {t('onBoarding.weekDays')}
                    </div>
                </div>
                <div style={{ width: "100%", height: "25%", paddingLeft: "3%", display: "flex", alignItems: "center", fontSize: "0.9vw" }}>
                    <div style={{ width: "10%", display: "flex", alignItems: "center" }}>
                        <CustomCheckbox
                            disabled={canBeModified}
                            height={"2vh"}
                            width={"2vh"}
                            boxShadow={"none"}
                            backgroundColor={"#D9D9D9"}
                            onChange={(e) => dispatch(setHasSaturdayOnBoarding(!onBoardingValues.hasSaturday))}
                            checked={onBoardingValues.hasSaturday} />
                    </div>
                    <div style={{ width: "90%", display: "flex", alignItems: "center" }}>
                        {t('onBoarding.saturday')}
                    </div>
                </div>
                <div style={{ width: "100%", height: "25%", paddingLeft: "3%", display: "flex", alignItems: "center", fontSize: "0.9vw" }}>
                    <div style={{ width: "10%", display: "flex", alignItems: "center" }}>
                        <CustomCheckbox
                            disabled={canBeModified}
                            height={"2vh"}
                            width={"2vh"}
                            boxShadow={"none"}
                            backgroundColor={"#D9D9D9"}
                            onChange={(e) => dispatch(setHasSundayOnBoarding(!onBoardingValues.hasSunday))}
                            checked={onBoardingValues.hasSunday} />
                    </div>
                    <div style={{ width: "90%", display: "flex", alignItems: "center" }}>
                        {t('onBoarding.sunday')}
                    </div>
                </div>
            </div>
        )
    }

    const getServices = () => {
        let onBoardingValues = props.onBoardingState;
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ display: error_services ? "none" : "block", position: "absolute", top: "34%", left: "47.5%", width: "95%", height: "32%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.65vw", color: "red" }}>
                    {t('Veuillez selectionner au minimun un service par jour ainsi que les horaires correspondants')}
                </div>
                <div style={{ display: error_services ? "none" : "block", position: "absolute", top: "36.9%", left: "2.2%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.65vw", color: "red" }}>
                    {t('Veuillez selectionner au moins un jour')}
                </div>
                <div style={{ display: error_cameras ? "none" : "block", position: "absolute", top: "64.4%", left: "1.9%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.65vw", color: "red" }}>
                    {t('Veuillez renseigner les effectifs correspondants au/aux service(s) selectionné(s) précédemment')}
                </div>
                <div style={{ width: "17%", height: "25%", paddingLeft: "1.5%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {t('onBoarding.services')}
                </div>

                <div style={{ width: "100%", height: "25%", paddingLeft: "3%", fontSize: "0.9vw", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "20%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setHasWeekNoon(!onBoardingValues.hasWeekNoon))}
                                    checked={onBoardingValues.hasWeekNoon} />
                            </div>
                            <div style={{ width: "60%", display: "flex", alignItems: "center" }}>
                                {t('hours.noon')}
                            </div>
                        </div>
                        <div style={{ width: "80%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "20%", fontWeight: "bold" }}>
                                {t('components.hours')}
                            </div>
                            <div style={{ width: "80%" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    textAlign={"center"}
                                    onChange={(e) => dispatch(setWeekNoonHours(e.target.value))}
                                    value={onBoardingValues.weekNoonHours} />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "20%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "40%", display: "flex", alignItems: "center" }}>

                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setHasWeekEvening(!onBoardingValues.hasWeekEvening))}
                                    checked={onBoardingValues.hasWeekEvening} />
                            </div>
                            <div style={{ width: "60%", display: "flex", alignItems: "center" }}>
                                {t('hours.evening')}
                            </div>
                        </div>
                        <div style={{ width: "80%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "20%", fontWeight: "bold" }}>
                                {t('components.hours')}
                            </div>
                            <div style={{ width: "80%" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    textAlign={"center"}
                                    onChange={(e) => dispatch(setWeekEveningHours(e.target.value))}
                                    value={onBoardingValues.weekEveningHours} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", height: "25%", paddingLeft: "3%", fontSize: "0.9vw", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "20%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setHasSaturdayNoon(!onBoardingValues.hasSaturdayNoon))}
                                    checked={onBoardingValues.hasSaturdayNoon} />
                            </div>
                            <div style={{ width: "60%", display: "flex", alignItems: "center" }}>
                                {t('hours.noon')}
                            </div>
                        </div>
                        <div style={{ width: "80%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "20%", fontWeight: "bold" }}>
                                {t('components.hours')}
                            </div>
                            <div style={{ width: "80%" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    textAlign={"center"}
                                    onChange={(e) => dispatch(setSaturdayNoonHours(e.target.value))}
                                    value={onBoardingValues.saturdayNoonHours}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "20%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setHasSaturdayEvening(!onBoardingValues.hasSaturdayEvening))}
                                    checked={onBoardingValues.hasSaturdayEvening} />
                            </div>
                            <div style={{ width: "60%", display: "flex", alignItems: "center" }}>
                                {t('hours.evening')}
                            </div>
                        </div>
                        <div style={{ width: "80%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "20%", fontWeight: "bold" }}>
                                {t('components.hours')}
                            </div>
                            <div style={{ width: "80%" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    textAlign={"center"}
                                    onChange={(e) => dispatch(setSaturdayEveningHours(e.target.value))}
                                    value={onBoardingValues.saturdayEveningHours} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: "100%", height: "25%", paddingLeft: "3%", fontSize: "0.9vw", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "20%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setHasSundayNoon(!onBoardingValues.hasSundayNoon))}
                                    checked={onBoardingValues.hasSundayNoon} />
                            </div>
                            <div style={{ width: "60%", display: "flex", alignItems: "center" }}>
                                {t('hours.noon')}
                            </div>
                        </div>
                        <div style={{ width: "80%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "20%", fontWeight: "bold" }}>
                                {t('components.hours')}
                            </div>
                            <div style={{ width: "80%" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    textAlign={"center"}
                                    onChange={(e) => dispatch(setSundayNoonHours(e.target.value))}
                                    value={onBoardingValues.sundayNoonHours} />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "20%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "40%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => dispatch(setHasSundayEvening(!onBoardingValues.hasSundayEvening))}
                                    checked={onBoardingValues.hasSundayEvening} />
                            </div>
                            <div style={{ width: "60%", display: "flex", alignItems: "center" }}>
                                {t('hours.evening')}
                            </div>
                        </div>
                        <div style={{ width: "80%", height: "100%", display: "flex", alignItems: "center" }}>
                            <div style={{ width: "20%", fontWeight: "bold" }}>
                                {t('components.hours')}
                            </div>
                            <div style={{ width: "80%" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    textAlign={"center"}
                                    onChange={(e) => dispatch(setSundayEveningHours(e.target.value))}
                                    value={onBoardingValues.sundayEveningHours} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const updateAverageTraysCam = (e, cam, hour) => {
        let onBoardingValues = props.onBoardingState;
        let value = e.target.value;
        let cams = JSON.parse(JSON.stringify(onBoardingValues.cams));
        let index = cams.findIndex(a => a.camName === cam);
        if (index === -1) {
            let newCam = {
                camName: cam,
                noonGuests: "",
                eveningGuests: ""
            }
            if (hour === "noon")
                newCam.noonGuests = value;
            else if (hour === "evening")
                newCam.eveningGuests = value;
            cams.push(newCam);
        }
        else {
            if (hour === "noon")
                cams[index].noonGuests = value;
            else if (hour === "evening")
                cams[index].eveningGuests = value;
        }
        dispatch(setCamsOnBoarding(cams));
        dispatch(setAverageGuestsOnBoarding(getAverageTot()))
    }
    const getAverageTraysCam = (cam, hour) => {
        let onBoardingValues = props.onBoardingState;
        let index = onBoardingValues.cams.findIndex(a => a.camName === cam);
        if (index === -1)
            return "";
        else {
            if (hour === "noon")
                return onBoardingValues.cams[index].noonGuests;
            else if (hour === "evening")
                return onBoardingValues.cams[index].eveningGuests;
        }
    }
    const getAverageTot = () => {
        let tot = 0;
        for (const cam of props.onBoardingState.cams) {
            tot = parseInt(tot) + (parseInt(cam.noonGuests) || 0) + (parseInt(cam.eveningGuests) || 0)
        }
        if (tot !== 0) return tot;
        else return "";
    }
    const getCameras = () => {
        return (
            <div style={{ width: "100%", height: "70%" }}>
                <div style={{ height: "10%" }}></div>
                {props.cams.length === 1 ?
                    <div style={{ width: "100%", height: "25%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "35%", height: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "57.3%", height: "100%", paddingLeft: "1.3%" }}>
                                {t('onBoarding.noonEffectives')}
                            </div>
                            <div style={{ width: "22.5%", height: "100%" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    textAlign={"center"}
                                    type={"number"}
                                    width={"100%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    onChange={(e) => updateAverageTraysCam(e, "Total", "noon")}
                                    value={getAverageTraysCam("Total", "noon")}
                                />
                            </div>
                        </div>
                        <div style={{ width: "65%", height: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "25%", height: "100%" }}>
                                {t('onBoarding.eveningEffectives')}
                            </div>
                            <div style={{ width: "65%", height: "100%" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    textAlign={"center"}
                                    type={"number"}
                                    width={"20%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    onChange={(e) => updateAverageTraysCam(e, "Total", "evening")}
                                    value={getAverageTraysCam("Total", "evening")} />
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ height: "80%", width: "100%" }}>
                        {props.cams.map((item) => {
                            if (item.displayName !== "Total")
                                return (
                                    <div style={{ height: "60%" }}>
                                        <div style={{ height: 100 / (props.cams.length - 1) + "%", width: "90%", display: "flex", flexDirection: "row", paddingLeft: "0.5%" }}>
                                            <div style={{ width: "18%", height: "100%" }}>
                                                {t('onBoarding.cam', { nameCam: item.displayName })}
                                            </div>
                                            <div style={{ width: "49%", height: "100%", display: "flex", flexDirection: "row" }}>
                                                <div style={{ width: "92%", height: "100%", display: "flex", paddingLeft: "10%" }}>
                                                    {t('onBoarding.noonEffectives')}
                                                </div>

                                                <div style={{ width: "85%", height: "100%" }}>
                                                    <CustomTextField
                                                        disabled={canBeModified}
                                                        textAlign={"center"}
                                                        type={"number"} width={"50%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                                        onChange={(e) => updateAverageTraysCam(e, item.displayName, "noon")}
                                                        value={getAverageTraysCam(item.displayName, "noon")} />
                                                </div>
                                            </div>
                                            <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                                                <div style={{ width: "50%", height: "100%" }}>
                                                    {t('onBoarding.eveningEffectives')}
                                                </div>
                                                <div style={{ width: "70%", height: "100%" }}>
                                                    <CustomTextField
                                                        disabled={canBeModified}
                                                        textAlign={"center"}
                                                        type={"number"}
                                                        width={"40%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                                        onChange={(e) => updateAverageTraysCam(e, item.displayName, "evening")}
                                                        value={getAverageTraysCam(item.displayName, "evening")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            else return ""
                        })}
                    </div>
                }
                <div style={{ height: "16%" }}></div>
                <div style={{ width: "100%", height: "34%", display: "flex", flexDirection: "row", paddingLeft: "0.5%" }}>
                    <div style={{ width: "20%", height: "100%" }}>
                        {t('onBoarding.averageEffectives')}
                    </div>
                    <div style={{ width: "80%", height: "100%" }}>
                        <CustomTextField
                            disabled={true}
                            textAlign={"center"}
                            type={"number"}
                            width={"10%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                            value={getAverageTot()} />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div style={{ width: "100%", height: "100%", paddingLeft: "2%", paddingRight: "2%", color: "#666666" }}>

            <div style={{ width: "10%", height: "6.5%", position: "absolute", top: "3%", left: "2%" }}>
                <CustomButton
                    width={"100%"}
                    height={"100%"}
                    backgroundColor={"#666666"}
                    color={"#fff"}
                    onClick={() => backClick()}
                >
                    {t('buttons.back')}
                </CustomButton>
            </div>
            {
                props.user_type === "admin" &&
                <div style={{ width: "10%", height: "6.5%", position: "absolute", top: "3%", right: "2%" }}>
                    {
                        props.on_boarding_done ? <CustomButton
                            width={"100%"}
                            height={"100%"}
                            backgroundColor={"#68B4E3"}
                            color={"#fff"}
                            onClick={() => dispatch(setOnBoardingDone(false))}
                        >
                            {t('buttons.modify')}
                        </CustomButton> :
                            <CustomButton
                                width={"100%"}
                                height={"100%"}
                                backgroundColor={"#666666"}
                                color={"#fff"}
                                onClick={() => dispatch(setOnBoardingDone(true))}
                            >
                                {t('buttons.cancel')}
                            </CustomButton>
                    }
                </div>}
            <div className="on-boarding-title-row">
                {t('onBoarding.title', { year: year, page: " - Page 1/4" })}
            </div>
            <div style={{ display: error_services ? "none" : "block", position: "absolute", top: "24.5%", left: "2%", width: "95%", height: "32%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_cameras ? "none" : "block", position: "absolute", top: "58%", left: "2%", width: "95%", height: "30%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ height: "6%" }} />
            <div style={{ width: "100%", height: "12%" }}>
                <i>{t('onBoarding.description')}</i>
            </div>
            <div style={{ height: "2%" }} />
            <div style={{ width: "100%", height: "30%" }}>
                <div style={{ height: "14%", width: "100%", color: "#68B4E3", fontSize: "1.2vw", fontWeight: "bold" }}>
                    {t('onBoarding.serviceHours')}
                </div>
                <div style={{ height: "8%" }} />
                <div style={{ height: "78%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "25%", height: "100%" }}>
                        {getWeekDays()}
                    </div>
                    <div style={{ width: "75%", height: "100%" }}>
                        {getServices()}
                    </div>
                </div>
            </div>
            <div style={{ height: "5%" }} />
            <div style={{ width: "100%", height: "31%", justifyContent: "center", alignItems: "center" }}>
                <div style={{ height: "14%", width: "100%", color: "#68B4E3", fontSize: "1.2vw", fontWeight: "bold" }}>
                    {t('onBoarding.cams')}
                </div>
                <div style={{ height: "8%" }}></div>
                <div style={{ height: "70%", width: "100%" }}>
                    {getCameras()}
                </div>
            </div>
            <div style={{ width: "100%", height: "7%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <CustomButton
                    width={"15%"}
                    height={"100%"}
                    color={"#fff"}
                    backgroundColor={"#90C261"}
                    onClick={() => nextPage()}
                >
                    {t('buttons.next')}
                </CustomButton>
            </div>
        </div>
    )
}