import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";

let back_url = `${env.REACT_APP_BACK_URL}/files`;

async function pushFileToS3(url, file) {
    let response = await axios.put(url, file);
    return response;
}

async function deleteFileFromS3(url, file) {
    let response = await axios.delete(url, file);
    return response;
}
async function downloadFileFromS3(url, fileName) {
    let response = await axios({ url: url, method: 'GET', responseType: 'blob' });
    let blob = new Blob([response.data]);
    const blobUrl = URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement('a');
    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );

    // Remove link from body
    document.body.removeChild(link);
    return response;
}

export async function uploadFilesForRestaurant(fileName, file, restaurant_name, analysisNumber, dropType, fileType) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/upload", {
                params: { fileName: fileName, restaurant_name: restaurant_name, analysisNumber: analysisNumber, dropType: dropType, fileType: fileType },
            })
            .then((response) => {
                pushFileToS3(response.data, file);
            })
            .then((response) => resolve(response))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject(err);
            })
    });
}
export function deleteFileForRestaurant(fileName, type) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/deleteFile", {
                params: { fileName: fileName, type: type },
            })
            .then((response) => {
                deleteFileFromS3(response.data, fileName)
            })
            .then((response) => resolve(response))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject(err);
            })
    });
}
export function getFilesOfRestaurantAndAnalysis(path, type) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/files", {
                params: { path: path, type: type },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject(err);
            })
    });
}

export function downloadFileRestaurant(path, fileName, type) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/download", {
                params: { path: path, type: type },
            })
            .then(async (response) => await downloadFileFromS3(response.data, fileName))
            .then((response) => resolve(response))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject(err);
            })
    });
}
export async function downLoadSynthesisRestaurant(path, values) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/downloadSynthesis", {
                params: { path: path, values: values },
            })
            .then(async (response) => {
                for (const url of response.data) {
                    await downloadFileFromS3(url.url, url.fileName)
                }
            })
            .then((response) => resolve(response))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject(err);
            })
    });
}
export function hasSynthesisAnalysis(path) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/hasSynthesisAnalysis", {
                params: { path: path },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject(err);
            })
    });
}

export function getAllRessourcesFiles() {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/ressources"
            )
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject(err);
            })
    });
}

export function downLoadRessourceFile(path, fileName) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/downloadRessourceFile", {
                params: { path: path },
            })
            .then(async (response) => await downloadFileFromS3(response.data, fileName))
            .then((response) => resolve(response))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject(err);
            })
    });

}

export function downloadExcel(restaurant_name, analyzes, name) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${env.REACT_APP_BACK_URL}/excelExport/getExcelDataForRestaurant`, { params: { restaurant_name: restaurant_name, analyzes: analyzes }, responseType: 'blob' })
            .then(async (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                resolve("done")
            })
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject(err);
            })
    });
};

export function downloadExcelClient(clientName, analyzes, name) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${env.REACT_APP_BACK_URL}/excelExport/getExcelDataForClient`, { params: { clientName: clientName, analyzes: analyzes }, responseType: 'blob' })
            .then(async (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                resolve("done")
            })
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject(err);
            })
    });
};
