import React from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomButton from "../../../customComponents/CustomButton";
import CustomTextField from "../../../customComponents/CustomTextField";
import { resetNewClient, setNewClientMail, setNewClientName, setNewClientPassword } from "../../../../actions_reducers/actions/admin.action";
import { setCurrentPage } from "../../../../actions_reducers/actions/navigation.action";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";

const ClientListNewClient = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const checkValue = (value) => {
        if (value === "" || value === null)
            return false;
        else return true
    }
    const goToAddRestaurant = () => {
        if (checkValue(props.newClientName) && checkValue(props.newClientName) && checkValue(props.newClientName)) {
            updateArianneThread("addRestaurant", props.arianne, dispatch, "");
            dispatch(setCurrentPage("addRestaurant"));
        }
        else window.alert("Veuillez remplir tous les champs");

    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh", display: "flex", justifyContent: "center", color: "#666666" }}>
            <CustomBlock
                width={"100%"}
                height={"60%"}>
                <CustomButton
                    position={"absolute"}
                    top={"1%"}
                    left={"0.5%"}
                    width={"15%"}
                    height={"10%"}
                    color={"#fff"}
                    backgroundColor={"#666666"}
                    onClick={() => {
                        updateArianneThread("clientList", props.arianne, dispatch, "");
                        dispatch(setCurrentPage("clientList"));
                        dispatch(resetNewClient());
                    }}
                    value={t('buttons.back')} />
                <div style={{ height: "40%", width: "50%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <div style={{ height: "50%", width: "100%", display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                        Nom du client
                    </div>
                    <div style={{ height: "50%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CustomTextField width={"60%"} height={"50%"}
                            filter={"none"}
                            backgroundColor={"#D9D9D9"}
                            borderRadius={"10px"}
                            type={"text"}
                            value={props.newClientName}
                            onChange={(e) => dispatch(setNewClientName(e.target.value))} />

                    </div>

                </div>
                <div style={{ height: "40%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <div style={{ height: "50%", width: "100%", display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                            Adresse e-mail
                        </div>
                        <div style={{ height: "50%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CustomTextField width={"60%"} height={"50%"}
                                filter={"none"}
                                backgroundColor={"#D9D9D9"}
                                borderRadius={"10px"}
                                type={"text"}
                                value={props.newClientMail}
                                onChange={(e) => dispatch(setNewClientMail(e.target.value))} />

                        </div>
                    </div>
                    <div style={{ width: "50%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                        <div style={{ height: "50%", width: "100%", display: "flex", alignItems: "flex-end", justifyContent: "center" }}>
                            Mot de passe
                        </div>
                        <div style={{ height: "50%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <CustomTextField width={"60%"} height={"50%"}
                            filter={"none"}
                            backgroundColor={"#D9D9D9"}
                            borderRadius={"10px"}
                            type={"text"}
                            value={props.newClientPassword}
                            onChange={(e) => dispatch(setNewClientPassword(e.target.value))} />

                        </div>
                    </div>
                </div>
                <CustomButton
                    position={"absolute"}
                    bottom={"1%"}
                    right={"0.5%"}
                    width={"15%"}
                    height={"10%"}
                    color={"#fff"}
                    onClick={() => goToAddRestaurant()}
                    value={t('buttons.confirm')} />
            </CustomBlock>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        selectedRestaurant: state.adminReducer.selectedRestaurantModifs,
        newClientName: state.adminReducer.newClientName,
        newClientMail: state.adminReducer.newClientMail,
        newClientPassword: state.adminReducer.newClientPassword,
        arianne: state.navigationReducer.arianne
    };
}

export default connect(mapStateToProps)(ClientListNewClient);
