import { getNumberDaysExtrap } from '../../../customFunctions/extrapolation.function';

const formatNumber = (num) => {
    if (num)
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    else return num
};
const getValues = (analysis, kitchenAnalysis, averageTrays) => {
    let mass = 0;
    let massKitchen = 0;
    let trays = 0;
    let traysKitchen = 0;

    let numberDaysExtrapWeek = getNumberDaysExtrap('week');
    let numberDaysExtrapYear = getNumberDaysExtrap('year');

    if (analysis) {
        mass = parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
        trays = parseInt(analysis.trays);
    }
    if (kitchenAnalysis) {
        massKitchen = parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
        traysKitchen = parseInt(kitchenAnalysis.trays);
    }

    let massTot = parseFloat(mass) + parseFloat(massKitchen);

    let massConv = trays > 0 && parseFloat(mass) / parseInt(trays);
    let massConvKitchen = traysKitchen > 0 && parseFloat(massKitchen) / parseInt(traysKitchen);

    let massWeek = massConv * averageTrays * numberDaysExtrapWeek;
    let massKitchenWeek = massConvKitchen * averageTrays * numberDaysExtrapWeek;
    let massYear = massConv * averageTrays * numberDaysExtrapYear;
    let massKitchenYear = massConvKitchen * averageTrays * numberDaysExtrapYear;

    let massTotWeek = massWeek + massKitchenWeek
    let massTotYear = massYear + massKitchenYear

    let nbMeals = parseInt(massTot / 600);
    let nbMealsWeek = parseInt(massTotWeek / 600);
    let nbMealsYear = parseInt(massTotYear / 600);

    return {
        nbMeals: formatNumber(nbMeals),
        nbMealsWeek: formatNumber(nbMealsWeek),
        nbMealsYear: formatNumber(nbMealsYear),
    }

}

export const getSeventhSlide = (pres, t, analysis, kitchenAnalysis, x, averageTrays) => {
    let slide = pres.addSlide();
    let nbfrench1 = 1;
    let nbfrench2 = 5;

    let values = getValues(analysis, kitchenAnalysis, averageTrays)

    slide.addImage({ path: require('../assets/7/bubble.png'), x: "44.5%", y: "67%", w: "20%", h: "27%" })
    slide.addImage({ path: require('../assets/7/lasagna.png'), x: "57.5%", y: "65%", w: "21%", h: "21%" })
    slide.addImage({ path: require('../assets/7/titlearea.png'), x: "0%", y: "5%", w: "72%", h: "20%" })
    slide.addImage({ path: require('../assets/7/buuble2.png'), x: "62%", y: "12.5%", w: "35%", h: "55%" })
    slide.addImage({ path: require('../assets/7/textbox.png'), x: "8%", y: "32%", w: "21.5%", h: "14.5%" })
    slide.addImage({ path: require('../assets/7/textbox.png'), x: "32%", y: "32%", w: "21.5%", h: "14.5%" })
    slide.addImage({ path: require('../assets/7/textbox.png'), x: "20.7%", y: "50%", w: "21.5%", h: "14.5%" })

    slide.addText(t("synthesis.page7.wastesocialimpact"), { x: "2.5%", y: "10%", w: "50%", h: "10%", fontFace: 'Muli', align: "left",  color: 'FFFFFF', fontSize: 25, bold: true });
    slide.addText(t("synthesis.page7.nbMeals"), { x: "8.25%", y: "33.25%", w: "21%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 14, bold: true });
    slide.addText(t("synthesis.page7.nbMealsWeek"), { x: "32.2%", y: "33.25%", w: "21%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 14, bold: true });
    slide.addText(t("synthesis.page7.nbMealsYear"), { x: "21%", y: "51.25%", w: "21%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 14, bold: true });
    slide.addText(values.nbMeals, { x: "16%", y: "-7.5%", w: "100%", h: "100%", fontFace: 'Muli',  color: '54B0E3', fontSize: 17, bold: true });
    slide.addText(values.nbMealsWeek, { x: "40.5%", y: "-7.5%", w: "100%", h: "100%", fontFace: 'Muli',  color: '54B0E3', fontSize: 17, bold: true });
    slide.addText(values.nbMealsYear, { x: "28%", y: "10.5%", w: "100%", h: "100%", fontFace: 'Muli',  color: '54B0E3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page7.didYouKnow"), { x: "76.5%", y: "1%", w: "28%", h: "32%", fontFace: 'Muli',  color: '#54B0E3', fontSize: 17, bold: true, options: { align: "center" } });
    slide.addText(t("synthesis.page7.today"), { x: "67.5%", y: "16.4%", w: "28%", h: "32%", fontFace: 'Muli',  color: '666666', fontSize: 17, bold: true, options: { align: "center" } });
    slide.addText(t("synthesis.page7.comparaison", { value1: nbfrench1, value2: nbfrench2 }), { x: "71.5%", y: "26.4%", w: "28%", h: "32%", fontFace: 'Muli',  color: '#7ABCE2', fontSize: 17, bold: true, options: { align: "center" } });
    slide.addText(t("synthesis.page7.reduceWaste"), { x: "66.5%", y: "38.4%", w: "26%", h: "32%", fontFace: 'Muli',  color: '#90C261', fontSize: 17, bold: true, align: "center" });
    slide.addText(t("synthesis.page7.foodwastedesc"), { x: "3%", y: "78%", w: "43%", h: "10%", fontFace: 'Muli',  color: '54B0E3', fontSize: 21, bold: true });
    slide.addText(t("synthesis.page7.peopleneedno", { value: formatNumber(values.nbMealsYear) }), { x: "45.5%", y: "73%", w: "14%", h: "7%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 40, bold: true });
    slide.addText(t("synthesis.page7.peopleneed"), { x: "46%", y: "80%", w: "14%", h: "8%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 15, bold: true });
    slide.addText(t("synthesis.page7.byportions"), { x: "50%", y: "86.5%", w: "8%", h: "5%", fontFace: 'Muli',  color: '54B0E3', fontSize: 8, bold: true });
    slide.addText(x, { x: "97%", y: "96%", w: "3%", h: "3%", fontFace: 'Muli', align: "right",  color: '666666', fontSize: 15, bold: false });
    slide.background = { color: "#F8F8F3" }

    return pres;
}
