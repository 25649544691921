import React  from "react";
import { connect } from "react-redux"; // REDUX

const Planning = (props) => {
    return (
        <div className="main-page-main-div">
        </div>
    )
}
function mapStateToProps(state) {
    return {
    };
}

export default connect(mapStateToProps)(Planning);