export const getCategoryColor = (category) => {
    const categoryColors = {
        "Crudités": "#B1D490",
        "Déchets inévitables": "#949494",
        "Dessert": "#AE8FE0",
        "Féculents": "#F2C37F",
        "Fromage": "#D5D5D5",
        "Fruit": "#EA95C3",
        "Légumes": "#89A290",
        "Oeufs": "#FEE652",
        "Oeuf": "#FEE652",
        "Pain": "#F0AC62",
        "Plats composés": "#9CCEBE",
        "Produits de la mer": "#87C8EB",
        "Viande": "#F26E6E",
        "Viandes": "#F26E6E",
        "Autres": "#CACACA",
        "Others": "#CACACA",
    };

    if (category in categoryColors) {
        return categoryColors[category];
    } else {
        return null;
    }
}

export const getFoodsColors = (food) => {
    const foodColors = {
        "Total": "#90c261",
        "Autres": "#CACACA",
        "Autre plats composés": "#9CCEBE",
        "Autres desserts": "#AE8FE0",
        "Autres légumes": "#B1D490",
        "Feuille d'Artichaut": "#89A290",
        "Fruit": "#EA95C3",
        "Gâteau": "#F2C37F",
        "Hachi / Lasagne": "#F26E6E",
        "Jambon": "#EA95C3",
        "Légumes blancs": "#D5D5D5",
        "Légumes rouges": "#F26E6E",
        "Légumes verts": "#89A290",
        "Lentille": "#F2C37F",
        "Maïs": "#949494",
        "Oeuf": "#FDD11D",
        "Pâtes": "#F2C37F",
        "Peau de fruit": "#EA95C3",
        "Pizza / Quiche": "#FEE652",
        "Plats composés": "#CC1F3A",
        "Pois chiches": "#FEE652",
        "Poissons / Fruits de mer": "#87C8EB",
        "Tartinade": "#AE8FE0",
        "Viande": "#F26E6E",

        "Abricot": "#FFA500",
        "Agrume": "#DB7863",
        "Ananas": "#FFFF00",
        "Artichaut": "#CAFF78",
        "Asperge": "#CBEA9C",
        "Aubergine": "#9932CC",
        "Autres plats composés": "#6D6C7A",
        "Autres fruits": "#F2C2DC",
        "Autres gateaux": "#DA8B97",
        "Autres légumes blancs": "#D3D3D3",
        "Autres légumes rouges": "#D88894",
        "Autres légumes verts": "#C1E1CA",
        "Autres peaux de fruits": "#666666",
        "Avocat": "#D7FBB5",
        "Banane": "#F5F07D",
        "Beignet": "#FF69B4",
        "Betterave": "#8A4969",
        "Boulettes": "#BC620E",
        "Brocoli": "#4C9761",
        "Calamar": "#FF9D79",
        "Carotte": "#FF7F50",
        "Carotte râpée": "#F0AC62",
        "Céréales": "#97DEBC",
        "Champignon": "#7D6833",
        "Charcuterie": "#DE4A94",
        "Chili": "#F3164B",
        "Choucroute": "#DACC84",
        "Chou-de-Bruxelles": "#AEA133",
        "Chou-fleur": "#FFF4BA",
        "Choux blanc": "#F7F6DF",
        "Choux rouge": "#773EC2",
        "Compote": "#E6FA6B",
        "Concombre": "#CFFFA9",
        "Coquille": "#A7E8C6",//
        "Cornichon": "#95AA72",
        "Courge": "#FF9110",
        "Courgette": "#228933",
        "Couscous": "#F0E68C",
        "Couverts": "#DBBBEC",
        "Crème dessert": "#F7DD81",
        "Crepe": "#DDB292",
        "Crevette": "#EFA781",
        "Crumble": "#9A725D",
        "Endive Crue": "#E7FFD9",
        "Endive cuite": "#F0F2D4",
        "Epinard": "#196926",
        "Feuille / Aromate": "#806400",
        "Friand": "#F2C37F",
        "Frites": "#FEE652",
        "Fromage": "#4682B4",
        "Fruit à coque": "#DFEFCD",
        "Fruit rouge": "#D5808D",
        "Fruit sec": "#800000",
        "Fruit à coque": "#9D5623",
        "Galette": "#A17F66",
        "Gateau": "#CAE8C0",//
        "Gaufre": "#FED56B",
        "Gnocchi": "#F1E14F",
        "Guacamole": "#AAD185",
        "Hachi": "#841414",
        "Hamburger": "#F5B000",
        "Haricot blanc": "#FFE1D1",
        "Haricot rouge": "#AE1B3F",
        "Haricot vert": "#4EC160",
        "Houmous": "#D39B4A",
        "Jambon blanc": "#FAA8D1",
        "Kiwi": "#6D9249",
        "Lasagne": "#D51E1E",
        "Lentilles": "#395F55",
        "Main": "#9DE2BC",
        "Mais": "#FFEF8E",
        "Mangue": "#FF611D",
        "Mélange de légumes": "#4C6534",
        "Melon": "#FFB21D",
        "Mollusque": "#EAE8E4",
        "Mousse": "#855F29",
        "Nem / Samossa": "#B99460",
        "NonOrganique": "#E3E5BF",
        "Oeuf dur": "#FFF507",
        "Oignon": "#F8DBFF",
        "Olive": "#435931",
        "Omelette": "#E9FE6B",
        "Os": "#666666",
        "Paella": "#FFD707",
        "Pain": "#CF9746",
        "Pané": "#75602A",
        "Pastèque": "#DF6970",
        "Paté": "#E17BAC",
        "Pates": "#F2C37F",
        "Pates bolognaise": "#DD4625",
        "Pates carbonara": "#FEABCE",
        "Peau d'agrume": "#F2C37F",
        "Peau d'ananas": "#FFFF00",
        "Peau de banane": "#F5F07D",
        "Peau de kiwi": "#9A725D",
        "Peau de melon / pastèque": "#99DD59",
        "Peau de poisson": "#BDCAE3",
        "Pelure de légumes / fruits": "#666666",
        "Peche": "#FA5632",
        "Petit-pois": "#88F38C",
        "Pizza": "#EA95C3",
        "Poire": "#CFEA95",
        "Poireau": "#BEE7B7",
        "Pois chiche": "#FFF4B9",
        "Poisson": "#F5F1DD",
        "Poivron": "#FF0000",
        "Pomme": "#85E359",
        "Pomme de terre": "#EDF062",
        "Potatoes": "#F1D545",
        "Purée": "#FFF6C6",
        "Purée de légumes rouges": "#CA6060",
        "Purée de légumes verts": "#9DCA98",
        "Quenelle": "#B06F29",
        "Quiche": "#FFD8AD",
        "Radis": "#FF78D9",
        "Raisin": "#AE5991",
        "Ravioli": "#B43B3B",
        "Riz": "#D5D5D5",
        "Salade": "#CDFF9E",
        "Salade composée": "#EA881F",
        "Salade Composée": "#EA881F",
        "Salade de fruits": "#FFBD3C",
        "Sandwich": "#FFC6E5",
        "Saucisse": "#B64172",
        "Saumon": "#FFC3A9",
        "Soupe": "#CE802B",
        "Steak haché": "#704C4C",
        "Tapenade": "#433535",
        "Tarte": "#B684F6",
        "Thon": "#FF9A6F",
        "Tomate": "#FF3535",
        "Viande blanche": "#FFEFD7",
        "Viande blanche en sauce": "#AC8752",
        "Viande rouge": "#FF3535",
        "Viande rouge en sauce": "#9C1D1D",
        "Volaille": "#FFD0AE",
        "Wrap": "#FFE482",
        "Yaourt": "#FFECDE"


    };

    if (food in foodColors) {
        return foodColors[food];
    } else {
        return null;
    }
}
