const initialState = {
    allInformations: [],
    selectedClient: null,
    allClientAnalyzes: [],
    displayedClient: null,
    demoAccounts: [],
    allAccounts: [],
    selectedInformations: null,
    selectedRestaurantModifs: null,
    newClientName: null,
    newClientMail: null,
    newClientPassword: null,
    newRestaurantName: null,
    newRestaurantDisplayName: null,
    newRestaurantSynthesisName: null,
    newRestaurantMail: null,
    newRestaurantPassword: null,
    newRestaurantMorning: false,
    newRestaurantNoon: true,
    newRestaurantEvening: false,
    newRestaurantSaturday: false,
    newRestaurantSunday: false,
    newRestaurantMultipleCams: false,
    newRestaurantCamsList: [],
    newRestaurantHasTheoricNumber: false,
    newRestaurantUnit: "g",
    newRestaurantCostUnit: "€",
    newRestaurantAverageTrays: null,
    newRestaurantHasRestaurant: true,
    newRestaurantHasKitchen: false,
    newRestaurantNumberWeeksYear: null,
    selectedChatId: null,
    selectedAdminChatType: "open",
    loggedInChat: false,
    userIdsEmails: []
}


const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ALL_INFORMATIONS':
            return {
                ...state,
                allInformations: action.allInformations
            }
        case 'SET_SELECTED_CLIENT':
            return {
                ...state,
                selectedClient: action.selectedClient
            }
        case 'SET_ALL_CLIENT_ANALYZES':
            return {
                ...state,
                allClientAnalyzes: action.allClientAnalyzes
            }
        case 'SET_DISPLAYED_CLIENT':
            return {
                ...state,
                displayedClient: action.displayedClient
            }
        case 'SET_DEMO_ACCOUNTS':
            return {
                ...state,
                demoAccounts: action.demoAccounts
            }
        case 'SET_ALL_ACCOUNTS':
            return {
                ...state,
                allAccounts: action.allAccounts
            }
        case 'SET_SELECTED_INFORMATIONS':
            return {
                ...state,
                selectedInformations: action.selectedInformations
            }
        case 'SET_SELECTED_RESTAURANT_MODIFS':
            return {
                ...state,
                selectedRestaurantModifs: action.selectedRestaurantModifs
            }
        case 'SET_NEW_CLIENT_NAME':
            return {
                ...state,
                newClientName: action.newClientName
            }
        case 'SET_NEW_CLIENT_MAIL':
            return {
                ...state,
                newClientMail: action.newClientMail
            }
        case 'SET_NEW_CLIENT_PASSWORD':
            return {
                ...state,
                newClientPassword: action.newClientPassword
            }
        case 'SET_NEW_RESTAURANT_NAME':
            return {
                ...state,
                newRestaurantName: action.newRestaurantName
            }
        case 'SET_NEW_RESTAURANT_DISPLAY_NAME':
            return {
                ...state,
                newRestaurantDisplayName: action.newRestaurantDisplayName
            }
        case 'SET_NEW_RESTAURANT_SYNTHESIS_NAME':
            return {
                ...state,
                newRestaurantSynthesisName: action.newRestaurantSynthesisName
            }
        case 'SET_NEW_RESTAURANT_MAIL':
            return {
                ...state,
                newRestaurantMail: action.newRestaurantMail
            }
        case 'SET_NEW_RESTAURANT_PASSWORD':
            return {
                ...state,
                newRestaurantPassword: action.newRestaurantPassword
            }
        case 'SET_NEW_RESTAURANT_MORNING':
            return {
                ...state,
                newRestaurantMorning: action.newRestaurantMorning
            }
        case 'SET_NEW_RESTAURANT_NOON':
            return {
                ...state,
                newRestaurantNoon: action.newRestaurantNoon
            }
        case 'SET_NEW_RESTAURANT_EVENING':
            return {
                ...state,
                newRestaurantEvening: action.newRestaurantEvening
            }
        case 'SET_NEW_RESTAURANT_SATURDAY':
            return {
                ...state,
                newRestaurantSaturday: action.newRestaurantSaturday
            }
        case 'SET_NEW_RESTAURANT_SUNDAY':
            return {
                ...state,
                newRestaurantSunday: action.newRestaurantSunday
            }
        case 'SET_NEW_RESTAURANT_MULTIPLE_CAMS':
            return {
                ...state,
                newRestaurantMultipleCams: action.newRestaurantMultipleCams
            }
        case 'SET_NEW_RESTAURANT_CAMS_LIST':
            return {
                ...state,
                newRestaurantCamsList: action.newRestaurantCamsList
            }
        case 'SET_NEW_RESTAURANT_HAS_THEORIC_NUMBER':
            return {
                ...state,
                newRestaurantHasTheoricNumber: action.newRestaurantHasTheoricNumber
            }
        case 'SET_NEW_RESTAURANT_UNIT':
            return {
                ...state,
                newRestaurantUnit: action.newRestaurantUnit
            }
        case 'SET_NEW_RESTAURANT_COST_UNIT':
            return {
                ...state,
                newRestaurantCostUnit: action.newRestaurantCostUnit
            }
        case 'SET_NEW_RESTAURANT_AVERAGE_TRAYS':
            return {
                ...state,
                newRestaurantAverageTrays: action.newRestaurantAverageTrays
            }
        case 'SET_NEW_RESTAURANT_HAS_RESTAURANT':
            return {
                ...state,
                newRestaurantHasRestaurant: action.newRestaurantHasRestaurant
            }
        case 'SET_NEW_RESTAURANT_HAS_KITCHEN':
            return {
                ...state,
                newRestaurantHasKitchen: action.newRestaurantHasKitchen
            }
        case 'SET_NEW_RESTAURANT_NUMBER_WEEKS_YEAR':
            return {
                ...state,
                newRestaurantNumberWeeksYear: action.newRestaurantNumberWeeksYear
            }
        case 'SET_SELECTED_CHAT_ID':
            return {
                ...state,
                selectedChatId: action.selectedChatId
            }
        case 'SET_SELECTED_ADMIN_CHAT_TYPE':
            return {
                ...state,
                selectedAdminChatType: action.selectedAdminChatType
            }
        case 'SET_LOGGED_IN_CHAT':
            return {
                ...state,
                loggedInChat: action.loggedInChat
            }
        case 'SET_USER_IDS_EMAILS':
            return {
                ...state,
                userIdsEmails: action.userIdsEmails
            }
        case 'RESET_NEW_RESTAURANT':
            return {
                ...state,
                newRestaurantName: null,
                newRestaurantDisplayName: null,
                newRestaurantSynthesisName: null,
                newRestaurantMail: null,
                newRestaurantPassword: null,
                newRestaurantMorning: false,
                newRestaurantNoon: true,
                newRestaurantEvening: false,
                newRestaurantSaturday: false,
                newRestaurantSunday: false,
                newRestaurantMultipleCams: false,
                newRestaurantCamsList: [],
                newRestaurantHasTheoricNumber: false,
                newRestaurantUnit: "g",
                newRestaurantCostUnit: "€",
                newRestaurantAverageTrays: null,
                newRestaurantHasRestaurant: true,
                newRestaurantHasKitchen: false,
                newRestaurantNumberWeeksYear: null
            }
        case 'RESET__NEW_CLIENT': {
            return {
                ...state,
                newClientName: null,
                newClientMail: null,
                newClientPassword: null,
            }
        }
        case 'HARD_RESET_ADMIN':
            return initialState;
        default:
            return state;
    }
}

export default adminReducer;