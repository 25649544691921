import React, { useEffect, useState } from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import { useTranslation } from "react-i18next";
import { ReactComponent as Check } from "../../../assets/check.svg";
import { ReactComponent as Warning } from "../../../assets/warning.svg";
import { ReactComponent as Arrow } from "../../../assets/arrow.svg";
import CustomButton from "../../customComponents/CustomButton";
import CustomTextField from "../../customComponents/CustomTextField";
import CustomCheckbox from "../../customComponents/CustomCheckbox";
import { useDispatch } from "react-redux";
import images from "../../customFunctions/getImages";
import { setSelectedImageSamplesBin } from "../../../actions_reducers/actions/datas.action";
import 'moment/locale/fr';
import { getBaseUrls } from "../../customFunctions/user.function";
import CustomAutocomplete from "../../customComponents/CustomAutocomplete";
import { ImagesearchRoller } from "@mui/icons-material";

const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const SamplesBinList = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let baseUrl = getBaseUrls().urlBin;
    const [update, setUpdate] = useState(0);
    const [dropdownIsOpen, setDropdownIsOpen] = useState(null);
    const [modify, setModify] = useState(null);
    const [newValue, setNewValue] = useState(null);
    const [picturesToDelete, setPicturesToDelete] = useState([]);
    const [currentSamples, setCurrentSamples] = useState([]);
    useEffect(() => {
        setUpdate(old => old + 1);
        let samples = [];
        for (const sample of props.samplesBin) {
            let dateSample = moment(sample.created_at).format('YYYY-MM-DD');
            if (dateSample === props.selectedDay) {
                samples.push(sample)
            }
        }
        setCurrentSamples(samples)
    }, [props.selectedDay])
    const changeDropdownOpen = (e) => {
        if (dropdownIsOpen === null || (e && e.target.id !== dropdownIsOpen))
            setDropdownIsOpen(e.target.id);
        else
            setDropdownIsOpen(null);
    };
    const addSampleToUpdated = (sample) => {
        let updatedSamples = props.updatedSamplesBin;
        let index = updatedSamples.findIndex(a => a.created_at === sample.created_at);
        if (index === -1)
            updatedSamples.push(sample)
        else updatedSamples[index] = sample;
        return "done";
    }
    const changeSelectedAlim = (sample, e) => {
        if (e.target.id !== t('foods.Non organique')) {
            let food = props.food_list_kitchen.find(a => t(`foods.${a.nameFood}`) === e.target.id)
            if (food) {
                sample.category = food.categoryFood;
                sample.nameFood = food.nameFood;
                sample.category = food.categoryFood;
                sample.aliment_id = food.idFood;
                sample.status = "volume_done"
                let cost = parseFloat(sample.mass) * parseFloat(food.costfood);
                let co2 = parseFloat(sample.mass) * parseFloat(food.co2Food);
                sample.cost = cost;
                sample.co2 = co2;
                if (food.categoryFood === "Déchets inévitables") {
                    sample.inevitableMass = sample.mass;
                    sample.inevitableCost = cost;
                    sample.inevitableCo2 = co2;
                }
            }
        }
        else {
            sample.aliment_id = 350;
            sample.nameFood = "Non organique";
            sample.status = "volume_done";
        }
        addSampleToUpdated(sample)
    }

    const updateMassValue = (sample) => {
        if (sample.aliment_id !== null) {
            let food = props.food_list_kitchen.find(a => a.idFood === sample.aliment_id)
            sample.mass = parseFloat(newValue);
            if (food) {
                let cost = parseFloat(newValue) * parseFloat(food.costfood);
                let co2 = parseFloat(newValue) * parseFloat(food.co2Food);
                sample.cost = cost;
                sample.co2 = co2;
                if (food.categoryFood === "Déchets inévitables") {
                    sample.inevitableMass = sample.mass;
                    sample.inevitableCost = cost;
                    sample.inevitableCo2 = co2;
                }
            }
        }
        else {
            sample.mass = parseFloat(newValue);
        }
        addSampleToUpdated(sample)
        setNewValue(null);
        setModify(null);
    }
    const getAlimList = () => {
        let result = [];
        for (const alim of props.food_list_kitchen) {
            result.push(t(`foods.${alim.nameFood}`))
        }
        result.push(t('foods.Non organique'));
        result.sort((a, b) => a.localeCompare(b))
        return result;
    }
    const getFoodName = (sample) => {
        let alim = props.food_list_kitchen.find(a => parseInt(a.idFood) === parseInt(sample.aliment_id));
        if (alim)
            return t(`foods.${alim.nameFood}`)
    }
    const getDisplayedValue = (sample) => {
        if (sample.aliment_id !== null && sample.aliment_id !== 350) {
            return t(`foods.${props.food_list_kitchen.find(a => parseInt(a.idFood) === parseInt(sample.aliment_id)).nameFood}`);
        }
        else if (sample.aliment_id === 350) return t('foods.Non organique');
        else return t('table.alim');
    }


    const updateDeleteList = (e) => {
        let tmp = props.deletedSamplesBin;
        let index = tmp.findIndex(a => a === e.target.id);
        if (index === -1)
            tmp.push(e.target.id)
        else tmp.splice(index, 1);
        setPicturesToDelete([...tmp])
    }
    const checkKey = (e) => {
        if (props.selectedImage) {
            if (e.keyCode === 39) {
                nextUrl()
            }
            else if (e.keyCode === 37) {
                previousUrl()
            }
        }
    }
    document.onkeydown = checkKey;
    const previousUrl = () => {
        let newImageRef = null;
        let index = currentSamples.findIndex(a => a.image_ref === props.selectedImage);
        if (index === 0) {
            newImageRef = currentSamples[currentSamples.length - 1].image_ref;
        }
        else {
            newImageRef = currentSamples[index - 1].image_ref;
        }
        dispatch(setSelectedImageSamplesBin(newImageRef))
    }
    const nextUrl = () => {
        let newImageRef = null;
        let index = currentSamples.findIndex(a => a.image_ref === props.selectedImage);
        if (index === currentSamples.length - 1) {
            newImageRef = currentSamples[0].image_ref;
        }
        else {
            newImageRef = currentSamples[index + 1].image_ref;
        }
        dispatch(setSelectedImageSamplesBin(newImageRef))
    }
    const imageModal = () => {
        return (
            <CustomBlock id={props.selectedImage} width={"90%"} height={"110%"} right={"5%"} top={'-13%'} display={props.selectedImage === null ? "none" : "block"}
                position={"absolute"} zIndex={20000000000}>
                <div id={props.selectedImage} style={{ height: "90%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div id={props.selectedImage} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        onClick={() => previousUrl()}>
                        <Arrow id={props.selectedImage} className="arrow-left" />
                    </div>
                    <div id={props.selectedImage} style={{ width: "80%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img id={props.selectedImage} src={baseUrl + props.selectedImage} style={{ width: "60%", height: "auto" }} />
                    </div>
                    <div id={props.selectedImage} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        onClick={() => nextUrl()}>
                        <Arrow id={props.selectedImage} className="arrow-right" />
                    </div>
                </div>
                <div id={props.selectedImage} style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div id={props.selectedImage} style={{ width: "10%" }} />
                    <div id={props.selectedImage} style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {"image_ref : " + props.selectedImage}
                    </div>
                    <div id={props.selectedImage} style={{ width: "10%" }} />
                </div>
            </CustomBlock>
        )
    }
    const getRows = () => {
        let result = [];
        for (const [index, sample] of currentSamples.entries()) {
            let id = sample.created_at;
            let image_ref = sample.image_ref;
            let imageSource = image_ref != null ? baseUrl + sample.image_ref : images.foods[sample.nameFood.split(" ").join("_").replace("/", "_").replace("'", "_").replace(/-/g, "_")];

            result.push(
                <div id={image_ref} style={{ width: "100%", height: "25vh", display: "flex", flexDirection: "row", backgroundColor: index % 2 === 0 && "#F0F0F0" }}>
                    <div id={image_ref} style={{ width: props.login === "admin" ? "25%" : "40%", padding: "4%", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={(e) => image_ref != null && dispatch(setSelectedImageSamplesBin(e.target.id))} >
                        <img id={image_ref} style={{ width: image_ref != null ? "100%" : props.login === "admin" ? "50%" : "35%", height: "auto" }} src={imageSource} />
                    </div>
                    <div id={image_ref} style={{ width: props.login === "admin" ? "15%" : "20%", alignItems: "center", height: "auto", justifyContent: "center", display: "flex" }}>

                        {modify === id ?
                            <CustomTextField id={id} width={"70%"} height={"10%"} value={newValue || sample.mass} onChange={(e) => setNewValue(e.target.value)} />
                            : <div style={{ width: "100%", height: "100%", justifyContent: "center", display: "flex", alignItems: "center" }}>
                                {t('mass', { "value": { "mass": sample.mass * 1000, "unit": "kg", fixed: 2 } })}
                            </div>
                        }
                    </div>
                    {props.login === "admin" &&
                        <div style={{ width: "7%", alignItems: "center", height: "auto", justifyContent: "center", display: "flex" }}>
                            {modify === id ?
                                <div style={{ width: "100%", height: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <CustomButton width={"100%"} height={"12%"} value={t('buttons.confirm')} color={"#fff"} backgroundColor={"#72B9A2"} fontSize={"0.9vw"} onClick={() => updateMassValue(sample)} />
                                    <div style={{ height: "4%" }} />
                                    <CustomButton width={"100%"} height={"12%"} value={t('buttons.cancel')} color={"#fff"} backgroundColor={"#666666"} fontSize={"0.9vw"} onClick={() => setModify(null)} />
                                </div>
                                : <CustomButton width={"100%"} height={"12%"} value={t('buttons.modify')} color={"#fff"} backgroundColor={"#EA881F"} fontSize={"0.9vw"} onClick={() => setModify(id)} />}

                        </div>}
                    <div style={{ width: props.login === "admin" ? "23%" : "35%", alignItems: "center", height: "auto", justifyContent: "center", display: "flex" }}>

                        {props.login === "admin" ? <CustomAutocomplete
                            id={id}
                            autocomplete={true}
                            width={"70%"} height={"15%"} backgroundColor={"#90C261"} arrowColor={"#fff"} color={"#fff"}
                            displayedValue={getDisplayedValue(sample)}
                            selectedValue={getFoodName(sample)}
                            values={getAlimList()}
                            onChange={e => changeSelectedAlim(sample, e)}
                            isOpen={id === dropdownIsOpen}
                            maxHeightDropdown={"30vh"}
                            dropdownUpdate={Math.random()}
                            fontSize={"1vw"}
                            onClick={e => changeDropdownOpen(e)}
                            border={"1px solid #90C261"}
                        />
                            :
                            getFoodName(sample)
                        }
                    </div>
                    {props.login === "admin" &&
                        <div style={{ width: "12.5%", height: "auto", justifyContent: "center", alignItems: "center", display: "flex" }}>
                            <CustomCheckbox
                                height={"4vh"}
                                width={"4vh"}
                                backgroundColor={picturesToDelete.indexOf(id) > -1 ? "#666666" : "#D9D9D9"}
                                id={sample.created_at} checked={picturesToDelete.indexOf(id) > -1} onChange={updateDeleteList} />
                        </div>}
                    {props.login === "admin" &&
                        <div style={{ width: "12.5%", alignItems: "center", height: "auto", justifyContent: "center", display: "flex" }}>
                            {sample.aliment_id === null ? t('components.unverified') : t('components.verified')}
                        </div>}
                    {props.login === "admin" &&
                        <div style={{ width: "10%", alignItems: "center", height: "auto", justifyContent: "center", display: "flex" }}>
                            {sample.status !== "volume_done" ?
                                <Warning style={{ width: "40%", height: "40%" }} fill={"#EA881F"} /> :
                                <Check style={{ width: "40%", height: "40%" }} fill={"#72B9A2"} />
                            }
                        </div>}
                </div >
            )
        }
        return result;
    }
    return (
        <CustomBlock width={"100%"} height={"100%"}>
            {imageModal()}
            <div style={{ height: "15%", width: "100%", display: "flex", flexDirection: "row", color: "#6D6C7A", fontWeight: 500, fontSize: "1.2vw" }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: props.login === "admin" ? "25%" : "40%" }}>
                    {t('table.picture')}
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: props.login === "admin" ? "15%" : "20%" }}>
                    {t('table.weight')}
                </div>
                {props.login === "admin" &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "7%" }}>
                    </div>}
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: props.login === "admin" ? "23%" : "35%" }}>
                    {t('table.alim')}
                </div>
                {props.login === "admin" &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "12.5%" }}>
                    </div>}
                {props.login === "admin" &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "12.5%" }}>
                        {t('table.state')}
                    </div>}
                {props.login === "admin" &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "10%" }}>
                    </div>}
            </div>
            <div style={{ height: "85%", width: "100%" }}>
                <CustomScrollDiv update={update}>
                    {getRows()}
                </CustomScrollDiv>

            </div>
        </CustomBlock >
    )
}

export default SamplesBinList;
