// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Import Firebase storage


const firebaseConfig = {
    apiKey: "AIzaSyDzuOv3WU0h0lGGbpfvJ6WfhgG8PzmAU3U",
    authDomain: "chatkikleo.firebaseapp.com",
    projectId: "chatkikleo",
    storageBucket: "chatkikleo.appspot.com",
    messagingSenderId: "893318802897",
    appId: "1:893318802897:web:7661da24e7056d4dc884f0",
    measurementId: "G-93XSGY46EK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Auth
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
