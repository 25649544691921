import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomButton from "../../../customComponents/CustomButton";
import { setCurrentPageOnBoarding } from "../../../../actions_reducers/actions/files.action";
import CustomCheckbox from "../../../customComponents/CustomCheckbox";
import CustomTextField from "../../../customComponents/CustomTextField";
import { setBreadLimit, setBreadMass, setBreadMoment, setBreadPosition, setBreadRefill, setBreadType, setNumberBread, setReasonService, setSelfFoods, setServiceType, setBreadOrigin } from "../../../../actions_reducers/actions/onBoarding.action";

import React, { useState } from 'react';


const year = "2023-2024";

export const SecondPage = (props) => {
    const [error_services, seterror_services] = useState(true);
    const [error_bread, seterror_bread] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const canBeModified = props.on_boarding_done;

    const hasAllServices = () => {
        let onBoardingValues = props.onBoardingState;
        if (onBoardingValues.serviceType !== "") {
            if (onBoardingValues.serviceType === t('onBoarding.linear')) {
                if (onBoardingValues.reasonService.replace(/ /g, "") !== "")
                    return true;
                else return false;
            }
            else if (onBoardingValues.serviceType === t('onBoarding.semiPart')) {
                if (onBoardingValues.selfFoods.length > 0)
                    return true;
                else return false;
            }
            else if (onBoardingValues.selfFoods.length > 1)
                return true;
            else return false;
        }
        else return false;
    }

    const hasAllBread = () => {
        let onBoardingValues = props.onBoardingState;
        if (onBoardingValues.breadPosition === "")
            return false;
        else if (onBoardingValues.breadType === "")
            return false;
        else if (onBoardingValues.breadOrigin === "")
            return false;
        else if (onBoardingValues.breadMass === "")
            return false;
        else if (onBoardingValues.breadLimit === "")
            return false;
        else if (onBoardingValues.breadLimit === true && onBoardingValues.numberBread === "")
            return false;
        else if (onBoardingValues.breadRefill === "")
            return false;
        else if (onBoardingValues.breadRefill === true && onBoardingValues.breadMoment === "")
            return false;
        else return true;
    }

    const nextPage = () => {
        if (hasAllServices() === false)
            seterror_services(false);
        else
            seterror_services(true);

        if (hasAllBread() === false)
            seterror_bread(false);
        else
            seterror_bread(true);

        if (hasAllBread() && hasAllServices())
            dispatch(setCurrentPageOnBoarding(3))

    }
    const previousPage = () => {
        dispatch(setCurrentPageOnBoarding(1))
    }
    const updateServiceType = (e) => {
        let value = e.target.id;
        dispatch(setReasonService(""));
        dispatch(setSelfFoods([]));
        dispatch(setServiceType(value));
    }
    const updateComposant = (e, type) => {
        let value = e.target.id;
        let foods = JSON.parse(JSON.stringify(props.onBoardingState.selfFoods))
        if (type === "linear") {
            dispatch(setReasonService(e.target.value))
        }
        else if (type === 'semi') {
            foods = [value];
        }
        else {
            let index = foods.findIndex(a => a === value);
            if (index === -1) {
                foods.push(value);
            }
            else {
                foods.splice(index, 1)
            }
        }
        dispatch(setSelfFoods(foods));
    }
    const getServiceType = () => {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ display: error_services ? "none" : "block", position: "absolute", top: "9.5%", left: "14%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.746vw", color: "red" }}>
                    {t('Veuillez selectionner au moins une réponse parmis celles proposées ci-dessous')}
                </div>
                <div style={{ display: error_bread ? "none" : "block", position: "absolute", top: "54%", left: "14%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.76vw", color: "red" }}>
                    {t('Veuillez selectionner au moins une réponse parmis celles proposées ci-dessous')}
                </div>
                <div style={{ display: error_services ? "none" : "block", position: "absolute", top: "37.4%", left: "3.4%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                    {t('Veuillez sélectionner au moins 2 réponses')}
                </div>
                <div style={{ position: "absolute", top: "60%", left: "48.5%", width: "9%", height: "3.2%", paddingLeft: "1.5%", display: "flex", textDecoration: "underline", alignItems: "center", fontSize: "0.9vw", color: "#555B61" }}>
                    {t('Production')}
                </div>
                <div style={{ position: "absolute", top: "60%", left: "35%", width: "9%", height: "3.2%", paddingLeft: "1.5%", display: "flex", textDecoration: "underline", alignItems: "center", fontSize: "0.9vw", color: "#555B61" }}>
                    {t('Portions')}
                </div>
                <div style={{ position: "absolute", top: "61%", left: "46.15%", width: "0%", height: "10.5%", paddingLeft: "0%", display: "flex", alignItems: "center", border: "2px solid #9E9E9E", borderRadius: "3px" }}></div>

                <div style={{ width: "100%", height: "12%", color: "#68B4E3", fontSize: "1.2vw", fontWeight: "bold" }}>
                    {t('onBoarding.serviceType')}
                </div>
                <div style={{ width: "100%", height: "90%", display: "flex", flexDirection: "row", color: "#666666", zIndex: 2 }}>
                    <div style={{ height: "100%", width: "50%" }}>
                        <div style={{ height: "50%", width: "100%" }}>
                            <div style={{ width: "100%", height: "25%", display: "flex", flexDirection: "row" }}>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "18%", lineHeight: "90%" }}>
                                    <div style={{ width: "4%", height: "10%", paddingBottom: "5%" }}>
                                        <CustomCheckbox
                                            disabled={canBeModified}
                                            id={t('onBoarding.linear')}
                                            height={"2vh"}
                                            width={"2vh"}
                                            boxShadow={"none"}
                                            backgroundColor={"#D9D9D9"}
                                            onChange={(e) => updateServiceType(e)}
                                            checked={props.onBoardingState.serviceType === t('onBoarding.linear')} />
                                    </div>
                                    <div>
                                        <div>
                                            <div style={{ paddingTop: "0.5%" }}></div>
                                            <b>{t('onBoarding.linear')}</b>&nbsp;
                                            <span style={{ fontSize: "0.8vw" }}>
                                                {t('onBoarding.linearDesc')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", width: "90%", height: "30%", lineHeight: "90%", paddingLeft: "4%" }}>
                                <p>
                                    {t('onBoarding.whyService')}&nbsp;
                                    <span style={{ fontSize: "0.8vw" }}>
                                        {t('onBoarding.whyDesc')}
                                    </span >
                                </p>
                            </div>
                            <div style={{ height: "4%" }} />
                            <div style={{ height: "53%", width: "100%", paddingLeft: "4%" }}>
                                <textarea
                                    style={{ position: "relative" }}
                                    disabled={canBeModified || props.onBoardingState.serviceType !== t('onBoarding.linear')}
                                    className="on-boarding-text-area"
                                    onChange={(e) => updateComposant(e, "linear")}
                                    value={props.onBoardingState.reasonService} />
                            </div>
                        </div>
                        <div style={{ height: "13%" }} />
                        <div style={{ height: "54%", width: "100%" }}>
                            <div style={{ width: "100%", height: "20%", display: "flex", flexDirection: "row" }}>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "20%", lineHeight: "90%" }}>
                                    <div style={{ width: "4%", height: "100%" }}>
                                        <CustomCheckbox
                                            disabled={canBeModified}
                                            id={t('onBoarding.participative')}
                                            height={"2.3vh"}
                                            width={"2vh"}
                                            boxShadow={"none"}
                                            backgroundColor={"#D9D9D9"}
                                            onChange={(e) => updateServiceType(e)}
                                            checked={props.onBoardingState.serviceType === t('onBoarding.participative')} />
                                    </div>
                                    <p>
                                        <div style={{ paddingTop: "0.5%" }}></div>
                                        <b>{t('onBoarding.participative')}</b>&nbsp;
                                        <span style={{ fontSize: "0.8vw" }}>
                                            {t('onBoarding.participativeDesc')}
                                        </span >
                                    </p>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "19%", alignItems: "center", lineHeight: "90%", paddingLeft: "4%" }}>
                                {t('onBoarding.participativeChoices')}
                            </div>
                            <div style={{ height: "6%" }}></div>
                            <div style={{ height: "48%", width: "100%", paddingLeft: "4%" }}>
                                <div style={{ height: "25%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ height: "90%", width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ width: "12%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                id={t('onBoarding.appetizers')}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                disabled={canBeModified || props.onBoardingState.serviceType !== t('onBoarding.participative')}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => updateComposant(e, "part")}
                                                checked={(props.onBoardingState.selfFoods.findIndex(a => a === t('onBoarding.appetizers')) !== -1 &&
                                                    props.onBoardingState.serviceType === t('onBoarding.participative')
                                                )} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.appetizers')}
                                        </div>
                                    </div>
                                    <div style={{ height: "100%", width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ width: "12%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                id={t('onBoarding.vegetables')}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                disabled={canBeModified || props.onBoardingState.serviceType !== t('onBoarding.participative')}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => updateComposant(e, "part")}
                                                checked={(props.onBoardingState.selfFoods.findIndex(a => a === t('onBoarding.vegetables')) !== -1 &&
                                                    props.onBoardingState.serviceType === t('onBoarding.participative')
                                                )} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.vegetables')}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: "70%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ height: "100%", width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ width: "12%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                id={t('onBoarding.starch')}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                disabled={canBeModified || props.onBoardingState.serviceType !== t('onBoarding.participative')}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => updateComposant(e, "part")}
                                                checked={(props.onBoardingState.selfFoods.findIndex(a => a === t('onBoarding.starch')) !== -1 &&
                                                    props.onBoardingState.serviceType === t('onBoarding.participative')
                                                )} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.starch')}
                                        </div>
                                    </div>
                                    <div style={{ height: "100%", width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ width: "12%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                id={t('onBoarding.proteins')}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                disabled={canBeModified || props.onBoardingState.serviceType !== t('onBoarding.participative')}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => updateComposant(e, "part")}
                                                checked={(props.onBoardingState.selfFoods.findIndex(a => a === t('onBoarding.proteins')) !== -1 &&
                                                    props.onBoardingState.serviceType === t('onBoarding.participative')
                                                )} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.proteins')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "90%", width: "50%" }}>
                        <div style={{ height: "55%", width: "100%" }}>
                            <div style={{ width: "100%", height: "20%", display: "flex", flexDirection: "row" }}>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "20%", lineHeight: "90%" }}>
                                    <div style={{ width: "4%", height: "100%" }}>
                                        <CustomCheckbox
                                            disabled={canBeModified}
                                            id={t('onBoarding.semiPart')}
                                            height={"2vh"}
                                            width={"2vh"}
                                            boxShadow={"none"}
                                            backgroundColor={"#D9D9D9"}
                                            onChange={(e) => updateServiceType(e)}
                                            checked={props.onBoardingState.serviceType === t('onBoarding.semiPart')} />
                                    </div>
                                    <p>
                                        <div style={{ paddingTop: "0.4%" }}></div>
                                        <b>{t('onBoarding.semiPart')}</b>&nbsp;
                                        <span style={{ fontSize: "0.8vw" }}>
                                            {t('onBoarding.semiPartDesc')}
                                        </span >
                                    </p>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "5%", paddingLeft: "4%" }}>
                                {t('onBoarding.semiPartChoice')}
                            </div>
                            <div style={{ height: "100%", width: "100%", paddingLeft: "4%" }}>
                                <div style={{ height: "53%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ height: "100%", width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ width: "12%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                id={t('onBoarding.appetizers')}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                disabled={canBeModified || props.onBoardingState.serviceType !== t('onBoarding.semiPart')}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => updateComposant(e, "semi")}
                                                checked={(props.onBoardingState.selfFoods.findIndex(a => a === t('onBoarding.appetizers')) !== -1 &&
                                                    props.onBoardingState.serviceType === t('onBoarding.semiPart')
                                                )} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.appetizers')}
                                        </div>
                                    </div>
                                    <div style={{ height: "100%", width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ width: "12%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                id={t('onBoarding.vegetables')}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                disabled={canBeModified || props.onBoardingState.serviceType !== t('onBoarding.semiPart')}
                                                onChange={(e) => updateComposant(e, "semi")}
                                                checked={(props.onBoardingState.selfFoods.findIndex(a => a === t('onBoarding.vegetables')) !== -1 &&
                                                    props.onBoardingState.serviceType === t('onBoarding.semiPart')
                                                )} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.vegetables')}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ paddingBottom: "-19%", height: "0.1%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ height: "100%", width: "50%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "12%", height: "80%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                id={t('onBoarding.starch')}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                disabled={canBeModified || props.onBoardingState.serviceType !== t('onBoarding.semiPart')}
                                                onChange={(e) => updateComposant(e, "semi")}
                                                checked={(props.onBoardingState.selfFoods.findIndex(a => a === t('onBoarding.starch')) !== -1 &&
                                                    props.onBoardingState.serviceType === t('onBoarding.semiPart')
                                                )} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.starch')}
                                        </div>
                                    </div>
                                    <div style={{ height: "100%", width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ width: "12%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                id={t('onBoarding.proteins')}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                disabled={canBeModified || props.onBoardingState.serviceType !== t('onBoarding.semiPart')}
                                                onChange={(e) => updateComposant(e, "semi")}
                                                checked={(props.onBoardingState.selfFoods.findIndex(a => a === t('onBoarding.proteins')) !== -1 &&
                                                    props.onBoardingState.serviceType === t('onBoarding.semiPart')
                                                )} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.proteins')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }


    const getBreadDesc = () => {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ width: "40%", height: "20%", color: "#68B4E3", fontSize: "1.2vw", display: "flex", alignItems: "center", fontWeight: "bold", paddingTop: "1%" }}>
                    {t('onBoarding.bread')}
                </div>
                <div style={{ width: "100%", height: "90%", color: "#666666" }}>
                    <div style={{ height: "50%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ height: "100%", width: "30%" }}>
                            <div style={{ height: "20%", fontWeight: "bold" }}>
                                {t('onBoarding.whereBread')}
                            </div>
                            <div style={{ height: "100%", width: "100%" }}>
                                <div style={{ height: "50%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "50%", height: "90%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "18%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadPosition(t('onBoarding.start')))}
                                                checked={props.onBoardingState.breadPosition === t('onBoarding.start')} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.start')}
                                        </div>
                                    </div>
                                    <div style={{ width: "50%", height: "90%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "18%", height: "100%", display: "flex", alignItems: "center" }}>

                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadPosition(t('onBoarding.outside')))}
                                                checked={props.onBoardingState.breadPosition === t('onBoarding.outside')} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.outside')}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: "33%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "50%", height: "24%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "18%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadPosition(t('onBoarding.end')))}
                                                checked={props.onBoardingState.breadPosition === t('onBoarding.end')} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.end')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "5%" }} />
                        <div style={{ height: "100%", width: "30%" }}>
                            <div style={{ height: "20%", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", fontWeight: "bold" }}>
                                {t('onBoarding.breadType')}
                            </div>
                            <div style={{ height: "8%" }}></div>
                            <div style={{ height: "80%", width: "100%" }}>
                                <div style={{ height: "60%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "18%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                onChange={(e) => dispatch(setBreadType(t('onBoarding.ball')))}
                                                checked={props.onBoardingState.breadType === t('onBoarding.ball')} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.ball')}
                                        </div>
                                    </div>
                                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "15%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadOrigin(t('onBoarding.indus')))}
                                                checked={props.onBoardingState.breadOrigin === t('onBoarding.indus')} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.indus')}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: "20%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "18%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadType(t('onBoarding.slice')))}
                                                checked={props.onBoardingState.breadType === t('onBoarding.slice')} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.slice')}
                                        </div>
                                    </div>
                                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "15%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadOrigin(t('onBoarding.baked')))}
                                                checked={props.onBoardingState.breadOrigin === t('onBoarding.baked')} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.baked')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "5%" }} /><div style={{ height: "100%", width: "30%" }}>
                            <div style={{ height: "23%", fontWeight: "bold" }}>
                                {t('onBoarding.breadMass')}
                            </div>
                            <div style={{ height: "85%", width: "100%" }}>
                                <div style={{ height: "50%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "34%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "26%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadMass(t('mass', { "value": { "mass": 20, "unit": "g", fixed: 0 } })))}
                                                checked={props.onBoardingState.breadMass === t('mass', { "value": { "mass": 20, "unit": "g", fixed: 0 } })} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('mass', { "value": { "mass": 20, "unit": "g", fixed: 0 } })}
                                        </div>
                                    </div>
                                    <div style={{ width: "33%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadMass(t('mass', { "value": { "mass": 40, "unit": "g", fixed: 0 } })))}
                                                checked={props.onBoardingState.breadMass === t('mass', { "value": { "mass": 40, "unit": "g", fixed: 0 } })} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('mass', { "value": { "mass": 40, "unit": "g", fixed: 0 } })}
                                        </div>
                                    </div>
                                    <div style={{ width: "33%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadMass(t('mass', { "value": { "mass": 60, "unit": "g", fixed: 0 } })))}
                                                checked={props.onBoardingState.breadMass === t('mass', { "value": { "mass": 60, "unit": "g", fixed: 0 } })} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('mass', { "value": { "mass": 60, "unit": "g", fixed: 0 } })}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: "20%", width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "34%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "26%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadMass(t('mass', { "value": { "mass": 30, "unit": "g", fixed: 0 } })))}
                                                checked={props.onBoardingState.breadMass === t('mass', { "value": { "mass": 30, "unit": "g", fixed: 0 } })} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('mass', { "value": { "mass": 30, "unit": "g", fixed: 0 } })}
                                        </div>
                                    </div>
                                    <div style={{ width: "33%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadMass(t('mass', { "value": { "mass": 50, "unit": "g", fixed: 0 } })))}
                                                checked={props.onBoardingState.breadMass === t('mass', { "value": { "mass": 50, "unit": "g", fixed: 0 } })} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('mass', { "value": { "mass": 50, "unit": "g", fixed: 0 } })}
                                        </div>
                                    </div>
                                    <div style={{ width: "33%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadMass(t('onBoarding.moreThan', { value: t('mass', { "value": { "mass": 60, "unit": "g", fixed: 0 } }) })))}
                                                checked={props.onBoardingState.breadMass === t('onBoarding.moreThan', { value: t('mass', { "value": { "mass": 60, "unit": "g", fixed: 0 } }) })} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.moreThan', { value: t('mass', { "value": { "mass": 60, "unit": "g", fixed: 0 } }) })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "8%" }}></div>
                    <div style={{ height: "85%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", height: "100%" }}>
                            <div style={{ height: "3%", fontWeight: "bold" }}>
                                {t('onBoarding.guestsLimit')}
                            </div>
                            <div style={{ height: "85%", width: "100%" }}>
                                <div style={{ width: "40%", height: "30%", display: "flex", flexDirection: "row", paddingTop: "4.3%" }}>
                                    <div style={{ width: "37%", height: "100%", display: "flex", flexDirection: "row", paddingright: "2.5%" }}>
                                        <div style={{ width: "37%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadLimit(true))}
                                                checked={props.onBoardingState.breadLimit === true} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.yes')}
                                        </div>
                                    </div>
                                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "26%", height: "100%", display: "flex", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"4vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => {
                                                    dispatch(setBreadLimit(false));
                                                    dispatch(setNumberBread(""))
                                                }}
                                                checked={props.onBoardingState.breadLimit === false} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.no')}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: "5%" }}></div>
                                <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ paddingLeft: "2.4%", width: "65%", height: "100%" }}>
                                        {t('onBoarding.ifYesNumber')}
                                    </div>
                                    <div style={{ width: "35%", height: "100%" }}>
                                        <CustomTextField
                                            disabled={canBeModified || props.onBoardingState.breadLimit === false}
                                            width={"30%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                            textAlign={"center"}
                                            type={"number"}
                                            onChange={(e) => dispatch(setNumberBread(e.target.value))}
                                            value={props.onBoardingState.numberBread} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div style={{ width: "50%", height: "100%" }}>
                            <div style={{ height: "3%", fontWeight: "bold" }}>
                                {t('onBoarding.guestsRefill')}
                            </div>
                            <div style={{ height: "12%" }}></div>
                            <div style={{ height: "85%", width: "100%" }}>
                                <div style={{ width: "30%", height: "20%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row", paddingRight: "5%" }}>
                                        <div style={{ width: "42%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => dispatch(setBreadRefill(true))}
                                                checked={props.onBoardingState.breadRefill === true} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", width: "34%" }}>
                                            {t('onBoarding.yes')}
                                        </div>
                                    </div>
                                    <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "41%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <CustomCheckbox
                                                disabled={canBeModified}
                                                height={"2vh"}
                                                width={"2vh"}
                                                boxShadow={"none"}
                                                backgroundColor={"#D9D9D9"}
                                                onChange={(e) => {
                                                    dispatch(setBreadRefill(false));
                                                    dispatch(setBreadMoment(""))
                                                }}
                                                checked={props.onBoardingState.breadRefill === false} />
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {t('onBoarding.no')}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "48%", height: "100%", display: "flex", justifyContent: "center", paddingRight: "1.5%" }}>
                                        {t('onBoarding.ifYesRefill')}
                                    </div>
                                    <div style={{ width: "33%", height: "100%" }}>
                                        <CustomTextField
                                            disabled={canBeModified}
                                            width={"90%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                            textAlign={"center"}

                                            onChange={(e) => dispatch(setBreadMoment(e.target.value))}
                                            value={props.onBoardingState.breadMoment} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )

    }
    return (
        <div style={{ width: "100%", height: "90%", paddingLeft: "2%", paddingRight: "2%", }}>
            <div style={{ display: error_services ? "none" : "block", position: "absolute", top: "8%", left: "2%", width: "96%", height: "43%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_bread ? "none" : "block", position: "absolute", top: "52%", left: "2%", width: "96%", height: "35.5%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div className="on-boarding-title-row">
                {t('onBoarding.title', { year: year, page: " - Page 2/4" })}
            </div>
            <div style={{ height: "3%" }} />
            <div style={{ width: "100%", height: "44%" }}>
                {getServiceType()}
            </div>
            <div style={{ height: "5%" }} />
            <div style={{ width: "100%", height: "36%" }}>
                {getBreadDesc()}
            </div>

            <div style={{ height: "8%" }} />
            <div style={{ width: "100%", height: "8%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <CustomButton
                    width={"15%"}
                    height={"100%"}
                    color={"#fff"}
                    backgroundColor={"#666666"}
                    onClick={() => previousPage()}
                >
                    {t('buttons.previous')}
                </CustomButton>
                <div style={{ width: "2%" }} />
                <CustomButton
                    width={"15%"}
                    height={"100%"}
                    color={"#fff"}
                    backgroundColor={"#90C261"}
                    onClick={() => nextPage()}
                >
                    {t('buttons.next')}
                </CustomButton>
            </div>

        </div >
    )
}