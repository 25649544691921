export function setSelectedPageRessources(selectedPageRessources) {
    return {
        type: 'SET_SELECTED_PAGE_RESSOURCES',
        selectedPageRessources: selectedPageRessources
    }
}

export function setPaths(paths) {
    return {
        type: 'SET_PATHS',
        paths: paths
    }
}