import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import 'moment/locale/fr';
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import CustomButton from "../../customComponents/CustomButton";
import CustomTextField from "../../customComponents/CustomTextField";
import { setFoodList } from "../../../actions_reducers/actions/datas.action";
import { updateFoodRestaurant } from "../../../apiRoutes/food.routes";

const Foods = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [sortType, setSortType] = useState("nameFood")
    const [modifyAlim, setModifyAlim] = useState(null);
    const [nameRising,] = useState(true);
    const [categoryRising,] = useState(true);
    const [costRising,] = useState(true);

    const [updatedFoods, setUpdatedFoods] = useState(JSON.parse(JSON.stringify(props.food_list)))
    const [foodToUpdate, setFoodToUpdate] = useState(null);
    const sortTable = (a, b) => {
        switch (sortType) {
            case 'nameFood':
                if (nameRising)
                    return a.nameFood.localeCompare(b.nameFood);
                else
                    return b.nameFood.localeCompare(a.nameFood);
            case 'categoryFood':
                if (categoryRising)
                    return a.categoryFood.localeCompare(b.categoryFood);
                else
                    return b.categoryFood.localeCompare(a.categoryFood);
            case 'cost':
                if (costRising)
                    return parseFloat(a.costfood) - parseFloat(b.costfood);
                else
                    return parseFloat(b.costfood) - parseFloat(a.costfood);
            default:
                return a.nameFood.localeCompare(b.nameFood);
        }
    }
    const updateCostFood = (e, id) => {
        let foods = JSON.parse(JSON.stringify(props.food_list));
        let food = foods.find(a => a.idFood === id);
        food.costfood = parseFloat(e.target.value);
        setFoodToUpdate(food);
        setUpdatedFoods(foods);
    }

    const cancelChange = (id) => {
        let tmp = foodToUpdate;
        let index = tmp.findIndex(a => a.idFood === id);
        if (index !== -1) {
            tmp.splice(index, 1);
            setFoodToUpdate(tmp);
        }
    }

    const confirmChanges = async () => {
        await updateFoodRestaurant(foodToUpdate)
        dispatch(setFoodList(updatedFoods));
        setFoodToUpdate(null);
        setModifyAlim(null);
    }

    const getFoodsList = () => {
        let foods = updatedFoods;
        let result = [];
        foods.sort(sortTable)
        for (const [index, food] of foods.entries()) {
            result.push(
                <div style={{ height: "12%", width: "100%", display: "flex", flexDirection: "row", backgroundColor: index % 2 === 0 && "#F0F0F0" }}>
                    <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", paddingLeft: "5%" }}>
                        {t(`foods.${food.nameFood}`)}
                    </div>
                    <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", paddingLeft: "5%" }}>
                        {t(`foods.${food.categoryFood}`)}
                    </div>
                    <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>

                        {modifyAlim !== food.idFood ? parseFloat(food.costfood).toFixed(2) :
                            <CustomTextField type={"number"} step={"any"} value={food.costfood} textAlign={"right"} onChange={(e) => updateCostFood(e, food.idFood)} />}
                    </div>
                    {modifyAlim !== food.idFood ?
                        <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <CustomButton width={"40%"} height={"60%"} backgroundColor={"#72B9A2"} color={"#fff"} value={t('buttons.modify')} onClick={() => setModifyAlim(food.idFood)} />
                        </div>
                        :
                        <div style={{ width: "25%", height: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "45%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton width={"100%"} height={"60%"} backgroundColor={"#666666"} color={"#fff"} value={t('buttons.cancel')} onClick={() => cancelChange(food.idFood)} />
                            </div>
                            <div style={{ width: "5%" }} />
                            <div style={{ width: "45%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton width={"100%"} height={"60%"} backgroundColor={"#89CD85"} color={"#fff"} value={t('buttons.confirm')} onClick={() => confirmChanges()} />
                            </div>
                        </div>
                    }
                </div>
            )
        }
        return result;
    }

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row", fontWeight: "bold" }}>
                <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", paddingLeft: "5%", cursor: "pointer" }} onClick={() => setSortType('nameFood')}>
                    {t('table.alim')}
                </div>
                <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", paddingLeft: "5%", cursor: "pointer" }} onClick={() => setSortType('categoryFood')}>
                    {t('table.category')}
                </div>
                <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={() => setSortType('cost')}>
                    {t('table.costKg')}
                </div>
                <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}>
                    {t('table.actions')}
                </div>
            </div>
            <div style={{ height: "90%", width: "100%" }}>
                <CustomScrollDiv update={3}>
                    {getFoodsList()}
                </CustomScrollDiv>
            </div>
        </div>
    )
}

export default Foods;