import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/raspoubelleFood`;

export function getKitchenFoods(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getKitchenFoodsForRestaurant", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}
export function createFoodsKitchen(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/createFoodsKitchen", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}