import React, { useState } from "react";
const CustomBlock = (props) => {
    const [hover, setHover] = useState(false)

    const onMouseEnter = () => {
        setHover(true)
    }
    const onMouseLeave = () => {
        setHover(false)
    }
    const getBackgroundColor = () => {
        if (props.backgroundColor) {
            if (props.hasHover) {
                if (hover || props.selected) return props.backgroundColor;
                else return props.backgroundColor + "70";
            }
            else {
                return props.backgroundColor;
            }
        }
        else return "#ffffff"
    }
    return (
        <div className='custom-block'
            ref={props.ref}
            style={{
                minHeight: props.minHeight,
                whiteSpace: props.whiteSpace,
                height: props.height,
                width: props.width,
                marginLeft: props.marginLeft || 0,
                marginRight: props.marginRight || 0,
                marginTop: props.marginTop || 0,
                marginBottom: props.marginBottom || 0,
                backgroundColor: getBackgroundColor(),
                paddingLeft: props.paddingLeft || 0,
                paddingRight: props.paddingRight || 0,
                paddingTop: props.paddingTop || 0,
                paddingBottom: props.paddingBottom || 0,
                border: props.border || "none",
                boxShadow: props.boxShadow || "0px 4px 4px 0px #00000040",
                position: props.position || "relative",
                top: props.top,
                bottom: props.bottom,
                right: props.right,
                left: props.left,
                opacity: props.opacity || 1,
                display: props.display || "block",
                justifyContent: props.justifyContent,
                alignItems: props.alignItems,
                flexDirection: props.flexDirection,
                fontSize: props.fontSize,
                color: props.color,
                fontWeight: props.fontWeight,
                cursor: props.cursor,
                zIndex: props.zIndex
            }}
            onMouseEnter={props.onMouseEnter || onMouseEnter}
            onMouseLeave={props.onMouseLeave || onMouseLeave}>
            {props.children}
        </div>
    )
}

export default CustomBlock;