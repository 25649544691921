export function setDateStartSearch(dateStart) {
    return {
        type: 'SET_DATE_START_SEARCH',
        dateStart: dateStart
    }
}

export function setDateEndSearch(dateEnd) {
    return {
        type: 'SET_DATE_END_SEARCH',
        dateEnd: dateEnd
    }
}

export function setCumulativeTotal(cumulativeTotal) {
    return {
        type: 'SET_CUMULATIVE_TOTAL',
        cumulativeTotal: cumulativeTotal
    }
}

export function setAggregateSearch(aggregate) {
    return {
        type: 'SET_AGGREGATE_SEARCH',
        aggregate: aggregate
    }
}

export function setSelectedFoodsSearch(selectedFoods) {
    return {
        type: 'SET_SELECTED_FOODS_SEARCH',
        selectedFoods: selectedFoods
    }
}

export function setSelectedCategoriesSearch(selectedCategories) {
    return {
        type: 'SET_SELECTED_CATEGORIES_SEARCH',
        selectedCategories: selectedCategories
    }

}

export function setSelectedGraphValueSearch(selectedGraphValue) {
    return {
        type: 'SET_SELECTED_GRAPH_VALUE_SEARCH',
        selectedGraphValue: selectedGraphValue
    }
}

export function setSelectedRoomSearch(selectedRoom) {
    return {
        type: 'SET_SELECTED_ROOM_SEARCH',
        selectedRoom: selectedRoom
    }
}

export function setSelectedServiceSearch(selectedService) {
    return {
        type: 'SET_SELECTED_SERVICE_SEARCH',
        selectedService: selectedService
    }
}

export function setSelectedCamSearch(selectedCam) {
    return {
        type: 'SET_SELECTED_CAM_SEARCH',
        selectedCam: selectedCam
    }
}

export function hardResetSearch() {
    return {
        type: 'HARD_RESET_SEARCH'
    }
}

