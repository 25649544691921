import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import { ReactComponent as Euro } from "../../../../assets/euro.svg";
import { ReactComponent as Kg } from "../../../../assets/kg.svg";
import { ReactComponent as Earth } from "../../../../assets/earth.svg";
import 'moment/locale/fr';
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);


const ClientWaste = (props) => {
    const { t } = useTranslation();

    const getMaxValue = () => {
        let max = 1;
        for (const date of props.clientAnalyzesDates) {
            if (date.analysisNumber > max) max = date.analysisNumber
        }
        let hasMax = false;
        if (props.clientAnalyzesDates.length > 0 && props.clientAnalyzes.length > 0)
            while (hasMax === false) {
                let lastDate = props.clientAnalyzesDates.find(a => a.analysisNumber === max);
                if (lastDate) {
                    let testAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                        moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(lastDate.dateStart)))
                        &&
                        moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(lastDate.dateEnd)))
                    )
                    let testAnalyzesKitchen = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                        moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(lastDate.dateStart)))
                        &&
                        moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(lastDate.dateEnd)))
                    )
                    if ((!testAnalyzes || testAnalyzes.length === 0) && (!testAnalyzesKitchen || testAnalyzesKitchen.length === 0)) {
                        max -= 1;
                    }
                    else hasMax = true;
                }
                else hasMax = true
            }
        return max;
    }

    const getValues = () => {
        let max = getMaxValue();
        let dates = props.clientAnalyzesDates.find(a => parseInt(a.analysisNumber) === max);
        let selectedAnalyzes = [];
        if (dates)
            selectedAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
            );
        let mass = 0;
        let cost = 0;
        let co2 = 0;
        let trays = 0;
        let massKitchen = 0;
        let costKitchen = 0;
        let co2Kitchen = 0;
        let traysKitchen = 0;
        let traysDayTest = 0;
        let nbDaysTest = 0;
        let traysFinal = 0;
        for (const analysis of selectedAnalyzes) {
            let averageTrays = props.clientAverageGuests.find(a => a.restaurant_name === analysis.restaurant_name)
            mass = parseFloat(mass) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
            cost = parseFloat(cost) + parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost);
            co2 = parseFloat(co2) + parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2);
            trays = parseFloat(trays) + parseFloat(analysis.trays);

            traysDayTest = parseInt(traysDayTest) + parseInt(analysis.trays)
            nbDaysTest = parseInt(nbDaysTest) + parseInt(analysis.dates.length)

            let kitchenAnalysis = props.clientKitchenAnalyzes.find(a => a.analysisNumber === analysis.analysisNumber && a.restaurant_name === analysis.restaurant_name);
            if (kitchenAnalysis) {
                massKitchen = parseFloat(massKitchen) + parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
                costKitchen = parseFloat(costKitchen) + parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost);
                co2Kitchen = parseFloat(co2Kitchen) + parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2);
                traysKitchen = parseFloat(traysKitchen) + parseFloat(kitchenAnalysis.trays);

                traysDayTest = parseInt(traysDayTest) + parseInt(kitchenAnalysis.trays);
                nbDaysTest = parseInt(nbDaysTest) + parseInt(kitchenAnalysis.dates.length)
            }
            traysFinal = parseFloat(traysFinal) + (averageTrays ? averageTrays.value : parseInt(traysDayTest) / parseInt(nbDaysTest));
            traysDayTest = 0;
            nbDaysTest = 0;
        }

        let massConv = mass / trays;
        let massConvKitchen = massKitchen / (traysKitchen > 0 ? traysKitchen : 1);
        let costConv = cost / trays;
        let costConvKitchen = costKitchen / (traysKitchen > 0 ? traysKitchen : 1);
        let co2Conv = co2 / trays;
        let co2ConvKitchen = co2Kitchen / (traysKitchen > 0 ? traysKitchen : 1);

        let massConvTot = parseFloat(massConv) + parseFloat(massConvKitchen);
        let costConvTot = parseFloat(costConv) + parseFloat(costConvKitchen);
        let co2ConvTot = parseFloat(co2Conv) + parseFloat(co2ConvKitchen);

        let massFinal = parseInt(traysFinal) * massConvTot * 350;
        let costFinal = parseInt(traysFinal) * costConvTot * 350;
        let co2Final = parseInt(traysFinal) * co2ConvTot * 350;
        return {
            mass: t('mass', { value: { "mass": massFinal.toFixed(0), "unit": "kg" } }),
            cost: t('currency', { "value": { cost: costFinal.toFixed(0), "fixed": 0 } }),
            co2: t('mass', { value: { "mass": co2Final.toFixed(0), "unit": "kg" } }),
            massConv: t('mass', { value: { "mass": (massConvTot).toFixed(1), "unit": "g" } }),
        }
    }
    return (
        <CustomBlock width={'100%'} height={'100%'} paddingTop={"2%"} paddingBottom={"2%"} paddingLeft={"2%"} paddingRight={"2%"}>
            <div style={{ height: "55%", width: "100%" }}>
                <div className="dashboard-block-title" style={{ height: "10%" }}>
                    {t('components.wasteState')}
                </div>
                <div style={{ width: "100%", height: "70%", display: 'flex', flexDirection: "row", alignItems: "center" }}>
                    <div style={{ width: "50%", color: "#666666" }}>
                        {t('components.averageWasteConv')}

                    </div>
                    <div style={{ width: "50%", height: "100%", display: 'flex', alignItems: "center" }}>
                        <CustomBlock
                            backgroundColor={"#68B4E3"}
                            color={"#fff"}
                            width={"30%"}
                            height={"40%"}
                            marginLeft={"8%"}
                        >
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", color: "#fff" }}>
                                {getValues().massConv}

                            </div>

                        </CustomBlock>
                    </div>
                </div>
                <div style={{ width: "100%", height: "20%", color: "#666666" }}>
                    {t('components.totalWaste')}
                </div>
            </div>
            <div style={{ width: "100%", height: "45%", flexDirection: "row", display: "flex" }}>

                <CustomBlock
                    backgroundColor={"#7DCBB9"}
                    color={"#fff"}
                    width={"30%"}
                    height={"100%"}
                    paddingRight={"2%"}
                    paddingBottom={"1%"}
                    paddingTop={"1%"}
                    paddingLeft={"2%"}
                >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "50%", color: "#fff" }}>
                        <div style={{ width: "35%", height: "100%", display: "flex", alignItems: "center" }}>
                            <Kg style={{ width: "100%", height: "100%" }} />
                        </div>
                        <div style={{ width: "65%", height: "100%", display: "flex", alignItems: "center", fontSize: "1.3vw", textAlign: "center" }}>
                            {getValues().mass}
                        </div>
                    </div>
                    <div style={{ fontSize: "0.9vw", width: "100%", display: "flex", alignItems: "center", textAlign: "center", height: "50%", color: "#fff", paddingLeft: "5%", paddingRight: "5%" }}>
                        {t('components.wasteConso')}
                    </div>
                </CustomBlock>
                <div style={{ width: "5%" }} />
                <CustomBlock
                    backgroundColor={"#89CD85"}
                    color={"#fff"}
                    width={"30%"}
                    height={"100%"}
                    paddingRight={"2%"}
                    paddingBottom={"1%"}
                    paddingTop={"1%"}
                    paddingLeft={"2%"}
                >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "50%", color: "#fff" }}>
                        <div style={{ width: "35%", height: "100%", display: "flex", alignItems: "center" }}>
                            <Euro style={{ width: "100%", height: "80%" }} />
                        </div>
                        <div style={{ width: "65%", height: "100%", display: "flex", alignItems: "center", fontSize: "1.3vw", textAlign: "center" }}>
                            {getValues().cost}
                        </div>
                    </div>
                    <div style={{ fontSize: "0.9vw", width: "100%", display: "flex", alignItems: "center", textAlign: "center", height: "50%", color: "#fff", paddingLeft: "5%", paddingRight: "5%" }}>
                        {t('components.costConso')}
                    </div>
                </CustomBlock>
                <div style={{ width: "5%" }} />
                <CustomBlock
                    backgroundColor={"#90C261"}
                    color={"#fff"}
                    width={"30%"}
                    height={"100%"}
                    paddingRight={"2%"}
                    paddingBottom={"1%"}
                    paddingTop={"1%"}
                    paddingLeft={"2%"}
                >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "50%", color: "#fff" }}>
                        <div style={{ width: "35%", height: "100%", display: "flex", alignItems: "center" }}>
                            <Earth style={{ width: "100%", height: "80%" }} />
                        </div>
                        <div style={{ width: "65%", height: "100%", display: "flex", alignItems: "center", fontSize: "1.3vw", textAlign: "center" }}>
                            {getValues().co2}
                        </div>
                    </div>
                    <div style={{ fontSize: "0.9vw", width: "100%", display: "flex", alignItems: "center", textAlign: "center", height: "50%", color: "#fff", paddingLeft: "5%", paddingRight: "5%" }}>
                        {t('components.co2Rejected')}
                    </div>
                </CustomBlock>
            </div>
        </CustomBlock>
    )
}
export default ClientWaste;