import React from "react";
import { useDispatch } from "react-redux";
import { setSelectedPicturesDate, setSelectedRestaurantAnalysisPictures, setSelectedRestaurantPictures } from "../../../../actions_reducers/actions/client.action";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import { useTranslation } from "react-i18next";

const ClientPicturesSelect = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const getInitialName = (restaurant_name) => {
        let restaurant = props.displayNames.find(a => a.restaurant_name === restaurant_name || a.value === restaurant_name);
        if (restaurant) return restaurant.restaurant_name;
        else return restaurant.restaurant_name;

    }
    const changeSelectedRestaurant = (e) => {
        let restaurant_name = e.target.id;
        dispatch(setSelectedRestaurantAnalysisPictures(null));
        dispatch(setSelectedPicturesDate(null));

        dispatch(setSelectedRestaurantPictures(getInitialName(restaurant_name)));
    }

    const changeSelectedAnalysis = (e) => {
        let analysisNumber = e.target.id;
        dispatch(setSelectedPicturesDate(null));

        dispatch(setSelectedRestaurantAnalysisPictures(analysisNumber));
    }

    const changeSelectedDate = (e) => {
        let date = e.target.id;
        dispatch(setSelectedPicturesDate(date));
    }

    const getName = (restaurant_name) => {
        let restaurant = props.displayNames.find(a => a.restaurant_name === restaurant_name);
        if (restaurant) return restaurant.value;
        else return restaurant_name;
    }
    const getRestaurantsDropdownValues = () => {
        let result = [];
        for (const analysis of props.analyzes) {
            if (!result.find(a => a === getName(analysis.restaurant_name)))
                result.push(getName(analysis.restaurant_name));
        }
        result.sort((a, b) => a.localeCompare(b))
        return result;
    }

    const getAnalyzesDropdownValues = () => {
        let result = [];
        let analyzes = JSON.parse(JSON.stringify(props.analyzes));
        if (props.selectedRestaurantPictures) {
            let currentAnalyzes = analyzes.filter(a => a.restaurant_name === props.selectedRestaurantPictures);
            for (const analysis of currentAnalyzes) {
                result.push(t('components.analysisName', { analysisNumber: analysis.analysisNumber }))
            }
        }
        result.sort((a, b) => { return (+a.replace("Analyse", "")) - (+b.replace("Analyse", "")) })
        return result;
    }

    const getDatesDropdownValues = () => {
        let result = [];
        let analyzes = JSON.parse(JSON.stringify(props.analyzes));
        if (props.selectedAnalysis) {
            let analysisNumber = parseInt(props.selectedAnalysis.split(t('table.analysis'))[1]);
            let currentAnalysis = analyzes.find(a => a.restaurant_name === props.selectedRestaurantPictures && a.analysisNumber === analysisNumber);
            for (const day of currentAnalysis.dates) {
                result.push(t('date', { "value": { "date": day, "format": "DD-MM-YYYY" } }))
            }
        }
        result.sort((a, b) => a.localeCompare(b));
        return result;
    }
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
            <CustomDropdown
                width={"30%"}
                height={"100%"}
                backgroundColor={"#68B4E3"}
                color={"#fff"}
                arrowColor={"#fff"}
                maxHeightDropdown={"50vh"}
                displayedValue={getName(props.selectedRestaurantPictures) || t('components.selectRestaurant')}
                selectedValue={props.selectedRestaurantPictures}
                values={getRestaurantsDropdownValues()}
                onChange={changeSelectedRestaurant}
            />
            <div style={{ width: "5%" }} />
            <CustomDropdown
                width={"30%"}
                height={"100%"}
                backgroundColor={"#72B9A2"}
                color={"#fff"}
                arrowColor={"#fff"}
                displayedValue={props.selectedAnalysis || t('components.selectAnalysis')}
                selectedValue={props.selectedAnalysis}
                values={getAnalyzesDropdownValues()}
                onChange={changeSelectedAnalysis} />
            <div style={{ width: "5%" }} />
            <CustomDropdown
                width={"30%"}
                height={"100%"}
                backgroundColor={"#7DCBB9"}
                color={"#fff"}
                arrowColor={"#fff"}
                maxHeightDropdown={"50vh"}
                displayedValue={props.selectedDate || t('components.selectDate')}
                selectedValue={props.selectedDate}
                values={getDatesDropdownValues()}
                onChange={changeSelectedDate} />
        </div >
    )
}

export default ClientPicturesSelect;
