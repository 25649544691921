import { ResponsiveLine } from '@nivo/line';
const SensiLineChart = (props) => {
    const data = props.data;
    const getMax = () => {
        let max = 0;
        for (const data of props.data) {
            for (const data2 of data.data) {
                if (data2.y > max)
                    max = data2.y;
            }
        }
        return max + max ;
    }
    const getMarker = (t) => {
        if (props.markerValue && t) {
            let markers = [];
            for (const [index, point] of t.points.entries()) {
                if (index !== 0 && index !== t.points.length - 1) {
                    let marker = [
                        <svg key={"marker-1"}>
                            <line 
                                id={"index"}
                                stroke={"#666666"}
                                strokeWidth={2}
                                opacity={.62}
                                x1={point.x}
                                y1={t.innerHeight}
                                y2={point.y - 45}
                                x2={point.x}
                            />
                            <circle cx={point.x} cy={point.y - 40} r="25" style={{
                                fill: '#000000',
                                opacity: '0.25', transform: 'translate(2px, 2px)'
                            }} />
                            <circle cx={point.x} cy={point.y - 40} r="25" fill={"#9BCBB5"} />
                            <text x={point.x} y={point.y - 35} textAnchor='middle' fill="#fff" fontWeight="bold">25,2 g</text>
                            <text x={point.x} y={point.y - 70} textAnchor='middle' fill="#7ABCE2" fontWeight="bold">02/24</text>
                        </svg>
                    ]
                    markers.push(<g>{marker}</g>)
                }
            }
            return markers
        }
        else return null;
    }
    return (<ResponsiveLine
        id={props.id}
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 0,
            max: getMax(),
            stacked: false,
            reverse: false
        }}
        yFormat=" >-.3f"
        curve="linear"
        axisTop={null}
        axisRight={null}
        axisBottom={props.axisBottom || null}
        axisLeft={null}
        lineWidth={0}
        defs={
            [{
                id: props.id,
                type: 'linearGradient',
                colors: [
                    { offset: 0, color: props.color, opacity: 1 },
                    { offset: 100, color: props.color, opacity: 1 },
                ],
            }]}
        fill={
            [
                { match: '*', id: props.id },
            ]
        }
        enableGridX={false}
        enableGridY={false}
        pointSize={0}
        enableArea={true}
        colors={d => d.color || d}
        areaOpacity={1}
        useMesh={true}
        animate={false}
        isInteractive={false}
        markers={getMarker()}
        layers={
            [
                "grid",
                "axes",
                "areas",
                "lines",
                "points",
                "slices",
                "mesh",
                "legends",
                getMarker]}
    />)
}

export default SensiLineChart;