import { getNumberDaysExtrap } from '../../../customFunctions/extrapolation.function';
import { getCost } from '../SensiFunctions';
import { addCatsBars, getCatValues } from '../SynthesisFunctions';

const getValues = (analysis, kitchenAnalysis, t, food_list, food_list_kitchen, costType, averageTrays) => {
    let cost = 0;
    let costKitchen = 0;
    let trays = 0;
    let traysKitchen = 0;


    let numberDaysExtrapWeek = getNumberDaysExtrap('week');
    let numberDaysExtrapYear = getNumberDaysExtrap('year');

    if (analysis) {
        cost = costType === "commodity_cost" ? getCost(analysis, food_list) : parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost);
        trays = parseInt(analysis.trays);
    }
    if (kitchenAnalysis) {
        costKitchen = costType === "commodity_cost" ? getCost(kitchenAnalysis, food_list_kitchen) : parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost);
        traysKitchen = parseInt(kitchenAnalysis.trays);
    }

    let costTot = parseFloat(cost) + parseFloat(costKitchen);

    let costConv = trays > 0 && parseFloat(cost) / parseInt(trays);
    let costConvKitchen = traysKitchen > 0 && parseFloat(costKitchen) / parseInt(traysKitchen);
    let costConvTot = costConv + costConvKitchen;

    let costWeek = costConv * averageTrays * numberDaysExtrapWeek;
    let costKitchenWeek = costConvKitchen * averageTrays * numberDaysExtrapWeek;
    let costYear = costConv * averageTrays * numberDaysExtrapYear;
    let costKitchenYear = costConvKitchen * averageTrays * numberDaysExtrapYear;

    let costTotWeek = costWeek + costKitchenWeek
    let costTotYear = costYear + costKitchenYear
    let eco = t('currency', { value: { cost: costTotYear / 2, fixed: 0 } });

    return {
        costTot: costTot,
        costConvTot: costConvTot,
        costWeek: costTotWeek,
        costYear: costTotYear,
        eco: eco
    }

}

export const getFifthSlide = (pres, t, analysis, kitchenAnalysis, x, food_list, food_list_kitchen, costType, averageTrays) => {
    let slide = pres.addSlide();
    let values = getValues(analysis, kitchenAnalysis, t, food_list, food_list_kitchen, costType, averageTrays);
    let cats = getCatValues(analysis, kitchenAnalysis, food_list, food_list_kitchen, costType);

    addCatsBars(pres, slide, t, cats);

    slide.addImage({ path: require('../assets/4/Component 5.png'), x: "73.1%", y: "0%", w: "27%", h: "40%" })
    slide.addImage({ path: require('../assets/4/Ellipse 49.png'), x: "0%", y: "6.5%", w: "60%", h: "15%" })
    slide.addImage({ path: require('../assets/5/Ellipse 51.png'), x: "0%", y: "18%", w: "25%", h: "11%" })
    slide.addImage({ path: require('../assets/5/textbox.png'), x: "59.1%", y: "41%", w: "18.5%", h: "13.5%" })
    slide.addImage({ path: require('../assets/5/textbox.png'), x: "59.1%", y: "56.7%", w: "18.5%", h: "13.5%" })
    slide.addImage({ path: require('../assets/5/textbox.png'), x: "59.1%", y: "72.5%", w: "18.5%", h: "13.5%" })

    slide.addText(t("synthesis.global.loosediagnostic"), { x: "2.5%", y: "8%", w: "45%", h: "10%", fontFace: 'Muli', align: "left",  color: 'FFFFFF', fontSize: 25, bold: true });
    slide.addText(t("synthesis.page5.loosecost"), { x: "2.5%", y: "20.5%", w: "30%", h: "5%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 22, bold: true });
    slide.addText(t("synthesis.page5.bubbledesc", { eco: values.eco }), { x: "76%", y: "10%", w: "22%", h: "13%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 13, bold: true });
    slide.addText(t("synthesis.page5.guestcost"), { x: "59.5%", y: "42%", w: "18%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 13, bold: true });
    slide.addText(t('currency', { value: { "cost": values.costConvTot, fixed: 2 } }), { x: "60.7%", y: "48.2%", w: "16%", h: "5%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page5.weekcost"), { x: "59.5%", y: "57.7%", w: "18%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 13, bold: true });
    slide.addText(t('currency', { value: { "cost": values.costWeek, fixed: 0 } }), { x: "60.7%", y: "64%", w: "16%", h: "5%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page5.yearcost"), { x: "59.5%", y: "73.6%", w: "18%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 13, bold: true });
    slide.addText(t('currency', { value: { "cost": values.costYear, fixed: 0 } }), { x: "60.7%", y: "79.9%", w: "16%", h: "5%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 17, bold: true });

    slide.addText(x, { x: "97%", y: "96%", w: "3%", h: "3%", fontFace: 'Muli', align: "right",  color: '666666', fontSize: 15, bold: false });
    slide.background = { color: "#F8F8F3" }

    return pres;
}
