export function setAllDatesPictures(dates) {
    return {
        type: 'SET_ALL_DATES_PICTURES',
        dates: dates
    }
}

export function setPictures(pictures) {
    return {
        type: 'SET_PICTURES',
        pictures: pictures
    }
}

export function setSelectedAnalysisPictures(selectedAnalysis) {
    return {
        type: 'SET_SELECTED_ANALYSIS_PICTURES',
        selectedAnalysis: selectedAnalysis
    }
}

export function setSelectedDatePictures(selectedDate) {
    return {
        type: 'SET_SELECTED_DATE_PICTURES',
        selectedDate: selectedDate
    }
}

export function setSelectedPicture(selectedPicture) {
    return {
        type: 'SET_SELECTED_PICTURE',
        selectedPicture: selectedPicture
    }
}

export function setSamplesModif(samplesModif) {
    return {
        type: 'SET_SAMPLES_MODIF',
        samplesModif: samplesModif
    }
}

export function setPicturesToDelete(picturesToDelete) {
    return {
        type: 'SET_PICTURES_TO_DELETE',
        picturesToDelete: picturesToDelete
    }
}

export function setAlimsToDelete(alimsToDelete) {
    return {
        type: 'SET_ALIMS_TO_DELETE',
        alimsToDelete: alimsToDelete
    }
}
export function setLastSelectedDatePictures(lastSelectedDate) {
    return {
        type: 'SET_LAST_SELECTED_DATE_PICTURES',
        lastSelectedDate: lastSelectedDate
    }
}
export function setDisplayedPictures(displayedPictures) {
    return {
        type: 'SET_DISPLAYED_PICTURES',
        displayedPictures: displayedPictures
    }
}
export function setLoadingPictures(loading) {
    return {
        type: 'SET_LOADING_PICTURES',
        loading: loading
    }
}

export function setSelectedMonthPictures(selectedMonth) {
    return {
        type: 'SET_SELECTED_MONTH_PICTURES',
        selectedMonth: selectedMonth
    }
}
export function setNumberPagesPictures(numberPages) {
    return {
        type: 'SET_NUMBER_PAGES_PICTURES',
        numberPages: numberPages
    }
}
export function setDaysListPictures(daysList) {
    return {
        type: 'SET_DAYS_LIST_PICTURES',
        daysList: daysList
    }
}
export function setSelectedFilterPictures(selectedFilter) {
    return {
        type: "SET_SELECTED_FILTER_PICTURES",
        selectedFilter: selectedFilter
    }
}
export function resetPictures() {
    return {
        type: 'RESET_PICTURES'
    }
}