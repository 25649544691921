import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/account`;

export function updatePasswordForAccount(emailAccount, oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/password", {
                params: { emailAccount: emailAccount, oldPassword: oldPassword, newPassword: newPassword },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else {
                    window.alert(`Une erreur s'est produite : ${err}`)
                    reject('error');
                }
            })
    });
}

export function createNewPassword(emailAccount, password) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/createNewPassword", {
                params: { emailAccount: emailAccount, password: password },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else {
                    window.alert(`Une erreur s'est produite : ${err}`)
                    reject('error');
                }
            })
    });
}
export function createAccount(item) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/create", {
                item: item
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else {
                    window.alert("Une erreur s'est produite lors de la création")
                    reject('error');
                }
            })
    });
}

export function updateAccountValues(select, update, password) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/modifyAccountValues", {
                select: select, update: update, password: password
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else {
                    window.alert("Une erreur s'est produite lors de la création")
                    reject('error');
                }
            })
    });
}

export const getAllDemoAccounts = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getAllDemoAccounts")
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export const getAllAccounts = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/accounts")
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export const getUsersLoginAndIds = () => {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getUsersLoginAndIds")
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export const updateAccount = (account, newPassword, newType, associated, associated_restaurant) => {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateAccount", {
                params: { account: account, newPassword: newPassword, newType: newType, associated: associated, associated_restaurant: associated_restaurant },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else {
                    window.alert(`Une erreur s'est produite : ${err}`)
                    reject('error');
                }
            })
    });

}

export function deleteAccountFromMail(emailAccount) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/deleteAccountFromMail", {
                params: { emailAccount: emailAccount },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else {
                    window.alert(`Une erreur s'est produite : ${err}`)
                    reject('error');
                }
            })
    });

}