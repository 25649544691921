const initialState = {
    current_page: 'connexion',
    arianne: [],
    language: "fr"
}


const navigationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_PAGE':
            return {
                ...state,
                current_page: action.current_page
            }
        case 'SET_ARIANNE':
            return {
                ...state,
                arianne: action.arianne
            }
        case 'SET_LANGUAGE':
            return {
                ...state,
                language: action.language
            }
        case 'HARD_RESET_NAVIGATION':
            return initialState;
        default:
            return state;
    }
}

export default navigationReducer;