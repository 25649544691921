import React, { useEffect, useState } from "react";
import CustomButton from "../../customComponents/CustomButton";
import CustomDropdown from "../../customComponents/CustomDropdown";
import { useTranslation } from "react-i18next";
import { deleteSamplesBin, updateSamplesBin } from "../../../apiRoutes/samplesBin.route";
import { useDispatch } from "react-redux";
import { setLastSelectedDaySamplesBin, setSelectedAnalysisSamplesBin, setSelectedDaySamplesBin, setSelectedImageSamplesBin } from "../../../actions_reducers/actions/datas.action";
import 'moment/locale/fr';
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const SamplesBinSelection = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [daysList, setDaysList] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);

    const confirmModifications = async () => {
        await updateSamplesBin(props.updatedSamplesBin, props.restaurant_name);
    }
    const confirmSuppressions = async () => {
        await deleteSamplesBin(props.deletedSamplesBin, props.restaurant_name);
    }
    useEffect(() => {
        getDaysDropdown()
    }, [props.selectedAnalysis])
    const getDaysDropdown = () => {
        let result = [];
        let dates = [];
        let currentAnalyzes = JSON.parse(JSON.stringify(props.analyzes)).filter(a => a.analysisNumber === props.selectedAnalysis);
        let currentKitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenAnalyzes)).filter(a => a.analysisNumber === props.selectedAnalysis);
        for (const analysis of currentAnalyzes) {
            for (const day of analysis.days) {
                if (!dates.find(a => a.name === moment(day.date).format('DD-MM-YYYY')))
                    dates.push({ name: moment(day.date).format("DD-MM-YYYY") })
            }
        }
        for (const analysis of currentKitchenAnalyzes) {
            for (const day of analysis.days) {
                if (!dates.find(a => a.name === moment(day.date).format('DD-MM-YYYY')))
                    dates.push({ name: moment(day.date).format("DD-MM-YYYY") })
            }
        }
        for (const sample of props.samplesBin) {
            let date = moment(sample.created_at).format('DD-MM-YYYY');
            if (!result.find(a => a.name === date) && getDateMajFormat(moment(sample.created_at).format("MMMM YYYY")) === props.selectedAnalysis) {
                result.push({ name: date, warning: false });
            }
        }
        for (let tmp of result) {
            let samples = JSON.parse(JSON.stringify(props.samplesBin));
            let unchecked = samples.filter(a => moment(a.created_at).format('DD-MM-YYYY') === tmp.name);
            if (unchecked.find(a => a.nameFood == null))
                tmp.warning = true
            else tmp.warning = false

            if (props.login !== "admin") {
                result = result.filter(tmp => !tmp.warning);
            }

        }
        result.sort((a, b) => moment(a.name).isBefore(moment(b.name)))
        setDaysList(result);

    }
    const getDisplayedDropdownValue = () => {
        return t(`date`, { "value": { "date": moment(props.selectedDay).format("YYYY-MM-DD"), "format": "dddd DD MMMM YYYY" } });
    }
    const changeSelectedDay = (e) => {
        dispatch(setSelectedDaySamplesBin(moment(e.target.id, "DD-MM-YYYY").format("YYYY-MM-DD")))
    }
    const backToCalendar = () => {
        dispatch(setSelectedImageSamplesBin(null));
        dispatch(setSelectedDaySamplesBin(null));
    }
    const changeSelectedMonth = (e) => {
        let value = e.target.id;
        dispatch(setSelectedAnalysisSamplesBin(value))
        let result = [];
        let dates = JSON.parse(JSON.stringify(props.dates));
        result = dates.filter(a => value.date === getDateMajFormat(moment(a).format("MMMM YYYY")));
        result = result.sort((a, b) => moment(new Date(a)).isSameOrAfter(moment(new Date(b))) ? 0 : -1);
        result = result.map(item => moment(item).format('DD-MM-YYYY'))
        dispatch(setSelectedDaySamplesBin(moment(result[0], "DD-MM-YYYY").format("YYYY-MM-DD")))
        dispatch(setLastSelectedDaySamplesBin(moment(result[0], "DD-MM-YYYY").format("YYYY-MM-DD")))
    }
    const getDateMajFormat = (date) => {
        return date.charAt(0).toUpperCase() + date.slice(1)
    }
    const getMonths = () => {
        let result = [];
        for (const date of props.dates) {
            if (!result.find(a => a === getDateMajFormat(t('date', { value: { date: date.date, format: "MMMM YYYY" } }))))
                result.push(getDateMajFormat(t('date', { value: { date: date.date, format: "MMMM YYYY" } })))
        }
        result.sort((a, b) => moment(new Date(b)).isSameOrAfter(moment(new Date(a))) ? 0 : -1)
        return result
    }
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
            <div style={{ width: "15%", height: "100%" }}>
                <CustomButton width={"100%"} height={"100%"} backgroundColor={"#666666"} value={t('buttons.back')} color={"#fff"}
                    onClick={() => backToCalendar()} />
            </div>
            <div style={{ width: "2.5%" }} />
            <div style={{ width: "20%", height: "100%" }}>

                <CustomDropdown width={"90%"} height={"100%"}
                    backgroundColor={'#68B4E3'}
                    displayedValue={t('buttons.selectMonth')}
                    values={getMonths()}
                    onChange={changeSelectedMonth}
                    maxHeightDropdown={getMonths().length > 8 && "50vh"}
                    arrowColor={"#fff"}
                    color={"#fff"}
                    border={"1px solid #68B4E3"} />
            </div>
            <div style={{ width: "20%", height: "100%" }}>

                <CustomDropdown width={"90%"} height={"100%"}
                    backgroundColor={'#68B4E3'}
                    displayedValue={getDisplayedDropdownValue()}
                    selectedValue={t(`date`, { "value": { "date": props.selectedDay, "format": "DD-MM-YYYY" } })}
                    values={daysList}
                    onChange={changeSelectedDay}
                    maxHeightDropdown={"50vh"}
                    arrowColor={"#fff"}
                    color={"#fff"}
                    border={"1px solid #68B4E3"}
                    alert={true} />
            </div>
            <div style={{ width: "5%" }} />
            <div style={{ width: "20%", height: "100%" }}>
                {props.login === "admin" &&
                    <CustomButton width={"100%"} height={"100%"} backgroundColor={"#ECAC68"} value={t('buttons.deleteSelection')} color={"#fff"}
                        onClick={() => confirmSuppressions()} />}
            </div>
            <div style={{ width: "5%" }} />
            <div style={{ width: "15%", height: "100%" }}>
                {props.login === "admin" &&
                    <CustomButton width={"100%"} height={"100%"} backgroundColor={"#89CD85"} value={t('buttons.confirm')} color={"#fff"}
                        onClick={() => confirmModifications()} />
                }
            </div>
        </div>
    )
}

export default SamplesBinSelection;