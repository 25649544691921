import React from "react";
import CustomButton from "../../../customComponents/CustomButton";
import { useTranslation } from "react-i18next";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { useDispatch } from "react-redux";

const ClientEvolution = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigateTo = (value) => {
        updateArianneThread(value, props.arianne, dispatch, t())
    }
    
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
            <CustomButton width={"30%"} height={"100%"} backgroundColor={"#68B4E3"} value={t('buttons.compareRestaurant')} color={"#fff"}
                onClick={() => navigateTo('clientCompare')} />
            <div style={{ width: "5%" }} />
            <CustomButton width={"30%"} height={"100%"} backgroundColor={"#68B4E3"} value={t('buttons.accessPictures')} color={"#fff"}
                onClick={() => navigateTo('clientPictures')}/>
            <div style={{ width: "5%" }} />
            <CustomButton width={"30%"} height={"100%"} backgroundColor={"#68B4E3"} value={t('buttons.detailedAnalysis')} color={"#fff"}
                onClick={() => navigateTo('clientDetailed')} />
        </div>
    )
}
export default ClientEvolution;