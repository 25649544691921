import moment from 'moment';
import { getNumberDaysExtrap } from '../../../customFunctions/extrapolation.function';

const getSvgsRect = (value) => {
    switch (value) {
        case 'topLine':
            return `
            <svg width="587" height="296" viewBox="0 0 587 296" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.5519 294.707C-27.6465 298.557 12.7748 204.24 35.5519 191.729C58.3291 179.218 69.878 129.814 73.086 104.791C76.2941 79.7682 108.695 52.1791 127.944 44.4797C147.192 36.7804 189.711 42.5459 213.772 37.7339C233.02 33.8843 260.694 6.69007 270.425 -3.14798L517.184 -18.3257C497.815 -9.67178 473.655 9.70722 538.93 27.5095C620.523 49.7623 558.05 14.1157 534.561 16.5882C511.072 19.0608 502.348 22.0438 498.64 29.8888C494.931 37.7339 471.442 66.9769 603.722 79.3395" stroke="#B3D693" stroke-width="2" stroke-dasharray="4 4"/>
</svg>
            `
        default:
            return `
            <svg width="587" height="296" viewBox="0 0 587 296" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.5519 294.707C-27.6465 298.557 12.7748 204.24 35.5519 191.729C58.3291 179.218 69.878 129.814 73.086 104.791C76.2941 79.7682 108.695 52.1791 127.944 44.4797C147.192 36.7804 189.711 42.5459 213.772 37.7339C233.02 33.8843 260.694 6.69007 270.425 -3.14798L517.184 -18.3257C497.815 -9.67178 473.655 9.70722 538.93 27.5095C620.523 49.7623 558.05 14.1157 534.561 16.5882C511.072 19.0608 502.348 22.0438 498.64 29.8888C494.931 37.7339 471.442 66.9769 603.722 79.3395" stroke="#B3D693" stroke-width="2" stroke-dasharray="4 4"/>
</svg>
            `
    }
}

const formatNumber = (num) => {
    if (num)
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    else return num
};

const getExtrapValue = (value, kitchenValue, trays, traysKitchen, averageTrays, type) => {
    let valueTrays = trays > 0 ? value / trays * averageTrays * getNumberDaysExtrap(type) : 0;
    let valueKitchen = traysKitchen > 0 ? kitchenValue / traysKitchen * averageTrays * getNumberDaysExtrap(type) : 0;
    return valueTrays + valueKitchen;
}
const getCatValues = (analysis, category) => {
    let result = 0;
    for (const day of analysis.days) {
        for (const cat of day.categories) {
            if (cat.category === category)
                result = parseFloat(result) + parseFloat(cat.mass);
        }
    }
    return result;
}

const getValues = (analysis, kitchenAnalysis, type, averageTrays) => {
    let mass = 0;
    let trays = 0;
    let massKitchen = 0;
    let traysKitchen = 0;
    let starchies = 0;
    let meat = 0;
    let vegetables = 0;
    let bread = 0;
    let starchiesKitchen = 0;
    let meatKitchen = 0;
    let vegetablesKitchen = 0;
    let breadKitchen = 0;

    if (analysis) {
        mass = analysis.mass - analysis.inevitableMass;
        trays = analysis.trays;
        starchies = parseFloat(starchies) + parseFloat(getCatValues(analysis, "Féculents"));
        meat = parseFloat(meat) + parseFloat(getCatValues(analysis, "Viandes"));
        vegetables = parseFloat(vegetables) + parseFloat(getCatValues(analysis, "Légumes"));
        bread = parseFloat(bread) + parseFloat(getCatValues(analysis, "Pain"));
    }
    if (kitchenAnalysis) {
        massKitchen = kitchenAnalysis.mass - kitchenAnalysis.inevitableMass;
        traysKitchen = kitchenAnalysis.trays;
        starchiesKitchen = parseFloat(starchiesKitchen) + parseFloat(getCatValues(kitchenAnalysis, "Féculents"));
        meatKitchen = parseFloat(meatKitchen) + parseFloat(getCatValues(kitchenAnalysis, "Viandes"));
        vegetablesKitchen = parseFloat(vegetablesKitchen) + parseFloat(getCatValues(kitchenAnalysis, "Légumes"));
        breadKitchen = parseFloat(breadKitchen) + parseFloat(getCatValues(kitchenAnalysis, "Pain"));
    }

    let massTot = getExtrapValue(mass, massKitchen, trays, traysKitchen, averageTrays, type);
    let starchiesTot = getExtrapValue(starchies, starchiesKitchen, trays, traysKitchen, averageTrays, type);
    let meatTot = getExtrapValue(meat, meatKitchen, trays, traysKitchen, averageTrays, type);
    let vegetablesTot = getExtrapValue(vegetables, vegetablesKitchen, trays, traysKitchen, averageTrays, type);
    let breadTot = getExtrapValue(bread, breadKitchen, trays, traysKitchen, averageTrays, type);

    let hamburgers = parseInt(massTot / 120);
    let water = parseInt(massTot / 600 * 2000);
    let pastas = parseInt(starchiesTot / 200);
    let bathtub = parseInt(water / 200);
    let nuggets = parseInt(meatTot / 20);
    let carrots = parseInt(vegetablesTot / 100);
    let baguettes = parseInt(breadTot / 250);


    let massConv = trays > 0 ? mass / trays : 0;
    let massConvKitchen = traysKitchen > 0 ? massKitchen / traysKitchen : 0;
    let massConvFinal = massConv + massConvKitchen;

    return {
        massConv: massConvFinal,
        mass: massTot,
        hamburgers: formatNumber(hamburgers),
        starchies: starchiesTot,
        pastas: formatNumber(pastas),
        water: formatNumber(water),
        bathtub: formatNumber(bathtub),
        meat: meatTot,
        nuggets: formatNumber(nuggets),
        vegetables: vegetablesTot,
        carrots: formatNumber(carrots),
        bread: breadTot,
        baguettes: formatNumber(baguettes)

    }
}

export const getChildSlide = (pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, type, x1, x2, restaurant_name, averageTrays, displayName) => {
    let slide = pres.addSlide();
    let values = getValues(analysis, kitchenAnalysis, type, averageTrays);
    let dateStart;
    let dateEnd;
    if (analysis) {
        dateStart = moment(analysis.dateStart).format("DD/MM/YYYY");
        dateEnd = moment(analysis.dateEnd).format("DD/MM/YYYY");
    }
    else if (kitchenAnalysis) {
        dateStart = moment(kitchenAnalysis.dateStart).format("DD/MM/YYYY");
        dateEnd = moment(kitchenAnalysis.dateEnd).format("DD/MM/YYYY");
    }

    const topLine = getSvgsRect('topLine');

    slide.addImage({ path: require('../sensi_assets/enfants/elipse hg.png'), x: "0%", y: "0%", w: "50%", h: "22%", align: "center" });
    slide.addImage({ path: require('../sensi_assets/enfants/elipse bd.png'), x: "50%", y: "78%", w: "50%", h: "22%", align: "center" });
    slide.addImage({ path: require('../sensi_assets/enfants/logo.png'), x: "4%", y: "89%", w: "16%", h: "9%", align: "center" });

    slide.addText(t('sensibilisation2.warning'), { x: "11.5%", y: "4.3%", w: "80%", h: "10%", align: "center", fontFace: 'Baguet Script', color: '#54b0e3', fontSize: 64, bold: false });
    slide.addText(t('sensibilisation2.wastefood'), { x: "12.5%", y: "12%", w: "80%", h: "5%", align: "center", fontFace: 'Mulish Extrabold', color: '#90c261', fontSize: 34, bold: true });
    slide.addText(t('sensibilisation2.wastefood2'), { x: "12.5%", y: "16%", w: "80%", h: "5%", align: "center", fontFace: 'Mulish Extrabold', color: '#90c261', fontSize: 34, bold: true });

    slide.addImage({
        data: `data:image/svg+xml;base64,${btoa(topLine)}`,
        x: "0%",
        y: "0%",
        w: "100%",
        h: "30%",
    });

    /// Part mass by guest
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
        x: "7.5%",
        y: "22%",
        w: "85%",
        h: "13%",
        fill: "FFFFFF",
        shadow: { type: "outer", color: "000000", opacity: 0.25, blur: 4, offset: 4, angle: 90 },
        rectRadius: 0.5
    });
    slide.addText(t('sensibilisation2.me+friends', { value: t(`sensi_adult_day.one${type}`) }), { x: "14.5%", y: "22%", w: "80%", h: "5%", align: "center", fontFace: 'Mulish Extrabold', color: '#7ABCE2', fontSize: 16, bold: true });
    slide.addText(t('mass', { value: { "mass": values.massConv, "unit": "g", fixed: 0 } }), { x: "25.5%", y: "26.5%", w: "20%", h: "5%", align: "right", fontFace: 'Mulish Extrabold', color: '#9EC97F', fontSize: 40, bold: true });
    slide.addText(t('sensibilisation2.foodByGuest'), { x: "45.5%", y: "26.5%", w: "20%", h: "5%", align: "left", fontFace: 'Mulish Extrabold', color: '#9EC97F', fontSize: 14, bold: true });
    slide.addText(t('sensibilisation2.improvement'), { x: "12.5%", y: "30.5%", w: "80%", h: "5%", align: "center", fontFace: 'Baguet Script', color: '#7ABCE2', fontSize: 14, bold: false });
    slide.addImage({ path: require('../sensi_assets/enfants/enfants.png'), x: "7%", y: "22.5%", w: "14%", h: "11%", align: "center" });

    /// Part cheeseburgers equiv
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
        x: "9%",
        y: "37%",
        w: "40%",
        h: "16.5%",
        fill: "FFFFFF",
        shadow: { type: "outer", color: "000000", opacity: 0.25, blur: 4, offset: 4, angle: 90 },
        rectRadius: 0.5
    });
    slide.addText(t('mass', { value: { "mass": values.mass, "unit": "kg", fixed: parseInt(values.mass) < 10000 ? 1 : 0 } }), { x: "18%", y: "38%", w: "25%", h: "5%", align: "right", fontFace: 'Baloo 2 Extrabold', color: '#824C21', fontSize: 28, bold: true });
    slide.addText(t('sensibilisation2.foodwaste'), { x: "24%", y: "41%", w: "19%", h: "5%", align: "right", fontFace: 'Baloo 2 Extrabold', color: '#824C21', fontSize: 14, bold: true });
    slide.addText(t('sensibilisation2.equivalentto'), { x: "26%", y: "44%", w: "22%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#BA8C52', fontSize: 14, bold: false });
    slide.addText(parseInt(values.hamburgers) > 1 ? t('sensibilisation2.burgers') : t('sensibilisation2.burger'), { x: "26%", y: "48.8%", w: "22%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#BA8C52', fontSize: 14, bold: false });
    slide.addText(t('sensibilisation2.value', { value: values.hamburgers }), { x: "26%", y: "46.5%", w: "30%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#BA8C52', fontSize: 28, bold: true });
    slide.addImage({ path: require('../sensi_assets/enfants/burger.png'), x: "13.5%", y: "44%", w: "11%", h: "6%", align: "center" });

    /// Part pasta equiv
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
        x: "51%",
        y: "37%",
        w: "40%",
        h: "16.5%",
        fill: "95CFE8",
        shadow: { type: "outer", color: "000000", opacity: 0.25, blur: 4, offset: 4, angle: 90 },
        rectRadius: 0.5
    })
    slide.addText(t('mass', { value: { "mass": values.starchies, "unit": "kg", fixed: parseInt(values.starchies) < 10000 ? 1 : 0 } }), { x: "56%", y: "38%", w: "19%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#FFFFFF', fontSize: 28, bold: true });
    slide.addText(t('sensibilisation2.value', { value: values.pastas }), { x: "67.5%", y: "45.75%", w: "15%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#FFFFFF', fontSize: 28, bold: true });
    slide.addText(t('sensibilisation2.pastawaste'), { x: "67.5%", y: "41%", w: "20%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#FFFFFF', fontSize: 14, bold: false });
    slide.addText(t('sensibilisation2.thatrepresent'), { x: "67.5%", y: "43%", w: "25%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#FFFFFF', fontSize: 14, bold: false });
    slide.addText(parseInt(values.pastas) > 1 ? t('sensibilisation2.pastafoods') : t('sensibilisation2.pastafood'), { x: "67.5%", y: "48%", w: "20%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#FFFFFF', fontSize: 14, bold: false });
    slide.addImage({ path: require('../sensi_assets/enfants/pates.png'), x: "53%", y: "44%", w: "13%", h: "6.5%", align: "center" });

    /// Part water equiv
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
        x: "9%",
        y: "55.5%",
        w: "40%",
        h: "16.5%",
        fill: "B5E7D7",
        shadow: { type: "outer", color: "000000", opacity: 0.25, blur: 4, offset: 4, angle: 90 },
        rectRadius: 0.5
    });
    slide.addText(t('sensibilisation.litervalue', { value: values.water }), { x: "11.5%", y: "56.5%", w: "40%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#54B0E3', fontSize: x2, bold: true });
    slide.addText(t('sensibilisation2.value', { value: values.bathtub }), { x: "29%", y: "64%", w: "15%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#FFFFFF', fontSize: x2, bold: true });
    slide.addText(t('sensibilisation2.waterwaste'), { x: "11.5%", y: "57%", w: "22%", h: "10%", fontFace: 'Baloo 2 Extrabold', align: 'left', color: '#54B0E3', fontSize: 14, bold: false });
    slide.addText(t('sensibilisation2.equivalentto2'), { x: "29%", y: "61%", w: "25%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#FFFFFF', fontSize: 14, bold: false });
    slide.addText(parseInt(values.bathtub) > 1 ? t('sensibilisation2.waterbaths') : t('sensibilisation2.waterbath'), { x: "28.5%", y: "67%", w: "25%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#FFFFFF', fontSize: 14, bold: false });
    slide.addImage({ path: require('../sensi_assets/enfants/bain.png'), x: "11.5%", y: "62%", w: "17%", h: "9%", align: "center" });

    /// Part nuggets equiv
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
        x: "51%",
        y: "55.5%",
        w: "40%",
        h: "16.5%",
        fill: "FFFFFF",
        shadow: { type: "outer", color: "000000", opacity: 0.25, blur: 4, offset: 4, angle: 90 },
        rectRadius: 0.5
    });
    slide.addText(t('mass', { value: { "mass": values.meat, "unit": "kg", fixed: parseInt(values.meat) < 10000 ? 1 : 0 } }), { x: "53%", y: "56%", w: "20%", h: "5%", align: "center", fontFace: 'Baloo 2 Extrabold', color: '#348680', fontSize: x2, bold: true });
    slide.addText(t('sensibilisation2.value', { value: values.nuggets }), { x: "59.5%", y: "63.5%", w: "25%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#296966', fontSize: x2, bold: true });
    slide.addText(t('sensibilisation2.meatwaste'), { x: "50%", y: "59%", w: "25%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'center', color: '#348680', fontSize: 14, bold: false });
    slide.addText(t('sensibilisation2.thiscorrespond'), { x: "54%", y: "63.5%", w: "25%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'left', color: '#296966', fontSize: 14, bold: false });
    slide.addText(parseInt(values.nuggets) > 1 ? t('sensibilisation2.nuggetswaste') : t('sensibilisation2.nuggetwaste'), { x: "56%", y: "65.5%", w: "15%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'center', color: '#296966', fontSize: 14, bold: false });
    slide.addImage({ path: require('../sensi_assets/enfants/nuggets.png'), x: "72.75%", y: "58%", w: "16%", h: "7%", align: "center" });

    /// Part carots equiv
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
        x: "9%",
        y: "74%",
        w: "40%",
        h: "16.5%",
        fill: "FFFFFF",
        shadow: { type: "outer", color: "000000", opacity: 0.25, blur: 4, offset: 4, angle: 90 },
        rectRadius: 0.5
    });
    slide.addText(t('mass', { value: { "mass": values.vegetables, "unit": "kg", fixed: parseInt(values.vegetables) < 10000 ? 1 : 0 } }), { x: "13%", y: "75.5%", w: "25%", h: "5%", align: "left", fontFace: 'Baloo 2 Extrabold', color: '#749C3B', fontSize: 28, bold: true });
    slide.addText(t('sensibilisation2.value', { value: values.carrots }), { x: "22%", y: "83%", w: "15%", h: "5%", align: "center", fontFace: 'Baloo 2 Extrabold', color: '#F8A95C', fontSize: x2, bold: true });
    slide.addText(t('sensibilisation2.vegetableswaste'), { x: "16%", y: "78%", w: "21%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'left', color: '#749C3B', fontSize: 14, bold: true });
    slide.addText(t('sensibilisation2.that'), { x: "20%", y: "80.5%", w: "10%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'left', color: '#F8A95C', fontSize: 14, bold: false });
    slide.addText(parseInt(values.carrots) > 1 ? t('sensibilisation2.carrots') : t('sensibilisation2.carrot'), { x: "32%", y: "85%", w: "12%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'left', color: '#F8A95C', fontSize: 14, bold: false });
    slide.addImage({ path: require('../sensi_assets/enfants/carottes.png'), x: "9%", y: "81%", w: "14%", h: "10%", align: "center" });

    /// Part bread equiv
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
        x: "51%",
        y: "74%",
        w: "40%",
        h: "16.5%",
        fill: "B3D693",
        shadow: { type: "outer", color: "000000", opacity: 0.25, blur: 4, offset: 4, angle: 90 },
        rectRadius: 0.5
    });
    slide.addText(t('mass', { value: { "mass": values.bread, "unit": "kg", fixed: parseInt(values.bread) < 10000 ? 1 : 0 } }), { x: "48.5%", y: "75.5%", w: "20%", h: "5%", align: "right", fontFace: 'Baloo 2 Extrabold', color: '#FFFFFF', fontSize: x2, bold: true });
    slide.addText(t('sensibilisation2.value', { value: values.baguettes }), { x: "43.5%", y: "85.25%", w: "20%", h: "5%", align: "right", fontFace: 'Baloo 2 Extrabold', color: '#FFFFFF', fontSize: x2, bold: true });
    slide.addText(t('sensibilisation2.breadparttotrash'), { x: "66.5%", y: "74.7%", w: "20%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'left', color: '#FFFFFF', fontSize: 14, bold: false });
    slide.addText(t('sensibilisation2.breadpart'), { x: "66.5%", y: "76.4%", w: "20%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'left', color: '#FFFFFF', fontSize: 14, bold: false });
    slide.addText(t('sensibilisation2.trashbin'), { x: "53.5%", y: "78.1%", w: "40%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'left', color: '#FFFFFF', fontSize: 14, bold: false });
    slide.addText(t('sensibilisation2.equivalentto3'), { x: "53.5%", y: "82%", w: "25%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'left', color: '#FFFFFF', fontSize: 14, bold: false });
    slide.addText(parseInt(values.baguettes) > 1 ? t('sensibilisation2.breadstickstrash') : t('sensibilisation2.breadsticktrash'), { x: "64.5%", y: "85%", w: "20%", h: "5%", fontFace: 'Baloo 2 Extrabold', align: 'left', color: '#FFFFFF', fontSize: 14, bold: false });
    slide.addImage({ path: require('../sensi_assets/enfants/baguettes.png'), x: "80%", y: "75%", w: "8%", h: "11%", align: "center", rotate: 25, flipH: true });

    slide.addText(t('sensi_adult_day.analysisdate', { value1: dateStart, value2: dateEnd }), { x: "20%", y: "91.5%", w: "60%", h: "5%", align: "center", fontFace: 'Baloo 2 Extrabold', color: '#666666', fontSize: 12, bold: true });
    slide.addText(t('sensibilisation2.value', { value: displayName }), { x: "20%", y: "93.5%", w: "60%", h: "5%", align: "center", fontFace: 'Baloo 2 Extrabold', color: '#666666', fontSize: 12, bold: true });


    slide.background = { color: "#F8F8F3" }

    return pres;
}
