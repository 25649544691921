import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/clientAnalyzes`;

export function getClientAnalyzes(clientName) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getClientAnalyzes", {
                params: { clientName: clientName },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function getAllClientAnalyzes() {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getAllClientAnalyzes")
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function createClientAnalysis(clientName, analysisNumber, dateStart, dateEnd) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/createClientAnalysis", {
                params: { clientName: clientName, analysisNumber: analysisNumber, dateStart: dateStart, dateEnd: dateEnd }
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}
export function updateClientAnalysis(clientName, analysisNumber, dateStart, dateEnd) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateClientAnalysis",
                { clientName: clientName, analysisNumber: analysisNumber, dateStart: dateStart, dateEnd: dateEnd }
            )
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}
export function deleteClientAnalysis(clientName, analysisNumber) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/deleteClientAnalysis", {
                clientName: clientName, analysisNumber: analysisNumber
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}