
const getValues = (analysis, kitchenAnalysis) => {
    let mass = 0;
    let trays = 0;
    let massKitchen = 0;
    let traysKitchen = 0;

    if (analysis) {
        mass = parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
        trays = parseInt(analysis.trays);
    }
    if (kitchenAnalysis) {
        massKitchen = parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
        traysKitchen = parseInt(kitchenAnalysis.trays)
    }

    let massConv = trays > 0 ? mass / trays : 0;
    let massConvKitchen = traysKitchen > 0 ? massKitchen / traysKitchen : 0;

    let massConvTotal = massConv + massConvKitchen;
    return {
        massConvTotal: massConvTotal
    }
}
export const getTenthSlide = (pres, t, analysis, kitchenAnalysis, x, objectives) => {
    let slide = pres.addSlide();
    let objectif = objectives.find(a => a.title === "Mon objectif n°2");
    let values = getValues(analysis, kitchenAnalysis)
    let reduc = "...";
    let oldMassConv = "...";
    let newMassConv = "...";
    if (objectif && objectif.reduction) {
        reduc = objectif.reduction;
        newMassConv = t('mass', { value: { "mass": values.massConvTotal - values.massConvTotal * reduc / 100, "unit": "g", fixed: 0 } });
    }
    oldMassConv = t('mass', { value: { "mass": values.massConvTotal, "unit": "g", fixed: 0 } });


    slide.addImage({ path: require('../assets/10/title_bg.png'), x: "0%", y: "2%", w: "50%", h: "20%" })

    slide.addImage({ path: require('../assets/10/up.png'), x: "18%", y: "43%", w: "20%", h: "9%" })
    slide.addImage({ path: require('../assets/10/down.png'), x: "18%", y: "59%", w: "20%", h: "9%" })

    slide.addImage({ path: require('../assets/3/logo_bg.png'), x: "0%", y: "84%", w: "17.5%", h: "16%" })
    slide.addImage({ path: require('../assets/1/logoNoText.png'), x: "1%", y: "89%", w: "11%", h: "9%" })

    slide.addText(t("synthesis.global.stepgoal"), { x: "2.5%", y: "6%", w: "32%", h: "11%", fontFace: 'Muli', align: "left", color: 'FFFFFF', fontSize: 25, bold: true });


    slide.addText(t("synthesis.page_ten.shortterm"), { x: "18%", y: "44.5%", w: "20%", h: "5%", align: "center", fontFace: 'Muli', color: 'F8F8F3', fontSize: 25, bold: true });
    slide.addText(t("synthesis.page_ten.percentage_mass", { value1: reduc, value2: oldMassConv, value3: newMassConv }), { x: "40%", y: "43.5%", w: "40%", h: "9%", fontFace: 'Muli', color: '666666', fontSize: 18, bold: true });

    slide.addText(t("synthesis.page_ten.longterm"), { x: "18%", y: "60.75%", w: "20%", h: "5%", align: "center", fontFace: 'Muli', color: 'F8F8F3', fontSize: 25, bold: true });
    slide.addText(t("synthesis.page_ten.nationalpact"), { x: "40%", y: "59.75%", w: "28%", h: "9%", fontFace: 'Muli', color: '666666', fontSize: 18, bold: true });

    slide.addText(x, { x: "96%", y: "96%", w: "4%", h: "3%", fontFace: 'Muli', align: "right", color: '666666', fontSize: 15, bold: false });
    slide.background = { color: "#F8F8F3" }

    return pres;
}
