import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import { ReactComponent as Question } from "../../../assets/question.svg";
import { setAllAnalyzes, setMenusList, setSelectedTypeDatasRestaurant } from "../../../actions_reducers/actions/datas.action";
import Menus from "./Menus/Menus";
import WasteManagement from "./WasteManagement";
import Foods from "./Foods";
import { getMenusRestaurant } from "../../../apiRoutes/menus.routes";
import { getAnalyzes } from "../../../apiRoutes/analyzes.routes";

const DatasRestaurant = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();

    useEffect(() => {
        getMenusAndAnalyzes()
    }, []);

    const getMenusAndAnalyzes = async () => {
        let allMenus = await getMenusRestaurant(props.restaurant_name);
        let analyzes = await getAnalyzes(props.restaurant_name);
        dispatch(setAllAnalyzes(analyzes));
        dispatch(setMenusList(allMenus));
    }

    const getBackgroundColor = (type) => {
        if (type === props.selected_type)
            return "#fff";
        else if (type === "foods")
            return "#90c261";
        else if (type === "menus" && props.selected_type === "foods")
            return "#90c261";
        else if (type === "menus" && props.selected_type === "wasteManagement")
            return "#68b4e3";
        else if (type === "wasteManagement")
            return "#68b4e3";
    }
    const getColor = (type) => {
        if (type !== props.selected_type)
            return "#fff"
    }

    const getBoxShadow = () => {
        return '0px 0px 3px 0px #00000040';
    }
    const getDisplayedElem = () => {
        if (props.selected_type === "menus")
            return <Menus
                selectedAnalysis={props.selectedAnalysis}
                selectedDate={props.selectedDate}
                selectedService={props.selectedService}
                all_analyzes={props.all_analyzes}
                menus_list={props.menus_list}
                has_noon={props.has_noon}
                has_evening={props.has_evening}
                food_list={props.food_list}
                restaurant_name={props.restaurant_name} />
        else if (props.selected_type === "wasteManagement") return <WasteManagement restaurant_name={props.restaurant_name} wasteManagementMethod={props.wasteManagementMethod} />
        else return <Foods
            food_list={props.food_list} />
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <div style={{ width: "100%", height: "8%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {props.selected_type === "foods" &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "7%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Question />
                        </div>
                        <div style={{ width: "93%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", }}>
                            {t('longTexts.datasRestaurantDisclamer')}
                        </div>
                    </div>}
            </div>
            <div style={{ height: "2%" }} />
            <div style={{ height: "90%", width: "100%", position: "relative" }}>
                <div style={{ height: "12%", width: "100%", display: "flex", flexDirection: "row", zIndex: 0 }}>
                    <div style={{
                        fontSize: "1.3vw", color: getColor('foods'), width: "34.6%", height: "100%", backgroundColor: getBackgroundColor('foods'), cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center",
                        borderTopLeftRadius: 15, boxShadow: getBoxShadow('foods'),
                        paddingBottom: ".75%"
                    }}
                        onClick={() => dispatch(setSelectedTypeDatasRestaurant('foods'))}>
                        {t('table.foodCost')}
                    </div>
                    <div style={{
                        fontSize: "1.3vw", color: getColor('menus'), marginLeft: "-2%", width: "34.6%", height: "100%", backgroundColor: getBackgroundColor('menus'), cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center",
                        borderTopLeftRadius: 15, boxShadow: getBoxShadow('menus'),
                        paddingBottom: ".75%"
                    }}
                        onClick={() => dispatch(setSelectedTypeDatasRestaurant('menus'))}>
                        {t('table.customMenus')}

                    </div>
                    <div style={{
                        fontSize: "1.3vw", color: getColor('wasteManagement'), marginLeft: "-2%", width: "34.8%", height: "100%", backgroundColor: getBackgroundColor('wasteManagement'), cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center",
                        borderTopRightRadius: 15, borderTopLeftRadius: 15, boxShadow: getBoxShadow('wasteManagement'),
                        paddingBottom: ".75%"
                    }}
                        onClick={() => dispatch(setSelectedTypeDatasRestaurant('wasteManagement'))}>
                        {t('table.wasteManagement')}

                    </div>
                </div>
                <div style={{ height: props.selected_type === "wasteManagement" ? "40%" : "91%", width: "100%", backgroundColor: "#fff", marginTop: "-1%", position: "absolute", boxShadow: "0px 4px 4px 0px #00000040", borderRadius: 15 }}>
                    {getDisplayedElem()}
                </div>
            </div>

        </div >
    )
}
function mapStateToProps(state) {
    return {
        restaurant_name: state.userReducer.selected_restaurant,
        food_list: state.datasReducer.food_list,
        selected_type: state.datasReducer.selected_type_datas_restaurant,
        selectedAnalysis: state.datasReducer.selected_analysis_menus,
        selectedDate: state.datasReducer.selected_date_menus,
        selectedService: state.datasReducer.selected_service_menus,
        all_analyzes: state.datasReducer.all_analyzes,
        menus_list: state.datasReducer.menus_list,
        has_noon: state.userReducer.has_noon,
        has_evening: state.userReducer.has_evening,
        wasteManagementMethod: state.datasReducer.wasteManagementMethod
    };
}

export default connect(mapStateToProps)(DatasRestaurant);