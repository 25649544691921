import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/tray`;

export function getEmptyTraysByAnalysis(restaurant_name, dateStart, dateEnd) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getEmptyTraysByAnalysis", {
                params: { restaurant_name: restaurant_name, dateStart: dateStart, dateEnd: dateEnd },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getNumberNonOrgaForAnalysis(restaurant_name, dateStart, dateEnd) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getNumberNonOrgaForAnalysis", {
                params: { restaurant_name: restaurant_name, dateStart: dateStart, dateEnd: dateEnd },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function getTraysValues(restaurant_name, dateStart, dateEnd) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getAllTraysDetailedForAnalysis", {
                params: { restaurant_name: restaurant_name, dateStart: dateStart, dateEnd: dateEnd },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getTraysWithSamplesForDate(restaurant_name, date) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getTraysWithSamplesForDate", {
                params: { restaurant_name: restaurant_name, date: date },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getAllDatesTrays(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getAllDatesTrays", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getCaptedTraysByAnalysis(restaurant_name){
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getCaptedTraysByAnalysis", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}

export function updateAlimsForTray(restaurant_name, date, updatedSamples) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateAlimsForTray", {
                params: { restaurant_name: restaurant_name, date: date, updatedSamples: updatedSamples },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function deleteAlimForTray(restaurant_name, date, image_ref, ids) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/deleteAlimForTray", {
                params: { restaurant_name: restaurant_name, date: date, image_ref: image_ref, ids: ids },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function updateAlimForDay(date, restaurant_name, old_id, newId) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateAlimForDay", {
                params: { date: date, restaurant_name: restaurant_name, old_id: old_id, newId: newId },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function deleteTraysWithSamples(restaurant_name, date, picturesList) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/deleteTraysWithSamples", {
                params: { restaurant_name: restaurant_name, date: date, picturesList: picturesList },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}