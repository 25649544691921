import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import TopGaspi from "../../commonElements/TopGaspi";
import 'moment/locale/fr';
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const ClientLosses = (props) => {

    const getMaxValue = () => {
        let max = 1;
        for (const date of props.clientAnalyzesDates) {
            if (date.analysisNumber > max) max = date.analysisNumber
        }
        let hasMax = false;
        if (props.clientAnalyzesDates.length > 0 && props.clientAnalyzes.length > 0)
            while (hasMax === false) {
                let lastDate = props.clientAnalyzesDates.find(a => a.analysisNumber === max);
                if (lastDate) {
                    let testAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                        moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(lastDate.dateStart)))
                        &&
                        moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(lastDate.dateEnd)))
                    )
                    let testAnalyzesKitchen = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                        moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(lastDate.dateStart)))
                        &&
                        moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(lastDate.dateEnd)))
                    )
                    if ((!testAnalyzes || testAnalyzes.length === 0) && (!testAnalyzesKitchen || testAnalyzesKitchen.length === 0)) {
                        max -= 1;
                    }
                    else hasMax = true;
                }
                else hasMax = true
            }
        return max;
    }

    const getValue = () => {
        let max = getMaxValue();
        let dates = props.clientAnalyzesDates.find(a => a.analysisNumber === max);
        let selectedAnalyzes = [];
        let selectedAnalyzesKitchen = [];
        if (dates) {
            selectedAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
            );
            selectedAnalyzesKitchen = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
            );
        }
        let categories = [];
        let mass = 0;
        for (const analysis of selectedAnalyzes) {
            mass = parseFloat(mass) + parseFloat(analysis.mass);
            for (const category of analysis.categories) {
                let index = categories.findIndex(a => a.id === category.category)
                if (index === -1) {
                    let newCat = {
                        id: category.category,
                        mass: category.mass
                    }
                    categories.push(newCat);
                }
                else {
                    categories[index].mass = parseFloat(categories[index].mass) + parseFloat(category.mass);
                }

            }
        }
        for (const analysis of selectedAnalyzesKitchen) {
            mass = parseFloat(mass) + parseFloat(analysis.mass);
            for (const category of analysis.categories) {
                let index = categories.findIndex(a => a.id === category.category)
                if (index === -1) {
                    let newCat = {
                        id: category.category,
                        mass: category.mass
                    }
                    categories.push(newCat);
                }
                else {
                    categories[index].mass = parseFloat(categories[index].mass) + parseFloat(category.mass);
                }

            }
        }
        return categories;
    }
    return (
        <CustomBlock width={'100%'} height={'100%'}>
            <TopGaspi analysis={getValue()} type={"client"} state={{ selectedPieCategory: null }} />
        </CustomBlock>
    )
}
export default ClientLosses;