import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../actions_reducers/reducers/reducers";
import axios from "axios";
import { disconnectUser } from "../apiRoutes/disconnectError";

const saveToLocalStorage = (state) => {
  try {
    localStorage.setItem('userState', JSON.stringify(state.userReducer));
    localStorage.setItem('navigationState', JSON.stringify(state.navigationReducer));
    localStorage.setItem('adminState', JSON.stringify(state.adminReducer));
    localStorage.setItem('clientState', JSON.stringify(state.clientReducer));
  } catch (e) {
    console.error(e);
  }
};
const getLandingPage = (type) => {
  if (type === 'admin') {
    return 'clientList';
  }
  else if (type === 'super-client') {
    return 'clientDashboard';
  }
  else if (type === 'client') {
    return 'clientDashboard';
  }
  else if (type === 'chef') {
    return 'dashboard';
  }

}
/*
const resetClient = (state) => {
  state.clientAnalyzesDates = [];
  state.clientAnalyzes = [];
  state.clientKitchenAnalyzes = [];
  state.clientExtrapolatedAnalyzes = [];
  state.clientKitchenExtrapolatedAnalyzes = [];
  state.selectedAnalysisNumber = 3;
  state.dataTypeClient = "mass";
  state.has_loaded_client = false;
  state.selectedRestaurants = [];
  state.selectedRestaurantClient = null;
  state.selectedAnalysisNumberClientRestaurant = null;
  state.analysisNumbersList = [];
  state.extrapolationValue = "analysis";
  state.selectedRestaurantPictures = null;
  state.selectedRestaurantAnalysisPictures = null;
  state.selectedPicturesDate = null;
  state.picturesListClient = [];
  state.restaurantListDataType = "total";
  return state;
}
const resetDashboard = (state) => {
  state.selectedAnalysisType = 'massByConv';
  state.selectedAnalysisNumber = 1;
  state.selectedPieCategory = null;
  state.extrapolationValue = "analysis";
  state.showedAnalysis = null;
  state.selectedDay = null;
  state.selectedCategory = null;
  state.selectedDataType = 'kitchen';
  return state;
}
const resetDatas = (state) => {
  state.analyzesBaseDatas = [];
  state.analyzesDatas = [];
  state.extrapolatedAnalyzes = [];
  state.kitchenDatas = [];
  state.kitchenAnalyzesDatas = [];
  state.kitchenExtrapolatedAnalyzes = [];
  state.analyzesTrays = [];
  state.clientAnalyzes = [];
  state.categories = [
    "Viandes",
    "Légumes",
    "Fruit",
    "Produits de la mer",
    "Féculents",
    "Crudités",
    "Fromage",
    "Dessert",
    "Plats composés",
    "Pain",
    "Oeufs",
    "Déchets inévitables",
  ];
  state.pictures_list = [];
  state.food_list = [];
  state.food_list_kitchen = [];
  state.trays_by_day = [];
  state.weighIns = [];
  state.samplesBin = [];
  state.deletedSamplesBin = [];
  state.updatedSamplesBin = [];
  state.selected_day_samples_bin = null;
  state.selected_image_samples_bin = null;
  return state;
}
const resetFiles = (state) => {
  state.selected_analysis = null;
  state.selected_cam = null;
  state.files_list = [];
  return state;
}*/
const resetNavigation = (state, type) => {
  state.current_page = getLandingPage(type);
  return state;
}/*
const resetObjectives = (state) => {
  state.objectives = [];
  state.selectedObjectif = "Objectif global";
  return state;
}*/
const resetUser = (state) => {
  if (state.hasChangedPassword == null || state.hasChangedPassword === false) {
    state.userId= null;
    state.massUnit= 'g';
    state.costUnit= '€';
    state.token= null;
    state.user_type= null;
    state.is_logged_in= false;
    state.has_loaded= false;
    state.login= null;
    state.restaurant_list= [];
    state.selected_restaurant= null;
    state.multiple_cams= false;
    state.cams= [{ camName: 'total', displayName: "Total" }];
    state.selected_cams= [{ camName: 'total', displayName: "Total" }];
    state.analysisDataType= ["total", "restaurant", "kitchen"];
    state.selectedAnalysisDataType= ["total", "restaurant", "kitchen"];
    state.hours= ["total", "noon", "evening"];
    state.selected_hours= ["total", "noon", "evening"];
    state.has_kitchen= false;
    state.has_restaurant= true;
    state.has_synthesis= true;
    state.has_noon= false;
    state.has_evening= false;
    state.has_survey= false;
    state.has_saturday= false;
    state.has_sunday= false;
    state.averageGuests= 1;
    state.clientAverageGuests= [];
    state.numberWeeks= 0;
    state.displayName= null;
    state.linkToSurvey= null;
    state.synthesisName= null;
    state.restaurant_type= null;
    state.isChatOpen= false;
    state.hasChangedPassword= true
  }
  else {
    state.has_loaded = false;
    state.cams = [{ camName: 'total', displayName: "Total" }];
    state.selected_cams = [{ camName: 'total', displayName: "Total" }];
    state.analysisDataType = ["total", "restaurant", "kitchen"];
    state.selectedAnalysisDataType = ["total", "restaurant", "kitchen"];
    state.hours = ["total", "noon", "evening"];
    state.selected_hours = ["total", "noon", "evening"];
    state.has_kitchen = false;
    state.has_noon = false;
    state.has_evening = false;
    state.has_survey = false;
    if (state.user_type === "admin")
      state.selected_restaurant = null
  }
  return state;
}

const resetClient = (state) => {
  state.loadingClient = false
  state.selectedAnalyzesExcel = []
  state.clientAnalyzesDates = []
  state.clientAnalyzes = []
  state.clientKitchenAnalyzes = []
  state.clientExtrapolatedAnalyzes = []
  state.clientKitchenExtrapolatedAnalyzes = []
  state.selectedAnalysisNumber = 1
  state.dataTypeClient = "mass"
  state.has_loaded_client = false
  state.selectedRestaurants = []
  state.selectedRestaurantClient = null
  state.selectedAnalysisNumberClientRestaurant = null
  state.analysisNumbersList = []
  state.extrapolationValue = "analysis"
  state.selectedRestaurantPictures = null
  state.selectedRestaurantAnalysisPictures = null
  state.selectedPicturesDate = null
  state.picturesListClient = []
  state.restaurantListDataType = "total"
  state.displayNames = []
  state.hasAnalyzes = false

  return state;
}
const resetAdmin = (state) => {
  state.selectedRestaurantModifs = null;
  state.newClientName = null;
  state.newClientMail = null;
  state.newClientPassword = null;
  state.newRestaurantName = null;
  state.newRestaurantDisplayName = null;
  state.newRestaurantSynthesisName = null;
  state.newRestaurantMail = null;
  state.newRestaurantPassword = null;
  state.newRestaurantMorning = false;
  state.newRestaurantNoon = true;
  state.newRestaurantEvening = false;
  state.newRestaurantSaturday = false;
  state.newRestaurantSunday = false;
  state.newRestaurantMultipleCams = false;
  state.newRestaurantCamsList = [];
  state.newRestaurantHasTheoricNumber = false;
  state.newRestaurantUnit = "g";
  state.newRestaurantCostUnit = "€";
  state.newRestaurantAverageTrays = null;
  state.newRestaurantHasRestaurant = true;
  state.newRestaurantHasKitchen = false;
  state.newRestaurantNumberWeeksYear = null;
  state.selectedChatId = null;
  state.selectedAdminChatType = "open";
  state.loggedInChat = false;
  state.userIdsEmails = [];
  return state;
}
/*
const resetStates = (state) => {
  state.clientReducer = resetClient(state.clientReducer);
  state.dashboardReducer = resetDashboard(state.dashboardReducer);
  state.datasReducer = resetDatas(state.datasReducer);
  state.filesReducer = resetFiles(state.filesReducer);
  state.navigationReducer = resetNavigation(state.navigationReducer, state.userReducer.user_type);
  state.objectivesReducer = resetObjectives(state.objectivesReducer);
  state.userReducer = resetUser(state.userReducer);
  return state;
}*/

const loadFromLocalStorage = () => {
  try {
    let userState = localStorage.getItem('userState');
    let clientState = localStorage.getItem('clientState');
    let adminState = localStorage.getItem('adminState')
    let userStateJson = null;
    let navigationStateJson = null;
    if (userState) {
      userStateJson = resetUser(JSON.parse(userState));
      axios.defaults.headers.common['token'] = userStateJson.token;
      let navigationState = localStorage.getItem('navigationState');

      if (navigationState)
        navigationStateJson = resetNavigation(JSON.parse(navigationState), userStateJson.user_type);
    }
    let state = {};
    if (clientState != null) {
      state.clientReducer = resetClient(JSON.parse(clientState));
    }
    if (adminState != null) {
      state.adminReducer = resetAdmin(JSON.parse(adminState));
    }
    if (adminState == null) disconnectUser();
    if (userState != null) {
      state.userReducer = userStateJson;
      state.navigationReducer = navigationStateJson;
    }
    return userState ? state : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const persistedStore = loadFromLocalStorage();


const applicationStore = configureStore({
  reducer: rootReducer, preloadedState: persistedStore,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // Disable immutable state check
      serializableCheck: false, // Disable serializable state check if it's also slow
    }),
})

applicationStore.subscribe(() => {
  saveToLocalStorage(applicationStore.getState());
});
export default applicationStore;