export function setSelectedAnalysisFiles(selected_analysis) {
    return {
        type: "SET_SELECTED_ANALYSIS_FILES",
        selected_analysis: selected_analysis
    }
}
export function setFilesList(files_list) {
    return {
        type: "SET_FILES_LIST",
        files_list: files_list
    }
}
export function setSelectedCamFiles(selected_cam) {
    return {
        type: "SET_SELECTED_CAM_FILES",
        selected_cam: selected_cam
    }
}
export function setShowOnBoarding(show_on_boarding) {
    return {
        type: "SET_SHOW_ON_BOARDING",
        show_on_boarding: show_on_boarding
    }
}
export function setCurrentPageOnBoarding(current_page_on_boarding) {
    return {
        type: 'SET_CURRENT_PAGE_ON_BOARDING',
        current_page_on_boarding: current_page_on_boarding
    }
}

export function setOnBoardingDone(on_boarding_done) {
    return {
        type: 'SET_ON_BOARDING_DONE',
        on_boarding_done: on_boarding_done
    }
}
export function setAnalyzesSynthesis(analyzesSynthesis) {
    return {
        type: 'SET_ANALYZES_SYNTHESIS',
        analyzesSynthesis: analyzesSynthesis
    }
}

export function setSelectedDocuments(selectedDocuments) {
    return {
        type: 'SET_SELECTED_DOCUMENTS',
        selectedDocuments: selectedDocuments
    }
}

export function setDownloadModalOpen(downloadModalOpen) {
    return {
        type: 'SET_DOWNLOAD_MODAL_OPEN',
        downloadModalOpen: downloadModalOpen
    }
}
export function setHasKitchenValues(hasKitchenValues) {
    return {
        type: 'SET_HAS_KITCHEN_VALUES',
        hasKitchenValues: hasKitchenValues
    }
}
export function setHasRestaurantValues(hasRestaurantValues) {
    return {
        type: 'SET_HAS_RESTAURANT_VALUES',
        hasRestaurantValues: hasRestaurantValues
    }
}
export function setHasAssessment(hasAssessment) {
    return {
        type: 'SET_HAS_ASSESSMENT',
        hasAssessment: hasAssessment
    }
}
export function setSelectedAnalyzesExcelFiles(selected_analyzes) {
    return {
        type: 'SET_SELECTED_ANALYZES_EXCEL_FILES',
        selected_analyzes: selected_analyzes
    }
}
export function setLoadingFiles(loadingFiles) {
    return {
        type: "SET_LOADING_FILES",
        loadingFiles: loadingFiles
    }
}
export function setRealTrays(realTrays) {
    return {
        type: "SET_REAL_TRAYS",
        realTrays: realTrays
    }
}
export function hardResetFiles() {
    return {
        type: 'HARD_RESET_FILES'
    }
}