import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import 'moment/locale/fr';
import CustomBlock from "../../../customComponents/CustomBlock";
import MenusSelection from "./MenusSelection";
import MenusList from "./MenusList";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);
const separationHeight = "6vh";
const separationWidth = "6%";

const Menus = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <div style={{ height: "15%", width: "100%" }}>
                <MenusSelection
                    selectedAnalysis={props.selectedAnalysis}
                    selectedDate={props.selectedDate}
                    selectedService={props.selectedService}
                    all_analyzes={props.all_analyzes}
                    has_noon={props.has_noon}
                    has_evening={props.has_evening} />
            </div>
            <div style={{ height: "85%", width: "100%" }}>
                <MenusList
                    menus_list={props.menus_list}
                    selectedDate={props.selectedDate}
                    selectedService={props.selectedService}
                    food_list={props.food_list}
                    restaurant_name={props.restaurant_name} />
            </div>

        </div>
    )
}

export default Menus;