import adminReducer from './admin.reducer';
import userReducer from "./user.reducer";
import navigationReducer from "./navigation.reducer";
import clientReducer from "./client.reducer";
import dashboardReducer from "./dashboard.reducer";
import datasReducer from "./datas.reducer";
import filesReducer from "./files.reducer";
import objectivesReducer from "./objectives.reducer";
import planningReducer from './planning.reducer';
import { combineReducers } from "redux";
import onBoardingReducer from './onBoarding.reducer';
import operationsReducer from './operations.reducer';
import alertsReducer from './alerts.reducer';
import picturesReducer from './pictures.reducer'
import searchReducer from './search.reducer';
import ressourcesReducer from './ressources.reducer';
import mailsReducer from './mails.reducer';

const allReducers = combineReducers({
    userReducer: userReducer,
    navigationReducer: navigationReducer,
    clientReducer: clientReducer,
    dashboardReducer: dashboardReducer,
    datasReducer: datasReducer,
    filesReducer: filesReducer,
    objectivesReducer: objectivesReducer,
    adminReducer: adminReducer,
    onBoardingReducer: onBoardingReducer,
    planningReducer: planningReducer,
    operationsReducer: operationsReducer,
    alertsReducer: alertsReducer,
    picturesReducer: picturesReducer,
    searchReducer: searchReducer,
    ressourcesReducer: ressourcesReducer,
    mailsReducer: mailsReducer
});

export default allReducers;
