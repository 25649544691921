import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import CustomButton from "../../../customComponents/CustomButton";
import CustomDropdownCheckboxes from "../../../customComponents/CustomDropdownCheckBoxes";
import CustomTextField from "../../../customComponents/CustomTextField";
import { useTranslation } from "react-i18next";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { setCurrentPage } from "../../../../actions_reducers/actions/navigation.action";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import CustomCheckbox from "../../../customComponents/CustomCheckbox";
import { ReactComponent as ArrowDown } from "../../../../assets/arrowdown2.svg";
import { createAccount, updateAccountValues, deleteAccountFromMail } from "../../../../apiRoutes/account.route";
import { setAllAccounts } from "../../../../actions_reducers/actions/admin.action";
const ClientListAccounts = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const modalRef = useRef(null);
    const modalDeleteRef = useRef(null);

    const [dropdownRestaurantOpen, setDropdownRestaurantOpen] = useState(false)
    const [emailAccount, setEmailAccount] = useState(null);
    const [passwordAccount, setPasswordAccount] = useState(null);
    const [accountType, setAccountType] = useState(t("table.chief"));
    const [associatedRestaurants, setAssociatedRestaurants] = useState([]);
    const [restaurant_list, setRestaurantList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isModif, setIsModif] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
    const changeDropdownRestaurantOpen = () => setDropdownRestaurantOpen(!dropdownRestaurantOpen)
    const resetModal = () => {
        setEmailAccount(null);
        setPasswordAccount(null);
        setAccountType(t("table.chief"));
        setAssociatedRestaurants([]);
        setModalOpen(false);
        setIsModif(false);
    }
    const backToClientList = () => {
        updateArianneThread("clientList", props.arianne, dispatch, "");
        dispatch(setCurrentPage("clientList"));
    }
    useEffect(() => {

        let restaurant_list = [];
        for (const information of props.informations) {
            if (information.clientName === props.selectedClient)
                restaurant_list.push(information.restaurantName)
        }
        setRestaurantList(restaurant_list);
    }, [])
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && event.target.id === "") {
                setDropdownRestaurantOpen(false)
            }
            else if (modalRef.current && !modalRef.current.contains(event.target) && !modalDeleteOpen)
                setModalOpen(false)
            else if (modalDeleteRef.current && !modalDeleteRef.current.contains(event.target))
                setModalDeleteOpen(false)
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef, modalDeleteRef, modalRef]);
    const getType = (type) => {
        if (type === t("table.super-client"))
            return 'super-client';
        else if (type === t("table.client"))
            return 'client';
        else return 'chef';
    }
    const confirmAccount = async () => {
        let clientName = props.selectedClient;
        let restaurantName = props.selectedRestaurant;
        let associated_restaurants = associatedRestaurants;
        let type = accountType;
        let isGood = true;
        if (!emailAccount || emailAccount == null || !passwordAccount || passwordAccount == null)
            isGood = false;
        let newAccount = {
            emailAccount: emailAccount,
            passwordAccount: passwordAccount,
            type: getType(type)
        };
        if (type === t("table.super-client")) {
            newAccount.clientName = clientName;
            newAccount.associated_restaurants = restaurant_list;
        }
        else if (type === t("table.client")) {
            if (associated_restaurants.length === 0) isGood = false;
            newAccount.clientName = clientName;
            newAccount.associated_restaurants = associated_restaurants;
        }
        else {
            newAccount.restaurantName = restaurantName;
            newAccount.associated_restaurants = [restaurantName];
        }
        if (isGood) {
            let accounts = JSON.parse(JSON.stringify(props.accounts));
            if (!isModif) {
                accounts.push(newAccount);
                dispatch(setAllAccounts(accounts));
                await createAccount(newAccount);
                resetModal()
                window.alert("Le compte a bien été créé");
            }
            else {
                let index = accounts.findIndex(a => a.emailAccount === newAccount.emailAccount);
                let selectedAccount = { emailAccount: emailAccount };
                let newPassword = '';
                let updatedAccount = {
                    type: getType(type)
                }
                if (type === t("table.super-client")) {
                    updatedAccount.clientName = clientName;
                    updatedAccount.associated_restaurants = restaurant_list;
                }
                else if (type === t("table.client")) {
                    if (associated_restaurants.length === 0) isGood = false;
                    updatedAccount.clientName = clientName;
                    updatedAccount.associated_restaurants = associated_restaurants;
                }
                else {
                    updatedAccount.restaurantName = restaurantName;
                    updatedAccount.associated_restaurants = [restaurantName];
                }
                if (passwordAccount !== "******") newPassword = passwordAccount;
                await updateAccountValues(selectedAccount, updatedAccount, newPassword);
                let newAccountForList = JSON.parse(JSON.stringify(updatedAccount));
                newAccountForList.emailAccount = emailAccount;
                newAccountForList.passwordAccount = accounts[index].passwordAccount;
                accounts[index] = newAccountForList;
                dispatch(setAllAccounts(accounts));
                resetModal();

            }
        }
        else
            window.alert("Veuillez remplir tous les champs")
    }

    const getAccounts = (type) => {
        let results = [];
        let name = props.selectedClient;
        if (type === "chef")
            name = props.selectedRestaurant
        let i = 0;
        for (const account of props.accounts) {
            let nameToCompare = account.clientName;
            if (type === "chef")
                nameToCompare = account.restaurantName
            if (type === "client" && account.associated_restaurants.find(a => a === props.selectedRestaurant) && account.type === type) {
                results.push(
                    <div onClick={() => openModif(account)} style={{ cursor: "pointer", width: "100%", height: "15%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: i % 2 === 0 && "#fff" }}>
                        {account.emailAccount}
                    </div>
                )
                i++;
            }
            else if (nameToCompare === name && account.type === type && type !== "client") {
                results.push(
                    <div onClick={() => openModif(account)} style={{ cursor: "pointer", width: "100%", height: "15%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: i % 2 === 0 && "#fff" }}>
                        {account.emailAccount}
                    </div>
                )
                i++;
            }
        }
        return results;
    }
    const openModif = (account) => {
        setEmailAccount(account.emailAccount);
        setPasswordAccount("******");
        setAccountType(t(`table.${account.type}`));
        setAssociatedRestaurants(account.associated_restaurants || []);
        setIsModif(true)
        setModalOpen(true);
    }
    const updateRestaurants = (e) => {
        let index = associatedRestaurants.findIndex(a => a === e.target.id);
        if (index === -1)
            setAssociatedRestaurants(old => [...old, e.target.id])
        else
            setAssociatedRestaurants(old => [...old.slice(0, index), ...old.slice(index + 1)])
    }
    const checkedRestaurant = (restaurant) => {
        return associatedRestaurants.findIndex(a => a === restaurant) > -1
    }
    const getRestaurantsDropdown = (values) => {
        return (<div
            ref={dropdownRef}
            className='custom-dropdown'
            id={'general-dropdown-food'}
            style={{
                height: "80%",
                width: "80%",
                color: "#666666",
                backgroundColor: "#f0f0f0",
                zIndex: dropdownRestaurantOpen ? 100000 : 1
            }}
            onClick={(e) => { if (e.target.id === "general-dropdown-food") { setDropdownRestaurantOpen(false); changeDropdownRestaurantOpen() } }}>
            {t('buttons.select')}
            <ArrowDown
                id={'general-dropdown-food'} className='arrow-down-img' fill={"#666666"} />

            < div className="custom-dropdown-choices"
                style={{
                    display: dropdownRestaurantOpen ? 'block' : 'none',
                    backgroundColor: '#f0f0f0',
                    height: values.length > 4 ? "40vh" : "20vh"
                }}>
                <CustomScrollDiv update={0}>
                    {values.map((restaurant) => {
                        return (
                            <div key={'dropdown-food-' + restaurant} id={restaurant} className="custom-dropdown-choice-div"
                                style={{ display: "flex", flexDirection: "row", height: "5vh" }}>
                                <div style={{ width: "10%", height: "100%" }} onClick={(e) => updateRestaurants(e)}>
                                    <CustomCheckbox width={"2vh"} height={"2vh"} checked={checkedRestaurant(restaurant)} id={restaurant} />
                                </div>
                                <div style={{ width: "90%", height: "100%" }}>
                                    {restaurant}
                                </div>
                            </div>)
                    })}
                </CustomScrollDiv>
            </div>

        </div >)
    }

    const deleteAccount = async () => {
        let accounts = JSON.parse(JSON.stringify(props.accounts));
        let index = accounts.findIndex(a => a.emailAccount === emailAccount);
        accounts.splice(index, 1);
        dispatch(setAllAccounts(accounts))
        await deleteAccountFromMail(emailAccount);
        resetModal()
        setModalDeleteOpen(false);
    }

    const deleteAccountModal = () => {
        if (modalDeleteOpen) {
            return (
                <CustomBlock modalDeleteRef={modalDeleteRef} width={"40%"} height={"40%"} position={"absolute"} zIndex={100000} top={"22%"}  >
                    <div ref={modalDeleteRef} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <div style={{ height: "55%", width: "70%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            {t('components.deleteAccount', { value: emailAccount })}
                        </div>
                        <div style={{ height: "35%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton value={t('buttons.cancel')} backgroundColor={"#666666"} color={"#fff"} width={"80%"} onClick={() => setModalDeleteOpen(false)} />
                            </div>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton value={t('buttons.confirmButton')} backgroundColor={"#90C261"} color={"#fff"} width={"80%"} onClick={() => deleteAccount()} />
                            </div>
                        </div>
                    </div>
                </CustomBlock>
            )
        }
    }
    const addAccountModal = () => {
        let accountTypes = [t("table.super-client"), t("table.client"), t("table.chief")];
        if (modalOpen)
            return (
                <CustomBlock width={"60%"} height={"60%"} position={"absolute"} zIndex={100000} top={"18%"}>
                    <div ref={modalRef} style={{ width: "100%", height: "100%" }}>
                        <div style={{ height: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {isModif ? t('components.modifAccount') : t('components.addAccount')}

                            {
                                isModif &&
                                <div style={{ position: "absolute", right: "2%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CustomButton color={"#fff"} backgroundColor={"#ECAC68"} value={t('buttons.delete')} width={"10vw"} onClick={() => setModalDeleteOpen(true)} />
                                </div>
                            }
                        </div>
                        <div style={{ height: "25%", display: "flex" }}>
                            <div style={{ width: "50%" }}>
                                <div style={{ width: "100%", height: "60%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                    {t('table.typeAccount')}
                                </div>
                                <div style={{ height: "2%" }} />
                                <div style={{ width: "100%", height: "38%", display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                                    <CustomDropdown width={"80%"} height={"80%"} color={"#666666"}
                                        backgroundColor={"#f0f0f0"}
                                        values={accountTypes}
                                        selectedValue={accountType}
                                        displayedValue={accountType}
                                        onChange={(e) => setAccountType(e.target.id)} />
                                </div>
                            </div>
                            {accountType === t('table.client') &&
                                <div style={{ width: "50%" }}>
                                    <div style={{ width: "100%", height: "60%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                                        {t('table.associatedRestaurants')}
                                    </div>
                                    <div style={{ height: "2%" }} />
                                    <div style={{ width: "100%", height: "38%", display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                                        {getRestaurantsDropdown(restaurant_list)}
                                    </div>
                                </div>}

                        </div>
                        <div style={{ height: "40%", display: "flex" }}>
                            <div style={{ width: "50%", display: "flex" }}>
                                <div style={{ width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    {t('buttons.email')}
                                </div>
                                <div style={{ width: "2%" }} />
                                <div style={{ width: "68%", display: "flex", alignItems: "center" }}>
                                    <CustomTextField width={"80%"} height={"20%"}
                                        filter={"none"}
                                        backgroundColor={"#D9D9D9"}
                                        borderRadius={"10px"}
                                        type={"text"}
                                        value={emailAccount}
                                        disabled={isModif}
                                        onChange={(e) => setEmailAccount(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ width: "50%", display: "flex" }}>
                                <div style={{ width: "30%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                    {t('buttons.password')}
                                </div>
                                <div style={{ width: "2%" }} />
                                <div style={{ width: "68%", display: "flex", alignItems: "center" }}>
                                    <CustomTextField width={"80%"} height={"20%"}
                                        filter={"none"}
                                        backgroundColor={"#D9D9D9"}
                                        borderRadius={"10px"}
                                        type={"text"}
                                        value={passwordAccount}
                                        onChange={(e) => setPasswordAccount(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "20%", display: "flex" }}>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton width={"35%"} height={"40%"} color={"#fff"} backgroundColor={"#666666"} value={t('buttons.cancel')} onClick={() => resetModal()} />
                            </div>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton width={"35%"} height={"40%"} color={"#fff"} backgroundColor={"#90c261"} value={t('buttons.confirm')} onClick={() => confirmAccount()} />
                            </div>
                        </div>

                    </div>
                </CustomBlock>
            )
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh", display: "flex", justifyContent: "center", color: "#666666" }}>
            {addAccountModal()}
            {deleteAccountModal()}
            <CustomBlock
                width={"100%"}
                height={"100%"}>
                <div style={{ height: "15%", fontSize: "1.2vw", width: "100%", display: "flex" }}>
                    <div style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
                        <CustomButton backgroundColor={"#666666"} color={"#fff"} width={"70%"} height={"40%"} value={t('buttons.back')} onClick={() => backToClientList()} />
                    </div>
                    <div style={{ width: "70%" }}>
                        <div style={{ height: "48%", display: "flex", justifyContent: "center", alignItems: "flex-end", fontWeight: "bold" }}>
                            {props.selectedRestaurant}
                        </div>
                        <div style={{ height: "4%" }} />
                        <div style={{ height: "48%", display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                            {t('components.clientAndName', { value: props.selectedClient })}
                        </div>
                    </div>
                    <div style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
                        <CustomButton backgroundColor={"#89CD85B3"} color={"#fff"} width={"70%"} height={"40%"} value={t('buttons.plusAccount')} onClick={() => setModalOpen(true)} />
                    </div>
                </div>
                <div style={{ height: "85%", width: "100%", display: "flex", flexDirection: "row", backgroundColor: "#F0F0F0" }}>
                    <div style={{ width: "33%" }}>
                        <div style={{ height: "15%", display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #666666", borderRight: "1px solid #666666" }}>
                            {t('components.accountList', { value: t("table.super-client") })}
                        </div>
                        <div style={{ height: "85%", borderRight: "1px solid #666666" }}>
                            <CustomScrollDiv update={Math.random()}>
                                {getAccounts("super-client")}
                            </CustomScrollDiv>
                        </div>
                    </div>
                    <div style={{ width: "34%" }}>
                        <div style={{ height: "15%", display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #666666", borderRight: "1px solid #666666" }}>
                            {t('components.accountList', { value: t("table.client") })}
                        </div>
                        <div style={{ height: "85%", borderRight: "1px solid #666666" }}>
                            <CustomScrollDiv update={Math.random()}>
                                {getAccounts("client")}
                            </CustomScrollDiv>
                        </div>
                    </div>
                    <div style={{ width: "33%" }}>
                        <div style={{ height: "15%", display: "flex", justifyContent: "center", alignItems: "center", borderBottom: "1px solid #666666" }}>
                            {t('components.accountList', { value: t("table.restaurant") })}
                        </div>
                        <div style={{ height: "85%" }}>
                            <CustomScrollDiv update={Math.random()}>
                                {getAccounts("chef")}
                            </CustomScrollDiv>
                        </div>
                    </div>
                </div>
            </CustomBlock>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        selectedRestaurant: state.adminReducer.selectedRestaurantModifs,
        accounts: state.adminReducer.allAccounts,
        selectedClient: state.adminReducer.selectedClient,
        arianne: state.navigationReducer.arianne,
        informations: state.adminReducer.allInformations
    };
}

export default connect(mapStateToProps)(ClientListAccounts);
