export function setSelectedTaskType(taskType) {
    return {
        type: 'SET_SELECTED_TASK_TYPE',
        taskType: taskType
    }
}
export function setTasks(tasks) {
    return {
        type: "SET_TASKS",
        tasks: tasks
    }
}

export function setTasksClient(tasksClient) {
    return {
        type: 'SET_TASKS_CLIENT',
        tasksClient: tasksClient
    }
}

export function setAddTaskOpen(addTaskOpen) {
    return {
        type: "SET_ADD_TASK_OPEN",
        addTaskOpen: addTaskOpen
    }
}
export function setIsTaskSelected(isTaskSelected) {
    return {
        type: 'SET_IS_TASK_SELECTED',
        isTaskSelected: isTaskSelected
    }
}
export function setSelectedClientDropdownPlanning(selectedClientDropdown) {
    return {
        type: 'SET_SELECTED_CLIENT_DROPDOWN_PLANNING',
        selectedClientDropdown: selectedClientDropdown
    }
}
export function setSelectedRestaurantDropdownPlanning(selectedRestaurantDropdown) {
    return {
        type: 'SET_SELECTED_RESTAURANT_DROPDOWN_PLANNING',
        selectedRestaurantDropdown: selectedRestaurantDropdown
    }
}
export function setSelectedTaskTypeDropdownPlanning(selectedTaskTypeDropdown) {
    return {
        type: 'SET_SELECTED_TASK_TYPE_DROPDOWN_PLANNING',
        selectedTaskTypeDropdown: selectedTaskTypeDropdown
    }
}

export function setSelectedRestaurantPlanning(selectedRestaurant) {
    return {
        type: "SET_SELECTED_RESTAURANT_PLANNING",
        selectedRestaurant: selectedRestaurant
    }
}
export function setAnalysisTypePlanning(analysisType) {
    return {
        type: "SET_ANALYSIS_TYPE_PLANNING",
        analysisType: analysisType
    }
}
export function setTypePlanning(type) {
    return {
        type: "SET_TYPE_PLANNING",
        typePlanning: type
    }
}
export function setAnalysisNumberPlanning(analysisNumber) {
    return {
        type: "SET_ANALYSIS_NUMBER_PLANNING",
        analysisNumber: analysisNumber
    }
}
export function setAssessmentNumber(assessmentNumber) {
    return {
        type: "SET_ASSESSMENT_NUMBER",
        assessmentNumber: assessmentNumber
    }
}
export function setHasSaturdayPlanning(hasSaturday) {
    return {
        type: "SET_HAS_SATURDAY_PLANNING",
        hasSaturday: hasSaturday
    }
}
export function setHasSundayPlanning(hasSunday) {
    return {
        type: "SET_HAS_SUNDAY_PLANNING",
        hasSunday: hasSunday
    }
}
export function setDateStartPlanning(dateStart) {
    return {
        type: "SET_DATE_START_PLANNING",
        dateStart: dateStart
    }
}
export function setDateEndPlanning(dateEnd) {
    return {
        type: "SET_DATE_END_PLANNING",
        dateEnd: dateEnd
    }
}
export function setHourStartPlanning(hourStart) {
    return {
        type: "SET_HOUR_START_PLANNING",
        hourStart: hourStart
    }
}
export function setHourEndPlanning(hourEnd) {
    return {
        type: "SET_HOUR_END_PLANNING",
        hourEnd: hourEnd
    }
}

export function setOldDateStartPlanning(oldDateStart) {
    return {
        type: "SET_OLD_DATE_START",
        oldDateStart: oldDateStart
    }
}
export function setOldDateEndPlanning(oldDateEnd) {
    return {
        type: "SET_OLD_DATE_END",
        oldDateEnd: oldDateEnd
    }
}
export function setOldHourStartPlanning(oldHourStart) {
    return {
        type: "SET_OLD_HOUR_START",
        oldHourStart: oldHourStart
    }
}
export function setOldHourEndPlanning(oldHourEnd) {
    return {
        type: "SET_OLD_HOUR_END",
        oldHourEnd: oldHourEnd
    }
}