import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/slackAlerts`;


export function sendAlertMessageChat(msg) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/sendAlertMessageChat", {
                params: { msg: msg },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}