export function setAllDatesAlerts(dates) {
    return {
        type: 'SET_ALL_DATES_ALERTS',
        dates: dates
    }
}

export function setAlerts(alerts) {
    return {
        type: 'SET_ALERTS',
        alerts: alerts
    }
}

export function setSelectedAnalysisAlerts(selectedAnalysis) {
    return {
        type: 'SET_SELECTED_ANALYSIS_ALERTS',
        selectedAnalysis: selectedAnalysis
    }
}

export function setSelectedDateAlerts(selectedDate) {
    return {
        type: 'SET_SELECTED_DATE_ALERTS',
        selectedDate: selectedDate
    }
}

export function setSelectedAlert(selectedAlert) {
    return {
        type: 'SET_SELECTED_ALERT',
        selectedAlert: selectedAlert
    }
}

export function setModifiedAlerts(modifiedAlerts) {
    return {
        type: 'SET_MODIFIED_ALERTS',
        modifiedAlerts: modifiedAlerts
    }
}

export function setAlertDone(alertDone) {
    return {
        type: 'SET_ALERT_DONE',
        alertDone: alertDone
    }
}

export function setUncheckedDates(uncheckedDates) {
    return {
        type: 'SET_UNCHECKED_DATES',
        uncheckedDates: uncheckedDates
    }
}

export function resetAlerts() {
    return {
        type: 'RESET_ALERTS'
    }
}