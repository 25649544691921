import React from "react"
import CustomButton from "../../customComponents/CustomButton"
import { ReactComponent as Menu } from "../../../assets/menu.svg";
import { useDispatch } from "react-redux";
import { updateArianneThread } from "../../arianneThread/ArianneThread";
import { setCurrentPage } from "../../../actions_reducers/actions/navigation.action";
import { softResetUser } from "../../../actions_reducers/actions/user.action";

export const BackToMenuButton = (props) => {
    const dispatch = useDispatch();
    const backToMenu = () => {
        updateArianneThread('files', props.arianne, dispatch, "");
        dispatch(setCurrentPage('files'));
        dispatch(softResetUser())
    }
    return (
        <CustomButton
            width={props.width || "100%"}
            height={props.height || "100%"}
            backgroundColor={"#949494"}
            color={"white"}
            fontSize={'1.1vw'}
            onClick={backToMenu}
        >
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ height: "100%", width: "15%" }}>
                    <Menu className="dashboard-menu-icon" />

                </div>
                <div className="dashboard-back-to-menu-text">
                    {props.value || ''}
                </div>
            </div>
        </CustomButton>
    )
}