import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { ReactComponent as Euro } from "../../../../assets/euro.svg";
import { ReactComponent as Kg } from "../../../../assets/kg.svg";
import { ReactComponent as Earth } from "../../../../assets/earth.svg";
import { useTranslation } from "react-i18next";
import { setDataTypeClient, setRestaurantListDataType } from "../../../../actions_reducers/actions/client.action";
import { useDispatch } from "react-redux";

const separationWidth = "4%";
const ClientDataType = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const hasMultipleDataTypes = () => {
        if (props.clientAnalyzes.length === 0 || props.clientKitchenAnalyzes.length === 0)
            return false;
        else
            return true;
    }

    const getWidth = () => {
        if (hasMultipleDataTypes()) {
            return "22%"
        }
        else return "33%";
    }
    const setDataType = (value) => {
        dispatch(setRestaurantListDataType(value))
    }
    return (
        <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: "row" }}>
            <CustomBlock width={getWidth()} height={"100%"} backgroundColor={"#7DCBB9"}
                paddingTop={"1%"} paddingBottom={"#1%"}
                hasHover={true} selected={props.selectedDataType === "mass"}>
                <div style={{ width: "100%", height: "100%", cursor: "pointer" }} onClick={() => dispatch(setDataTypeClient('mass'))}>
                    <div className="client-datatype-icon-div">
                        <Kg className="client-datatype-icon" />
                    </div>
                    <div className="client-datatype-text-div">
                        {t('components.wastedTotal')}
                    </div>
                </div>
            </CustomBlock>
            <div style={{ width: separationWidth }} />
            <CustomBlock width={getWidth()} height={"100%"} backgroundColor={"#89CD85"}
                paddingTop={"1%"} paddingBottom={"#1%"}
                hasHover={true} selected={props.selectedDataType === "cost"}>
                <div style={{ width: "100%", height: "100%", cursor: "pointer" }} onClick={() => dispatch(setDataTypeClient('cost'))}>
                    <div className="client-datatype-icon-div">
                        <Euro className="client-datatype-icon" />
                    </div>
                    <div className="client-datatype-text-div">
                        {t('components.economicLoss')}
                    </div>
                </div>
            </CustomBlock>
            <div style={{ width: separationWidth }} />
            <CustomBlock width={getWidth()} height={"100%"} backgroundColor={"#A2C37F"}
                paddingTop={"1%"} paddingBottom={"#1%"}
                hasHover={true} selected={props.selectedDataType === "co2"}>
                <div style={{ width: "100%", height: "100%", cursor: "pointer" }} onClick={() => dispatch(setDataTypeClient('co2'))}>
                    <div className="client-datatype-icon-div">
                        <Earth className="client-datatype-icon" />
                    </div>
                    <div className="client-datatype-text-div">
                        {t('components.environmentalImpact')}
                    </div>
                </div>
            </CustomBlock>
            {(hasMultipleDataTypes() || props.userState.user_type === "admin") && <>
                <div style={{ width: separationWidth }} />
                <CustomBlock width={"22%"} height={"100%"} backgroundColor={"#fff"}
                    paddingTop={"1%"} paddingBottom={"#1%"}>
                    {props.userState.user_type !== "admin" ?
                        <div style={{ width: "100%", height: "100%", color: "#666666" }} >
                            <div style={{ width: "100%", height: "20%", fontSize: "0.9vw", textAlign: "center", fontWeight: "bold" }}>Type de données</div>
                            <div className="checkbox-data-type-div">
                                <label for={"total"} style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <input style={{ marginRight: "2%" }} type="radio" id={"total"} name={"type"} value={"total"} defaultChecked={true}
                                        onClick={(e) => setDataType(e.target.value)} />
                                    {t('dataTypes.total')}
                                </label>

                            </div>
                            <div className="checkbox-data-type-div">
                                <label for={"restaurant"} style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <input style={{ marginRight: "2%" }} type="radio" id={"restaurant"} name={"type"} value={"restaurant"}
                                        onClick={(e) => setDataType(e.target.value)} />
                                    {t('dataTypes.restaurant')}
                                </label>

                            </div>
                            <div className="checkbox-data-type-div">
                                <label for={"kitchen"} style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <input style={{ marginRight: "2%" }} type="radio" id={"kitchen"} name={"type"} value={"kitchen"}
                                        onClick={(e) => setDataType(e.target.value)} />
                                    {t('dataTypes.kitchen')}
                                </label>

                            </div>
                            <div className="checkbox-data-type-div">
                                <label for={"detailed"} style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                    <input style={{ marginRight: "2%" }} type="radio" id={"detailed"} name={"type"} value={"detailed"}
                                        onClick={(e) => setDataType(e.target.value)} />
                                    {t('dataTypes.detailed')}
                                </label>

                            </div>
                        </div> :
                        <div style={{ width: "100%", height: "100%", color: "#666666" }} >
                            <div style={{ width: "100%", height: "20%", fontSize: "0.9vw", textAlign: "center", fontWeight: "bold" }}>Type de données</div>
                            <div style={{ height: "50%", width: "100%", display: "flex", flexDirection: "row" }}>
                                <div style={{ height: "100%", width: "50%", display: "flex", flexDirection: "column", borderRight: "1px solid #666666" }}>
                                    <div className="checkbox-data-type-div" style={{ height: "100%" }}>
                                        <label for={"total"} style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                            <input style={{ marginRight: "2%" }} type="radio" id={"total"} name={"type"} value={"total"} defaultChecked={true}
                                                onClick={(e) => setDataType(e.target.value)} />
                                            {t('dataTypes.total')}
                                        </label>

                                    </div>
                                    <div className="checkbox-data-type-div" style={{ height: "100%" }}>
                                        <label for={"detailed"} style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                            <input style={{ marginRight: "2%" }} type="radio" id={"detailed"} name={"type"} value={"detailed"}
                                                onClick={(e) => setDataType(e.target.value)} />
                                            {t('dataTypes.detailed')}
                                        </label>

                                    </div>
                                </div>
                                <div style={{ height: "100%", width: "50%", display: "flex", flexDirection: "column" }}>

                                    <div className="checkbox-data-type-div" style={{ paddingLeft: "15%", height: "100%" }}>
                                        <label for={"restaurant"} style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                            <input style={{ marginRight: "2%" }} type="radio" id={"restaurant"} name={"type"} value={"restaurant"}
                                                onClick={(e) => setDataType(e.target.value)} />
                                            {t('dataTypes.restaurant')}
                                        </label>

                                    </div>
                                    <div className="checkbox-data-type-div" style={{ paddingLeft: "15%", height: "100%" }}>
                                        <label for={"kitchen"} style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                            <input style={{ marginRight: "2%" }} type="radio" id={"kitchen"} name={"type"} value={"kitchen"}
                                                onClick={(e) => setDataType(e.target.value)} />
                                            {t('dataTypes.kitchen')}
                                        </label>

                                    </div>
                                </div>

                            </div>
                            <div className="checkbox-data-type-div" style={{ height: "30%", width: "100%", padding: "0%" }}>
                                <label for={"detailedDays"} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <input style={{ marginRight: "2%" }} type="radio" id={"detailedDays"} name={"type"} value={"detailedDays"}
                                        onClick={(e) => setDataType(e.target.value)} />
                                    {t('dataTypes.detailedDays')}
                                </label>

                            </div>
                        </div>}
                </CustomBlock>
            </>}
        </div>
    )
}

export default ClientDataType;
