import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { ReactComponent as Tray } from "../../../../assets/tray.svg";
import { ReactComponent as Euro } from "../../../../assets/euro.svg";
import { ReactComponent as Kg } from "../../../../assets/kg.svg";
import { ReactComponent as Earth } from "../../../../assets/earth.svg";
import { useTranslation } from 'react-i18next';
import { setSelectedAnalysisType } from "../../../../actions_reducers/actions/dashboard.action";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { ReactComponent as Information } from "../../../../assets/informationButton.svg";

const DayValues = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selected = props.state.selectedAnalysisType;
    const marginLeft = '4.7%';
    const width = '20.5%';
    const height = '100%';
    const [showInfo, setShowInfo] = useState("")

    const getInfo = (type) => {
        return (
            <Information fill={"#FFFFFF"} style={{ width: "2vw", height: "3vh", top: "4%", right: "1%", position: "absolute" }}
                onMouseLeave={() => setShowInfo("")}
                onMouseEnter={() => setShowInfo(type)} />)
    }
    const getValues = () => {
        let massByConv = 0;
        let mass = 0;
        let cost = 0;
        let co2 = 0;
        let trays = 0;
        let analysis = props.analysis;
        if (analysis) {
            massByConv = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseInt(analysis.trays)
            mass = parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)
            cost = parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)
            co2 = parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2)
            trays = analysis.trays;
        }
        if (props.has_kitchen && props.kitchenAnalysis) {
            massByConv += ((parseFloat(props.kitchenAnalysis.mass) - parseFloat(props.kitchenAnalysis.inevitableMass)) / parseInt(props.kitchenAnalysis.trays))
            mass += parseFloat(props.kitchenAnalysis.mass) - parseFloat(props.kitchenAnalysis.inevitableMass)
            cost += parseFloat(props.kitchenAnalysis.cost) - parseFloat(props.kitchenAnalysis.inevitableCost)
            co2 += parseFloat(props.kitchenAnalysis.co2) - parseFloat(props.kitchenAnalysis.inevitableCo2)
            if (trays === 0 && analysis)
                trays = analysis.trays;

        }
        return {
            massByConv: massByConv.toFixed(1),
            mass: mass.toFixed(0),
            cost: cost.toFixed(0),
            co2: co2.toFixed(0),
            trays: trays.toFixed(0)
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginLeft: 0, marginTop: '4vh' }}>
            <CustomBlock width={width} height={height} backgroundColor={'#5BB3E4'} hasHover={true} selected={selected === "massByConv"}>
                <div className="dashboard-block-values" onClick={() => dispatch(setSelectedAnalysisType('massByConv'))}>
                    <div className="dashboard-analysis-row-icon-value">
                        <div className="dashboard-block-values-icon-analysis">
                            <Tray style={{ height: '100%', width: '100%' }} />
                        </div>
                        <div className="dashboard-block-values-analysis-value">
                            {t('mass', { "value": { "mass": getValues().massByConv, "unit": "g" } })}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend">
                        {t('components.average')}
                    </div>
                </div>
            </CustomBlock>
            <CustomBlock width={width} height={height} marginLeft={marginLeft} hasHover={true} backgroundColor={'#7DCBB9'} selected={selected === "mass"}>
                {getInfo("mass")}
                {showInfo === "mass" && (
                    <div className="window-info" style={{ width: "23vw", position: "absolute", bottom: "100%", right: "-22vw", zIndex: "874656783", backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.bioWasteInfo")}</div>
                )}
                <div className="dashboard-block-values" onClick={() => dispatch(setSelectedAnalysisType('mass'))}>
                    <div className="dashboard-analysis-row-icon-value">
                        <div className="dashboard-block-values-icon-analysis">
                            <Kg style={{ height: '100%', width: '100%' }} />
                        </div>
                        <div className="dashboard-block-values-analysis-value">
                            {t('mass', { "value": { "mass": getValues().mass, "unit": "kg" } })}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend" style={{ width: "85%", paddingLeft: "15%" }}>
                        {t('components.wastedTotal')}
                    </div>
                </div>
            </CustomBlock>
            <CustomBlock width={width} height={height} marginLeft={marginLeft} hasHover={true} backgroundColor={'#89CD85'} selected={selected === "cost"}>
                {getInfo("cost")}
                {showInfo === "cost" && (
                    <div className="window-info" style={{ width: "23vw", position: "absolute", bottom: "100%", right: "-22vw", zIndex: "874656783", backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.costInfo")}</div>
                )}
                <div className="dashboard-block-values" onClick={() => dispatch(setSelectedAnalysisType('cost'))}>
                    <div className="dashboard-analysis-row-icon-value">
                        <div className="dashboard-block-values-icon-analysis">
                            <Euro style={{ height: '100%', width: '100%' }} />
                        </div>
                        <div className="dashboard-block-values-analysis-value">
                            {t('currency', { "value": { cost: getValues().cost } })}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend">
                        {t('components.economicLoss')}
                    </div>
                </div>
            </CustomBlock>
            <CustomBlock width={width} marginLeft={marginLeft} height={height} hasHover={true} backgroundColor={'#90c261'} selected={selected === "co2"}>
                {getInfo("co2")}
                {showInfo === "co2" && (
                    <div className="window-info" style={{ width: "19vw", position: "absolute", bottom: "100%", right: "-18vw", zIndex: "874656783", backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.co2Info")}</div>
                )}
                <div className="dashboard-block-values" onClick={() => dispatch(setSelectedAnalysisType('co2'))}>
                    <div className="dashboard-analysis-row-icon-value">
                        <div className="dashboard-block-values-icon-analysis">
                            <Earth style={{ height: '100%', width: '100%' }} />
                        </div>
                        <div className="dashboard-block-values-analysis-value">
                            {t('mass', { "value": { "mass": getValues().co2, "unit": "kg" } })}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend">
                        {t('components.environmentalImpact')}
                    </div>
                </div>
            </CustomBlock>
        </div>
    )
}

export default DayValues;
