import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import PlanningDetails from "./PlanningDetails";
import PlanningCalendar from "./PlanningCalendar";
import { getAllTasks, getSpecificTasks } from "../../../../apiRoutes/planning.routes";
import { setSelectedRestaurantDropdownPlanning, setTasks, setTasksClient } from "../../../../actions_reducers/actions/planning.action";
import PlanningSelection from "./PlanningSelection";
import PlanningRestaurant from "./PlanningRestaurant";
import PlanningClient from "./PlanningClient";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import { useTranslation } from "react-i18next";

const Planification = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        if (props.userState.user_type === "admin") {
            if (props.planningState.tasks.length === 0)
                getTasksList()
        }
        else {
            getTasksListClient()
        }
    }, []);
    const getTasksListClient = async () => {
        if (props.userState.user_type === "chef") {
            let tasks = await getSpecificTasks(props.userState.selected_restaurant);
            dispatch(setTasksClient(tasks))
        }
        else {
            let tasks = await getSpecificTasks(props.clientName, "client", props.userState.login);
            dispatch(setTasksClient(tasks))
        }
    }
    const getTasksList = async () => {
        let tasks = await getAllTasks();
        dispatch(setTasks(tasks))
    }
    const getRestaurantList = () => {
        let result = [];
        for (const restaurant of props.clientState.displayNames) {
            result.push(restaurant.value)
        }
        result.unshift(t('components.allRestaurants'))
        return result;
    }
    const updateSelectedRestaurant = (e) => {
        let restaurantTmp = e.target.id;
        if (restaurantTmp !== t('components.allRestaurants')) {
            let restaurant = props.clientState.displayNames.find(a => a.value === restaurantTmp).restaurant_name
            dispatch(setSelectedRestaurantDropdownPlanning(restaurant))
        }
        else
            dispatch(setSelectedRestaurantDropdownPlanning(null))
    }
    const getDisplayedValueDropdownRestaurant = () => {
        if (props.planningState.selectedRestaurantDropdown === null)
            return t('components.allRestaurants');
        else return props.clientState.displayNames.find(a => a.restaurant_name === props.planningState.selectedRestaurantDropdown).value;
    }
    if (props.userState.user_type === "admin")
        return (
            <div className="main-page-main-div" style={{ height: "85.8vh" }}>
                <div style={{ height: "5%", width: "100%" }}>
                    <PlanningSelection restaurants={props.restaurants} planningState={props.planningState} />
                </div>
                <div style={{ height: "3%" }} />
                <div style={{ height: "92%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "18%", height: "100%" }}>
                        <PlanningDetails restaurants={props.restaurants} tasks={props.planningState.tasks}
                            isTaskSelected={props.planningState.isTaskSelected}
                            addTaskOpen={props.planningState.addTaskOpen} planningState={props.planningState} />
                    </div>
                    <div style={{ width: "4%" }} />
                    <div style={{ width: "78%", height: "100%" }} >
                        <PlanningCalendar tasks={props.planningState.tasks} addTaskOpen={props.planningState.addTaskOpen} restaurants={props.restaurants} planningState={props.planningState} />
                    </div>
                </div>
            </div>
        )
    else if (props.userState.user_type === "chef") return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <PlanningRestaurant tasks={props.planningState.tasksClient} displayName={props.displayName} />
        </div>
    )
    else return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <div style={{ height: "8%", display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }}>
                <CustomDropdown
                    height={"70%"}
                    width={"25%"}
                    arrowColor={"#fff"}
                    color={"#fff"}
                    backgroundColor={"#89CD85"}
                    values={getRestaurantList()}
                    displayedValue={getDisplayedValueDropdownRestaurant()}
                    onChange={updateSelectedRestaurant}
                    maxHeightDropdown={getRestaurantList().length > 7 && "40vh"}
                />
            </div>
            <div style={{ height: "92%" }}>
                <PlanningClient tasks={props.planningState.tasksClient} displayNames={props.clientState.displayNames} restaurants={props.restaurants} planningState={props.planningState} />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userState: state.userReducer,
        planningState: state.planningReducer,
        restaurants: state.adminReducer.allInformations,
        displayName: state.userReducer.displayName,
        clientState: state.clientReducer,
        clientName: state.adminReducer.selectedClient
    };
}

export default connect(mapStateToProps)(Planification);
