import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/analyzes`;

export function getNextAnalysisForRestaurant(restaurant_name, nextAnalysisNumber) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getNextAnalysisForRestaurant", {
                params: { restaurant_name: restaurant_name, nextAnalysisNumber: nextAnalysisNumber },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function createAnalyzes(analyzes) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/createAnalyzes", {
                analyzes: analyzes
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function deleteAnalyzes(analyzes) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/deleteAnalyzes", {
                analyzes: analyzes
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function setSynthesisOver(restaurant_name, analysisNumber, synthesisOver) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/setSynthesisOver", {
                restaurant_name: restaurant_name, analysisNumber: analysisNumber, synthesisOver: synthesisOver
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function updateAnalysisValues(restaurant_name, analysisNumber, params) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateAnalysisValues", {
                restaurant_name: restaurant_name, analysisNumber: analysisNumber, params: params
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getAnalyzes(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/analyzes", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}

export function updateAnalysis(analysis) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateAnalysis", {
                analysis: analysis
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}