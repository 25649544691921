import React from "react";
import { useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import CustomButton from "../../../customComponents/CustomButton";
import { getTemplate, sendMail } from "../../../../apiRoutes/mails.routes";
import CustomAutocomplete from "../../../customComponents/CustomAutocomplete";
import CustomDropdownCheckBoxes from "../../../customComponents/CustomDropdownCheckBoxes";
import { setCc, setMailVariables, setSelectedRestaurantMail, setSelectedTemplate, setSelectedTemplateId, setSelectedVersionId, setSubject, setTemplateHtml, setTo, softRestMails } from "../../../../actions_reducers/actions/mails.action";
import CustomTextField from "../../../customComponents/CustomTextField";

const MailsClientList = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const confirmMailSend = async () => {
        let params = {
            to: props.to,
            cc: props.cc,
            version_id: props.mailsState.selectedVersionId,
            template_id: props.mailsState.selectedTemplateId,
            variables: {}
        }
        let hasVariables = true
        for (const variable of props.mailVariables) {
            if (variable.value === "") hasVariables = false;
            params.variables[variable.name] = variable.value;
        }
        if (hasVariables === true && props.to != null && props.to != "") {
            let res = await sendMail(params);
            if (res) {
                dispatch(softRestMails())
                window.alert("L'email a bien été envoyé.")
            }
            else window.alert("Erreur lors de l'envoi du mail.")
        }
        else window.alert("Veuillez renseigner toutes les variables et sélectionner un destinataire principal.")
    }

    const updateSelectedMailDropdown = (e, restaurant) => {
        getMailHtml(e.target.id);
        dispatch(setSelectedTemplate(e.target.id));
        dispatch(setSelectedRestaurantMail(restaurant))
    }
    const getMailsDropdown = (restaurant) => {
        let values = [];
        for (const mail of props.templates) {
            values.push(mail.name)
        }
        values.sort((a, b) => a.localeCompare(b))
        return <CustomAutocomplete
            width={"50%"}
            height={"50%"}
            color={"#666666"}
            maxHeightDropdown={"40vh"}
            values={values}
            selectedValue={(props.selectedTemplate && restaurant === props.selectedRestaurantMail) ? props.selectedTemplate : null}
            displayedValue={(props.selectedTemplate && restaurant === props.selectedRestaurantMail) ? props.selectedTemplate : t('components.selectTemplate')}
            onChange={(e) => updateSelectedMailDropdown(e, restaurant)}
        />
    }
    const getMailHtml = async (id) => {
        let template = props.templates.find(a => a.name === id);
        let template_id = template.template_id;
        let version_id = template.id
        let templateFull = await getTemplate(template_id, version_id)
        const regex = /{{{(.*?)}}}/g;
        let matches = [...templateFull[1].subject.matchAll(regex)];
        let matches2 = [...templateFull[1].html_content.matchAll(regex)];
        let variablesTmp = [];
        for (const match of matches) {
            variablesTmp.push({ type: "subject", name: match[1], value: "" })
        }
        for (const match of matches2) {
            variablesTmp.push({ type: "mail", name: match[1], value: "" })
        }
        dispatch(setSubject(templateFull[1].subject));
        dispatch(setSelectedTemplateId(template_id));
        dispatch(setSelectedVersionId(version_id));
        dispatch(setMailVariables(variablesTmp))
        dispatch(setTemplateHtml(templateFull[1].html_content))
    }
    const getRows = () => {
        let result = [];
        let list = JSON.parse(JSON.stringify(props.informations)).filter(a => a.clientName === props.selectedClient);
        list.sort((a, b) => a.restaurantName.localeCompare(b.restaurantName))
        for (const [index, restaurant] of list.entries()) {
            result.push(
                <div style={{ height: "15%", width: "100%", display: "flex", flexDirection: "row", backgroundColor: index % 2 === 0 ? "#F0F0F0" : "#fff" }}>
                    <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"80%"} height={"50%"} color={"#fff"} backgroundColor={"#9CCEBE"}
                            boxShadow={"none"}
                            id={restaurant.restaurantName}
                        >
                            {restaurant.restaurantName}
                        </CustomButton>
                    </div>
                    <div style={{ width: "70%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {getMailsDropdown(restaurant.restaurantName)}
                    </div>
                </div>
            )
        }
        return result;
    }
    const changeVariableValue = (e, variable) => {
        let variables = JSON.parse(JSON.stringify(props.mailVariables));
        let index = variables.findIndex(a => a.name === variable.name);
        variables[index].value = e.target.value;
        dispatch(setMailVariables(variables));
    }
    const getVariables = () => {
        let result = [];
        if (props.mailVariables) {
            for (const variable of JSON.parse(JSON.stringify(props.mailVariables))) {
                result.push(
                    <div style={{ display: "flex", height: "33%", width: "100%" }}>
                        <div style={{ width: "40%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            {t(`mail.${variable.type}`) + " : " + t(`mail.${variable.name}`)}
                        </div>
                        <div style={{ width: "40%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomTextField filter={"none"}
                                width={"90%"}
                                height={"50%"}
                                backgroundColor={"#D9D9D9"}
                                borderRadius={"10px"}
                                paddingLeft={"2%"}
                                paddingRight={"2%"}
                                value={variable.value}
                                onChange={(e) => changeVariableValue(e, variable)} />
                        </div>
                    </div>
                )
            }
        }
        return result;
    }
    const changeCcList = (mail) => {
        let ccs = JSON.parse(JSON.stringify(props.cc));
        let index = ccs.findIndex(a => a === mail);
        if (index === -1)
            ccs.push(mail)
        else ccs.splice(index, 1);
        dispatch(setCc(ccs))
    }
    const changeToList = (mail) => {
        let tos = JSON.parse(JSON.stringify(props.to));
        let index = tos.findIndex(a => a === mail);
        if (index === -1)
            tos.push(mail)
        else tos.splice(index, 1);
        dispatch(setTo(tos))
    }
    const getDestination = () => {
        let informations = props.informations.find(a => a.restaurantName === props.selectedRestaurantMail)
        let to = informations.mainReceiversMails;
        let cc = informations.copyReceiversMails;
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ height: "33%", display: "flex" }}>
                    <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "2%" }}>
                        {t(`mail.to`)}
                    </div>
                    <div style={{ width: "80%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <CustomDropdownCheckBoxes filter={"none"}
                            width={"70%"}
                            height={"50%"}
                            backgroundColor={"#D9D9D9"}
                            color={"#666666"}
                            borderRadius={"10px"}
                            justifyContent={"flex-start"}
                            insidePaddingLeft={"3%"}
                            displayedValue={t("mail.mainReceiver")}
                            values={to}
                            selectedValues={props.to}
                            onChange={(e) => changeToList(e.target.id)} />
                    </div>
                </div>
                <div style={{ height: "33%", display: "flex" }}>
                    <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "2%" }}>
                        {t(`mail.cc`)}
                    </div>
                    <div style={{ width: "80%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <CustomDropdownCheckBoxes filter={"none"}
                            width={"70%"}
                            height={"50%"}
                            backgroundColor={"#D9D9D9"}
                            color={"#666666"}
                            borderRadius={"10px"}
                            justifyContent={"flex-start"}
                            insidePaddingLeft={"3%"}
                            displayedValue={t("mail.copyReceivers")}
                            values={cc}
                            selectedValues={props.cc}
                            onChange={(e) => changeCcList(e.target.id)} />
                    </div>
                </div>
            </div>
        )
    }
    const getFinalValueSubject = () => {
        let template = props.subject;
        for (const variable of props.mailVariables) {
            if (variable.value)
                template = template.replace(`{{{${variable.name}}}}`, variable.value);
        }
        return template;
    }
    const getFinalValueBody = () => {
        let template = props.templateHtml;
        for (const variable of props.mailVariables) {
            if (variable.value)
                template = template.replace(`{{{${variable.name}}}}`, variable.value);
        }
        return template;
    }
    const getMailModal = () => {
        if (props.templateHtml != null) {
            return (<CustomBlock
                position={"absolute"}
                width={"100%"}
                height={"100%"}
                zIndex={"10"}
                top={"-2%"}
            >
                <div style={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "2vw", fontWeight: "bold", color: "#666666" }}>
                    {props.selectedRestaurantMail + " - " + props.selectedTemplate}
                </div>
                <div style={{ height: "25%", display: "flex" }}>
                    <div style={{ width: "50%", height: "100%" }}>
                        <CustomScrollDiv update={2}>
                            {getVariables()}
                        </CustomScrollDiv>
                    </div>
                    <div style={{ width: "50%", height: "100%" }}>
                        {getDestination()}
                    </div>
                </div>
                <div style={{ height: "10%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {getFinalValueSubject()}
                </div>
                <div style={{ height: "45%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ height: "100%", width: "60%", border: "1px solid #C4C4C4" }}>
                        <CustomScrollDiv update={Math.random()}>
                            <div style={{}} dangerouslySetInnerHTML={{ __html: getFinalValueBody() }} />
                        </CustomScrollDiv>

                    </div>
                </div>
                <div style={{ height: "10%", display: "flex" }}>
                    <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"50%"} height={"60%"} color={"#fff"} backgroundColor={"#666666"} value={t('buttons.cancel')} onClick={() => dispatch(softRestMails())} />
                    </div>
                    <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"50%"} height={"60%"} color={"#fff"} backgroundColor={"#89CD85B3"} value={t('buttons.confirm')} onClick={() => confirmMailSend()} />
                    </div>
                </div>
            </CustomBlock>)
        }
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}>
            {getMailModal()}
            <div style={{ height: "15%", width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{
                    width: "30%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.3vw"
                    , color: "#666666", fontWeight: "600"
                }}>
                    {t('table.associatedRestaurants')}
                </div>

                <div style={{
                    width: "70%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.3vw"
                    , color: "#666666", fontWeight: "600"
                }}>
                    {props.selectedClient}
                </div>
            </div>
            <div style={{ height: "85%", width: "100%" }}>
                <CustomScrollDiv id={"client-list-table"} update={Math.random()}>
                    {getRows()}
                </CustomScrollDiv>
            </div>
        </CustomBlock>
    )
}


export default MailsClientList;
