export const getEightSlide = (pres, t, analysis, kitchenAnalysis, x) => {
    let slide = pres.addSlide();

    slide.addImage({ path: require('../assets/8/title bg.png'), x: "45%", y: "2%", w: "55%", h: "20%" })
    slide.addImage({ path: require('../assets/8/observations.png'), x: "7.7%", y: "26%", w: "42%", h: "10%" })

    slide.addText(".......", { shape: pres.shapes.ROUNDED_RECTANGLE, valign: 'top', x: "7.7%", y: "37.8%", w: "42%", h: "13%", fontFace: 'Muli', line: { color: "7ABCE2", width: 2 },  color: '666666', fontSize: 14, bold: true });
    slide.addText(".......", { shape: pres.shapes.ROUNDED_RECTANGLE, valign: 'top', x: "7.7%", y: "53.5%", w: "42%", h: "13%", fontFace: 'Muli', line: { color: "7ABCE2", width: 2 },  color: '666666', fontSize: 14, bold: true });
    slide.addText(".......", { shape: pres.shapes.ROUNDED_RECTANGLE, valign: 'top', x: "7.7%", y: "69.2%", w: "42%", h: "13%", fontFace: 'Muli', line: { color: "7ABCE2", width: 2 },  color: '666666', fontSize: 14, bold: true });

    slide.addText(".......", { shape: pres.shapes.ROUNDED_RECTANGLE, valign: 'top', x: "51%", y: "37.8%", w: "42%", h: "13%", fontFace: 'Muli', line: { color: "90C261", width: 2 },  color: '666666', fontSize: 14, bold: true });
    slide.addText(".......", { shape: pres.shapes.ROUNDED_RECTANGLE, valign: 'top', x: "51%", y: "53.5%", w: "42%", h: "13%", fontFace: 'Muli', line: { color: "90C261", width: 2 },  color: '666666', fontSize: 14, bold: true });
    slide.addText(".......", { shape: pres.shapes.ROUNDED_RECTANGLE, valign: 'top', x: "51%", y: "69.2%", w: "42%", h: "13%", fontFace: 'Muli', line: { color: "90C261", width: 2 },  color: '666666', fontSize: 14, bold: true });

    slide.addImage({ path: require('../assets/8/solutionstips.png'), x: "51%", y: "26%", w: "42%", h: "10%" })

    slide.addImage({ path: require('../assets/3/logo_bg.png'), x: "0%", y: "86%", w: "15%", h: "14%" })
    slide.addImage({ path: require('../assets/1/logoNoText.png'), x: "1%", y: "89%", w: "11%", h: "9%" })

    slide.addText(t("synthesis.page8-9.strategy"), { x: "49%", y: "7%", w: "60%", h: "10%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 25, bold: true });
    slide.addText(t("synthesis.page8-9.possible_soltions"), { x: "51.5%", y: "27%", w: "41%", h: "7%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 27, bold: true });
    slide.addText(t("synthesis.page8-9.observations"), { x: "8%", y: "27%", w: "41%", h: "7%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 27, bold: true });
    slide.addText(x, { x: "97%", y: "96%", w: "3%", h: "3%", fontFace: 'Muli', align: "right",  color: '666666', fontSize: 15, bold: false });
    slide.background = { color: "#F8F8F3" }

    return pres;
}
