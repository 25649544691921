const images = {
  // bacs: {
  //   Pas_de_bac: require('../../assets/bacs/Pas_de_bac.png'),
  //   Petit_bac: require('../../assets/bacs/Petit_carré.png'),
  //   Moyen_bac: require('../../assets/bacs/Moyen_carré.png'),
  //   Grand_bac: require('../../assets/bacs/Grand_carré.png'),
  //   Gastronorme: require('../../assets/bacs/Gastronorme.png'),
  //   Bac_carre: require('../../assets/bacs/bac_carré.png'),
  //   Long_bac: require('../../assets/bacs/long_bac.png')
  // },
  // categories: {
  //   Entrée: require('../../assets/categories/Crudités.png'),
  //   Crudités: require('../../assets/categories/Crudités.png'),
  //   Graine: require('../../assets/categories/Graine.png'),
  //   Condiment: require('../../assets/categories/Condiment.png'),
  //   Petit_déjeuner: require('../../assets/categories/Petit_déjeuner.png'),
  //   Déchets_inévitables: require('../../assets/categories/Déchets_inévitables.png'),
  //   Dessert: require('../../assets/categories/Dessert.png'),
  //   Féculents: require('../../assets/categories/Féculents.png'),
  //   Fromage: require('../../assets/categories/Fromage.png'),
  //   Fruit: require('../../assets/categories/Fruit.png'),
  //   Légumes: require('../../assets/categories/Légumes.png'),
  //   Oeuf: require('../../assets/categories/Oeufs.png'),
  //   Pain: require('../../assets/categories/Pain.png'),
  //   Plats_composés: require('../../assets/categories/Plats_composés.png'),
  //   Poisson_fruits_de_mer: require('../../assets/categories/Produits_de_la_mer.png'),
  //   Poissons: require('../../assets/categories/Produits_de_la_mer.png'),
  //   Beurre: require('../../assets/foods/Beurre.png'),
  //   Viandes: require('../../assets/categories/Viandes.png'),
  //   Autres: require('../../assets/categories/Plats_composés.png'),
  // },
  foods: {
    Entrée: require('../../assets/foods/Crudités.png'),
    Crudités: require('../../assets/foods/Crudités.png'),
    Graine: require('../../assets/foods/Graine.png'),
    Condiment: require('../../assets/foods/Condiment.png'),
    Petit_déjeuner: require('../../assets/foods/Petit_déjeuner.png'),
    Déchets_inévitables: require('../../assets/foods/Déchets_inévitables.png'),
    Dessert: require('../../assets/foods/Dessert.png'),
    Féculents: require('../../assets/foods/Féculents.png'),
    Fromage: require('../../assets/foods/Fromage.png'),
    Fruit: require('../../assets/foods/Fruit.png'),
    Légumes: require('../../assets/foods/Légumes.png'),
    Oeuf: require('../../assets/foods/Oeufs.png'),
    Pain: require('../../assets/foods/Pain.png'),
    Plats_composés: require('../../assets/foods/Plats_composés.png'),
    Poisson_fruits_de_mer: require('../../assets/foods/Produits_de_la_mer.png'),
    Poissons: require('../../assets/foods/Produits_de_la_mer.png'),
    Beurre: require('../../assets/foods/Beurre.png'),
    Viandes: require('../../assets/foods/Viandes.png'),
    Autres: require('../../assets/foods/Plats_composés.png'),
    Autres: require('../../assets/foods/Plats_composés.png'),
    Crudités: require('../../assets/foods/Crudités.png'),
    Déchets_inévitables: require('../../assets/foods/Déchets_inévitables.png'),
    Dessert: require('../../assets/foods/Dessert.png'),
    Féculents: require('../../assets/foods/Féculents.png'),
    Fruit: require('../../assets/foods/Fruit.png'),
    Légumes: require('../../assets/foods/Légumes.png'),
    Oeuf: require('../../assets/foods/Oeufs.png'),
    Plats_composés: require('../../assets/foods/Plats_composés.png'),
    Poisson_fruits_de_mer: require('../../assets/foods/Produits_de_la_mer.png'),
    Viande: require('../../assets/foods/Viandes.png'),
    Abricot: require('../../assets/foods/Abricot.png'),
    Agrume: require('../../assets/foods/Agrumes.png'),
    Ananas: require('../../assets/foods/Ananas.png'),
    Artichaut: require('../../assets/foods/Artichaut.png'),
    Asperge: require('../../assets/foods/Asperge.png'),
    Aubergine: require('../../assets/foods/Aubergine.png'),
    Autres_plats_composés: require('../../assets/foods/Autres_plats_composés.png'),
    Autres_fruits: require('../../assets/foods/Autres_fruits.png'),
    Autres_gateaux: require('../../assets/foods/Autres_gateaux.png'),
    Autres_légumes_blancs: require('../../assets/foods/Autres_légumes_blancs.png'),
    Autres_légumes_rouges: require('../../assets/foods/Autres_légumes_rouges.png'),
    Autres_légumes_verts: require('../../assets/foods/Autres_légumes_verts.png'),
    Autres_peaux_de_fruits: require('../../assets/foods/Autres_peaux_de_fruits.png'),
    Avocat: require('../../assets/foods/Avocat.png'),
    Banane: require('../../assets/foods/Banane.png'),
    Beignet: require('../../assets/foods/Beignet.png'),
    Betterave: require('../../assets/foods/Betterave.png'),
    Boulettes: require('../../assets/foods/Boulettes.png'),
    Brocoli: require('../../assets/foods/Brocoli.png'),
    Calamar: require('../../assets/foods/Calamar.png'),
    Carotte: require('../../assets/foods/Carotte.png'),
    Carotte_râpée: require('../../assets/foods/Carotte_râpée.png'),
    Céréales: require('../../assets/foods/Céréales.png'),
    Champignon: require('../../assets/foods/Champignon.png'),
    Charcuterie: require('../../assets/foods/Charcuterie.png'),
    Chili: require('../../assets/foods/Chili.png'),
    Choucroute: require('../../assets/foods/Choucroute.png'),
    Chou_de_Bruxelles: require('../../assets/foods/Chou-de-Bruxelles.png'),
    Chou_fleur: require('../../assets/foods/Chou-fleur.png'),
    Choux_blanc: require('../../assets/foods/Choux_blanc.png'),
    Choux_rouge: require('../../assets/foods/Choux_rouge.png'),
    Compote: require('../../assets/foods/Compote.png'),
    Concombre: require('../../assets/foods/Concombre.png'),
    Coquille: require('../../assets/foods/Coquille.png'),
    Cornichon: require('../../assets/foods/Cornichon.png'),
    Courge: require('../../assets/foods/Courge.png'),
    Courgette: require('../../assets/foods/Courgette.png'),
    Couscous: require('../../assets/foods/Couscous.png'),
    Crème_dessert: require('../../assets/foods/Crème_dessert.png'),
    Crepe: require('../../assets/foods/Crêpe.png'),
    Crevette: require('../../assets/foods/Crevette.png'),
    Crumble: require('../../assets/foods/Crumble.png'),
    Endive_crue: require('../../assets/foods/Endive.png'),
    Endive_cuite: require('../../assets/foods/Endive_cuite.png'),
    Epinard: require('../../assets/foods/Epinard.png'),
    Feuille___Aromate: require('../../assets/foods/Feuille_aromate.png'),
    Friand: require('../../assets/foods/Friand.png'),
    Frites: require('../../assets/foods/Frites.png'),
    Fromage: require('../../assets/foods/Fromage.png'),
    Fruit_à_coque: require('../../assets/foods/Fruit_à_coque.png'),
    Fruit_rouge: require('../../assets/foods/Fruits_rouges.png'),
    Fruit_sec: require('../../assets/foods/Fruit_sec.png'),
    Galette: require('../../assets/foods/Galette.png'),
    Gateau: require('../../assets/foods/Gateau.png'),
    Gaufre: require('../../assets/foods/Gaufre.png'),
    Gnocchi: require('../../assets/foods/Gnocchi.png'),
    Guacamole: require('../../assets/foods/Guacamole.png'),
    Hachi: require('../../assets/foods/Hachi.png'),
    Hamburger: require('../../assets/foods/Hamburger.png'),
    Haricot_blanc: require('../../assets/foods/Haricot_blanc.png'),
    Haricot_rouge: require('../../assets/foods/Haricot_rouge.png'),
    Haricot_vert: require('../../assets/foods/Haricot_vert.png'),
    Houmous: require('../../assets/foods/Houmous.png'),
    Jambon_blanc: require('../../assets/foods/Jambon.png'),
    Kiwi: require('../../assets/foods/Kiwi.png'),
    Lasagne: require('../../assets/foods/Lasagne.png'),
    Lentilles: require('../../assets/foods/Lentille.png'),
    Mais: require('../../assets/foods/Maïs.png'),
    Mangue: require('../../assets/foods/Mangue.png'),
    Mélange_de_légumes: require('../../assets/foods/Mélange_de_légumes.png'),
    Melon: require('../../assets/foods/Melon.png'),
    Mollusque: require('../../assets/foods/Mollusque.png'),
    Mousse: require('../../assets/foods/Mousse.png'),
    Nem___Samossa: require('../../assets/foods/Nem.png'),
    Oeuf: require('../../assets/foods/Oeuf_dur.png'),
    Oeuf_dur: require('../../assets/foods/Oeufs.png'),
    Oignon: require('../../assets/foods/Oignon.png'),
    Olive: require('../../assets/foods/Olive.png'),
    Omelette: require('../../assets/foods/Omelette.png'),
    Os: require('../../assets/foods/Os.png'),
    Paella: require('../../assets/foods/Paella.png'),
    Pain: require('../../assets/foods/Pain.png'),
    Pané: require('../../assets/foods/Pané.png'),
    Pastèque: require('../../assets/foods/Pastèque.png'),
    Paté: require('../../assets/foods/Pâté.png'),
    Pates: require('../../assets/foods/Pâtes.png'),
    Pates_bolognaise: require('../../assets/foods/Pâtes_bolognaise.png'),
    Pates_carbonara: require('../../assets/foods/Pâtes_carbonara.png'),
    Peau_d_agrumes: require('../../assets/foods/Peau_d_agrume.png'),
    Peau_d_ananas: require('../../assets/foods/Peau_d_ananas.png'),
    Peau_de_banane: require('../../assets/foods/Peau_de_banane.png'),
    Peau_de_kiwi: require('../../assets/foods/Peau_de_kiwi.png'),
    Peau_de_melon___pastèque: require('../../assets/foods/Peau_de_melon_pastèque.png'),
    Peau_de_poisson: require('../../assets/foods/Peau_de_poisson.png'),
    Pelure_de_légumes___fruits: require('../../assets/foods/Peau_fine.png'),
    Peche: require('../../assets/foods/Pêche.png'),
    Petit_pois: require('../../assets/foods/Petit_pois.png'),
    Pizza: require('../../assets/foods/Pizza.png'),
    Poire: require('../../assets/foods/Poire.png'),
    Poireau: require('../../assets/foods/Poireaux.png'),
    Pois_chiche: require('../../assets/foods/Pois_chiches.png'),
    Poisson: require('../../assets/foods/Poisson.png'),
    Poivron: require('../../assets/foods/Poivron.png'),
    Pomme: require('../../assets/foods/Pomme.png'),
    Pomme_de_terre: require('../../assets/foods/Pomme_de_terre.png'),
    Potatoes: require('../../assets/foods/Potatoes.png'),
    Purée: require('../../assets/foods/Purée.png'),
    Purée_de_légumes_rouges: require('../../assets/foods/Purée_de_légumes_rouges.png'),
    Purée_de_légumes_verts: require('../../assets/foods/Purée_légumes_verts.png'),
    Quenelle: require('../../assets/foods/Quenelle.png'),
    Quiche: require('../../assets/foods/Quiche.png'),
    Radis: require('../../assets/foods/Radis.png'),
    Raisin: require('../../assets/foods/Raisin.png'),
    Ravioli: require('../../assets/foods/Ravioli.png'),
    Riz: require('../../assets/foods/Riz.png'),
    Salade: require('../../assets/foods/Salade.png'),
    Salade_composée: require('../../assets/foods/Salade_Composée.png'),
    Salade_de_fruits: require('../../assets/foods/Salade_de_fruits.png'),
    Sandwich: require('../../assets/foods/Sandwich.png'),
    Saucisse: require('../../assets/foods/Saucisse.png'),
    Saumon: require('../../assets/foods/Saumon.png'),
    Soupe: require('../../assets/foods/Soupe.png'),
    Steak_haché: require('../../assets/foods/Steak_haché.png'),
    Tapenade: require('../../assets/foods/Tapenade.png'),
    Tarte: require('../../assets/foods/Tarte.png'),
    Thon: require('../../assets/foods/Thon.png'),
    Tomate: require('../../assets/foods/Tomate.png'),
    Viande_blanche: require('../../assets/foods/Viande_blanche.png'),
    Viande_blanche_en_sauce: require('../../assets/foods/Viande_blanche_en_sauce.png'),
    Viande_rouge: require('../../assets/foods/Viande_rouge.png'),
    Viande_rouge_en_sauce: require('../../assets/foods/Viande_rouge_en_sauce.png'),
    Volaille: require('../../assets/foods/Volaille.png'),
    Wrap: require('../../assets/foods/Wrap.png'),
    Yaourt: require('../../assets/foods/Yaourt.png')
  },
};

export default images;
