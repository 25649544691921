import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const FirstAnalysisEvolution = (props) => {
    const { t } = useTranslation();
    const getValue = (value) => {
        return (parseFloat(value.mass) - parseFloat(value.inevitableMass)) / parseInt(value.trays)
    }
    const getEvolutionValue = () => {
        let firstAnalysis = props.analyzesDatas.find(a => a.analysisNumber === 1);
        let firstKitchenAnalysis = props.kitchenAnalyzesDatas.find(a => a.analysisNumber === 1);

        let valueFirst = 0;
        let valueFirstKitchen = 0;
        if (firstAnalysis) {
            valueFirst = getValue(firstAnalysis);
        }
        if (firstKitchenAnalysis) {
            valueFirstKitchen = getValue(firstKitchenAnalysis);
        }

        let currentAnalysis = props.analyzesDatas.find(a => a.analysisNumber === parseInt(props.selectedAnalysis));
        let currentKitchenAnalysis = props.kitchenAnalyzesDatas.find(a => a.analysisNumber === parseInt(props.selectedAnalysis));

        let valueCurrent = 0;
        let valueCurrentKitchen = 0;
        if (currentAnalysis) {
            valueCurrent = getValue(currentAnalysis);
        }
        if (currentKitchenAnalysis) {
            valueCurrentKitchen = getValue(currentKitchenAnalysis);
        }

        let valueFirstTotal = valueFirst + valueFirstKitchen;
        let valueCurrentTotal = valueCurrent + valueCurrentKitchen;
        let valueEvo = ((valueCurrentTotal - valueFirstTotal) / valueFirstTotal) * 100;
        let evolution = (valueEvo > 0 ? " + " : " - ") + (Math.abs(valueEvo)).toFixed(0) + " %"
        return evolution
    }
    const getMonthFirst = () => {
        let firstAnalysis = props.analyzesDatas.find(a => a.analysisNumber === 1);
        let firstKitchenAnalysis = props.kitchenAnalyzesDatas.find(a => a.analysisNumber === 1);
        let currentAnalysis = props.analyzesDatas.find(a => a.analysisNumber === parseInt(props.selectedAnalysis));
        let currentKitchenAnalysis = props.kitchenAnalyzesDatas.find(a => a.analysisNumber === parseInt(props.selectedAnalysis));
        let date1 = "";
        let date2 = "";
        if (firstAnalysis) date1 = moment(firstAnalysis.dateEnd).subtract(7, "days")
        else if (firstKitchenAnalysis) date1 = moment(firstKitchenAnalysis.dateEnd).subtract(7, "days");
        if (currentAnalysis) date2 = moment(currentAnalysis.dateEnd).subtract(7, "days")
        else if (currentKitchenAnalysis) date2 = moment(currentKitchenAnalysis.dateEnd).subtract(7, "days");
        return {
            first: t("date", { value: { date: date1, format: "MMMM YYYY" } }),
            second: t("date", { value: { date: date2, format: "MMMM YYYY" } })
        }
    }
    return (
        <CustomBlock width={'100%'} height={'40vh'} >
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title" style={{ paddingTop: "2%", paddingLeft: "3%" }}>
                    {t('components.foodWasteEvo')}
                </div>
                <div style={{ width: "100%", height: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomBlock width={"80%"} height={"80%"} backgroundColor={"#68B4E3A1"} color={"#fff"}>
                        <div style={{ height: "25%" }} />
                        <div style={{ height: "25%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "clamp(2.5rem,2.5rem,5rem)" }}>
                            {getEvolutionValue()}
                        </div>
                        <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{
                                width: "80%", textAlign: "center",
                                fontSize: "1.2vw"
                            }}>
                                {t('components.betweenDates', { month1: getMonthFirst().first, month2: getMonthFirst().second })}

                            </div>
                        </div>
                    </CustomBlock>
                </div >
            </div >
        </CustomBlock >
    )
}

export default FirstAnalysisEvolution;
