import { ResponsiveBar } from '@nivo/bar';
import './Chart.css';
import { useTranslation } from 'react-i18next';
import { red } from '@mui/material/colors';

const HorizontalBarChart = (props) => {
    const { t } = useTranslation();
    const data = props.data.sort((a, b) => a.name - b.name);

    const getMarkersValues = () => {
        if (props.type === "kitchen")
            return {
                values: [20, 40, 60],
                markerValue: 40,
                max: 80,
                divide: 80
            }
        else if (props.type === "restaurant")
            return {
                values: [40, 80, 120],
                markerValue: 80,
                max: 160,
                divide: 160
            }
        else if (props.type === "all")
            return {
                values: [40, 120, 200],
                markerValue: 120,
                max: 240,
                divide: 240
            }
        else return {
            values: [],
            markerValue: null,
            max: 0,
            divide: 1
        }
    }

    const toolTipElement = (node) => {
        return (
            <div className="custom-tooltip-pie" style={{
                backgroundColor: node.data.color,
                width: "150%"
            }}>
                {t('mass', { "value": { "mass": node.data.mass, "unit": "g" } })}
            </div>
        )
    }
    const getMarker = (graph) => {
        if (getMarkersValues().markerValue && graph) {
            let value = graph.innerWidth - (graph.innerWidth / getMarkersValues().divide) * getMarkersValues().markerValue;
            let text = t('components.nationAverage')
            let marker = [
                <svg key={"marker-1"} x={value}>
                    <line className="vertical-start-line"
                        id={"index"}
                        stroke={"#72B9A2"}
                        x1={-value}
                        y1={graph.innerHeight}
                        y2={0}
                        x2={-value}
                    />
                    <line className="vertical-line"
                        id={"index"}
                        stroke={"#F26E6E"}
                        x1={0}
                        y1={graph.innerHeight + 6}
                        y2={0}
                        x2={0}
                    />
                    <text x={"-14%"} y={-5} className="vertical-marker-text">{text}</text>
                </svg>
            ]
            return <g>{marker}</g>
        }
        else return null;
    }

    const getColor = (node) => {
        return node.data.color
    }

    return (<ResponsiveBar
        data={data}
        keys={[
            "x"
        ]}
        padding={-0.2}
        indexBy="name"
        margin={{ top: props.top || 30, right: props.right || 50, bottom: props.bottom || 30, left: props.left || 50 }}
        tooltip={(node) => toolTipElement(node)}
        layout={'horizontal'}
        colors={getColor}
        indexScale={{ type: 'band', round: true }}
        enableGridX={false}
        enableGridY={false}
        valueScale={{
            type: 'linear',
            min: 0,
            max: getMarkersValues().max,
            stacked: true,
            reverse: false
        }}
        yScale={{ max: 4, type: 'linear' }}
        axisLeft={null}
        borderRadius={5}
        enableLabel={false}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickValues: getMarkersValues().values,
            format: v => `${v} g`
        }}
        markers={
            [
                {
                    axis: 'x',
                    value: getMarkersValues().values[0],
                    lineStyle: { stroke: '#949494', strokeWidth: 1 },
                },
                {
                    axis: 'x',
                    value: getMarkersValues().values[getMarkersValues().values.length - 1],
                    lineStyle: { stroke: '#949494', strokeWidth: 1 },
                },
            ]}
        layers={
            [
                "grid",
                "axes",
                "areas",
                "markers",
                "bars",
                "points",
                "slices",
                "mesh",
                "legends",
                "labels",
                getMarker,
                (props) => {
                    const { bars } = props;
                    return bars.map((bar) => {
                        let color = "";
                        let bgColor = "";
                        if (bar.color !== "#F8E187") {
                            color = "#7CA67A";
                            bgColor = "#DCFEDB";
                        }
                        else {
                            color = "#FAD133";
                            bgColor = "#FFF7D8";
                        }
                        if (parseInt(bar.index) % 2 !== 0 && bar.data.value / (getMarkersValues().max + 20) * 100 <= 60)
                            return (
                                <foreignObject
                                    key={bar.key}
                                    x={bar.x + bar.width + 12} // Position outside the bar
                                    y={bar.y + bar.height / 2 - 7.5} // Center vertically
                                    width="34%"
                                    height="20px"
                                    style={{ overflow: 'visible', height: "10%" }}
                                >
                                    <div
                                        style={{
                                            color: color,
                                            backgroundColor: bgColor,
                                            borderRadius: '3px',
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: ".75vw",
                                            height: "100%"
                                        }}
                                    >
                                        {bar.data.indexValue}
                                    </div>
                                </foreignObject>)
                        else if (parseInt(bar.index) % 2 !== 0 && bar.data.value / (getMarkersValues().max + 20) * 100 > 60) {
                            return (
                                <foreignObject
                                    key={bar.key}
                                    x={bar.x + 2} // Position outside the bar
                                    y={bar.y + bar.height / 2 - 7.5} // Center vertically
                                    style={{ overflow: 'visible', height: "10%", width: bar.width }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: bar.color,
                                            borderRadius: '3px',
                                            display: "flex",
                                            textAlign: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: ".75vw",
                                            height: "100%",
                                            width: "100%"
                                        }}
                                    >
                                        {bar.data.indexValue}
                                    </div>
                                </foreignObject>)
                        }
                    }
                    );
                }
            ]}
    />)
}

export default HorizontalBarChart;