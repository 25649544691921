import React, { useEffect } from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { getTraysValueForCamAndHour } from "../../../customFunctions/trays.function";
import { useState } from "react";
import { ReactComponent as Information } from "../../../../assets/informationButton.svg";

const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const KitchenValues = (props) => {
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false)
    const [allValues, setAllValues] = useState({
        averageWeighIns: 0,
        serviceWeignIns: 0,
        averageWeighInsMass: 0,
        othersWeighIns: 0
    })
    useEffect(() => {
        let values = getValues();
        setAllValues(values)
    }, [])
    const getInfo = (type) => {
        return (
            <Information fill={"#666666"} style={{ width: "1.5vw", height: "2.5vh", top: "55%", right: "-2%", position: "absolute" }}
                onMouseLeave={() => setShowInfo(false)}
                onMouseEnter={() => setShowInfo(true)} />)
    }

    const getValues = () => {
        if (props.kitchenDatas && props.samplesBin) {
            let allDates = [];
            let averageWeighIns = 0;
            let serviceWeignIns = 0;
            let totalMass = 0;
            let othersWeighIns = 0;
            let weighIns = 0;
            for (const day of props.kitchenDatas.days) {
                let samples = JSON.parse(JSON.stringify(props.samplesBin)).filter(a => moment(a.created_at).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD'))
                averageWeighIns += samples.length


                allDates.push(moment(day.date).format('YYYY-MM-DD'))
                let sample = JSON.parse(JSON.stringify(props.samplesBin)).find(a => moment(a.created_at).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD'))
                if (sample)
                    serviceWeignIns += 1;

                for (const sample of samples) {
                    weighIns += 1;
                    if (sample.nameFood === "Autres")
                        othersWeighIns += 1;
                    if (sample.category !== "Déchets inévitables")
                        totalMass += sample.mass * 1000
                }
            }
            if (props.analysis) {
                for (const day of props.analysis.days) {
                    let date = moment(day.date).format('YYYY-MM-DD')
                    if (!allDates.find(a => a === date))
                        allDates.push(date)
                }
            }
            return {
                averageWeighIns: (averageWeighIns / props.kitchenDatas.days.length).toFixed(0),
                serviceWeignIns: ((serviceWeignIns / allDates.length) * 100).toFixed(0),
                averageWeighInsMass: totalMass / props.kitchenDatas.days.length,
                othersWeighIns: (othersWeighIns / weighIns * 100).toFixed(0)
            }
        }
        else return {
            averageWeighIns: 0,
            serviceWeignIns: 0,
            averageWeighInsMass: 0,
            othersWeighIns: 0
        };

    }
    const getHorizBar = (value) => {
        return (
            <div style={{ width: "80%", height: "100%", display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                <div style={{ width: "75%", height: "30%", display: "flex", backgroundColor: "#CBC4C4B3", borderRadius: "10px" }}>
                    <div style={{ width: (value >= 0 && value <= 5) ? "5%" : value + "%", backgroundColor: "#86CAF1", borderRadius: "10px" }} />
                </div>
                <div style={{ width: "25%", height: "30%", display: "flex", alignItems: "center", justifyContent: "flex-end", color: "#68B4E3" }}>
                    {value + "%"}
                </div>
            </div>
        )
    }
    return (
        <CustomBlock width={"100%"} height={"100%"}>
            <div style={{ height: "70%", width: ".25%", position: "absolute", backgroundColor: "#D9D9D9", left: "50%", top: "20%" }} />
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title" style={{ height: "10%", width: "100%" }}>
                    <div className="top-gaspi-title-div" style={{ paddingLeft: ".5%" }}>
                        {t('components.kitchenValuesBlockTitle')}

                    </div>
                </div>
                <div style={{ height: "90%", width: "100%", display: "flex" }}>
                    <div style={{ width: "50%", height: "100%" }}>

                        <div style={{ height: "50%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                            <div style={{ height: "50%", justifyContent: "center", display: "flex", alignItems: "flex-end" }}>
                                {t('components.averageWeighInsDay')}
                            </div>
                            <div style={{ height: "10%" }} />
                            <div style={{ fontWeight: "600", fontSize: "1.4vw", height: "40%", justifyContent: "center", display: "flex", alignItems: "flex-start" }}>
                                {t('components.weighInsValues', { value: allValues.averageWeighIns })}
                            </div>
                        </div>

                        <div style={{ height: "50%", width: "100%", paddingLeft: "1%" }}>
                            <div style={{ height: "45%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "400" }}>
                                {t('components.serviceWeighIns')}
                            </div>
                            <div style={{ height: "55%", width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center", fontWeight: "600" }}>
                                {getHorizBar(allValues.serviceWeignIns)}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "50%", height: "100%" }}>

                        <div style={{ height: "50%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                            <div style={{ height: "50%", justifyContent: "center", display: "flex", alignItems: "flex-end" }}>
                                {t('components.averageWeighIns')}
                            </div>
                            <div style={{ height: "10%" }} />
                            <div style={{ fontWeight: "600", fontSize: "1.4vw", height: "40%", justifyContent: "center", display: "flex", alignItems: "flex-start" }}>
                                {t('mass', { value: { mass: allValues.averageWeighInsMass, unit: 'kg' } })}
                            </div>
                        </div>

                        <div style={{ height: "50%", width: "100%", paddingLeft: "1%" }}>
                            <div style={{ height: "45%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "400" }}>
                                {t('components.othersWeighIns')}
                            </div>
                            <div style={{ height: "55%", width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "center", fontWeight: "600" }}>
                                {getHorizBar(allValues.othersWeighIns)}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </CustomBlock>
    )
}
export default KitchenValues;
