import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/newTrays`;

export function getNewTrays(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getNewTrays", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function addNewTrays(trays_of_day) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/addNewTrays", {
                trays_of_day: trays_of_day
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function updateNewTrays(date, restaurant_name, traysCam) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateNewTrays", {
                date: date,
                restaurant_name: restaurant_name,
                traysCam: traysCam
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}