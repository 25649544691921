const initialState = {
    selectedAnalysisType: 'massByConv',
    selectedAnalysisNumber: 1,
    selectedPieCategory: null,
    extrapolationValue: "analysis",
    showedAnalysis: null,
    selectedDay: null,
    selectedCategory: null,
    selectedDataType: 'restaurant',
    isAnalysisCut: false,
    loadAllDatas: true
}


const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ANALYSIS_TYPE':
            return {
                ...state,
                selectedAnalysisType: action.selectedAnalysisType
            }
        case 'SET_SELECTED_ANALYSIS_NUMBER':
            return {
                ...state,
                selectedAnalysisNumber: action.selectedAnalysisNumber
            }
        case 'SET_SELECTED_PIE_CATEGORY':
            return {
                ...state,
                selectedPieCategory: action.selectedPieCategory
            }
        case 'SET_EXTRAPOLATION_VALUE':
            return {
                ...state,
                extrapolationValue: action.extrapolationValue
            }
        case 'SET_SHOWED_ANALYIS':
            return {
                ...state,
                showedAnalysis: action.showedAnalysis
            }
        case 'SET_SELECTED_DAY':
            return {
                ...state,
                selectedDay: action.selectedDay
            }
        case 'SET_SELECTED_CATEGORY':
            return {
                ...state,
                selectedCategory: action.selectedCategory
            }
        case 'SET_SELECTED_DATA_TYPE_DASHBOARD':
            return {
                ...state,
                selectedDataType: action.selectedDataType
            }
        case 'SET_IS_ANALYSIS_CUT':
            return {
                ...state,
                isAnalysisCut: action.isAnalysisCut
            }
        case 'SET_LOAD_ALL_DATAS':
            return {
                ...state,
                loadAllDatas: action.loadAllDatas
            }
        case 'HARD_RESET_DASHBOARD':
            return initialState;

        default:
            return state;
    }
}

export default dashboardReducer;