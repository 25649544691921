import React from "react";
const CustomCheckbox = (props) => {
    return (
        <input type="checkbox" className='custom-checkbox-dropdown'
            id={props.id}
            style={{
                height: props.height,
                width: props.width,
                marginLeft: props.marginLeft || 0,
                marginRight: props.marginRight || 0,
                marginTop: props.marginTop || 0,
                marginBottom: props.marginBottom || 0,
                backgroundColor: props.backgroundColor || "#fff",
                paddingLeft: props.paddingLeft || 0,
                paddingRight: props.paddingRight || 0,
                paddingTop: props.paddingTop || 0,
                paddingBottom: props.paddingBottom || 0,
                boxShadow: props.boxShadow || "0px 4px 4px 0px #00000040",
                position: props.position || "relative",
                top: props.top,
                bottom: props.bottom,
                right: props.right,
                left: props.left,
                zIndex: props.zIndex,
                opacity: props.opacity || 1,
                border: props.error ? "1px solid red" : "1px solid #666666"
            }}
            disabled={props.disabled || false}
            checked={props.checked}
            onChange={props.onChange} />
    )
}

export default CustomCheckbox;