import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Warning } from "../../../../assets/warning.svg";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);


const AnalysisTitle = (props) => {
    const { t } = useTranslation();
    if (props.analysis && props.analysis.isOver) {
        const date = t('date', { value: { date: moment(props.analysis.dateEnd).subtract(7, "days").format("YYYY-MM-DD"), format: "MMMM YYYY" } });
        const title = date.charAt(0).toUpperCase() + date.slice(1)
        return (
            <div className="dashboard-analysis-title">
                <span style={{ fontWeight: "600", fontSize: "1.6vw" }}>
                    {title}&nbsp;
                </span>
                <span style={{fontSize:"1.4vw"}}>
                    {t('components.analysisTitle', {
                        dateStart: t('date', { value: { "date": props.analysis.dateStart, "format": "DD/MM/YYYY" } }),
                        dateEnd: t('date', { value: { "date": props.analysis.dateEnd, "format": "DD/MM/YYYY" } })
                    })}
                </span>
            </div>
        )
    }
    else {
        return (
            <div style={{ width: "100%", height: "100%", paddingRight: "5%" }}>
                <div className="dashboard-analysis-title" style={{ color: "#fff" }}>
                    {t('components.pendingAnalysis')}
                </div>
                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                    <div className="dashboard-analysis-warning-icon">
                        <Warning className="dashboard-analysis-warning-style" />
                    </div>
                    <div className="dashboard-analysis-message-text">
                        {t('longTexts.pendingAnalysis')}
                    </div>
                </div>
            </div>
        )
    }
}

export default AnalysisTitle;