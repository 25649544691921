import React, { useEffect, useState } from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import PieChart from "../../charts/PieChart";
import { getCategoryColor, getFoodsColors } from "../../customComponents/CustomColors";
import { useDispatch } from "react-redux";
import { setSelectedPieCategory } from "../../../actions_reducers/actions/dashboard.action";

const TopGaspi = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [colors, setColors] = useState({});

    useEffect(() => {
        if (props.food_list_kitchen) {
            updateColors()
        }
    }, [])
    const updateColors = () => {
        let tmp = {};
        for (const food of props.food_list_kitchen) {
            let name = t(`foods.${food.nameFood}`);
            if (getColor(name) == null) {
                tmp[name] = getRandomColor()
            }
        }
        setColors(tmp);
    }
    function getRandomColor() {
        return "hsl(" + 360 * Math.random() + ',' +
            (25 + 60 * Math.random()) + '%,' +
            (70 + 5 * Math.random()) + '%)'
    }
    const getColor = (value) => {
        if (props.state.selectedPieCategory === null) return getCategoryColor(value)
        else {
            if (value !== "Autres") {
                let color = getFoodsColors(value);
                if (color != null)
                    return color;
                else {
                    return getFoodsColors(value) || getRandomColor();
                }
            }
            else return "#CACACA"
        }
    }
    const getMassByName = (values, type) => {
        let result = [];
        if (values) {
            for (const value of values) {
                result.push({ id: type === "category" ? value.category : value.nameFood, mass: value.mass });
            }
        }
        return result;
    }
    const getFinalValues = (result, valuesMass, indexFood) => {
        for (const value of valuesMass) {
            if (value.id) {
                let index = result.findIndex(a => a.id.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") === value.id.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
                if (index === -1) {
                    let newCat = {
                        id: value.id,
                        value: value.mass,
                        color: getColor(value.id, indexFood),
                    };
                    result.push(newCat);
                }
                else {
                    result[index].value = parseFloat(result[index].value) + parseFloat(value.mass);
                }
            }
        }
        return result;
    }
    const getData = () => {
        let result = [];
        if (props.type !== "client") {
            let analysis
            let kitchenAnalysis
            if (props.analysis) analysis = props.analysis;
            else if (props.analyzesDatas) analysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber));
            if (analysis) {
                for (const day of analysis.days) {
                    if (day.mass > 0) {
                        if (props.state.selectedPieCategory === null) {
                            let categoriesMass = getMassByName(day.categories, "category");
                            result = getFinalValues(result, categoriesMass);
                        }
                        else {
                            let index = day.categories.findIndex(a => a.category === props.state.selectedPieCategory);
                            if (index !== -1) {
                                let foodsMass = getMassByName(day.categories[index].foods, "food");
                                result = getFinalValues(result, foodsMass, index);
                            }
                        }
                    }
                }
            }
            if (props.has_kitchen) {
                if (props.kitchenAnalysis) kitchenAnalysis = props.kitchenAnalysis;
                else if (props.kitchenAnalyzesDatas) kitchenAnalysis = props.kitchenAnalyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber));
                if (kitchenAnalysis) {
                    for (const day of kitchenAnalysis.days) {
                        if (day.mass > 0) {
                            if (props.state.selectedPieCategory === null) {
                                let categoriesMass = getMassByName(day.categories, "category");
                                result = getFinalValues(result, categoriesMass);
                            }
                            else {
                                let index = day.categories.findIndex(a => a.category === props.state.selectedPieCategory);
                                if (index !== -1) {
                                    let foodsMass = getMassByName(day.categories[index].foods, "food");
                                    result = getFinalValues(result, foodsMass, index);
                                }
                            }
                        }
                    }
                }
            }
            result.sort((a, b) => b.value - a.value);
            let otherIndex = result.findIndex(a => a.id === "Autres");
            let others = {};
            if (otherIndex === -1)
                others = {
                    id: "Autres",
                    value: 0,
                    color: getColor("Autres")
                }
            else {
                others = result[otherIndex]
                result.splice(otherIndex, 1)
            }
            let inevIndex = result.findIndex(a => a.id === "Déchets inévitables")
            if (inevIndex !== -1) {
                result.splice(inevIndex, 1)
            }
            if (result.length > 5) {
                for (let i = 5; i < result.length; i++) {
                    others.value = parseFloat(others.value) + parseFloat(result[i].value)
                }
                result.length = 5;
            }
            result.unshift(others);
            for (const tmp of result) {
                tmp.tooltip = t(`foods.${tmp.id}`) + " : " + t('mass', { value: { "mass": tmp.value, "unit": "kg" } })
            }
        }
        else {
            result = getFinalValues(result, props.analysis);
            result.sort((a, b) => b.value - a.value);
            let otherIndex = result.findIndex(a => a.id === "Autres");
            let others = {};
            if (otherIndex === -1)
                others = {
                    id: "Autres",
                    value: 0,
                    color: getColor("Autres")
                }
            else {
                others = result[otherIndex]
                result.splice(otherIndex, 1)
            }
            let inevIndex = result.findIndex(a => a.id === "Déchets inévitables")
            if (inevIndex !== -1) {
                result.splice(inevIndex, 1)
            }
            if (result.length > 5) {
                for (let i = 5; i < result.length; i++) {
                    others.value = parseFloat(others.value) + parseFloat(result[i].value)
                }
                result.length = 5;
            }
            result.unshift(others);
            for (const tmp of result) {
                tmp.tooltip = t(`foods.${tmp.id}`) + " : " + t('mass', { value: { "mass": tmp.value, "unit": "kg" } })
            }
        }
        return result;
    }
    const getLegend = () => {
        let fullMass = 0;
        let data = getData();
        let result = [];
        for (const value of data) {
            if (value)
                fullMass = parseFloat(fullMass) + parseFloat(value.value);
        }
        for (const [index, value] of data.entries()) {
            if (value)
                if (value.id !== "Autres") {
                    result.push(
                        <div key={"top-gaspi-" + value.id} className="dashboard-top-gaspi-body-legend-item"
                            style={{
                                marginTop: parseInt(index) === 1 ? "2.5%" : 0, cursor: !props.state.selectedPieCategory ? "pointer" : "default"
                            }}
                            onClick={() => !props.state.selectedPieCategory && dispatch(setSelectedPieCategory(value.id))}
                        >
                            <div className="dashboard-top-gaspi-body-legend-text">
                                {index + '. ' + t(`foods.${value.id}`)}
                            </div>
                            <div className="dashboard-top-gaspi-body-legend-button"
                                style={{
                                    borderColor: getColor(value.id),
                                    color: getColor(value.id),
                                    backgroundColor: getColor(value.id) + '20'
                                }}>
                                {((parseFloat(value.value) / parseFloat(fullMass)) * 100).toFixed(1) + '%'}
                            </div>
                        </div>
                    )
                }
        }
        return result;
    }
    const getSecondTitleText = () => {
        if (props.state.selectedPieCategory)
            return t(`topGaspiTitles.${props.state.selectedPieCategory}`);
        else return '';
    }
    return (
        <CustomBlock width={'100%'} height={'100%'} marginTop={props.marginTop}>
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title">
                    <div className="top-gaspi-title-div" style={{ paddingLeft: "1%" }}>
                        {t('components.wasted') + getSecondTitleText()}

                    </div>
                    {props.state.selectedPieCategory !== null &&
                        <div className="top-gaspi-back-div" onClick={() => dispatch(setSelectedPieCategory(null))}>
                            {t('buttons.back')}
                        </div>
                    }
                </div>
                <div className="dashboard-block-subtitle" style={{ paddingLeft: "1%" }}>
                    {props.type !== "client" ?
                        t('components.percentOfTot')
                        : t('components.percentOfTotLastAnalysis')}
                </div>
                <div className="dashboard-top-gaspi-body">
                    <div className="dashboard-top-gaspi-body-legend">
                        {getLegend()}
                    </div>
                    <div className="dashboard-top-gaspi-body-pie">
                        <PieChart data={getData()}
                            innerRadius={props.innerRadius || 0.7}
                            bottom={25} left={9} top={10} right={1} onClick={(e) => (!props.state.selectedPieCategory && e.id !== "Autres") && dispatch(setSelectedPieCategory(e.id))} />
                    </div>
                </div>
            </div>

        </CustomBlock>
    )
}
export default TopGaspi;