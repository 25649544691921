import React from "react";
import AnalysisTitle from "./AnalysisTitle";
import ExtrapolationButtonAnalysis from "./ExtrapolationButtonAnalysis";
import AnalysisValues from "./AnalysisValues";
import AnalysisGraph from "./AnalysisGraph";
import Bread from "./Bread";
import AverageWaste from "./AverageWaste";
import DataSelection from "../../commonElements/DataSelection";
import TraysValues from "./TraysValues";
import TopGaspi from "../../commonElements/TopGaspi";
import RepartitionGraph from "../../commonElements/RepartitionGraph";
import Strategies from "./Strategies";
import Pictures from "../../commonElements/Pictures";
import { useEffect } from "react";
import { getEmptyTraysByAnalysis, getNumberNonOrgaForAnalysis } from "../../../../apiRoutes/trays.route";
import { useDispatch } from "react-redux";
import { setAnalyzesTrays, setExtrapolatedAnalyzes, setKitchenExtrapolatedAnalyzes, setSelectedImageSamplesBin } from "../../../../actions_reducers/actions/datas.action";
import { BackToMenuButton } from "../../commonElements/BackToMenuButton";
import { useTranslation } from "react-i18next";
import Repartition from "./Repartition";
import WeighIns from "../../commonElements/WeighIns";
import AnalysisGap from "./AnalysisGap";
import { setSelectedPicture } from "../../../../actions_reducers/actions/pictures.action";
import { setExtrapolationValue } from "../../../../actions_reducers/actions/dashboard.action";
import KitchenValues from "./KitchenValues";

const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const DashboardAnalysisDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setExtrapolationValue("analysis"))
        dispatch(setKitchenExtrapolatedAnalyzes(props.kitchenAnalyzesDatas));
        dispatch(setExtrapolatedAnalyzes(props.analyzesDatas));
        if (!props.analyzesTrays.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis) && a.restaurant_name === props.userState.selected_restaurant))
            getTraysValues()
    }, [])
    const getTraysValues = async () => {
        let analysis = getSelectedBaseAnalysis();
        if (!analysis) analysis = getSelectedBaseAnalysiKitchen();
        let dateStart = analysis.dateStart;
        let dateEnd = moment(analysis.dateEnd).add(1, 'day').format("YYYY-MM-DD");
        let restaurant_name = props.userState.selected_restaurant;
        let emptyForAnalysis = await getEmptyTraysByAnalysis(restaurant_name, dateStart, dateEnd);
        let nonOrgaForAnalysis = await getNumberNonOrgaForAnalysis(restaurant_name, dateStart, dateEnd);
        let analysisTrays = {
            restaurant_name: restaurant_name,
            analysisNumber: props.dashboardState.showedAnalysis,
            nonOrga: nonOrgaForAnalysis,
            empty: emptyForAnalysis,
            days: []
        };
        let analyzesTrays = JSON.parse(JSON.stringify(props.analyzesTrays));
        analyzesTrays.push(analysisTrays);
        dispatch(setAnalyzesTrays(analyzesTrays));
    }
    const hasDatas = (type) => {
        if (props.userState.selectedAnalysisDataType.find(a => a === type) || props.userState.selectedAnalysisDataType.length === 0)
            return true;
        else return false;
    }
    const getCurrentAnalysis = () => {
        let selectedAnalysis;
        if (props.extrapolatedAnalyzes.length > 0 && props.analyzesDatas.find(a => a && parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis))) selectedAnalysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
        else if (props.kitchenAnalyzesDatas.length > 0 && props.kitchenAnalyzesDatas.find(a => a && parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis))) selectedAnalysis = props.kitchenAnalyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
        return selectedAnalysis;

    }
    const getSelectedAnalysis = () => {
        if (props.extrapolatedAnalyzes.length > 0 && hasDatas("restaurant")) {
            let selectedAnalysis = props.extrapolatedAnalyzes.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
            return selectedAnalysis;
        }
    }
    const getSelectedBaseAnalysis = () => {
        if (props.extrapolatedAnalyzes.length > 0 && hasDatas("restaurant")) {
            let selectedAnalysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));

            return selectedAnalysis;
        }
    }
    const getSelectedBaseAnalysiKitchen = () => {
        if (props.kitchenExtrapolatedAnalyzes.length > 0 && hasDatas("kitchen")) {
            let selectedKitchenAnalysis = props.kitchenAnalyzesDatas.find(a => a != null && parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis))
            if (selectedKitchenAnalysis)
                return selectedKitchenAnalysis
        }
    }
    const getAnalysisTrays = () => {
        let analysisTrays = props.analyzesTrays.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
        return analysisTrays;
    }

    const getSelectedKitchenAnalysis = () => {
        if (props.kitchenExtrapolatedAnalyzes.length > 0 && hasDatas("kitchen")) {
            let selectedAnalysis = props.kitchenExtrapolatedAnalyzes.find(a => a && parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
            return selectedAnalysis;
        }
    }
    const analyzesTypes = () => {
        let selectedAnalysis = null;
        let selectedKitchenAnalysis = null;
        if (props.extrapolatedAnalyzes.length > 0) selectedAnalysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
        if (props.kitchenAnalyzesDatas.length > 0) selectedKitchenAnalysis = props.kitchenAnalyzesDatas.find(a => a && parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
        return {
            hasRestaurantAnalysis: selectedAnalysis ? true : false,
            hasKitchenAnalysis: selectedKitchenAnalysis ? true : false
        };

    }

    const getObservationsForAnalysis = () => {
        let result = null;
        let observations = JSON.parse(JSON.stringify(props.observations));
        result = observations.find(a => a.analysisNumber === parseInt(props.dashboardState.showedAnalysis));
        return result;
    }
    return (

        <div className="main-page-main-div" style={{ backgroundColor: getCurrentAnalysis() && !getCurrentAnalysis().isOver && "#CBC4C4" }} onClick={(e) => {
            if (props.selectedImage !== null && e.target.id !== props.selectedImage && e.target.localName !== "path")
                dispatch(setSelectedImageSamplesBin(null))
            if (props.selectedPicture !== null && e.target.id !== props.selectedPicture && e.target.localName !== "path")
                dispatch(setSelectedPicture(null))
        }
        }>
            <div style={{ display: 'flex', flexDirection: 'row', width: "100%", height: getCurrentAnalysis() && getCurrentAnalysis().isOver ? "6vh" : "14vh" }}>
                <div style={{ width: getCurrentAnalysis().isOver ? "60%" : "81%", height: "100%" }}>
                    <AnalysisTitle showedAnalysis={props.dashboardState.showedAnalysis} analysis={getCurrentAnalysis()} />
                </div>
                <div style={{ width: getCurrentAnalysis().isOver ? "40%" : "19%", height: "100%" }}>
                    {getCurrentAnalysis() && getCurrentAnalysis().isOver ?
                        <DataSelection
                            hasRestaurantAnalysis={analyzesTypes().hasRestaurantAnalysis}
                            hasKitchenAnalysis={analyzesTypes().hasKitchenAnalysis}
                            userState={props.userState}
                            analyzesBaseDatas={props.analyzesBaseDatas}
                            baseAnalysis={getSelectedBaseAnalysis()}
                            baseAnalysisKitch={getSelectedBaseAnalysiKitchen()}
                            dashboardState={props.dashboardState}
                            analyzesDatas={props.analyzesDatas}
                            kitchenAnalyzesDatas={props.kitchenAnalyzesDatas}
                            value={t('buttons.accessMenuAndGuests')}
                            arianne={props.arianne} />
                        : <BackToMenuButton
                            state={props.dashboardState}
                            analyzesDatas={props.analyzesDatas}
                            value={t('buttons.accessMenuAndGuests')}
                            arianne={props.arianne}
                        />
                    }
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: "100%", height: "21vh", marginBottom: "2.75%" }}>
                <AnalysisValues analysis={getSelectedAnalysis()} baseAnalysis={getSelectedBaseAnalysis()} baseAnalysisKitch={getSelectedBaseAnalysiKitchen()} state={props.dashboardState} userState={props.userState} has_kitchen={props.has_kitchen} kitchenAnalysis={getSelectedKitchenAnalysis()} />
            </div>
            <div style={{ width: "100%", height: "47vh", display: "flex", flexDirection: "row" }}>
                <div style={{ width: "58.5%", height: "100%" }}>
                    <AnalysisGraph analysis={getSelectedAnalysis()} kitchenAnalysis={getSelectedKitchenAnalysis()} state={props.dashboardState} arianne={props.arianne} userState={props.userState} baseAnalysis={getSelectedBaseAnalysis()} baseAnalysisKitch={getSelectedBaseAnalysiKitchen()} />
                </div>
                <div style={{ width: "41.5%", height: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ width: "100%", height: "100%", paddingLeft: "9%" }}>
                        <div style={{ width: "100%", height: "36.54%", display: "flex" }}>
                            <div style={{ width: "45%", height: "100%" }}>
                                <AnalysisGap state={props.dashboardState} analyzesDatas={props.analyzesBaseDatas} kitchenAnalyzesDatas={props.kitchenAnalyzesDatas} />
                            </div>
                            <div style={{ width: "10.75%" }} />
                            <div style={{ width: "45%", height: "100%" }}>
                                <Bread analysis={getSelectedAnalysis()} state={props.dashboardState} has_kitchen={props.has_kitchen} kitchenAnalysis={getSelectedKitchenAnalysis()} baseAnalysis={getSelectedBaseAnalysis()} baseAnalysisKitch={getSelectedBaseAnalysiKitchen()} />
                            </div>
                        </div>
                        <div style={{ height: "10%" }} />
                        <div style={{ width: "100%", height: "53.46%" }}>
                            <AverageWaste state={props.dashboardState} analyzesDatas={props.analyzesBaseDatas} kitchenAnalysis={getSelectedBaseAnalysiKitchen()} has_kitchen={props.has_kitchen} kitchenAnalyzesDatas={props.kitchenAnalyzesDatas} analysis={getSelectedBaseAnalysis()} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", height: "38vh", display: "flex", flexDirection: "row", marginTop: "4%" }} >
                <div style={{ width: "23%", height: "100%" }}>
                    <TraysValues analysis={getCurrentAnalysis()} analysisTrays={getAnalysisTrays()} userState={props.userState} />
                </div>
                <div style={{ width: "5%" }} />
                <div style={{ width: "40%", height: "100%" }}>
                    <TopGaspi
                        food_list_kitchen={props.food_list_kitchen} state={props.dashboardState} analysis={getSelectedAnalysis()} has_kitchen={props.has_kitchen} kitchenAnalysis={getSelectedKitchenAnalysis()} />
                </div>
                <div style={{ width: "5%" }} />
                <div style={{ width: "27%", height: "100%" }}>
                    <RepartitionGraph baseAnalysis={getSelectedBaseAnalysis()} baseAnalysisKitch={getSelectedBaseAnalysiKitchen()} analysis={getSelectedAnalysis()} state={props.dashboardState} has_kitchen={props.has_kitchen} kitchenAnalysis={getSelectedKitchenAnalysis()} linkSize={120} />
                </div>
            </div>
            {(props.has_kitchen && props.has_restaurant && getSelectedKitchenAnalysis()) &&
                <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "40vh", marginTop: "4%" }}>
                    <div style={{ width: "40%", height: "100%" }}>
                        <Repartition width={"100%"} state={props.dashboardState} baseAnalysisKitch={props.kitchenAnalyzesDatas} baseAnalysis={props.analyzesDatas} kitchenDatas={props.kitchenExtrapolatedAnalyzes} analyzesDatas={props.extrapolatedAnalyzes} />
                    </div>
                    <div style={{ width: "5%" }} />
                    <div style={{ width: "55%", height: "100%" }}>
                        <KitchenValues userState={props.userState} analysis={getCurrentAnalysis()} kitchenDatas={getSelectedBaseAnalysiKitchen()} samplesBin={props.samplesBin} />
                    </div>
                </div>
            }
            {(((getCurrentAnalysis() && getCurrentAnalysis().isOver && getObservationsForAnalysis() != null) || props.userState.login.includes("kikleo.com") || props.userState.login.includes("admin")) && props.has_synthesis) &&
                < div style={{ width: "100%", height: "85.8vh", marginTop: "4%" }} >
                    <Strategies userState={props.userState} observations={props.observations}
                        analysisNumber={parseInt(props.dashboardState.showedAnalysis)} />

                </div>
            }
            {
                getSelectedAnalysis() &&
                <div style={{ width: "100%", height: "85vh", marginTop: "4%" }} >
                    <Pictures
                        selectedPicture={props.selectedPicture}
                        pictures_list={props.pictures_list}
                        analysis={getSelectedAnalysis()}
                        food_list={props.food_list}
                        userState={props.userState}
                        displayedPictures={props.displayedPictures} />
                </div>
            }
            {
                getSelectedKitchenAnalysis() &&
                <div style={{ width: "100%", height: "85vh", marginTop: "4%" }} >
                    <WeighIns
                        selectedDate={props.dashboardState.selectedDay}
                        pictures_list={props.pictures_list}
                        analysis={getSelectedKitchenAnalysis()}
                        food_list={props.food_list}
                        userState={props.userState}
                        weighIns={props.weighIns}
                        food_list_kitchen={props.food_list_kitchen}
                        kitchenDatas={props.kitchenAnalyzesDatas}
                        analysisNumber={props.dashboardState.showedAnalysis}
                        selectedImage={props.selectedImage} />
                </div>
            }
        </div >
    )
}
export default DashboardAnalysisDetails;