import React from "react";
import ExtrapolationButton from "../../commonElements/ExtrapolationButton";
import AnalyzesValues from "../../commonElements/AnalyzesValues";
import CompareSelection from "./CompareSelection";
import CompareRestaurant from "./CompareRestaurants";
import CompareGraph from "./CompareGraph";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDropdown from "../../../customComponents/CustomDropdown";

const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const separationHeight = "6vh";
const separationWidth = "6%";
const ClientCompare = (props) => {
    const { t } = useTranslation();
    const [selectedType, setSelectedType] = useState(t('components.totalWasteConv'));

    const getSelectedAnalysis = () => {
        let dates = props.clientAnalyzesDates.find(a => parseInt(a.analysisNumber) === parseInt(props.selectedAnalysis));
        if (dates) {
            let currentAnalysis = JSON.parse(JSON.stringify(props.clientExtrapolatedAnalyzes)).find(a =>
                a.restaurant_name === props.selectedRestaurant &&
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
            );
            return currentAnalysis;
        }
    }
    const getSelectedBaseAnalysis = () => {
        let dates = props.clientAnalyzesDates.find(a => parseInt(a.analysisNumber) === parseInt(props.selectedAnalysis));
        if (dates) {
            let currentAnalysis = JSON.parse(JSON.stringify(props.clientAnalyzes)).find(a =>
                a.restaurant_name === props.selectedRestaurant &&
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
            );
            return currentAnalysis;
        }

    }
    const getSelectedAnalysisKitchen = () => {
        let dates = props.clientAnalyzesDates.find(a => parseInt(a.analysisNumber) === parseInt(props.selectedAnalysis));
        if (dates) {
            let currentAnalysis = JSON.parse(JSON.stringify(props.clientKitchenExtrapolatedAnalyzes)).find(a =>
                a.restaurant_name === props.selectedRestaurant &&
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
            );
            return currentAnalysis;
        }
    }
    const getSelectedBaseAnalysisKitchen = () => {
        let dates = props.clientAnalyzesDates.find(a => parseInt(a.analysisNumber) === parseInt(props.selectedAnalysis));
        if (dates) {
            let currentAnalysis = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).find(a =>
                a.restaurant_name === props.selectedRestaurant &&
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
            );
            return currentAnalysis;
        }

    }
    const updateSelectedDropdownValue = (e) => {
        let value = e.target.id;
        setSelectedType(value)
    }
    const getDropdownTypeValues = () => {
        return [t('components.totalWasteConv'), t('components.restaurantWasteConv'), t('components.kitchenWasteConv')]
    }
    const showRestaurant = () => {
        if (selectedType === t('components.totalWasteConv') || selectedType === t('components.restaurantWasteConv'))
            return true;
        else return false;
    }
    const showKitchen = () => {
        if (selectedType === t('components.totalWasteConv') || selectedType === t('components.kitchenWasteConv'))
            return true;
        else return false;

    }
    const getDropdownType = () => {
        return (
            <CustomDropdown
                width={'80%'}
                height={'80%'}
                backgroundColor={'#fff'}
                displayedValue={selectedType}
                selectedValue={selectedType}
                values={getDropdownTypeValues()}
                onChange={updateSelectedDropdownValue}
                arrowColor={"#6D6C7A"}
                color={"#949494"}
                border={"1px solid #6D6C7A"}

            />)
    }
    return (
        <div className="main-page-main-div">
            <div style={{ display: 'flex', flexDirection: 'row', width: "100%", height: "36vh" }}>
                <div style={{
                    width: '55%'
                }}>
                    <ExtrapolationButton id={"client-extrap"} state={props.clientState} analyzesDatas={props.clientAnalyzes}
                        kitchenAnalyzesDatas={props.clientKitchenAnalyzes} type={"client"} userState={props.userState} />
                    <AnalyzesValues state={props.clientState} analyzesDatas={getSelectedAnalysis()} baseAnalysis={getSelectedBaseAnalysis()} height={"26vh"} type={"client"}
                        showKitchen={showKitchen()}
                        showRestaurant={showRestaurant()}
                        kitchenAnalysis={getSelectedAnalysisKitchen()}
                        baseAnalysisKitchen={getSelectedBaseAnalysisKitchen()} />
                </div>
                <div style={{ width: separationWidth }} />
                <div style={{ width: "39%" }}>
                    <CompareSelection
                        displayNames={props.displayNames}
                        selectedRestaurants={props.selectedRestaurants}
                        selectedRestaurant={props.selectedRestaurant}
                        selectedAnalysis={props.selectedAnalysis}
                        analysisNumbersList={props.analysisNumbersList}
                        dropdown={getDropdownType()} />
                </div>
            </div>
            <div style={{ height: separationHeight }} />
            <div style={{ width: "100%", height: "50vh" }}>
                <CompareGraph
                    displayNames={props.displayNames}
                    selectedRestaurants={props.selectedRestaurants}
                    selectedRestaurant={props.selectedRestaurant}
                    selectedAnalysis={props.selectedAnalysis}
                    clientAnalyzes={props.clientAnalyzes}
                    clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                    clientExtrapolatedAnalyzes={props.clientExtrapolatedAnalyzes}
                    clientAnalyzesDates={props.clientAnalyzesDates}
                    dataTypeClient={props.dataTypeClient}
                    extrapolationValue={props.extrapolationValue}
                    showKitchen={showKitchen()}
                    showRestaurant={showRestaurant()}
                    kitchenAnalyzes={props.clientKitchenExtrapolatedAnalyzes}
                    baseAnalyzesKitchen={props.clientKitchenAnalyzes} />
            </div>
            <div style={{ height: separationHeight }} />
            <div style={{ width: "100%", height: "85.8vh" }}>
                <CompareRestaurant
                    displayNames={props.displayNames}
                    clientAnalyzes={props.clientAnalyzes}
                    clientAnalyzesDates={props.clientAnalyzesDates}
                    selectedRestaurants={props.selectedRestaurants}
                    selectedRestaurant={props.selectedRestaurant}
                    selectedAnalysis={props.selectedAnalysis}
                    analysisNumbersList={props.analysisNumbersList}
                    clientKitchenAnalyzes={props.clientKitchenAnalyzes} />
            </div>
        </div >
    )
}

export default ClientCompare;
