import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useDispatch } from "react-redux";
import CustomButton from "../../../customComponents/CustomButton";
import { setCurrentPageOnBoarding, setOnBoardingDone, setShowOnBoarding } from "../../../../actions_reducers/actions/files.action";
import CustomCheckbox from "../../../customComponents/CustomCheckbox";
import CustomTextField from "../../../customComponents/CustomTextField";
import { hardResetOnBoarding, setHasActions, setProjectContact, setProjectContactMail, setProjectContactPhone, setProjectReferent, setProjectReferentMail, setProjectReferentPhone, setStakeholders, setTeamMotivation, setTeamMotivationNote, setTypeActions, setWhyNotActions } from "../../../../actions_reducers/actions/onBoarding.action";
import { useState } from "react";
import { saveOnBoardingForRestaurant } from "../../../../apiRoutes/onBoarding.routes";
import { modifyWasteManagementMethod } from "../../../../apiRoutes/informations.routes";

const year = "2023-2024";

export const FourthPage = (props) => {
    const [error_actions, seterror_actions] = useState(true);
    const [error_motivation, seterror_motivation] = useState(true);
    const [error_stakeholders, seterror_stakeholders] = useState(true);
    const [error_contact, seterror_contact] = useState(true);
    const [error_contact2, seterror_contact2] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const canBeModified = props.on_boarding_done;
    const [modalConfirmOpen, setModalConfirmOpen] = useState(false);

    const hasAllInformations = () => {
        let onBoardingValues = props.onBoardingState;
        if (onBoardingValues.hasActions === "") {
            seterror_actions(false);
            return false;
        } else if (onBoardingValues.hasActions === true && onBoardingValues.typeActions.replace(/ /g, "") === "") {
            seterror_actions(false);
            return false;
        } else if (onBoardingValues.hasActions === false && onBoardingValues.whyNotActions.replace(/ /g, "") === "") {
            seterror_actions(false);
            return false;
        } else if (onBoardingValues.teamMotivation === "") {
            seterror_actions(true);
            seterror_motivation(false);
            return false;
        } else if (onBoardingValues.teamMotivation === t('onBoarding.neutral') || onBoardingValues.teamMotivation === t('onBoarding.no')) {
            seterror_motivation(false);
            return true;
        } else if (onBoardingValues.stakeholders.replace(/ /g, "") === "") {
            seterror_motivation(true);
            seterror_stakeholders(false);
            return false;
        } else if (onBoardingValues.projectContact.replace(/ /g, "") === "") {
            seterror_stakeholders(true);
            seterror_contact(false);
            return false;
        } else if (onBoardingValues.projectContactMail.replace(/ /g, "") === "") {
            seterror_contact(false);
            return false;
        } else if (onBoardingValues.projectContactPhone.replace(/ /g, "") === "") {
            seterror_contact(false);
            return false;
        } else if (onBoardingValues.projectReferent.replace(/ /g, "") !== "" && (onBoardingValues.projectReferentMail.replace(/ /g, "") === "" || onBoardingValues.projectReferentPhone.replace(/ /g, "") === "")) {
            seterror_contact2(false);
            return false;
        }
        else {
            seterror_actions(true);
            seterror_motivation(true);
            seterror_stakeholders(true);
            seterror_contact(true);
            seterror_contact2(true)
            return true;
        }
    }

    const confirmInformations = () => {
        if (hasAllInformations())
            setModalConfirmOpen(true);
    }
    const previousPage = () => {
        dispatch(setCurrentPageOnBoarding(3))
    }
    const getWasteManagement = () => {
        let wasteManagement = props.onBoardingState.wasteManagementMethod;
        if (wasteManagement === t('wasteManagementMethods.incineration'))
            return "incinération"
        else if (wasteManagement === t('wasteManagementMethods.burial'))
            return "enfouissement"
        else if (wasteManagement === t('wasteManagementMethods.composting'))
            return "compostage"
        else if (wasteManagement === t('wasteManagementMethods.methanization'))
            return "méthanisation"
        else if (wasteManagement === t('wasteManagementMethods.animalFeed'))
            return "alimentation animale"
    }
    const saveInformations = async () => {
        let newOnBoarding = JSON.parse(JSON.stringify(props.onBoardingState));
        newOnBoarding.restaurant_name = props.restaurant_name;
        let wasteManagementMethod = getWasteManagement()
        await modifyWasteManagementMethod(props.restaurant_name, wasteManagementMethod.toLowerCase())
        await saveOnBoardingForRestaurant(newOnBoarding);
        setModalConfirmOpen(false);
        dispatch(hardResetOnBoarding());
        dispatch(setShowOnBoarding(false));
        dispatch(setOnBoardingDone(true));
    }
    const getModalConfirm = () => {
        return (
            <div style={{
                height: "100vh",
                width: "100vw",
                zIndex: 10000000000,
                position: "fixed",
                top: 0,
                right: 0,
                backgroundColor: "#66666670",
            }}>
                <CustomBlock
                    position={"fixed"}
                    width={"50vw"}
                    height={"auto"}
                    backgroundColor={"#fff"}
                    top={"20%"}
                    left={"30.5%"}
                    paddingBottom={"1%"}
                    paddingTop={"1%"}
                    paddingLeft={"2%"}
                    paddingRight={"2%"}
                >
                    <div style={{ width: "100%", height: "6vh", fontSize: "1.2vw", fontWeight: "500", color: "#666666" }}>
                        {t('components.confirmMessage')}
                    </div>
                    <div style={{ width: "100%", height: "6vh", color: "#666666", display: "flex", alignItems: "center" }}>
                        {t('onBoarding.lastModification')}
                    </div>
                    <div style={{ width: "100%", height: "6vh", color: "#666666" }}>
                    </div>
                    <div style={{ width: "100%", height: "7vh", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center" }}>
                            <CustomButton
                                width={"50%"}
                                height={"70%"}
                                color={"#fff"}
                                backgroundColor={"#72B9A2"}
                                onClick={() => saveInformations()}>{t('buttons.confirm')}
                            </CustomButton>
                        </div>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center" }}>
                            <CustomButton
                                width={"50%"}
                                height={"70%"}
                                color={"#fff"}
                                backgroundColor={"#666666"}
                                onClick={() => setModalConfirmOpen(false)}>{t('buttons.cancel')}
                            </CustomButton>
                        </div>
                    </div>
                </CustomBlock>
            </div>
        )
    }
    const getWaste = () => {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ width: "100%", height: "6%", color: "#68B4E3", fontSize: "1.2vw", fontWeight: "bold" }}>
                    {t('onBoarding.wasteReduction')}
                </div>
                <div style={{ width: "100%", height: "5.5%", color: "#666666" }}>
                    <i>{t('onBoarding.wasteReductionDesc')}</i>
                </div>
                <div style={{ width: "100%", height: "88%", color: "#666666" }}>
                    <div style={{ width: "99.5%", height: "8%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ width: "70%", fontWeight: "bold" }}>
                            {t('onBoarding.isActions')}
                        </div>
                        <div style={{ width: "10%", height: "100%", display: "flex", flexDirection: "row", paddingLeft: "0%" }}>
                            <div style={{ width: "24.5%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => {
                                        dispatch(setHasActions(props.onBoardingState.hasActions === "" ? true : !props.onBoardingState.hasActions));
                                        dispatch(setWhyNotActions(""))
                                    }}
                                    checked={props.onBoardingState.hasActions === true} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {t('onBoarding.yes')}
                            </div>
                        </div>
                        <div style={{ width: "10%", height: "100%", display: "flex", flexDirection: "row", paddingLeft: "0%" }}>
                            <div style={{ width: "24.5%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomCheckbox
                                    disabled={canBeModified}
                                    height={"2vh"}
                                    width={"2vh"}
                                    boxShadow={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    onChange={(e) => {
                                        dispatch(setHasActions(props.onBoardingState.hasActions === "" ? false : !props.onBoardingState.hasActions));
                                        dispatch(setTypeActions(""))
                                    }}
                                    checked={props.onBoardingState.hasActions === false} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {t('onBoarding.no')}
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "12%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "14%", height: "100%", display: "flex", paddingTop: "0.8%" }}>
                            {t('onBoarding.ifYesActions')}
                        </div>
                        <div style={{ width: "100%", height: "100%", position: "relative" }}>
                            <textarea
                                disabled={canBeModified || props.onBoardingState.hasActions === false}
                                className="on-boarding-text-area"
                                onChange={(e) => dispatch(setTypeActions(e.target.value))}
                                value={props.onBoardingState.typeActions} />
                        </div>
                    </div>
                    <div style={{ height: "2%" }} />
                    <div style={{ height: "12%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "14%", height: "100%", display: "flex", paddingTop: "0.8%" }}>
                            {t('onBoarding.ifNoActions')}
                        </div>
                        <div style={{ width: "100%", height: "100%", position: "relative" }}>
                            <textarea
                                disabled={canBeModified || props.onBoardingState.hasActions === true}
                                className="on-boarding-text-area"
                                onChange={(e) => dispatch(setWhyNotActions(e.target.value))}
                                value={props.onBoardingState.whyNotActions} />
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "11%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ width: "64.8%", fontWeight: "bold" }}>
                            {t('onBoarding.teamMotivation')}
                        </div>
                        <div style={{ width: "35%", height: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "21%", height: "100%", display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "35%", height: "100%", display: "flex", alignItems: "center" }}>
                                    <CustomCheckbox
                                        disabled={canBeModified}
                                        height={"2vh"}
                                        width={"2vh"}
                                        boxShadow={"none"}
                                        backgroundColor={"#D9D9D9"}
                                        onChange={(e) => dispatch(setTeamMotivation(t('onBoarding.yes')))}
                                        checked={props.onBoardingState.teamMotivation === t('onBoarding.yes')} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {t('onBoarding.yes')}
                                </div>
                            </div>
                            <div style={{ width: "27%", height: "100%", display: "flex", flexDirection: "row", }}>
                                <div style={{ width: "27%", height: "100%", display: "flex", alignItems: "center" }}>
                                    <CustomCheckbox
                                        disabled={canBeModified}
                                        height={"2vh"}
                                        width={"2vh"}
                                        boxShadow={"none"}
                                        backgroundColor={"#D9D9D9"}
                                        onChange={(e) => dispatch(setTeamMotivation(t('onBoarding.neutral')))}
                                        checked={props.onBoardingState.teamMotivation === t('onBoarding.neutral')} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {t('onBoarding.neutral')}
                                </div>
                            </div>
                            <div style={{ width: "20%", height: "100%", display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "35%", height: "100%", display: "flex", alignItems: "center" }}>
                                    <CustomCheckbox
                                        disabled={canBeModified}
                                        height={"2vh"}
                                        width={"2vh"}
                                        boxShadow={"none"}
                                        backgroundColor={"#D9D9D9"}
                                        onChange={(e) => dispatch(setTeamMotivation(t('onBoarding.no')))}
                                        checked={props.onBoardingState.teamMotivation === t('onBoarding.no')} />
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {t('onBoarding.no')}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ width: "100%", height: "4.5%", display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: "1%" }}>
                        <div style={{ width: "8.8%" }}>
                            {t('onBoarding.note')}
                        </div>
                        <div style={{ width: "100%", height: "100%" }}>
                            <CustomTextField
                                disabled={canBeModified}
                                width={"85.7%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                textAlign={"left"}
                                paddingLeft={"2%"}
                                onChange={(e) => dispatch(setTeamMotivationNote(e.target.value))}
                                value={props.onBoardingState.teamMotivationNote} />
                        </div>
                    </div>
                    <div style={{ height: "2%" }}></div>
                    <div style={{ width: "100%", height: "12%", display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "2%" }}>
                        <p>
                            <b>{t('onBoarding.stakeholders')}</b>&nbsp;
                            <span style={{ fontSize: "0.8vw", display: "flex", flexDirection: "column", paddingLeft: "2%" }}>
                                {t('onBoarding.stakeholdersDesc')}
                            </span >
                        </p>
                    </div>
                    <div style={{ width: "100%", height: "10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <CustomTextField
                            disabled={canBeModified}
                            width={"87.2%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                            textAlign={"left"}
                            paddingLeft={"2%"}
                            onChange={(e) => dispatch(setStakeholders(e.target.value))}
                            value={props.onBoardingState.stakeholders} />
                    </div>
                    <div style={{ height: "1%" }}></div>
                    <div style={{ width: "93%", height: "5%", display: "flex", flexDirection: "row", alignItems: "center", fontWeight: "bold", paddingTop: "1%" }}>
                        <div style={{ width: "45%" }}>
                            {t('onBoarding.contact')}
                        </div>
                        <div style={{ width: "55%", height: "100%", display: "flex", alignItems: "center" }}>
                            <CustomTextField
                                disabled={canBeModified}
                                width={"88.7%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                textAlign={"left"}
                                paddingLeft={"2%"}
                                onChange={(e) => dispatch(setProjectContact(e.target.value))}
                                value={props.onBoardingState.projectContact} />
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "12%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: "1%" }} >
                            <div style={{ width: "22%" }}>
                                {t('onBoarding.mail')}
                            </div>
                            <div style={{ width: "75%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomTextField
                                    disabled={canBeModified} width={"80%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    textAlign={"left"}
                                    paddingLeft={"2%"}
                                    onChange={(e) => dispatch(setProjectContactMail(e.target.value))}
                                    value={props.onBoardingState.projectContactMail} />
                            </div>
                        </div>
                        <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                            <div style={{ width: "31%" }}>
                                {t('onBoarding.phone')}
                            </div>
                            <div style={{ width: "65%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    type={"number"} width={"67%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    textAlign={"left"}
                                    paddingLeft={"2%"}
                                    onChange={(e) => dispatch(setProjectContactPhone(e.target.value))}
                                    value={props.onBoardingState.projectContactPhone} />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "7%", display: "flex", flexDirection: "row", fontWeight: "bold", alignItems: "flex-end" }}>
                        <div style={{ width: "64%" }}>
                            {t('onBoarding.technicalContact')}
                        </div>
                        <div style={{ width: "42%", height: "100%", display: "flex", alignItems: "flex-end", paddingTop: "2%" }}>
                            <CustomTextField
                                disabled={canBeModified}
                                width={"68%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                textAlign={"left"}
                                paddingLeft={"2%"}
                                onChange={(e) => dispatch(setProjectReferent(e.target.value))}
                                value={props.onBoardingState.projectReferent} />
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "12%", display: "flex", flexDirection: "row", alignItems: "center", paddingLeft: "1%" }}>
                        <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center" }} >
                            <div style={{ width: "21%" }}>
                                {t('onBoarding.mail')}
                            </div>
                            <                   div style={{ width: "75%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    width={"80%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    textAlign={"left"}
                                    paddingLeft={"2%"}
                                    onChange={(e) => dispatch(setProjectReferentMail(e.target.value))}
                                    value={props.onBoardingState.projectReferentMail} />
                            </div>
                        </div>
                    </div>

                    <div style={{ width: "100%", height: "6Fnot%", display: "flex", flexDirection: "row", alignItems: "right", paddingLeft: "1%" }}>
                        <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row", alignItems: "right", paddingRight: "1%" }} >
                            <div style={{ width: "31%" }}>
                                {t('onBoarding.phone')}
                            </div>
                            <div style={{ width: "51.5%", height: "100%", display: "flex", alignItems: "center" }}>
                                <CustomTextField
                                    disabled={canBeModified}
                                    type={"number"}
                                    width={"100%"} backgroundColor={"#F0F0F0"} filter={"none"} borderRadius={"10px"} color={"#666666"}
                                    textAlign={"left"}
                                    paddingLeft={"2%"}
                                    onChange={(e) => dispatch(setProjectReferentPhone(e.target.value))}
                                    value={props.onBoardingState.projectReferentPhone} />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
    return (
        <div style={{ width: "100%", height: "100%", paddingLeft: "2%", paddingRight: "2%", }}>
            {modalConfirmOpen && getModalConfirm()}
            <div style={{ display: error_actions ? "none" : "block", position: "absolute", top: "6.5%", left: "2%", width: "85%", height: "33.7%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_motivation ? "none" : "block", position: "absolute", top: "41.1%", left: "2%", width: "85%", height: "10.5%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_stakeholders ? "none" : "block", position: "absolute", top: "52.4%", left: "2%", width: "85%", height: "13.4%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_contact ? "none" : "block", position: "absolute", top: "66.6%", left: "2%", width: "85%", height: "11%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_contact2 ? "none" : "block", position: "absolute", top: "78.6%", left: "2%", width: "85%", height: "19%", paddingLeft: "1.5%", alignItems: "center", border: "0.11vw solid red", borderRadius: "0.56vw" }}></div>
            <div style={{ display: error_actions ? "none" : "block", position: "absolute", top: "15.4%", left: "1.5%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                {t('Veuillez selectionner une réponse et la préciser ensuite')}
            </div>
            <div style={{ display: error_motivation ? "none" : "block", position: "absolute", top: "44.6%", left: "2.4%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                {t('Veuillez selectionner une réponse (et la préciser ensuite si besoin)')}
            </div>
            <div style={{ display: error_stakeholders ? "none" : "block", position: "absolute", top: "58.8%", left: "2.5%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                {t('Veuillez remplir le champ ci-dessous')}
            </div>
            <div style={{ display: error_contact ? "none" : "block", position: "absolute", top: "70.7%", left: "2.5%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                {t('Veuillez remplir toutes les informations de contact')}
            </div>
            <div style={{ display: error_contact2 ? "none" : "block", position: "absolute", top: "83.2%", left: "2.5%", width: "95%", height: "3%", paddingLeft: "1.5%", alignItems: "center", fontSize: "0.6vw", color: "red" }}>
                {t('Veuillez remplir toutes les informations de contact')}
            </div>
            <div className="on-boarding-title-row">
                {t('onBoarding.title', { year: year, page: " - Page 4/4" })}
            </div>
            <div style={{ height: "1%" }} />
            <div style={{ width: "100%", height: "80%" }}>
                {getWaste()}
            </div>
            <div style={{ height: "2%" }}></div>

            <div style={{ width: "100%", height: "10%", display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "flex-end" }}>
                <CustomButton
                    width={"15%"}
                    height={"70%"}
                    color={"#fff"}
                    backgroundColor={"#666666"}
                    onClick={() => previousPage()}
                >
                    {t('buttons.previous')}
                </CustomButton>
                {!props.on_boarding_done &&
                    <><div style={{ width: "2%" }} />
                        <CustomButton
                            width={"20%"}
                            height={"70%"}
                            color={"#fff"}
                            backgroundColor={"#68B4E3"}
                            onClick={() => confirmInformations()}
                        >
                            {t('buttons.confirmInformations')}
                        </CustomButton>
                    </>}
            </div>

        </div >
    )
}