import env from "react-dotenv";
import applicationStore from "../../store/store";

export function getNumberDaysExtrap(value, nbDaysanalysis) {
    let numberWeeks = applicationStore.getState().userReducer.numberWeeks;
    let hasSaturday = env.REACT_APP_ENV_TYPE === "ovh" ? true : applicationStore.getState().userReducer.has_saturday;
    let hasSunday = env.REACT_APP_ENV_TYPE === "ovh" ? true : applicationStore.getState().userReducer.has_sunday;
    const getNumberDays = (numberWeeks) => {
        if (hasSaturday && hasSunday)
            return parseInt(numberWeeks) * (7);
        else if (hasSaturday || hasSunday)
            return parseInt(numberWeeks) * 6;
        else return numberWeeks * 5;
    }

    switch (value) {
        case 'day':
            return 1;
        case 'week':
            return getNumberDays(1);
        case 'month':
            return getNumberDays(4.5);
        case 'year':
            return env.REACT_APP_ENV_TYPE === "ovh" ? 350 : getNumberDays(numberWeeks);
        case 'analysis':
            return nbDaysanalysis;
    }
}
const calculateExtrapolatedValues = (value, extrapType, averageTrays, analysisTrays) => {
    let massConv = parseFloat(value.mass) / parseInt(analysisTrays);
    let costConv = parseFloat(value.cost) / parseInt(analysisTrays);
    let co2Conv = parseFloat(value.co2) / parseInt(analysisTrays);
    let inevMassConv = parseFloat(value.inevitableMass) / parseInt(analysisTrays);
    let inveCostConv = parseFloat(value.inevitableCost) / parseInt(analysisTrays);
    let inevCo2Conv = parseFloat(value.inevitableCo2) / parseInt(analysisTrays);
    value.mass = massConv * averageTrays * getNumberDaysExtrap(extrapType);
    value.cost = costConv * averageTrays * getNumberDaysExtrap(extrapType);
    value.co2 = co2Conv * averageTrays * getNumberDaysExtrap(extrapType);
    value.inevitableMass = inevMassConv * averageTrays * getNumberDaysExtrap(extrapType);
    value.inevitableCost = inveCostConv * averageTrays * getNumberDaysExtrap(extrapType);
    value.inevitableCo2 = inevCo2Conv * averageTrays * getNumberDaysExtrap(extrapType);

    return value;
}
export const extrapolateAnalyzesValues = (analyzes, extrapType, type, averageTraysTmp) => {
    if (type !== 'client') {
        if (extrapType !== "analysis") {
            for (let analysis of analyzes) {
                let analysisTrays = analysis.trays;
                let averageTrays = averageTraysTmp ? averageTraysTmp : analysis.trays / analysis.days.length;
                analysis = calculateExtrapolatedValues(analysis, extrapType, averageTrays, analysisTrays)
                for (let day of analysis.days) {
                    day = calculateExtrapolatedValues(day, extrapType, averageTrays, analysisTrays)
                    for (let category of day.categories) {
                        category = calculateExtrapolatedValues(category, extrapType, averageTrays, analysisTrays)
                        for (let food of category.foods) {
                            food = calculateExtrapolatedValues(food, extrapType, averageTrays, analysisTrays)
                        }
                    }
                }
            }
        }

    }
    else {
        if (extrapType !== "analysis") {
            for (let analysis of analyzes) {
                let analysisTrays = analysis.trays;
                let averageTrays = averageTraysTmp.find(a => a.restaurant_name === analysis.restaurant_name) ?
                    averageTraysTmp.find(a => a.restaurant_name === analysis.restaurant_name).value
                    : analysis.trays / analysis.dates.length;
                analysis = calculateExtrapolatedValues(analysis, extrapType, averageTrays, analysisTrays)
                for (let day of analysis.categories) {
                    day = calculateExtrapolatedValues(day, extrapType, averageTrays, analysisTrays)
                }
            }
        }
    }
    return analyzes;
}