import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux"; //REDUX
import applicationStore from "./store/store";
import './localization/i18n';
import { HashRouter } from 'react-router-dom';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://7c0d8e1e7923743bd42ae9fb8ea1e2db@o4508199894843392.ingest.de.sentry.io/4508199896481872",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [ "https://plateforme.kikleo.com", "https://plateforme.kikleo.fr"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
//ceci est un commentaire inutile

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={applicationStore}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
