import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import { ReactComponent as Euro } from "../../../../assets/euro.svg";
import { ReactComponent as Kg } from "../../../../assets/kg.svg";
import { ReactComponent as Earth } from "../../../../assets/earth.svg";
import 'moment/locale/fr';
import { getNumberDaysExtrap } from "../../../customFunctions/extrapolation.function";
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);


const ClientWasteDetails = (props) => {
    const { t } = useTranslation();


    const getMaxValue = () => {
        let max = 1;
        for (const date of props.clientAnalyzesDates) {
            if (date.analysisNumber > max) max = date.analysisNumber
        }
        let hasMax = false;
        if (props.clientAnalyzesDates.length > 0 && props.clientAnalyzes.length > 0)
            while (hasMax === false) {
                let lastDate = props.clientAnalyzesDates.find(a => a.analysisNumber === max);
                let testAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                    moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(lastDate.dateStart)))
                    &&
                    moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(lastDate.dateEnd)))
                )
                let testAnalyzesKitchen = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                    moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(lastDate.dateStart)))
                    &&
                    moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(lastDate.dateEnd)))
                )
                if ((!testAnalyzes || testAnalyzes.length === 0) && (!testAnalyzesKitchen || testAnalyzesKitchen.length === 0)) {
                    max -= 1;
                }
                else hasMax = true;
            }
        return max;
    }

    const getValues = () => {
        let numberDaysExtrap = getNumberDaysExtrap("year")
        let max = getMaxValue();
        let dates = props.clientAnalyzesDates.find(a => parseInt(a.analysisNumber) === max);
        let selectedAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
            moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
            &&
            moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
        );
        let selectedKitchenAnalyzes = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
            moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
            &&
            moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
        );
        let mass = 0;
        let cost = 0;
        let co2 = 0;
        let trays = 0;
        let massKitchen = 0;
        let costKitchen = 0;
        let co2Kitchen = 0;
        let traysKitchen = 0;
        let traysDayTest = 0;
        let nbDaysTest = 0;
        let traysFinal = 0;
        for (const analysis of selectedAnalyzes) {
            let averageTrays = props.clientAverageGuests.find(a => a.restaurant_name === analysis.restaurant_name)
            mass = parseFloat(mass) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
            cost = parseFloat(cost) + parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost);
            co2 = parseFloat(co2) + parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2);
            trays = parseFloat(trays) + parseFloat(analysis.trays);

            traysDayTest = parseInt(traysDayTest) + parseInt(analysis.trays)
            nbDaysTest = parseInt(nbDaysTest) + parseInt(analysis.dates.length)

            let kitchenAnalysis = selectedKitchenAnalyzes.find(a => a.analysisNumber === analysis.analysisNumber && a.restaurant_name === analysis.restaurant_name);
            if (kitchenAnalysis) {
                massKitchen = parseFloat(massKitchen) + parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
                costKitchen = parseFloat(costKitchen) + parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost);
                co2Kitchen = parseFloat(co2Kitchen) + parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2);
                traysKitchen = parseFloat(traysKitchen) + parseFloat(kitchenAnalysis.trays);

                traysDayTest = parseInt(traysDayTest) + parseInt(kitchenAnalysis.trays);
                nbDaysTest = parseInt(nbDaysTest) + parseInt(kitchenAnalysis.dates.length);
            }
            traysFinal = parseFloat(traysFinal) + (averageTrays ? averageTrays.value : parseInt(traysDayTest) / parseInt(nbDaysTest));
            traysDayTest = 0;
            nbDaysTest = 0;
        }

        for (const kitchenAnalysis of selectedKitchenAnalyzes) {
            let averageTrays = props.clientAverageGuests.find(a => a.restaurant_name === kitchenAnalysis.restaurant_name)
            if (!selectedAnalyzes.find(a => a.restaurant_name === kitchenAnalysis.restaurant_name)) {
                massKitchen = parseFloat(massKitchen) + parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
                costKitchen = parseFloat(costKitchen) + parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost);
                co2Kitchen = parseFloat(co2Kitchen) + parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2);
                traysKitchen = parseFloat(traysKitchen) + parseFloat(kitchenAnalysis.trays);

                traysDayTest = parseInt(traysDayTest) + parseInt(kitchenAnalysis.trays);
                nbDaysTest = parseInt(nbDaysTest) + parseInt(kitchenAnalysis.dates.length)
                traysFinal = parseFloat(traysFinal) + (averageTrays ? averageTrays.value : parseInt(traysDayTest) / parseInt(nbDaysTest));
                traysDayTest = 0;
                nbDaysTest = 0;

            }
        }
        let massConv = trays > 0 ? mass / trays : 0;
        let massConvKitchen = traysKitchen > 0 ? massKitchen / traysKitchen : 0;
        let costConv = trays > 0 ? cost / trays : 0;
        let costConvKitchen = traysKitchen > 0 ? costKitchen / traysKitchen : 0;
        let co2Conv = trays > 0 ? co2 / trays : 0;
        let co2ConvKitchen = traysKitchen > 0 ? co2Kitchen / traysKitchen : 0;

        let massConvTot = parseFloat(massConv) + parseFloat(massConvKitchen);
        let costConvTot = parseFloat(costConv) + parseFloat(costConvKitchen);
        let co2ConvTot = parseFloat(co2Conv) + parseFloat(co2ConvKitchen);
        let massFinal = parseInt(traysFinal) * massConvTot * numberDaysExtrap;
        let costFinal = parseInt(traysFinal) * costConvTot * numberDaysExtrap;
        let co2Final = parseInt(traysFinal) * co2ConvTot * numberDaysExtrap;

        let massKitchenFinal = parseInt(traysFinal) * massConvKitchen * numberDaysExtrap;
        let massRestaurantFinal = parseInt(traysFinal) * massConv * numberDaysExtrap;
        let costKitchenFinal = parseInt(traysFinal) * costConvKitchen * numberDaysExtrap;
        let costRestaurantFinal = parseInt(traysFinal) * costConv * numberDaysExtrap;
        let co2KitchenFinal = parseInt(traysFinal) * co2ConvKitchen * numberDaysExtrap;
        let co2RestaurantFinal = parseInt(traysFinal) * co2Conv * numberDaysExtrap;
        return {
            mass: t('mass', { value: { "mass": massFinal.toFixed(0), "unit": "kg" } }),
            cost: t('currency', { "value": { cost: costFinal.toFixed(0), "fixed": 0 } }),
            co2: t('mass', { value: { "mass": co2Final.toFixed(0), "unit": "kg" } }) + t('components.ofCo2'),
            massConv: t('mass', { value: { "mass": (massConvTot).toFixed(1), "unit": "g" } }),
            massRestaurantFinal: t('mass', { value: { "mass": massRestaurantFinal.toFixed(0), "unit": "kg" } }),
            massKitchenFinal: t('mass', { value: { "mass": massKitchenFinal.toFixed(0), "unit": "kg" } }),
            costKitchenFinal: t('currency', { "value": { cost: costKitchenFinal.toFixed(0), "fixed": 0 } }),
            costRestaurantFinal: t('currency', { "value": { cost: costRestaurantFinal.toFixed(0), "fixed": 0 } }),
            co2KitchenFinal: t('mass', { value: { "mass": co2KitchenFinal.toFixed(0), "unit": "kg" } }),
            co2RestaurantFinal: t('mass', { value: { "mass": co2RestaurantFinal.toFixed(0), "unit": "kg" } }),
        }
    }
    return (
        <CustomBlock width={'100%'} height={'100%'} paddingTop={"2%"} paddingBottom={"2%"} paddingLeft={"2%"} paddingRight={"2%"}>
            <div className="dashboard-block-title" style={{ height: "10%" }}>
                {t('components.losses')}
            </div>
            <div style={{ width: "100%", height: "10%", color: "#666666" }}>
                {t('components.totalWaste')}
            </div>
            <div style={{ height: "80%", width: "100%", paddingRight: "5%", paddingLeft: "5%" }}>

                <div style={{ width: "100%", height: "50%", flexDirection: "row", display: "flex" }}>
                    <CustomBlock
                        backgroundColor={"#7DCBB9"}
                        color={"#fff"}
                        width={"30%"}
                        height={"100%"}
                        paddingRight={"2%"}
                        paddingBottom={"4%"}
                        paddingTop={"4%"}
                        paddingLeft={"2%"}
                    >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "50%", color: "#fff" }}>
                            <div style={{ width: "35%", height: "100%", display: "flex", alignItems: "center" }}>
                                <Kg style={{ width: "100%", height: "100%" }} />
                            </div>
                            <div style={{ width: "65%", height: "100%", display: "flex", alignItems: "center", fontSize: "1.3vw", textAlign: "center" }}>
                                {getValues().mass}
                            </div>
                        </div>
                        <div style={{ fontSize: "0.9vw", width: "100%",display: "flex", alignItems: "center", textAlign: "center", height: "50%", color: "#fff", paddingLeft: "5%", paddingRight: "5%" }}>
                            {t('components.wasteConso')}
                        </div>
                    </CustomBlock>
                    <div style={{ width: "5%" }} />
                    <CustomBlock
                        backgroundColor={"#89CD85"}
                        color={"#fff"}
                        width={"30%"}
                        height={"100%"}
                        paddingRight={"2%"}
                        paddingBottom={"4%"}
                        paddingTop={"4%"}
                        paddingLeft={"2%"}
                    >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "50%", color: "#fff" }}>
                            <div style={{ width: "35%", height: "100%", display: "flex", alignItems: "center" }}>
                                <Euro style={{ width: "90%", height: "100%" }} />
                            </div>
                            <div style={{ width: "65%", height: "100%", display: "flex", alignItems: "center", fontSize: "1.3vw", textAlign: "center" }}>
                                {getValues().cost}
                            </div>
                        </div>
                        <div style={{ fontSize: "0.9vw", width: "100%", display: "flex", alignItems: "center", textAlign: "center", height: "50%", color: "#fff", paddingLeft: "5%", paddingRight: "5%" }}>
                            {t('components.costConso')}
                        </div>
                    </CustomBlock>
                    <div style={{ width: "5%" }} />
                    <CustomBlock
                        backgroundColor={"#90C261"}
                        color={"#fff"}
                        width={"30%"}
                        height={"100%"}
                        paddingRight={"2%"}
                        paddingBottom={"4%"}
                        paddingTop={"4%"}
                        paddingLeft={"2%"}
                    >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "50%", color: "#fff" }}>
                            <div style={{ width: "35%", height: "100%", display: "flex", alignItems: "center" }}>
                                <Earth style={{ width: "90%", height: "100%" }} />
                            </div>
                            <div style={{ width: "65%", height: "100%", display: "flex", alignItems: "center", fontSize: "1.3vw", textAlign: "center" }}>
                                {getValues().co2}
                            </div>
                        </div>
                        <div style={{ fontSize: "0.9vw", width: "100%", display: "flex", alignItems: "center", textAlign: "center", height: "50%", color: "#fff", paddingLeft: "5%", paddingRight: "5%" }}>
                            {t('components.co2Rejected')}
                        </div>
                    </CustomBlock>
                </div>
                <div style={{ height: "5%" }} />
                <div style={{ width: "100%", height: "20%", flexDirection: "row", display: "flex" }}>
                    <div className="client-dashboard-losses-div" >
                        <div style={{ width: "100%", height: "80%", textAlign: "center" }}>
                            <div style={{ height: "50%" }}>
                                {getValues().massRestaurantFinal}
                            </div>
                            <div style={{ height: "50%" }}>
                                {t('components.restaurantWaste')}
                            </div>

                        </div>
                    </div>
                    <div style={{ width: "5%" }} />
                    <div className="client-dashboard-losses-div">
                        <div style={{ width: "50%", height: "80%", textAlign: "center" }}>
                            <div style={{ height: "50%" }}>
                                {getValues().costRestaurantFinal}
                            </div>
                            <div style={{ height: "50%" }}>
                                {t('components.restaurantWaste')}
                            </div>
                        </div>

                    </div>
                    <div style={{ width: "5%" }} />
                    <div className="client-dashboard-losses-div">
                        <div style={{ width: "50%", height: "80%", textAlign: "center" }}>
                            <div style={{ height: "50%" }}>
                                {getValues().co2RestaurantFinal}
                            </div>
                            <div style={{ height: "50%" }}>
                                {t('components.restaurantWaste')}
                            </div>
                        </div>

                    </div>
                </div>
                <div style={{ height: "5%" }} />
                <div style={{ width: "100%", height: "20%", flexDirection: "row", display: "flex" }}>
                    <div className="client-dashboard-losses-div">
                        <div style={{ width: "50%", height: "80%", textAlign: "center" }}>
                            <div style={{ height: "50%" }}>
                                {getValues().massKitchenFinal}
                            </div>
                            <div style={{ height: "50%" }}>
                                {t('components.kitchenWaste')}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "5%" }} />
                    <div className="client-dashboard-losses-div">
                        <div style={{ width: "50%", height: "80%", textAlign: "center" }}>
                            <div style={{ height: "50%" }}>
                                {getValues().costKitchenFinal}
                            </div>
                            <div style={{ height: "50%" }}>
                                {t('components.kitchenWaste')}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "5%" }} />
                    <div className="client-dashboard-losses-div">
                        <div style={{ width: "50%", height: "80%", textAlign: "center" }}>
                            <div style={{ height: "50%" }}>
                                {getValues().co2KitchenFinal}
                            </div>
                            <div style={{ height: "50%" }}>
                                {t('components.kitchenWaste')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomBlock>
    )
}
export default ClientWasteDetails;