import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import './Bars.css'
import { ReactComponent as Chief } from "../../assets/chief.svg";
import ArianneThread, { updateArianneThread } from "../arianneThread/ArianneThread";
import { hardResetAdmin } from "../../actions_reducers/actions/admin.action";
import { hardResetClient } from "../../actions_reducers/actions/client.action";
import { hardResetDashboard } from "../../actions_reducers/actions/dashboard.action";
import { hardResetDatas } from "../../actions_reducers/actions/datas.action";
import { hardResetFiles } from "../../actions_reducers/actions/files.action";
import { hardResetNavigation, setCurrentPage } from "../../actions_reducers/actions/navigation.action";
import { hardResetObjectives } from "../../actions_reducers/actions/objectives.action";
import { hardResetUser } from "../../actions_reducers/actions/user.action";
import { useTranslation } from "react-i18next";
import logo_kikleo from '../../assets/logo_kikleo.png';
import CustomBlock from "../customComponents/CustomBlock";

const TopBar = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dropdownRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const disconnect = () => {
        dispatch(hardResetAdmin())
        dispatch(hardResetClient())
        dispatch(hardResetDashboard())
        dispatch(hardResetDatas())
        dispatch(hardResetFiles())
        dispatch(hardResetNavigation())
        dispatch(hardResetObjectives())
        dispatch(hardResetUser())
    }

    const navigateToAccount = () => {
        setIsOpen(false)
        updateArianneThread('account', props.arianne, dispatch);
        dispatch(setCurrentPage('account'));
    }
    return (
        <div className="top-bar-main-div">

            <div style={{ width: "12%", height: "100%", backgroundColor: "#90c261", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img alt={"logo"} src={logo_kikleo} style={{ maxHeight: "50%" }} />
            </div>
            <ArianneThread
                arianne={props.navigationState.arianne}
                analyzesBaseDatas={props.analyzesBaseDatas}
                userState={props.userState}
                dashboardState={props.dashboardState} />
            <div style={{
                width: "50%", height: "100%", position: "absolute", top: 0, right: 0,
                display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "row"
            }}>
                <div style={{ width: "80%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", fontSize: "0.9vw", color: "#6D6C7A" }}>
                    <div style={{ width: "100%", height: "100%", display: props.userState.user_type !== "chef" ? "block" : "none", paddingBottom: "0.5%", paddingRight: "5%", alignItems: "center", fontSize: "1.5vw", color: "#54B0E3" }}>
                        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", paddingTop: "0.7%" }}>
                            {(props.userState.displayName || props.userState.selected_restaurant) &&
                                <CustomBlock
                                    width={"auto"}
                                    whiteSpace={"nowrap"}
                                    boxShadow={"none"}
                                    height={"70%"}
                                    color={"#fff"}
                                    fontSize={"1.1vw"}
                                    display={"flex"}
                                    justifyContent={"center"}
                                    paddingLeft={"7%"}
                                    paddingRight={"7%"}
                                    alignItems={"center"}
                                    backgroundColor={"#9ccebe"}
                                >
                                    {props.userState.displayName || props.userState.selected_restaurant}
                                </CustomBlock>}
                        </div>
                    </div>
                    {props.userState.login}
                </div>
                <div
                    ref={dropdownRef} style={{ width: "10%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", zIndex: 10000000000 }}>
                    <Chief style={{ cursor: "pointer", width: "100%", height: "100%" }} onClick={() => setIsOpen(!isOpen)} />

                    <div
                        style={{
                            display: isOpen ? "flex" : "none", flexDirection: "column",
                            justifyContent: "center", alignItems: "center",
                            width: "10vw", height: "10vh", position: "absolute", backgroundColor: "#68B4E3", top: "90%", right: "15%",
                            borderRadius: "5px", boxShadow: "0px 4px 4px 0px #00000040",
                            zIndex: 1000000000000000000000000000000
                        }}>
                        <div className="disconnect-button"
                            onClick={() => navigateToAccount()}>
                            {t('buttons.account')}
                        </div>
                        <div style={{ width: "80%", height: "1%", backgroundColor: "#fff" }} />
                        <div className="disconnect-button"
                            onClick={() => disconnect()}
                        >
                            {t('buttons.disconnect')}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{left: "42%", width: "50%", height: "100%", display:"flex",justifyContent:"flex-end",alignItems:"center",fontSize:"1.2vw", fontWeight: "bold", color:"#68B4E3"}}>
            </div> */}
        </div >
    )
}
function mapStateToProps(state) {
    return {
        userState: state.userReducer,
        navigationState: state.navigationReducer,
        analyzesBaseDatas: state.datasReducer.analyzesBaseDatas,
        dashboardState: state.dashboardReducer,
        arianne: state.navigationReducer.arianne
    };
}

export default connect(mapStateToProps)(TopBar);
