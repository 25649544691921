import React, { useState, useEffect, useRef } from 'react';
import { collection, doc, onSnapshot, addDoc, serverTimestamp, arrayUnion, getDocs, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase storage utilities
import { db, storage } from '../../../firebase'; // Import storage from firebase.js
import CustomScrollDiv from '../../customComponents/CustomScrollBar';
import { ReactComponent as ChefIconChat } from "../../../assets/ChefIconChat.svg";
import { ReactComponent as Cross } from "../../../assets/cross.svg";
import { ReactComponent as SendIcon } from "../../../assets/sendIcon.svg";
import { ReactComponent as PictureChat } from "../../../assets/pictureChat.svg";
import { ReactComponent as Clip } from "../../../assets/clip.svg";
import "./Chat.css";
import { useDispatch } from 'react-redux';
import { setIsChatOpen } from '../../../actions_reducers/actions/user.action';
import CustomBlock from '../../customComponents/CustomBlock';
import CustomButton from '../../customComponents/CustomButton';
import { setSelectedChatId } from '../../../actions_reducers/actions/admin.action';
import { useTranslation } from 'react-i18next';
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

function AdminChatConversation({ chatId, userId, userIdsEmails, adminId }) {
    const { t } = useTranslation()
    const textareaRef = useRef(null);
    const iconRef = useRef(null);
    const dispatch = useDispatch();
    const [file, setFile] = useState(null); // Add state for the image file

    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [imageFile, setImageFile] = useState(null); // Add state for the image file
    const [update, setUpdate] = useState(0);
    const [imageSrc, setImageSrc] = useState(null);

    const markMessagesAsRead = async (chatId, adminId) => {
        const chatRef = doc(db, 'chats', chatId);
        const messagesCollection = collection(chatRef, 'messages');

        const messagesSnapshot = await getDocs(messagesCollection);

        messagesSnapshot.forEach(async (messageDoc) => {
            const messageData = messageDoc.data();

            // If the message was not read by the current user
            if (!messageData.readBy.includes(adminId)) {
                // Add the user ID to the readBy array
                await updateDoc(messageDoc.ref, {
                    readBy: arrayUnion(adminId)
                });
            }
        });
    };
    useEffect(() => {
        const chatRef = doc(db, 'chats', chatId);
        const messagesCollection = collection(chatRef, 'messages');
        const unsubscribe = onSnapshot(messagesCollection, (snapshot) => {
            const messages = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            messages.sort((a, b) => a.timestamp && b.timestamp && a.timestamp.seconds - b.timestamp.seconds)
            setMessages(messages);
            markMessagesAsRead(chatId, adminId);
            setUpdate(old => old + 1)
        });

        return () => unsubscribe();
    }, [chatId]);

    const uploadFile = async (file) => {
        const fileRef = ref(storage, `chat_files/${Date.now()}_${file.name}`);
        const uploadResult = await uploadBytes(fileRef, file);
        const fileUrl = await getDownloadURL(uploadResult.ref);
        return fileUrl;
    };
    const handleInputChange = (e) => {
        const textarea = textareaRef.current;
        const newText = e.target.value;
        // Dynamically set height based on content
        textarea.style.height = "auto"; // Reset height to auto
        const newHeight = textarea.scrollHeight; // Get the new scroll height based on content

        // Set the height of the textarea to the new scroll height
        textarea.style.height = `${newHeight}px`;

        // Update the text state
        setNewMessage(newText);
    };
    // Function to handle sending a message
    const sendMessage = async () => {
        if (newMessage.trim() || imageFile || file) {
            let imageUrl = '';
            let fileUrl = '';

            const chatRef = doc(db, 'chats', chatId);

            // If a file is selected, upload it to Firebase Storage
            if (file) {
                fileUrl = await uploadFile(file);
                await addDoc(collection(chatRef, 'messages'), {
                    text: newMessage,
                    senderId: adminId,
                    fileUrl: fileUrl, // Save file URL to Firestore
                    fileName: file.name, // Save the file name
                    fileType: file.type, // Save the file type (e.g., 'application/pdf')
                    timestamp: serverTimestamp(),
                    readBy: [], // Initialize as an empty array
                });
                await updateDoc(chatRef, {
                    lastMessage: newMessage || 'File', // Update with the last text or a placeholder if it's a file
                    lastMessageTimestamp: serverTimestamp(), // Timestamp of the last message
                    lastMessageSenderId: adminId, // Update with the sender ID of the last message
                });
            }

            // If an image is selected, upload it to Firebase Storage
            if (imageFile) {
                const imageRef = ref(storage, `chat_images/${Date.now()}_${imageFile.name}`);
                const uploadResult = await uploadBytes(imageRef, imageFile);
                imageUrl = await getDownloadURL(uploadResult.ref);
                await addDoc(collection(chatRef, 'messages'), {
                    text: newMessage || "Image",
                    senderId: adminId,
                    imageUrl: imageUrl, // Save image URL to Firestore if an image was uploaded
                    timestamp: serverTimestamp(),
                    readBy: [], // Initialize as an empty array
                });
                await updateDoc(chatRef, {
                    lastMessage: newMessage || 'Image', // Update with the last text or a placeholder if it's a file
                    lastMessageTimestamp: serverTimestamp(), // Timestamp of the last message
                    lastMessageSenderId: adminId, // Update with the sender ID of the last message
                });
            }
            if (!imageFile && !file) {
                await addDoc(collection(chatRef, 'messages'), {
                    text: newMessage,
                    senderId: adminId,
                    timestamp: serverTimestamp(),
                    readBy: [], // Initialize as an empty array
                });
                await updateDoc(chatRef, {
                    lastMessage: newMessage, // Update with the last text or a placeholder if it's a file
                    lastMessageTimestamp: serverTimestamp(), // Timestamp of the last message
                    lastMessageSenderId: adminId, // Update with the sender ID of the last message
                });

            }
            const docSnapshot = await getDoc(chatRef);
            if (!docSnapshot.exists()) {
                // Document does not exist; create it with some initial data
                await setDoc(chatRef, {
                    createdAt: serverTimestamp(),
                    lastMessage: '',
                    lastMessageTimestamp: serverTimestamp(),
                    lastMessageSenderId: '',
                    status: "open",
                });
            }
            // Update the parent chat document with the latest message info
            await updateDoc(chatRef, {
                lastMessage: newMessage || (imageFile && 'Image') || (file && "File"), // Update with the last text or a placeholder if it's just an image
                lastMessageTimestamp: serverTimestamp(), // Timestamp of the last message
                lastMessageSenderId: adminId, // Update with the sender ID of the last message
                status: "open",
            });
            setUpdate(old => old + 1)
            setNewMessage(''); // Clear message input
            setImageFile(null); // Clear image input
            setImageSrc(null);
            setFile(null);
            let input = document.getElementById('chatInput');
            if (input)
                input.style.height = "auto"
        }
    };

    // Handle image file selection
    const handleImageChange = (e) => {
        setFile(null);
        if (e.target.files[0]) {
            setImageFile(e.target.files[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const handleChangeFile = (e) => {
        setImageSrc(null);
        setImageFile(null);
        const selectedFile = e.target.files[0]; // Get the selected file
        setFile(selectedFile); // Save the file to state
    }
    const removePicture = () => {
        setImageSrc(null);
        setImageFile(null);
    }
    const isMessageRead = (msg) => {
        if (msg.readBy && msg.readBy.length > 1)
            return (<div className={(userId === msg.senderId || isAdmin(msg.senderId)) ? 'subtitleBoxRight' : 'subtitleBoxLeft'}>{t('components.read')}</div>)
        else
            return <div className={(userId === msg.senderId || isAdmin(msg.senderId)) ? 'subtitleBoxRight' : 'subtitleBoxLeft'}>{t('components.unread')}</div>
    }
    const handleButtonCamClick = () => {
        document.getElementById('pictureInput').click(); // Trigger the hidden file input click
    };
    const handleButtonFileClick = () => {

        document.getElementById('fileInput').click(); // Trigger the hidden file input click
    }

    const handleButtonClick = () => {
        document.getElementById('fileInput').click(); // Trigger the hidden file input click
    };
    const isMoreThanOneHourDifference = (message1, message2) => {
        if (message1 && message2) {
            let timestamp1 = message1.timestamp
            let timestamp2 = message2.timestamp
            let diffInMilliseconds = 0;
            if (timestamp1 && timestamp2) {
                const date1 = new Date(timestamp1.seconds * 1000 + Math.floor(timestamp1.nanoseconds / 1000000));
                const date2 = new Date(timestamp2.seconds * 1000 + Math.floor(timestamp2.nanoseconds / 1000000));
                // Get the difference in milliseconds
                diffInMilliseconds = Math.abs(date1 - date2);
            }
            // Check if the difference is more than 1 hour (3600000 milliseconds)
            return diffInMilliseconds > 3600000;
        }
        else return false
    };
    const isLessThanOneHourDifference = (message1, message2) => {
        if (message1 && message2) {
            let timestamp1 = message1.timestamp
            let timestamp2 = message2.timestamp
            let diffInMilliseconds = 0;
            if (timestamp1 && timestamp2) {
                const date1 = new Date(timestamp1.seconds * 1000 + Math.floor(timestamp1.nanoseconds / 1000000));
                const date2 = new Date(timestamp2.seconds * 1000 + Math.floor(timestamp2.nanoseconds / 1000000));
                // Get the difference in milliseconds
                diffInMilliseconds = Math.abs(date1 - date2);
            }
            // Check if the difference is more than 1 hour (3600000 milliseconds)
            return diffInMilliseconds < 3600000;
        }
        else return false;
    };

    const mustShowRead = (prevMessage, currentMessage, nextMessage) => {
        if (currentMessage.senderId === userId) {
            if (currentMessage && nextMessage) {
                if ((currentMessage.senderId !== nextMessage.senderId))
                    return true;
                else
                    if (isLessThanOneHourDifference(currentMessage, nextMessage))
                        return false;
                    else return true;
            }
            else if (prevMessage && currentMessage) {
                if (prevMessage.senderId !== currentMessage.senderId)
                    return true;
                else {
                    if (isLessThanOneHourDifference(prevMessage, currentMessage) && nextMessage)
                        return false;
                    else return true;
                }

            }
            else if (currentMessage && !prevMessage && !nextMessage)
                return true;
        }
    }
    const removeFile = () => {
        setFile(null);
    }
    const isAdmin = (id) => {
        let email = userIdsEmails.find(a => a.id === id)
        if (email.email.includes("kikleo.admin.com")) return true
        else return false
    }
    const getType = (chatId) => {
        let email = userIdsEmails.find(a => a.id === chatId);
        console.log(email)
        console.log(email)
        if (email.associated_restaurants.length > 1) return email.clientName
        else return email.associated_restaurants[0]
    }
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <div style={{ height: "10%", width: "100%" }}>
                <CustomButton value={t('buttons.back')} width={"10%"} height={"65%"} color={"#fff"} backgroundColor={"#666666"} onClick={() => dispatch(setSelectedChatId(null))} />
            </div>
            <CustomBlock width={"100%"} height={"90%"}  >
                <div style={{ height: "12%", backgroundColor: "#EAEAEA", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", width: "100%", display: "flex" }}>
                    <div style={{ width: "7%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "6vh", height: "6vh", borderRadius: "50%", backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <ChefIconChat />
                        </div>
                    </div>
                    <div style={{ height: "100%", width: "93%", display: "flex", alignItems: "center", fontSize: "1.3vw", fontWeight: "bold" }}>
                        {userIdsEmails.find(a => a.id === chatId).email + " - " + getType(chatId)}
                    </div>
                </div>
                <div className="chatBoxAdmin">
                    <CustomScrollDiv update={update} scrollToBottom={true}>
                        {messages.map((msg, index) => {
                            return (<div key={msg.id} className="chatLine" style={{
                                marginTop: (index === 0 || isLessThanOneHourDifference(messages[index - 1], msg)) && "1vh",
                                marginBottom: (index === 0 || isLessThanOneHourDifference(msg, messages[index + 1])) && "1vh"
                            }}>
                                {(index === 0 || isMoreThanOneHourDifference(messages[index - 1], msg)) &&
                                    <div className='date-div'>
                                        {msg.timestamp && t('date', { value: { date: moment(msg.timestamp.toDate()), format: "DD/MM/YYYY HH:mm" } })}
                                    </div>
                                }
                                {!msg.imageUrl && !msg.fileUrl && (
                                    <div style={{ width: "100%", height: "100%", position: "relative", display: "flex", justifyContent: (userId === msg.senderId || isAdmin(msg.senderId)) ? "flex-end" : "flex-start" }}>
                                        <div className={(userId === msg.senderId || isAdmin(msg.senderId)) ? 'fromMeBubble' : 'fromOtherBubble'} >
                                            {msg.text}
                                        </div>
                                        {mustShowRead(messages[index - 1], msg, messages[index + 1]) && isMessageRead(msg)}
                                    </div>)
                                }
                                {msg.imageUrl && !msg.fileUrl && (
                                    <div style={{ width: "100%", height: "100%", position: "relative", display: "flex", justifyContent: (userId === msg.senderId || isAdmin(msg.senderId)) ? "flex-end" : "flex-start" }}>
                                        <div className={(userId === msg.senderId || isAdmin(msg.senderId)) === msg.senderId ? 'fromMeBubble' : 'fromOtherBubble'} >
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <img src={msg.imageUrl} alt="Chat Image" style={{ width: "18vw", marginBottom: msg.text && msg.text !== "Image" && "1vh" }} />
                                                {msg.text && msg.text !== "Image" && msg.text}
                                            </div>
                                        </div>
                                        {mustShowRead(messages[index - 1], msg, messages[index + 1]) && isMessageRead(msg)}
                                    </div>

                                )}
                                {
                                    msg.fileUrl && !msg.imageUrl && (
                                        <div style={{ width: "100%", height: "100%", position: "relative", display: "flex", justifyContent: (userId === msg.senderId || isAdmin(msg.senderId)) ? "flex-end" : "flex-start" }}>
                                            <div className={(userId === msg.senderId || isAdmin(msg.senderId)) ? 'fromMeBubble' : 'fromOtherBubble'} >
                                                <a href={msg.fileUrl} target="_blank" rel="noopener noreferrer">
                                                    {msg.fileName}
                                                </a>
                                                {msg.text && msg.text !== "File" && "\n" + msg.text}
                                            </div>
                                            {mustShowRead(messages[index - 1], msg, messages[index + 1]) && isMessageRead(msg)}
                                        </div>
                                    )
                                }
                            </div>
                            )
                        })}
                    </CustomScrollDiv>
                </div >
                <div className='chatWrite'>
                    {imageSrc &&
                        <div style={{ width: '95%', position: "absolute", bottom: "102%", backgroundColor: "#fff", padding: "0%", border: "1px solid #666", borderRadius: "5px" }}>
                            <div style={{ position: "relative", display: "inline-block", width: "100%", height: "100%", display: "flex", justifyContent: "center", paddingTop: "1%", paddingBottom: "1%" }}>
                                <img src={imageSrc} alt="Chat Image" style={{ height: 'auto', width: "15%" }} />
                                <Cross className='cross-icon' onClick={() => removePicture()} />
                            </div>
                        </div>
                    }
                    {file &&
                        <div style={{ width: '95%', height: "7vh", position: "absolute", bottom: "101%", backgroundColor: "#fff", padding: "0%", border: "1px solid #666", paddingLeft: "1%", borderRadius: "5px" }}>
                            <div style={{ position: "relative", display: "inline-block", width: "100%", height: "100%", display: "flex", alignItems: "center" }}>
                                {file.name}
                                <Cross className='cross-icon-file' style={{ top: "20%" }} onClick={() => removeFile()} />
                            </div>
                        </div>
                    }
                    <div style={{ height: "6vh", width: "100%", }}>
                        <div style={{ height: "auto", minHeight: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>

                            <textarea
                                ref={textareaRef}
                                type="text"
                                id='chatInput'
                                className='chatInput'
                                value={newMessage}
                                onChange={handleInputChange}
                                rows={1}
                                style={{
                                    position: "absolute",
                                    bottom: `0px`, // Adjust textarea to grow upwards
                                    resize: "none", // Disable manual resizing
                                    height: "auto",
                                    maxHeight: "20vh"
                                }}
                                placeholder={t('components.enterMessage')}
                            />
                            {/*<button className="sendButton" onClick={sendMessage}>{t('buttons.send')}</button>*/}
                            <SendIcon
                                ref={iconRef} style={{
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    fontSize: '24px',
                                    color: '#555',
                                    transition: 'top 0.2s, left 0.2s',
                                    right: "5%"
                                }} onClick={sendMessage} />
                        </div>
                    </div>
                    <div style={{ height: "4vh", width: "100%" }}>

                        <input style={{ display: "none" }} id="pictureInput" accept="image/*" type="file" onChange={handleImageChange} /> {/* File input for image */}
                        <PictureChat className='camera-icon' style={{ left: "1%" }}
                            onClick={handleButtonCamClick}
                        />
                        <input style={{ display: "none" }} id="fileInput" type="file" accept=".pptx,.pdf,.docx,.xlsx,.xls,.csv,.doc" onChange={handleChangeFile} /> {/* File input for image */}
                        <Clip className='file-icon'
                            style={{ left: "6%" }}
                            onClick={handleButtonFileClick}
                        />
                    </div>

                </div>
            </CustomBlock >

        </div>
    );
}

export default AdminChatConversation;
