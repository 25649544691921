import React, { useEffect } from "react";
import { useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { ReactComponent as Warning } from "../../../../assets/warning.svg";
import { ReactComponent as Pen } from "../../../../assets/operationPen.svg";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import CustomButton from "../../../customComponents/CustomButton";
import CustomTextField from "../../../customComponents/CustomTextField";
import { setOperations, setSelectedOperation } from "../../../../actions_reducers/actions/operations.action";
import { getDetailsPlanningForDay, getDetailsWeighInsForDay, updateDailyPlanning } from "../../../../apiRoutes/dailyPlanning.routes";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const OperationsKitchenDetails = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        updateWeighIns()
    }, [])
    const updateWeighIns = async () => {
        let operations = JSON.parse(JSON.stringify(props.state.operations))
        let currentOperations = operations.filter(a => moment(a.date).format("YYYY-MM-DD") === props.state.selectedDate);
        let newValues = await getDetailsWeighInsForDay(currentOperations);
        for (const value of newValues) {
            let index = operations.findIndex(a => a.id === value.id);
            operations[index] = value;
        }
        dispatch(setOperations(operations))
    }
    const updateSelectedOperationCommentary = (value) => {
        let selectedOperation = JSON.parse(JSON.stringify(props.state.selectedOperation))
        selectedOperation.commentary = value;
        dispatch(setSelectedOperation(selectedOperation))
    }
    const confirmChanges = async () => {
        let operations = JSON.parse(JSON.stringify(props.state.operations));
        let index = operations.findIndex(a => a._id === props.state.selectedOperation._id);
        operations[index] = props.state.selectedOperation;
        dispatch(setOperations(operations));
        await updateDailyPlanning(props.state.selectedOperation)
        dispatch(setSelectedOperation(null));
    }
    const getModalModifyOperation = () => {
        if (props.state.selectedOperation) {
            return (
                <CustomBlock
                    position={"absolute"}
                    width={"40%"}
                    height={"35%"}
                    left={"27%"}
                    top={"15%"}
                    zIndex={10}
                >
                    <div style={{ height: "15%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {props.state.selectedOperation.restaurant_name}
                    </div>
                    <div style={{ height: "65%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "40%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('table.commentary')}
                        </div>
                        <div style={{ width: "60%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomTextField filter={"none"}
                                width={"90%"}
                                height={"30%"}
                                backgroundColor={"#D9D9D9"}
                                borderRadius={"10px"}
                                onChange={(e) => updateSelectedOperationCommentary(e.target.value)}
                                value={props.state.selectedOperation.commentary} />
                        </div>
                    </div>
                    <div style={{ height: "20%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton width={"60%"} height={"70%"} backgroundColor={"#949494"} color={"#fff"} value={t('buttons.cancel')} onClick={() => dispatch(setSelectedOperation(null))} />
                        </div>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton width={"60%"} height={"70%"} backgroundColor={"#90c261"} color={"#fff"} value={t('buttons.confirm')} onClick={() => confirmChanges()} />
                        </div>
                    </div>
                </CustomBlock>
            )
        }
    }

    const unShowedRestaurants = [
        "CREIL",
        "DOUMERC",
        "FOCH",
        "LAGOUBRAN",
        "RA54"
    ]
    const getOperationsRows = () => {
        let result = [];
        let currentOperations = JSON.parse(JSON.stringify(props.state.operations)).filter(a => moment(a.date).format("YYYY-MM-DD") === props.state.selectedDate);

        if (currentOperations && currentOperations.length > 0) {
            let restaurants = JSON.parse(JSON.stringify(props.informations)).filter(a => a.clientName === props.state.selectedClient)
            currentOperations = currentOperations.filter(a => !unShowedRestaurants.find(b => b === a.restaurant_name))
            if (props.state.selectedClient !== null)
                currentOperations = currentOperations.filter(a => restaurants.find(b => b.restaurantName === a.restaurant_name))
            currentOperations.sort((a, b) => a.restaurant_name.localeCompare(b.restaurant_name));
            let i = 0;
            for (const [index, operation] of currentOperations.entries()) {
                let currentRestaurant = props.informations.find(a => a.restaurantName === operation.restaurant_name)
                if (currentRestaurant.hasKitchen) {
                    result.push(
                        <div style={{ width: "100%", height: "15%", display: "flex", flexDirection: "row", color: "#666666", backgroundColor: i % 2 === 0 && "#f0f0f0" }}>
                            <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {operation.restaurant_name}
                            </div>
                            <div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {operation.numberSamplesBin}
                            </div>
                            <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                                onClick={() => dispatch(setSelectedOperation(operation))}>
                                {operation.commentary ?
                                    operation.commentary :
                                    <Pen width={"50%"} height={"50%"} />}
                            </div>
                        </div>
                    )
                    i += 1
                }
            }
        }
        return result;
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}
            paddingTop={"1%"}
            paddingBottom={"1%"}>
            {getModalModifyOperation()}
            <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "row", color: "#666666", fontWeight: "bold", fontSize: "1.1vw" }}>
                <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {t('table.restaurant')}
                </div>
                <div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {t('table.numberWeighIns')}
                </div>
                <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                    {t('table.opsState')}
                </div>
            </div>
            <div style={{ height: "90%" }}>
                <CustomScrollDiv update={Math.random()}>
                    {getOperationsRows()}
                </CustomScrollDiv>

            </div>
        </CustomBlock>
    )
}

export default OperationsKitchenDetails;
