const initialState = {
    templates: [],
    selectedTemplate: null,
    selectedRestaurantMail: null,
    templateHtml: null,
    mailVariables: null,
    selectedTemplateId: null,
    selectedVersionId: null,
    to: [],
    cc: ["support@kikleo.com"],
    subject: null
}


const mailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TEMPLATES':
            return {
                ...state,
                templates: action.templates
            }
        case 'SET_SELECTED_TEMPLATE':
            return {
                ...state,
                selectedTemplate: action.selectedTemplate
            }
        case 'SET_SELECTED_RESTAURANT_MAIL':
            return {
                ...state,
                selectedRestaurantMail: action.selectedRestaurantMail
            }
        case 'SET_TEMPLATE_HTML':
            return {
                ...state,
                templateHtml: action.templateHtml
            }
        case 'SET_MAIL_VARIABLES':
            return {
                ...state,
                mailVariables: action.mailVariables
            }
        case 'SET_SELECTED_TEMPLATE_ID':
            return {
                ...state,
                selectedTemplateId: action.selectedTemplateId
            }
        case 'SET_SELECTED_VERSION_ID':
            return {
                ...state,
                selectedVersionId: action.selectedVersionId
            }
        case 'SET_TO':
            return {
                ...state,
                to: action.to
            }
        case 'SET_CC':
            return {
                ...state,
                cc: action.cc
            }
        case 'SET_SUBJECT':
            return {
                ...state,
                subject: action.subject
            }
        case 'SOFT_RESET_MAILS':
            return {
                ...state,
                templateHtml: null,
                mailVariables: null,
                selectedTemplateId: null,
                selectedVersionId: null,
                selectedRestaurantMail: null,
                selectedTemplate: null,
                to: [],
                cc: ["support@kikleo.com"]
            }
        default:
            return state;
    }
}

export default mailsReducer;