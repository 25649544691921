import React, { useState, useEffect } from 'react';
import { collection, doc, onSnapshot, addDoc, serverTimestamp, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase storage utilities
import { db, storage } from '../../../firebase'; // Import storage from firebase.js
import CustomScrollDiv from '../../customComponents/CustomScrollBar';
import "./Chat.css";
import AdminChatList from './AdminChatList';
import { connect, useDispatch } from 'react-redux';
import AdminChatConversation from './AdminChatConversation';
import { useTranslation } from 'react-i18next';
import { setSelectedAdminChatType } from '../../../actions_reducers/actions/admin.action';

const AdminChat = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [conversations, setConversations] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({});

  const listenForUnreadMessages = (chatId, userId, callback) => {
    const chatRef = doc(db, 'chats', chatId);
    const messagesCollection = collection(chatRef, 'messages');
    // Set up the real-time listener for unread messages
    const unsubscribe = onSnapshot(messagesCollection, (snapshot) => {
      const unreadMessages = snapshot.docs.filter((doc) => {
        const messageData = doc.data();
        return !messageData.readBy.includes(userId); // Check if user ID is not in readBy array
      });

      // Pass the unread count to the callback function
      callback(unreadMessages.length);
    });

    // Return the unsubscribe function
    return unsubscribe;
  };
  useEffect(() => {
    const unsubscribeList = [];
    // Loop through each conversation
    for (const conversation of conversations) {
      const chatId = conversation.id;
      // Set up real-time listener for each conversation
      const unsubscribe = listenForUnreadMessages(chatId, props.userState.userId, (unreadCount) => {
        if (props.userIdsEmails.find(a => a.id === chatId && conversation.status === "open")) {
          // Update the unread count for this conversation
          setUnreadCounts((prevCounts) => ({
            ...prevCounts,
            [chatId]: unreadCount,
          }));
        }
      });
      unsubscribeList.push(unsubscribe);
      // Store the unsubscribe function for cleanup
    }

    // Clean up all listeners when the component unmounts
    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe());
    };
  }, [conversations.length]);
  useEffect(() => {
    const chatsCollection = collection(db, 'chats');

    // Set up a real-time listener using `onSnapshot`
    const unsubscribe = onSnapshot(chatsCollection, (snapshot) => {
      const conversationsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setConversations(conversationsList);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  const getBackgroundColor = (type) => {
    if (type === props.selected_type)
      return "#fff";
    else
      return "#68b4e3";
  }
  const getColor = (type) => {
    if (type !== props.selected_type)
      return "#fff"
  }

  const getBoxShadow = () => {
    return '0px 0px 3px 0px #00000040';
  }
  const getUnreadCounts = () => {
    let length = 0;
    Object.keys(unreadCounts).map(key => {
      if (unreadCounts[key] > 0) length += 1
    })
    return length
  }
  return (
    <div className="main-page-main-div" style={{ height: "85.8vh" }}>
      {props.selectedChatId == null ?
        <div style={{ width: "100%", height: "100%" }}>
          <div style={{ height: "13%", display: "flex", width: "100%", marginBottom: "-1%" }}>

            <div style={{
              fontSize: "1.3vw", color: getColor('open'), width: "50%", height: "100%", backgroundColor: getBackgroundColor('open'),
              cursor: "pointer",
              display: "flex", justifyContent: "center", alignItems: "center",
              borderTopLeftRadius: 15, borderTopRightRadius: 15, boxShadow: getBoxShadow('open'),
              paddingBottom: ".75%",
              position: "relative",
              fontWeight: 600
            }}
              onClick={() => dispatch(setSelectedAdminChatType('open'))}>
              {getUnreadCounts() > 0 &&
                <div className='unreadMessagesDiv' style={{
                  backgroundColor: props.selected_type === "open" ? "#666666" : "#fff",
                  color: props.selected_type === "open" ? "#fff" : "#68B4E3"
                }}>
                  {getUnreadCounts()}
                </div>}
              {t('table.activeConv')}
            </div>
            <div style={{
              fontSize: "1.3vw", color: getColor('closed'), width: "50%", height: "100%", backgroundColor: getBackgroundColor('closed'), cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center",
              borderTopLeftRadius: 15, borderTopRightRadius: 15, boxShadow: getBoxShadow('closed'),
              paddingBottom: ".75%",
              fontWeight: 600
            }}
              onClick={() => dispatch(setSelectedAdminChatType('closed'))}>
              {t('table.closedConv')}

            </div>
          </div>
          <div style={{ height: "88%" }}>
            <AdminChatList userIdsEmails={props.userIdsEmails} unreadCounts={unreadCounts} conversations={conversations} selected_type={props.selected_type} loggedInChat={props.loggedInChat} />
          </div>

        </div> :
        <div style={{ width: "100%", height: "100%" }}>
          <AdminChatConversation userIdsEmails={props.userIdsEmails} chatId={props.selectedChatId} userId={"Support"} adminId={props.userState.userId} />
        </div>
      }
    </div >
  );
}
function mapStateToProps(state) {
  return {
    userState: state.userReducer,
    selectedChatId: state.adminReducer.selectedChatId,
    selected_type: state.adminReducer.selectedAdminChatType,
    loggedInChat: state.adminReducer.loggedInChat,
    userIdsEmails: state.adminReducer.userIdsEmails
  };
}

export default connect(mapStateToProps)(AdminChat);
