
import 'moment/locale/fr';
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const getTotalNewTrays = (traysForDay, hour, cam, dayTrays) => {
    let total = 0;
    if (traysForDay) {
        if (traysForDay.traysCam && traysForDay.traysCam[0]) {
            let camValue = traysForDay.traysCam.find(a => a.nameCam ? a.nameCam === cam : true);
            if (camValue) {
                let hourValue = camValue.trays.find(a => a.hour === hour);
                if (hourValue && hourValue.nbTrays && hourValue.nbTrays !== '' && parseInt(hourValue.nbTrays) !== 0) total = parseInt(hourValue.nbTrays);
                else {
                    total = traysForDay.nbTrays > 10 ? traysForDay.nbTrays : dayTrays;
                }
            }
            else {
                total = traysForDay.nbTrays > 10 ? traysForDay.nbTrays : dayTrays
            }
        }
        else {
            total = traysForDay.nbTrays > 10 ? traysForDay.nbTrays : dayTrays;
        }
    }
    else {
        total = dayTrays;
    }
    return parseInt(total);
}

const addNewValue = (value, mass, cost, co2, inevMass, inevCost, inevCo2) => {
    value.mass = parseFloat(value.mass) + parseFloat(mass);
    value.cost = parseFloat(value.cost) + parseFloat(cost);
    value.co2 = parseFloat(value.co2) + parseFloat(co2);
    value.inevitableMass = parseFloat(value.inevitableMass) + parseFloat(inevMass);
    value.inevitableCost = parseFloat(value.inevitableCost) + parseFloat(inevCost);
    value.inevitableCo2 = parseFloat(value.inevitableCo2) + parseFloat(inevCo2);
    return value;
}

export const updateDataForTrays = (datas, newTrays, multipleCams, camsNames, restaurant_name) => {
    let datasToChange = JSON.parse(JSON.stringify(datas));
    for (let data of datasToChange) {
        let hour = data.hour;
        let numberCam = data.camValue.split(restaurant_name.toLowerCase())[1];
        let camValue = "Total";
        if (multipleCams) {
            let camValueTmp = camsNames.find(a => parseInt(a.numberCam) === parseInt(numberCam))
            if (camValueTmp) camValue = camValueTmp.nameCam;
        }
        let newTraysForData = 0;
        for (let day of data.days) {
            let traysForDay = newTrays.find(a => moment(a.date).format("YYYY-MM-DD") === moment(day.date).format("YYYY-MM-DD"));
            let newTraysDay = getTotalNewTrays(traysForDay, hour, camValue, day.trays);
            newTraysForData = parseInt(newTraysForData) + parseInt(newTraysDay);
            let multiplicator = (newTraysDay - day.trays) / (day.trays !== 0 ? day.trays : 1);
            for (let category of day.categories) {
                for (let food of category.foods) {
                    let newMass = food.mass * multiplicator;
                    let newCost = food.cost * multiplicator;
                    let newCo2 = food.co2 * multiplicator;
                    let newInevMass = food.inevitableMass * multiplicator;
                    let newInevCost = food.inevitableCost * multiplicator;
                    let newInevCo2 = food.inevitableCo2 * multiplicator;
                    let newTraysFood = food.trays * multiplicator;
                    day = addNewValue(day, newMass, newCost, newCo2, newInevMass, newInevCost, newInevCo2);
                    data = addNewValue(data, newMass, newCost, newCo2, newInevMass, newInevCost, newInevCo2);

                    food.trays = parseInt(food.trays) + parseInt(newTraysFood);
                    category.trays = parseInt(category.trays) + parseInt(newTraysFood);

                    food = addNewValue(food, newMass, newCost, newCo2, newInevMass, newInevCost, newInevCo2)
                    category = addNewValue(category, newMass, newCost, newCo2, newInevMass, newInevCost, newInevCo2)
                }
            }
            day.trays = newTraysDay;
        }
        data.trays = newTraysForData;
    }

    return datasToChange;
}