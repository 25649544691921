const initialState = {
    dates: [],
    alerts: [],
    modifiedAlerts: [],
    selectedAnalysis: null,
    selectedDate: null,
    selectedAlert: null,
    alertDone: false,
    uncheckedDates: []
}


const alertsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ALL_DATES_ALERTS':
            return {
                ...state,
                dates: action.dates
            }
        case 'SET_ALERTS':
            return {
                ...state,
                alerts: action.alerts
            }
        case 'SET_SELECTED_ANALYSIS_ALERTS':
            return {
                ...state,
                selectedAnalysis: action.selectedAnalysis
            }
        case 'SET_SELECTED_DATE_ALERTS':
            return {
                ...state,
                selectedDate: action.selectedDate
            }
        case 'SET_SELECTED_ALERT': {
            return {
                ...state,
                selectedAlert: action.selectedAlert
            }
        }
        case 'SET_MODIFIED_ALERTS': {
            return {
                ...state,
                modifiedAlerts: action.modifiedAlerts
            }
        }
        case 'SET_ALERT_DONE': {
            return {
                ...state,
                alertDone: action.alertDone
            }
        }
        case 'SET_UNCHECKED_DATES': {
            return {
                ...state,
                uncheckedDates: action.uncheckedDates
            }
        }
        case 'RESET_ALERTS': {
            return initialState
        }
        default:
            return state;
    }
}

export default alertsReducer;