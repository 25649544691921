import { getCategoryColor } from '../../customComponents/CustomColors';

export const getCommodityCost = (mass, aliment_id, foodList) => {
    if (foodList && aliment_id) {
        let foodValues = foodList.find(a => a.idFood === aliment_id);
        return parseFloat(mass / 1000 * (parseFloat(foodValues.costfood)));
    }
    else return 0;
}

const formatNumber = (num) => {
    if (num)
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    else return num
};

const addCatToValues = (analysis, cats, type, food_list, costType) => {
    if (costType !== "commodity_cost" || type === "mass") {
        for (const day of analysis.days) {
            for (const cat of day.categories) {
                if (cat.category !== "Déchets inévitables") {
                    let catIndex = cats.findIndex(a => a.name === cat.category);
                    let value = type === "mass" ? cat.mass : cat.cost
                    if (catIndex === -1) {
                        let newCat = {
                            name: cat.category,
                            value: value
                        }
                        cats.push(newCat)
                    }
                    else {
                        cats[catIndex].value = parseFloat(cats[catIndex].value) + parseFloat(value)
                    }
                }
            }
        }
        return cats;
    } else {

        for (const day of analysis.days) {
            for (const cat of day.categories) {
                if (cat.category !== "Déchets inévitables") {
                    let catCost = 0;
                    for (const food of cat.foods) {
                        catCost = parseFloat(catCost) + parseFloat(getCommodityCost(food.mass, food.aliment_id, food_list))
                    }
                    let catIndex = cats.findIndex(a => a.name === cat.category);
                    if (catIndex === -1) {
                        let newCat = {
                            name: cat.category,
                            value: catCost
                        }
                        cats.push(newCat)
                    }
                    else {
                        cats[catIndex].value = parseFloat(cats[catIndex].value) + parseFloat(catCost)
                    }
                }
            }
        }
        return cats;
    }
}

export const getCatValues = (analysis, kitchenAnalysis, type, food_list, food_list_kitchen, costType) => {
    let cats = [];
    if (analysis) {
        cats = addCatToValues(analysis, cats, type, food_list, costType);
    }
    if (kitchenAnalysis) {
        cats = addCatToValues(kitchenAnalysis, cats, type, food_list_kitchen, costType);
    }
    cats.sort((a, b) => b.value - a.value);
    return cats;
}


const getBarHeight = (maxValue, currentValue) => {
    return currentValue / maxValue * 45
}
const getBarMargin = (index) => {
    return (1.5 / 38.87) * 100 + index * (1.85 / 38.87) * 100 + "%"
}

export const getImagesValues = (t, cat, index) => {
    let path = null;
    let imageMarginLeft = (1.45 / 38.87) * 100 + index * (1.85 / 38.87) * 100 + "%";
    let imageMarginTop = '';
    let imageWidth = '3.4%';
    let imageHeight = '';

    if (t(`foods.${cat}`) === t('foods.Légumes')) {
        path = require('./assets/categories/vegetables.png');
        imageMarginTop = "87%";
        imageHeight = "7.5%";
    }
    else if (t(`foods.${cat}`) === t('foods.Autres')) {
        path = require('./assets/categories/others.png');
        imageMarginTop = "86%";
        imageHeight = "7.8%";
    }
    else if (t(`foods.${cat}`) === t('foods.Crudités')) {
        path = require('./assets/categories/raw_vegetables.png');
        imageMarginTop = "87.5%";
        imageHeight = "6.5%";
    }
    else if (t(`foods.${cat}`) === t('foods.Féculents')) {
        path = require('./assets/categories/starchies.png');
        imageMarginTop = "87%";
        imageHeight = "7.3%";
    }
    else if (t(`foods.${cat}`) === t('foods.Plats composés')) {
        path = require('./assets/categories/mixed_dishes.png');
        imageMarginTop = "87%";
        imageHeight = "6.5%";
    }
    else if (t(`foods.${cat}`) === t('foods.Produits de la mer')) {
        path = require('./assets/categories/fish.png');
        imageMarginTop = "86%";
        imageHeight = "7.2%";
    }
    else if (t(`foods.${cat}`) === t('foods.Poisson/fruits de mer')) {
        path = require('./assets/categories/fish.png');
        imageMarginTop = "86%";
        imageHeight = "7.2%";
    }
    else if (t(`foods.${cat}`) === t('foods.Viandes')) {
        path = require('./assets/categories/meat.png');
        imageMarginTop = "87%";
        imageHeight = "7%";
    }
    else if (t(`foods.${cat}`) === t('foods.Pain')) {
        path = require('./assets/categories/bread.png');
        imageMarginTop = "87.5%";
        imageHeight = "5.5%";
    }
    else if (t(`foods.${cat}`) === t('foods.Dessert')) {
        path = require('./assets/categories/dessert.png');
        imageMarginTop = "87%";
        imageHeight = "5.5%";
    }
    else if (t(`foods.${cat}`) === t('foods.Fruit')) {
        path = require('./assets/categories/fruit.png');
        imageMarginTop = "87.2%";
        imageHeight = "5.5%";
    }
    else if (t(`foods.${cat}`) === t('foods.Fromage')) {
        path = require('./assets/categories/cheese.png');
        imageMarginTop = "88%";
        imageHeight = "5%";
    }
    else if (t(`foods.${cat}`) === t('foods.Oeufs')) {
        path = require('./assets/categories/egg.png');
        imageMarginTop = "87%";
        imageHeight = "5.5%";
    }
    else if (t(`foods.${cat}`) === t('foods.Oeuf')) {
        path = require('./assets/categories/egg.png');
        imageMarginTop = "87%";
        imageHeight = "5.5%";
    }

    return {
        path: path,
        imageMarginLeft: imageMarginLeft,
        imageMarginTop: imageMarginTop,
        imageWidth: imageWidth,
        imageHeight: imageHeight
    }

}
const getBarMarginText = (index) => {
    return (1 / 38.87) * 100 + index * (1.855 / 38.87) * 100 + "%"
}
const getBarMarginTopText = (index) => {
    return (1.1 / 38.87) * 100 + index * (1.85 / 38.87) * 100 + "%"
}
const getBarMarginTop = (maxValue, currentValue) => {
    return 85 - getBarHeight(maxValue, currentValue)
}
export const addCatsBars = (pres, slide, t, cats, type) => {
    let max = 0;
    for (const cat of cats) {
        let newValue = type === "mass" ? (parseFloat(cat.value) / 1000).toFixed(0) : cat.value.toFixed(0);

        if (parseInt(newValue) > max) max = newValue
    }
    let indexCat = 0;
    for (var i = 0; i < cats.length; i++) {
        let imageValues = getImagesValues(t, cats[i].name, i)
        let value = type === "mass" ? (parseFloat(cats[i].value) / 1000).toFixed(0) : cats[i].value.toFixed(0);
        if (value >= 1 && imageValues.path != null) {
            slide.addShape(pres.shapes.ROUNDED_RECTANGLE,
                {
                    x: getBarMargin(indexCat),
                    y: getBarMarginTop(max, value) + "%",
                    w: "3%",
                    h: getBarHeight(max, value) + "%",
                    fill: getCategoryColor(cats[i].name) || "#666666",
                    rectRadius: 0.1,
                    shadow: {
                        blur: 5,
                        offset: 0.5,
                        color: "000000",
                        opacity: .25
                    }
                });

            slide.addText(t(`foods.${cats[i].name}`), {
                x: getBarMarginText(indexCat),
                y: "93.5%",
                w: "5.5%",
                color: "666666",
                align: "center",
                fontFace: "Muli",
                lineBreak: true,
                fontSize: 8.5,
                h: (1.11 / 19.05) * 100 + "%",
            })
            slide.addImage({
                path: imageValues.path,
                x: imageValues.imageMarginLeft,
                y: imageValues.imageMarginTop,
                w: imageValues.imageWidth,
                h: imageValues.imageHeight,
                sizing: { type: "contain" }
            })
            slide.addText(
                type === "mass" ?
                    t('mass', { value: { mass: value * 1000, unit: "kg", fixed: 0 } }) :
                    t('currency', { value: { cost: value, fixed: 0 } })
                , {
                    x: getBarMarginTopText(indexCat),
                    y: getBarMarginTop(max, value) - 4 + "%",
                    w: "5%",
                    h: "4%",
                    color: getCategoryColor(cats[i].name),
                    align: "center",
                    fontFace: "Muli",
                    fontSize: 11,
                })
        }
        if (cats[i].name !== "Déchets inévitables") indexCat += 1

    }
}

export const getCo2Eq = (co2) => {
    let type = "";
    let travel = "";
    let number = 0;
    let co2Kg = parseFloat(co2) / 1000;

    if (co2Kg < 21) {
        travel = "Annecy - Genève";
        number = parseFloat((Math.round(co2Kg / 10 * 2) / 2).toFixed(1))
        type = number >= 2 ? "A.R." : "aller";
    }
    else if (co2Kg < 45) {
        travel = "Marseille - Avignon";
        number = parseFloat((Math.round(co2Kg / 22 * 2) / 2).toFixed(1))
        type = number >= 2 ? "A.R." : "aller";
    }
    else if (co2Kg < 81) {
        travel = "Nantes - Le Mans";
        number = parseFloat((Math.round(co2Kg / 40 * 2) / 2).toFixed(1))
        type = number >= 2 ? "A.R." : "aller";
    }
    else if (co2Kg < 171) {
        travel = "Strasbourg - Genève";
        number = parseFloat((Math.round(co2Kg / 85 * 2) / 2).toFixed(1))
        type = number >= 2 ? "A.R." : "aller";
    }
    else {
        travel = "Paris - Marseille";
        number = parseFloat((Math.round(co2Kg / 170 * 2) / 2).toFixed(1))
        type = number >= 2 ? "A.R." : "aller";
    }
    if (number >= 2) number = parseFloat(Math.round(number / 2).toFixed(1))

    return {
        travel: travel,
        number: number,
        type: type
    }
}

export const getTravelValues = (co2) => {

    let car = getCo2Eq(co2);
    let co2Kg = co2 / 1000;
    let trainNumber = parseFloat((Math.round(co2Kg / 2 * 2) / 2).toFixed(1));
    let trainType = parseFloat(trainNumber) >= 2 ? "A.R." : "aller"
    let trainTravel = "Paris - Milan";

    let carNumber = car.number;
    let carType = car.type;
    let carTravel = car.travel;

    let planeNumber = parseFloat((Math.round(co2Kg / 886 * 2) / 2).toFixed(1))
    let planeType = parseFloat(planeNumber) >= 2 ? "A.R." : "aller";
    let planeTravel = "Paris - New-York";
    if (planeNumber >= 2)
        planeNumber = parseFloat(Math.round(planeNumber / 2).toFixed(1))
    if (trainNumber >= 2)
        trainNumber = parseFloat(Math.round(trainNumber / 2).toFixed(1))

    return {
        trainNumber: formatNumber(trainNumber),
        trainType: trainType,
        trainTravel: trainTravel,
        carNumber: carNumber,
        carType: carType,
        carTravel: carTravel,
        planeNumber: planeNumber,
        planeType: planeType,
        planeTravel: planeTravel
    }
}