import { hasMultipleCams, hasMultipleHours } from "./user.function";

export const getTraysValueForCamAndHour = (array, userState, type) => {
    let value = 0;
    const multipleCams = hasMultipleCams(userState);
    const multipleHours = hasMultipleHours(userState);
    for (const values of array) {
        let hasCamTotal = userState.selected_cams.find(a => a.camName === 'total');
        let hasCam = userState.selected_cams.find(a => a.camName === values.camName);
        let hasHourTotal = userState.selected_hours.find(a => a === 'total');
        let hasHour = userState.selected_hours.find(a => a === values.hour);
        if (multipleCams && multipleHours) {
            if (hasCamTotal && hasHourTotal)
                value = parseInt(value) + parseInt(values[`${type}`]);
            else if (!hasCamTotal && hasHourTotal) {
                if (hasCam) value = parseInt(value) + parseInt(values[`${type}`]);
            }
            else if (hasCamTotal && !hasHourTotal) {
                if (hasHour) value = parseInt(value) + parseInt(values[`${type}`]);
            }
            else { 
                if (hasHour && hasCam) value = parseInt(value) + parseInt(values[`${type}`]);
            }
        }
        else if (multipleCams && !multipleHours) {
            if (hasCamTotal) {
                value = parseInt(value) + parseInt(values[`${type}`]);
            }
            else {
                if (hasCam)
                    value = parseInt(value) + parseInt(values[`${type}`]);
            }
        }
        else if (!multipleCams && multipleHours) {
            if (hasHourTotal) {
                value = parseInt(value) + parseInt(values[`${type}`]);
            }
            else {
                if (hasHour)
                    value = parseInt(value) + parseInt(values[`${type}`]);
            }
        }
        else value = values[`${type}`];

    }
    return value;
}