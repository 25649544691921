import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/food`;

export function getFoodsOfRestaurant(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/foods", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getFoodsOfClient(clientName) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/foodsClient", {
                params: { clientName: clientName },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function updateFoodRestaurant(food) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateFoodRestaurant", {
                params: { food: food },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}
export function createFoodsForRestaurant(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/createFoods", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}