import React, { useEffect } from "react";
import { useDispatch } from "react-redux"; // REDUX
import CustomBlock from "../../customComponents/CustomBlock";
import { fr } from 'date-fns/esm/locale'
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import isSameMonth from "date-fns/isSameMonth";
import isSameDay from "date-fns/isSameDay";
import toDate from "date-fns/toDate";
import format from "date-fns/format";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import { useState } from "react";
import 'moment/locale/fr';
import { setLastSelectedDatePictures, setSelectedDatePictures, setSelectedMonthPictures } from "../../../actions_reducers/actions/pictures.action";
import { ReactComponent as Camera } from "../../../assets/camera.svg";
import { useTranslation } from "react-i18next";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);


const PicturesCalendar = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate,] = useState(new Date());
    useEffect(() => {
        if (props.lastSelectedDate) setCurrentDate(new Date(props.lastSelectedDate));
        else {
            if (props.dates.length > 0)
                setCurrentDate(new Date(props.dates[props.dates.length - 1].date))
            else setCurrentDate(new Date());
        }
    }, [props.dates.length])
    const header = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="column col-start">
                    <div className="icon" onClick={prevMonth}>
                        chevron_left
                    </div>
                </div>
                <div className="column col-center">
                    <span>{moment(currentDate).format(dateFormat)}</span>
                </div>
                <div className="column col-end">
                    <div className="icon" onClick={nextMonth}>
                        chevron_right
                    </div>
                </div>
            </div>
        );
    };
    const nextMonth = () => {
        setCurrentDate(addMonths(currentDate, 1));
    };
    const prevMonth = () => {
        setCurrentDate(subMonths(currentDate, 1));
    };

    const daysOfWeek = () => {
        const dateFormat = "E";
        const days = [];
        const monthStart = startOfMonth(currentDate);
        let startDate = startOfWeek(monthStart);
        for (let i = 1; i < 8; i++) {
            days.push(
                <div className="column col-center" key={i}>
                    {format(addDays(startDate, i), dateFormat, { fr })}
                </div>
            );
        }
        return <div className="days row">{days}</div>;
    };

    const getPicturesDate = (date) => {
        let currentDate = moment(date).format("YYYY-MM-DD");
        let dateTmp = props.dates.find(a => a.date === currentDate)
        if (dateTmp)
            return (
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{height:"20%"}} />
                    <div style={{ height: "40%" }}>
                        <Camera style={{ width: "22%", height: "100%" }} />
                    </div>
                    <div style={{ height: "40%", color: "#90c261" }}>
                        {dateTmp.trays}
                    </div>
                </div>
            )
    }

    const cells = () => {
        const monthStart = startOfMonth(currentDate);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = addDays(startDate, 1);
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`column cell ${!isSameMonth(day, monthStart)
                            ? "disabled"
                            : isSameDay(day, selectedDate)
                                ? "selected"
                                : ""
                            }`}
                        style={{ overflow: "visible" }}
                        key={day}
                        onClick={() => onDateClick(toDate(cloneDay))}
                    >
                        {getPicturesDate(day)}
                        <div className="number" style={{ zIndex: 10 }}
                            onClick={(e) => {
                            }}>{formattedDate}</div>
                        <span className="bg">{formattedDate}</span>
                    </div>
                );

                day = addDays(day, 1);
            }
            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    };
    const onDateClick = async (date) => {
        let dateTmp = moment(date).format("YYYY-MM-DD")
        dispatch(setSelectedDatePictures(dateTmp))
        dispatch(setLastSelectedDatePictures(dateTmp))
        let month = t('date', { value: { date: date, format: "MMMM YYYY" } })
        let monthFinal = month.charAt(0).toUpperCase() + month.slice(1)
        dispatch(setSelectedMonthPictures(monthFinal))
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}
            paddingTop={"1%"}>
            <div className="calendar">
                <div style={{ color: "#666666" }}>{header()}</div>
                <div style={{ color: "#666666" }}>{daysOfWeek()}</div>
                <div style={{ color: "#666666" }}>{cells()}</div>
            </div>

        </CustomBlock>
    )
}

export default PicturesCalendar;
