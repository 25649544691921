import { useTranslation } from "react-i18next";
import CustomBlock from "../../customComponents/CustomBlock";
import { ReactComponent as Pdf } from "../../../assets/pdf.svg";
import { ReactComponent as Check } from "../../../assets/check.svg";
import { ReactComponent as Warning } from "../../../assets/warning.svg";
import { setSelectedAnalysisFiles, setShowOnBoarding } from "../../../actions_reducers/actions/files.action";
import { useDispatch } from "react-redux";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import CustomButton from "../../customComponents/CustomButton";
import Synthesis from "../synthesis/Synthesis";
import { downLoadSynthesisRestaurant, hasSynthesisAnalysis } from "../../../apiRoutes/files.routes";
import { useEffect, useState } from "react";
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);


export const FilesAnalyzes = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const updateSelectedAnalysis = (e) => {
        dispatch(setSelectedAnalysisFiles(e))
    }
    const downloadSynthesis = async (e) => {
        if (e) {
            let analysis = e.toLowerCase().replace(/ /g, "_");
            let restaurant_name = props.restaurant_name;
            let path = restaurant_name + "/synthesis/" + analysis + "/";

            let values = {
                account: props.userState.login,
                analysisName: e.toLowerCase(),
                restaurant_name: props.restaurant_name
            }
            await downLoadSynthesisRestaurant(path, values);
        }
        else return "";
    }
    const hasSynthesis = (analysisNumber) => {
        let synthesis = props.analyzesSynthesis.find(a => a.analysisNumber === analysisNumber);
        if (synthesis) {
            return synthesis.hasSynthesis
        }
        else return false;
    }
    const addAnalyzesToResult = (analyzes, result, allAnalyzes) => {
        for (const analysis of analyzes) {
            if (!allAnalyzes.find(a => a === analysis.analysisNumber)) {
                allAnalyzes.push(analysis.analysisNumber);
                let analysisNumber = analysis.analysisNumber;
                let dateStart = analysis.dateStart;
                let dateEnd = analysis.dateEnd;
                let isOver = analysis.isOver;
                let hasMenus = analysis.hasMenus;
                let hasEffectives = analysis.hasEffectives;
                let analysisName = "analyse_" + analysisNumber;
                let selectAnalysis = t('arianne.detailedAnalysis', { complementValue: analysisNumber });
                let isCurrentSelected = selectAnalysis === props.selected_analysis;
                let date = t('date', { value: { date: moment(analysis.dateEnd).subtract(7, 'days').format('YYYY-MM-DD'), format: 'MMMM YYYY' } })
                result.push(
                    <div key={analysisName} id={analysisName} className="files-analyzes-table-row" style={{ backgroundColor: isCurrentSelected && "#CACACA80", borderRadius: isCurrentSelected && "10px", fontWeight: isCurrentSelected && "bold" }}>
                        <div id={analysisName}
                            style={{ width: props.user_type === "admin" ? props.has_synthesis ? "12%" : "14.4%" : props.has_synthesis ? "20%" : "22.4%", justifyContent: "flex-start", display: "flex" }}
                            onClick={(e) => updateSelectedAnalysis(selectAnalysis)}>
                            {props.user_type === "admin" ?
                                t('components.analysisName', { "analysisNumber": analysisNumber }) :
                                date.charAt(0).toUpperCase() + date.slice(1)
                            }
                        </div>
                        <div id={analysisName} style={{ width: props.has_synthesis ? "44%" : "46.4%", display: "flex" }}
                            onClick={(e) => updateSelectedAnalysis(selectAnalysis)}>
                            {t('components.from')}&nbsp;
                            {t('date', { "value": { "date": dateStart, "format": "DD MMMM YYYY" } })}&nbsp;
                            {t('components.to')}&nbsp;
                            {t('date', { "value": { "date": dateEnd, "format": "DD MMMM YYYY" } })}&nbsp;
                        </div>
                        {props.user_type === "admin" && <Synthesis id={analysisName} />}
                        <div id={analysisName} style={{ width: props.user_type === "admin" ? props.has_synthesis ? "10%" : "12.4%" : props.has_synthesis ? "12%" : "14.4%" }}
                            onClick={(e) => updateSelectedAnalysis(selectAnalysis)}>
                            {hasMenus ?
                                <Check id={analysisName} style={{ width: "50%", height: "60%", fill: "#72B9A2" }}
                                    onClick={(e) => updateSelectedAnalysis(selectAnalysis)} />
                                :
                                <Warning id={analysisName} style={{ width: "46%", height: "50%", fill: "#EA881F" }}
                                    onClick={(e) => updateSelectedAnalysis(selectAnalysis)} />
                            }
                        </div>
                        <div id={analysisName} style={{ width: props.user_type === "admin" ? props.has_synthesis ? "10%" : "12.4%" : props.has_synthesis ? "12%" : "14.4%" }}
                            onClick={(e) => updateSelectedAnalysis(selectAnalysis)}>
                            {hasEffectives ?
                                <Check id={analysisName} name={"test"} style={{ width: "50%", height: "60%", fill: "#72B9A2" }}
                                    onClick={(e) => updateSelectedAnalysis(selectAnalysis)} />
                                :
                                <Warning id={analysisName} style={{ width: "46%", height: "50%", fill: "#EA881F" }}
                                    onClick={(e) => updateSelectedAnalysis(selectAnalysis)} />
                            }
                        </div>
                        {props.has_synthesis &&
                            <div id={analysisName} style={{ width: "12%" }}
                                onClick={(e) => isOver && downloadSynthesis(analysisName)}>
                                {hasSynthesis(analysisNumber) && <Pdf id={analysisName} style={{ width: "50%", height: "60%", fill: "#666666" }}
                                />}
                            </div>}
                    </div>
                )
            }
        }
        return {
            result: result,
            allAnalyzes: allAnalyzes
        };
    }
    const getAnalyzesRows = () => {
        let result = [];
        let allAnalyzes = [];
        let analyzes = JSON.parse(JSON.stringify(props.analyzesDatas)).sort((a, b) => b.analysisNumber - a.analysisNumber);
        let kitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenDatas)).sort((a, b) => b.analysisNumber - a.analysisNumber);
        let allAnalyzesTmp = JSON.parse(JSON.stringify(props.allAnalyzes)).sort((a, b) => b.analysisNumber - a.analysisNumber);
        let analyzesTest = analyzes;
        for (const analysis of kitchenAnalyzes) {
            if (!analyzesTest.find(a => a.analysisNumber === analysis.analysisNumber))
                analyzesTest.push(analysis)
        }
        let nextAnalysis = allAnalyzesTmp.find(a => a.analysisNumber === 1);
        if (analyzesTest[0])
            nextAnalysis = allAnalyzesTmp.find(a => a.analysisNumber === analyzesTest[0].analysisNumber + 1)
        if (nextAnalysis) {
            nextAnalysis.dateStart = nextAnalysis.dates[0];
            nextAnalysis.dateEnd = nextAnalysis.dates[nextAnalysis.dates.length - 1]
            analyzesTest.push(nextAnalysis)
        }
        analyzesTest.sort((a, b) => b.analysisNumber - a.analysisNumber)
        result = addAnalyzesToResult(analyzesTest, result, allAnalyzes).result;
        allAnalyzes = addAnalyzesToResult(analyzesTest, result, allAnalyzes).allAnalyzes
        return result;
    }
    return (
        <CustomBlock
            width={"70%"}
            height={"100%"}
            marginLeft={"5%"}
            paddingLeft={"1%"}
            paddingRight={"1%"}
            paddingTop={"1%"}
            paddingBottom={"1%"}
            backgroundColor={"#fff"}
        >
            <div className="files-analyzes-table-headers">
                <div style={{ width: props.user_type === "admin" ? props.has_synthesis ? "12%" : "14.4%" : props.has_synthesis ? "20%" : "22.4%", justifyContent: "flex-start", display: "flex" }}>
                    {props.user_type === "admin" ? t('table.analysis') : t('table.month')}
                </div>
                <div style={{ width: props.has_synthesis ? "44%" : "46.4%", justifyContent: "center", display: "flex", paddingRight: "15%" }}>
                    {t('table.period')}
                </div>
                {props.user_type === "admin" &&
                    <div style={{ width: "12%", justifyContent: "flex-start", display: "flex" }}>
                        {t('table.adminSynthesis')}
                    </div>
                }
                <div style={{ width: props.user_type === "admin" ? props.has_synthesis ? "10%" : "12.4%" : props.has_synthesis ? "12%" : "14.4%" }}>
                    {t('table.menus')}
                </div>
                <div style={{ width: props.user_type === "admin" ? props.has_synthesis ? "10%" : "12.4%" : props.has_synthesis ? "12%" : "14.4%" }}>
                    {t('table.effectives')}
                </div>
                {props.has_synthesis &&
                    <div style={{ width: "12%" }}>
                        {t('table.download')}
                    </div>}
            </div>
            {!props.on_boarding_done &&
                <div style={{ height: "20%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <Warning style={{ width: "8%", height: "40%", fill: "#E1686A", marginTop: "1%" }} />
                    <CustomButton
                        width={"50%"}
                        height={"40%"}
                        fontSize={"1vw"}
                        color={"#fff"}
                        backgroundColor={"#9CCEBE"}
                        onClick={() => dispatch(setShowOnBoarding(true))}
                    >
                        {t('components.onBoardingButton')}
                    </CustomButton>
                </div>
            }
            <div className="files-analyzes-table-body" style={{ height: props.on_boarding_done ? "90%" : "70%" }}>
                <CustomScrollDiv update={Math.random()}>
                    {getAnalyzesRows()}
                </CustomScrollDiv>
            </div>
        </CustomBlock>
    )
}