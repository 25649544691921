import React from "react";
import CustomButton from "../../../customComponents/CustomButton";
import { useTranslation } from "react-i18next";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { useDispatch } from "react-redux";

const DashboardNoAnalyzes = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigateTo = (value) => {
        updateArianneThread(value, props.arianne, dispatch, t())
    }

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ height: "50%", width: "80%", justifyContent: "center", alignItems: "center", display: "flex", textAlign: "center", fontSize: "clamp(1.25rem,1.25rem,2rem)" }}>
                {t('components.noAnalyzesClient')}
            </div>
            <div style={{ height: "25%", width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                <CustomButton width={"30%"} height={"50%"} backgroundColor={"#68B4E3"} value={t('buttons.detailedAnalysis')} color={"#fff"}
                    onClick={() => navigateTo('clientDetailed')} />

            </div>
        </div>
    )
}
export default DashboardNoAnalyzes;