import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import LineChart from "../../../charts/LineChart";
import { useTranslation } from "react-i18next";

const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const CompareGraph = (props) => {
    const { t } = useTranslation();
    const getColor = (index) => {
        if (index === 0) return '#68B4E3';
        else if (index === 1) return '#90C261';
        else if (index === 2) return '#EA881F';
        else if (index === 3) return '#FDD11D';
        else if (index === 4) return '#AE8FE0';

    }
    const getValue = (analysis, singleData) => {
        switch (props.dataTypeClient) {
            case 'massByConv':
                var massByConv = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseFloat(analysis.trays);
                singleData.y = massByConv;
                singleData.tooltip = (analysis.restaurant_name + " : " + t('mass', { "value": { "mass": massByConv, "unit": "g" } }));
                return singleData;
            case 'mass':
                var mass = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass))
                singleData.y = mass / 1000;
                singleData.tooltip = analysis.restaurant_name + " : " + t('mass', { "value": { "mass": mass, "unit": "kg" } });
                return singleData;
            case 'cost':
                var cost = parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)
                singleData.y = cost;
                singleData.tooltip = analysis.restaurant_name + " : " + t('currency', { "value": { "cost": cost, "fixed": 0 } });
                return singleData;
            case 'co2':
                var co2 = (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2))
                singleData.y = co2 / 1000;
                singleData.tooltip = analysis.restaurant_name + " : " + t('mass', { "value": { "mass": co2, "unit": "kg" } });
                return singleData
            case 'trays':
                var trays = parseInt(analysis.trays)
                singleData.y = parseInt(analysis.trays);
                singleData.tooltip = analysis.restaurant_name + " : " + trays + t('components.trays');
                return singleData;
            default:
                var defaultValue = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseFloat(analysis.trays);
                singleData.y = defaultValue;
                singleData.tooltip = analysis.restaurant_name + " : " + t('mass', { "value": { "mass": defaultValue, "unit": "g" } });
                return singleData;
        }
    }
    const getName = (restaurant_name) => {
        let restaurant = props.displayNames.find(a => a.restaurant_name === restaurant_name);
        if (restaurant && restaurant.value) return restaurant.value;
        else return restaurant_name;
    }

    const getTooltip = (restaurantTmp, singleData) => {
        let restaurant = getName(restaurantTmp)
        switch (props.dataTypeClient) {
            case 'massByConv':
                singleData.tooltip = restaurant + " : " + t('mass', { "value": { "mass": singleData.y, "unit": "g" } });
                return singleData;
            case 'mass':
                singleData.tooltip = restaurant + " : " + t('mass', { "value": { "mass": singleData.y * 1000, "unit": "kg" } });
                return singleData;
            case 'cost':
                singleData.tooltip = restaurant + " : " + t('currency', { "value": { "cost": singleData.y, "fixed": 0 } });
                return singleData;
            case 'co2':
                singleData.tooltip = restaurant + " : " + t('mass', { "value": { "mass": singleData.y * 1000, "unit": "kg" } });
                return singleData
            default:
                singleData.tooltip = restaurant + " : " + t('mass', { "value": { "mass": singleData.y, "unit": "g" } });
                return singleData;
        }

    }
    const getValues = () => {
        let result = [
        ];
        let analyzes = JSON.parse(JSON.stringify(props.clientAnalyzes));
        let kitchenAnalyzes = JSON.parse(JSON.stringify(props.baseAnalyzesKitchen));
        if (props.extrapolationValue !== "analysis" && props.dataTypeClient !== "massByConv") {
            analyzes = JSON.parse(JSON.stringify(props.clientExtrapolatedAnalyzes));
            kitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenAnalyzes));
        }
        let currentAnalyzes = analyzes.filter(a => props.selectedRestaurants.find(b => b === a.restaurant_name));
        let currentKitchenAnalyzes = kitchenAnalyzes.filter(a => props.selectedRestaurants.find(b => b === a.restaurant_name));
        let max = 0;
        for (const [index, restaurant] of props.selectedRestaurants.entries()) {
            let newRest =
            {
                id: restaurant,
                color: getColor(index),
                data: [
                ]
            }
            if (currentAnalyzes && props.showRestaurant) {
                for (const analysis of currentAnalyzes.filter(a => a.restaurant_name === restaurant)) {
                    let analysisDates = props.clientAnalyzesDates.find(a =>
                        moment(new Date(analysis.dateStart)).isSameOrAfter(moment(new Date(a.dateStart)))
                        &&
                        moment(new Date(analysis.dateEnd)).isSameOrBefore(moment(new Date(a.dateEnd)))
                    )
                    if (analysisDates) {
                        let newValue = {
                            x: analysisDates.analysisNumber,
                        }
                        newValue = getValue(analysis, newValue)
                        if (newValue.y > max) { max = newValue.y }
                        newRest.data.push(newValue)
                    }
                }
            }
            if (currentKitchenAnalyzes) {
                for (const analysis of currentKitchenAnalyzes.filter(a => a.restaurant_name === restaurant)) {
                    let analysisDates = props.clientAnalyzesDates.find(a =>
                        moment(new Date(analysis.dateStart)).isSameOrAfter(moment(new Date(a.dateStart)))
                        &&
                        moment(new Date(analysis.dateEnd)).isSameOrBefore(moment(new Date(a.dateEnd)))
                    )
                    if (analysisDates) {
                        let newValue = {
                            x: analysisDates.analysisNumber,
                        }
                        newValue = getValue(analysis, newValue);
                        if (newValue.y > max) { max = newValue.y }
                        let index = newRest.data.findIndex(a => a.x === analysisDates.analysisNumber)
                        if (index === -1) {
                            newRest.data.push(newValue)
                        }
                        else
                            newRest.data[index].y = parseFloat(newRest.data[index].y) + parseFloat(newValue.y)
                    }
                }
            }
            for (let data of newRest.data) {
                data = getTooltip(restaurant, data)
            }
            if (newRest.data[0]) {
                newRest.data.sort((a, b) => a.x - b.x)
                let firstData = JSON.parse(JSON.stringify(newRest.data[0]))
                firstData.x = firstData.x - 1
                newRest.data.unshift(firstData)
                let lastData = JSON.parse(JSON.stringify(newRest.data[newRest.data.length - 1]))
                lastData.x = lastData.x + 1
                newRest.data.push(lastData)
            }
            result.push(newRest)
        }
        let newRest =
        {
            id: "test",
            color: "transparent",
            data: []
        }
        for (const date of props.clientAnalyzesDates) {
            let newDate = moment(date.dateEnd).add(-7, "days");
            newRest.data.push({
                name: moment(newDate).format("MM/YY"),
                x: date.analysisNumber,
                y: max + max * 15 / 100
            })
        }
        result.unshift(newRest)
        result[0].data.sort((a, b) => a.x - b.x)
        result[0].data.unshift({
            name: t('components.reducedAnalysis', { analysisNumber: 0 }),
            x: 0,
            y: max + max * 15 / 100
        })
        result[0].data.push({
            name: t('components.reducedAnalysis', { analysisNumber: result[0].data[result[0].data.length - 1].x + 1 }),
            x: result[0].data[result[0].data.length - 1].x + 1,
            y: max + max * 15 / 100
        })
        return {
            graph: result,
            max: max
        };
    }
    const getAxisBottom = () => {
        return {

            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'transportation',
            legendOffset: 36,
            legendPosition: 'middle'

        }
    }
    return (
        <CustomBlock width={'100%'} height={'100%'}
            paddingTop={"2%"}
            paddingBottom={"2%"}
            paddingRight={"2%"}
            paddingLeft={"2%"}>
            <div style={{ height: "15%", width: "100%" }}>
                <div className="dashboard-block-title">{t(`components.graph${props.dataTypeClient}`)}</div>
                <div className="dashboard-block-subtitle">{t(`units.${props.dataTypeClient}`)}</div>
            </div>
            <div style={{ height: "85%", width: "100%" }}>
                <LineChart
                    id={"client-graph"}
                    color={"transparent"}
                    data={getValues().graph}
                    stacked={false}
                    axisBottom={getAxisBottom}
                    selectedAnalysisNumber={props.selectedAnalysis}
                    bottom={10} />

            </div>
        </CustomBlock>
    )
}
export default CompareGraph;