import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { getTraysValueForCamAndHour } from "../../../customFunctions/trays.function";
import { useState } from "react";
import { ReactComponent as Information } from "../../../../assets/informationButton.svg";

const TraysValues = (props) => {
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false)

    const getInfo = (type) => {
        return (
            <Information fill={"#666666"} style={{ width: "1.5vw", height: "2.5vh", top: "55%", right: "-2%", position: "absolute" }}
                onMouseLeave={() => setShowInfo(false)}
                onMouseEnter={() => setShowInfo(true)} />)
    }
    const getAverageTrays = () => {
        if (props.type !== "day") {
            if (props.analysis) {
                let trays = (props.analysis.trays / props.analysis.days.length).toFixed(0);
                return trays;
            }
        }
        else if (props.day) {
            return props.day.trays;
        }
        else if (props.kitchenDay) {
            return props.kitchenDay.trays;
        }
        else return 0;
    }
    const getEmptyTrays = () => {
        let emptyTrays = 0;
        if (props.type !== "day") {
            if (props.analysis && props.analysisTrays) {
                let empties = getTraysValueForCamAndHour(props.analysisTrays.empty, props.userState, "empty");
                emptyTrays = ((empties / props.analysis.trays) * 100).toFixed(0);
            }
        }
        else if (props.day && props.dayTrays) {
            let empties = getTraysValueForCamAndHour(props.dayTrays.empty, props.userState, "empty");
            return ((empties / props.day.trays) * 100).toFixed(0);
        }
        else if (props.kitchenDay && props.dayTrays) {
            let empties = getTraysValueForCamAndHour(props.dayTrays.empty, props.userState, "empty");
            return ((empties / props.kitchenDay.trays) * 100).toFixed(0);
        }
        return emptyTrays;
    }
    const getSortedTrays = () => {
        let sortedTrays = 0;
        if (props.type !== "day") {
            if (props.analysis && props.analysisTrays) {
                let nonOrgas = getTraysValueForCamAndHour(props.analysisTrays.nonOrga, props.userState, "nonOrga");
                sortedTrays = (((1 - nonOrgas / props.analysis.trays)) * 100).toFixed(0);
            }
        }
        else if (props.day && props.dayTrays) {
            let nonOrgas = getTraysValueForCamAndHour(props.dayTrays.nonOrga, props.userState, "nonOrga");
            return (((1 - nonOrgas / props.day.trays)) * 100).toFixed(0);
        }
        else if (props.kitchenDay && props.dayTrays) {
            let nonOrgas = getTraysValueForCamAndHour(props.dayTrays.nonOrga, props.userState, "nonOrga");
            return (((1 - nonOrgas / props.kitchenDay.trays)) * 100).toFixed(0);
        }
        return sortedTrays;
    }
    const getHorizBar = (value) => {
        return (
            <div style={{ width: "90%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "80%", height: "40%", display: "flex", backgroundColor: "#CBC4C4B3", borderRadius: "10px" }}>
                    <div style={{ width: (value >= 0 && value <= 5) ? "5%" : value + "%", backgroundColor: "#90C261", borderRadius: "10px" }} />
                </div>
                <div style={{ width: "20%", height: "40%", display: "flex", alignItems: "center", justifyContent: "flex-end", color: "#90C261" }}>
                    {value + "%"}
                </div>
            </div>
        )
    }
    return (
        <CustomBlock width={"100%"} height={"100%"}>
            <div style={{ width: '100%', height: '100%', padding: '3%' }}>
                <div className="dashboard-block-title" style={{ height: "15%", width: "100%" }}>
                    <div className="top-gaspi-title-div" style={{ paddingLeft: "1%" }}>
                        {t('components.traysBlockTitle')}

                    </div>
                </div>
                <div style={{ height: "15%", width: "100%", display: "flex", alignItems: "center", paddingLeft: "1%" }}>
                    <div style={{ width: props.type !== "day" ? "80%" : "65%" }}>
                        {props.type !== "day" ? t('components.averageTrays') : t('components.numberTraysValue')}
                    </div>
                    <div style={{ fontWeight: "600", width: "20%", justifyContent: "center", display: "flex", alignItems: "center" }}>
                        {getAverageTrays()}
                    </div>
                </div>
                <div style={{ height: "35%", width: "100%", paddingLeft: "1%" }}>
                    <div style={{ height: "50%", display: "flex", alignItems: "flex-end", fontWeight: "400" }}>
                        {t('components.emptyTrays')}
                    </div>
                    <div style={{ height: "50%", display: "flex", alignItems: "flex-start", justifyContent: "center", fontWeight: "600" }}>
                        {getHorizBar(getEmptyTrays())}
                    </div>
                </div>
                <div style={{ height: "35%", width: "100%", paddingLeft: "1%" }}>
                    <div style={{ height: "50%", display: "flex", alignItems: "flex-end", fontWeight: "400", position: "relative" }}
                        onMouseLeave={() => setShowInfo(false)}
                        onMouseEnter={() => setShowInfo(true)} >
                        {t('components.sortedTrays')}{props.type !== "day" && getInfo()}
                        {showInfo && (
                            <div className="window-info" style={{
                                width: "20vw", position: "absolute", bottom: "50%",
                                right: props.type !== "day" ? "-20vw" : "10vw", zIndex: "20031997", backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px", fontSize: "2vh",
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                color: "#666666"
                            }}>
                                {t("components.sortedTraysInfo")}</div>
                        )}
                    </div>
                    <div style={{ height: "50%", display: "flex", alignItems: "flex-start", justifyContent: "center", fontWeight: "600" }}>
                        {getHorizBar(getSortedTrays())}
                    </div>

                </div>
            </div>
        </CustomBlock>
    )

}
export default TraysValues;
