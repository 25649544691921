export function setSelectedAnalysisType(selectedAnalysisType) {
    return {
        type: 'SET_SELECTED_ANALYSIS_TYPE',
        selectedAnalysisType: selectedAnalysisType,
    }
}

export function setSelectedAnalysisNumber(selectedAnalysisNumber) {
    return {
        type: 'SET_SELECTED_ANALYSIS_NUMBER',
        selectedAnalysisNumber: selectedAnalysisNumber,
    }
}

export function setSelectedPieCategory(selectedPieCategory) {
    return {
        type: 'SET_SELECTED_PIE_CATEGORY',
        selectedPieCategory: selectedPieCategory
    }
}

export function setExtrapolationValue(extrapolationValue) {
    return {
        type: 'SET_EXTRAPOLATION_VALUE',
        extrapolationValue: extrapolationValue
    }
}

export function setShowedAnalysis(showedAnalysis) {
    return {
        type: 'SET_SHOWED_ANALYIS',
        showedAnalysis: showedAnalysis
    }
}

export function setSelectedDay(selectedDay) {
    return {
        type: 'SET_SELECTED_DAY',
        selectedDay: selectedDay
    }
}

export function setSelectedCategory(selectedCategory) {
    return {
        type: 'SET_SELECTED_CATEGORY',
        selectedCategory: selectedCategory
    }
}

export function setSelectedDataTypeDashboard(selectedDataType) {
    return {
        type: 'SET_SELECTED_DATA_TYPE_DASHBOARD',
        selectedDataType: selectedDataType
    }
}

export function setIsAnalysisCut(isAnalysisCut) {
    return {
        type: 'SET_IS_ANALYSIS_CUT',
        isAnalysisCut: isAnalysisCut
    }
}

export function setLoadAllDatas(loadAllDatas) {
    return {
        type: "SET_LOAD_ALL_DATAS",
        loadAllDatas: loadAllDatas
    }
}
export function hardResetDashboard() {
    return {
        type: 'HARD_RESET_DASHBOARD'
    }
}