import React from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import SamplesBinSelection from "./SamplesBinSelection";
import SamplesBinList from "./SamplesBinList";
import { setAllDatesSamplesBin, setSelectedAnalysisSamplesBin, setSelectedImageSamplesBin } from "../../../actions_reducers/actions/datas.action";
import { useEffect } from "react";
import SamplesBinCalendar from "./SamplesBinCalendar";
import { getAllDatesSamplesBin } from "../../../apiRoutes/samplesBin.route";
const separationHeight = "4vh";

const SamplesBinGestion = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (props.selectedAnalysis === null) {
            let result = [];
            for (const analysis of props.analyzes) {
                if (!result.find(a => a === analysis.analysisNumber))
                    result.push(analysis.analysisNumber)
            }
            for (const analysis of props.kitchenAnalyzes) {
                if (!result.find(a => a === analysis.analysisNumber))
                    result.push(analysis.analysisNumber)
            }
            result.sort((a, b) => b - a)
            dispatch(setSelectedAnalysisSamplesBin(result[0]))
        }
    }, [])
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }} onClick={(e) => {
            if (props.selectedImage !== null && e.target.id !== props.selectedImage && e.target.localName !== "path")
                dispatch(setSelectedImageSamplesBin(null))
        }
        }>
            {props.selected_day_samples_bin &&
                <>
                    <div style={{ width: "100%", height: "6vh", display: "flex", flexDirection: "row" }}>
                        <SamplesBinSelection
                            samplesBin={props.samplesBin}
                            deletedSamplesBin={props.deletedSamplesBin}
                            updatedSamplesBin={props.updatedSamplesBin}
                            selectedDay={props.selected_day_samples_bin}
                            restaurant_name={props.restaurant_name}
                            analyzes={props.analyzes}
                            kitchenAnalyzes={props.kitchenAnalyzes}
                            selectedAnalysis={props.selectedAnalysis}
                            login={props.login}
                            dates={props.all_dates_samples_bin} />
                    </div>
                    <div style={{ height: separationHeight }} />
                </>
            }
            <div style={{ width: "100%", height: props.selected_day_samples_bin != null ? "75.8vh" : "100%" }}>
                {
                    props.selected_day_samples_bin ?
                        <SamplesBinList
                            samplesBin={props.samplesBin}
                            food_list_kitchen={props.food_list_kitchen}
                            deletedSamplesBin={props.deletedSamplesBin}
                            updatedSamplesBin={props.updatedSamplesBin}
                            selectedDay={props.selected_day_samples_bin}
                            selectedImage={props.selectedImage}
                            login={props.login} />
                        : <SamplesBinCalendar dates={props.all_dates_samples_bin} lastSelectedDate={props.lastSelectedDate} />
                }
            </div>
        </div >
    )
}
function mapStateToProps(state) {
    return {
        samplesBin: state.datasReducer.samplesBin,
        selected_day_samples_bin: state.datasReducer.selected_day_samples_bin,
        food_list_kitchen: state.datasReducer.food_list_kitchen,
        deletedSamplesBin: state.datasReducer.deletedSamplesBin,
        updatedSamplesBin: state.datasReducer.updatedSamplesBin,
        restaurant_name: state.userReducer.selected_restaurant,
        selectedImage: state.datasReducer.selected_image_samples_bin,
        analyzes: state.datasReducer.analyzesDatas,
        kitchenAnalyzes: state.datasReducer.kitchenAnalyzesDatas,
        selectedAnalysis: state.datasReducer.selected_analysis_samples_bin,
        lastSelectedDate: state.datasReducer.last_selected_day_samples_bin,
        all_dates_samples_bin: state.datasReducer.all_dates_samples_bin,
        login: state.userReducer.user_type
    };
}

export default connect(mapStateToProps)(SamplesBinGestion);
