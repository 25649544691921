import React from "react";
import { useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import 'moment/locale/fr';
import CustomTextField from "../../../customComponents/CustomTextField";
import CustomAutocomplete from "../../../customComponents/CustomAutocomplete";
import CustomCheckbox from "../../../customComponents/CustomCheckbox";
import CustomButton from "../../../customComponents/CustomButton";
import TimePicker from 'react-time-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { setAddTaskOpen, setAnalysisNumberPlanning, setAnalysisTypePlanning, setAssessmentNumber, setDateEndPlanning, setDateStartPlanning, setHasSaturdayPlanning, setHasSundayPlanning, setHourEndPlanning, setHourStartPlanning, setIsTaskSelected, setOldDateEndPlanning, setOldDateStartPlanning, setOldHourEndPlanning, setOldHourStartPlanning, setSelectedRestaurantPlanning, setTasks, setTypePlanning } from "../../../../actions_reducers/actions/planning.action";
import { createDailyPlanning, deleteDailyPlanning, updateDailyPlanning, updateDailyPlannings } from "../../../../apiRoutes/dailyPlanning.routes";
import { createAnalyzes, deleteAnalyzes, updateAnalysis } from "../../../../apiRoutes/analyzes.routes";
import { createNewTask, deleteTaskForRestaurant, updateTask } from "../../../../apiRoutes/planning.routes";
import { updateObjectif } from "../../../../apiRoutes/objectives.route";
import CustomDatePicker from "../../../customComponents/CustomDatePicker";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const PlanningDetails = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const resetTaskValues = () => {
        dispatch(setSelectedRestaurantPlanning(null));
        dispatch(setTypePlanning(null));
        dispatch(setAnalysisNumberPlanning(null));
        dispatch(setAssessmentNumber(null));
        dispatch(setHasSaturdayPlanning(false));
        dispatch(setHasSundayPlanning(false));
        dispatch(setDateStartPlanning(null));
        dispatch(setDateEndPlanning(null));
        dispatch(setOldDateStartPlanning(null));
        dispatch(setOldDateEndPlanning(null));
        dispatch(setHourStartPlanning(null));
        dispatch(setOldHourEndPlanning(null));
        dispatch(setOldHourStartPlanning(null));
        dispatch(setAddTaskOpen(false));
        dispatch(setIsTaskSelected(false));
        dispatch(setAnalysisTypePlanning("Classique"));
    }

    const getAllDates = (startDate, endDate) => {
        const startDay = moment(startDate).format("YYYY-MM-DD"); //BEGIN GET ALL DATES
        const endDay = moment(endDate).format("YYYY-MM-DD");
        const allDates = [];
        var tmp = startDay;
        while (moment(tmp).isBefore(endDay)) {
            allDates.push(moment(tmp).format("YYYY-MM-DD"));
            tmp = moment(tmp).add(1, "days");
        }
        allDates.push(endDay);
        return allDates;
    };
    const confirmTask = async () => {
        let state = props.planningState;
        let tasks = JSON.parse(JSON.stringify(props.planningState.tasks));

        let newTask = {
            checked: false,
            restaurant_name: state.selectedRestaurant,
            type: state.type,
            analysisType: state.analysisType,
            analysisNumber: parseInt(state.analysisNumber),
            saturday: state.saturday,
            sunday: state.sunday,
            dateEnd: state.dateEnd,
            dateStart: state.dateStart || moment(new Date()).format("YYYY-MM-DD"),
            hourStart: state.hourStart || moment(new Date()).format("YYYY-MM-DD"),
            hourEnd: state.hourEnd,
            oldDateStart: state.oldDateStart,
            oldDateEnd: state.oldDateEnd,
            oldHourStart: state.oldHourStart,
            oldHourEnd: state.oldHourEnd,
        }
        if (!state.isTaskSelected) {
            if (parseInt(state.analysisNumber) === 1) {
                let newObjectif = {
                    restaurant_name: state.selectedRestaurant,
                    title: "Objectif global",
                    dateStart: moment(state.dateStart).add(-1, 'day'),
                    targetDate: moment(state.dateStart).add(1, 'year'),
                    reduction: 50,
                    indicator: "Masse par convive",
                    aliment: "Global"
                };
                await updateObjectif(newObjectif);
            }
            await createNewTask(newTask);
            let analyzes = [];
            if (state.type === t('table.analysis')) {
                if (newTask.analysisType === t('components.continuous')) {
                    let dateStart = moment(newTask.dateStart);
                    let dateEnd = moment(newTask.dateEnd);
                    let dates = getAllDates(dateStart, dateEnd);
                    let initialMonth = dateStart.month();
                    let months = [initialMonth];
                    let tmpAnalysis = {
                        restaurant_name: state.selectedRestaurant,
                        analysisNumber: parseInt(state.analysisNumber),
                        isOver: false,
                        hasEffectives: false,
                        hasMenus: false,
                        dates: getAllDates(dateStart, moment(dateStart).endOf('month').format("YYYY-MM-DD"))
                    }
                    analyzes.push(tmpAnalysis)
                    for (const date of dates) {
                        let tmpTask = JSON.parse(JSON.stringify(tmpAnalysis))
                        let month = moment(date).month();
                        if (months.findIndex(a => parseInt(a) === parseInt(month)) === -1) {
                            let tmpDateStart = moment(date).startOf('month').format("YYYY-MM-DD")
                            let tmpDateEnd = moment(date).endOf('month').format("YYYY-MM-DD")
                            let newAnalysis = {
                                restaurant_name: state.selectedRestaurant,
                                analysisNumber: parseInt(tmpTask.analysisNumber) + parseInt(analyzes.length),
                                isOver: false,
                                hasEffectives: false,
                                hasMenus: false,
                                dates: getAllDates(tmpDateStart, tmpDateEnd)
                            }
                            analyzes.push(newAnalysis)
                            months.push(moment(date).month());
                        }
                    }
                }
                else {
                    let dateStart = moment(newTask.dateStart);
                    let dateEnd = moment(newTask.dateEnd);
                    let tmpAnalysis = {
                        restaurant_name: state.selectedRestaurant,
                        analysisNumber: parseInt(state.analysisNumber),
                        isOver: false,
                        hasEffectives: false,
                        hasMenus: false,
                        dates: getAllDates(dateStart, dateEnd)
                    }
                    analyzes.push(tmpAnalysis)
                }
                await createAnalyzes(analyzes)
                let dateStart = moment(newTask.dateStart);
                let dateEnd = moment(newTask.dateEnd);
                let dailyPlannings = [];
                let allDates = getAllDates(dateStart, dateEnd);
                for (const date of allDates) {
                    let dailyPlanning = {
                        checked: false,
                        date: date,
                        restaurant_name: state.selectedRestaurant,
                        nbPicturesUnuploaded: 0,
                        nbPicturesUploaded: 0,
                        nbUploadLink: 0,
                        uploadingStatus: "to_upload",
                        inferenceStatus: "to_process"
                    }
                    dailyPlannings.push(dailyPlanning);
                }
                await createDailyPlanning(dailyPlannings)
            }
            tasks.push(newTask);
            dispatch(setTasks(tasks))
            resetTaskValues();
        }
        else {
            await updateTask(newTask);
            if (state.type === t('table.analysis')) {
                let dateStart = moment(newTask.dateStart);
                let dateEnd = moment(newTask.dateEnd);
                let tmpAnalysis = {
                    restaurant_name: state.selectedRestaurant,
                    analysisNumber: parseInt(state.analysisNumber),
                    isOver: false,
                    hasEffectives: false,
                    hasMenus: false,
                    dates: getAllDates(dateStart, dateEnd)
                }
                await updateAnalysis(tmpAnalysis)
                await updateDailyPlannings(state.selectedRestaurant, state.dateStart, state.dateEnd, state.oldDateStart, state.oldDateEnd)
            }
            let index = tasks.findIndex(a =>
                a.restaurant_name === newTask.restaurant_name &&
                a.type === newTask.type &&
                a.dateStart === newTask.oldDateStart &&
                a.dateEnd === newTask.oldDateEnd &&
                a.hourStart === newTask.oldHourStart &&
                a.hourEnd === newTask.oldHourEnd)
            tasks[index] = newTask;
            dispatch(setTasks(tasks))
            resetTaskValues();
        }
    }

    const deleteTask = async () => {
        let state = props.planningState;
        let newTask = {
            restaurant_name: state.selectedRestaurant,
            type: state.type,
            analysisType: state.analysisType,
            analysisNumber: parseInt(state.analysisNumber),
            saturday: state.saturday,
            sunday: state.sunday,
            dateEnd: state.dateEnd,
            dateStart: state.dateStart,
            hourStart: state.hourStart,
            hourEnd: state.hourEnd,
        }
        await deleteTaskForRestaurant(newTask);
        let analyzesToDelete = [];
        if (state.type === t('table.analysis')) {
            if (newTask.analysisType === t('components.continuous')) {
                let tmpAnalyzesToDelete = {
                    restaurant_name: state.selectedRestaurant,
                    analysisNumber: parseInt(state.analysisNumber)
                }
                let dateStart = moment(newTask.dateStart);
                let dateEnd = moment(newTask.dateEnd);
                let dates = getAllDates(dateStart, dateEnd);
                let initialMonth = dateStart.month();
                let months = [initialMonth];
                analyzesToDelete.push(tmpAnalyzesToDelete)
                for (const date of dates) {
                    let tmpTask = JSON.parse(JSON.stringify(tmpAnalyzesToDelete))
                    let month = moment(date).month();
                    if (months.findIndex(a => parseInt(a) === parseInt(month)) === -1) {
                        let tmpAnalyzesToDelete = {
                            restaurant_name: state.selectedRestaurant,
                            analysisNumber: parseInt(tmpTask.analysisNumber) + parseInt(analyzesToDelete.length)
                        }
                        analyzesToDelete.push(tmpAnalyzesToDelete)
                        months.push(moment(date).month());
                    }
                }
            }
            else {
                let tmpAnalyzesToDelete = {
                    restaurant_name: state.selectedRestaurant,
                    analysisNumber: parseInt(state.analysisNumber)
                }
                analyzesToDelete.push(tmpAnalyzesToDelete)
            }
            await deleteAnalyzes(analyzesToDelete)
            let dateStart = moment(newTask.dateStart);
            let dateEnd = moment(newTask.dateEnd);
            let dailyPlannings = [];
            let allDates = getAllDates(dateStart, dateEnd);
            for (const date of allDates) {
                let dailyPlanningToDelete = {
                    date: date,
                    restaurant_name: state.selectedRestaurant
                }
                dailyPlannings.push(dailyPlanningToDelete);
            }
            await deleteDailyPlanning(dailyPlannings)
        }
        let tasks = JSON.parse(JSON.stringify(props.planningState.tasks));
        let index = tasks.findIndex(a =>
            a.restaurant_name === newTask.restaurant_name
            && a.type === newTask.type
            && a.dateStart === newTask.dateStart
            && a.dateEnd === newTask.dateEnd
            && a.hourStart === newTask.hourStart
            && a.hourEnd === newTask.hourEnd);
        tasks.splice(index, 1)
        dispatch(setTasks(tasks))
        resetTaskValues();
    }

    const isWeekInTask = (week, year, task) => {
        let weekStartTask = moment(task.dateStart).week();
        let weekEndTask = moment(task.dateEnd).week();
        let yearStartTask = moment(task.dateStart).year();
        let yearEndTask = moment(task.dateEnd).year();
        if (weekStartTask <= week && weekEndTask >= week && yearStartTask === year && yearEndTask === year) {
            return true;
        }
        else if (yearStartTask < year && yearEndTask === year && weekStartTask <= week && weekEndTask >= week) {
            return true;
        }
        else if (yearEndTask > year && yearStartTask === year && weekStartTask <= week && weekEndTask <= week) {
            return true;
        }
        else return false;
    }

    const shownAnalyzes = (analyzes) => {
        let result = analyzes;
        let currentClient = props.planningState.selectedClientDropdown
        let currentRestaurant = props.planningState.selectedRestaurantDropdown
        if (currentRestaurant != null)
            result = analyzes.filter(a => a.restaurant_name === currentRestaurant)
        else if (currentClient != null) {
            let restaurantList = JSON.parse(JSON.stringify(props.restaurants)).filter(a => a.clientName === currentClient);
            result = analyzes.filter(a => restaurantList.find(b => a.restaurant_name === b.restaurantName));
        }


        return result;
    }
    const getAnalyzesOfWeek = (type) => {
        let tasks = JSON.parse(JSON.stringify(props.tasks));
        let selectedWeek = moment(new Date()).week();
        let selectedYear = moment(new Date()).year();
        let currentAnalyzesTmp = tasks.filter(a => isWeekInTask(selectedWeek, selectedYear, a))
        let currentAnalyzes = shownAnalyzes(currentAnalyzesTmp);
        // currentAnalyzes.sort((a, b) => a.restaurant_name.localeCompare(b.restaurant_name));
        currentAnalyzes.sort((a, b) => {
            if (moment(a.dateStart).isBefore(moment(b.dateStart))) {
                return -1;
            } else if (moment(a.dateStart).isAfter(moment(b.dateStart))) {
                return 1;
            } else {
                return a.restaurant_name.localeCompare(b.restaurant_name);
            }
        });

        let result = [];
        for (const analysis of currentAnalyzes) {
            if (analysis.type === type) {
                let dateStart = t('date', { "value": { "date": analysis.dateStart, "format": "DD/MM/YYYY" } })
                let dateEnd = t('date', { "value": { "date": analysis.dateEnd, "format": "DD/MM/YYYY" } })
                let hourStart = analysis.hourStart && analysis.hourStart.includes(":") ? analysis.hourStart : "";
                let hourEnd = analysis.hourEnd && analysis.hourEnd.includes(":") ? analysis.hourEnd : "";

                result.push(
                    <div style={{ height: "10%", width: "100%", display: "flex", flexDirection: "column", paddingTop: "4%", paddingBottom: "4%", fontSize: "0.8vw", color: "#666666" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "50%", alignItems: "center" }}>
                            {analysis.type === "Analyse" ?
                                t('components.analysisRestaurant', { number: analysis.analysisNumber, restaurant_name: analysis.restaurant_name }) :
                                analysis.type + " - " + analysis.restaurant_name}
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "50%", alignItems: "center" }}>
                            {type === "Analyse" ?
                                t('components.fromTo', { dateStart: dateStart, dateEnd: dateEnd }) :
                                (hourStart && hourEnd) ? t("components.thisDay", { dateStart: dateStart, hourStart: hourStart, hourEnd: hourEnd }) : `Le ${dateStart}`}
                        </div>
                    </div>
                )
            }
        }
        return result;
    }
    const getComplementValue = () => {
        if (props.planningState.type === t('table.analysis'))
            return "#" + props.planningState.analysisNumber;
        else if (props.planningState.type === t('components.assessment'))
            return "#" + props.planningState.assessmentNumber;
        else return "";
    }
    const getRestaurantName = () => {
        return props.planningState.selectedRestaurant;
    }
    const getTaskDetails = () => {
        let restaurants = props.restaurants.map(item => item.restaurantName);
        restaurants.sort((a, b) => a.localeCompare(b));
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <div style={{ height: "5%", width: "100%", paddingLeft: "3%", color: "#666666", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                    {t('components.restaurantSelect')}
                </div>
                <div style={{ height: "8%", width: "100%", justifyContent: "center", display: "flex", alignItems: "center" }}>
                    <CustomAutocomplete height={"60%"} width={"80%"}
                        values={restaurants}
                        displayedValue={props.planningState.selectedRestaurant}
                        selectedValue={props.planningState.selectedRestaurant}
                        onChange={(e) => dispatch(setSelectedRestaurantPlanning(e.target.id))}
                        autocomplete={true}
                        maxHeightDropdown={"40vh"}
                        arrowColor={"#6D6C7A"}
                        color={"#949494"}
                        fontSize={"0.9vw"}
                        border={"1px solid #6D6C7A"}
                        zIndex={10000} />
                </div>
                <div style={{ height: "6%", paddingLeft: "3%", width: "100%", color: "#666666", fontWeight: "bold" }}>
                    {t('components.eventType')}
                </div>
                <div style={{ height: "20%", width: "100%", paddingLeft: "6%" }}>
                    <div style={{ height: "25%", width: "100%", color: "#949494", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: props.planningState.analysisType === t('components.continuous') ? "50%" : "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "3O%", height: "100%" }}>
                                <CustomCheckbox
                                    width={"1.8vh"}
                                    height={"1.8vh"}
                                    checked={props.planningState.type === t('table.analysis')}
                                    onChange={() => {
                                        dispatch(setHourEndPlanning(null));
                                        dispatch(setHourStartPlanning(null));
                                        dispatch(setAssessmentNumber(null));
                                        dispatch(setTypePlanning(t('table.analysis')))
                                    }} />
                            </div>
                            <div style={{ width: "100%", paddingLeft: "10%" }}>
                                {t('table.analysis')}
                            </div>
                        </div>
                        {props.planningState.type === t('table.analysis') &&
                            <div style={{ width: props.planningState.analysisType === t('components.continuous') ? "40%" : "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                                <div style={{ height: "100%", width: "30%", paddingLeft: "8%" }}>
                                    {t('components.number')}
                                </div>
                                <div style={{ height: "100%", width: "80%", paddingLeft: "8%" }}>
                                    <CustomTextField width={"40%"} height={"60%"}
                                        borderRadius={"5px"}
                                        textAlign={"center"}
                                        type={"number"}
                                        id={"test-analysis-number"}
                                        value={props.planningState.analysisNumber || ""}
                                        onChange={(e) => {
                                            dispatch(setAnalysisNumberPlanning(e.target.value))
                                        }
                                        } />
                                    <div style={{ paddingLeft: "8%" }}></div>
                                    {props.planningState.analysisType === t('components.continuous') && "  à"}
                                </div>
                            </div>
                        }
                        {
                            props.planningState.analysisType === t('components.continuous') &&
                            <div style={{ width: "29%", height: "100%", display: "flex", flexDirection: "row" }}>
                                <div style={{ height: "100%", width: "30%" }}>
                                    {t('components.number')}
                                </div>
                                <div style={{ height: "100%", width: "70%" }}>
                                    {props.planningState.analysisNumber ? parseInt(props.planningState.analysisNumber) + 11 : ""}
                                </div>
                            </div>
                        }
                    </div>
                    {props.planningState.type === t('table.analysis') &&
                        <div style={{ height: "15%", width: "100%", paddingLeft: "10%", color: "#949494", display: "flex", flexDirection: "row", fontSize: "0.7vw" }}>
                            <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row", }}>
                                <div style={{ width: "20%", height: "100%" }}>
                                    <CustomCheckbox
                                        width={"1.8vh"}
                                        height={"1.8vh"}
                                        checked={props.planningState.analysisType === t('components.classical')}
                                        onChange={() => {
                                            dispatch(setAnalysisTypePlanning(t('components.classical')))
                                        }} />
                                </div>
                                <div style={{ width: "80%" }}>
                                    {t('components.classical')}
                                </div>
                            </div><div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "20%", height: "100%" }}>
                                    <CustomCheckbox
                                        width={"1.8vh"}
                                        height={"1.8vh"}
                                        checked={props.planningState.analysisType === t('components.continuous')}
                                        onChange={() => {
                                            dispatch(setAnalysisTypePlanning(t('components.continuous')))
                                        }} />
                                </div>
                                <div style={{ width: "80%" }}>
                                    {t('components.continuous')}
                                </div>
                            </div>
                        </div>}
                    <div style={{ height: "25%", width: "100%", color: "#949494", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "20%", height: "100%" }}>
                                <CustomCheckbox
                                    width={"1.8vh"}
                                    height={"1.8vh"}
                                    checked={props.planningState.type === t('components.assessment')}
                                    onChange={() => {
                                        dispatch(setHourEndPlanning(null));
                                        dispatch(setHourStartPlanning(null));
                                        dispatch(setAnalysisNumberPlanning(null));
                                        dispatch(setTypePlanning(t('components.assessment')))
                                    }} />
                            </div>
                            <div style={{ width: "80%" }}>
                                {t('components.assessment')}
                            </div>
                        </div>
                        {props.planningState.type === t('components.assessment') &&
                            <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "row" }}>
                                <div style={{ height: "100%", width: "20%" }}>
                                    {t('components.number')}
                                </div>
                                <div style={{ height: "100%", width: "80%" }}>
                                    <CustomTextField width={"40%"} height={"60%"}
                                        borderRadius={"5px"}
                                        textAlign={"right"}
                                        type={"number"}
                                        value={props.planningState.assessmentNumber || ""}
                                        onChange={(e) => dispatch(setAssessmentNumber(e.target.value))} />
                                </div>
                            </div>}
                    </div>
                    <div style={{ width: "50%", height: "25%", display: "flex", flexDirection: "row", color: "#949494" }}>
                        <div style={{ width: "20%", height: "100%" }}>
                            <CustomCheckbox
                                width={"1.8vh"}
                                height={"1.8vh"}
                                checked={props.planningState.type === t('components.installation')}
                                onChange={() => {
                                    dispatch(setDateStartPlanning(null));
                                    dispatch(setDateEndPlanning(null));
                                    dispatch(setTypePlanning(t('components.installation')))
                                }} />
                        </div>
                        <div style={{ width: "80%" }}>
                            {t('components.installation')}
                        </div>
                    </div>
                    <div style={{ width: "50%", height: "25%", display: "flex", flexDirection: "row", color: "#949494" }}>
                        <div style={{ width: "20%", height: "100%" }}>
                            <CustomCheckbox
                                width={"1.8vh"}
                                height={"1.8vh"}
                                checked={props.planningState.type === t('components.maintenance')}
                                onChange={() => {
                                    dispatch(setDateStartPlanning(null));
                                    dispatch(setDateEndPlanning(null));
                                    dispatch(setTypePlanning(t('components.maintenance')))
                                }} />
                        </div>
                        <div style={{ width: "80%" }}>
                            {t('components.maintenance')}
                        </div>
                    </div>
                </div>
                <div style={{ height: "8%", width: "100%", color: "#666666", fontWeight: "bold", display: "flex", alignItems: "center", paddingLeft: "3%", paddingTop: "5%" }}>
                    {t('components.weekEndWork-1')}
                    <div style={{ height: "100%", width: "100%", color: "#666666", fontWeight: "bold", fontSize: "0.7vw", display: "flex", alignItems: "center", paddingLeft: "3%", paddingTop: "1%" }}>
                        {t('components.weekEndWork-2')}
                    </div>
                </div>
                <div style={{ height: "10%", width: "100%", paddingLeft: "6%" }}>
                    <div style={{ width: "50%", height: "50%", display: "flex", flexDirection: "row", color: "#949494" }}>
                        <div style={{ width: "20%", height: "100%" }}>
                            <CustomCheckbox
                                width={"1.8vh"}
                                height={"1.8vh"}
                                onChange={() => dispatch(setHasSaturdayPlanning(!props.planningState.hasSaturday))}
                                checked={props.planningState.hasSaturday} />
                        </div>
                        <div style={{ width: "80%" }}>
                            {t('onBoarding.saturday')}
                        </div>
                    </div>
                    <div style={{ width: "50%", height: "50%", display: "flex", flexDirection: "row", color: "#949494" }}>
                        <div style={{ width: "20%", height: "100%" }}>
                            <CustomCheckbox
                                width={"1.8vh"}
                                height={"1.8vh"}
                                onChange={() => dispatch(setHasSundayPlanning(!props.planningState.hasSunday))}
                                checked={props.planningState.hasSunday} />
                        </div>
                        <div style={{ width: "80%" }}>
                            {t('onBoarding.sunday')}
                        </div>
                    </div>
                </div>
                <div style={{ height: "2%" }} />
                <div style={{ height: "10%", width: "85%", paddingLeft: "6%", position: "relative", zIndex: 1 }}>
                    <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "row", color: "#949494" }}>
                        <div style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('components.from')}
                        </div>
                        <div style={{ width: "5%" }} />
                        <div style={{
                            width: "85%", height: "100%",
                            display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"
                        }}>
                            <CustomDatePicker
                                id={'dropdown-search-selection'}
                                startDate={new Date()}
                                selected={props.planningState.dateStart ? new Date(props.planningState.dateStart) : new Date()}
                                onChange={(e) => {
                                    e ?
                                        dispatch(setDateStartPlanning(moment(e).format("YYYY-MM-DD"))) :
                                        dispatch(setDateStartPlanning(null))
                                }}
                                backgroundColor={"#D9D9D9"}
                                popper={"top"} />
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "row", color: "#949494" }}>
                        <div style={{ width: "10%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {t('components.to')}
                        </div>
                        <div style={{ width: "5%" }} />
                        <div style={{ width: "85%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center" }}>

                            <CustomDatePicker
                                id={'dropdown-search-selection'}
                                startDate={new Date()}
                                selected={props.planningState.dateEnd ? new Date(props.planningState.dateEnd) : new Date()}
                                onChange={(e) => {
                                    e ?
                                        dispatch(setDateEndPlanning(moment(e).format("YYYY-MM-DD"))) :
                                        dispatch(setDateEndPlanning(null))
                                }}
                                backgroundColor={"#D9D9D9"} />
                        </div>
                    </div>
                </div>
                {(props.planningState.type === t('components.installation') || props.planningState.type === t('components.maintenance') || props.planningState.type === t('components.assessment')) &&
                    <><div style={{ height: "2%" }} />
                        <div style={{ height: "10%", width: "100%" }}>
                            <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "row", color: "#949494" }}>
                                <div style={{ width: "50%", height: "100%" }}>
                                    {t('components.hourStart')}
                                </div>
                                <div style={{ width: "50%" }}>
                                    <TimePicker
                                        onChange={(e) => {
                                            dispatch(setHourStartPlanning(e))
                                        }} value={props.planningState.hourStart} />
                                </div>
                            </div>
                            <div style={{ width: "100%", height: "50%", display: "flex", flexDirection: "row", color: "#949494" }}>
                                <div style={{ width: "50%", height: "100%" }}>
                                    {t('components.hourEnd')}
                                </div>
                                <div style={{ width: "50%" }}>
                                    <TimePicker
                                        onChange={(e) => {
                                            dispatch(setHourEndPlanning(e))
                                        }} value={props.planningState.hourEnd} />
                                </div>
                            </div>
                        </div></>}
                <div style={{ height: "2%" }} />
                <div style={{ height: (props.planningState.type === t('components.installation') || props.planningState.type === t('components.assessment')|| props.planningState.type === t('components.maintenance')) ? "15%" : "27%", width: "100%" }}>
                    <div style={{ height: "30%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton height={"80%"} width={"70%"} backgroundColor={"#666666"} color={"#fff"}
                            onClick={() => resetTaskValues()}>
                            {t('buttons.back')}
                        </CustomButton>
                    </div>
                    <div style={{ height: "5%" }} />
                    <div style={{ height: "30%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton height={"80%"} width={"70%"} backgroundColor={"#949494"} color={"#fff"}
                            onClick={() => deleteTask()}>
                            {t('buttons.delete')}
                        </CustomButton>
                    </div>
                    <div style={{ height: "5%" }} />
                    <div style={{ height: "30%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton height={"80%"} width={"70%"} backgroundColor={"#68B4E3"} color={"#fff"}
                            onClick={() => confirmTask()}>
                            {t('buttons.confirm')}
                        </CustomButton>
                    </div>
                </div>
            </div >
        )
    }

    const isScrolldiv = () => {
        let tasks = JSON.parse(JSON.stringify(props.tasks));
        let selectedWeek = moment(new Date()).week();
        let selectedYear = moment(new Date()).year();
        let currentAnalyzesTmp = tasks.filter(a => isWeekInTask(selectedWeek, selectedYear, a))
        let currentAnalyzes = shownAnalyzes(currentAnalyzesTmp)
        currentAnalyzes.sort((a, b) => a.restaurant_name.localeCompare(b.restaurant_name));

        if (currentAnalyzes.length > 9)
            return true;
        else
            return false;
    }

    const getTitle = () => {
        if (props.addTaskOpen) {
            if (props.isTaskSelected) {
                return t('components.newTaskTitle', { eventType: props.planningState.type, complementValue: getComplementValue(), restaurant_name: getRestaurantName() })
            }
            else return t('components.newTask')
        }
        else return t('components.currentAnalyzes')
    }
    return (
        <CustomBlock
            width={"104%"}
            height={"100%"}
            paddingLeft={"2%"}
            paddingRight={"2%"}
            paddingTop={"2%"}
            paddingBottom={"2%"}
            backgroundColor={props.addTaskOpen ? "#B5DAF1" : "#fff"}>

            <div style={{ height: "2%" }}></div>
            <div style={{ height: "9%", width: "100%", display: "flex", justifyContent: "center", color: "#666666", fontWeight: "bold", paddingLeft: "5%" }}>
                {getTitle()}
            </div>
            <div style={{ height: "90%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "left", paddingLeft: "5%" }}>
                {props.addTaskOpen ?
                    <>
                        {getTaskDetails()}
                    </> :
                    <CustomScrollDiv update={Math.random()}>
                        {getAnalyzesOfWeek("Analyse").length > 0 && (
                            <div style={{ paddingLeft: "1%" }}>
                                <div style={{ fontWeight: "light", fontSize: "1.85vh", color: "#68B4E3", paddingLeft: "5%", alignItems: "center" }}>• {t("table.analysis")}</div>
                                {getAnalyzesOfWeek("Analyse")}
                            </div>
                        )}

                        {getAnalyzesOfWeek("Installation").length > 0 && (
                            <div style={{ paddingLeft: "1%" }}>
                                <div style={{ fontWeight: "light", fontSize: "1.85vh", color: "#90C261", paddingTop: "5%", paddingLeft: "5%" }}>• {t("components.installation")}</div>
                                {getAnalyzesOfWeek("Installation")}
                            </div>
                        )}

                        {getAnalyzesOfWeek("Maintenance").length > 0 && (
                            <div style={{ paddingLeft: "1%" }}>
                                <div style={{ fontWeight: "light", fontSize: "1.85vh", color: "#90C261", paddingTop: "5%", paddingLeft: "5%" }}>• {t("components.maintenance")}</div>
                                {getAnalyzesOfWeek("Maintenance")}
                            </div>
                        )}

                        {getAnalyzesOfWeek("Bilan").length > 0 && (
                            <div style={{ paddingLeft: "1%" }}>
                                <div style={{ fontWeight: "light", fontSize: "1.85vh", color: "#90C261", paddingTop: "5%", paddingLeft: "5%" }}>• {t("components.assessment")}</div>
                                {getAnalyzesOfWeek("Bilan")}
                            </div>
                        )}

                    </CustomScrollDiv>}
            </div>
        </CustomBlock>
    )
}
export default PlanningDetails;
