import React, { useState } from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { ResponsiveBar } from '@nivo/bar';

const Repartition = (props) => {
    const { t } = useTranslation();
    const [visibleRestaurant, setVisibleRestaurant] = useState(false)
    const [visibleKitchen, setVisibleKitchen] = useState(false)
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);

    const getValues = () => {
        let selectedAnalysisNumber = props.state.selectedAnalysisNumber;
        let kitchenAnalysis = JSON.parse(JSON.stringify(props.kitchenDatas)).find(a => parseInt(a.analysisNumber) === parseInt(selectedAnalysisNumber));

        let analysis = JSON.parse(JSON.stringify(props.analyzesDatas)).find(a => parseInt(a.analysisNumber) === parseInt(selectedAnalysisNumber))

        let baseAnalysis = props.baseAnalysis && JSON.parse(JSON.stringify(props.baseAnalysis)).find(a => parseInt(a.analysisNumber) === parseInt(selectedAnalysisNumber));
        let baseKitchenAnalysis = props.baseAnalysisKitch && JSON.parse(JSON.stringify(props.baseAnalysisKitch)).find(a => parseInt(a.analysisNumber) === parseInt(selectedAnalysisNumber))
        let valueKitch = 0;
        let valueRest = 0;
        let totalToShow = "";
        let valueTotal = 1;
        let valueRestToShow = "";
        let valueKitchToShow = "";
        if (analysis || kitchenAnalysis) {
            switch (props.state.selectedAnalysisType) {
                case 'massByConv':
                    valueRest = baseAnalysis ? (parseFloat(baseAnalysis.mass) - parseFloat(baseAnalysis.inevitableMass)) / parseFloat(baseAnalysis.trays) : 0
                    valueKitch = baseKitchenAnalysis ? (parseFloat(baseKitchenAnalysis.mass) - parseFloat(baseKitchenAnalysis.inevitableMass)) / parseFloat(baseKitchenAnalysis.trays) : 0;
                    valueTotal = valueRest + valueKitch;
                    valueKitchToShow = t('mass', { "value": { "mass": valueKitch, "unit": "g" } });
                    valueRestToShow = t('mass', { "value": { "mass": valueRest, "unit": "g" } });
                    totalToShow = t('mass', { "value": { "mass": valueTotal, "unit": "g" } });
                    break;
                case 'mass':
                    valueRest = analysis ? parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass) : 0
                    valueKitch = kitchenAnalysis ? parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass) : 0;
                    valueTotal = valueRest + valueKitch;
                    valueKitchToShow = t('mass', { "value": { "mass": valueKitch, "unit": "kg" } });
                    valueRestToShow = t('mass', { "value": { "mass": valueRest, "unit": "kg" } });
                    totalToShow = t('mass', { "value": { "mass": valueTotal, "unit": "kg" } });
                    break;
                case 'cost':
                    valueRest = analysis ? parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost) : 0
                    valueKitch = kitchenAnalysis ? (parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost)) : 0
                    valueTotal = valueRest + valueKitch;
                    valueKitchToShow = t('currency', { "value": { cost: Math.abs(valueKitch).toFixed(0) } })
                    valueRestToShow = t('currency', { "value": { cost: Math.abs(valueRest).toFixed(0) } })
                    totalToShow = t('currency', { "value": { cost: Math.abs(valueTotal).toFixed(0) } })
                    break;
                case 'co2':
                    valueRest = analysis ? parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2) : 0
                    valueKitch = kitchenAnalysis ? parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2) : 0
                    valueTotal = valueRest + valueKitch;
                    valueKitchToShow = t('mass', { "value": { "mass": valueKitch, "unit": "kg" } });
                    valueRestToShow = t('mass', { "value": { "mass": valueRest, "unit": "kg" } });
                    totalToShow = t('mass', { "value": { "mass": valueTotal, "unit": "kg" } });
                    break;
                default:
                    valueRest = analysis ? (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseFloat(analysis.trays) : 0
                    valueKitch = kitchenAnalysis ? (parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass)) / parseFloat(kitchenAnalysis.trays) : 0;
                    valueTotal = valueRest + valueKitch;
                    valueKitchToShow = t('mass', { "value": { "mass": valueKitch, "unit": "g" } });
                    valueRestToShow = t('mass', { "value": { "mass": valueRest, "unit": "g" } });
                    totalToShow = t('mass', { "value": { "mass": valueTotal, "unit": "g" } });
                    break;
            }
        }
        let kitchenPercentage = (valueKitch / valueTotal * 100).toFixed(0);
        let restaurantPercentage = (valueRest / valueTotal * 100).toFixed(0);

        return {
            valueTotal: totalToShow,
            kitchenPercentage: kitchenPercentage,
            valueRestToShow: valueRestToShow,
            valueKitchToShow: valueKitchToShow,
            restaurantPercentage: restaurantPercentage
        }
    }
    const getValuesClient = () => {
        let valueKitch = 0;
        let valueRest = 0;
        let traysRestaurant = 0;
        let massRestaurant = 0;
        let traysKitchen = 0;
        let massKitchen = 0;
        let totalToShow = "";
        let valueTotal = 1;
        let valueRestToShow = "";
        let valueKitchToShow = "";
        if (props.analyzes && props.analyzes.length > 0)
            for (const analysis of props.analyzes) {
                massRestaurant = parseFloat(massRestaurant) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)
                traysRestaurant = parseInt(traysRestaurant) + parseInt(analysis.trays);
            }
        if (props.kitchenAnalyzes && props.kitchenAnalyzes.length > 0)
            for (const analysis of props.kitchenAnalyzes) {
                massKitchen = parseFloat(massKitchen) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)
                traysKitchen = parseInt(traysKitchen) + parseInt(analysis.trays);
            }
        valueRest = traysRestaurant > 0 ? massRestaurant / traysRestaurant : 0;
        valueKitch = traysKitchen > 0 ? massKitchen / traysKitchen : 0;
        valueTotal = valueRest + valueKitch;

        totalToShow = t('mass', { "value": { "mass": valueTotal, "unit": "g", fixed: 0 } });
        valueRestToShow = t('mass', { "value": { "mass": valueRest, "unit": "g", fixed: 0 } });
        valueKitchToShow = t('mass', { "value": { "mass": valueKitch, "unit": "g", fixed: 0 } });
        let kitchenPercentage = (valueKitch / valueTotal * 100).toFixed(0);
        let restaurantPercentage = (valueRest / valueTotal * 100).toFixed(0);

        return {
            valueTotal: totalToShow,
            kitchenPercentage: kitchenPercentage,
            valueRestToShow: valueRestToShow,
            valueKitchToShow: valueKitchToShow,
            restaurantPercentage: restaurantPercentage
        }
    }
    const getTooltip = (node) => {
        return (
            <div style={{ height: "5vh", width: "12vw", boxShadow: "0px 4px 4px 0px #00000040", border: `3px solid ${node.color}`, borderRadius: 5, backgroundColor: "#fff", color: "#666666", padding: "4%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {t(`dataTypes.${node.id}`) + " : " + node.data[node.id + "Value"]}
            </div>
        )
    }
    const getColor = (node) => {
        return node.data[node.id + "Color"]
    }
    const getBarRepartition = () => {
        let values;
        if (props.type !== "client")
            values = getValues()
        else
            values = getValuesClient();
        let data = [
            {
                "type": "",
                "restaurant": values.restaurantPercentage,
                "restaurantColor": "#90c261",
                "restaurantValue": values.valueRestToShow,
                "kitchen": values.kitchenPercentage,
                "kitchenColor": "#98D0EE",
                "kitchenValue": values.valueKitchToShow,
            },
        ]
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <div style={{ height: "15%" }} />
                <div style={{ height: "15%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div style={{ height: "50%", width: "50%", display: "flex" }}>
                        <div style={{ width: "3vw", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <div style={{ width: "2.5vw", height: "1.5vh", borderRadius: 3, backgroundColor: "#90c261" }} />
                        </div>
                        <div style={{ width: "60%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            {t('components.wasteRestaurant', { value: values.restaurantPercentage + " %" })}
                        </div>
                    </div>
                    <div style={{ height: "50%", width: "50%", display: "flex", justifyContent: "flex-end" }}>
                        <div style={{ width: "60%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            {t('components.wasteKitchen', { value: values.kitchenPercentage + " %" })}
                        </div>
                        <div style={{ width: "3vw", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <div style={{ width: "2.5vw", height: "1.5vh", borderRadius: 3, backgroundColor: "#98D0EE" }} />
                        </div>
                    </div>
                </div>
                <div style={{ height: "10%" }} />
                <div style={{ height: "50%", width: "100%" }}>
                    <ResponsiveBar
                        data={data}
                        keys={[
                            'restaurant',
                            'kitchen',
                        ]}
                        indexBy="type"
                        enableLabel={false}
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={getColor}
                        tooltip={getTooltip}
                        layout="horizontal"
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.1
                                ]
                            ]
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        borderRadius={5}
                        borderWidth={3}
                        labelTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        role="application"
                        ariaLabel="Nivo bar chart demo"
                        barAriaLabel={e => e.formattedValue + " in country: " + e.indexValue}
                    />

                </div>
            </div>
        )
    }
    return (
        <CustomBlock width={props.width || '40%'} height={'100%'}
            paddingTop={"1%"}
            paddingBottom={"2%"}
            paddingRight={"2%"}
            paddingLeft={"2%"}>
            <div className="dashboard-block-title">
                <div className="top-gaspi-title-div" style={{ width: "100%" }}>
                    {t('components.wasteRepartition')}
                </div>
            </div>
            <div className="dashboard-block-subtitle">
                {t('components.totalValue', { value: props.type !== "client" ? getValues().valueTotal : getValuesClient().valueTotal })}
            </div>
            <div style={{ height: "80%", width: "100%", justifyContent: "center", alignItems: "center", display: "flex" }}>
                {getBarRepartition()}
            </div>
        </CustomBlock>
    )
}
export default Repartition;