import React from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import PieChart from "../../charts/PieChart";

const RepartitionGraph = (props) => {
    const { t } = useTranslation();
    const getColors = () => {
        let areaColor = '';
        let labelColor = '';

        switch (props.state.selectedAnalysisType) {
            case "mass":
                labelColor = '#9FD6C9';
                areaColor = '#72B9A2';
                break;
            case "cost":
                labelColor = '#9DCA98';
                areaColor = '#7EBC77';
                break;
            case "co2":
                labelColor = '#ADD08C';
                areaColor = '#85B35A';
                break;
            case "massByConv":
                labelColor = '#5BB3E4';
                areaColor = '#5BB3E4';
                break;
            default:
                labelColor = '#9FD6C9';
                areaColor = '#72B9A2';
                break;

        }
        return {
            labelColor: labelColor,
            areaColor: areaColor
        }
    }
    const getData = () => {
        let selectedAnalysisType = props.state.selectedAnalysisType;
        let evitable = 0;
        let inevitable = 0;
        let total = 0;
        let evitableTooltip = '';
        let inevitableTooltip = '';
        if (props.analysis) {
            switch (selectedAnalysisType) {
                case "mass":
                    evitable = (props.analysis.mass - props.analysis.inevitableMass);
                    inevitable = props.analysis.inevitableMass;
                    total = evitable + inevitable;
                    evitableTooltip = t('mass', { value: { "mass": evitable, "unit": "kg" } });
                    inevitableTooltip = t('mass', { value: { "mass": inevitable, "unit": "kg" } });
                    break;
                case "cost":
                    evitable = (props.analysis.cost - props.analysis.inevitableCost);
                    inevitable = props.analysis.inevitableCost;
                    total = evitable + inevitable;
                    evitableTooltip = t('currency', { value: { cost: evitable } });
                    inevitableTooltip = t('currency', { value: { cost: inevitable } });
                    break;
                case "co2":
                    evitable = (props.analysis.co2 - props.analysis.inevitableCo2);
                    inevitable = props.analysis.inevitableCo2;
                    total = evitable + inevitable;
                    evitableTooltip = t('mass', { value: { "mass": evitable, "unit": "kg" } });
                    inevitableTooltip = t('mass', { value: { "mass": inevitable, "unit": "kg" } });
                    break;
                case "massByConv":
                    evitable = props.baseAnalysis && (props.baseAnalysis.mass - props.baseAnalysis.inevitableMass) / props.baseAnalysis.trays;
                    inevitable = props.baseAnalysis && props.baseAnalysis.inevitableMass / props.baseAnalysis.trays;
                    total = evitable + inevitable;
                    evitableTooltip = t('mass', { value: { "mass": evitable, "unit": "g" } });
                    inevitableTooltip = t('mass', { value: { "mass": inevitable, "unit": "g" } });
                    break;
                default:
                    evitable = (props.analysis.mass - props.analysis.inevitableMass);
                    inevitable = props.analysis.inevitableMass;
                    total = evitable + inevitable;
                    evitableTooltip = t('mass', { value: { "mass": evitable, "unit": "kg" } });
                    evitableTooltip = t('mass', { value: { "mass": evitable, "unit": "kg" } });
                    break;

            }
        }
        if (props.kitchenAnalysis) {
            switch (selectedAnalysisType) {
                case "mass":
                    evitable += (props.kitchenAnalysis.mass - props.kitchenAnalysis.inevitableMass);
                    inevitable += props.kitchenAnalysis.inevitableMass;
                    total = evitable + inevitable;
                    evitableTooltip = t('mass', { value: { "mass": evitable, "unit": "kg" } });
                    inevitableTooltip = t('mass', { value: { "mass": inevitable, "unit": "kg" } });
                    break;
                case "cost":
                    evitable += (props.kitchenAnalysis.cost - props.kitchenAnalysis.inevitableCost);
                    inevitable += props.kitchenAnalysis.inevitableCost;
                    total = evitable + inevitable;
                    evitableTooltip = t('currency', { value: { cost: evitable } });
                    inevitableTooltip = t('currency', { value: { cost: inevitable } });
                    break;
                case "co2":
                    evitable += (props.kitchenAnalysis.co2 - props.kitchenAnalysis.inevitableCo2);
                    inevitable += props.kitchenAnalysis.inevitableCo2;
                    total = evitable + inevitable;
                    evitableTooltip = t('mass', { value: { "mass": evitable, "unit": "kg" } });
                    inevitableTooltip = t('mass', { value: { "mass": inevitable, "unit": "kg" } });
                    break;
                case "massByConv":
                    evitable += props.baseAnalysisKitch && (props.baseAnalysisKitch.mass - props.baseAnalysisKitch.inevitableMass) / props.baseAnalysisKitch.trays;
                    inevitable += props.baseAnalysisKitch && props.baseAnalysisKitch.inevitableMass / props.baseAnalysisKitch.trays;
                    total = evitable + inevitable;
                    evitableTooltip = t('mass', { value: { "mass": evitable, "unit": "g" } });
                    inevitableTooltip = t('mass', { value: { "mass": inevitable, "unit": "g" } });
                    break;
                default:
                    evitable += (props.kitchenAnalysis.mass - props.kitchenAnalysis.inevitableMass);
                    inevitable += props.kitchenAnalysis.inevitableMass;
                    total = evitable + inevitable;
                    evitableTooltip = t('mass', { value: { "mass": evitable, "unit": "kg" } });
                    evitableTooltip = t('mass', { value: { "mass": evitable, "unit": "kg" } });
                    break;

            }
        }
        let colors = getColors()
        let result = [{
            id: "test1",
            id1: ((inevitable / total) * 100).toFixed(0) + t("components.percentWaste"),
            id2: t('components.unavoidable'),
            value: inevitable,
            color: "#D9D9D9",
            labelColor: "#6D6C7A",
            tooltip: inevitableTooltip
        }, {
            id: "test2",
            id1: ((evitable / total) * 100).toFixed(0) + t("components.percentWaste"),
            id2: t('components.avoidable'),
            value: evitable,
            color: colors.areaColor,
            labelColor: colors.labelColor,
            tooltip: evitableTooltip
        }];
        let percent = (evitable / total * 100).toFixed(0)
        return { result: result, percent: percent };
    }
    const getTotalValue = () => {
        let selectedAnalysisType = props.state.selectedAnalysisType;
        let value = '';
        let total = 0;
        if (props.analysis) {
            switch (selectedAnalysisType) {
                case "mass":
                    total = props.analysis.mass;
                    value = t('mass', { value: { "mass": total, "unit": "kg" } });
                    break;
                case "cost":
                    total = props.analysis.cost;
                    value = t('currency', { value: { cost: total } });
                    break;
                case "co2":
                    total = props.analysis.co2;
                    value = t('mass', { value: { "mass": total, "unit": "kg" } });
                    break;
                case "massByConv":
                    total = props.baseAnalysis && props.baseAnalysis.mass / props.baseAnalysis.trays;
                    value = t('mass', { value: { "mass": total, "unit": "g" } });
                    break;
                default:
                    total = props.analysis.mass;
                    value = t('mass', { value: { "mass": total, "unit": "kg" } });
                    break;

            }
        }
        if (props.has_kitchen && props.kitchenAnalysis) {
            switch (selectedAnalysisType) {
                case "mass":
                    total += props.kitchenAnalysis.mass;
                    value = t('mass', { value: { "mass": total, "unit": "kg" } });
                    break;
                case "cost":
                    total += props.kitchenAnalysis.cost;
                    value = t('currency', { value: { cost: total } });
                    break;
                case "co2":
                    total += props.kitchenAnalysis.co2;
                    value = t('mass', { value: { "mass": total, "unit": "kg" } });
                    break;
                case "massByConv":
                    total += props.baseAnalysisKitch && props.baseAnalysisKitch.mass / props.baseAnalysisKitch.trays;
                    value = t('mass', { value: { "mass": total, "unit": "g" } });
                    break;
                default:
                    total += props.kitchenAnalysis.mass;
                    value = t('mass', { value: { "mass": total, "unit": "kg" } });
                    break;

            }
        }

        return t('components.total') + " : " + value;
    }
    return (
        <CustomBlock width={'100%'} height={'100%'}>
            <div style={{ width: '100%', height: '100%', padding: '3%', paddingBottom: "7%", position: "relative" }}>
                <div className="dashboard-block-title">
                    {t('components.repartition')}
                </div>
                <div className="dashboard-block-subtitle">
                    {getTotalValue()}
                </div>
                <div className="dashboard-graph-body" style={{ zIndex: 100000 }}>
                    <PieChart data={getData().result}
                        innerRadius={0.7}
                        bottom={25} left={5} top={10} right={5} />
                </div>
                <div style={{ width: "25%", height: "20%", top: "48%", left: "38%", position: "absolute", display: "flex", color: getColors().labelColor, flexDirection: "column", justifyContent: "center", alignItems: "center", zIndex: 0 }}>
                    <div style={{ width: "100%", textAlign: "center", fontWeight: "700", fontSize: "1.3vw" }}>
                        {getData().percent + "%"}
                    </div>
                    <div style={{ width: "100%", textAlign: "center" }}>
                        {t('components.avoidableWaste')}
                    </div>
                </div>
            </div>
        </CustomBlock>
    )
}
export default RepartitionGraph;
