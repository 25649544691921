import React, { useEffect, useState } from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomButton from "../../../customComponents/CustomButton";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { ReactComponent as Warning } from "../../../../assets/warning.svg";
import { ReactComponent as FileFood } from "../../../../assets/filefood.svg";
import { ReactComponent as File } from "../../../../assets/file.svg";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { setCurrentPage } from "../../../../actions_reducers/actions/navigation.action";
import { softResetUser } from "../../../../actions_reducers/actions/user.action";
import { getNextAnalysisForRestaurant } from "../../../../apiRoutes/analyzes.routes";

const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const Informations = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentAnalysis, setCurrentAnalysis] = useState(null);
    const [nextAnalysis, setNextAnalysis] = useState(null);
    const [previousAnalysis, setPreviousAnalysis] = useState(null);
    const [isMonthly, setIsMonthly] = useState(false);
    useEffect(() => {
        if (props.analyzesDatas.length > 0 || props.kitchenAnalyzesDatas.length > 0) {
            getNextAnalysis()
            getPreviousAnalysis()
        }
    }, [props.analyzesDatas.length, props.kitchenAnalyzesDatas.length])

    const getPreviousAnalysis = async () => {
        let lastAnalysis = { analysisNumber: 0 };
        for (let analysis of props.analyzesDatas) {
            if (parseInt(analysis.analysisNumber) > parseInt(lastAnalysis.analysisNumber)) {
                lastAnalysis = analysis;
            }
        }
        for (let analysis of props.kitchenAnalyzesDatas) {
            if (analysis.analysisNumber > lastAnalysis.analysisNumber && moment(new Date()).isSameOrAfter(moment(analysis.dateStart))) lastAnalysis = analysis;
        }
        let nextAnalysisNumber = lastAnalysis.analysisNumber - 1;

        let res = await getNextAnalysisForRestaurant(props.userState.selected_restaurant, nextAnalysisNumber);
        if (res && res !== '') {
            setPreviousAnalysis(res);
        }
    }
    const getNextAnalysis = async () => {
        let lastAnalysis = { analysisNumber: 0 };
        for (let analysis of props.analyzesDatas) {
            if (parseInt(analysis.analysisNumber) > parseInt(lastAnalysis.analysisNumber)) {
                lastAnalysis = analysis;
            }
        }
        for (let analysis of props.kitchenAnalyzesDatas) {
            if (analysis.analysisNumber > lastAnalysis.analysisNumber && moment(new Date()).isSameOrAfter(moment(analysis.dateStart))) lastAnalysis = analysis;
        }
        setCurrentAnalysis(lastAnalysis);
        let nextAnalysisNumber = lastAnalysis.analysisNumber + 1;

        let res = await getNextAnalysisForRestaurant(props.userState.selected_restaurant, nextAnalysisNumber);


        if (res && res !== '') {
            setNextAnalysis(res);
            let lastDateLastAnalysis = lastAnalysis.dateEnd;
            if (moment(lastDateLastAnalysis).add(1, 'day').format("YYYY-MM-DD") === res.dates[0])
                setIsMonthly(true)
        }
        else {
            let previousAnalysisNumber = lastAnalysis.analysisNumber - 1;
            let analysis = props.analyzesDatas.find(a => a.analysisNumber === previousAnalysisNumber);
            let kitchenAnalysis = props.kitchenAnalyzesDatas.find(a => a.analysisNumber === previousAnalysisNumber);
            if (analysis) {
                if (moment(analysis.dateEnd).add(1, 'day').format("YYYY-MM-DD") === lastAnalysis.dateStart)
                    setIsMonthly(true)
            }
            else if (kitchenAnalysis) {
                if (moment(kitchenAnalysis.dateEnd).add(1, 'day').format("YYYY-MM-DD") === lastAnalysis.dateStart)
                    setIsMonthly(true)

            }
        }
    }
    const getNextAnalysisDate = () => {
        let nextAnalysisDate = nextAnalysis.dates[0];
        return t('date', { "value": { "date": nextAnalysisDate, "format": "DD MMMM YYYY" } })
    }

    const backToMenu = () => {
        updateArianneThread('files', props.arianne, dispatch, "");
        dispatch(setCurrentPage('files'));
        dispatch(softResetUser())
    }
    const goToFoods = () => {
        updateArianneThread('datasRestaurant', props.arianne, dispatch, '');
        dispatch(setCurrentPage('datasRestaurant'));
    }
    const getNextAnalysisValue = () => {
        if (nextAnalysis && !isMonthly)
            return <>{t('informations.nextAnalysis')}&nbsp;<span style={{ fontWeight: "bold" }}>{getNextAnalysisDate()}</span></>
        else if (nextAnalysis && isMonthly)
            return t('informations.monthlyAnalysis')
        else
            return t('informations.noNextAnalysis')
    }
    const getSynthesisValue = () => {
        if (!isMonthly) {
            if (currentAnalysis && currentAnalysis.isOver)
                return t('informations.synthesisReady')
            else return t('informations.synthesisNotReady')
        }
        else {
            if (previousAnalysis) {
                if (previousAnalysis.isOver) return t('informations.previousSynthesisReady');
                else return t('informations.previousSynthesisNotReady');
            }
            else {
                if (currentAnalysis && currentAnalysis.isOver)
                    return t('informations.synthesisReady')
                else return t('informations.synthesisNotReady')
            }
        }
    }
    const getSynthesisCheck = () => {
        if (!isMonthly) {
            if (currentAnalysis && currentAnalysis.isOver)
                return <Check className="informations-check-style" style={{ paddingtop: "1%" }} />
            else return <Warning className="informations-warning-style" />
        }
        else {
            if (previousAnalysis) {
                if (previousAnalysis.isOver) return <Check className="informations-check-style" style={{ paddingtop: "1%" }} />;
                else return <Warning className="informations-warning-style" />;
            }
            else {
                if (currentAnalysis && currentAnalysis.isOver)
                    return <Check className="informations-check-style" style={{ paddingtop: "1%" }} />
                else return <Warning className="informations-warning-style" />
            }
        }
    }
    return (
        <CustomBlock width={'100%'} height={'38vh'}>
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title" style={{ paddingLeft: "1%" }}>
                    {t('components.informations')}
                </div>
                <div className="dashboard-informations-body">
                    <div className="dashboard-informations-element">
                        <span className="dot" />
                        {getNextAnalysisValue()}
                    </div>
                    <div className="separation-div" />
                    {/*props.has_synthesis &&*/ <div className="dashboard-informations-element">
                        <div className="text-div">
                            <span className="dot" />
                            {getSynthesisValue()}
                        </div>
                        <div className="icon-div">
                            {getSynthesisCheck()}

                        </div>
                    </div>}
                    <div className="separation-div" />
                    <div className="dashboard-informations-element">
                        <div className="text-div">
                            <span className="dot" />
                            {currentAnalysis && currentAnalysis.hasEffectives && currentAnalysis.hasMenus ?
                                t('informations.documentsGood')
                                : t('informations.missingDocuments')
                            }
                        </div>
                        <div className="icon-div">
                            {currentAnalysis && currentAnalysis.hasEffectives && currentAnalysis.hasMenus ?
                                <Check className="informations-check-style" />
                                : <Warning className="informations-warning-style" />}
                        </div>
                    </div>
                    <div style={{ height: "2%" }}></div>
                    <div className="dashboard-informations-button">
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton
                                width={"70%"}
                                height={"70%"}
                                backgroundColor={"#949494"}
                                color={"white"}
                                fontSize={'1vw'}
                                onClick={() => backToMenu()}
                            >
                                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", paddingLeft: "4%", paddingRight: "4%" }}>
                                    <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "2%" }}>
                                        <File height={"90%"} width={"100%"} fill={"#fff"} />
                                    </div>
                                    <div style={{ width: "75%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        {t('buttons.accessFile')}
                                    </div>

                                </div>
                            </CustomButton>
                        </div>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomButton
                                width={"70%"}
                                height={"70%"}
                                backgroundColor={"#949494"}
                                color={"white"}
                                fontSize={'1vw'}
                                onClick={() => goToFoods()}
                            >
                                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", paddingLeft: "4%", paddingRight: "4%" }}>
                                    <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <FileFood />
                                    </div>
                                    <div style={{ width: "75%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        {t('buttons.refineDatas')}
                                    </div>

                                </div>
                            </CustomButton>
                        </div>
                    </div>

                </div>
            </div>
        </CustomBlock>
    )
}

export default Informations;