import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomAutocomplete from "../../../customComponents/CustomAutocomplete";
import CustomButton from "../../../customComponents/CustomButton";
import CustomDatePicker from "../../../customComponents/CustomDatePicker";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import { setAllClientAnalyzes, setSelectedClient } from "../../../../actions_reducers/actions/admin.action";
import { createClientAnalysis, deleteClientAnalysis, updateClientAnalysis } from "../../../../apiRoutes/clientAnalyzes.routes";
import { ReactComponent as Cross } from "../../../../assets/cross.svg";
import { deleteFileForRestaurant, downloadFileRestaurant, getFilesOfRestaurantAndAnalysis, uploadFilesForRestaurant } from "../../../../apiRoutes/files.routes";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const ClientSettings = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const modalDeleteRef = useRef(null)
    const [update, setUpdate] = useState(0);
    const [isModif, setIsModif] = useState(null)
    const [dateStart, setDateStart] = useState(new Date())
    const [dateEnd, setDateEnd] = useState(new Date())
    const [deletedAnalysis, setDeletedAnalysis] = useState(null);
    const [addAnalysis, setAddAnalysis] = useState(false);
    const [newDateStart, setNewDateStart] = useState(new Date());
    const [newDateEnd, setNewDateEnd] = useState(new Date());
    const [file, setFile] = useState(null)

    useEffect(() => {
        getClientSignature()
    }, []);
    const getClientSignature = async () => {
        let url = props.selectedClient + "/"
        let filesList = await getFilesOfRestaurantAndAnalysis(url, "signature");
        if (filesList && filesList[0]) {
            setFile(filesList[0].split(url)[1])
        }
    }
    const getClientList = () => {
        let result = [];
        for (const restaurant of props.informations) {
            if (!result.find(a => a === restaurant.clientName))
                result.push(restaurant.clientName);
        }
        result.sort((a, b) => a.localeCompare(b))
        return result;
    }
    const updateSelectedClient = (e) => {
        dispatch(setSelectedClient(e.target.id))
        setUpdate(old => old + 1)
    }

    const updateSelectedDateStart = (e) => {
        setDateStart(e)
    }
    const updateSelectedDateEnd = (e) => {
        setDateEnd(e)
    }

    const cancelModif = () => {
        setDateStart(new Date());
        setDateEnd(new Date());
        setIsModif(null);
    }
    const confirmModif = async () => {
        if (moment(dateStart).isBefore(moment(dateEnd))) {
            await updateClientAnalysis(props.selectedClient, isModif, moment(dateStart).format("YYYY-MM-DD"), moment(dateEnd).format('YYYY-MM-DD'))

            let analyzes = JSON.parse(JSON.stringify(props.allClientAnalyzes));
            let index = analyzes.findIndex(a => a.analysisNumber === isModif && a.clientName === props.selectedClient)
            analyzes[index].dateStart = moment(dateStart).format("YYYY-MM-DD");
            analyzes[index].dateEnd = moment(dateEnd).format('YYYY-MM-DD');
            dispatch(setAllClientAnalyzes(analyzes))
            cancelModif()
        }
        else {
            window.alert("Veuillez sélectionner des dates valides")
        }
    }
    const confirmDelete = async () => {
        await deleteClientAnalysis(props.selectedClient, deletedAnalysis);
        let analyzes = JSON.parse(JSON.stringify(props.allClientAnalyzes));
        let index = analyzes.findIndex(a => a.analysisNumber === deletedAnalysis && a.clientName === props.selectedClient)
        for (const analysis of analyzes) {
            if (analysis.analysisNumber > deletedAnalysis && analysis.clientName === props.selectedClient)
                analysis.analysisNumber -= 1;
        }
        analyzes.splice(index, 1)
        dispatch(setAllClientAnalyzes(analyzes))
        setDeletedAnalysis(null);
    }
    const confirmAdd = async (newAnalysisNumber) => {
        if (moment(newDateStart).isBefore(moment(newDateEnd))) {
            await createClientAnalysis(props.selectedClient, newAnalysisNumber, moment(newDateStart).format("YYYY-MM-DD"), moment(newDateEnd).format('YYYY-MM-DD'))
            let analyzes = JSON.parse(JSON.stringify(props.allClientAnalyzes));
            analyzes.push({
                analysisNumber: newAnalysisNumber,
                clientName: props.selectedClient,
                dateStart: moment(newDateStart).format("YYYY-MM-DD"),
                dateEnd: moment(newDateEnd).format('YYYY-MM-DD'),
            })
            dispatch(setAllClientAnalyzes(analyzes))
            setAddAnalysis(false)
        }
        else {
            window.alert("Veuillez sélectionner des dates valides")
        }

    }
    const modalAdd = () => {
        let analyzes = JSON.parse(JSON.stringify(props.allClientAnalyzes)).filter((a) => a.clientName === props.selectedClient);
        let newAnalysisNumber = 0;
        for (const analysis of analyzes) {
            if (analysis.analysisNumber > newAnalysisNumber)
                newAnalysisNumber = analysis.analysisNumber;
        }
        newAnalysisNumber += 1;
        if (addAnalysis) {
            return (
                <CustomBlock width={"55%"} height={"50%"} position={"absolute"} zIndex={100000} top={"22%"}  >
                    <div ref={modalDeleteRef} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <div style={{ height: "25%", width: "70%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            {t('components.newAnalysis', { value: deletedAnalysis })}
                        </div>
                        <div style={{ height: "30%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            Du :
                            <CustomDatePicker
                                id={'dropdown-search-selection'}
                                startDate={newDateStart}
                                selected={newDateStart}
                                onChange={(e) => setNewDateStart(e)}
                                backgroundColor={"#f0f0f0"} />
                            <div style={{ width: "5%" }} />
                            Au :
                            <CustomDatePicker
                                id={'dropdown-search-selection'}
                                startDate={newDateEnd}
                                selected={newDateEnd}
                                onChange={(e) => setNewDateEnd(e)}
                                backgroundColor={"#f0f0f0"} />
                        </div>
                        <div style={{ height: "35%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton value={t('buttons.cancel')} backgroundColor={"#666666"} color={"#fff"} width={"80%"} onClick={() => setAddAnalysis(false)} />
                            </div>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton value={t('buttons.confirmButton')} backgroundColor={"#90C261"} color={"#fff"} width={"80%"} onClick={() => confirmAdd(newAnalysisNumber)} />
                            </div>
                        </div>
                    </div>
                </CustomBlock>
            )
        }
    }

    const modalDelete = () => {
        if (deletedAnalysis) {
            return (
                <CustomBlock width={"40%"} height={"40%"} position={"absolute"} zIndex={100000} top={"22%"}  >
                    <div ref={modalDeleteRef} style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <div style={{ height: "55%", width: "70%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            {t('components.deleteAnalysis', { value: deletedAnalysis })}
                        </div>
                        <div style={{ height: "35%", width: "80%", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton value={t('buttons.cancel')} backgroundColor={"#666666"} color={"#fff"} width={"80%"} onClick={() => setDeletedAnalysis(null)} />
                            </div>
                            <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton value={t('buttons.confirmButton')} backgroundColor={"#90C261"} color={"#fff"} width={"80%"} onClick={() => confirmDelete()} />
                            </div>
                        </div>
                    </div>
                </CustomBlock>
            )
        }
    }

    const getRows = () => {
        let analyzes = JSON.parse(JSON.stringify(props.allClientAnalyzes)).filter((a) => a.clientName === props.selectedClient);
        let result = [];
        for (const [index, analysis] of analyzes.sort((a, b) => a.analysisNumber - b.analysisNumber).entries()) {
            result.push(
                <div style={{ width: "100%", height: "12vh", display: "flex", backgroundColor: index % 2 === 0 && "#F0F0F0" }}>
                    <div style={{ width: "30%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {t('arianne.detailedAnalysis', { complementValue: analysis.analysisNumber })}
                    </div>
                    <div style={{ width: "40%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {
                            isModif === analysis.analysisNumber ?
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                    Du :
                                    <CustomDatePicker
                                        width={"20%"}
                                        id={'dropdown-search-selection'}
                                        startDate={dateStart}
                                        selected={dateStart}
                                        onChange={updateSelectedDateStart}
                                        backgroundColor={index % 2 === 0 ? "#fff" : "#F0F0F0"} />
                                    <div style={{ width: "5%" }} />
                                    Au :
                                    <CustomDatePicker
                                        id={'dropdown-search-selection'}
                                        startDate={dateEnd}
                                        selected={dateEnd}
                                        onChange={updateSelectedDateEnd}
                                        backgroundColor={index % 2 === 0 ? "#fff" : "#F0F0F0"} />
                                </div>
                                :
                                t('components.fromTo', { dateStart: moment(analysis.dateStart).format("DD-MM-YYYY"), dateEnd: moment(analysis.dateEnd).format("DD-MM-YYYY") })}
                    </div>
                    <div style={{ width: "30%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {isModif === analysis.analysisNumber ?
                            <CustomButton width={"30%"} height={"40%"} backgroundColor={"#666666"} color={"#fff"} value={t('buttons.cancel')} onClick={() => cancelModif()} />
                            :
                            <CustomButton width={"30%"} height={"40%"} backgroundColor={"#72B9A2"} color={"#fff"} value={t('buttons.modify')} onClick={() => {
                                setIsModif(analysis.analysisNumber)
                                setDateStart(new Date(analysis.dateStart))
                                setDateEnd(new Date(analysis.dateEnd));
                            }} />
                        }
                        <div style={{ width: "10%" }} />
                        {isModif === analysis.analysisNumber ?
                            <CustomButton width={"30%"} height={"40%"} backgroundColor={"#72B9A2"} color={"#fff"} value={t('buttons.confirm')} onClick={() => confirmModif()} />
                            :
                            <CustomButton width={"30%"} height={"40%"} backgroundColor={"#EA881F"} color={"#fff"} value={t('buttons.delete')} onClick={() => setDeletedAnalysis(analysis.analysisNumber)} />
                        }
                    </div>
                </div>
            )
        }
        return result;
    }
    const handleChange = async (e) => {
        e.preventDefault();
        let url = props.selectedClient + "/" + e.target.files[0].name.replace(/ /g, "_");
        await uploadFilesForRestaurant(url, e.target.files[0], null, null, null, "signature");
        setFile(e.target.files[0].name.replace(/ /g, "_"))
    }
    const clickInputUpload = () => {
        document.getElementById("file").click();
    }
    const downloadFile = async (e) => {
        let fileName = file;
        let url = props.selectedClient + "/"
        let path = url + fileName;
        await downloadFileRestaurant(path, fileName, "signature");
    }
    const deleteFile = async () => {
        let url = props.selectedClient + "/"
        await deleteFileForRestaurant(url + file, "signature")
        setFile(null)
    }
    const clientSignature = () => {
        if (!file) {
            return (
                <div style={{ width: "50%", height: "100%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "23%", height: "100%", display: "flex", alignItems: "center" }}>
                        Signature client :
                    </div>
                    <CustomButton width={"30%"} height={"30%"}
                        color="#fff"
                        onClick={() => clickInputUpload()}>
                        Cliquez pour ajouter
                        <input name="file" id="file" type="file" className="files-drop-input" multiple={false} onChange={handleChange} />
                    </CustomButton>
                </div>
            )
        }
        else return (
            <div style={{ width: "50%", height: "100%", display: "flex", alignItems: "center" }}>
                <div style={{ width: "23%", height: "100%", display: "flex", alignItems: "center" }}>
                    Signature client :
                </div>
                <div style={{ width: "auto", height: "25%", display: "flex", alignItems: "center", color: "#007bff", textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => downloadFile()}>
                    {file}
                </div>
                <div style={{ width: "23%", height: "100%", display: "flex", alignItems: "center",paddingTop:"1%" }}>
                    <Cross style={{ width: "50%", height: "50%", cursor: "pointer" }} onClick={() => deleteFile()} />
                </div>

            </div>

        )
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", color: "#666666" }}>
            {modalDelete()}
            {modalAdd()}
            <div style={{ height: "10%", width: "100%" }}>
                <CustomAutocomplete
                    width={'25%'}
                    height={'100%'}
                    backgroundColor={'#68B4E3'}
                    displayedValue={props.selectedClient}
                    selectedValue={props.selectedClient}
                    values={getClientList()}
                    onChange={updateSelectedClient}
                    arrowColor={"#fff"}
                    maxHeightDropdown={"40vh"}
                    color={"#fff"} />
            </div>
            <div style={{ height: "5%" }} />
            <CustomBlock
                width={"100%"}
                height={"85%"}>
                <div style={{ height: "15%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
                    <div style={{ position: "absolute", left: "2%", width: "100%", height: "100%", justifyContent: "flex-start", alignItems: "center", display: "flex", height: "100%" }}>
                        {clientSignature()}
                    </div>
                    {t('table.clientAnalysisPeriod')}
                    <div style={{ position: "absolute", right: "2%", width: "15%", height: "100%", justifyContent: "center", alignItems: "center", display: "flex", height: "100%" }}>
                        <CustomButton width={"80%"} height={"40%"} color={"#fff"} backgroundColor={"#89CD85B3"} value={t('buttons.addAnalysis')} onClick={() => setAddAnalysis(true)} />
                    </div>
                </div>
                <div style={{ height: "85%", width: "100%" }}>
                    <CustomScrollDiv update={update}>
                        {getRows()}
                    </CustomScrollDiv>
                </div>
            </CustomBlock>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        selectedClient: state.adminReducer.selectedClient,
        informations: state.adminReducer.allInformations,
        allClientAnalyzes: state.adminReducer.allClientAnalyzes
    };
}

export default connect(mapStateToProps)(ClientSettings);
