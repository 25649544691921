import { t } from 'i18next';

const getValues = (analysis, kitchenAnalysis) => {
    let mass = 0;
    let trays = 0;
    let massKitchen = 0;
    let traysKitchen = 0;

    if (analysis) {
        mass = parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
        trays = parseInt(analysis.trays);
    }
    if (kitchenAnalysis) {
        massKitchen = parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
        traysKitchen = parseInt(kitchenAnalysis.trays)
    }

    let massConv = trays > 0 ? mass / trays : 0;
    let massConvKitchen = traysKitchen > 0 ? massKitchen / traysKitchen : 0;

    let massTotal = mass + massKitchen;
    let massConvTotal = massConv + massConvKitchen;
    return {
        massTotal: massTotal,
        massConvTotal: massConvTotal
    }
}

const getEvo = (previousMass, currentMass) => {
    let evo = (((parseFloat(currentMass) - parseFloat(previousMass)) / parseFloat(previousMass)) * 100).toFixed(0)
    let evoAbs = Math.abs(evo);
    return {
        evo: evo,
        evoAbs: evoAbs
    }
}

const getTypeEvo = (evo) => {
    if (parseInt(evo) >= 0) return t('synthesis.page2.increase');
    else return t('synthesis.page2.decrease');
}
const getObjectiveValue = (objectives) => {
    let objectif = objectives.find(a => a.title === "Mon objectif n°1");
    let value = 50;
    if (objectif && objectif.reduction) {
        return objectif.reduction;
    }
    else return value;
}
export const getSecondSlide = (pres, t, analysisNumber, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, x, objectives) => {

    let currentValues = getValues(analysis, kitchenAnalysis);
    let previousValues = getValues(previousAnalysis, previousKitchenAnalysis);
    let evo = getEvo(previousValues.massConvTotal, currentValues.massConvTotal);
    let type = getTypeEvo(evo.evo);
    let objectifValue = getObjectiveValue(objectives);
    let valueObj = (previousValues.massConvTotal - previousValues.massConvTotal * objectifValue / 100).toFixed(0);
    let slide = pres.addSlide();
    slide.addImage({ path: require('../assets/2/header.png'), x: "49%", y: "4%", w: "51%", h: "21%" })
    slide.addImage({ path: require('../assets/2/objective_bg.png'), x: "0%", y: "22%", w: "40%", h: "70%" })
    slide.addImage({ path: require('../assets/2/res_circle1.png'), x: "60%", y: "43%", w: "10%", h: "10%" });
    slide.addImage({ path: require('../assets/2/obj_circle1.png'), x: "9%", y: "43%", w: "10%", h: "10%" });
    slide.addImage({ path: require('../assets/2/obj_circle2.png'), x: "9%", y: "56%", w: "10%", h: "10%" });
    slide.addImage({ path: require('../assets/2/res_circle2.png'), x: "60%", y: "56%", w: "10%", h: "10%" });
    slide.addText(t("synthesis.page2.analysisresult", { analysisNumber: analysisNumber }), { x: "58%", y: "10.5%", w: "36%", h: "7%", fontFace: 'Muli', align: "center", color: 'FFFFFF', fontSize: 25, bold: true });
    slide.addText(t("synthesis.page2.result"), { x: "60%", y: "29%", w: "20%", h: "5%", fontFace: 'Muli', align: "center", color: '90C261', fontSize: 28, bold: true });
    slide.addText(t("synthesis.page2.finalgoal"), { x: "10%", y: "29%", w: "20%", h: "5%", fontFace: 'Muli', color: 'F8F8F3', fontSize: 28, bold: true });
    slide.addText(t("synthesis.page2.resultnote", { analysisNumber: analysisNumber }), { x: "60%", y: "34%", w: "20%", align: "center", h: "5%", fontFace: 'Muli', color: '666666', fontSize: 13, bold: true });
    slide.addText(t("synthesis.page2.goalnote", { analysisNumber: analysisNumber - 1 }), { x: "10.8%", y: "34%", w: "17%", h: "5%", fontFace: 'Muli', color: 'F8F8F3', fontSize: 13, bold: true });
    slide.addText(t("synthesis.page2.fromwaste"), { x: "70%", y: "45.7%", w: "20%", h: "5%", fontFace: 'Muli', color: '666666', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page2.fromwaste"), { x: "19%", y: "45.7%", w: "20%", h: "5%", fontFace: 'Muli', color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page2.byguest"), { x: "70%", y: "58.2%", w: "20%", h: "5%", fontFace: 'Muli', color: '666666', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page2.byguest"), { x: "19%", y: "58.2%", w: "20%", h: "5%", fontFace: 'Muli', color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t('mass', { value: { "mass": currentValues.massTotal, "unit": "kg", fixed: 0 } }), { x: "61%", y: "45.4%", w: "9%", h: "5%", align: "center", fontFace: 'Muli', color: 'F8F8F3', fontSize: 20, bold: true });
    slide.addText(t('mass', { value: { "mass": previousValues.massTotal, "unit": "kg", fixed: 0 } }), { x: "10%", y: "45.4%", w: "9%", h: "5%", align: "center", fontFace: 'Muli', color: 'B3D693', fontSize: 20, bold: true });
    slide.addText(t('mass', { value: { "mass": currentValues.massConvTotal, "unit": "g", fixed: 0 } }), { x: "61%", y: "58.4%", w: "9%", h: "5%", align: "center", fontFace: 'Muli', color: 'F8F8F3', fontSize: 20, bold: true });
    slide.addText(t('mass', { value: { "mass": previousValues.massConvTotal, "unit": "g", fixed: 0 } }), { x: "10%", y: "58.4%", w: "9%", h: "5%", align: "center", fontFace: 'Muli', color: 'B3D693', fontSize: 20, bold: true });
    slide.addText(t("synthesis.page2.ordesc3", { type: type }), { x: "58%", y: "71.68%", w: type === t('synthesis.page2.increase') ? "27%" : "22%", h: "5%", fontFace: 'Muli', color: '777777', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page2.ordesc1", { massConv: valueObj }), { x: "15%", y: "78.5%", w: "12%", h: "5%", fontFace: 'Muli', color: 'F8F8F3', fontSize: 14, bold: true });
    slide.addText(t("synthesis.page2.decrease1", { decrease: objectifValue }), { x: "15%", y: "68.5%", w: "15%", h: "8%", fontFace: 'Muli', color: 'F8F8F3', fontSize: 14, bold: true });
    slide.addText(t("synthesis.page2.or2"), { x: "10%", y: "78.4%", w: "6%", h: "5%", fontFace: 'Muli', color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page2.goal"), { x: "7%", y: "70%", w: "9%", h: "5%", fontFace: 'Muli', color: 'F8F8F3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page2.increase1", { percentEvo: evo.evoAbs }), { x: type === t('synthesis.page2.increase') ? "83%" : "79%", y: "71.45%", w: "8%", h: "5%", fontFace: 'Muli', color: '7ABCE2', fontSize: 21, bold: true });

    slide.addText(x, { x: "97%", y: "96%", w: "3%", h: "3%", fontFace: 'Muli', align: "right", color: '666666', fontSize: 15, bold: false });
    slide.background = { color: "#F8F8F3" }

    return pres;
}