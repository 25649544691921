import { ResponsiveBar } from '@nivo/bar';
import './Chart.css';
import { getCategoryColor, getFoodsColors } from '../customComponents/CustomColors';
const BarChart = (props) => {
    const data = props.data;
    const getMax = () => {
        let max = 0;
        for (const data of props.data) {
            Object.keys(data).map(key => {
                if (!key.includes("tray") && data[key] > max && !key.includes("tooltip") && !key.includes("name")&& !key.includes("x"))
                    max = data[key];
            })
            if (data.y > max)
                max = data.y;
            if (data.kitchen && data.kitchen > max)
                max = data.kitchen
        }
        return max + max * 15 / 100;
    }
    const tooltips = { "restaurant": "tooltipRestaurant", "kitchen": "tooltipKitchen" };
    const toolTipElement = (node) => {
        let tooltip = props.keys ? node.data[tooltips[node.id]] : node.data.tooltip;
        if (props.keys && (!props.keys.find(a => a === "restaurant") && !props.keys.find(a => a === "kitchen")) && !props.keys.find(a => a === 'y'))
            tooltip = node.data["tooltip" + node.id]
        else if (props.keys && props.keys.find(a => a === 'y'))
            tooltip = node.data.tooltip
        return (
            <div className="custom-tooltip-pie" style={{
                backgroundColor: props.color || getColor(node),
                width: "130%"
            }}>
                {tooltip}
            </div>
        )
    }
    const colors = { 'restaurant': '#90c261', 'kitchen': '#54b0e3' }
    const getColor = (bar) => {
        if (props.keys && (props.keys.find(a => a === "restaurant") || props.keys.find(a => a === "kitchen")))
            return colors[bar.id]
        else if ((props.keys && !props.keys.find(a => a === "restaurant")) || props.keys === null) {
            if (getCategoryColor(bar.indexValue) || getFoodsColors(bar.indexValue)) {
                if (getCategoryColor(bar.indexValue)) return getCategoryColor(bar.indexValue)
                else return getFoodsColors(bar.indexValue) != null ? getFoodsColors(bar.indexValue) : "#CACACA";
            }
            else if (getCategoryColor(bar.id) || getFoodsColors(bar.id)) {
                if (getCategoryColor(bar.id)) return getCategoryColor(bar.id)
                else return getFoodsColors(bar.id) != null ? getFoodsColors(bar.id) : "#CACACA";
            }
            else
                return "#CACACA"
        }
        else if (props.keys && !props.keys.find(a => a === "trays"))
            return "#CACACA"
        else if (bar.data.color) return bar.data.color
        else return "#CACACA"
    }
    const getXString = (firstString, secondString) => {
        let firstX = "0em";
        let secondX = "0em";
        if (firstString.length <= 5)
            firstX = '-1.4em'
        else {
            if (secondString.length > firstString.length) {
                if (secondString.length >= 10)
                    firstX = "-0.9em"
                else
                    if (firstString.length <= 6) {
                        firstX = "-1em"
                        secondX = "-0.4em"
                    }
                    else
                        firstX = "-0.35em"
            }
            else {
                if (secondString.length >= 10)
                    secondX = "-0.9em"
                else {
                    if (secondString.length > 5) {
                        if (firstString.length <= 6) {
                            firstX = "-1em"
                            secondX = "-0.4em"
                        }
                        else {
                            secondX = "-0.4em";
                        }
                    }
                    else
                        secondX = "-0.8em"
                }

            }
        }
        return {
            firstX: firstX,
            secondX: secondX
        }
    }
    const getTspanGroups = (value) => {
        let spaceIndex = value.toString().indexOf(' ');
        let result = [];
        if (spaceIndex === -1) {
            return <tspan x="0" y="10" style={{ fontSize: "0.65vw", fill: "#666666", fontFamily: "Alexandria" }}>{value}</tspan>
        }
        else {
            let items = value.split(' ');
            let firstString = items[0];
            let secondString = items.slice(1).join(' ')
            result.push(<tspan x={getXString(firstString, secondString).firstX} y="0em" style={{ fontSize: "0.65vw", fill: "#666666", fontFamily: "Alexandria" }}>{firstString}</tspan>)
            result.push(<tspan x={getXString(firstString, secondString).secondX} y="1em" style={{ fontSize: "0.65vw", fill: "#666666", fontFamily: "Alexandria" }}>{secondString}</tspan>)
            return result;
        }
    }

    return (<ResponsiveBar
        data={data}
        id={props.id}
        onClick={props.onClick && props.onClick}
        keys={props.keys || ["y"]}
        theme={{ bars: { cursor: 'pointer' } }}
        indexBy="name"
        margin={{ top: props.top || 30, right: props.right || 50, bottom: props.bottom || 30, left: props.left || 50 }}
        tooltip={(node) => toolTipElement(node)}
        padding={0.3}
        indexScale={{ type: 'band', round: true }}

        valueScale={{
            type: 'linear',
            min: 0,
            max: getMax(),
            stacked: true,
            reverse: false
        }}
        borderRadius={5}
        animate={true}
        enableLabel={false}
        colors={props.color || getColor}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: props.legendRotation || 0,
            legendPosition: 'middle',
            legendOffset: 32,
            format: d => {
                if (props.legendRotation) {
                    if (d.length > 9) {
                        return d.slice(0, 9) + "...";
                    }
                    else return d;
                }
                else return d;
            },

            renderTick: ({
                opacity,
                textAnchor,
                textBaseline,
                textX,
                textY,
                value,
                x,
                y
            }) => {
                if (props.legendRotation) {
                    return (
                        <g
                            transform={`translate(${x},${y})`}
                            style={{ opacity }}
                        >
                            <text
                                alignmentBaseline={textBaseline}
                                textAnchor={textAnchor}
                                style={{ transform: 'rotate(-45deg)' }}
                                transform={`translate(${textX},${textY})`}
                            >
                                {getTspanGroups(value)}
                            </text>
                        </g>
                    )
                }
                else return (
                    <g
                        transform={`translate(${x},${y})`}
                        style={{ opacity }}
                    >
                        <text
                            alignmentBaseline={textBaseline}
                            textAnchor={textAnchor}
                            transform={`translate(${textX},${textY})`}
                        >
                            {getTspanGroups(value)}
                        </text>
                    </g>)
            }
        }}
        groupMode="grouped"
        labelSkipWidth={12}
        labelSkipHeight={12}
    />)
}

export default BarChart;