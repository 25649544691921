
import { useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import CustomButton from "./CustomButton";
import { useTranslation } from "react-i18next";


const CustomTextArea = (props) => {
    const editorRef = useRef(null);
    const { t } = useTranslation();

    const handleEditorChange = () => {
        props.handleChange(editorRef.current.getContent(), props.index, props.type)
    }
    return (
        <div>
            <Editor
                apiKey='i516xh4j331y91nl8trn8x56qxismzegsomjr3c3ibx6ruk3'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={props.value}
                init={{
                    height: props.height,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                        'lists'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'numlist bullist',
                    content_style: 'body { font-family:Alexandria,normal; font-size:14px }',
                    setup: function (editor) {
                        editor.on('keydown', function (e) {
                            if (e.key == 13) {
                                editor.focus();
                                editor.selection.select(editor.getBody(), true);
                                editor.selection.collapse(false);
                            }
                        });
                    }
                }}
            />
            <div style={{ height: "2vh" }} />
            <div style={{ width: "100%", height: "3.5vh", display: "flex", justifyContent: "flex-end" }}>
                <CustomButton width={"40%"} height={"100%"} backgroundColor={"#68B4E3"} color={"#fff"} onClick={() => handleEditorChange()}>{t('buttons.confirmText')}</CustomButton>
            </div>
        </div>
    )
}

export default CustomTextArea;
