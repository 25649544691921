// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import { ResponsivePie } from '@nivo/pie'
import './Chart.css'
import { useTranslation } from 'react-i18next'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const PieChart = (props) => {
    const { t } = useTranslation();
    const getColors = (t) => {
        return t.data.color;
    }
    const toolTipElement = (node) => {
        return (
            <div className="custom-tooltip-pie" style={{
                backgroundColor: node.datum.color
            }}>
                {`${node.datum.data.tooltip}`}
            </div>
        )
    }
    const getLinkLabelSize = () => {
        if (props.data[0].id1 && props.linkSize) {
            let percent = parseInt(props.data[0].id1.split("% Déchets")[0]);
            if (percent < 10)
                return 50;
            else if (percent < 30)
                return 20;
            else if (percent >= 30 && percent < 40)
                return 15;
            else if (percent >= 40 && percent <= 70)
                return 0;
        }
        else return 0;
    }

    return (
        <ResponsivePie
            tooltip={(node) => toolTipElement(node)}
            data={props.data}
            theme={{ slices: { cursor: 'pointer' } }}
            margin={{ top: props.top || 20, right: props.right || 0, bottom: props.bottom || 20, left: props.left || 0 }}
            innerRadius={props.innerRadius || 0.6}
            padAngle={0.7}
            cornerRadius={0}
            activeOuterRadiusOffset={0}
            arcLinkLabelsThickness={2}
            borderWidth={0}
            colors={(t) => getColors(t)}
            arcLinkLabelsStraightLength={getLinkLabelSize() * 1}
            arcLinkLabelsDiagonalLength={10}
            isInteractive={true}
            onMouseEnter={(_data, event) => {
                if (_data.id !== t('foods.Autres'))
                    event.target.style.cursor = 'pointer';
            }}
            onMouseLeave={(_data, event) => {
                event.target.style.cursor = 'default';
            }}
            onClick={props.onClick}
            enableArcLinkLabels={props.hasLegend || false}
            arcLinkLabel={d =>
                <>
                    <tspan x="0" dy="-0.5em" style={{ fontSize: "0.75vw", fill: d.data.labelColor, fontFamily: "Alexandria" }}>{d.data.id1}</tspan>
                    <tspan x={d.arc.startAngle === 0 ? "0.5em" : "-1em"} dy="1em" style={{ fontSize: "0.75vw", fill: d.data.labelColor, fontFamily: "Alexandria" }}>{d.data.id2}</tspan>
                </>
            }
            enableArcLabels={false}
            arcLinkLabelsColor={d => d.data.labelColor}
        />
    )
}

export default PieChart;