import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import MailsClientList from "./MailsClientList";
import { getTemplatesList } from "../../../../apiRoutes/mails.routes";
import { setTemplates } from "../../../../actions_reducers/actions/mails.action";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import { setSelectedClient } from "../../../../actions_reducers/actions/admin.action";

const Mails = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        getMailsList()
    }, [])
    const getMailsList = async () => {
        let list = await getTemplatesList();
        let result = [];
        for (const item of list) {
            for (const version of item.versions) {
                result.push(version);
            }
        }
        dispatch(setTemplates(result));
    }
    const getClientList = () => {
        let result = [];
        for (const restaurant of props.informations) {
            if (!result.find(a => a === restaurant.clientName))
                result.push(restaurant.clientName);
        }
        result.sort((a, b) => a.localeCompare(b))
        return result;
    }

    const updateSelectedClient = (e) => {
        dispatch(setSelectedClient(e.target.id))
    }
    return (
        <div className="main-page-main-div">
            <div style={{ width: "100%", height: "6vh" }}>
                <CustomDropdown
                    id={'client-list-'}
                    width={'25%'}
                    height={'100%'}
                    backgroundColor={'#68B4E3'}
                    displayedValue={props.selectedClient}
                    selectedValue={props.selectedClient}
                    values={getClientList()}
                    onChange={updateSelectedClient}
                    arrowColor={"#fff"}
                    maxHeightDropdown={"40vh"}
                    color={"#fff"} />
            </div>
            <div style={{ height: "4vh" }} />
            <div style={{ height: "75.8vh", width: "100%" }}>
                <MailsClientList
                    informations={props.informations}
                    selectedClient={props.selectedClient}
                    arianne={props.arianne}
                    templates={props.templates}
                    selectedTemplate={props.selectedTemplate}
                    selectedRestaurantMail={props.selectedRestaurantMail}
                    templateHtml={props.templateHtml}
                    mailVariables={props.mailVariables}
                    mailsState={props.mailsState}
                    to={props.to}
                    cc={props.cc}
                    subject={props.subject} />
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userState: state.userReducer,
        informations: state.adminReducer.allInformations,
        selectedClient: state.adminReducer.selectedClient,
        arianne: state.navigationReducer.arianne,
        templates: state.mailsReducer.templates,
        selectedTemplate: state.mailsReducer.selectedTemplate,
        selectedRestaurantMail: state.mailsReducer.selectedRestaurantMail,
        templateHtml: state.mailsReducer.templateHtml,
        mailVariables: state.mailsReducer.mailVariables,
        mailsState: state.mailsReducer,
        to: state.mailsReducer.to,
        cc: state.mailsReducer.cc,
        subject: state.mailsReducer.subject
    };
}

export default connect(mapStateToProps)(Mails);
