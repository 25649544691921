import React, { useEffect, useState } from "react";
import CustomDropdown from "../../customComponents/CustomDropdown";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import 'moment/locale/fr';
import CustomBlock from "../../customComponents/CustomBlock";
import { setAggregateSearch, setCumulativeTotal, setDateEndSearch, setDateStartSearch, setSelectedCategoriesSearch, setSelectedFoodsSearch } from "../../../actions_reducers/actions/search.action";
import { ReactComponent as ArrowDown } from "../../../assets/arrowdown2.svg";
import CustomCheckbox from "../../customComponents/CustomCheckbox";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import CustomDatePicker from "../../customComponents/CustomDatePicker";
import { useRef } from "react";
import SearchTop from "./SearchTop";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const SearchSelection = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);

    const [update, setUpdate] = useState(0);
    const [dropdownAggregateOpen, setDropdownAggregateOpen] = useState(false);
    const [dropdownAlimsOpen, setDropdownAlimsOpen] = useState(false);
    const [categoryOpen, setCategoryOpen] = useState(null);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && event.target.id === "") {
                setDropdownAlimsOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        setUpdate(old => old + 1)
    }, [categoryOpen])
    useEffect(() => {
        if (props.dateStart == null) {
            dispatch(setDateStartSearch(moment(new Date()).format("YYYY-MM-DD")))
        }
        if (props.dateEnd == null) {
            dispatch(setDateEndSearch(moment(new Date()).format("YYYY-MM-DD")))
        }
    }, [props.dateStart, props.dateEnd, dispatch])
    const changeDropdownAggregateOpen = () => setDropdownAggregateOpen(!dropdownAggregateOpen);
    const changeDropdownAlimsOpen = () => {
        setCategoryOpen(null);
        setDropdownAlimsOpen(!dropdownAlimsOpen)
    };

    const updateSelectedDateStart = (e) => {
        let date = moment(e).format("YYYY-MM-DD");
        dispatch(setDateStartSearch(date));
    }

    const updateSelectedDateEnd = (e) => {
        let date = moment(e).format("YYYY-MM-DD");
        dispatch(setDateEndSearch(date));
    }
    const getHighlighted = () => {
        /**/
        let resultRestaurant = [];
        let resultKitchen = [];
        let resultRestaurantKitchen = [];
        let resultRestaurantFinal = [];
        if (props.selectedRoom === "total" || props.selectedRoom === "restaurant")
            for (const analysis of props.analyzes) {
                for (const day of analysis.days) {
                    if (!resultRestaurant.find(a => new Date(a) === new Date(day.date)))
                        resultRestaurant.push(new Date(day.date))
                }
            }
        if (props.selectedRoom === "total" || props.selectedRoom === "kitchen") {
            for (const analysis of props.kitchenDatas) {
                for (const day of analysis.days) {
                    if (!resultKitchen.find(a => new Date(a) === new Date(day.date)))
                        resultKitchen.push(new Date(day.date))
                }
            }
            for (const day of props.all_dates_samples_bin) {
                if (!resultKitchen.find(a => new Date(a) === new Date(day.date)))
                    resultKitchen.push(new Date(day.date))
            }
        }

        if (props.selectedRoom === "total")
            for (const result of resultRestaurant) {
                let indexKitchen = resultKitchen.findIndex(a => moment(a).format("YYYY-MM-DD") === moment(result).format('YYYY-MM-DD'));
                if (indexKitchen !== -1) {
                    resultRestaurantKitchen.push(result);
                    resultKitchen.splice(indexKitchen, 1)
                }
                else resultRestaurantFinal.push(result);
            }
        return [
            {
                "react-datepicker__day--highlighted-custom-restaurant": resultRestaurant,
            },
            {
                "react-datepicker__day--highlighted-custom-kitchen": resultKitchen,
            },
            {
                "react-datepicker__day--highlighted-custom-restaurant-kitchen": resultRestaurantKitchen,
            },
        ];
    }
    const getDropdownsPeriod = () => {
        return (
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'row' }}>
                <div
                    id={'dropdown-search-selection'} style={{ height: "100%", width: "50%", justifyContent: "center", display: "flex", alignItems: "center" }}>

                    <CustomBlock
                        id={'dropdown-search-selection'}
                        width={"80%"}
                        height={"50%"}
                        justifyContent={"center"}
                        display={"flex"}
                        backgroundColor={"#E7F3FA"}
                    >
                        <CustomDatePicker
                            id={'dropdown-search-selection'}
                            startDate={props.dateStart ? new Date(props.dateStart) : new Date()}
                            selected={props.dateStart ? new Date(props.dateStart) : new Date()}
                            onChange={updateSelectedDateStart}
                            highlightRange={getHighlighted()}
                            backgroundColor={"#D9D9D9"} />
                    </CustomBlock>
                </div>
                <div
                    id={'dropdown-search-selection'} style={{ height: "100%", width: "50%", justifyContent: "center", display: "flex", alignItems: "center" }}>

                    <CustomBlock
                        id={'dropdown-search-selection'}
                        width={"80%"}
                        height={"50%"}
                        justifyContent={"center"}
                        alignItems={"#center"}
                        display={"flex"}
                        backgroundColor={"#E7F3FA"}
                    >
                        <CustomDatePicker
                            id={'dropdown-search-selection'}
                            startDate={props.dateEnd ? new Date(props.dateEnd) : new Date()}
                            selected={props.dateEnd ? new Date(props.dateEnd) : new Date()}
                            onChange={updateSelectedDateEnd}
                            highlightRange={getHighlighted()}
                            backgroundColor={"transparent"} />
                    </CustomBlock>
                </div>
            </div>
        )
    }

    const getDisplayedValueAggregate = () => {
        switch (props.aggregate) {
            case 'day':
                return t('components.aggregateDay')
            case "week":
                return t('components.aggregateWeek')
            case 'month':
                return t('components.aggregateMonth')
            case 'year':
                return t('components.aggregateYear')
            case 'analysis':
                return t('components.aggregateAnalysis')
            default:
                return t('components.aggregateAnalysis')
        }
    }

    const updateAggregateValue = (e) => {
        if (e.target.id === t('components.aggregateDay'))
            dispatch(setAggregateSearch('day'));
        else if (e.target.id === t('components.aggregateWeek'))
            dispatch(setAggregateSearch('week'));
        else if (e.target.id === t('components.aggregateMonth'))
            dispatch(setAggregateSearch('month'));
        else if (e.target.id === t('components.aggregateYear'))
            dispatch(setAggregateSearch('year'));
        else if (e.target.id === t('components.aggregateAnalysis'))
            dispatch(setAggregateSearch('analysis'));

    }

    const getDropdownAggregate = () => {
        let values = [
            t('components.aggregateDay'),
            t('components.aggregateWeek'),
            t('components.aggregateMonth'),
            t('components.aggregateYear'),
            t('components.aggregateAnalysis')
        ];
        return (
            <CustomDropdown
                id={'dropdown-search-selection'}
                width={"80%"}
                height={"50%"}
                values={values}
                displayedValue={getDisplayedValueAggregate()}
                color={"#666666"}
                arrowColor={"#68B4E3"}
                backgroundColor={"#E7F3FA"}
                isOpen={dropdownAggregateOpen}
                onClick={() => { setDropdownAlimsOpen(false); setCategoryOpen(null); changeDropdownAggregateOpen() }}
                onChange={updateAggregateValue}
            />
        )

    }

    const getValuesCats = () => {
        let allCategories = [];
        for (const food of props.food_list) {
            let index = allCategories.findIndex(a => a.category === food.categoryFood)
            if (index === -1) {
                let newCat = {
                    category: food.categoryFood,
                    foods: [food.nameFood]
                }
                allCategories.push(newCat);
            }
            else {
                if (!allCategories[index].foods.find(a => a === food.nameFood))
                    allCategories[index].foods.push(food.nameFood)
            }
        }
        if (props.userReducer.has_kitchen) {
            for (const food of props.food_list_kitchen) {
                let index = allCategories.findIndex(a => a.category === food.categoryFood)
                if (index === -1) {
                    let newCat = {
                        category: food.categoryFood,
                        foods: [food.nameFood]
                    }
                    allCategories.push(newCat);
                }
                else {
                    if (!allCategories[index].foods.find(a => a === food.nameFood))
                        allCategories[index].foods.push(food.nameFood)
                }
            }
        }
        allCategories.sort((a, b) => a.category.localeCompare(b.category));
        for (const category of allCategories) {
            category.foods.sort();
            category.foods.unshift(t('components.selectAll'))
        }
        allCategories.unshift({ category: t('components.selectAll') })
        return allCategories;
    }
    const updateCategoryOpen = (e) => {
        if (categoryOpen === null || categoryOpen !== e.target.id)
            setCategoryOpen(e.target.id);
        else
            setCategoryOpen(null);
    }
    const updateCategories = (e) => {
        let value = e.target.id;
        let selectedCategories = JSON.parse(JSON.stringify(props.selectedCategories));
        let allCats = getValuesCats().map(item => item.category)
        let indexAll = allCats.findIndex(a => a === t('components.selectAll'));
        allCats.splice(indexAll, 1)
        if (value !== t('components.selectAll')) {
            let index = selectedCategories.findIndex(a => a === value);
            if (index === -1) {
                selectedCategories.push(value);
            }
            else {
                selectedCategories.splice(index, 1);
            }
            dispatch(setSelectedCategoriesSearch(selectedCategories));
        }
        else {
            if (allCats.length === selectedCategories.length) {
                dispatch(setSelectedCategoriesSearch([]));
            }
            else {
                dispatch(setSelectedCategoriesSearch(allCats));
            }
        }
    }
    const updateFoods = (e) => {
        let value = e.target.id;
        if (value !== '') {
            let selectedFoods = JSON.parse(JSON.stringify(props.selectedFoods));
            let allFoods = getValuesCats().find(a => a.category === categoryOpen).foods.map(item => item);
            let index = selectedFoods.findIndex(a => a === value);
            if (value !== t('components.selectAll')) {
                if (index === -1) {
                    selectedFoods.push(value);
                }
                else
                    selectedFoods.splice(index, 1);
                dispatch(setSelectedFoodsSearch(selectedFoods));
            }
            else {
                let hasAllFoods = true;
                for (const food of allFoods) {
                    if (!selectedFoods.find(a => a === food)) {
                        selectedFoods.push(food);
                        hasAllFoods = false;
                    }
                }
                if (hasAllFoods === true) {
                    for (const food of allFoods) {
                        let index = selectedFoods.findIndex(a => a === food);
                        selectedFoods.splice(index, 1);
                    }
                }
                dispatch(setSelectedFoodsSearch(selectedFoods));
            }
        }
    }
    const checkedFood = (food) => {
        if (food !== t('components.selectAll'))
            return props.selectedFoods.find(a => a === food) != null;
        else if (categoryOpen != null && categoryOpen !== "") {
            let selectedFoods = JSON.parse(JSON.stringify(props.selectedFoods));
            let allFoods = getValuesCats().find(a => a.category === categoryOpen).foods.map(item => item);
            let hasAllFoods = true;
            for (const food of allFoods) {
                if (!selectedFoods.find(a => a === food)) {
                    hasAllFoods = false;
                }
            }
            return hasAllFoods;
        }
    }
    const checkedCat = (cat) => {
        if (cat !== t('components.selectAll'))
            return props.selectedCategories.find(a => a === cat) != null
        else {
            let selectedCats = JSON.parse(JSON.stringify(props.selectedCategories));
            let allCats = getValuesCats().map(item => item.category)
            let indexAll = allCats.findIndex(a => a === t('components.selectAll'));
            allCats.splice(indexAll, 1)
            return selectedCats.length === allCats.length;
        }
    }
    const getDropdownStudiedAlims = () => {
        return (
            <div
                ref={dropdownRef}
                className='custom-dropdown'
                id={'general-dropdown-food'}
                style={{
                    height: "50%",
                    width: "80%",
                    color: "#666666",
                    backgroundColor: "#E7F3FA",
                    zIndex: dropdownAlimsOpen ? 100000 : 1
                }}
                onClick={(e) => { if (e.target.id === "general-dropdown-food") { setDropdownAggregateOpen(false); changeDropdownAlimsOpen() } }}>
                {t('components.selectAlims')}
                <ArrowDown
                    id={'general-dropdown-food'} className='arrow-down-img' fill={"#68B4E3"} />

                < div className="custom-dropdown-choices"
                    style={{
                        display: dropdownAlimsOpen ? 'block' : 'none',
                        backgroundColor: '#E7F3FA',
                    }}>
                    {getValuesCats().map((value) => {
                        return (
                            <div key={'dropdown-food-' + value.category} id={value.category} className="custom-dropdown-choice-div"
                                style={{ display: "flex", flexDirection: "row", height: "4.5vh", backgroundColor: categoryOpen === value.category && "#54b0e3", color: categoryOpen === value.category && "#fff" }}
                            >
                                <div id={value.category} style={{ width: "10%", height: "100%" }} onClick={(e) => updateCategories(e)}>
                                    <CustomCheckbox width={"2vh"} height={"2vh"} checked={checkedCat(value.category)} id={value.category} onChange={updateCategories} />
                                </div>
                                <div id={value.category} style={{ width: "80%", height: "100%" }} onClick={(e) => value.category !== t('components.selectAll') && updateCategoryOpen(e)}>
                                    {value.category !== t('components.selectAll') ? t(`foods.${value.category}`) : t('components.selectAll')}
                                </div>
                                <div id={value.category} style={{ width: "10%", height: "100%" }} onClick={(e) => value.category !== t('components.selectAll') && updateCategoryOpen(e)}>
                                    {value.category !== t('components.selectAll') && <ArrowDown id={value.category} style={{ width: "30%", height: "40%" }} fill={"#68B4E3"} />}
                                </div>
                                <div className="custom-dropdown-choices" style={{
                                    height: "50vh", borderRadius: "5px", color: "#666666", display: categoryOpen === value.category ? "block" : "none", position: "absolute", top: 0, left: "-100%", backgroundColor: "#E7F3FA", width: "100%"
                                }}>
                                    <CustomScrollDiv update={update}>
                                        {value.category !== t('components.selectAll') && value.foods.map((food) => {
                                            return (
                                                <div key={'dropdown-food-' + food} id={value} className="custom-dropdown-choice-div"
                                                    style={{ display: "flex", flexDirection: "row", height: "5vh" }}>
                                                    <div style={{ width: "10%", height: "100%" }} onClick={(e) => updateFoods(e)}>
                                                        <CustomCheckbox width={"2vh"} height={"2vh"} checked={checkedFood(food)} id={food} onChange={updateFoods} disabled={props.cumulativeTotal} />
                                                    </div>
                                                    <div style={{ width: "90%", height: "100%" }}>
                                                        {food !== t('components.selectAll') ? t(`foods.${food}`) : t('components.selectAll')}
                                                    </div>
                                                </div>)
                                        })}
                                    </CustomScrollDiv>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div >
        )
    }
    const getLegendCalendar = () => {
        return (
            <div style={{ width: "100%", height: "100%", position: "absolute", paddingLeft: "2%" }}>
                <div style={{ height: "7.5%" }} />
                <div style={{ height: "7.5%", width: "100%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "10%", height: "80%", backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "2px" }} >
                        <div style={{ height: "70%", width: "90%", backgroundColor: "#90c261" }} />
                    </div>
                    <div style={{ width: "5%" }} />
                    {t('table.restaurant')}
                </div>
                <div style={{ height: "2.5%" }} />
                <div style={{ height: "7.5%", width: "100%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "10%", height: "80%", backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "2px" }} >
                        <div style={{ height: "70%", width: "90%", backgroundColor: "#68B4E3" }} />
                    </div>
                    <div style={{ width: "5%" }} />
                    {t('table.kitchen')}
                </div>
                <div style={{ height: "2.5%" }} />
                <div style={{ height: "7.5%", width: "100%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "10%", height: "80%", backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "2px" }} >
                        <div style={{ height: "70%", width: "90%", backgroundColor: "#D5808D" }} />
                    </div>
                    <div style={{ width: "5%" }} />
                    {t('table.restaurant') + " + " + t('table.kitchen')}
                </div>
                <div style={{ height: "7.5%" }} />
            </div>
        )
    }

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
            <div style={{ width: "30%", height: "80%" }}>
                <CustomBlock width={"100%"} height={"100%"} backgroundColor={"#5BB3E4"} color={"#fff"} paddingTop={"4%"} paddingLeft={"2%"}>
                    <div style={{ height: "10%", width: "100%", display: "flex", alignItems: "center", fontSize: "1.2vw", paddingLeft: "2%", paddingTop: "1%" }}>
                        {t('components.periodOfStudy')}
                    </div>
                    <div style={{ height: "30%" }}>
                        {getLegendCalendar()}
                    </div>
                    <div style={{ height: "60%", width: "100%" }}>
                        {getDropdownsPeriod()}
                    </div>
                </CustomBlock>
            </div>
            <div style={{ width: "5%" }} />
            <div style={{ width: "30%", height: "80%" }}>
                <CustomBlock width={"100%"} height={"100%"} backgroundColor={"#5BB3E4"} color={"#fff"} paddingTop={"4%"} paddingLeft={"2%"}>
                    <div style={{ height: "10%", width: "100%", display: "flex", alignItems: "center", fontSize: "1.2vw", paddingLeft: "2%", paddingTop: "0%" }}>
                        {t('components.aggregate')}
                    </div>
                    <div style={{ height: "20%", width: "100%", paddingLeft: "2%" }}>
                        <div style={{ width: "50%", position: "absolute", top: "22%", display: "flex", fontSize: "1.2vw", alignItems: "center" }}>
                            {t('components.cumulativeTotal')}
                            <CustomCheckbox position={"absolute"} right={"5%"} width={"2vh"} height={"2vh"} checked={props.cumulativeTotal} id={"cumulativeTotal"} onChange={() => dispatch(setCumulativeTotal(!props.cumulativeTotal))} />
                        </div>
                    </div>
                    <div style={{ height: "70%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {getDropdownAggregate()}
                    </div>
                </CustomBlock>
            </div>
            <div style={{ width: "5%" }} />
            <div style={{ width: "30%", height: "80%" }}>
                <div style={{ height: "30%", width: "100%" }}>
                    <SearchTop userReducer={props.userReducer}
                        selectedRoom={props.selectedRoom} selectedService={props.selectedService} selectedCam={props.selectedCam} cams={props.cams} />
                </div>
                <CustomBlock width={"100%"} height={"70%"} backgroundColor={"#5BB3E4"} color={"#fff"} paddingTop={"2%"} paddingLeft={"2%"}>
                    <div style={{ height: "20%", width: "100%", display: "flex", alignItems: "center", fontSize: "1.2vw", paddingLeft: "2%", paddingTop: "1%" }}>
                        {t('components.studiedAlims')}
                    </div>
                    <div style={{ height: "80%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {getDropdownStudiedAlims()}
                    </div>
                </CustomBlock>
            </div>
        </div>
    )
}

export default SearchSelection;