import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomButton from "../../customComponents/CustomButton";
import CustomCheckbox from "../../customComponents/CustomCheckbox";
import { setWasteManagementMethod } from "../../../actions_reducers/actions/datas.action";
import { modifyWasteManagementMethod } from "../../../apiRoutes/informations.routes";

const WasteManagement = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [modify, setModify] = useState(false);
    const [newWasteManagementMethod, setNewWasteManagementMethod] = useState(props.wasteManagementMethod);
    const updateWasteManagementMethod = async () => {
        dispatch(setWasteManagementMethod(newWasteManagementMethod));
        await modifyWasteManagementMethod(props.restaurant_name, newWasteManagementMethod)
        setModify(false);
    }
    
    return (
        <div style={{ width: "100%", height: "100%" }}>
            <div style={{ height: "25%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", fontSize: "clamp(1.3rem,1.3rem,3rem)" }}>{t('components.wasteManagementMethod')}</div>
            <div style={{ height: "5%" }} />
            <div style={{ height: "65%", width: "100%", display: "flex" }}>
                <div style={{ width: "33%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ width: "100%", height: "33%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "45%", display: "flex", justifyContent: "flex-end" }}>
                            <CustomCheckbox disabled={!modify} onChange={() => setNewWasteManagementMethod("incinération")} checked={newWasteManagementMethod === "incinération"} />
                        </div>
                        <div style={{ width: "5%" }} />
                        <div style={{ width: "50%", display: "flex", justifyContent: "flex-start" }}>
                            {t('wasteManagementMethods.incineration')}
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "33%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "45%", display: "flex", justifyContent: "flex-end" }}>
                            <CustomCheckbox disabled={!modify} onChange={() => setNewWasteManagementMethod("enfouissement")} checked={newWasteManagementMethod === "enfouissement"} />
                        </div>
                        <div style={{ width: "5%" }} />
                        <div style={{ width: "50%", display: "flex", justifyContent: "flex-start" }}>
                            {t('wasteManagementMethods.burial')}
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "33%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "45%", display: "flex", justifyContent: "flex-end" }}>
                            <CustomCheckbox disabled={!modify} onChange={() => setNewWasteManagementMethod("alimentation animale")} checked={newWasteManagementMethod === "alimentation animale"} />
                        </div>
                        <div style={{ width: "5%" }} />
                        <div style={{ width: "50%", display: "flex", justifyContent: "flex-start" }}>
                            {t('wasteManagementMethods.animalFeed')}
                        </div>
                    </div>
                </div>
                <div style={{ width: "33%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ width: "100%", height: "33%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "45%", display: "flex", justifyContent: "flex-end" }}>
                            <CustomCheckbox disabled={!modify} onChange={() => setNewWasteManagementMethod("compostage")} checked={newWasteManagementMethod === "compostage"} />
                        </div>
                        <div style={{ width: "5%" }} />
                        <div style={{ width: "50%", display: "flex", justifyContent: "flex-start" }}>
                            {t('wasteManagementMethods.composting')}
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "33%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "45%", display: "flex", justifyContent: "flex-end" }}>
                            <CustomCheckbox disabled={!modify} onChange={() => setNewWasteManagementMethod("méthanisation")} checked={newWasteManagementMethod === "méthanisation"} />
                        </div>
                        <div style={{ width: "5%" }} />
                        <div style={{ width: "50%", display: "flex", justifyContent: "flex-start" }}>
                            {t('wasteManagementMethods.methanization')}
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "33%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    </div>
                </div>
                <div style={{ width: "34%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {
                        modify === false ?
                            <CustomButton width={"30%"} height={"18%"} color={"#fff"} value={t('buttons.modify')} onClick={() => setModify(true)} />
                            :
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                                <CustomButton width={"30%"} height={"18%"} color={"#fff"} backgroundColor={"#72B9A2"} value={t('buttons.confirm')} onClick={() => updateWasteManagementMethod()} />
                                <div style={{ width: "5%" }} />
                                <CustomButton width={"30%"} height={"18%"} color={"#fff"} backgroundColor={"#666666"} value={t('buttons.cancel')} onClick={() => setModify(false)} />
                            </div>
                    }
                </div>
            </div>
            <div style={{ height: "10%" }} />
        </div>
    )
}

export default WasteManagement;