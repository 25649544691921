import React, { useState } from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { ReactComponent as Tray } from "../../../assets/tray.svg";
import { ReactComponent as Euro } from "../../../assets/euro.svg";
import { ReactComponent as Kg } from "../../../assets/kg.svg";
import { ReactComponent as Earth } from "../../../assets/earth.svg";
import { useTranslation } from 'react-i18next';
import { setSelectedAnalysisType } from "../../../actions_reducers/actions/dashboard.action";
import { useDispatch } from "react-redux";
import { setDataTypeClient } from "../../../actions_reducers/actions/client.action";
import { ReactComponent as Information } from "../../../assets/informationButton.svg";

const AnalyzesValues = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selected = props.type === "client" ? props.state.dataTypeClient : props.state.selectedAnalysisType;
    const marginLeft = '3%';
    const width = '24%';
    const height = props.height;
    const [showInfo, setShowInfo] = useState("")

    const getInfo = (type) => {
        return (
            <Information fill={"#FFFFFF"} style={{ width: "2vw", height: "3vh", top: "3%", right: "1%", position: "absolute" }}
                onMouseLeave={() => setShowInfo("")}
                onMouseEnter={() => setShowInfo(type)} />)
    }

    const getValues = () => {
        let mass = 0;
        let cost = 0;
        let co2 = 0;
        let massConvTrays = 0;
        let massKitchen = 0;
        let costKitchen = 0;
        let co2Kitchen = 0;
        let massConvKitch = 0;
        if (props.type !== "client") {
            let analysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber));
            let baseAnalysis = props.baseAnalysis.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber));
            if (analysis) {
                massConvTrays = (parseFloat(baseAnalysis.mass) - parseFloat(baseAnalysis.inevitableMass)) / parseInt(baseAnalysis.trays);
                mass = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)).toFixed(0);
                cost = (parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)).toFixed(0);
                co2 = (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2)).toFixed(0);
            }
            if (props.has_kitchen) {
                let kitchenAnalysis = props.kitchenDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber));
                let baseAnalysisKitch = props.baseAnalysisKitch.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber));
                if (kitchenAnalysis) {
                    massConvKitch = (parseFloat(baseAnalysisKitch.mass) - parseFloat(baseAnalysisKitch.inevitableMass)) / parseInt(baseAnalysisKitch.trays);
                    massKitchen = (parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass)).toFixed(0);
                    costKitchen = (parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost)).toFixed(0);
                    co2Kitchen = (parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2)).toFixed(0);
                }
            }
        }
        else {
            let analysis = props.analyzesDatas;
            let kitchenAnalysis = props.kitchenAnalysis;
            let baseAnalysis = props.baseAnalysis;
            let baseAnalysisKitchen = props.baseAnalysisKitchen;
            if (baseAnalysis && props.showRestaurant)
                massConvTrays = ((parseFloat(baseAnalysis.mass) - parseFloat(baseAnalysis.inevitableMass)) / parseInt(baseAnalysis.trays));
            if (baseAnalysisKitchen && props.showKitchen)
                massConvKitch = ((parseFloat(baseAnalysisKitchen.mass) - parseFloat(baseAnalysisKitchen.inevitableMass)) / parseInt(baseAnalysisKitchen.trays));
            if (analysis && props.showRestaurant) {
                mass = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)).toFixed(0);
                cost = (parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)).toFixed(0);
                co2 = (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2)).toFixed(0);
            }
            if (kitchenAnalysis && props.showKitchen) {
                massKitchen = (parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass)).toFixed(0);
                costKitchen = (parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost)).toFixed(0);
                co2Kitchen = (parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2)).toFixed(0);
            }

        }
        return {
            massByConv: (parseFloat(massConvTrays) + parseFloat(massConvKitch)).toFixed(1),
            mass: parseFloat(mass) + parseFloat(massKitchen),
            cost: parseFloat(cost) + parseFloat(costKitchen),
            co2: parseFloat(co2) + parseFloat(co2Kitchen)
        }
    }
    const updateAnalysisType = (value) => {
        if (props.type !== "client")
            dispatch(setSelectedAnalysisType(value))
        else dispatch(setDataTypeClient(value))
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginLeft: 0, marginTop: '4vh', position: "relative" }}>
            <CustomBlock width={width} height={height} backgroundColor={'#5BB3E4'} hasHover={true} selected={selected === "massByConv"}>
                <div className="dashboard-block-values" onClick={() => updateAnalysisType('massByConv')}>
                    <div className="dashboard-block-values-icon">
                        <Tray style={{ height: '100%', width: '100%' }} />
                    </div>
                    <div className="dashboard-block-values-value">
                        {t('mass', { "value": { "mass": getValues().massByConv, "unit": "g" } })}
                    </div>
                    <div className="dashboard-block-values-legend">
                        {t('components.average')}
                    </div>
                </div>
            </CustomBlock>
            <CustomBlock width={width} height={height} marginLeft={marginLeft} hasHover={true} backgroundColor={'#7DCBB9'} selected={selected === "mass"}>
                {getInfo("mass")}
                <div className="dashboard-block-values" onClick={() => updateAnalysisType('mass')}>
                    <div className="dashboard-block-values-icon">
                        <Kg style={{ height: '100%', width: '100%' }} />
                    </div>
                    <div className="dashboard-block-values-value">
                        {t('mass', { "value": { "mass": getValues().mass, "unit": "kg" } })}
                    </div>
                    <div className="dashboard-block-values-legend" style={{ width: "90%", paddingLeft: "8%" }}>
                        {t('components.wastedTotal')}
                    </div>
                </div>
            </CustomBlock>
            <CustomBlock width={width} height={height} marginLeft={marginLeft} hasHover={true} backgroundColor={'#89CD85'} selected={selected === "cost"}>
                {getInfo("cost")}
                <div className="dashboard-block-values" onClick={() => updateAnalysisType('cost')}>
                    <div className="dashboard-block-values-icon">
                        <Euro style={{ height: '100%', width: '100%' }} />
                    </div>
                    <div className="dashboard-block-values-value">
                        {t('currency', { "value": { "cost": getValues().cost, "fixed": 0 } })}
                    </div>
                    <div className="dashboard-block-values-legend">
                        {t('components.economicLoss')}
                    </div>
                </div>
            </CustomBlock>
            <CustomBlock width={width} height={height} marginLeft={marginLeft} hasHover={true} backgroundColor={'#90c261'} selected={selected === "co2"}>
                {getInfo("co2")}
                <div className="dashboard-block-values" onClick={() => updateAnalysisType('co2')}>
                    <div className="dashboard-block-values-icon">
                        <Earth style={{ height: '100%', width: '100%' }} />
                    </div>
                    <div className="dashboard-block-values-value" style={{ flexDirection: "column" }}>
                        {t('mass', { "value": { "mass": getValues().co2, "unit": "kg" } })}
                        <div style={{ fontSize: "0.8vw", position: "absolute",top:"57%" }}>
                            {t('components.ofCo2')}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend">
                        {t('components.environmentalImpact')}
                    </div>
                </div>
            </CustomBlock>

            {showInfo === "mass" && (
                <div className="window-info" style={{ width: "60%", position: "absolute", bottom: "100%", left: "46%", zIndex: "874656783", backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.bioWasteInfo")}</div>
            )}

            {showInfo === "cost" && (
                <div className="window-info" style={{ width: "60%", position: "absolute", bottom: "100%", left: "72%", zIndex: "874656783", backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.costInfo")}</div>
            )}

            {showInfo === "co2" && (
                <div className="window-info" style={{ width: "60%", position: "absolute", bottom: "100%", left: "97%", zIndex: "874656783", backgroundColor: "#FFFFFF", padding: "10px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.co2Info")}</div>
            )}
        </div>
    )
}

export default AnalyzesValues
