import { getSensiValues } from '../SensiFunctions';


export const getTeenSlide = (pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis) => {
    let slide = pres.addSlide();

    let values = getSensiValues(t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis);
    let decrease = "diminué";
    let massvalue1 = values.currentMassConv;
    let massvalue2 = 3800000;
    let arvalue1 = 3;
    let ardest1 = "Paris";
    let ardest2 = "Nantes";
    let foodsavevalue = 112;
    let litervalue = "224 000";
    let foodmassvalue = 67000;
    let breadmass = 7000;
    let breadpartvalue = 141;
    let poduim3 = 1000;
    let poduim2 = 2000;
    let poduim1 = 3000;
    let datestart = "25/09/2020";
    let dateend = "25/09/2022";
    let value3 = "Restaurantname";

    slide.addImage({ path: require('../sensi_assets/lignearianne.png'), x: "20%", y: "30%", w: "60%", h: "70%" })
    slide.addImage({ path: require('../sensi_assets/sous_titre.png'), x: "43.5%", y: "9.8%", w: "19%", h: "2.25%" })
    slide.addImage({ path: require('../sensi_assets/bulle1.png'), x: "7%", y: "21%", w: "40%", h: "16%" })
    slide.addImage({ path: require('../sensi_assets/bulle2.png'), x: "53%", y: "23%", w: "42%", h: "16%" })
    slide.addImage({ path: require('../sensi_assets/bulle3.png'), x: "8.5%", y: "41%", w: "37%", h: "16%" })
    slide.addImage({ path: require('../sensi_assets/bulle4.png'), x: "54%", y: "43%", w: "39%", h: "15%" })
    slide.addImage({ path: require('../sensi_assets/bulle5.png'), x: "7%", y: "61%", w: "40%", h: "16%" })
    slide.addImage({ path: require('../sensi_assets/bulle6.png'), x: "53%", y: "60.5%", w: "39%", h: "17%" })
    slide.addImage({ path: require('../sensi_assets/bulleccl.png'), x: "8.5%", y: "81%", w: "86%", h: "15%" })
    slide.addImage({ path: require('../sensi_assets/pouce.png'), x: "0%", y: "22%", w: "30%", h: "20%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/nuage.png'), x: "76.5%", y: "15%", w: "25%", h: "15%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/voiture.png'), x: "50.5%", y: "30%", w: "18%", h: "10%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/lasagnes.png'), x: "2.5%", y: "45.7%", w: "23%", h: "12%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/eau.png'), x: "80%", y: "48.5%", w: "15%", h: "10%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/eau.png'), x: "80%", y: "48.5%", w: "15%", h: "10%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/baguettes.png'), x: "3%", y: "66.5%", w: "15%", h: "12%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/jauge.png'), x: "41%", y: "62.35%", w: "5%", h: "15%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/attention.png'), x: "78%", y: "65.5%", w: "15%", h: "10%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/graph3eme.png'), x: "55.5%", y: "72.6%", w: "6.5%", h: "5.3%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/graph1er.png'), x: "63.5%", y: "68.7%", w: "6.5%", h: "9.3%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/graph2eme.png'), x: "71.5%", y: "70.8%", w: "6.5%", h: "7.3%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/Logo.png'), x: "0.5%", y: "94%", w: "14%", h: "6%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/terre.png'), x: "76%", y: "80%", w: "19%", h: "13%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addImage({ path: require('../sensi_assets/icone3eme.png'), x: "55.7%", y: "68.25%", w: "6%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 15, bold: true });
    slide.addImage({ path: require('../sensi_assets/icone1er.png'), x: "64.7%", y: "65.4%", w: "5%", h: "1.75%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 15, bold: true });
    slide.addImage({ path: require('../sensi_assets/icone2eme.png'), x: "71.7%", y: "67.15%", w: "6%", h: "2%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 15, bold: true });

    slide.addText(t('sensibilisation.title1'), { x: "12.5%", y: "3.5%", w: "80%", h: "5%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 35, bold: true });
    slide.addText(t('sensibilisation.title2', { value: decrease }), { x: "21%", y: "7.5%", w: "60%", h: "5%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 35, bold: true });
    slide.addText(t('sensibilisation.title3'), { x: "12%", y: "11.5%", w: "80%", h: "4.5%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 35, bold: true });
    slide.addText(t('sensibilisation.wasteDay'), { x: "6%", y: "18%", w: "35%", h: "4%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 16, bold: true });
    slide.addText(massvalue1, { x: "17.5%", y: "20.5%", w: "32%", h: "10%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 36, bold: true });
    slide.addText(t('mass', { value: { "mass": massvalue2, "unit": "kg", fixed: 0 } }), { x: "45.8%", y: "21.35%", w: "32%", h: "10%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 20, bold: true });
    slide.addText(t('sensibilisation.arcarvalue2', { value: arvalue1 }), { x: "58%", y: "26.85%", w: "32%", h: "10%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 20, bold: true });
    slide.addText(t('sensibilisation.bypeople'), { x: "27%", y: "29%", w: "20%", h: "4%", align: "left", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.Co2equivalent'), { x: "53.5%", y: "22.5%", w: "22%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.Co2equivalent2'), { x: "65%", y: "24.5%", w: "20%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.or'), { x: "66%", y: "27%", w: "10%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 19, bold: true });
    slide.addText(t('sensibilisation.arcarvalue'), { x: "72%", y: "30%", w: "22%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.arcarvalue3', { value_1: ardest1, value_2: ardest2 }), { x: "68.85%", y: "32%", w: "22%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.itstoo'), { x: "9.5%", y: "40.5%", w: "17%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.foodsave'), { x: "29%", y: "40.5%", w: "10%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.breadpartvalue', { value: foodsavevalue }), { x: "11.25%", y: "37.25%", w: "32%", h: "10%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 24, bold: true });
    slide.addText(t('mass', { value: { "mass": foodmassvalue, "unit": "kg", fixed: 0 } }), { x: "12.75%", y: "45.25%", w: "32%", h: "10%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 24, bold: true });
    slide.addText(t('sensibilisation.foodsave2'), { x: "9.25%", y: "42.5%", w: "30%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.Or'), { x: "23%", y: "45.5%", w: "6%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 14, bold: true });
    slide.addText(t('sensibilisation.food'), { x: "23%", y: "51.5%", w: "15%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.And'), { x: "57%", y: "43.5%", w: "6%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.litervalue', { value: litervalue }), { x: "57%", y: "39.9%", w: "32%", h: "10%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 32, bold: true });
    slide.addText(t('sensibilisation.watersave'), { x: "55.4%", y: "45.8%", w: "32%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.watersave2'), { x: "55.8%", y: "47.8%", w: "26%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.watersave3'), { x: "55.8%", y: "49.8%", w: "26%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.breadwastemetter'), { x: "6.5%", y: "61.5%", w: "35%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 18, bold: true });
    slide.addText(t('sensibilisation.or'), { x: "21.5%", y: "64.7%", w: "7%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('mass', { value: { "mass": breadmass, "unit": "kg", fixed: 0 } }), { x: "14%", y: "64.45%", w: "10%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 24, bold: true });
    slide.addText(t('sensibilisation.breadpartvalue', { value: breadpartvalue }), { x: "25.5%", y: "64.45%", w: "10%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 24, bold: true });
    slide.addText(t('sensibilisation.breadparttotrash'), { x: "16.5%", y: "67.5%", w: "25%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.breadparttotrash2'), { x: "17%", y: "69.5%", w: "24%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.breadparttotrash3'), { x: "17.7%", y: "71.5%", w: "12%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: true });
    slide.addText(t('sensibilisation.mostfoodwaste'), { x: "51.5%", y: "60.5%", w: "35%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 18, bold: true });
    slide.addText(t('sensibilisation.mostfoodwaste2'), { x: "52%", y: "62.5%", w: "35%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 18, bold: true });
    slide.addText(t('.'), { x: "53.7%", y: "69.25%", w: "10%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 15, bold: true });
    slide.addText(t('.'), { x: "61.7%", y: "65.25%", w: "10%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 15, bold: true });
    slide.addText(t('.'), { x: "69.7%", y: "67.4%", w: "10%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 15, bold: true });
    slide.addText(t('mass', { value: { "mass": poduim3, "unit": "kg", fixed: 0 } }), { x: "53.7%", y: "71.75%", w: "10%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 15, bold: true });
    slide.addText(t('mass', { value: { "mass": poduim1, "unit": "kg", fixed: 0 } }), { x: "61.7%", y: "67.75%", w: "10%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 15, bold: true });
    slide.addText(t('mass', { value: { "mass": poduim2, "unit": "kg", fixed: 0 } }), { x: "69.7%", y: "69.9%", w: "10%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 15, bold: true });
    slide.addText(t('sensibilisation.whyfightagainstwaste'), { x: "8.5%", y: "81%", w: "60%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 18, bold: true });
    slide.addText(t('sensibilisation.whyfightagainstwaste2'), { x: "8.3%", y: "83%", w: "35%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 18, bold: true });
    slide.addText(t('sensibilisation.explanationdesc'), { x: "9%", y: "86%", w: "70%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: false });
    slide.addText(t('sensibilisation.explanationdesc2'), { x: "9.1%", y: "88%", w: "70%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: false });
    slide.addText(t('sensibilisation.explanationdesc3'), { x: "4.95%", y: "90%", w: "80%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: false });
    slide.addText(t('sensibilisation.explanationdesc4'), { x: "4.3%", y: "92%", w: "60%", h: "4%", align: "center", fontFace: 'Muli', color: 'FFFFFF', fontSize: 16, bold: false });
    slide.addText(t('sensi_adult_day.analysisdate', { value1: datestart, value2: dateend }), { x: "10%", y: "96.15%", w: "60%", h: "5%", align: "center", fontFace: 'Baloo 2 Extrabold', color: '#666666', fontSize: 12, bold: true });
    slide.addText(t('sensibilisation2.value', { value: value3 }), { x: "40%", y: "96.15%", w: "60%", h: "5%", align: "center", fontFace: 'Baloo 2 Extrabold', color: '#666666', fontSize: 12, bold: false });

    slide.background = { color: "#F8F8F3" }

    return pres;
}