import React, { useEffect, useRef } from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import { ReactComponent as Euro } from "../../../../assets/euro.svg";
import { ReactComponent as Kg } from "../../../../assets/kg.svg";
import { ReactComponent as Earth } from "../../../../assets/earth.svg";
import LineChart from "../../../charts/LineChart";
import 'moment/locale/fr';
import CustomDropdown from "../../../customComponents/CustomDropdown";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoadingClient, setSelectedAnalysisNumberClient, setSelectedAnalyzesExcelClient } from "../../../../actions_reducers/actions/client.action";
import { getNumberDaysExtrap } from "../../../customFunctions/extrapolation.function";
import CustomButton from "../../../customComponents/CustomButton";
import { ReactComponent as ArrowDown } from "../../../../assets/arrowdown2.svg";
import CustomCheckbox from "../../../customComponents/CustomCheckbox";
import loading from '../../../../assets/loading.gif'
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import { downloadExcelClient } from "../../../../apiRoutes/files.routes";
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const ClientEvolution = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dropdownRef = useRef(null);
    const [selectedType,] = useState(t('components.totalWasteConv'));
    const [modalExcel, setModalExcel] = useState(false);

    const [dropdownAnalyzesOpen, setdropdownAnalyzesOpen] = useState(false);
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && event.target.type !== "checkbox") {
                setdropdownAnalyzesOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const getMaxValue = () => {
        let max = 1;
        for (const date of props.clientAnalyzesDates) {
            if (date.analysisNumber > max) max = date.analysisNumber
        }
        let hasMax = false;
        if (props.clientAnalyzesDates.length > 0 && props.clientAnalyzes.length > 0)
            while (hasMax === false) {
                let lastDate = props.clientAnalyzesDates.find(a => a.analysisNumber === max);
                let testAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                    moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(lastDate.dateStart)))
                    &&
                    moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(lastDate.dateEnd)))
                )
                let testAnalyzesKitchen = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                    moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(lastDate.dateStart)))
                    &&
                    moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(lastDate.dateEnd)))
                )
                if ((!testAnalyzes || testAnalyzes.length === 0) && (!testAnalyzesKitchen || testAnalyzesKitchen.length === 0)) {
                    max -= 1;
                }
                else hasMax = true;
            }
        return max;
    }
    const getBaseValueRest = (analysisNumber, restaurant_name) => {
        let analysis = props.clientAnalyzesBase.find(a => a.analysisNumber === analysisNumber && a.restaurant_name === restaurant_name);
        let mass = parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
        let trays = parseInt(analysis.trays);
        return {
            mass: mass,
            trays: trays
        };
    }
    const getBaseValueKitch = (analysisNumber, restaurant_name) => {
        let analysis = props.clientKitchenAnalyzesBase.find(a => a.analysisNumber === analysisNumber && a.restaurant_name === restaurant_name);
        let mass = parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
        let trays = parseInt(analysis.trays);
        return {
            mass: mass,
            trays: trays
        };
    }

    const getValuesForAnalyzes = (analyzes, kitchenAnalyzes) => {
        let mass = 0;
        let cost = 0;
        let co2 = 0;
        let trays = 0;
        let massKitchen = 0;
        let costKitchen = 0;
        let co2Kitchen = 0;
        let traysKitchen = 0;
        let traysDayTest = 0;
        let nbDaysTest = 0;
        let traysFinal = 0;
        for (const analysis of analyzes) {
            let averageTrays = props.clientAverageGuests.find(a => a.restaurant_name === analysis.restaurant_name)
            mass = parseFloat(mass) + parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
            cost = parseFloat(cost) + parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost);
            co2 = parseFloat(co2) + parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2);
            trays = parseFloat(trays) + parseFloat(analysis.trays);
            traysDayTest = parseInt(traysDayTest) + parseInt(analysis.trays)
            nbDaysTest = parseInt(nbDaysTest) + parseInt(analysis.dates.length)

            let kitchenAnalysis = kitchenAnalyzes.find(a => a.restaurant_name === analysis.restaurant_name);
            if (kitchenAnalysis) {
                massKitchen = parseFloat(massKitchen) + parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
                costKitchen = parseFloat(costKitchen) + parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost);
                co2Kitchen = parseFloat(co2Kitchen) + parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2);
                traysKitchen = parseFloat(traysKitchen) + parseFloat(kitchenAnalysis.trays);

                traysDayTest = parseInt(traysDayTest) + parseInt(kitchenAnalysis.trays);
                nbDaysTest = parseInt(nbDaysTest) + parseInt(kitchenAnalysis.dates.length)
            }
            traysFinal = parseFloat(traysFinal) + parseInt(traysDayTest) / parseInt(nbDaysTest);
            //traysFinal = parseFloat(traysFinal) + (averageTrays ? averageTrays.value : parseInt(traysDayTest) / parseInt(nbDaysTest));
            traysDayTest = 0;
            nbDaysTest = 0;
        }
        for (const kitchenAnalysis of kitchenAnalyzes) {
            let averageTrays = props.clientAverageGuests.find(a => a.restaurant_name === kitchenAnalysis.restaurant_name)
            if (!analyzes.find(a => a.restaurant_name === kitchenAnalysis.restaurant_name)) {
                massKitchen = parseFloat(massKitchen) + parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
                costKitchen = parseFloat(costKitchen) + parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost);
                co2Kitchen = parseFloat(co2Kitchen) + parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2);
                traysKitchen = parseFloat(traysKitchen) + parseFloat(kitchenAnalysis.trays);

                traysDayTest = parseInt(traysDayTest) + parseInt(kitchenAnalysis.trays);
                nbDaysTest = parseInt(nbDaysTest) + parseInt(kitchenAnalysis.dates.length)
                traysFinal = parseFloat(traysFinal) + parseInt(traysDayTest) / parseInt(nbDaysTest);
                //traysFinal = parseFloat(traysFinal) + (averageTrays ? averageTrays.value : parseInt(traysDayTest) / parseInt(nbDaysTest));
                traysDayTest = 0;
                nbDaysTest = 0;

            }
        }
        let massConv = trays !== 0 ? mass / trays : 0;
        let massConvKitchen = traysKitchen !== 0 ? massKitchen / traysKitchen : 0;
        let costConv = trays !== 0 ? cost / trays : 0;
        let costConvKitchen = traysKitchen !== 0 ? costKitchen / traysKitchen : 0;
        let co2Conv = trays !== 0 ? co2 / trays : 0;
        let co2ConvKitchen = traysKitchen !== 0 ? co2Kitchen / traysKitchen : 0;

        let massConvTot = parseFloat(massConv) + parseFloat(massConvKitchen);
        let costConvTot = parseFloat(costConv) + parseFloat(costConvKitchen);
        let co2ConvTot = parseFloat(co2Conv) + parseFloat(co2ConvKitchen);
        return {
            massConvTot: massConvTot,
            costConvTot: costConvTot,
            co2ConvTot: co2ConvTot,
            traysFinal: traysFinal,
        }
    }
    const getValues = () => {
        let numberDaysExtrap = getNumberDaysExtrap("year")
        let mass1 = 0;
        let cost1 = 0;
        let co21 = 0;
        let mass2 = 0;
        let cost2 = 0;
        let co22 = 0;
        let trays2 = 0;
        let max = getMaxValue();
        for (const date of props.clientAnalyzesDates) {
            let firstAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                date.analysisNumber === props.selectedAnalysisNumber &&
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(date.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(date.dateEnd))));
            let firstKitchenAnalyzes = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                date.analysisNumber === props.selectedAnalysisNumber &&
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(date.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(date.dateEnd))));
            let firstValues = getValuesForAnalyzes(firstAnalyzes, firstKitchenAnalyzes);
            if (firstValues.massConvTot > 0) {
                mass1 = firstValues.massConvTot;
                cost1 = firstValues.costConvTot;
                co21 = firstValues.co2ConvTot;
            }
            let lastAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                date.analysisNumber === max &&
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(date.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(date.dateEnd))));
            let lastKitchenAnalyzes = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                date.analysisNumber === max &&
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(date.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(date.dateEnd))));
            let lastValues = getValuesForAnalyzes(lastAnalyzes, lastKitchenAnalyzes);
            mass2 = lastValues.massConvTot;
            cost2 = lastValues.costConvTot;
            co22 = lastValues.co2ConvTot;
            trays2 = trays2 + lastValues.traysFinal
        }
        let massEco = (mass2 - mass1) * trays2 * numberDaysExtrap
        let costEco = (cost2 - cost1) * trays2 * numberDaysExtrap
        let co2Eco = (co22 - co21) * trays2 * numberDaysExtrap
        return {
            mass: t('mass', { value: { "mass": isNaN(massEco) ? 0 : massEco.toFixed(0), "unit": "kg" } }),
            cost: t('currency', { "value": { "cost": isNaN(costEco) ? 0 : Math.abs(costEco).toFixed(0), "fixed": 0 } }),
            co2: t('mass', { value: { "mass": isNaN(co2Eco) ? 0 : co2Eco.toFixed(0), "unit": "kg" } }) + t('components.ofCo2'),
        }
    }


    const getLabel = (a) => {
        let labels = a.points.map((item, index) => {
            let res = []
            if (index !== 0 && index !== a.points.length - 1) {
                res.push(
                    <svg key={index} width="100%" height={a.innerHeight} className="label-svg">
                        <text
                            id={index}
                            fill={"#666666"}
                            x={item.x}
                            y={item.y - 35} dominantBaseline="middle" textAnchor="middle">{t('mass', { value: { "mass": item.data.yStacked.toFixed(1), "unit": "g" } })}</text>
                        <line className="vertical-line"
                            id={index}
                            stroke={"#949494"}
                            style={{ strokeWidth: 1.5 }}
                            x1={item.x}
                            y1={item.y - 20}
                            y2={a.innerHeight}
                            x2={item.x} />
                        <text
                            id={index}
                            fill={"#666666"}
                            x={item.x}
                            y={a.innerHeight + 20} dominantBaseline="middle" textAnchor="middle">{t('date', { value: { "date": item.data.name, "format": "MM/YY" } })}</text>

                    </svg>
                )
            }
            return res;
        })
        return <g>{labels}</g>
    }
    const getGraphData = () => {
        let max = getMaxValue()
        let result = [
            {
                id: "analyzes_values",
                color: "#68B4E3",
                data: []
            }
        ];
        for (const date of props.clientAnalyzesDates) {
            let analyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(date.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(date.dateEnd)))
            );
            let kitchenAnalyzes = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(date.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(date.dateEnd)))
            );
            let baseMass = 0;
            let baseTrays = 0;
            let baseMassKitch = 0;
            let baseTraysKitch = 0;
            if (analyzes && analyzes[0] && showRestaurant()) {
                for (const analysis of analyzes) {
                    let baseValueRest = getBaseValueRest(analysis.analysisNumber, analysis.restaurant_name);
                    baseMass = parseFloat(baseMass) + parseFloat(baseValueRest.mass)
                    baseTrays = parseFloat(baseTrays) + parseFloat(baseValueRest.trays)
                }
            }
            if (kitchenAnalyzes && kitchenAnalyzes[0] && showKitchen()) {
                for (const kitchenAnalysis of kitchenAnalyzes) {
                    let baseValueKitch = getBaseValueKitch(kitchenAnalysis.analysisNumber, kitchenAnalysis.restaurant_name);
                    baseMassKitch = parseFloat(baseMassKitch) + parseFloat(baseValueKitch.mass)
                    baseTraysKitch = parseFloat(baseTraysKitch) + parseFloat(baseValueKitch.trays)
                }
            }
            let massConv = baseTrays !== 0 && baseMass / baseTrays;
            let massConvKitchen = baseTraysKitch !== 0 && baseMassKitch / baseTraysKitch;
            let data = {
                name: moment(date.dateEnd).format("YYYY-MM-DD"),
                x: date.analysisNumber,
                y: massConv + massConvKitchen
            }
            result[0].data.push(data)
        }
        result[0].data.sort((a, b) => a.x - b.x)
        if (result[0].data[0]) {
            let firstValue = JSON.parse(JSON.stringify(result[0].data[0]));
            firstValue.x = 0;
            let lastValue = JSON.parse(JSON.stringify(result[0].data[result[0].data.length - 1]));
            lastValue.x = max + 1;
            result[0].data.unshift(firstValue);
            result[0].data.push(lastValue);
        }
        return result;
    }
    const getDisplayedExtrapValue = () => {
        return t('components.sinceAnalysis', { analysisNumber: props.selectedAnalysisNumber })
    }
    const getDropdownValues = () => {
        let max = getMaxValue();
        let values = [];
        for (const date of props.clientAnalyzesDates) {
            let analyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(date.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(date.dateEnd)))
            );
            let kitchenAnalyzes = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(date.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(date.dateEnd)))
            );
            if (analyzes && analyzes[0] && showRestaurant()) {
                if (date.analysisNumber !== max) values.push(t('components.analysisName', { analysisNumber: date.analysisNumber }))
            }
            else if (kitchenAnalyzes && kitchenAnalyzes[0] && showKitchen())
                if (date.analysisNumber !== max) values.push(t('components.analysisName', { analysisNumber: date.analysisNumber }))
        }
        values.sort((a, b) => a.localeCompare(b))
        return values;
    }
    const updateValue = (e) => {
        let value = parseInt(e.target.id.split(t('table.analysis'))[1]);
        dispatch(setSelectedAnalysisNumberClient(value))
    }
    const showRestaurant = () => {
        if (selectedType === t('components.totalWasteConv') || selectedType === t('components.restaurantWasteConv'))
            return true;
        else return false;
    }
    const showKitchen = () => {
        if (selectedType === t('components.totalWasteConv') || selectedType === t('components.kitchenWasteConv'))
            return true;
        else return false;

    }
    const handleDownloadExcel = async () => {
        if (props.selected_analyzes.length === 0)
            window.alert(t('errors.atLeastOneAnalysis'))
        else {
            dispatch(setLoadingClient(true));
            let final = JSON.parse(JSON.stringify(props.selected_analyzes))
            let index = final.findIndex(a => a === t('components.selectAll'))
            if (index !== -1) final.splice(index, 1)
            let name = t('components.dataKikleo');
            if (final.length === 1) {
                let analysis = props.clientAnalyzes.find(a => a.analysisNumber === parseInt(final[0]));
                let date = moment(analysis.dates[analysis.dates.length - 1]).add(-1, 'week').format("MM_YYYY");
                name = t('components.dataKikleoMonth', { month: date })
            }
            await downloadExcelClient(props.selectedClient, props.selected_analyzes, name)
            dispatch(setLoadingClient(false));
            dispatch(setSelectedAnalyzesExcelClient([]))
            setModalExcel(false)
        }
    };
    const updateSelectedAnalyzes = (id) => {
        let analyzes = props.clientAnalyzes;
        
        let tmp = JSON.parse(JSON.stringify(props.selected_analyzes))
        if (id !== t('components.selectAll')) {
            let index = tmp.findIndex(a => parseInt(a) === parseInt(id))
            if (index === -1) {
                tmp.push(parseInt(id))
            }
            else {
                tmp.splice(index, 1)
            }
        }
        else {
            if (tmp.length === analyzes.length + 1) {
                tmp = [];
            }
            else {
                for (const analysis of analyzes) {
                    if (!tmp.find(a => parseInt(a) === parseInt(analysis.analysisNumber)))
                        tmp.push(parseInt(analysis.analysisNumber));
                }
                tmp.push(t('components.selectAll'));
            }
        }
        dispatch(setSelectedAnalyzesExcelClient(tmp))
    }
    const getAnalyzesList = () => {
        let analyzes = props.clientAnalyzesDates;
        let values = [];
        for (const analysis of analyzes) {
                values.push({
                    analysisNumber: analysis.analysisNumber,
                    from: analysis.dateStart,
                    to: analysis.dateEnd
                })
        }
        values.sort((a, b) => parseInt(b.analysisNumber) - parseInt(a.analysisNumber))
        values.unshift({ analysisNumber: t('components.selectAll') });
        return (
            <div
                ref={dropdownRef}
                className='custom-dropdown'
                id={'general-dropdown-food'}
                style={{
                    height: "100%",
                    width: "70%",
                    color: "#666666",
                    backgroundColor: "#fff",
                    zIndex: dropdownAnalyzesOpen ? 100000 : 1,
                    border: "1px solid #666666",
                    boxShadow: "none"
                }}
                onClick={(e) => { e.target.type !== "checkbox" && setdropdownAnalyzesOpen(!dropdownAnalyzesOpen) }}>
                {t('components.periods')}
                <ArrowDown
                    id={'general-dropdown-food'} className='arrow-down-img' fill={"#666666"} />

                < div className="custom-dropdown-choices"
                    style={{
                        width: "100%",
                        display: dropdownAnalyzesOpen ? 'block' : 'none',
                        backgroundColor: '#fff',
                        borderRight: "1px solid #666666",
                        borderBottom: "1px solid #666666",
                        borderLeft: "1px solid #666666",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        height: "40vh",
                        paddingTop: "1%",
                        paddingBottom: "1%"
                    }}>
                    <CustomScrollDiv>
                        <div>
                            {values.map((value) => {
                                return (
                                    <div key={'dropdown-food-' + value} id={value.analysisNumber} className="custom-dropdown-choice-div"
                                        style={{
                                            display: "flex", flexDirection: "row", height: "4.5vh", backgroundColor: "#fff", color: "#666666",
                                            borderBottomLeftRadius: "10px",
                                            borderBottomRightRadius: "10px"
                                        }}
                                    >
                                        <div id={value.analysisNumber} style={{ width: "10%", height: "100%" }} onClick={(e) => ""}>
                                            <CustomCheckbox width={"2vh"} height={"2vh"} checked={props.selected_analyzes && props.selected_analyzes.findIndex(a => parseInt(a) === parseInt(value.analysisNumber) || a === t('components.selectAll')) !== -1} id={value.analysisNumber} onChange={(e) => updateSelectedAnalyzes(e.target.id)} />
                                        </div>
                                        <div id={value.analysisNumber} style={{ width: "80%", height: "100%" }} >
                                            {value.analysisNumber !== t('components.selectAll') ?
                                                t(`components.analysisFile`, { analysisNumber: value.analysisNumber, dateStart: t('date', { "value": { "date": value.from, "format": "DD-MM-YYYY" } }), dateEnd: t('date', { "value": { "date": value.to, "format": "DD-MM-YYYY" } }) })
                                                : t('components.selectAll')
                                            }
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </CustomScrollDiv>
                </div>
            </div >

        )
    }
    const getModalExcel = () => {
        if (!props.loadingFiles)
            return (
                <div style={{
                    height: "100vh",
                    width: "100vw",
                    zIndex: 10000000000,
                    position: "fixed",
                    top: 0,
                    right: 0,
                    backgroundColor: "#66666670",
                    display: modalExcel ? "block" : "none"
                }}>
                    <CustomBlock
                        position={"fixed"}
                        width={"50vw"}
                        height={"auto"}
                        backgroundColor={"#fff"}
                        top={"20%"}
                        left={"30.5%"}
                        paddingBottom={"1%"}
                        paddingTop={"1%"}
                        paddingLeft={"2%"}
                        paddingRight={"2%"}
                    >
                        <div style={{ width: "100%", height: "6vh", fontSize: "1.2vw", fontWeight: "500", color: "#666666", justifyContent: "center", display: "flex" }}>
                            {t('components.extractDatas')}
                        </div>
                        <div style={{
                            width: "100%", height: "6vh", color: "#666666", display: "flex",
                            justifyContent: "center"
                        }}>
                            {t('components.selectAnalyzesExtract')}
                        </div>
                        <div style={{
                            height: "6vh", width: "100%", alignSelf: "center", display: "flex",
                            justifyContent: "center"
                        }}>
                            {getAnalyzesList()}
                        </div>
                        <div style={{ height: "3vh" }} />
                        <div style={{ width: "100%", height: "7vh", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center" }}>
                                <CustomButton
                                    width={"50%"}
                                    height={"70%"}
                                    color={"#fff"}
                                    backgroundColor={"#72B9A2"}
                                    onClick={handleDownloadExcel}>
                                    {t('buttons.confirm')}
                                </CustomButton>
                            </div>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center" }}>
                                <CustomButton
                                    width={"50%"}
                                    height={"70%"}
                                    color={"#fff"}
                                    backgroundColor={"#666666"}
                                    onClick={() => {
                                        dispatch(setSelectedAnalyzesExcelClient([]));
                                        setModalExcel(false)
                                    }}>{t('buttons.cancel')}
                                </CustomButton>
                            </div>
                        </div>
                    </CustomBlock>
                </div>
            )
        else
            return (
                <div style={{
                    height: "100vh",
                    width: "100vw",
                    zIndex: 10000000000,
                    position: "fixed",
                    top: 0,
                    right: 0,
                    backgroundColor: "#66666670",
                    display: modalExcel ? "block" : "none"
                }}>
                    <CustomBlock
                        position={"relative"}
                        width={"50vw"}
                        height={"60vh"}
                        backgroundColor={"#fff"}
                        top={"20%"}
                        left={"30.5%"}
                        paddingBottom={"1%"}
                        paddingTop={"1%"}
                        paddingLeft={"2%"}
                        paddingRight={"2%"}
                    >
                        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                            <img src={loading} className="animated-gif" />
                            {t('components.excelGeneration')}
                        </div>
                    </CustomBlock>
                </div>)
    }
    return (
        <CustomBlock width={'100%'} height={'100%'}
            paddingTop={"2%"}
            paddingBottom={"2%"}
            paddingRight={"2%"}
            paddingLeft={"2%"}>
            {getModalExcel()}
            <div style={{ width: "100%", height: "20%", display: "flex", flexDirection: "row" }}>
                <div style={{ width: "60%" }} className="dashboard-block-title">
                    {t('components.evoAnalysisPeriod')}
                </div>
                <div style={{ width: "18%", height: "100%" }}>
                    {props.login === "admin" && <CustomButton
                        width={"100%"}
                        height={"50%"}
                        backgroundColor={'#90c261'}
                        color={"#fff"}
                        fontSize={"clamp(.75rem,.9rem,2rem)"}
                        onClick={() => setModalExcel(true)}
                        value={t('buttons.downloadExcel')}
                    />}
                </div>
                <div style={{ width: "4%" }} />
                <div style={{ width: "18%", height: "100%" }}>
                    <CustomDropdown
                        width={'100%'}
                        height={'50%'}
                        backgroundColor={'#fff'}
                        displayedValue={getDisplayedExtrapValue()}
                        selectedValue={t(`components.analysisName`, { analysisNumber: props.selectedAnalysisNumber })}
                        values={getDropdownValues()}
                        onChange={updateValue}
                        maxHeightDropdown={"30vh"}
                        arrowColor={"#6D6C7A"}
                        color={"#949494"}
                        border={"1px solid #6D6C7A"} />
                </div>
            </div>
            <div style={{ width: "100%", height: "80%", display: "flex", flexDirection: "row" }}>
                <div style={{ height: "100%", width: "50%", display: "flex", alignItems: "center" }}>
                    <div style={{ backgroundColor: "#D9D9D9", height: "100%", width: "90%", borderRadius: "10px", paddingBottom: "2%" }}>
                        <LineChart
                            id={"objectives"}
                            data={getGraphData()}
                            label={getLabel}
                            left={0}
                            right={0}
                            axisLeft={false}
                            color={"#68B4E3"}
                            type={"client"}
                            animate={false}
                        />
                    </div>
                </div>
                <div style={{ width: "50%", height: "100%" }}>
                    <div style={{ width: "100%", height: "18%", display: "flex", justifyContent: "center" }}>
                        <div style={{ width: "65%", textAlign: "center", color: "#666666" }}>
                            {t('components.sinceFirst', { period: t(`periods.year`) })}

                        </div>
                    </div>
                    <div style={{ height: "10%" }} />
                    <div style={{ width: "100%", height: "72%", display: "flex", flexDirection: "row" }}>

                        <CustomBlock
                            backgroundColor={"#7DCBB9"}
                            color={"#fff"}
                            width={"30%"}
                            height={"100%"}
                            paddingRight={"2%"}
                            paddingBottom={"1%"}
                            paddingTop={"1%"}
                            paddingLeft={"2%"}
                        >
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "50%", color: "#fff" }}>
                                <div style={{ width: "45%", height: "100%", display: "flex", alignItems: "center" }}>
                                    <Kg style={{ width: "100%", height: "100%" }} />
                                </div>
                                <div style={{ width: "55%", height: "100%", display: "flex", alignItems: "center", fontSize: "1.3vw", textAlign: "center" }}>
                                    {getValues().mass}
                                </div>
                            </div>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", textAlign: "center", height: "50%", color: "#fff", paddingLeft: "5%", paddingRight: "5%" }}>
                                {t('components.wasteConso')}
                            </div>
                        </CustomBlock>
                        <div style={{ width: "5%" }} />
                        <CustomBlock
                            backgroundColor={"#89CD85"}
                            color={"#fff"}
                            width={"30%"}
                            height={"100%"}
                            paddingRight={"2%"}
                            paddingBottom={"1%"}
                            paddingTop={"1%"}
                            paddingLeft={"2%"}
                        >
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "50%", color: "#fff" }}>
                                <div style={{ width: "35%", height: "100%", display: "flex", alignItems: "center" }}>
                                    <Euro style={{ width: "100%", height: "80%" }} />
                                </div>
                                <div style={{ width: "65%", height: "100%", display: "flex", alignItems: "center", fontSize: "1.3vw", textAlign: "center" }}>
                                    {getValues().cost}
                                </div>
                            </div>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", textAlign: "center", height: "50%", color: "#fff", paddingLeft: "5%", paddingRight: "5%" }}>
                                {t('components.ecoSave')}
                            </div>
                        </CustomBlock>
                        <div style={{ width: "5%" }} />
                        <CustomBlock
                            backgroundColor={"#90C261"}
                            color={"#fff"}
                            width={"30%"}
                            height={"100%"}
                            paddingRight={"2%"}
                            paddingBottom={"1%"}
                            paddingTop={"1%"}
                            paddingLeft={"2%"}
                        >
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: "50%", color: "#fff" }}>
                                <div style={{ width: "30%", height: "100%", display: "flex", alignItems: "center" }}>
                                    <Earth style={{ width: "100%", height: "80%" }} />
                                </div>
                                <div style={{ width: "70%", height: "100%", display: "flex", alignItems: "center", fontSize: "1.3vw", textAlign: "center" }}>
                                    {getValues().co2}
                                </div>
                            </div>
                            <div style={{ width: "100%", display: "flex", alignItems: "center", textAlign: "center", height: "50%", color: "#fff", paddingLeft: "5%", paddingRight: "5%" }}>
                                {t('components.co2Save')}
                            </div>
                        </CustomBlock>
                    </div>
                </div>
            </div>
        </CustomBlock>
    )
}
export default ClientEvolution;