import React, { useEffect, useState } from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import BarChart from "../../../charts/BarChart";
import 'moment/locale/fr';
import { setSelectedCategory } from "../../../../actions_reducers/actions/dashboard.action";
import { getCategoryColor, getFoodsColors } from "../../../customComponents/CustomColors";

const DayGraph = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [colors, setColors] = useState({});

    useEffect(() => {
        if (props.food_list_kitchen) {
            updateColors()
        }
    }, [])
    const getColor = (value) => {
        if (value !== t('foods.Autres')) {
            let color = getFoodsColors(value);
            if (color != null)
                return color;
            else {
                return colors[t(`foods.${value}`)];
            }
        }
        else return "#CACACA";
    }
    const updateColors = () => {
        let tmp = {};
        for (const food of props.food_list_kitchen) {
            let name = t(`foods.${food.nameFood}`);
            if (getColor(name) == null) {
                tmp[name] = getRandomColor()
            }
        }
        setColors(tmp);
    }
    function getRandomColor() {
        return "hsl(" + 360 * Math.random() + ',' +
            (25 + 60 * Math.random()) + '%,' +
            (70 + 5 * Math.random()) + '%)'
    }

    const getTooltip = (name, value) => {
        switch (props.state.selectedAnalysisType) {
            case 'massByConv':
                return name + " : " + t('mass', { "value": { "mass": value, "unit": "g" } });
            case 'mass':
                return name + " : " + t('mass', { "value": { "mass": value * 1000, "unit": "kg", "fixed": 1 } });
            case 'cost':
                return name + " : " + t('currency', { "value": { cost: value } });
            case 'co2':
                return name + " : " + t('mass', { "value": { "mass": value * 1000, "unit": "kg", "fixed": 1 } });
            default:
                return name + t('mass', { "value": { "mass": value, "unit": "g" } });
        }

    }
    const convertMass = (mass) => {
        if (props.userState.massUnit === "lbs") {
            if (Math.abs(mass) / 453.6 > 10000)
                return parseFloat(mass / 6350.29);
            else
                return parseFloat(mass / 453.6);
        }
        else return mass / 1000;
    }
    const getDataValuesInev = (singleData, name, value) => {
        switch (props.state.selectedAnalysisType) {
            case 'massByConv':
                var massByConv = (parseFloat(value.mass)) / (parseFloat(value.trays) ? parseFloat(value.trays) : 1);
                singleData.y = parseFloat(t('mass', { "value": { "mass": massByConv, "unit": "g" } }));
                singleData.tooltip = name + " : " + t('mass', { "value": { "mass": massByConv, "unit": "g" } });
                break;
            case 'mass':
                var mass = (parseFloat(value.mass))
                singleData.y = convertMass(mass);
                singleData.tooltip = name + " : " + t('mass', { "value": { "mass": mass, "unit": "kg", fixed: props.userState.massUnit === "lbs" ? 2 : 1 } });
                break;

        }
        return singleData;
    }
    const getDataValues = (singleData, name, value) => {
        switch (props.state.selectedAnalysisType) {
            case 'massByConv':
                var massByConv = (parseFloat(value.mass) - parseFloat(value.inevitableMass)) / (parseFloat(value.trays) ? parseFloat(value.trays) : 1);

                singleData.y = parseFloat(t('mass', { "value": { "mass": massByConv, "unit": "g" } }));
                singleData.tooltip = name + " : " + t('mass', { "value": { "mass": massByConv, "unit": "g" } });
                break;
            case 'mass':
                var mass = (parseFloat(value.mass) - parseFloat(value.inevitableMass))
                singleData.y = convertMass(mass);
                singleData.tooltip = name + " : " + t('mass', { "value": { "mass": mass, "unit": "kg", fixed: props.userState.massUnit === "lbs" ? 2 : 1 } });
                break;
            case 'cost':
                var cost = parseFloat(value.cost) - parseFloat(value.inevitableCost)
                singleData.y = cost;
                singleData.tooltip = name + " : " + t('currency', { "value": { cost: cost } });
                break;
            case 'co2':
                var co2 = (parseFloat(value.co2) - parseFloat(value.inevitableCo2))
                singleData.y = convertMass(co2);
                singleData.tooltip = name + " : " + t('mass', { "value": { "mass": co2, "unit": "kg", fixed: props.userState.massUnit === "lbs" ? 2 : 1 } });
                break
            case 'trays':
                var trays = parseInt(value.trays)
                singleData.y = parseInt(value.trays);
                singleData.tooltip = name + " : " + trays + " " + t('components.trays');
                break;
            default:
                var defaultValue = (parseFloat(value.mass) - parseFloat(value.inevitableMass)) / parseFloat(value.trays);
                singleData.y = parseFloat(t('mass', { "value": { "mass": defaultValue, "unit": "g" } }));
                singleData.tooltip = name + " : " + t('mass', { "value": { "mass": defaultValue, "unit": "g" } });
                break;
        }
        return singleData;
    }
    const showCat = (catIndex, dayMass, catMass, valueMass) => {
        if (catIndex === -1) {
            if ((valueMass / dayMass) < 0.05) return false;
            else return true;
        }
        else {
            if (valueMass / catMass < 0.05) return false;
            else return true;
        }
    }
    const getData = () => {
        let result = [];
        let values = [];
        if (props.analysis) {
            values = []
            let dayMass = parseFloat(props.analysis.mass) - parseFloat(props.analysis.inevitableMass)
            let catMass = 0;
            let catIndex = props.analysis.categories.findIndex(a => a.category === props.state.selectedCategory);
            if (catIndex === -1 && props.state.selectedCategory == null)
                values = props.analysis.categories;
            else if (catIndex !== -1) {
                catMass = parseFloat(props.analysis.categories[catIndex].mass) - parseFloat(props.analysis.categories[catIndex].inevitableMass)
                values = props.analysis.categories[catIndex].foods;
            }
            for (const value of values) {
                if ((props.state.selectedCategory == null && value.category !== "Déchets inévitables") ||
                    (props.state.selectedCategory != null && props.analysis.categories[catIndex].category !== "Déchets inévitables")) {
                    let valueMass = parseFloat(value.mass) - parseFloat(value.inevitableMass);
                    if (showCat(catIndex, dayMass, catMass, valueMass)) {
                        let baseName = catIndex === -1 ? value.category : value.nameFood;
                        let name = catIndex === -1 ? t(`foods.${value.category}`) : t(`foods.${value.nameFood}`);
                        let color = catIndex === -1 ? getCategoryColor(baseName) : (getFoodsColors(baseName) ? getFoodsColors(baseName) : colors[t(`foods.${value.nameFood}`)]);
                        let singleData = {
                            name: name,
                            x: baseName,
                            y: '',
                            tooltip: '',
                            color: color
                        };
                        singleData = getDataValues(singleData, name, value)
                        result.push(singleData);
                    }
                }
            }
        }
        if (props.has_kitchen && props.kitchenAnalysis) {
            values = []
            let catIndex = props.kitchenAnalysis.categories.findIndex(a => a.category === props.state.selectedCategory);
            if (catIndex === -1 && props.state.selectedCategory == null) {
                values = props.kitchenAnalysis.categories;
            }
            else if (catIndex !== -1 && props.state.selectedCategory != null) {
                values = props.kitchenAnalysis.categories[catIndex].foods;
            }
            for (const value of values) {
                if ((props.state.selectedCategory == null && value.category !== "Déchets inévitables") ||
                    (props.state.selectedCategory != null && props.kitchenAnalysis.categories[catIndex].category !== "Déchets inévitables")) {
                    let baseName = catIndex === -1 ? value.category : value.nameFood;
                    let name = catIndex === -1 ? t(`foods.${value.category}`) : t(`foods.${value.nameFood}`);
                    let color = catIndex === -1 ? getCategoryColor(baseName) : (getFoodsColors(baseName) ? getFoodsColors(baseName) : colors[t(`foods.${baseName}`)]);
                    let index = result.findIndex(a => a.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") === name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
                    let singleData = {
                        name: name,
                        x: baseName,
                        y: '',
                        tooltip: '',
                        color: color
                    };
                    singleData = getDataValues(singleData, name, value)
                    if (index === -1) {
                        result.push(singleData);
                    }
                    else {
                        result[index].y = parseFloat(result[index].y) + parseFloat(singleData.y);
                        result[index].tooltip = getTooltip(name, parseFloat(result[index].y))
                    }
                }
            }
        }
        result.sort((a, b) => b.y - a.y);
        if (props.analysis) {
            values = []
            let catIndex = props.analysis.categories.findIndex(a => a.category === props.state.selectedCategory);
            if (catIndex === -1 && props.state.selectedCategory == null)
                values = props.analysis.categories;
            else if (catIndex !== -1) {
                values = props.analysis.categories[catIndex].foods;
            }
            for (const value of values) {
                if ((props.state.selectedCategory == null && value.category === "Déchets inévitables") ||
                    (props.state.selectedCategory != null && props.analysis.categories[catIndex].category === "Déchets inévitables")) {
                    let baseName = catIndex === -1 ? value.category : value.nameFood;
                    let name = catIndex === -1 ? t(`foods.${value.category}`) : t(`foods.${value.nameFood}`);
                    let color = catIndex === -1 ? getCategoryColor(baseName) : (getFoodsColors(baseName) ? getFoodsColors(baseName) : colors[t(`foods.${value.nameFood}`)]);
                    let singleData = {
                        name: name,
                        x: baseName,
                        y: '',
                        tooltip: '',
                        color: color
                    };
                    singleData = getDataValuesInev(singleData, name, value);
                    result.push(singleData);
                }
            }
        }
        if (props.has_kitchen && props.kitchenAnalysis) {
            values = []
            let catIndex = props.kitchenAnalysis.categories.findIndex(a => a.category === props.state.selectedCategory);
            if (catIndex === -1 && props.state.selectedCategory == null) {
                values = props.kitchenAnalysis.categories;
            }
            else if (catIndex !== -1 && props.state.selectedCategory != null) {
                values = props.kitchenAnalysis.categories[catIndex].foods;
            }
            for (const value of values) {
                if ((props.state.selectedCategory == null && value.category === "Déchets inévitables") ||
                    (props.state.selectedCategory != null && props.kitchenAnalysis.categories[catIndex].category === "Déchets inévitables")) {
                    let baseName = catIndex === -1 ? value.category : value.nameFood;
                    let name = catIndex === -1 ? t(`foods.${value.category}`) : t(`foods.${value.nameFood}`);
                    let color = catIndex === -1 ? getCategoryColor(baseName) : (getFoodsColors(baseName) ? getFoodsColors(baseName) : colors[t(`foods.${value.nameFood}`)]);
                    let index = result.findIndex(a => a.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") === name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
                    let singleData = {
                        name: name,
                        x: baseName,
                        y: '',
                        tooltip: '',
                        color: color
                    };
                    singleData = getDataValuesInev(singleData, name, value)
                    if (index === -1) {
                        result.push(singleData);
                    }
                    else {
                        result[index].y = parseFloat(result[index].y) + parseFloat(singleData.y);
                        result[index].tooltip = getTooltip(name, parseFloat(result[index].y))
                    }
                }
            }

        }
        return result;
    }

    const onClickCat = (e) => {
        let name = e.data.x;
        if (props.state.selectedCategory == null)
            dispatch(setSelectedCategory(name));
    }
    const getMassSelectedAnalysis = () => {
        let mass = 0;
        let co2 = 0;
        if (props.analysis) {
            mass += parseFloat(props.analysis.mass) - parseFloat(props.analysis.inevitableMass)
            co2 += parseFloat(props.analysis.co2) - parseFloat(props.analysis.inevitableCo2)
        }
        if (props.kitchenAnalysis) {
            mass += parseFloat(props.kitchenAnalysis.mass) - parseFloat(props.kitchenAnalysis.inevitableMass)
            co2 += parseFloat(props.kitchenAnalysis.co2) - parseFloat(props.kitchenAnalysis.inevitableCo2)

        }
        return { mass: mass, co2: co2 };
    }
    const getGraphLegend = () => {
        let mass = getMassSelectedAnalysis().mass;
        let co2 = getMassSelectedAnalysis().co2;
        if (props.state.selectedAnalysisType === "cost") return t('graph.cost', { unit: props.userState.costUnit });
        if (props.userState.massUnit === "lbs") {
            if (props.state.selectedAnalysisType === "massByConv") return t('graph.massByConv', { unit: "oz" });
            else if (props.state.selectedAnalysisType === "mass") return t('graph.mass', { unit: mass / 453.6 > 10000 ? "st" : "lbs" });
            else if (props.state.selectedAnalysisType === "co2") return t('graph.co2', { unit: co2 / 453.6 > 10000 ? "st" : "lbs" });
        }
        else {
            if (props.state.selectedAnalysisType === "massByConv") return t('graph.massByConv', { unit: "g" });
            else if (props.state.selectedAnalysisType === "mass") return t('graph.mass', { unit: mass > 10000000 ? "T" : "kg" });
            else if (props.state.selectedAnalysisType === "co2") return t('graph.co2', { unit: co2 > 10000000 ? "T" : "kg" });
        }
    }

    return (
        <CustomBlock width={'100%'} height={'100%'}>
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title" style={{ height: "6%" }}>
                    <div style={{ width: "90%", height: "100%" }}>
                        {t(`components.graph${props.state.selectedAnalysisType}`)}
                    </div>
                    {props.state.selectedCategory &&
                        <div className="top-gaspi-back-div" style={{ width: "10%", height: "100%", display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
                            onClick={() => dispatch(setSelectedCategory(null))}>
                            {t(`buttons.back`)}
                        </div>}
                </div>
                <div style={{ fontSize: ".8vw", color: "#666666" }}>
                    {getGraphLegend()}
                </div>
                <div className="dashboard-graph-body">
                    <BarChart id={"dayGraph"} data={getData()} top={15} left={50} right={40} bottom={60} onClick={onClickCat} legendRotation={-45} />
                </div>
            </div>
        </CustomBlock>
    )
}
export default DayGraph;