import React, { useState, useEffect } from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { getTraysWithSamplesForDate } from "../../../../apiRoutes/trays.route";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import CustomDropdown from "../../../customComponents/CustomDropdown";

import 'moment/locale/fr';
import { getFoodName } from "../../../customFunctions/food.functions";
import { setPicturesListClient } from "../../../../actions_reducers/actions/client.action";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);


const ClientPicturesShowed = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [numberPages, setNumberPages] = useState(1);
    const [selectedPage, setSelectedPage] = useState(1);
    const [update, setUpdate] = useState(0);
    const [selectedFood, setSelectedFood] = useState(t("foods.Tous les aliments"));

    useEffect(() => {
        let picturesDay = props.picturesListClient.find(a => a.date === props.selectedDate && a.restaurant_name === props.selectedRestaurantPictures);
        if (!picturesDay)
            getPicturesForDate();
        else setNumberPages(picturesDay.numberPages);
    }, [props.selectedDate])
    const getPicturesForDate = async () => {
        let restaurant_name = props.selectedRestaurantPictures;
        let pictures = await getTraysWithSamplesForDate(restaurant_name, moment(props.selectedDate, "DD-MM-YYYY").format('YYYY-MM-DD'));
        let tmpNumberPages = Math.floor(pictures.length / 30) + (pictures.length % 30 !== 0 ? 1 : 0)
        let foodsOfDay = getAlimList(pictures);
        let datePictures = {
            restaurant_name: props.selectedRestaurantPictures,
            date: props.selectedDate,
            pictures: pictures.sort((a, b) => b.volume - a.volume),
            numberPages: tmpNumberPages,
            foodsOfDay: foodsOfDay
        };
        let allDatesPictures = JSON.parse(JSON.stringify(props.picturesListClient));
        allDatesPictures.push(datePictures);
        setUpdate(old => old + 1)
        setNumberPages(tmpNumberPages);
        dispatch(setPicturesListClient(allDatesPictures))
    }
    const getShowedPictures = () => {
        let result = [];
        let tmpDiv = [];
        const picturesDay = props.picturesListClient.find(a => a.date === props.selectedDate && a.restaurant_name === props.selectedRestaurantPictures);
        if (picturesDay && picturesDay.pictures) {
            let picturesFinal = picturesDay.pictures;
            if (selectedFood !== t("foods.Tous les aliments"))
                picturesFinal = picturesFinal.filter(a => a.samples.find(b => getFoodName(props.food_list, b.aliment_id) === selectedFood))
            for (let i = 0; i < picturesFinal.length; i++) {
                let picture = picturesFinal[i];
                tmpDiv.push(<img className="dashboard-picture" src={picture.url} />)
            }
            let size = 3;
            for (let i = 0; i < tmpDiv.length; i += size) {
                if (i < 30 * parseInt(selectedPage) && i >= 30 * (parseInt(selectedPage) - 1)) {
                    result.push(<div className="dashboard-picture-div">{tmpDiv.slice(i, i + size)}</div>);
                }
            }
        }
        return { result: result, tmpDiv: tmpDiv };
    }

    useEffect(() => {
        let length = getShowedPictures().tmpDiv.length
        let tmpNumberPages = Math.floor(length / 10) + (length % 10 !== 0 ? 1 : 0);
        setNumberPages(tmpNumberPages);
    }, []);

    const updateSelectedPage = (id) => {
        if (id !== '...') {
            setSelectedPage(id);
            setUpdate(old => old + 1)
        }
    }
    const nextSelectedPage = () => {
        setUpdate(old => old + 1)
        if (parseInt(selectedPage) < parseInt(numberPages))
            setSelectedPage(old => parseInt(old) + 1);

    }
    const prevSelectedPage = () => {
        setUpdate(old => old + 1)
        if (selectedPage > 1)
            setSelectedPage(old => parseInt(old) - 1);

    }
    const getPageSelection = (numberPages) => {
        let items = [selectedPage];
        const tmpPage = parseInt(selectedPage);
        if (tmpPage === 1) {
            if (numberPages > 1)
                items.push(2);
            if (numberPages > 3)
                items.push(3);
            if (numberPages > 4)
                items.push('...');
        }
        else if (tmpPage === 2) {
            if (numberPages > 2)
                items.push(3)
            if (numberPages > 3)
                items.push('...')
            items.unshift(1)
        }
        else if (tmpPage === numberPages) {
            if (numberPages > tmpPage - 1)
                items.unshift(tmpPage - 1)
            if (numberPages > tmpPage - 2)
                items.unshift(tmpPage - 2)
            if (numberPages > tmpPage - 3)
                items.unshift('...')
        }
        else {
            if (numberPages > tmpPage)
                items.push(tmpPage + 1);
            if (numberPages > tmpPage + 1)
                items.push('...');
            items.unshift(tmpPage - 1);
            items.unshift('...');
        }
        let result = [<div className="dashboard-pictures-number-div" onClick={() => prevSelectedPage()}>{"<"}</div>];
        for (const item of items) {
            result.push(
                <div id={item} className="dashboard-pictures-number-div"
                    style={{
                        fontWeight: parseInt(item) === parseInt(selectedPage) ? 600 : 300,
                        textDecoration: parseInt(item) === parseInt(selectedPage) ? "underline" : "none"
                    }}
                    onClick={(e) => updateSelectedPage(e.target.id)}>{item}</div>
            )
        }
        result.push(<div className="dashboard-pictures-number-div" onClick={() => nextSelectedPage()}>{">"}</div>);
        return result;
    }
    const changeFood = (e) => {
        setUpdate(old => old + 1)
        setSelectedFood(e.target.id);
        let pictures = JSON.parse(JSON.stringify(props.picturesListClient)).find(a => a.date === props.selectedDate && a.restaurant_name === props.selectedRestaurantPictures).pictures;
        if (e.target.id !== "Tous les aliments") {
            pictures = pictures.filter(a => a.samples.find(b => getFoodName(props.food_list, b.aliment_id) === e.target.id))
        }
        let tmpNumberPages = Math.floor(pictures.length / 30) + (pictures.length % 30 !== 0 ? 1 : 0);
        if (tmpNumberPages === 0) tmpNumberPages = 1;
        setNumberPages(parseInt(tmpNumberPages));
        setSelectedPage(1);
    }
    const getAlimentIdOfDay = (pictures) => {
        let result = [];
        for (const picture of pictures) {
            for (const sample of picture.samples) {
                if (!result.find(a => a === sample.aliment_id))
                    result.push(sample.aliment_id);
            }
        }
        return result;
    }
    const getAlimList = (pictures) => {
        let result = [];
        const alimentIds = getAlimentIdOfDay(pictures);
        for (const id of alimentIds) {
            let food = props.food_list.find(a => parseInt(a.idFood) === parseInt(id));
            if (food && food.categoryFood)
                result.push(food.nameFood);
        }
        result.sort((a, b) => a.localeCompare(b))
        result.unshift(t("foods.Tous les aliments"));
        return result;

    }
    const getSelectedAlim = () => {
        const picturesDay = props.picturesListClient.find(a => a.date === props.selectedDate);
        if (picturesDay && picturesDay.foodsOfDay) {
            return <CustomDropdown
                width={"70%"}
                height={"70%"}
                backgroundColor={"#fff"}
                color={"#6D6C7A"}
                arrowColor={"#6D6C7A"}
                displayedValue={selectedFood}
                selectedValue={selectedFood}
                values={picturesDay.foodsOfDay}
                maxHeightDropdown={"35vh"}
                dropdownUpdate={update}
                onChange={changeFood}
                border={"1px solid #6D6C7A"}
            />
        }
    }

    return (
        <CustomBlock width={'100%'} height={'100%'}>
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "8%" }}>
                    <div className="dashboard-block-title" style={{ width: "30%", flexDirection: "column" }}>
                        {t('components.pictures')}
                        <div className="dasboard-picture-select-page-div">
                            {getPageSelection(numberPages)}
                        </div>
                    </div>
                    <div style={{ width: "40%", height: "100%", display: "flex", justifyContent: "center" }}>

                    </div>
                    <div style={{ width: "30%", height: "100%", display: "flex", justifyContent: "flex-end" }}>
                        {getSelectedAlim()}
                    </div>
                </div>
                <div className="dashboard-pictures-body">
                    <CustomScrollDiv update={Math.random()}>
                        {getShowedPictures().result}
                    </CustomScrollDiv>
                </div>
            </div>
        </CustomBlock>
    )
}
export default ClientPicturesShowed;