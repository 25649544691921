import { getCost } from '../SensiFunctions';
import { getCo2Eq } from '../SynthesisFunctions';

const getValues = (analysis, kitchenAnalysis, food_list, food_list_kitchen, costType) => {
    let massRestaurant = 0;
    let massKitchen = 0;
    let traysRestaurant = 0;
    let traysKitchen = 0;
    let co2Tot = 0;
    let costTot = 0;
    let mealsTotal = 0;
    let allDays = []

    if (analysis) {
        massRestaurant = parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
        traysRestaurant = analysis.trays;
        co2Tot = parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2);
        costTot = costType === "commodity_cost" ? getCost(analysis, food_list) : parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost);
        for (const day of analysis.days) {
            if (!allDays.find(a => a.date === day.date))
                allDays.push(day)
        }
    }
    if (kitchenAnalysis) {
        massKitchen = parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
        traysKitchen = kitchenAnalysis.trays;
        co2Tot = parseFloat(co2Tot) + parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2);
        costTot = costType === "commodity_cost" ? parseFloat(costTot) + getCost(kitchenAnalysis, food_list_kitchen) : parseFloat(costTot) + parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost);

        for (const day of kitchenAnalysis.days) {
            if (!allDays.find(a => a.date === day.date))
                allDays.push(day)
        }
    }
    for (const day of allDays) {
        mealsTotal = parseInt(mealsTotal) + parseInt(day.trays);
    }
    let massTot = parseFloat(massRestaurant) + parseFloat(massKitchen);
    let meals = parseInt(massTot / 600);
    let massConv = traysRestaurant > 0 ? massRestaurant / traysRestaurant : 0;
    let massConvKitchen = traysKitchen > 0 ? massKitchen / traysKitchen : 0;
    let massConvTot = parseFloat(massConv) + parseFloat(massConvKitchen)
    return {
        massTot: massTot,
        massConvTot: massConvTot,
        costTot: costTot,
        co2Tot: co2Tot,
        meals: meals,
        mealsTotal: mealsTotal,
    }
}

const getAnalysisNameValue = (value) => {
    if (parseInt(value) === 1) {
        return value + "ᵉʳᵉ"
    }
    else return value + "ᵉᵐᵉ"
}
export const getThirdSlide = (pres, t, analysisNumber, analysis, kitchenAnalysis, x, food_list, food_list_kitchen, costType, synthesisName) => {
    let slide = pres.addSlide();
    let values = getValues(analysis, kitchenAnalysis, food_list, food_list_kitchen, costType)
    let co2Eq = getCo2Eq(values.co2Tot);
    let elem1 = document.getElementById('speedometerToImpres' + analysisNumber)
    let elem = elem1.getElementsByClassName('speedometer');
    const s = new XMLSerializer().serializeToString(elem[0].firstChild)
    const encodedData = window.btoa(s);

    slide.addImage({ path: require('../assets/3/header.png'), x: "0%", y: "4%", w: "51%", h: "21%" })
    slide.addImage({ path: require('../assets/3/bubble_bg.png'), x: "60%", y: "4%", w: "40%", h: "70%" })
    slide.addImage({ path: require('../assets/3/graph_bg.png'), x: "55%", y: "17%", w: "33%", h: "25%" })

    slide.addImage({ data: "data:image/svg+xml;base64," + encodedData, x: "57%", y: "20%", w: "29%", h: "22%" })

    slide.addImage({ path: require('../assets/3/cards_bg.png'), x: "5%", y: "48%", w: "28%", h: "30%" })
    slide.addImage({ path: require('../assets/3/cards_bg.png'), x: "36%", y: "48%", w: "28%", h: "30%" })
    slide.addImage({ path: require('../assets/3/cards_bg.png'), x: "67%", y: "48%", w: "28%", h: "30%" })
    slide.addImage({ path: require('../assets/3/plant.png'), x: "53%", y: "50%", w: "11%", h: "17%" })
    slide.addImage({ path: require('../assets/3/salad.png'), x: "18.5%", y: "48%", w: "13%", h: "20%" })
    slide.addImage({ path: require('../assets/3/cash.png'), x: "82%", y: "50%", w: "11%", h: "18%" })
    slide.addImage({ path: require('../assets/3/logo_bg.png'), x: "0%", y: "86%", w: "15%", h: "14%" })
    slide.addImage({ path: require('../assets/1/logoNoText.png'), x: "1%", y: "89%", w: "11%", h: "9%" })

    slide.addText("0", { x: "58%", y: "35%", w: "4%", h: "5%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 14, rotate: -90 });
    slide.addText("25", { x: "59.5%", y: "26.5%", w: "4%", h: "5%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 14, rotate: -60 });
    slide.addText("50", { x: "63.5%", y: "19.5%", w: "4%", h: "5%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 14, rotate: -32 });
    slide.addText("75", { x: "69.5%", y: "17%", w: "4%", h: "5%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 14 });
    slide.addText("100", { x: "75.5%", y: "19.5%", w: "4%", h: "5%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 14, rotate: 32 });
    slide.addText("125", { x: "79.5%", y: "26.5%", w: "4%", h: "5%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 14, rotate: 65 });
    slide.addText("150", { x: "81%", y: "35%", w: "4%", h: "5%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 14, rotate: 90 });


    slide.addText(t("synthesis.global.analysisrecap", { analysisNumber: analysisNumber }), { x: "2.5%", y: "12%", w: "30%", h: "5%", fontFace: 'Muli', align: "left", color: 'FFFFFF', fontSize: 25, bold: true });
    slide.addText(t("synthesis.page3.desc1", { number: getAnalysisNameValue(analysisNumber), synthesisName: synthesisName }), { x: "5%", y: "28%", w: "47%", h: "10%", fontFace: 'Muli', color: '666666', fontSize: 14, bold: true });
    slide.addText(t('mass', { value: { "mass": values.massConvTot, "unit": "g", fixed: 0 } }), { x: "5%", y: "40%", w: "8%", h: "5%", fontFace: 'Muli', color: '7ABCE2', fontSize: 27, bold: true });
    slide.addText(t("synthesis.page3.guestwastemass"), { x: "10.5%", y: "40.5%", w: "20%", h: "5%", fontFace: 'Muli', color: '666666', fontSize: 17, bold: true });

    slide.addText(t('mass', { value: { "mass": values.massTot, "unit": "kg", fixed: 0 } }), { x: "6%", y: "52%", w: "12%", h: "5%", fontFace: 'Muli', color: '7ABCE2', fontSize: 24, bold: true });
    slide.addText(t("synthesis.page3.bubble1.desc1"), { x: "6%", y: "59%", w: "15%", h: "8%", fontFace: 'Muli', color: '666666', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page3.bubble1.subtitle1", { meals: values.meals, mealsTotal: values.mealsTotal }), { x: "8%", y: "67%", w: "22%", h: "10%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 13 });

    slide.addText(t("synthesis.page3.co2", { value: t('mass', { value: { "mass": values.co2Tot, "unit": values.co2Tot >= 1000000 ? "T" : "kg", fixed: 0 } }) }), { x: "37%", y: "52%", w: "16%", h: "5%", fontFace: 'Muli', color: '7ABCE2', fontSize: 24, bold: true });
    slide.addText(t("synthesis.page3.bubble2.desc1"), { x: "37%", y: "59%", w: "15%", h: "8%", fontFace: 'Muli', color: '666666', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page3.bubble2.subtitle1", { number: co2Eq.number, type: co2Eq.type, travel: co2Eq.travel }), { x: "39.5%", y: "67%", w: "22%", h: "10%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 13 });

    slide.addText(t('currency', { value: { "cost": values.costTot, "unit": "€", fixed: 0 } }), { x: "68%", y: "52%", w: "10%", h: "5%", fontFace: 'Muli', color: '7ABCE2', fontSize: 24, bold: true });
    slide.addText(t("synthesis.page3.bubble3desc1"), { x: "68%", y: "59%", w: "15%", h: "8%", fontFace: 'Muli', color: '666666', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page3.bubble3subtitle1"), { x: "71.5%", y: "67%", w: "22%", h: "10%", align: "center", fontFace: 'Muli', color: '666666', fontSize: 13 });
    slide.addText(x, { x: "97%", y: "96%", w: "3%", h: "3%", fontFace: 'Muli', align: "right", color: '666666', fontSize: 15, bold: false });
    slide.background = { color: "#F8F8F3" }

    return pres;
}
