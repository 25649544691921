import React, { useEffect } from "react";
import DayTitle from "./DayTitle";
import DayGraph from "./DayGraph";
import DayValues from "./DayValues";
import DayGap from "./DayGap";
import RepartitionGraph from "../../commonElements/RepartitionGraph";
import Pictures from "../../commonElements/Pictures";
import DayTrays from "./DayTrays";
import { getEmptyTraysByAnalysis, getNumberNonOrgaForAnalysis } from "../../../../apiRoutes/trays.route";
import { useDispatch } from "react-redux";
import { setAnalyzesTrays, setExtrapolatedAnalyzes, setKitchenExtrapolatedAnalyzes, setSelectedImageSamplesBin } from "../../../../actions_reducers/actions/datas.action";
import DataSelection from "../../commonElements/DataSelection";
import { BackToMenuButton } from "../../commonElements/BackToMenuButton";
import { useTranslation } from "react-i18next";
import WeighIns from "../../commonElements/WeighIns";
import { setExtrapolationValue } from "../../../../actions_reducers/actions/dashboard.action";
import TraysValues from "../elementsAnalysisDetails/TraysValues";

const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const DashboardDay = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setExtrapolationValue("analysis"))
        dispatch(setKitchenExtrapolatedAnalyzes(props.kitchenAnalyzesDatas));
        dispatch(setExtrapolatedAnalyzes(props.analyzesDatas));
        let analysisTraysIndex = props.analyzesTrays.findIndex(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis))
        if (analysisTraysIndex !== -1 && !props.analyzesTrays[analysisTraysIndex].days.find(a => a.date === props.dashboardState.selectedDay))
            getTraysValues()
    }, [])
    const getTraysValues = async () => {
        let dateStart = props.dashboardState.selectedDay;
        let dateEnd = moment(props.dashboardState.selectedDay).add(1, 'day').format("YYYY-MM-DD");
        let restaurant_name = props.userState.selected_restaurant;
        let emptyForAnalysis = await getEmptyTraysByAnalysis(restaurant_name, dateStart, dateEnd);
        let nonOrgaForAnalysis = await getNumberNonOrgaForAnalysis(restaurant_name, dateStart, dateEnd);
        let index = props.analyzesTrays.findIndex(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis))
        let day = {
            date: props.dashboardState.selectedDay,
            nonOrga: nonOrgaForAnalysis,
            empty: emptyForAnalysis,
        };
        let analyzesTrays = JSON.parse(JSON.stringify(props.analyzesTrays));
        analyzesTrays[index].days.push(day);
        dispatch(setAnalyzesTrays(analyzesTrays));
    }

    const hasDatas = (type) => {
        if (props.userState.selectedAnalysisDataType.find(a => a === type) || props.userState.selectedAnalysisDataType.length === 0)
            return true;
        else return false;
    }

    const getCurrentAnalysis = () => {
        let selectedAnalysis;
        if (props.analyzesDatas.length > 0) selectedAnalysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
        else if (props.kitchenAnalyzesDatas.length > 0) selectedAnalysis = props.kitchenAnalyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));

        return selectedAnalysis;

    }
    const getSelectedDay = () => {
        if (hasDatas("restaurant")) {
            let selectedAnalysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));

            if (selectedAnalysis) {
                let dayOfAnalysis = selectedAnalysis.days.find(a => moment(a.date).format("YYYY-MM-DD") === props.dashboardState.selectedDay);
                return dayOfAnalysis;
            }
        }
        else return null
    }
    const getSelectedAnalysis = () => {
        if (hasDatas("restaurant")) {
            let selectedAnalysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
            return selectedAnalysis;
        }
    }

    const getDayTrays = () => {
        let analysisTrays = props.analyzesTrays.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
        if (analysisTrays) {
            let dayTrays = analysisTrays.days.find(a => a.date === props.dashboardState.selectedDay)
            return dayTrays;
        }
        else return null;
    }

    const getSelectedKitchenDay = () => {
        if (hasDatas("kitchen")) {
            let selectedAnalysis = props.kitchenAnalyzesDatas.find(a => a && parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
            if (selectedAnalysis) {
                let dayOfAnalysis = selectedAnalysis.days.find(a => moment(a.date).format("YYYY-MM-DD") === props.dashboardState.selectedDay);
                return dayOfAnalysis;
            }
            else return null;
        }
    }
    const getSelectedKitchenAnalysis = () => {
        if (hasDatas("kitchen")) {
            let selectedAnalysis = props.kitchenAnalyzesDatas.find(a => a && parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
            return selectedAnalysis;
        }
    }
    const analyzesTypes = () => {
        let selectedAnalysis = null;
        let selectedKitchenAnalysis = null;
        selectedAnalysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));
        selectedKitchenAnalysis = props.kitchenAnalyzesDatas.find(a => a && parseInt(a.analysisNumber) === parseInt(props.dashboardState.showedAnalysis));

        let dayTrays = null;
        if (selectedAnalysis)
            dayTrays = selectedAnalysis.days.find(a => moment(a.date).format("YYYY-MM-DD") === props.dashboardState.selectedDay)
        let daysKitchen = null;
        if (selectedKitchenAnalysis)
            daysKitchen = selectedKitchenAnalysis.days.find(a => moment(a.date).format("YYYY-MM-DD") === props.dashboardState.selectedDay)

        return {
            hasRestaurantAnalysis: dayTrays ? true : false,
            hasKitchenAnalysis: daysKitchen ? true : false
        };

    }
    console.log(props.dashboardState.selectedDay)
    return (
        <div className="main-page-main-div" style={{ backgroundColor: !getCurrentAnalysis().isOver && "#CBC4C4" }} onClick={(e) => {
            if (props.selectedImage !== null && e.target.id !== props.selectedImage && e.target.localName !== "path")
                dispatch(setSelectedImageSamplesBin(null))
        }
        }>
            <div style={{ display: 'flex', flexDirection: 'row', width: "100%", height: getCurrentAnalysis().isOver ? "6vh" : "14vh" }}>
                <div style={{ width: getCurrentAnalysis().isOver ? "60%" : "81%", height: "100%" }}>
                    <DayTitle selectedAnalysisNumber={props.dashboardState.selectedAnalysisNumber} date={props.dashboardState.selectedDay} analysis={getCurrentAnalysis()} />
                </div>
                <div style={{ width: getCurrentAnalysis().isOver ? "40%" : "19%", height: "100%" }}>
                    {!getCurrentAnalysis().isOver ?
                        <BackToMenuButton
                            state={props.dashboardState}
                            analyzesDatas={props.analyzesDatas}
                            value={t('buttons.accessMenuAndGuests')}
                            arianne={props.arianne}
                        /> : <DataSelection hasRestaurantAnalysis={analyzesTypes().hasRestaurantAnalysis} hasKitchenAnalysis={analyzesTypes().hasKitchenAnalysis} analyzesBaseDatas={props.analyzesBaseDatas} userState={props.userState} dashboardState={props.dashboardState} />
                    }
                </div>
            </div>
            <div style={{ height: "21vh", width: "100%", display: "flex", flexDirection: "row", marginBottom: "2.75%" }}>
                <div style={{ display: 'flex', flexDirection: 'row', width: "77%", height: "100%" }}>
                    <DayValues analysis={getSelectedDay()} state={props.dashboardState} has_kitchen={props.has_kitchen} kitchenAnalysis={getSelectedKitchenDay()} />
                </div>
                <div style={{ width: "23%", position: 'relative', marginTop: "4vh" }}>
                    <div style={{ position: "absolute", height: "39vh", width: "100%" }}>
                        <TraysValues type={"day"} dayTrays={getDayTrays()} day={getSelectedDay()} kitchenDay={getSelectedKitchenDay()}
                            userState={props.userState} />
                    </div>
                </div>
            </div>

            <div style={{ width: "100%", height: "47vh", display: "flex", flexDirection: "row" }}>
                <div style={{ width: "54.6%", height: "62vh" }}>
                    <DayGraph
                        userState={props.userState}
                        food_list_kitchen={props.food_list_kitchen} analysis={getSelectedDay()} state={props.dashboardState} has_kitchen={props.has_kitchen} kitchenAnalysis={getSelectedKitchenDay()} />
                </div>
                <div style={{ width: "3.7%" }} />
                <div style={{ width: "40.4%", height: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <div style={{ width: "39%", height: "36.54%" }}>
                            <DayGap day={getSelectedDay()} analysis={getSelectedAnalysis()} state={props.dashboardState} type={"day"} has_kitchen={props.has_kitchen} kitchenAnalysis={getSelectedKitchenAnalysis()} kitchenDay={getSelectedKitchenDay()} />
                        </div>
                        <div style={{ height: "10%" }} />
                        <div style={{ width: "103.2%", height: "40vh" }}>
                            <RepartitionGraph baseAnalysis={getSelectedDay()} baseAnalysisKitch={getSelectedKitchenDay()} analysis={getSelectedDay()} state={props.dashboardState} has_kitchen={props.has_kitchen} kitchenAnalysis={getSelectedKitchenDay()} />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: "14vh" }} />
            {getSelectedDay() &&
                <div style={{ width: "100%", height: "85vh", marginTop: "4%" }} >
                    <Pictures
                        selectedDate={props.dashboardState.selectedDay}
                        pictures_list={props.pictures_list}
                        analysis={getSelectedDay()}
                        food_list={props.food_list}
                        userState={props.userState} />
                </div>}
            {getSelectedKitchenDay() &&
                <div style={{ width: "100%", height: "85vh", marginTop: "4%" }} >
                    <WeighIns
                        selectedDate={props.dashboardState.selectedDay}
                        pictures_list={props.pictures_list}
                        analysis={getSelectedDay()}
                        food_list={props.food_list}
                        userState={props.userState}
                        weighIns={props.weighIns}
                        food_list_kitchen={props.food_list_kitchen}
                        kitchenDatas={props.kitchenAnalyzesDatas}
                        analysisNumber={props.dashboardState.showedAnalysis}
                        selectedImage={props.selectedImage} />
                </div>}

        </div >
    )
}
export default DashboardDay;