export function setOperations(operations) {
    return {
        type: 'SET_OPERATIONS',
        operations: operations
    }
}
export function setSelectedClientOperations(selectedClient) {
    return {
        type: 'SET_SELECTED_CLIENT_OPERATIONS',
        selectedClient: selectedClient
    }
}

export function setSelectedDateOperations(selectedDate) {
    return {
        type: 'SET_SELECTED_DATE_OPERATIONS',
        selectedDate: selectedDate
    }
}
export function setSelectedOperation(selectedOperation) {
    return {
        type: 'SET_SELECTED_OPERATION',
        selectedOperation: selectedOperation
    }
}
export function setOperationViewType(operationViewType) {
    return {
        type: 'SET_OPERATION_VIEW_TYPE',
        operationViewType: operationViewType
    }
}

export function setWeighInsOperations(weighIns) {
    return {
        type: 'SET_WEIGH_INS_OPERATIONS',
        weighIns: weighIns
    }
}