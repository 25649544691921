import React from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomButton from "../../../customComponents/CustomButton";
import CustomTextField from "../../../customComponents/CustomTextField";
import { resetNewClient, resetNewRestaurant, setNewRestaurantAverageTrays, setNewRestaurantCamsList, setNewRestaurantCostUnit, setNewRestaurantDisplayName, setNewRestaurantEvening, setNewRestaurantHasKitchen, setNewRestaurantHasRestaurant, setNewRestaurantHasTheoricNumber, setNewRestaurantMail, setNewRestaurantMorning, setNewRestaurantMultipleCams, setNewRestaurantName, setNewRestaurantNoon, setNewRestaurantNumberWeeksYear, setNewRestaurantPassword, setNewRestaurantSaturday, setNewRestaurantSunday, setNewRestaurantSynthesisName, setNewRestaurantUnit } from "../../../../actions_reducers/actions/admin.action";
import CustomCheckbox from "../../../customComponents/CustomCheckbox";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import { addInformations } from "../../../../apiRoutes/informations.routes";
import { createAccount } from "../../../../apiRoutes/account.route";
import { setCurrentPage } from "../../../../actions_reducers/actions/navigation.action";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { createFoodsForRestaurant } from "../../../../apiRoutes/food.routes";
import { createObjectivesForRestaurant } from "../../../../apiRoutes/objectives.route";
import { createAccountKitchen } from "../../../../apiRoutes/kitchenAccount.route";
import { createFoodsKitchen } from "../../../../apiRoutes/kitchenFood.routes";

const ClientListNewRestaurant = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isGood = () => {
        if (props.newRestaurantName === null || props.newRestaurantName.replace(/ /g, "") === "") return false;
        else if (props.newRestaurantDisplayName === null || props.newRestaurantDisplayName === "") return false;
        else if (props.newRestaurantAverageTrays === null || props.newRestaurantAverageTrays === "" || props.newRestaurantAverageTrays === 0) return false;
        else if (props.newRestaurantMorning === false && props.newRestaurantNoon === false && props.newRestaurantEvening === false) return false;
        else if (props.newRestaurantMultipleCams === true && props.newRestaurantCamsList.find(a => a.nameCam === "" || a.nameCam == null) != null)
            return false;
        else if (props.numberWeeksYear === null)
            return false;
        else return true;
    }
    const createClientAndRestaurant = async () => {
        if (isGood()) {
            if (props.newClientName) {
                let newClientAccount = {
                    emailAccount: props.newClientMail.replace(/ /g, "").toLowerCase(),
                    passwordAccount: props.newClientPassword.replace(/ /g, ""),
                    clientName: props.newClientName,
                    type: "super-client"
                }
                await createAccount(newClientAccount);
            }
            let newRestaurantAccount = {
                emailAccount: props.newRestaurantMail.replace(/ /g, "").toLowerCase(),
                passwordAccount: props.newRestaurantPassword.replace(/ /g, ""),
                restaurantName: props.newRestaurantName,
                type: "chef"
            }
            let newRestaurantInformations = {
                clientName: props.newClientName || props.selectedClient,
                restaurantName: props.newRestaurantName,
                displayName: props.newRestaurantDisplayName,
                synthesisName: props.newRestaurantSynthesisName,
                averageTrays: props.newRestaurantAverageTrays,
                hasTheoricNumber: props.newRestaurantHasTheoricNumber,
                camsNames: props.newRestaurantCamsList,
                morning: props.newRestaurantMorning,
                noon: props.newRestaurantNoon,
                evening: props.newRestaurantEvening,
                multipleCams: props.newRestaurantMultipleCams,
                staturday: props.newRestaurantSaturday,
                sunday: props.newRestaurantSunday,
                wasteManagementMethod: "compostage",
                costUnit: props.newRestaurantCostUnit,
                massUnit: props.newRestaurantUnit,
                numberWeeksYear: props.numberWeeksYear,
                hasRestaurant: props.hasRestaurant,
                hasKitchen: props.hasKitchen
            }
            await addInformations(newRestaurantInformations);
            await createAccount(newRestaurantAccount);
            await createAccountKitchen(props.newRestaurantName)
            await createFoodsKitchen(props.newRestaurantName)
            await createFoodsForRestaurant(props.newRestaurantName);
            await createObjectivesForRestaurant(props.newRestaurantName);
            window.alert("Le restaurant a bien été créé");
            updateArianneThread("clientList", props.arianne, dispatch, "");
            dispatch(setCurrentPage("clientList"));
            dispatch(resetNewRestaurant());
            dispatch(resetNewClient());
        }
        else window.alert("Veuillez remplir tous les champs.")
    }
    const changeCameras = () => {
        if (props.newRestaurantCamsList.length === 0) {
            dispatch(setNewRestaurantCamsList([
                {
                    numberCam: 1,
                    nameCam: ""
                },
                {
                    numberCam: 2,
                    nameCam: ""
                }
            ]))
        }
        else {
            dispatch(setNewRestaurantCamsList([]))
        }
    }
    const updateNameCam = (index, value) => {
        let cameras = JSON.parse(JSON.stringify(props.newRestaurantCamsList));
        cameras[index].nameCam = value;
        dispatch(setNewRestaurantCamsList(cameras));
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh", display: "flex", justifyContent: "center", color: "#666666" }}>
            <CustomBlock
                width={"100%"}
                height={"100%"}>
                {props.newClientName &&
                    <CustomButton
                        position={"absolute"}
                        top={"1%"}
                        left={"0.5%"}
                        width={"15%"}
                        height={"7%"}
                        color={"#fff"}
                        backgroundColor={"#666666"}
                        onClick={() => {
                            updateArianneThread("addClient", props.arianne, dispatch, "");
                            dispatch(setCurrentPage("addClient"));
                            dispatch(resetNewRestaurant());
                        }}
                        value={t('buttons.back')} />}
                <CustomButton
                    position={"absolute"}
                    bottom={"1%"}
                    right={"0.5%"}
                    width={"15%"}
                    height={"7%"}
                    color={"#fff"}
                    onClick={() => createClientAndRestaurant()}
                    value={t('buttons.confirm')} />
                <div style={{ height: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    Nouveau Restaurant
                </div>
                <div style={{ height: "15%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                        <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                Nom du restaurant
                            </div>
                            <div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomTextField width={"100%"} height={"40%"}
                                    filter={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    borderRadius={"10px"}
                                    type={"text"}
                                    value={props.newRestaurantName}
                                    onChange={(e) => dispatch(setNewRestaurantName(e.target.value.toUpperCase()))} />
                            </div>
                        </div>
                        <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                Display name
                            </div>
                            <div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomTextField width={"90%"} height={"40%"}
                                    filter={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    borderRadius={"10px"}
                                    type={"text"}
                                    value={props.newRestaurantDisplayName}
                                    onChange={(e) => dispatch(setNewRestaurantDisplayName(e.target.value))} />
                            </div>

                        </div>
                    </div>
                    <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                        <div style={{ height: "50%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            Nom du client: {props.newClientName || props.selectedClient}
                        </div>
                        <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                Nom sur la synthèse :
                            </div>
                            <div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomTextField width={"90%"} height={"40%"}
                                    filter={"none"}
                                    backgroundColor={"#D9D9D9"}
                                    borderRadius={"10px"}
                                    type={"text"}
                                    value={props.newRestaurantSynthesisName}
                                    onChange={(e) => dispatch(setNewRestaurantSynthesisName(e.target.value))} />
                            </div>

                        </div>
                    </div>
                </div>
                <div style={{ height: "15%", display: "flex" }}>

                    <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            Adresse e-mail
                        </div>
                        <div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomTextField width={"90%"} height={"40%"}
                                filter={"none"}
                                backgroundColor={"#D9D9D9"}
                                borderRadius={"10px"}
                                type={"text"}
                                value={props.newRestaurantMail}
                                onChange={(e) => dispatch(setNewRestaurantMail(e.target.value.toLowerCase()))} />
                        </div>

                    </div>

                    <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            Mot de passe
                        </div>
                        <div style={{ width: "60%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomTextField width={"100%"} height={"40%"}
                                filter={"none"}
                                backgroundColor={"#D9D9D9"}
                                borderRadius={"10px"}
                                type={"text"}
                                value={props.newRestaurantPassword}
                                onChange={(e) => dispatch(setNewRestaurantPassword(e.target.value))} />
                        </div>

                    </div>
                </div>
                <div style={{ height: "30%", display: "flex" }}>
                    <div style={{ width: "25%" }}>
                        <div style={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>Services analysés</div>
                        <div style={{ height: "30%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.newRestaurantMorning} onChange={() => dispatch(setNewRestaurantMorning(!props.newRestaurantMorning))} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Matin
                            </div>
                        </div>
                        <div style={{ height: "30%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.newRestaurantNoon} onChange={() => dispatch(setNewRestaurantNoon(!props.newRestaurantNoon))} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Midi
                            </div>
                        </div>
                        <div style={{ height: "30%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.newRestaurantEvening} onChange={() => dispatch(setNewRestaurantEvening(!props.newRestaurantEvening))} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Soir
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "25%" }}>
                        <div style={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>Jours de fonctionnement</div>

                        <div style={{ height: "30%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.newRestaurantSaturday} onChange={() => dispatch(setNewRestaurantSaturday(!props.newRestaurantSaturday))} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Samedi
                            </div>
                        </div>
                        <div style={{ height: "30%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.newRestaurantSunday} onChange={() => dispatch(setNewRestaurantSunday(!props.newRestaurantSunday))} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Dimanche
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "25%", position: "relative", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <div style={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>Plusieurs caméras</div>

                        <div style={{ height: "30%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.newRestaurantMultipleCams === true} onChange={() => { changeCameras(); dispatch(setNewRestaurantMultipleCams(true)) }} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Oui
                            </div>
                        </div>
                        <div style={{ height: "30%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.newRestaurantMultipleCams === false} onChange={() => { changeCameras(); dispatch(setNewRestaurantMultipleCams(false)) }} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Non
                            </div>
                        </div>
                        {props.newRestaurantMultipleCams &&
                            <div style={{ position: "absolute", height: "90%", bottom: "-70%", width: "80%", left: "25%" }}>
                                <div style={{ height: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{ height: "30%", width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                        Camera n°1
                                    </div>
                                    <div style={{ height: "70%", width: "100%" }}>
                                        <CustomTextField width={"70%"} height={"40%"}
                                            filter={"none"}
                                            backgroundColor={"#D9D9D9"}
                                            borderRadius={"10px"}
                                            type={"text"}
                                            value={props.newRestaurantCamsList[0].nameCam}
                                            onChange={(e) => updateNameCam(0, e.target.value)} />
                                    </div>
                                </div>
                                <div style={{ height: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{ height: "30%", width: "100%", display: "flex", justifyContent: "flex-start" }}>
                                        Camera n°2
                                    </div>
                                    <div style={{ height: "70%", width: "100%" }}>
                                        <CustomTextField width={"70%"} height={"40%"}
                                            filter={"none"}
                                            backgroundColor={"#D9D9D9"}
                                            borderRadius={"10px"}
                                            type={"text"}
                                            value={props.newRestaurantCamsList[1].nameCam}
                                            onChange={(e) => updateNameCam(1, e.target.value)} />
                                    </div>
                                </div>
                            </div>}
                    </div>
                    <div style={{ width: "25%" }}>
                        <div style={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>Nombre de plateaux théoriques</div>

                        <div style={{ height: "30%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.newRestaurantHasTheoricNumber === true} onChange={() => dispatch(setNewRestaurantHasTheoricNumber(true))} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Oui
                            </div>
                        </div>
                        <div style={{ height: "30%", display: "flex" }}>
                            <div style={{ width: "45%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.newRestaurantHasTheoricNumber === false} onChange={() => dispatch(setNewRestaurantHasTheoricNumber(false))} />
                            </div>
                            <div style={{ width: "55%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Non
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: "15%", display: "flex" }}>
                    <div style={{ width: "25%" }}>
                        <div style={{ height: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>Unité massique</div>
                        <div style={{ height: "80%", display: "flex", justifyContent: "center" }}>
                            <CustomDropdown
                                height={"30%"}
                                width={"60%"}
                                color={"#666666"}
                                backgroundColor={"#D9D9D9"}
                                displayedValue={props.newRestaurantUnit}
                                selectedValue={props.newRestaurantUnit}
                                values={["g", "lbs"]}
                                onChange={(e) => dispatch(setNewRestaurantUnit(e.target.id))}
                            />
                        </div>
                    </div>
                    <div style={{ width: "25%" }}>
                        <div style={{ height: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>Unité monétaire</div>
                        <div style={{ height: "80%", display: "flex", justifyContent: "center" }}>
                            <CustomDropdown
                                height={"30%"}
                                width={"60%"}
                                color={"#666666"}
                                backgroundColor={"#D9D9D9"}
                                displayedValue={props.newRestaurantCostUnit}
                                selectedValue={props.newRestaurantCostUnit}
                                values={["€", "$"]}
                                onChange={(e) => dispatch(setNewRestaurantCostUnit(e.target.id))}
                            />
                        </div>
                    </div>

                    <div style={{ width: "25%" }}>
                    </div>
                    <div style={{ width: "25%" }}>
                        <div style={{ height: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            Nombre de plateaux moyens
                        </div>
                        <div style={{ height: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomTextField width={"90%"} height={"100%"}
                                filter={"none"}
                                backgroundColor={"#D9D9D9"}
                                borderRadius={"10px"}
                                type={"number"}
                                value={props.newRestaurantAverageTrays}
                                onChange={(e) => dispatch(setNewRestaurantAverageTrays(e.target.value))} />

                        </div>
                    </div>
                </div>
                <div style={{ height: "10%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "25%", height: "100%", marginTop: "-2%" }}>
                        <div style={{ flexDirection: "row", display: "flex", height: "50%", width: "50%" }}>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.hasRestaurant} onChange={() => dispatch(setNewRestaurantHasRestaurant(!props.hasRestaurant))} />
                            </div>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Salle
                            </div>
                        </div>

                        <div style={{ flexDirection: "row", display: "flex", height: "50%", width: "50%" }}>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <CustomCheckbox checked={props.hasKitchen} onChange={() => dispatch(setNewRestaurantHasKitchen(!props.hasKitchen))} />
                            </div>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", marginLeft: "5%" }}>
                                Cuisine
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "25%", height: "100%", marginTop: "-2%" }}>

                        <div style={{ height: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            Nombre de semaines par an
                        </div>
                        <div style={{ height: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CustomTextField width={"90%"} height={"100%"}
                                filter={"none"}
                                backgroundColor={"#D9D9D9"}
                                borderRadius={"10px"}
                                type={"number"}
                                value={props.numberWeeksYear}
                                onChange={(e) => dispatch(setNewRestaurantNumberWeeksYear(e.target.value))} />

                        </div>
                    </div>
                </div>
            </CustomBlock >

        </div >
    )
}

function mapStateToProps(state) {
    return {
        selectedClient: state.adminReducer.selectedClient,
        selectedRestaurant: state.adminReducer.selectedRestaurantModifs,
        newClientName: state.adminReducer.newClientName,
        newClientMail: state.adminReducer.newClientMail,
        newClientPassword: state.adminReducer.newClientPassword,
        arianne: state.navigationReducer.arianne,
        newRestaurantName: state.adminReducer.newRestaurantName,
        newRestaurantDisplayName: state.adminReducer.newRestaurantDisplayName,
        newRestaurantSynthesisName: state.adminReducer.newRestaurantSynthesisName,
        newRestaurantMail: state.adminReducer.newRestaurantMail,
        newRestaurantPassword: state.adminReducer.newRestaurantPassword,
        newRestaurantMorning: state.adminReducer.newRestaurantMorning,
        newRestaurantNoon: state.adminReducer.newRestaurantNoon,
        newRestaurantEvening: state.adminReducer.newRestaurantEvening,
        newRestaurantSaturday: state.adminReducer.newRestaurantSaturday,
        newRestaurantSunday: state.adminReducer.newRestaurantSunday,
        newRestaurantMultipleCams: state.adminReducer.newRestaurantMultipleCams,
        newRestaurantCamsList: state.adminReducer.newRestaurantCamsList,
        newRestaurantHasTheoricNumber: state.adminReducer.newRestaurantHasTheoricNumber,
        newRestaurantUnit: state.adminReducer.newRestaurantUnit,
        newRestaurantCostUnit: state.adminReducer.newRestaurantCostUnit,
        newRestaurantAverageTrays: state.adminReducer.newRestaurantAverageTrays,
        hasRestaurant: state.adminReducer.newRestaurantHasRestaurant,
        hasKitchen: state.adminReducer.newRestaurantHasKitchen,
        numberWeeksYear: state.adminReducer.newRestaurantNumberWeeksYear
    };
}

export default connect(mapStateToProps)(ClientListNewRestaurant);
