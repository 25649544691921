import React, { useEffect } from "react";
import { useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import { setSelectedClient } from "../../../../actions_reducers/actions/admin.action";
import CustomButton from "../../../customComponents/CustomButton";
import { mediumResetUser, setHasLoaded, setSelectedRestaurant } from "../../../../actions_reducers/actions/user.action";
import { hardResetClient, setClientName, setHasLoadedClient } from "../../../../actions_reducers/actions/client.action";
import { hardResetDashboard } from "../../../../actions_reducers/actions/dashboard.action";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { setCurrentPage } from "../../../../actions_reducers/actions/navigation.action";
import { hardResetDatas } from "../../../../actions_reducers/actions/datas.action";
import { hardResetSearch } from "../../../../actions_reducers/actions/search.action";
import CustomAutocomplete from "../../../customComponents/CustomAutocomplete";

const ClientListSelection = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const getClientList = () => {
        let result = [];
        for (const restaurant of props.informations) {
            if (!result.find(a => a === restaurant.clientName))
                result.push(restaurant.clientName);
        }
        result.sort((a, b) => a.localeCompare(b))
        return result;
    }
    useEffect(() => {
        if (props.selectedClient == null) {
            let result = getClientList();
            if (result)
                dispatch(setSelectedClient(result[0]))
        }
    }, [props.informations.length]);

    const updateSelectedClient = (e) => {
        dispatch(setSelectedClient(e.target.id))
    }

    const navigateToTarget = (target) => {
        if (target === "clientDashboard") {
            dispatch(setHasLoadedClient(false));
            dispatch(setHasLoaded(false))
            dispatch(setClientName(props.selectedClient));
            dispatch(mediumResetUser());
            dispatch(hardResetDatas());
            dispatch(hardResetDashboard());
            dispatch(hardResetSearch());
            dispatch(setSelectedRestaurant(null));
            updateArianneThread("clientDashboard", props.arianne, dispatch, "");
            dispatch(setCurrentPage("clientDashboard"));
        }
        else if (target === "addClient") {
            updateArianneThread("addClient", props.arianne, dispatch, "");
            dispatch(setCurrentPage("addClient"));
        }
        else if (target === "demoAccounts") {
            updateArianneThread("demoAccounts", props.arianne, dispatch, "");
            dispatch(setCurrentPage("demoAccounts"));
        }
        else if (target === "clientSettings") {
            updateArianneThread("clientSettings", props.arianne, dispatch, "");
            dispatch(setCurrentPage("clientSettings"));
        }

    }
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
            <CustomAutocomplete
                id={'client-list-'}
                width={'25%'}
                height={'100%'}
                backgroundColor={'#68B4E3'}
                displayedValue={props.selectedClient}
                selectedValue={props.selectedClient}
                values={getClientList()}
                onChange={updateSelectedClient}
                arrowColor={"#fff"}
                maxHeightDropdown={"40vh"}
                color={"#fff"} />
            <div style={{ width: "2.5%" }} />
            <CustomButton
                width={"10%"}
                height={"100%"}
                backgroundColor={"#89CD85"}
                color={"#fff"}
                onClick={() => navigateToTarget("addClient")}
            >{t('buttons.addClient')}</CustomButton>
            <div style={{ width: "10%" }} />
            <CustomButton width={"15%"} height={"100%"} color={"#fff"} backgroundColor={"#89CD85"}
                onClick={() => navigateToTarget("clientSettings")}>
                {t('buttons.clientSettings')}
            </CustomButton>
            <div style={{ width: "2.5%" }} />
            <CustomButton
                width={"15%"}
                height={"100%"}
                backgroundColor={"#89CD85"}
                color={"#fff"}
                onClick={() => navigateToTarget("demoAccounts")}
            >{t('buttons.demoAccounts')}</CustomButton>
            <div style={{ width: "2.5%" }} />
            <CustomButton
                width={"20%"}
                height={"100%"}
                backgroundColor={"#89CD85"}
                color={"#fff"}
                onClick={() => { dispatch(hardResetClient()); navigateToTarget("clientDashboard") }}
            >{t('buttons.clientDashboard')}</CustomButton>
        </div>
    )
}


export default ClientListSelection;
