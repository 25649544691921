import { setKitchenAnalyzesDatas } from "../../actions_reducers/actions/datas.action"

const getInitialValues = (object, values, categoryType) => {
    object.mass = parseFloat(values.mass) * 1000
    object.cost = parseFloat(values.cost)
    object.co2 = parseFloat(values.co2) * 1000
    if (categoryType === "inevitable") {
        object.inevitableMass = parseFloat(values.mass) * 1000
        object.inevitableCost = parseFloat(values.cost) * 1000
        object.inevitableCo2 = parseFloat(values.co2) * 1000
    }
    return object
}

const getNewValues = (type, value, categoryType, trays) => {
    let newFood = {
        aliment_id: value.alimId,
        nameFood: value.alimName,
        trays: trays || 1,
        oldTrays: trays || 1
    }
    newFood = getInitialValues(newFood, value, categoryType);
    let newCat = {
        category: value.category,
        trays: trays || 1,
        oldTrays: trays || 1,
        foods: [newFood]
    }
    newCat = getInitialValues(newCat, value, categoryType);
    let newDay = {
        date: value.created_at,
        trays: 1,
        oldTrays: 1,
        categories: [newCat]
    }
    newDay = getInitialValues(newDay, value, categoryType);
    if (type === "day") {
        return newDay;
    }
    else if (type === "category") {
        return newCat;
    }
    else if (type === "food") {
        return newFood;
    }
}
const addValuesToExisting = (object, values, categoryType) => {
    object.mass = parseFloat(object.mass) + parseFloat(values.mass) * 1000
    object.cost = parseFloat(object.cost) + parseFloat(values.cost)
    object.co2 = parseFloat(object.co2) + parseFloat(values.co2) * 1000
    if (categoryType === "inevitable") {
        object.inevitableMass = parseFloat(object.inevitableMass) + parseFloat(values.inevitableMass) * 1000
        object.inevitableCost = parseFloat(object.inevitableCost) + parseFloat(values.inevitableCost) * 1000
        object.inevitableCo2 = parseFloat(object.inevitableCo2) + parseFloat(values.inevitableCo2) * 1000
    }
    return object;
}

export const updateKitchenDatas = (kitchenDatas, analysisNumber, value, categoryType, type, dispatch) => {
    if (type === "add") {
        let selectedAnalysis = kitchenDatas.find(a => parseInt(a.analysisNumber) === parseInt(analysisNumber));
        if (selectedAnalysis) {
            selectedAnalysis = addValuesToExisting(selectedAnalysis, value, categoryType)
            let dayIndex = selectedAnalysis.days.findIndex(a => a.date === value.created_at);
            if (dayIndex === -1) {
                let newValues = getNewValues("day", value, categoryType)
                selectedAnalysis.days.push(newValues);
            }
            else {
                let dayValue = selectedAnalysis.days[dayIndex];
                dayValue = addValuesToExisting(dayValue, value, categoryType);
                let catIndex = dayValue.categories.findIndex(a => a.category === value.category);
                if (catIndex === -1) {
                    let newValues = getNewValues("category", value, categoryType, dayValue.trays)
                    dayValue.categories.push(newValues);
                }
                else {
                    let catValue = dayValue.categories[catIndex];
                    catValue = addValuesToExisting(catValue, value, categoryType)
                    let foodIndex = catValue.foods.findIndex(a => a.nameFood === value.alimName);
                    if (foodIndex === -1) {
                        let newValues = getNewValues("food", value, categoryType, dayValue.trays)
                        catValue.foods.push(newValues);
                    }
                    else {
                        let foodValue = catValue.foods[foodIndex];
                        addValuesToExisting(foodValue, value, categoryType)
                    }
                }
            }
        }
        dispatch(setKitchenAnalyzesDatas(kitchenDatas))
    }
}