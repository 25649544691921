const initialState = {
    initialAnalyzes: [],
    analyzesBaseDatas: [],
    analyzesDatas: [],
    extrapolatedAnalyzes: [],
    kitchenDatas: [],
    kitchenAnalyzesDatas: [],
    kitchenExtrapolatedAnalyzes: [],
    analyzesTrays: [],
    clientAnalyzes: [],
    categories: [
        "Viandes",
        "Légumes",
        "Fruit",
        "Produits de la mer",
        "Féculents",
        "Crudités",
        "Fromage",
        "Dessert",
        "Plats composés",
        "Pain",
        "Oeufs",
        "Déchets inévitables",
    ],
    pictures_list: [],
    food_list: [],
    food_list_kitchen: [],
    food_values: [],
    trays_by_day: [],
    weighIns: [],
    samplesBin: [],
    deletedSamplesBin: [],
    updatedSamplesBin: [],
    selected_analysis_samples_bin: null,
    last_selected_day_samples_bin: null,
    all_dates_samples_bin: [],
    selected_day_samples_bin: null,
    selected_image_samples_bin: null,
    selected_type_datas_restaurant: 'foods',
    selected_analysis_menus: null,
    selected_date_menus: null,
    selected_service_menus: 'noon',
    menus_list: [],
    all_analyzes: [],
    all_observations: [],
    wasteManagementMethod: 'compostage'
}


const datasReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_INITIAL_ANALYZES':
            return {
                ...state,
                initialAnalyzes: action.initialAnalyzes
            }
        case 'SET_ANALYZES_BASE_DATAS':
            return {
                ...state,
                analyzesBaseDatas: action.analyzesBaseDatas
            }
        case 'SET_ANALYZES_DATAS':
            return {
                ...state,
                analyzesDatas: action.analyzesDatas
            }
        case 'SET_EXTRAPOLATED_ANALYZES':
            return {
                ...state,
                extrapolatedAnalyzes: action.extrapolatedAnalyzes
            }

        case 'SET_ANALYZES_TRAYS':
            return {
                ...state,
                analyzesTrays: action.analyzesTrays
            }
        case 'SET_PICTURES_LIST':
            return {
                ...state,
                pictures_list: action.pictures_list
            }
        case 'SET_FOOD_LIST':
            return {
                ...state,
                food_list: action.food_list
            }
        case 'SET_TRAYS_BY_DAY':
            return {
                ...state,
                trays_by_day: action.trays_by_day
            }
        case 'SET_KITCHEN_DATAS':
            return {
                ...state,
                kitchenDatas: action.kitchenDatas
            }
        case 'SET_KITCHEN_ANALYZES_DATAS':
            return {
                ...state,
                kitchenAnalyzesDatas: action.kitchenAnalyzesDatas
            }
        case 'SET_KITCHEN_EXTRAPOLATED_ANALYZES':
            return {
                ...state,
                kitchenExtrapolatedAnalyzes: action.kitchenExtrapolatedAnalyzes
            }
        case 'SET_WEIGH_INS':
            return {
                ...state,
                weighIns: action.weighIns
            }
        case 'SET_FOOD_LIST_KITCHEN':
            return {
                ...state,
                food_list_kitchen: action.food_list_kitchen
            }
        case 'SET_SAMPLES_BIN':
            return {
                ...state,
                samplesBin: action.samplesBin
            }
        case 'SET_DELETED_SAMPLES_BIN':
            return {
                ...state,
                deletedSamplesBin: action.deletedSamplesBin
            }
        case 'SET_UPDATED_SAMPLES_BIN':
            return {
                ...state,
                updatedSamplesBin: action.updatedSamplesBin
            }
        case 'SET_SELECTED_ANALYSIS_SAMPLES_BIN':
            return {
                ...state,
                selected_analysis_samples_bin: action.selected_analysis_samples_bin
            }
        case 'SET_SELECTED_DAY_SAMPLES_BIN':
            return {
                ...state,
                selected_day_samples_bin: action.selected_day_samples_bin
            }
        case 'SET_SELECTED_IMAGE_SAMPLES_BIN':
            return {
                ...state,
                selected_image_samples_bin: action.selected_image_samples_bin
            }
        case 'SET_SELECTED_TYPE_DATAS_RESTAURANT':
            return {
                ...state,
                selected_type_datas_restaurant: action.selected_type_datas_restaurant
            }
        case 'SET_SELECTED_ANALYSIS_MENUS':
            return {
                ...state,
                selected_analysis_menus: action.selected_analysis_menus
            }
        case 'SET_SELECTED_DATE_MENUS':
            return {
                ...state,
                selected_date_menus: action.selected_date_menus
            }
        case 'SET_SELECTED_SERVICE_MENUS':
            return {
                ...state,
                selected_service_menus: action.selected_service_menus
            }
        case 'SET_MENUS_LIST':
            return {
                ...state,
                menus_list: action.menus_list
            }
        case 'SET_ALL_ANALYZES':
            return {
                ...state,
                all_analyzes: action.all_analyzes
            }
        case 'SET_ALL_OBSERVATIONS':
            return {
                ...state,
                all_observations: action.all_observations
            }
        case 'SET_WASTE_MANAGEMENT_METHOD':
            return {
                ...state,
                wasteManagementMethod: action.wasteManagementMethod
            }
        case 'SET_FOOD_VALUES':
            return {
                ...state,
                food_values: action.food_values
            }
        case 'SET_LAST_SELECTED_DAY_SAMPLES_BIN':
            return {
                ...state,
                last_selected_day_samples_bin: action.last_selected_day_samples_bin
            }
        case 'SET_ALL_DATES_SAMPLES_BIN':
            return {
                ...state,
                all_dates_samples_bin: action.all_dates_samples_bin
            }
        case 'HARD_RESET_DATAS':
            return initialState;
        default:
            return state;
    }
}

export default datasReducer;