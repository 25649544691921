import React, { useState } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import CustomButton from "../../../customComponents/CustomButton";
import CustomTextField from "../../../customComponents/CustomTextField";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import CustomAutocomplete from "../../../customComponents/CustomAutocomplete";
import CustomDropdownCheckboxes from "../../../customComponents/CustomDropdownCheckBoxes";
import { updateAccount } from "../../../../apiRoutes/account.route";
import { setDemoAccounts } from "../../../../actions_reducers/actions/admin.action";

const ClientListDemoAccounts = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [modified, setModified] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [newType, setNewType] = useState(null);
    const [associated, setAssociated] = useState(null);
    const [associatedRestaurant, setAssociatedRestaurant] = useState([]);
    const confirmChanges = async () => {
        let accounts = props.demoAccounts;
        let index = accounts.findIndex(a => a.emailAccount === modified);
        await updateAccount(accounts[index], newPassword, newType, associated, associatedRestaurant);
        if (accounts) {
            accounts[index].associated_restaurants = associatedRestaurant
            accounts[index].type = newType;
            if (newType === 'chef') accounts[index].restaurantName = associated;
            else accounts[index].clientName = associated;
        }
        else if (associated) {
            accounts[index].associated_restaurants = associatedRestaurant
            if (accounts.type === 'chef') accounts[index].restaurantName = associated;
            else accounts[index].clientName = associated;
        }
        if (newPassword != null && newPassword != "") {
            accounts[index].passwordAccount = newPassword;
        }
        cancelChanges();
    }
    const cancelChanges = () => {
        setModified(null);
        setNewPassword(null);
        setNewType(null);
        setAssociated(null);
    }
    const getDisplayedValueAssociated = (account, restaurantList, clientList) => {
        if (newType && associated === null) {
            if (newType !== account.type) {
                if (newType === "chef") return restaurantList[0]
                else return clientList[0];
            }
            else {
                if (newType === "chef") return account.restaurantName;
                else return account.clientName;
            }
        }
        else if (newType === null && associated === null) {
            if (account.type === "chef") return account.restaurantName;
            else return account.clientName;
        }
        else return associated
    }
    const updateAssociatedRestaurant = (name) => {
        let tmp = associatedRestaurant;
        let index = tmp.findIndex(a => a === name)
        if (index === -1)
            tmp.push(name);
        else tmp.splice(index, 1)
        setAssociatedRestaurant([...tmp]);
    }
    const updateValueAssociated = (name, type) => {
        if (type === "super-client") {
            let restaurantList = [];
            for (const item of props.informations) {
                if (item.clientName === name)
                    restaurantList.push(item.restaurantName)
            }
            setAssociatedRestaurant(restaurantList);
        }
        else if (type === "chef") setAssociatedRestaurant([name])
        else if (type == "client") setAssociatedRestaurant([])
    }
    const getDropdownChoice = (account, restaurantList, clientList) => {
        let type = (newType ? newType : account.type);
        if (type !== "client")
            return (
                <CustomAutocomplete
                    width={"80%"}
                    height={"40%"}
                    color={"#666666"}
                    maxHeightDropdown={'40vh'}
                    values={(newType ? newType : account.type) === "chef" ? restaurantList : clientList}
                    displayedValue={getDisplayedValueAssociated(account, restaurantList, clientList)}
                    onChange={(e) => {
                        updateValueAssociated(e.target.id, type)
                        setAssociated(e.target.id)
                    }} />
            )
        else {
            let restaurants = [];
            for (const item of props.informations) {
                if (item.clientName === (associated ? associated : account.clientName))
                    restaurants.push(item.restaurantName)
            }
            return (
                <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <CustomAutocomplete
                        width={"80%"}
                        height={"40%"}
                        color={"#666666"}
                        maxHeightDropdown={'40vh'}
                        values={clientList}
                        displayedValue={getDisplayedValueAssociated(account, restaurantList, clientList)}
                        onChange={(e) => {
                            updateValueAssociated(e.target.id, type)
                            setAssociated(e.target.id)
                        }} />
                    <CustomDropdownCheckboxes
                        width={"80%"}
                        height={"40%"}
                        color={"#666666"}
                        maxHeightDropdown={'40vh'}
                        values={restaurants}
                        selectedValues={associatedRestaurant}
                        displayedValue={"Sélectionnez"}
                        onChange={(e) => updateAssociatedRestaurant(e.target.id)} />
                </div>
            )
        }
    }
    const updateType = (e, account, restaurantList, clientList) => {
        let accounts = props.demoAccounts;
        let index = accounts.findIndex(a => a.emailAccount === modified);
        accounts[index].type = e.target.id
        setNewType(e.target.id)
        if (e.target.id !== account.type) {
            if (e.target.id === "chef") {
                updateValueAssociated(restaurantList[0], e.target.id)
                setAssociated(restaurantList[0])
            }
            else if (e.target.id === "super-client") {
                updateValueAssociated(clientList[0], e.target.id)
                setAssociated(clientList[0])
            }
            else setAssociated(clientList[0])
        }
        else {
            if (e.target.id === "chef") {
                updateValueAssociated(account.restaurantName, e.target.id)
                setAssociated(account.restaurantName)
            }
            else if (e.target.id === "super-client") {
                updateValueAssociated(account.clientName, e.target.id)
                setAssociated(account.clientName)
            }
            else setAssociated(account.clientName)
        }
    }
    const getRows = () => {
        let result = [];
        let types = ['super-client', 'client', 'chef'];
        let restaurantList = [];
        let clientList = [];
        for (const item of props.informations) {
            restaurantList.push(item.restaurantName);
            if (!clientList.find(a => a === item.clientName))
                clientList.push(item.clientName);
        }
        restaurantList.sort((a, b) => a.localeCompare(b));
        clientList.sort((a, b) => a.localeCompare(b));
        for (const [index, account] of props.demoAccounts.entries()) {
            result.push(
                <div style={{ height: "12%", display: "flex", backgroundColor: index % 2 === 0 && "#F0F0F0" }}>
                    <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {account.emailAccount}
                    </div>
                    <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {modified === account.emailAccount ?
                            <CustomTextField
                                width={"70%"}
                                height={"40%"}
                                filter={"none"}
                                backgroundColor={"#D9D9D9"}
                                borderRadius={"10px"}
                                value={newPassword} onChange={(e) => setNewPassword(e.target.value)} /> :
                            '*******'}
                    </div>
                    <div style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {modified === account.emailAccount ?
                            <CustomDropdown
                                width={"70%"}
                                height={"40%"}
                                color={"#666666"}
                                values={types}
                                displayedValue={newType ? newType : account.type}
                                onChange={(e) => updateType(e, account, restaurantList, clientList)} />
                            :
                            account.type}
                    </div>
                    <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {modified === account.emailAccount ?
                            getDropdownChoice(account, restaurantList, clientList)
                            :
                            account.type === "chef" ? account.restaurantName : account.clientName}
                    </div>
                    <div style={{ width: "10%" }}>
                        {modified === account.emailAccount ?
                            <div style={{ height: "100%" }}>
                                <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CustomButton width={"80%"} height={"65%"} color={"#fff"} backgroundColor={"#666666"} onClick={() => cancelChanges()} value={t('buttons.cancel')} />
                                </div>
                                <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <CustomButton width={"80%"} height={"65%"} color={"#fff"} backgroundColor={"#90c261"} onClick={() => confirmChanges()} value={t('buttons.confirm')} />
                                </div>
                            </div>
                            :
                            <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <CustomButton width={"80%"} height={"32.5%"} color={"#fff"} backgroundColor={"#F0AC62"} onClick={() => { cancelChanges(); setModified(account.emailAccount) }} value={t('buttons.modify')} />
                            </div>}
                    </div>
                </div>
            )
        }
        return result;
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh", display: "flex", justifyContent: "center", color: "#666666", flexDirection: "column" }}>
            <CustomBlock
                width={"100%"}
                height={"100%"}>
                <div style={{ height: "15%", display: "flex" }}>
                    <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", fontSize: "2vh" }}>
                        {t('table.emailAdress')}
                    </div>
                    <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", fontSize: "2vh" }}>
                        {t('table.password')}
                    </div>
                    <div style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", fontSize: "2vh" }}>
                        {t('table.typeAccount')}
                    </div>
                    <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", fontSize: "2vh" }}>
                        {t('table.associated')}
                    </div>
                    <div style={{ width: "10%" }}>

                    </div>
                </div>
                <div style={{ height: "85%" }}>
                    <CustomScrollDiv update={Math.random()}>
                        {getRows()}
                    </CustomScrollDiv>
                </div>
            </CustomBlock>
        </div >
    )
}


function mapStateToProps(state) {
    return {
        demoAccounts: state.adminReducer.demoAccounts,
        informations: state.adminReducer.allInformations
    };
}

export default connect(mapStateToProps)(ClientListDemoAccounts);
