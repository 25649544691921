import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/objectives`;

export function getObjectivesOfRestaurant(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/objectives", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function updateObjectif(objectif) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/updateObjectif", {
                params: { objectif: objectif },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function createObjectivesForRestaurant(restaurant_name){
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/createObjectivesForRestaurant", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}