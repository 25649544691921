import moment from 'moment';
import { getSensiSvgs, getSensiValues, getSensiValuesCommodity } from '../SensiFunctions';
import { getCo2Eq, getTravelValues } from '../SynthesisFunctions';


const getTravel = (values) => {
    let number = 0;
    let type = "";
    let travel = "";
    let name = ""
    if (values.planeNumber > 1) {
        number = values.planeNumber;
        type = values.planeType;
        travel = values.planeTravel;
        name = "plane"
    }
    else if (values.carNumber > 1) {
        number = values.carNumber;
        type = values.carType;
        travel = values.carTravel;
        name = "car"
    }
    else {
        number = values.trainNumber;
        type = values.trainType;
        travel = values.trainTravel;
        name = "train"
    }
    return {
        number: number,
        type: type,
        travel: travel,
        name: name
    }
}

export const getAdultSlide = (pres, t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, type, valuesize, restaurant_name, food_list, food_list_kitchen, costType, averageTrays, displayName) => {
    let slide = pres.addSlide();
    let values;
    if (costType === "commodity_cost") values = getSensiValuesCommodity(t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, type, food_list, food_list_kitchen, costType, averageTrays);
    else values = getSensiValues(t, analysis, kitchenAnalysis, previousAnalysis, previousKitchenAnalysis, type, averageTrays);

    let travelValues = getTravelValues(values.co2);
    let travels = getTravel(travelValues);
    let dateStart;
    let dateEnd;
    let lastDate;
    let leftSide = travels.travel.split(" - ")[0];
    let rightSide = travels.travel.split(" - ")[1];

    if (analysis) {
        dateStart = moment(analysis.dateStart).format("DD/MM/YYYY");
        dateEnd = moment(analysis.dateEnd).format("DD/MM/YYYY");
    }
    else if (kitchenAnalysis) {
        dateStart = moment(kitchenAnalysis.dateStart).format("DD/MM/YYYY");
        dateEnd = moment(kitchenAnalysis.dateEnd).format("DD/MM/YYYY");
    }
    if (previousAnalysis) {
        lastDate = moment(previousAnalysis.dateStart).format("MMMM YYYY")
    }
    else if (previousKitchenAnalysis) {
        lastDate = moment(previousKitchenAnalysis.dateStart).format("MMMM YYYY")
    }


    /* ------------------------------- Top Corner ------------------------------- */
    slide.addImage({ path: require('../sensi_assets/adultes/rightCornerPPT.png'), x: "70 %", y: "0%", w: "30%", h: "16%" });
    slide.addText(t('sensibilisation3.represent'), { x: "68%", y: "17%", w: "30%", h: "5%", lineSpacing: 25, align: "left", fontFace: 'Muli', color: '#9EC97F', fontSize: 30, bold: true });



    /* -------------------------------- Titre PPT ------------------------------- */
    slide.addText(t('sensibilisation3.foodwaste'), { x: "5.5%", y: "2%", w: "70%", h: "10%", align: "left", fontFace: 'Muli', color: '54B0E3', fontSize: 50, lineSpacing: 46, bold: true });
    slide.addText(t(`sensi_adult_day.by${type}`), { x: "5.5%", y: "11%", w: "60%", h: "5%", align: "left", fontFace: 'Muli', color: '#9EC97F', fontSize: 32 });


    slide.addText(displayName, { x: "5.5%", y: "14.5%", w: "60%", h: "5%", align: "left", fontFace: 'Muli', color: '#666666', fontSize: 20 });


    /* ------------------------------ Improve Data ------------------------------ */
    slide.addImage({ path: require('../sensi_assets/adultes/rectangleAnalytics.png'), x: "0%", y: "20%", w: "65%", h: "19%" });
    slide.addImage({ path: require('../sensi_assets/adultes/spagettiBolo.png'), x: "46%", y: "22.5%", w: "20%", h: "13%" });
    slide.addShape(pres.shapes.LINE, { x: "21%", y: "28%", w: "8%", h: "0.0%", line: { color: "#676767", width: 2, dashType: "dash", endArrowType: "arrow" } });

    /* ---------------------------- Previous Number ----------------------------- */
    slide.addText(values.previousMassConv, {
        x: "4.5%", y: "22.5%", w: "13.2%", h: "9%", align: "center", fontFace: 'Muli', color: '#FFFFFF', fontSize: 16, bold: true, shape: pres.shapes.OVAL,
        fill: { color: "#9BCBB5" }
    });
    slide.addText(t('sensibilisation3.bypeople'), { x: "1%", y: "28.2%", w: "20%", h: "1%", align: "center", fontFace: 'Muli', color: '#FFFFFF', fontSize: 10, bold: false })
    slide.addText(t('sensibilisation3.previousNumber'), {
        x: "2%", y: "22.5%", w: "18%", h: "3%", align: "center", fontFace: 'Muli',  color: '#FFFFFF', fontSize: 10, bold: false, shape: pres.shapes.ROUNDED_RECTANGLE,
        fill: { color: "#9BCBB5" }, rectRadius: 0.1
    });

    /* ---------------------------- Actual Number ------------------------------- */
    slide.addText(values.currentMassConv, {
        x: "31.5%", y: "22.5%", w: "13.2%", h: "9%", align: "center", fontFace: 'Muli', color: '#FFFFFF', fontSize: 16, bold: true, shape: pres.shapes.OVAL,
        fill: { color: "#9BCBB5" }
    });
    slide.addText(t('sensibilisation3.bypeople'), { x: "28%", y: "28.2%", w: "20%", h: "1%", align: "center", fontFace: 'Muli', color: '#FFFFFF', fontSize: 10, bold: false })
    slide.addText(t('sensibilisation3.actualNumber'), {
        x: "29%", y: "22.5%", w: "18%", h: "3%", align: "center", fontFace: 'Muli',  color: '#FFFFFF', fontSize: 10, bold: false, shape: pres.shapes.ROUNDED_RECTANGLE,
        fill: { color: "#9BCBB5" }, rectRadius: 0.1
    });

    /* ---------------------------------- Delta --------------------------------- */
    slide.addText(t('sensibilisation3.wastedecrease', { value: lastDate }), { x: "8%", y: "33.5%", w: "35%", h: "3%", lineSpacing: "16", align: "center", fontFace: 'Muli',  color: '#FFFFFF', fontSize: 10, bold: false, shape: pres.shapes.ROUNDED_RECTANGLE, fill: { color: "#7ABCE2" }, rectRadius: 0.1 });
    slide.addText(values.evo, { x: "18%", y: "31.5%", w: "15%", h: "3%", align: "center", fontFace: 'Muli',  color: '#FFFFFF', fontSize: 18, bold: true, shape: pres.shapes.ROUNDED_RECTANGLE, fill: { color: "#7ABCE2" }, rectRadius: 0.1 });


    /* ------------------------------- Other Stats ------------------------------ */

    /* ---------------------------------- Trash --------------------------------- */
    slide.addImage({ path: require('../sensi_assets/adultes/rectanglePoubelle.png'), x: "67%", y: "22.5%", w: "30%", h: "24%" });
    slide.addText(t('mass', { value: { "mass": values.mass, "unit": "kg", fixed: 0 } }), { x: "72%", y: "25%", w: "20%", h: "3%", align: "center", fontFace: 'Muli',  color: '#7ABCE2', fontSize: valuesize, bold: true });
    slide.addText(t('sensibilisation3.foodName'), { x: "69%", y: "28%", w: "25%", h: "3%", align: "center", fontFace: 'Muli',  color: '#7ABCE2', fontSize: 15, bold: false });
    slide.addText(t('sensi_adult_day.trashday', { value: t(`sensi_adult_day.by${type}`) }), { x: "69%", y: "31%", w: "25%", h: "3%", align: "center", fontFace: 'Muli',  color: '#666666', fontSize: 15, bold: false });
    slide.addImage({ path: require('../sensi_assets/adultes/poubelle.png'), x: "74%", y: "35%", w: "15%", h: "11%" });

    /* --------------------------------- Money ----------------------------------- */
    slide.addImage({ path: require('../sensi_assets/adultes/rectangleMoney.png'), x: "67%", y: "47%", w: "30%", h: "24%" });
    slide.addImage({ path: require('../sensi_assets/adultes/piece.png'), x: "73%", y: "57%", w: "18%", h: "13%" });
    slide.addText(t('sensibilisation3.moneyBy'), { x: "67.5%", y: "53%", w: "28%", h: "15%", h: "3%", align: "center", fontFace: 'Muli',  color: '#90C261', fontSize: 12, bold: false });
    slide.addText(t('currency', { value: { "cost": values.cost, "fixed": 0 } }), { x: "73%", y: "50%", w: "20%", h: "3%", align: "center", fontFace: 'Muli',  color: '#90C261', fontSize: valuesize, bold: true });

    /* ----------------------------------- Watter ------------------------------- */
    slide.addImage({ path: require('../sensi_assets/adultes/rectangleEau.png'), x: "4%", y: "55.25%", w: "62.5%", h: "15.75%" });
    slide.addImage({ path: require('../sensi_assets/adultes/splashEau.png'), x: "49%", y: "52%", w: "24%", h: "18%" });
    slide.addText(t('sensibilisation3.litervalue', { value: values.water }), { x: "6%", y: "58%", w: "35%", h: "3%", align: "center", fontFace: 'Muli',  color: '#7AC2E2', fontSize: valuesize, bold: true });
    slide.addText(t('sensibilisation.waterused'), { x: "6%", y: "61%", w: "35%", h: "3%", align: "center", fontFace: 'Muli',  color: '#7AC2E2', fontSize: 15, bold: false });
    slide.addText(t('sensibilisation3.alimentProduction'), { x: "6%", y: "63%", w: "35%", h: "6%", lineSpacing: "16", align: "center", fontFace: 'Muli',  color: '#666666', fontSize: 15, bold: false });

    /* ---------------------------------- Meal ---------------------------------- */
    slide.addImage({ path: require('../sensi_assets/adultes/rectanglePlats.png'), x: "4%", y: "39.5%", w: "62.5%", h: "15.75%" });
    slide.addImage({ path: require('../sensi_assets/adultes/penneArabiata.png'), x: "40%", y: "47%" });
    slide.addImage({ path: require('../sensi_assets/adultes/platPoisson.png'), x: "52%", y: "47%" });
    slide.addImage({ path: require('../sensi_assets/adultes/saladeGrec.png'), x: "40%", y: "41.5%" });
    slide.addImage({ path: require('../sensi_assets/adultes/saladeMixte.png'), x: "52%", y: "41.5%" });
    slide.addText(t('sensi_adult_day.meal'), { x: "6%", y: "45.5%", w: "34%", h: "3%", align: "center", fontFace: 'Muli',  color: '#9BCBB5', fontSize: 15, bold: true });
    slide.addText(values.meals, { x: "6%", y: "42.25%", w: "34%", h: "3%", align: "center", fontFace: 'Muli',  color: '#9BCBB5', fontSize: valuesize, bold: true });
    slide.addText(t('sensi_adult_day.foodloose'), { x: "6%", y: "49%", w: "34%", h: "3%", lineSpacing: "16", align: "center", fontFace: 'Muli',  color: '#666666', fontSize: 15, bold: false });

    /* ---------------------------------- Bread ---------------------------------- */
    slide.addImage({ path: require('../sensi_assets/adultes/rectanglePain.png'), x: "4%", y: "71%", w: "45%", h: "24%" });
    slide.addImage({ path: require('../sensi_assets/adultes/painComplet.png'), x: "3%", y: "70%", w: "20%", h: "15%" });
    slide.addText(t('mass', { value: { "mass": values.bread, "unit": "kg", fixed: type === "day" ? 1 : 0 } }), { x: "25%", y: "74%", w: "20%", h: "3%", align: "center", fontFace: 'Muli',  color: '#90C261', fontSize: valuesize, bold: true });
    slide.addText(t('sensibilisation3.ofBread'), { x: "20.5%", y: "78%", w: "28%", align: "center", fontFace: 'Muli',  color: '#90C261', h: "3%", fontSize: 15, bold: true });
    slide.addText(t('sensibilisation3.itsAlso'), { x: "20.5%", y: "81%", w: "28%", align: "center", fontFace: 'Muli',  color: '#666666', h: "3%", fontSize: 15, bold: false });
    slide.addText(values.breadNumber, { x: "20.5%", y: "84.5%", w: "28%", align: "center", fontFace: 'Muli', h: "3%",  color: '#90C261', fontSize: valuesize, bold: true });
    slide.addText(t('sensibilisation3.pieceBread'), { x: "20.5%", y: "88%", w: "28%", align: "center", fontFace: 'Muli',  color: '#90C261', h: "3%", fontSize: 15, bold: true });

    /* ----------------------------------- Co2 ---------------------------------- */
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, { x: "50%", y: "71.8%", w: "47%", h: "57%", line: { color: "#9BCBB5", width: 2, dashType: "dash" }, rectRadius: 0.2 });
    slide.addText(t('sensibilisation3.co2Eq'), { x: "60%", y: "72%", w: "28%", h: "6%", lineSpacing: "16", align: "center", fontFace: 'Muli',  color: '#666666', fontSize: 15, bold: false });
    slide.addText(t('sensi_adult_day.ofCo2', { value: t('mass', { value: { "mass": values.co2, "unit": "kg", fixed: type === "day" ? 1 : 0 } }) }), { x: "55%", y: "80%", w: "40%", h: "5%", lineSpacing: 25, align: "center", fontFace: 'Muli',  color: '#71B9A1', fontSize: valuesize, bold: true });
    slide.addText(t('sensibilisation3.arequivalent', { number: travels.number, type: travels.type, travel: travels.travel, transport: t(`transport.${travels.name}`) }), { x: "51.5%", y: "95%", w: "45%", h: "5%", lineSpacing: "16", align: "center", fontFace: 'Muli',  color: '#666666', fontSize: 15, bold: false });

    if (travels.name === "plane") {
        slide.addImage({ path: require('../sensi_assets/adultes/chemin.png'), x: "63%", y: "92.5%", w: "20%", h: "2%" });
        slide.addImage({ path: require('../sensi_assets/adultes/drapeauFrance.png'), x: "59%", y: "85%", w: "11%", h: "9%" });
        slide.addImage({ path: require('../sensi_assets/adultes/drapeauStates.png'), x: "82%", y: "85.5%", w: "11%", h: "9%" });
    }
    else {
        slide.addImage({ path: require('../sensi_assets/adultes/chemin.png'), x: "65%", y: "92%", w: "20%", h: "2%" });
        slide.addImage({ path: require('../sensi_assets/adultes/signTravel.png'), x: "58%", y: "87%", w: "10%", h: "7%", rotate: "-15" });
        slide.addImage({ path: require('../sensi_assets/adultes/signTravel.png'), x: "81%", y: "87%", w: "10%", h: "7%" });
        slide.addText(leftSide, { x: "56%", y: "87%", w: "12%", h: "3%", align: "center", rotate: "-12.3", fontFace: 'Muli', color: '#666666', fontSize: 12, bold: false });
        slide.addText(rightSide, { x: "80%", y: "87%", w: "12%", h: "3%", align: "center", rotate: "7.5", fontFace: 'Muli', color: '#666666', fontSize: 12, bold: false });
    }
    /* ------------------------- Restaurant Information ------------------------- */
    slide.addText(t('sensi_adult_day.analysisdate', { value1: dateStart, value2: dateEnd }), { x: "20%", y: "94.5%", w: "35%", h: "5%", lineSpacing: "16", align: "left", fontFace: 'Muli',  color: '#666666', fontSize: 10, bold: false });
    // slide.addText(t('sensibilisation2.value', { value: restaurant_name }), { x: "36%", y: "0%", w: "60%", h: "5%", align: "right", fontFace: 'Baloo 2 Extrabold', color: '#666666', fontSize: 12, bold: false });

    /* -------------------------- Small left rectangle -------------------------- */
    slide.addImage({ path: require('../sensi_assets/adultes/bottomLeft.png'), x: "0%", y: "87 %", w: "20%", h: "14%" })

    return pres;
}