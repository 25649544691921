import React from "react";
import { connect } from "react-redux"; // REDUX
import SearchTop from "./SearchTop";
import SearchSelection from "./SearchSelection";
import SearchGraph from "./SearchGraph";

const Search = (props) => {
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <div style={{ height: "35%", width: "100%" }}>
                <SearchSelection
                    selectedRoom={props.selectedRoom}
                    all_dates_samples_bin={props.all_dates_samples_bin}
                    analyzes={props.analyzesDatas}
                    kitchenDatas={props.kitchenDatas} userReducer={props.userReducer} food_list_kitchen={props.food_list_kitchen} aggregate={props.aggregate} food_list={props.food_list} selectedCategories={props.selectedCategories}
                    selectedFoods={props.selectedFoods} dateStart={props.dateStart} dateEnd={props.dateEnd}
                    cumulativeTotal={props.cumulativeTotal}
                    selectedService={props.selectedService} selectedCam={props.selectedCam} cams={props.cams} />
            </div>
            <div style={{ height: "65%", width: "100%" }}>
                <SearchGraph
                    samplesBin={props.samplesBin}
                    userState={props.userReducer} aggregate={props.aggregate} food_list={props.food_list} selectedCategories={props.selectedCategories}
                    selectedFoods={props.selectedFoods} dateStart={props.dateStart} dateEnd={props.dateEnd}
                    analyzes={props.analyzesDatas}
                    kitchenDatas={props.kitchenDatas}
                    selectedGraphValue={props.selectedGraphValue}
                    selectedService={props.selectedService}
                    selectedRoom={props.selectedRoom}
                    selectedCam={props.selectedCam}
                    averageTrays={props.averageTrays}
                    cumulativeTotal={props.cumulativeTotal} />
            </div>
        </div >
    )
}
function mapStateToProps(state) {
    return {
        userReducer: state.userReducer,
        restaurant_name: state.userReducer.selected_restaurant,
        aggregate: state.searchReducer.aggregate,
        food_list: state.datasReducer.food_list,
        selectedFoods: state.searchReducer.selectedFoods,
        selectedCategories: state.searchReducer.selectedCategories,
        dateStart: state.searchReducer.dateStart,
        dateEnd: state.searchReducer.dateEnd,
        selectedGraphValue: state.searchReducer.selectedGraphValue,
        analyzesDatas: state.datasReducer.analyzesBaseDatas,
        selectedRoom: state.searchReducer.selectedRoom,
        selectedService: state.searchReducer.selectedService,
        selectedCam: state.searchReducer.selectedCam,
        cams: state.userReducer.cams,
        kitchenDatas: state.datasReducer.kitchenDatas,
        food_list_kitchen: state.datasReducer.food_list_kitchen,
        averageTrays: state.userReducer.averageGuests,
        samplesBin: state.datasReducer.samplesBin,
        all_dates_samples_bin: state.datasReducer.all_dates_samples_bin,
        cumulativeTotal: state.searchReducer.cumulativeTotal
    };
}

export default connect(mapStateToProps)(Search);