export function setObjectives(objectives) {
    return {
        type: 'SET_OBJECTIVES',
        objectives: objectives
    }
}

export function setSelectedObjectif(selectedObjectif) {
    return {
        type: 'SET_SELECTED_OBJECTIF',
        selectedObjectif: selectedObjectif
    }
}
export function hardResetObjectives() {
    return {
        type: 'HARD_RESET_OBJECTIVES'
    }
}