import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/samplesBin`;

export function getSamplesBin(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getSamplesBin", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function updateSamplesBin(samplesBin, restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateSamplesBin", {
                params: { restaurant_name: restaurant_name, samplesBin: samplesBin },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else {
                    window.alert("Une erreur s'est produite : " + err)
                    reject('error');
                }
            })
    });
}
export function deleteSamplesBin(samplesBin, restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .delete(back_url + "/deleteSamplesBin", {
                params: { restaurant_name: restaurant_name, samplesBin: samplesBin },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else {
                    window.alert("Une erreur s'est produite : " + err)
                    reject('error');
                }
            })
    });
}
export function getAllDatesSamplesBin(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getAllDatesSamplesBin", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}