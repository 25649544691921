import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import LineChart from "../../../charts/LineChart";
import { useDispatch } from "react-redux";
import { setSelectedDataTypeDashboard } from "../../../../actions_reducers/actions/dashboard.action";
import CustomSwitch from "../../../customComponents/CustomSwitch";
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const KitchenEvo = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const getColor = () => {
        switch (props.state.selectedDataType) {
            case 'restaurant':
                return '#90C261';
            case 'kitchen':
                return '#54B0E3';
            default:
                return '#949494';
        }
    }
    const getTooltip = (value) => {
        if (props.state.selectedDataType === "kitchen")
            return t('components.wasteKitchen', { value: value });
        else return t('components.wasteRestaurant', { value: value });
    }
    const getData = () => {
        let result = [
            {
                id: "analyzes_values",
                color: "#949494",
                data: [

                ]
            }
        ];
        let max = 0;
        let analyzes = props.analyzesDatas;
        let kitchenAnalyzes = props.kitchenDatas;
        let allDates = [];
        if (props.state.selectedDataType === "restaurant") {
            for (const analysis of analyzes) {
                allDates.push(moment(analysis.dateEnd).add(-7, "days").format("MM/YY"))
                if (analysis.analysisNumber > max) max = analysis.analysisNumber

            }
        }
        else {
            for (const analysis of kitchenAnalyzes) {
                allDates.push(moment(analysis.dateEnd).add(-7, "days").format("MM/YY"))
                if (analysis.analysisNumber > max) max = analysis.analysisNumber

            }
        }
        if (props.state.selectedAnalysisType === 'massByConv') {
            analyzes = props.baseAnalysis;
            kitchenAnalyzes = props.baseAnalysisKitch
        }
        if (props.state.selectedDataType === "restaurant") {
            for (const analysis of analyzes) {
                let newDate = moment(analysis.dateEnd).add(-7, "days")
                let singleData = {
                    name: moment(newDate).format("MM/YY"),
                    x: analysis.analysisNumber,
                    y: ''
                };
                switch (props.state.selectedAnalysisType) {

                    case 'massByConv':
                        var massByConv = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / (parseFloat(analysis.trays) ? parseFloat(analysis.trays) : 1);
                        singleData.y = parseFloat(t('mass', { "value": { "mass": massByConv, "unit": "g" } }));
                        singleData.tooltip = t('mass', { "value": { "mass": massByConv, "unit": "g" } });
                        break;
                    case 'mass':
                        var mass = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass))
                        singleData.y = parseFloat(t('mass', { "value": { "mass": mass, "unit": "kg" } }));
                        singleData.tooltip = t('mass', { "value": { "mass": mass, "unit": "kg" } });
                        break;
                    case 'cost':
                        var cost = parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)
                        singleData.y = cost;
                        singleData.tooltip = t('currency', { "value": { cost: cost } });
                        break;
                    case 'co2':
                        var co2 = (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2))
                        singleData.y = parseFloat(t('mass', { "value": { "mass": co2, "unit": "kg" } }));
                        singleData.tooltip = t('mass', { "value": { "mass": co2, "unit": "kg" } });
                        break
                    default:
                        var defaultValue = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseFloat(analysis.trays);
                        singleData.y = parseFloat(t('mass', { "value": { "mass": defaultValue, "unit": "g" } }));
                        singleData.tooltip = t('mass', { "value": { "mass": defaultValue, "unit": "g" } });
                        break;
                }
                result[0].data.push(singleData);
            }
        }
        else {
            for (const analysis of kitchenAnalyzes) {
                let newDate = moment(analysis.dateEnd).add(-7, "days")
                let singleData = {
                    name: moment(newDate).format("MM/YY"),
                    x: analysis.analysisNumber,
                    y: ''
                };
                switch (props.state.selectedAnalysisType) {
                    case 'massByConv':
                        singleData.y = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseFloat(analysis.trays);
                        singleData.tooltip = getTooltip(t('mass', { "value": { "mass": singleData.y, "unit": "g" } }))
                        break;
                    case 'mass':
                        singleData.y = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / 1000;
                        singleData.tooltip = getTooltip(t('mass', { "value": { "mass": singleData.y * 1000, "unit": "kg" } }))
                        break;
                    case 'cost':
                        singleData.y = parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost);
                        singleData.tooltip = getTooltip(t('currency', { "value": { cost: Math.abs(singleData.y).toFixed(0) } }))
                        break;
                    case 'co2':
                        singleData.y = (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2)) / 1000;
                        singleData.tooltip = getTooltip(t('mass', { "value": { "mass": singleData.y * 1000, "unit": "kg" } }))
                        break;
                    default:
                        singleData.y = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseFloat(analysis.trays);
                        singleData.tooltip = getTooltip(t('mass', { "value": { "mass": singleData.y, "unit": "g" } }))
                        break;
                }
                result[0].data.push(singleData);
            }

        }
        if (result[0].data[0]) {
            for (var i = 1; i <= max; i++) {
                let date = result[0].data.find(a => a.name === allDates[i])
                if (!date && allDates[i])
                    result[0].data.push({
                        name: allDates[i],
                        x: allDates[i].x,
                        y: 0
                    })
                result[0].data.sort((a, b) => a.x - b.x)
                if (result[0].data.length > 11) {
                    let indexes = [];
                    let max = result[0].data.length - 11;
                    for (let i = 1; i <= max; i++) {
                        indexes.push(i + 1)
                    }
                    for (const i of indexes) {
                        let index = result[0].data.findIndex(a => a.x === i)
                        result[0].data.splice(index, 1)
                    }
                    result[0].data.push({
                        name: "......",
                        x: 2,
                        y: result[0].data[0].y,
                        color: 'transparent'

                    })
                    result[0].data.sort((a, b) => a.x - b.x)
                }
            }
            result[0].data.sort((a, b) => a.x - b.x)
            let data1 = JSON.parse(JSON.stringify(result[0].data[0]));
            data1.name = "";
            data1.x = 0;
            result[0].data.unshift(data1);
            let data2 = JSON.parse(JSON.stringify(result[0].data[result[0].data.length - 1]));
            data2.name = "";
            data2.x = result[0].data[result[0].data.length - 1].x + 1;
            result[0].data.push(data2);
        }
        return result;
    }
    const changeDashboardDataType = () => {
        if (props.state.selectedDataType === "restaurant")
            dispatch(setSelectedDataTypeDashboard("kitchen"))
        else
            dispatch(setSelectedDataTypeDashboard("restaurant"))

    }
    const getMassSelectedAnalysis = () => {
        let mass = 0;
        let co2 = 0;
        if (props.state.selectedAnalysisNumber) {
            let analysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber))
            let kitchenAnalysis = props.kitchenDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber))
            if (analysis) {
                mass += parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)
                co2 += parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2)
            }
            if (kitchenAnalysis) {
                mass += parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass)
                co2 += parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2)

            }
        }
        return { mass: mass, co2: co2 };
    }
    const getLegend = () => {
        let mass = getMassSelectedAnalysis().mass;
        let co2 = getMassSelectedAnalysis().co2;
        if (props.state.selectedAnalysisType === "cost") return t('graph.cost', { unit: props.userState.costUnit });
        if (props.userState.massUnit === "lbs") {
            if (props.state.selectedAnalysisType === "massByConv") return t('graph.massByConv', { unit: "oz" });
            else if (props.state.selectedAnalysisType === "mass") return t('graph.mass', { unit: mass / 453.6 > 10000 ? "st" : "lbs" });
            else if (props.state.selectedAnalysisType === "co2") return t('graph.co2', { unit: co2 / 453.6 > 10000 ? "st" : "lbs" });
        }
        else {
            if (props.state.selectedAnalysisType === "massByConv") return t('graph.massByConv', { unit: "g" });
            else if (props.state.selectedAnalysisType === "mass") return t('graph.mass', { unit: mass > 10000000 ? "T" : "kg" });
            else if (props.state.selectedAnalysisType === "co2") return t('graph.co2', { unit: co2 > 10000000 ? "T" : "kg" });
        }
    }
    return (
        <CustomBlock width={'57%'} height={'45vh'} marginLeft={"3%"}>
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title" style={{ height: "6%", width: "100%" }}>
                    <div style={{ width: "70%", height: "100%" }}>
                        {t(`components.graph${props.state.selectedAnalysisType}`)}

                    </div>
                    <div style={{ width: "30%", height: "130%" }}>
                        <CustomSwitch leftText={t('components.kitchenSide')} rightText={t('components.restaurantSide')} onClick={() => changeDashboardDataType()} />

                    </div>
                </div>
                <div style={{ fontSize: ".8vw", color: "#666666" }}>
                    {getLegend()}
                </div>
                <div className="dashboard-graph-body">
                    <LineChart
                        id={"analysis-kitchen" + props.state.selectedDataType}
                        data={getData()} color={"#949494"} barColor={getColor()}
                        selectedAnalysisNumber={props.state.selectedAnalysisNumber} />
                </div>
            </div>
        </CustomBlock>
    )
}
export default KitchenEvo;