const initialState = {
    objectives: [],
    selectedObjectif: "Objectif global"
}


const objectivesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_OBJECTIVES':
            return {
                ...state,
                objectives: action.objectives
            }
        case 'SET_SELECTED_OBJECTIF':
            return {
                ...state,
                selectedObjectif: action.selectedObjectif
            }

        case 'HARD_RESET_OBJECTIVES':
            return initialState;
        default:
            return state;
    }
}

export default objectivesReducer;