const initialState = {
    selectedPageRessources: null,
    paths: []
}


const ressourcesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_PAGE_RESSOURCES':
            return {
                ...state,
                selectedPageRessources: action.selectedPageRessources
            }
        case 'SET_PATHS':
            return {
                ...state,
                paths: action.paths
            }
        default:
            return state;
    }
}

export default ressourcesReducer;