import React from "react";
import ClientPicturesSelect from "./ClientPicturesSelect";
import ClientPicturesShowed from "./ClientPicturesShowed";

const separationHeight = "6vh";
const ClientPictures = (props) => {

    return (
        <div className="main-page-main-div">
            <div style={{ width: "100%", height: "5vh" }}>
                <ClientPicturesSelect
                    displayNames={props.displayNames}
                    analyzes={props.clientAnalyzes}
                    selectedRestaurantPictures={props.selectedRestaurantPictures}
                    selectedAnalysis={props.selectedAnalysis}
                    selectedDate={props.selectedDate} />
            </div>
            <div style={{ height: separationHeight }} />
            <div style={{ height: "74.8vh", width: "100%" }}>
                <ClientPicturesShowed
                    selectedDate={props.selectedDate}
                    selectedRestaurantPictures={props.selectedRestaurantPictures}
                    picturesListClient={props.picturesListClient}
                    food_list={props.food_list} />
            </div>
        </div >
    )
}

export default ClientPictures;
