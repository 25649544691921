import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import 'moment/locale/fr';
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import { setSelectedAnalysisMenus, setSelectedDateMenus, setSelectedServiceMenus } from "../../../../actions_reducers/actions/datas.action";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);
const separationHeight = "6vh";
const separationWidth = "6%";

const MenusSelection = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const getDisplayedValueAnalysis = () => {
        if (props.selectedAnalysis == null)
            return t('components.selectMonth')
        else {
            let analysis = props.all_analyzes.find(a => a.analysisNumber === props.selectedAnalysis);
            return t('date', { value: { date: moment(analysis.dates[analysis.dates.length - 1]).subtract(7, 'day'), format: "MMMM YYYY" } })
        }
    }
    const updateAnalysis = (e) => {
        let analysis = props.all_analyzes.find(a => a.dates.find(b =>
            t('date', { value: { date: moment(b).subtract(7, 'day'), format: "MMMM YYYY" } }) === e.target.id
        ))
        dispatch(setSelectedAnalysisMenus(analysis.analysisNumber))
    }
    const getAnalyzes = () => {
        let result = [];
        let analyzes = JSON.parse(JSON.stringify(props.all_analyzes));
        analyzes.sort((a, b) => b.analysisNumber - a.analysisNumber)
        for (const analysis of analyzes) {
            let name = t('date', { value: { date: moment(analysis.dates[analysis.dates.length - 1]).subtract(7, 'day'), format: "MMMM YYYY" } })
            if (!result.find(a => a.name === name))
                result.push(name)
        }
        return result
    }
    const getDropdownAnalysis = () => {
        let values = getAnalyzes()
        return (
            <CustomDropdown
                width={"85%"}
                height={"50%"}
                backgroundColor={"#E7F3FA"}
                arrowColor={"#68B4E3"}
                color={"#949494"}
                maxHeightDropdown={"40vh"}
                values={values}
                displayedValue={getDisplayedValueAnalysis()}
                onChange={updateAnalysis}
            />
        )
    }

    const getDisplayedValueDate = () => {
        if (props.selectedDate == null)
            return t('components.selectDate');
        else return props.selectedDate;
    }
    const updateDate = (e) => {
        dispatch(setSelectedDateMenus(e.target.id))
    }
    const getDates = () => {
        let values = [];
        for (const analysis of props.all_analyzes) {
            if (props.selectedAnalysis == null || props.selectedAnalysis === analysis.analysisNumber) {
                for (const date of analysis.dates) {
                    let newDate = t('date', { value: { date: date } });
                    if (!values.find(a => a === newDate))
                        values.push(newDate);
                }
            }
        }
        values.sort((a, b) => moment(a, "DD-MM-YYYY").isBefore(moment(b, "DD-MM-YYYY")) ? -1 : 1)
        return values;
    }
    const getDropdownDate = () => {
        let values = getDates()
        return (
            <CustomDropdown
                width={"85%"}
                height={"50%"}
                backgroundColor={"#E7F3FA"}
                arrowColor={"#68B4E3"}
                color={"#949494"}
                values={values}
                maxHeightDropdown={"40vh"}
                displayedValue={getDisplayedValueDate()}
                onChange={updateDate}
            />
        )

    }

    const getDisplayedValueService = () => {
        return t(`hours.${props.selectedService}`);
    }
    const updateService = (e) => {
        if (e.target.id === t('hours.evening'))
            dispatch(setSelectedServiceMenus("evening"))
        else
            dispatch(setSelectedServiceMenus("noon"))
    }
    const getDropdownService = () => {
        let values = ["Midi", "Soir"]
        return (
            <CustomDropdown
                width={"85%"}
                height={"50%"}
                backgroundColor={"#E7F3FA"}
                arrowColor={"#68B4E3"}
                color={"#949494"}
                values={values}
                displayedValue={getDisplayedValueService()}
                onChange={updateService}
            />
        )

    }

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
            <div style={{ width: "28%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {getDropdownAnalysis()}
            </div>

            <div style={{ width: "28%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {getDropdownDate()}
            </div>

            {(props.has_noon && props.has_evening) && <div style={{ width: "28%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {getDropdownService()}
            </div>}
        </div>
    )
}

export default MenusSelection;