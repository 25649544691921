import React from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { ReactComponent as Book } from "../../../assets/book.svg";
import { useTranslation } from "react-i18next";
import { downLoadRessourceFile } from "../../../apiRoutes/files.routes";
const part1MiniEN = require('../../../assets/part1Mini.png');
const part2MiniEN = require('../../../assets/part2Mini.png');
const part3MiniEN = require('../../../assets/part3Mini.png');
const whiteBookMiniEN = require('../../../assets/whiteBookMini.png');


const RessourcesGaspi = (props) => {
    const { t } = useTranslation();

    const downloadFile = async (file) => {
        await downLoadRessourceFile("whiteBook/" + file, file);
    }

    const getFileToDownload = (type) => {
        if (type === "whiteBook") {
            if (props.language === "fr")
                return "Livre blanc.pdf";
            else return "White Book.pdf";
        }
        else if (type === "part1") {
            if (props.language === "fr")
                return "Partie 1 - 5 raisons de s'engager.pdf";
            else return "Part 1 - 5 reasons to get involved.pdf";
        }
        else if (type === "part2") {
            if (props.language === "fr")
                return "Partie 2 - Mise en place du diagnostic.pdf";
            else return "Part 2 - Setting up the diagnostic.pdf";
        }
        else if (type === "part3") {
            if (props.language === "fr")
                return "Partie 3 - Agir contre le GA.pdf";
            else return "Part 3 - Act against  Food waste.pdf";
        }
    }
    const getMini = (type) => {
        if (props.language === "fr") {
            if (type === "whiteBook")
                return <Book style={{ height: "100%" }} />
            else
                return <Book style={{ height: "80%" }} />
        }
        else {
            if (type === "whiteBook") {
                return <img alt={"whiteBookMini"} src={whiteBookMiniEN} style={{ height: "80%", boxShadow: "0px 4px 4px #666666" }} />;
            }
            else if (type === "part1") {
                return <img alt={"part1Mini"} src={part1MiniEN} style={{ height: "80%", boxShadow: "0px 4px 4px #666666" }} />;
            }
            else if (type === "part2") {
                return <img alt={"part2Mini"} src={part2MiniEN} style={{ height: "80%", boxShadow: "0px 4px 4px #666666" }} />;
            }
            else if (type === "part3") {
                return <img alt={"part3Mini"} src={part3MiniEN} style={{ height: "80%", boxShadow: "0px 4px 4px #666666" }} />;
            }
        }
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <div style={{ height: "8%", width: "100%", color: "#68B4E3", fontSize: "1.4vw", display: "flex" }}>
                {t('ressources.learnWaste')}
            </div>
            <div style={{ height: "40%", width: "100%" }}>
                <CustomBlock width={"100%"} height={"100%"} display={"flex"} flexDirection={"row"} >
                    <div style={{ width: "25%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {getMini("whiteBook")}
                    </div>
                    <div style={{ width: "60%", height: "100%" }} >
                        <div style={{ height: "2%" }}></div>
                        <div style={{ height: "12%", width: "100%", color: "#90C261", fontSize: "1.2vw", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                            {t('ressources.whiteBook')}
                        </div>
                        <div style={{ height: "12%", width: "100%", fontSize: "1.1vw", display: "flex", alignItems: "center", fontWeight: "bold" }}>
                            {t('ressources.wasteFight')}
                        </div>
                        <div style={{ position: "absolute", height: "38%", width: "70%", display: "flex", alignItems: "center" }}>
                            {t('ressources.firstLongTextWaste')}
                        </div>
                        <div style={{ height: "4%" }}></div>
                        <div style={{ position: "absolute", height: "100%", width: "70%", display: "flex", alignItems: "center" }}>
                            {t('ressources.secondLongTestWaste')}
                        </div>
                    </div>
                    <div style={{ width: "15%", height: "100%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end", paddingRight: "2%", paddingBottom: "1%" }} >

                        <button className='custom-button-gradient'
                            style={{
                                height: "4vh",
                                width: "9vw",
                                background: "#5BB3E4B3",
                                color: "#fff"
                            }}
                            onClick={() => downloadFile(getFileToDownload("whiteBook"))}>
                            {t('buttons.download')}
                        </button>
                    </div>
                </CustomBlock>
            </div>
            <div style={{ height: "10%", width: "100%", color: "#68B4E3", fontSize: "1.2vw", display: "flex", alignItems: "center" }}>
                {t('ressources.zoomChapter')}
            </div>
            <div style={{ height: "42%", width: "100%", display: "flex", flexDirection: "row" }}>
                <CustomBlock width={"30%"} height={"100%"} paddingTop={".5%"} paddingBottom={".5%"}>
                    <div style={{ height: "25%", textAlign: "center", fontWeight: "bold", fontSize: "1.1vw" }}>
                        {t('ressources.reasonsWaste')}
                    </div>
                    <div style={{ height: "55%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {getMini("part1")}
                    </div>
                    <div style={{ height: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button className='custom-button-gradient'
                            style={{
                                height: "4vh",
                                width: "9vw",
                                background: "#5BB3E4B3",
                                color: "#fff"
                            }}
                            onClick={() => downloadFile(getFileToDownload("part1"))}>
                            {t('buttons.download')}
                        </button>
                    </div>
                </CustomBlock>
                <div style={{ width: "5%" }} />
                <CustomBlock width={"30%"} height={"100%"} paddingTop={".5%"} paddingBottom={".5%"} >
                    <div style={{ height: "25%", textAlign: "center", fontWeight: "bold", fontSize: "1.1vw" }}>
                        {t('ressources.diagnosticWaste')}
                    </div>
                    <div style={{ height: "55%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {getMini("part2")}
                    </div>
                    <div style={{ height: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button className='custom-button-gradient'
                            style={{
                                height: "4vh",
                                width: "9vw",
                                background: "#5BB3E4B3",
                                color: "#fff"
                            }}
                            onClick={() => downloadFile(getFileToDownload("part2"))}>
                            {t('buttons.download')}
                        </button>
                    </div>
                </CustomBlock>
                <div style={{ width: "5%" }} />
                <CustomBlock width={"30%"} height={"100%"} paddingTop={".5%"} paddingBottom={".5%"} >
                    <div style={{ height: "25%", textAlign: "center", fontWeight: "bold", fontSize: "1.1vw" }}>
                        {t('ressources.actionWaste')}
                    </div>
                    <div style={{ height: "55%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {getMini("part3")}
                    </div>
                    <div style={{ height: "20%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <button className='custom-button-gradient'
                            style={{
                                height: "4vh",
                                width: "9vw",
                                background: "#5BB3E4B3",
                                color: "#fff"
                            }}
                            onClick={() => downloadFile(getFileToDownload("part3"))}>
                            {t('buttons.download')}
                        </button>
                    </div>
                </CustomBlock>

            </div>
        </div>
    )
}
export default RessourcesGaspi;