export function setHasWeekOnBoarding(hasWeek) {
    return {
        type: 'SET_HAS_WEEK_ON_BOARDING',
        hasWeek: hasWeek
    }
}
export function setHasSaturdayOnBoarding(hasSaturday) {
    return {
        type: 'SET_HAS_SATURDAY_ON_BOARDING',
        hasSaturday: hasSaturday
    }
}
export function setHasSundayOnBoarding(hasSunday) {
    return {
        type: 'SET_HAS_SUNDAY_ON_BOARDING',
        hasSunday: hasSunday
    }
}
export function setHasWeekNoon(hasWeekNoon) {
    return {
        type: 'SET_HAS_WEEK_NOON',
        hasWeekNoon: hasWeekNoon
    }
}
export function setHasWeekEvening(hasWeekEvening) {
    return {
        type: 'SET_HAS_WEEK_EVENING',
        hasWeekEvening: hasWeekEvening
    }
}
export function setHasSaturdayNoon(hasSaturdayNoon) {
    return {
        type: 'SET_HAS_SATURDAY_NOON',
        hasSaturdayNoon: hasSaturdayNoon
    }
}
export function setHasSaturdayEvening(hasSaturdayEvening) {
    return {
        type: 'SET_HAS_SATURDAY_EVENING',
        hasSaturdayEvening: hasSaturdayEvening
    }
}
export function setHasSundayNoon(hasSundayNoon) {
    return {
        type: 'SET_HAS_SUNDAY_NOON',
        hasSundayNoon: hasSundayNoon
    }
}
export function setHasSundayEvening(hasSundayEvening) {
    return {
        type: 'SET_HAS_SUNDAY_EVENING',
        hasSundayEvening: hasSundayEvening
    }
}
export function setWeekNoonHours(weekNoonHours) {
    return {
        type: 'SET_WEEK_NOON_HOURS',
        weekNoonHours: weekNoonHours
    }
}
export function setWeekEveningHours(weekEveningHours) {
    return {
        type: 'SET_WEEK_EVENING_HOURS',
        weekEveningHours: weekEveningHours
    }
}
export function setSaturdayNoonHours(saturdayNoonHours) {
    return {
        type: 'SET_SATURDAY_NOON_HOURS',
        saturdayNoonHours: saturdayNoonHours
    }
}
export function setSaturdayEveningHours(saturdayEveningHours) {
    return {
        type: 'SET_SATURDAY_EVENING_HOURS',
        saturdayEveningHours: saturdayEveningHours
    }
}
export function setSundayNoonHours(sundayNoonHours) {
    return {
        type: 'SET_SUNDAY_NOON_HOURS',
        sundayNoonHours: sundayNoonHours
    }
}
export function setSundayEveningHours(sundayEveningHours) {
    return {
        type: 'SET_SUNDAY_EVENING_HOURS',
        sundayEveningHours: sundayEveningHours
    }
}
export function setAverageGuestsOnBoarding(averageGuests) {
    return {
        type: 'SET_AVERAGE_GUESTS_ON_BOARDING',
        averageGuests: averageGuests
    }
}
export function setCamsOnBoarding(cams) {
    return {
        type: 'SET_CAMS_ON_BOARDING',
        cams: cams
    }
}
export function setServiceType(serviceType) {
    return {
        type: 'SET_SERVICE_TYPE',
        serviceType: serviceType
    }
}
export function setReasonService(reasonService) {
    return {
        type: 'SET_REASON_SERVICE',
        reasonService: reasonService
    }
}
export function setSelfFoods(selfFoods) {
    return {
        type: 'SET_SELF_FOODS',
        selfFoods: selfFoods
    }
}
export function setBreadPosition(breadPosition) {
    return {
        type: 'SET_BREAD_POSITION',
        breadPosition: breadPosition
    }
}
export function setBreadType(breadType) {
    return {
        type: 'SET_BREAD_TYPE',
        breadType: breadType
    }
}
export function setBreadOrigin(breadOrigin) {
    return {
        type: 'SET_BREAD_ORIGIN',
        breadOrigin: breadOrigin
    }
}
export function setBreadMass(breadMass) {
    return {
        type: 'SET_BREAD_MASS',
        breadMass: breadMass
    }
}
export function setBreadLimit(breadLimit) {
    return {
        type: 'SET_BREAD_LIMIT',
        breadLimit: breadLimit
    }
}
export function setNumberBread(numberBread) {
    return {
        type: 'SET_NUMBER_BREAD',
        numberBread: numberBread
    }
}
export function setBreadRefill(breadRefill) {
    return {
        type: 'SET_BREAD_REFILL',
        breadRefill: breadRefill
    }
}
export function setBreadMoment(breadMoment) {
    return {
        type: 'SET_BREAD_MOMENT',
        breadMoment: breadMoment
    }
}
export function setPortionType(portionType) {
    return {
        type: 'SET_PORTION_TYPE',
        portionType: portionType
    }
}
export function setAllowedRefill(allowedRefill) {
    return {
        type: 'SET_ALLOWED_REFILL',
        allowedRefill: allowedRefill
    }
}
export function setWhenRefill(whenRefill) {
    return {
        type: 'SET_WHEN_REFILL',
        whenRefill: whenRefill
    }
}
export function setWhyNotRefill(whyNotRefill) {
    return {
        type: 'SET_WHY_NOT_REFILL',
        whyNotRefill: whyNotRefill
    }
}
export function setNumberVege(numberVege) {
    return {
        type: 'SET_NUMBER_VEGE',
        numberVege: numberVege
    }
}
export function setVegeType(vegeType) {
    return {
        type: 'SET_VEGE_TYPE',
        vegeType: vegeType
    }
}
export function setWasteManagementMethodOnBoarding(wasteManagementMethod) {
    return {
        type: 'SET_WASTE_MANAGEMENT_METHOD_ON_BOARDING',
        wasteManagementMethod: wasteManagementMethod
    }
}
export function setHasActions(hasActions) {
    return {
        type: 'SET_HAS_ACTIONS',
        hasActions: hasActions
    }
}
export function setTypeActions(typeActions) {
    return {
        type: 'SET_TYPE_ACTIONS',
        typeActions: typeActions
    }
}
export function setWhyNotActions(whyNotActions) {
    return {
        type: 'SET_WHY_NOT_ACTIONS',
        whyNotActions: whyNotActions
    }
}
export function setTeamMotivation(teamMotivation) {
    return {
        type: 'SET_TEAM_MOTIVATION',
        teamMotivation: teamMotivation
    }
}
export function setTeamMotivationNote(teamMotivationNote) {
    return {
        type: 'SET_TEAM_MOTIVATION_NOTE',
        teamMotivationNote: teamMotivationNote
    }
}
export function setStakeholders(stakeholders) {
    return {
        type: 'SET_STAKEHOLDERS',
        stakeholders: stakeholders
    }
}
export function setProjectContact(projectContact) {
    return {
        type: 'SET_PROJECT_CONTACT',
        projectContact: projectContact
    }
}
export function setProjectContactMail(projectContactMail) {
    return {
        type: 'SET_PROJECT_CONTACT_MAIL',
        projectContactMail: projectContactMail
    }
}
export function setProjectContactPhone(projectContactPhone) {
    return {
        type: 'SET_PROJECT_CONTACT_PHONE',
        projectContactPhone: projectContactPhone
    }
}
export function setProjectReferent(projectReferent) {
    return {
        type: 'SET_PROJECT_REFERENT',
        projectReferent: projectReferent
    }
}
export function setProjectReferentMail(projectReferentMail) {
    return {
        type: 'SET_PROJECT_REFERENT_MAIL',
        projectReferentMail: projectReferentMail
    }
}
export function setProjectReferentPhone(projectReferentPhone) {
    return {
        type: 'SET_PROJECT_REFERENT_PHONE',
        projectReferentPhone: projectReferentPhone
    }
}
export function hardResetOnBoarding() {
    return {
        type: 'HARD_RESET_ON_BOARDING'
    }
}