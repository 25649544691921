import { getNumberDaysExtrap } from '../../../customFunctions/extrapolation.function';
import { addCatsBars, getCatValues } from '../SynthesisFunctions';

const getValues = (analysis, kitchenAnalysis, averageTrays) => {
    let mass = 0;
    let massKitchen = 0;
    let trays = 0;
    let traysKitchen = 0;

    let numberDaysExtrapWeek = getNumberDaysExtrap('week');
    let numberDaysExtrapYear = getNumberDaysExtrap('year');

    if (analysis) {
        mass = parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass);
        trays = parseInt(analysis.trays);
    }
    if (kitchenAnalysis) {
        massKitchen = parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass);
        traysKitchen = parseInt(kitchenAnalysis.trays);
    }

    let massTot = parseFloat(mass) + parseFloat(massKitchen);

    let massConv = trays > 0 && parseFloat(mass) / parseInt(trays);
    let massConvKitchen = traysKitchen > 0 && parseFloat(massKitchen) / parseInt(traysKitchen);
    let massConvTot = massConv + massConvKitchen;

    let massWeek = massConv * averageTrays * numberDaysExtrapWeek;
    let massKitchenWeek = massConvKitchen * averageTrays * numberDaysExtrapWeek;
    let massYear = massConv * averageTrays * numberDaysExtrapYear;
    let massKitchenYear = massConvKitchen * averageTrays * numberDaysExtrapYear;

    let massTotWeek = massWeek + massKitchenWeek
    let massTotYear = massYear + massKitchenYear
    let elephantValue = (massTotYear / 4000000).toFixed(0);

    return {
        massTot: massTot,
        massConvTot: massConvTot,
        massWeek: massTotWeek,
        massYear: massTotYear,
        elephantValue: elephantValue
    }

}
export const getFourthSlide = (pres, t, analysis, kitchenAnalysis, x, averageTrays) => {
    let slide = pres.addSlide();
    let values = getValues(analysis, kitchenAnalysis, averageTrays);
    let cats = getCatValues(analysis, kitchenAnalysis, 'mass');
    addCatsBars(pres, slide, t, cats, 'mass');

    slide.addImage({ path: require('../assets/4/Component 5.png'), x: "73.1%", y: "0%", w: "27%", h: "40%" })
    slide.addImage({ path: require('../assets/4/Ellipse 49.png'), x: "0%", y: "6.5%", w: "60%", h: "15%" })
    slide.addImage({ path: require('../assets/4/Ellipse 50.png'), x: "0%", y: "18%", w: "40%", h: "11%" })
    slide.addImage({ path: require('../assets/4/Group 6.png'), x: "82.1%", y: "29%", w: "18%", h: "34%" })
    slide.addImage({ path: require('../assets/4/elephant.png'), x: "84%", y: "49%", w: "11%", h: "17%" })
    slide.addImage({ path: require('../assets/4/textbox.png'), x: "59.1%", y: "41%", w: "18.5%", h: "13.5%" })
    slide.addImage({ path: require('../assets/4/textbox.png'), x: "59.1%", y: "57%", w: "18.5%", h: "13.5%" })
    slide.addImage({ path: require('../assets/4/textbox.png'), x: "59.1%", y: "73%", w: "18.5%", h: "13.5%" })
    slide.addText(t("synthesis.global.loosediagnostic"), { x: "2.5%", y: "8%", w: "45%", h: "10%", fontFace: 'Muli', align: "left",  color: 'FFFFFF', fontSize: 25, bold: true });
    slide.addText(t("synthesis.page4.wastequantity"), { x: "3%", y: "20.7%", w: "30%", h: "5%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 22, bold: true });
    slide.addText(t("synthesis.page4.tips"), { x: "77%", y: "6%", w: "17%", h: "5%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 19, bold: true });
    slide.addText(t("synthesis.page4.averagewaste"), { x: "77%", y: "14%", w: "21%", h: "13%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 14, bold: true });
    slide.addText(t("synthesis.page4.weightequivalent"), { x: "84.5%", y: "33%", w: "15%", h: "5%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 14, bold: true });
    slide.addText(values.elephantValue, { x: "85%", y: "40.5%", w: "10%", h: "10%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 30, bold: true });
    slide.addText(values.elephantValue > 1 ? t("synthesis.page4.elephants") : t("synthesis.page4.elephant"), { x: "87.9%", y: "41.1%", w: "11%", h: "9%", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 20, bold: true });
    slide.addText(t("synthesis.page4.guestquantity"), { x: "59.5%", y: "42%", w: "18%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 13, bold: true });
    slide.addText(t('mass', { value: { "mass": values.massConvTot, "unit": "g", fixed: 0 } }), { x: "60.7%", y: "48.2%", w: "16%", h: "5%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page4.weekquantity"), { x: "59.5%", y: "57.9%", w: "18%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 13, bold: true });
    slide.addText(t('mass', { value: { "mass": values.massWeek, "unit": "kg", fixed: 0 } }), { x: "60.7%", y: "64.25%", w: "16%", h: "5%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 17, bold: true });
    slide.addText(t("synthesis.page4.yearquantity"), { x: "59.5%", y: "74%", w: "18%", h: "5%", align: "center", fontFace: 'Muli',  color: 'F8F8F3', fontSize: 13, bold: true });
    slide.addText(t('mass', { value: { "mass": values.massYear, "unit": "T", fixed: 1 } }), { x: "60.7%", y: "80.2%", w: "16%", h: "5%", align: "center", fontFace: 'Muli',  color: '54B0E3', fontSize: 17, bold: true });
    slide.addText(x, { x: "97%", y: "96%", w: "3%", h: "3%", fontFace: 'Muli', align: "right",  color: '666666', fontSize: 15, bold: false });

    slide.background = { color: "#F8F8F3" }

    return pres;
}
