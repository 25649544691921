import React from "react";
import { useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import { setSelectedClientDropdownPlanning, setSelectedRestaurantDropdownPlanning, setSelectedTaskType } from "../../../../actions_reducers/actions/planning.action";
import CustomDropdown from "../../../customComponents/CustomDropdown";

const PlanningSelection = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const updateSelectedClient = (e) => {
        let client = e.target.id;
        if (client !== t('components.allClients'))
            dispatch(setSelectedClientDropdownPlanning(client));
        else
            dispatch(setSelectedClientDropdownPlanning(null));
    }
    const getDisplayedValueDropdownClient = () => {
        if (props.planningState.selectedClientDropdown === null)
            return t('components.allClients');
        else return props.planningState.selectedClientDropdown;
    }
    const getDropdownClient = () => {
        let clientList = []
        for (const restaurant of props.restaurants) {
            if (!clientList.find(a => a === restaurant.clientName))
                clientList.push(restaurant.clientName)
        }
        clientList.sort((a, b) => a.localeCompare(b));
        clientList.unshift(t('components.allClients'))
        return (
            <CustomDropdown
                height={"100%"}
                width={"90%"}
                arrowColor={"#fff"}
                color={"#fff"}
                backgroundColor={"#89CD85"}
                values={clientList}
                displayedValue={getDisplayedValueDropdownClient()}
                onChange={updateSelectedClient}
                maxHeightDropdown={"40vh"}
            />
        )
    }
    const getDisplayedValueDropdownRestaurant = () => {
        if (props.planningState.selectedRestaurantDropdown === null)
            return t('components.allRestaurants');
        else return props.planningState.selectedRestaurantDropdown;
    }
    const updateSelectedRestaurant = (e) => {
        let restaurant = e.target.id;
        if (restaurant !== t('components.allRestaurants'))
            dispatch(setSelectedRestaurantDropdownPlanning(restaurant))
        else
            dispatch(setSelectedRestaurantDropdownPlanning(null))
    }
    const getDropdownRestaurant = () => {
        let restaurantList = [];
        for (const restaurant of props.restaurants) {
            if (props.planningState.selectedClientDropdown === null) {
                restaurantList.push(restaurant.restaurantName);
            }
            else {
                if (restaurant.clientName === props.planningState.selectedClientDropdown)
                    restaurantList.push(restaurant.restaurantName)
            }
        }
        restaurantList.sort((a, b) => a.localeCompare(b));
        restaurantList.unshift(t('components.allRestaurants'))
        return (
            <CustomDropdown
                height={"100%"}
                width={"90%"}
                arrowColor={"#fff"}
                color={"#fff"}
                backgroundColor={"#89CD85"}
                values={restaurantList}
                displayedValue={getDisplayedValueDropdownRestaurant()}
                onChange={updateSelectedRestaurant}
                maxHeightDropdown={restaurantList.length > 7 && "40vh"}
            />
        )
    }
    const updateSelectedTask = (e) => {
        let value = e.target.id;
        if (value === t('components.seeAll')) {
            dispatch(setSelectedTaskType("all"))
        }
        else if (value === t('components.meetingsDates')) {
            dispatch(setSelectedTaskType("meetings"))
        }
        else if (value === t('components.analyzesPeriods')) {
            dispatch(setSelectedTaskType("analyzes"))
        }

    }
    const getDisplayedTask = () => {
        let value = props.planningState.selectedTaskType;
        if (value === "all") {
            return t('components.seeAll');
        }
        else if (value === "analyzes") {
            return t('components.analyzesPeriods');
        }
        else if (value === "meetings") {
            return t('components.meetingsDates');
        }
    }
    const getDropdownTaskType = () => {
        let tasksType = [
            t('components.seeAll'),
            t('components.meetingsDates'),
            t('components.analyzesPeriods')
        ];
        return (
            <CustomDropdown
                height={"100%"}
                width={"90%"}
                arrowColor={"#fff"}
                color={"#fff"}
                backgroundColor={"#68B4E3"}
                values={tasksType}
                displayedValue={getDisplayedTask()}
                onChange={updateSelectedTask}
            />
        )
    }

    return (
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
          <div style={{ width: "30%", height: "100%" }} />
          <div style={{ width: "70%", height: "100%", display: "flex", flexDirection: "row" }}>
              <div style={{ width: "35%", display: "flex", justifyContent: "flex-end" }}>
                  {getDropdownClient()}
              </div>
              <div style={{ width: "35%", display: "flex", justifyContent: "flex-end" }}>
                  {getDropdownRestaurant()}
              </div>
              <div style={{ width: "30%", display: "flex", justifyContent: "flex-end"}}>

                  {getDropdownTaskType()}
              </div>
          </div>
      </div>
  )
}
export default PlanningSelection;
