import React, { useState } from "react";
import { useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import CustomButton from "../../../customComponents/CustomButton";
import { mediumResetUser, setDisplayName, setHasLoaded, setSelectedRestaurant } from "../../../../actions_reducers/actions/user.action";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { setCurrentPage } from "../../../../actions_reducers/actions/navigation.action";
import { hardResetDashboard } from "../../../../actions_reducers/actions/dashboard.action";
import { setClientName } from "../../../../actions_reducers/actions/client.action";
import { hardResetDatas } from "../../../../actions_reducers/actions/datas.action";
import { resetAlerts } from "../../../../actions_reducers/actions/alerts.action";
import { setSelectedInformations, setSelectedRestaurantModifs } from "../../../../actions_reducers/actions/admin.action";
import { hardResetSearch } from "../../../../actions_reducers/actions/search.action";
import { resetPictures } from "../../../../actions_reducers/actions/pictures.action";

const ClientListTable = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState("");

    const navigateToTarget = (e, target) => {
        if (target === "dashboard") {
            let informations = props.informations.find(a => a.restaurantName === e.target.id)
            dispatch(setDisplayName(informations.displayName))
            dispatch(setHasLoaded(false))
            dispatch(setClientName(null))
            dispatch(mediumResetUser())
            dispatch(hardResetDashboard())
            dispatch(hardResetDatas())
            dispatch(hardResetSearch());
            dispatch(resetPictures());
            dispatch(resetAlerts());
            dispatch(setSelectedRestaurant(e.target.id))
            updateArianneThread("dashboard", props.arianne, dispatch, "");
            dispatch(setCurrentPage("dashboard"));
        }
        else if (target === "datas") {
            updateArianneThread("datas", props.arianne, dispatch, "");
            dispatch(setCurrentPage("datas"));
            dispatch(setSelectedRestaurantModifs(e.target.id));
        }
        else if (target === "addRestaurant") {
            updateArianneThread("addRestaurant", props.arianne, dispatch, "");
            dispatch(setCurrentPage("addRestaurant"));
        }
        else if (target === "settings") {
            let informations = props.informations.find(a => a.restaurantName === e.target.id)
            updateArianneThread("settings", props.arianne, dispatch, "");
            dispatch(setCurrentPage("settings"));
            dispatch(setSelectedInformations(informations))
        }
        else if (target === "accounts") {
            updateArianneThread("accounts", props.arianne, dispatch, "");
            dispatch(setCurrentPage("accounts"));
            dispatch(setSelectedRestaurantModifs(e.target.id));
        }
    }

    const getRows = () => {
        let result = [];
        let list = JSON.parse(JSON.stringify(props.informations)).filter(a => a.clientName === props.selectedClient);
        list.sort((a, b) => {
            // Comparer displayName
            if (a.displayName && b.displayName) {
              // Si les deux displayName existent, les comparer
              return a.displayName.localeCompare(b.displayName);
            } else if (a.displayName && !b.displayName) {
              // Si seulement a.displayName existe, a est plus petit
              return -1;
            } else if (!a.displayName && b.displayName) {
              // Si seulement b.displayName existe, b est plus petit
              return 1;
            } else {
              // Si aucun displayName n'existe, comparer restaurantName
              return a.restaurantName.localeCompare(b.restaurantName);
            }
          });
          
        for (const [index, restaurant] of list.filter(a => a.restaurantName.toLowerCase().includes(inputValue.toLowerCase())).entries()) {
            result.push(
                <div key={restaurant.restaurantName} style={{ height: "15%", width: "100%", display: "flex", flexDirection: "row", backgroundColor: index % 2 === 0 ? "#F0F0F0" : "#fff" }}>
                    <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"80%"} height={"50%"} color={"#fff"} backgroundColor={"#9CCEBE"}
                            boxShadow={"none"}
                            onClick={(e) => navigateToTarget(e, "dashboard")}
                            id={restaurant.restaurantName}>
                            {restaurant.displayName || restaurant.restaurantName}
                        </CustomButton>
                    </div>
                    <div style={{ width: "28%" }} />
                    <div style={{ width: "14%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"80%"} height={"40%"} color={"#fff"} backgroundColor={"#949494"}
                            boxShadow={"none"}
                            fontSize={"0.9vw"}
                            onClick={(e) => navigateToTarget(e, "datas")}
                            id={restaurant.restaurantName}>
                            {t('buttons.datas')}
                        </CustomButton>
                    </div>
                    <div style={{ width: "14%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"80%"} height={"40%"} color={"#fff"} backgroundColor={"#90c261"}
                            boxShadow={"none"}
                            fontSize={"0.9vw"}
                            onClick={(e) => navigateToTarget(e, "accounts")}
                            id={restaurant.restaurantName}>
                            {t('buttons.accounts')}
                        </CustomButton>
                    </div>
                    <div style={{ width: "14%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButton width={"80%"} height={"40%"} color={"#fff"} backgroundColor={"#68B4E3"}
                            boxShadow={"none"}
                            fontSize={"0.9vw"}
                            onClick={(e) => navigateToTarget(e, "settings")}
                            id={restaurant.restaurantName}>
                            {t('buttons.settings')}
                        </CustomButton>
                    </div>
                </div>
            )
        }
        return result;
    }
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}>
            <div style={{ height: "15%", width: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{
                    width: "30%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontSize: "1.3vw"
                    , color: "#666666", fontWeight: "600"
                }}>
                    {t('table.associatedRestaurants')}

                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        className="autocomplete-dropdown-input-client"
                        style={{ '--placeholder-color': "#666666", border: "1px solid #666666!important", borderRadius: "5px" }}
                        placeholder={inputValue || t('arianne.search')}
                    />
                </div>

                <div style={{
                    width: "40%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.3vw"
                    , color: "#666666", fontWeight: "600"
                }}>
                    {props.selectedClient}
                </div>
                <div style={{
                    width: "28%", display: "flex", justifyContent: "flex-end", alignItems: "center",
                }} >
                    <div style={{ width: "5%" }} />
                    <CustomButton width={"40%"} height={"40%"} color={"#fff"} backgroundColor={"#89CD85"}
                        fontSize={"0.9vw"}
                        onClick={(e) => navigateToTarget(e, "addRestaurant")}>
                        {t('buttons.addRestaurant')}
                    </CustomButton>

                </div>
            </div>
            <div style={{ height: "85%", width: "100%" }}>
                <CustomScrollDiv id={"client-list-table"} update={Math.random()}>
                    {getRows()}
                </CustomScrollDiv>
            </div>
        </CustomBlock>
    )
}


export default ClientListTable;
