const initialState = {
    dates: [],
    pictures: [],
    selectedAnalysis: null,
    selectedDate: null,
    selectedAlert: null,
    samplesModif: [],
    picturesToDelete: [],
    alimsToDelete: [],
    lastSelectedDate: null,
    displayedPictures: [],
    loading: false,
    selectedMonth: null,
    numberPages: 1,
    daysList: [],
    selectedFilter: "filterMass"
}


const picturesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ALL_DATES_PICTURES':
            return {
                ...state,
                dates: action.dates
            }
        case 'SET_PICTURES':
            return {
                ...state,
                pictures: action.pictures
            }
        case 'SET_SELECTED_ANALYSIS_PICTURES':
            return {
                ...state,
                selectedAnalysis: action.selectedAnalysis
            }
        case 'SET_SELECTED_DATE_PICTURES':
            return {
                ...state,
                selectedDate: action.selectedDate
            }
        case 'SET_SELECTED_PICTURE':
            return {
                ...state,
                selectedPicture: action.selectedPicture
            }

        case 'SET_SAMPLES_MODIF':
            return {
                ...state,
                samplesModif: action.samplesModif
            }
        case 'SET_PICTURES_TO_DELETE':
            return {
                ...state,
                picturesToDelete: action.picturesToDelete
            }
        case 'SET_ALIMS_TO_DELETE':
            return {
                ...state,
                alimsToDelete: action.alimsToDelete
            }
        case 'SET_LAST_SELECTED_DATE_PICTURES':
            return {
                ...state,
                lastSelectedDate: action.lastSelectedDate
            }
        case 'SET_DISPLAYED_PICTURES':
            return {
                ...state,
                displayedPictures: action.displayedPictures
            }
        case 'SET_LOADING_PICTURES':
            return {
                ...state,
                loading: action.loading
            }
        case 'SET_SELECTED_MONTH_PICTURES':
            return {
                ...state,
                selectedMonth: action.selectedMonth
            }
        case 'SET_NUMBER_PAGES_PICTURES':
            return {
                ...state,
                numberPages: action.numberPages
            }
        case 'SET_DAYS_LIST_PICTURES':
            return {
                ...state,
                daysList: action.daysList
            }
        case 'SET_SELECTED_FILTER_PICTURES':
            return {
                ...state,
                selectedFilter: action.selectedFilter
            }
        case 'RESET_PICTURES': {
            return initialState
        }
        default:
            return state;
    }
}

export default picturesReducer;