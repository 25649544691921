import React from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import { downLoadRessourceFile } from "../../../apiRoutes/files.routes";
const waterAndAlimentationMini = require('../../../assets/waterAndAlimentationMini.png');
const co2ImpactMini = require('../../../assets/co2ImpactMini.png');
const infographieMini = require('../../../assets/infographieMini.png');
const infographieWasteMini = require('../../../assets/infographieMini.png');
const whatIsCo2ImpactMini = require('../../../assets/whatIsCo2ImpactMini.png');
const waterAndAlimentationMiniEN = require('../../../assets/waterAndAlimentationMiniEN.png');
const co2ImpactMiniEN = require('../../../assets/co2ImpactMiniEN.png');
const infographieMiniEN = require('../../../assets/infographieMiniEN.png');
const infographieWasteMiniEN = require('../../../assets/infographieMiniEN.png');
const whatIsCo2ImpactMiniEN = require('../../../assets/whatIsCo2ImpactMiniEN.png');

const RessourcesImpact = (props) => {
    const { t } = useTranslation();
    const getPath = () => {
        if (props.language === "fr")
            return "impact"
        else
            return "impactEN"
    }
    const getDescription = (name) => {
        if (name === 'Eau_et_alimentation.pdf' || name === "Water_and_food_supply.pdf")
            return {
                title: t('ressources.impactFiles.waterAndAlimentation'),
                desc: t('ressources.impactFiles.waterAndAlimentationDesc'),
                picture: <img alt={"waterAndAlimentationMini"} src={props.language === "fr" ? waterAndAlimentationMini : waterAndAlimentationMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Impact carbone.pdf' || name === "The_carbon_impact_of_food.pdf")
            return {
                title: t('ressources.impactFiles.co2Impact'),
                desc: t('ressources.impactFiles.co2ImpactDesc'),
                picture: <img alt={"co2ImpactMini"} src={props.language === "fr" ? co2ImpactMini : co2ImpactMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Infographie_2pt.pdf' || name === "Understanding_food_waste.pdf")
            return {
                title: t('ressources.impactFiles.infographie'),
                desc: t('ressources.impactFiles.infographieDesc'),
                picture: <img alt={"infographieMini"} src={props.language === "fr" ? infographieMini : infographieMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Infographie_gaspillage_alimentaire.pdf' || name === "Infographic_Understanding_food_waste.pdf")
            return {
                title: t('ressources.impactFiles.infographieWaste'),
                desc: t('ressources.impactFiles.infographieWasteDesc'),
                picture: <img alt={"infographieWasteMini"} src={props.language === "fr" ? infographieWasteMini : infographieWasteMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === "Qu'est ce que l'impact carbone.pdf" || name === "Understanding_carbon_footprint.pdf")
            return {
                title: t('ressources.impactFiles.whatIsCo2Impact'),
                desc: t('ressources.impactFiles.whatIsCo2ImpactDesc'),
                picture: <img alt={"whatIsCo2ImpactMini"} src={props.language === "fr" ? whatIsCo2ImpactMini : whatIsCo2ImpactMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else
            return {
                title: name,
                desc: '',
                picture: <img alt={"whatIsCo2ImpactMini"} src={whatIsCo2ImpactMini} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
    }

    const downloadFile = async (file) => {
        let basePath = getPath() + "/"
        await downLoadRessourceFile(basePath + file, file);
    }
    const getRows = () => {
        let path = props.paths.find(a => a.path === getPath());
        let result = [];
        if (path && path.files) {
            let types = path.files;
            for (const type of types) {
                result.push(
                    <div style={{ width: "100%", height: "30%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "80%", height: "80%", backgroundColor: "#fff", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {getDescription(type).picture}
                            </div>
                        </div>
                        <div style={{ width: "55%" }}>
                            <div style={{ width: "100%", height: "40%", fontWeight: "bold", fontSize: "1.2vw", display: "flex", alignItems: "flex-end" }}>
                                {getDescription(type).title}
                            </div>
                            <div style={{ width: "100%", height: "60%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                {getDescription(type).desc}
                            </div>
                        </div>
                        <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <button className='custom-button-gradient'
                                style={{
                                    height: "4vh",
                                    width: "9vw",
                                    background: "#5BB3E4B3",
                                    color: "#fff"
                                }}
                                onClick={() => downloadFile(type)}>
                                {t('buttons.download')}
                            </button>
                        </div>
                    </div>
                )
            }
        }
        return result;
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <div style={{ height: "8%", width: "100%", color: "#68B4E3", fontSize: "1.4vw", display: "flex" }}>
                {t('ressources.learnWaste')}
            </div>
            <CustomBlock width={"100%"} height={"92%"} display={"flex"} flexDirection={"row"} >
                <CustomScrollDiv update={2}>
                    {getRows()}
                </CustomScrollDiv>
            </CustomBlock>
        </div>
    )
}
export default RessourcesImpact;