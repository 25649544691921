import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Warning } from "../../../../assets/warning.svg";
import CustomButton from "../../../customComponents/CustomButton";
import { useDispatch } from "react-redux";
import { setLoadAllDatas } from "../../../../actions_reducers/actions/dashboard.action";
import { setHasLoaded } from "../../../../actions_reducers/actions/user.action";

const AnalyzesCutText = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loadAllDatas = () => {
        dispatch(setLoadAllDatas(true));
        dispatch(setHasLoaded(false));
    }
    return (
        <div style={{ width: "100%", height: "9vh", flexDirection: "row", display: "flex" }}>
            <div style={{ width: "80%", height: "100%", display: "flex", alignItems: "center", fontSize: 'clamp(1.15rem,1.15rem,2.3rem)' }}>
                {t('longTexts.loadMoreAnalyzes')}
            </div>
            <div style={{ width: "20%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                <CustomButton width={"80%"} height={"80%"} value={t('buttons.loadAllData')} backgroundColor={"#666666"} color={"#fff"} onClick={() => loadAllDatas()} />
            </div>
        </div>
    )
}

export default AnalyzesCutText;