import React, { useEffect, useState } from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import CustomButton from "../../../customComponents/CustomButton";
import { ReactComponent as Pen } from "../../../../assets/pen.svg";
import 'moment/locale/fr';
import LineChart from "../../../charts/LineChart";
import { setObjectives, setSelectedObjectif } from "../../../../actions_reducers/actions/objectives.action";
import { useDispatch } from "react-redux";
import CustomTextField from "../../../customComponents/CustomTextField";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import CustomDatePicker from "../../../customComponents/CustomDatePicker";
import { getCategoryColor } from "../../../customComponents/CustomColors";
import { updateObjectif } from "../../../../apiRoutes/objectives.route";
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);
const Objectives = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [objectifToModify, setObjectifToModify] = useState(false);

    const [selectedObjectiveTitle, setSelectedObjectiveTitle] = useState('');
    const [selectedObjectiveCategory, setSelectedObjectiveCategory] = useState(null);
    const [selectedObjectiveIndicator, setSelectedObjectiveIndicator] = useState(null);
    const [selectedObjectiveReduction, setSelectedObjectiveReduction] = useState(null);
    const [selectedObjectiveDateStart, setSelectedObjectiveDateStart] = useState(new Date());
    const [selectedObjectiveTargetDate, setSelectedObjectiveTargetDate] = useState(new Date());

    useEffect(() => {
        let objectif = props.state.objectives.find(a => a.title === props.state.selectedObjectif);
        if (objectif) {
            setSelectedObjectiveTitle(objectif.title);
            setSelectedObjectiveCategory(objectif.aliment);
            setSelectedObjectiveIndicator(objectif.indicator);
            setSelectedObjectiveReduction(objectif.reduction ? objectif.reduction : "");
            setSelectedObjectiveDateStart(objectif.dateStart ? new Date(objectif.dateStart) : new Date());
            setSelectedObjectiveTargetDate(objectif.targetDate ? new Date(objectif.targetDate) : new Date());
        }
    }, [props.state.selectedObjectif, props.state.objectives]);

    const getAlimentValue = (aliment) => {
        if (aliment === "Global")
            return "global";
        else
            return t('components.ofValue', { value: t(`foods.${aliment}`) });
    }
    const getDeadline = (targetDate) => {
        if (targetDate.length === 4) return targetDate;
        else return moment(targetDate).format("MMMM YYYY");
    }
    const getIndicator = (indicator) => {
        if (indicator)
            return t(`indicators.${indicator}`);
        else return t(`indicators.mass`);
    }
    const getColor = (title) => {
        if (props.state.selectedObjectif === title)
            return "#F0AC62"
        else return "rgba(242, 195, 127, 0.5)"
    }
    const objectifIsFull = (objectif) => {
        if (objectif.aliment && objectif.indicator && objectif.reduction && objectif.dateStart && objectif.targetDate) return true;
        else return false;
    }
    const changeObjectifToModify = (title) => {
        let objectif = props.state.objectives.find(a => a.title === title);
        if (selectedObjectiveTitle !== title && objectifIsFull(objectif)) {
            setObjectifToModify(false)
            dispatch(setSelectedObjectif(title))
        }
        else if (!objectifIsFull(objectif)) {
            setObjectifToModify(true)
            dispatch(setSelectedObjectif(title))
        }
        else {
            setObjectifToModify(!objectifToModify)
        }
    }
    console.log(props.state.objectives)
    const getObjectives = () => {
        let result = [];
        for (const objectif of props.state.objectives) {
            let selected = objectif.title === props.state.selectedObjectif;
            result.push(
                <div
                    id={objectif.title} key={"ojective-" + objectif.title} className="objectives-button-div">
                    <CustomButton
                        id={objectif.title}
                        width={"100%"}
                        height={"75%"}
                        backgroundColor={getColor(objectif.title)}
                        paddingTop={"2%"}
                        paddingBottom={"2%"}
                        paddingLeft={"2%"}
                        paddingRight={"2%"}
                        selected={selected}
                        color={selected && "#fff"}
                        onClick={(e) =>
                            changeObjectifToModify(e.target.id)
                        }
                    >
                        <div
                            id={objectif.title} className="objectives-button-title">
                            <span className="dot" />
                            {t(`components.${objectif.title}`)}
                        </div>
                        {objectifIsFull(objectif) ?
                            <div
                                id={objectif.title} className="objectives-button-body">
                                {t('components.objectiveText', { "reduction": objectif.reduction, "aliment": getAlimentValue(objectif.aliment), "deadline": getDeadline(objectif.targetDate), "indicator": getIndicator(objectif.indicator) })}

                            </div>
                            : <div
                                id={objectif.title} className="objectives-button-body" style={{ paddingRight: "39%" }}>
                                {t('components.objectifToDetermine')}
                            </div>}
                        <Pen
                            id={objectif.title}
                            style={{ position: "absolute", width: "6%", height: "80%", right: "2%", top: 16, zIndex: 0 }} />
                    </CustomButton>
                </div>
            )
        }
        return result;
    }

    const getValueForObjectif = (objectif, data, analysis) => {
        if (objectif.aliment === 'Global') {
            switch (objectif.indicator) {
                case "Masse par convive":
                    var massByConv = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / (parseFloat(analysis.trays) ? parseFloat(analysis.trays) : 1);
                    data.y += parseFloat(t('mass', { "value": { "mass": massByConv, "unit": "g" } }));
                    break;
                case "Masse":
                    var mass = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass))
                    data.y += parseFloat(t('mass', { "value": { "mass": mass, "unit": "kg" } }));
                    break;
                case "Coût":
                    var cost = parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)
                    data.y += cost;
                    break;
                case "Co2":
                    var co2 = (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2))
                    data.y += parseFloat(t('mass', { "value": { "mass": co2, "unit": "kg" } }));
                    break
                default:
                    var defaultValue = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseFloat(analysis.trays);
                    data.y += parseFloat(t('mass', { "value": { "mass": defaultValue, "unit": "g" } }));
                    break;
            }
        }
        else {
            let tmpValue = 0;
            for (const day of analysis.days) {
                let catIndex = day.categories.findIndex(a => a.category === objectif.aliment)
                if (catIndex !== -1) {

                    if (objectif.indicator === t("units.Mass"))
                        tmpValue = parseFloat(tmpValue) + parseFloat(day.categories[catIndex].mass) / 1000;
                    else if (objectif.indicator === t("units.Cost"))
                        tmpValue = parseFloat(tmpValue) + parseFloat(day.categories[catIndex].cost);
                    else if (objectif.indicator === t("units.Co2"))
                        tmpValue = parseFloat(tmpValue) + parseFloat(day.categories[catIndex].co2) / 1000;
                    else
                        tmpValue = parseFloat(tmpValue) + parseFloat(day.categories[catIndex].mass) / 1000;
                }
            }
            data.y = data.y + tmpValue
        }

        return data;
    }
    const getData = () => {
        let objectif = props.state.objectives.find(a => a.title === props.state.selectedObjectif);

        let result = [
            {
                id: "analyzes_values",
                color: objectif ? getCategoryColor(objectif.aliment) : "",
                data: [

                ]
            }
        ];
        let markerValue = null;
        if (objectif) {
            let analyzes = JSON.parse(JSON.stringify(props.analyzesDatas)).filter((analysis) =>
                moment(new Date(analysis.dateStart)).isSameOrBefore(moment(new Date(objectif.targetDate))) &&
                moment(new Date(analysis.dateStart)).isSameOrAfter(moment(new Date(objectif.dateStart))));
            let kitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas)).filter((analysis) =>
                moment(new Date(analysis.dateStart)).isSameOrBefore(moment(new Date(objectif.targetDate))) &&
                moment(new Date(analysis.dateStart)).isSameOrAfter(moment(new Date(objectif.dateStart))));
            for (const analysis of analyzes) {
                let newDate = moment(analysis.dateEnd).add(-7, "days")
                let singleData = {
                    name: moment(newDate).format('MM/YY'),
                    x: analysis.analysisNumber,
                    y: 0,
                    color: 'transparent'
                };
                singleData = getValueForObjectif(objectif, singleData, analysis);
                result[0].data.push(singleData);
            }
            for (const analysis of kitchenAnalyzes) {
                let newDate = moment(analysis.dateEnd).add(-7, "days")
                let index = result[0].data.findIndex(a => a.x === analysis.analysisNumber);
                if (index === -1) {
                    let singleData = {
                        name: moment(newDate).format('MM/YY'),
                        x: analysis.analysisNumber,
                        y: 0,
                        color: 'transparent'
                    };
                    singleData = getValueForObjectif(objectif, singleData, analysis);
                    result[0].data.push(singleData);
                }
                else {
                    result[0].data[index] = getValueForObjectif(objectif, result[0].data[index], analysis);
                }
            }
        }
        result[0].data.sort((a, b) => a.x - b.x);
        if (result[0] && result[0].data[0]) {
            result[0].data.sort((a, b) => a.x - b.x)
            if (result[0].data.length > 10) {
                let indexes = [];
                let max = result[0].data.length - 9;
                for (let i = 1; i <= max; i++) {
                    indexes.push(i + 1)
                }
                for (const i of indexes) {
                    let index = result[0].data.findIndex(a => a.x === i)
                    result[0].data.splice(index, 1)
                }
                result[0].data.push({
                    name: "......",
                    x: 2,
                    y: result[0].data[0].y,
                    color: 'transparent'

                })
                result[0].data.sort((a, b) => a.x - b.x)
            }
            markerValue = result[0].data[0].y - ((result[0].data[0].y * objectif.reduction) / 100);
            let data1 = JSON.parse(JSON.stringify(result[0].data[0]));
            data1.name = "";
            data1.x = 0;
            data1.color = 'transparent';
            result[0].data.unshift(data1);
            let data2 = JSON.parse(JSON.stringify(result[0].data[result[0].data.length - 1]));
            data2.name = "";
            data1.color = 'transparent';
            data2.x = result[0].data[result[0].data.length - 1].x + 1;
            result[0].data.push(data2);
        }
        return {
            graph: result,
            markerValue: markerValue,
            color: objectif ? (objectif.aliment === "Global" ? "#68B4E3" : getCategoryColor(objectif.aliment)) : "",
        };
    }

    const saveObjective = async () => {
        let objectif = {
            title: selectedObjectiveTitle,
            restaurant_name: props.restaurant_name,
            aliment: selectedObjectiveCategory,
            indicator: selectedObjectiveIndicator,
            reduction: selectedObjectiveReduction,
            targetDate: moment(selectedObjectiveTargetDate).format("YYYY-MM-DD"),
            dateStart: moment(selectedObjectiveDateStart).format("YYYY-MM-DD")
        }
        let index = props.state.objectives.findIndex(a => a.title === selectedObjectiveTitle);
        let objectivesTmp = JSON.parse(JSON.stringify(props.state.objectives));
        objectivesTmp[index] = objectif;
        dispatch(setObjectives(objectivesTmp));
        await updateObjectif(objectif);
        setObjectifToModify(false);
    }

    const deleteObjective = async () => {
        let objectif = {
            title: selectedObjectiveTitle,
            restaurant_name: props.restaurant_name,
            aliment: "",
            indicator: "",
            reduction: null,
            targetDate: null,
            dateStart: null
        }
        let index = props.state.objectives.findIndex(a => a.title === selectedObjectiveTitle);
        let objectivesTmp = JSON.parse(JSON.stringify(props.state.objectives));
        objectivesTmp[index] = objectif;
        dispatch(setObjectives(objectivesTmp));
        await updateObjectif(objectif);
        setObjectifToModify(false);

    }
    const selectCategory = (category) => {
        if (category !== "Global") {
            let cat = props.categories.find(a => t(`foods.${a}`) === category);
            setSelectedObjectiveCategory(cat)
        }
        else
            setSelectedObjectiveCategory("Global")
    }
    const selectIndicator = (indicator) => {
        if (indicator === t("units.Mass"))
            setSelectedObjectiveIndicator("Masse")
        else if (indicator === t("units.Cost"))
            setSelectedObjectiveIndicator("Coût")
        else if (indicator === t("units.Co2"))
            setSelectedObjectiveIndicator("Co2")
        else if (indicator === t("units.MassConv"))
            setSelectedObjectiveIndicator("Masse par convive")
    }
    const getObjectifFields = () => {

        const indicators = [t("units.Mass"), t("units.Cost"), t("units.Co2")];
        const categories = []
        for (const category of JSON.parse(JSON.stringify(props.categories))) {
            categories.push(t(`foods.${category}`))
        }
        categories.unshift("Global");
        if (selectedObjectiveCategory === "Global") indicators.unshift(t("units.MassConv"))
        else {
            indicators.splice(1, 0)
        }
        return (
            <div className="set-objective-div">
                <div className="set-objective-field">
                    <div className="set-objective-field-title">{t('table.title')}</div>
                    <div className="set-objective-field-value">
                        {selectedObjectiveTitle}
                        {/*<CustomTextField
                            width={"80%"}
                            height={"50%"}
                            paddingLeft={"2%"}
                            paddingRight={"2%"}
                            backgroundColor={"#D9D9D9"}
                            value={selectedObjectiveTitle}
                            onChange={(e) => { setSelectedObjectiveTitle(e.target.value) }} />*/}
                    </div>
                </div>
                <div className="set-objective-field">
                    <div className="set-objective-field-title">{t('table.category')}</div>
                    <div className="set-objective-field-value">
                        <CustomDropdown
                            width={"80%"}
                            height={"50%"}
                            borderRadius={0}
                            backgroundColor={"#D9D9D9"}
                            values={categories}
                            selectedValue={selectedObjectiveCategory}
                            displayedValue={selectedObjectiveCategory ? t(`foods.${selectedObjectiveCategory}`) : ""}
                            onChange={(e) => selectCategory(e.target.id)}
                            maxHeightDropdown={"25vh"}
                            dropdownUpdate={21}
                            fontSize={"1vw"}
                            color={"#666666"}
                        />
                    </div>
                </div>
                <div className="set-objective-field">
                    <div className="set-objective-field-title">{t('table.indicator')}</div>
                    <div className="set-objective-field-value">
                        <CustomDropdown
                            width={"80%"}
                            height={"50%"}
                            backgroundColor={"#D9D9D9"}
                            borderRadius={0}
                            values={indicators}
                            selectedValue={selectedObjectiveIndicator}
                            displayedValue={t(`indicators.${selectedObjectiveIndicator}`)}
                            onChange={(e) => selectIndicator(e.target.id)}
                            fontSize={"1vw"}
                            color={"#666666"} />
                    </div>
                </div>
                <div className="set-objective-field">
                    <div className="set-objective-field-title">{t('table.reduction')}</div>
                    <div className="set-objective-field-value">
                        <CustomTextField
                            type={"number"}
                            width={"50%"}
                            height={"50%"}
                            paddingLeft={"2%"}
                            paddingRight={"2%"}
                            backgroundColor={"#D9D9D9"}
                            value={selectedObjectiveReduction}
                            onChange={(e) => { if (e.target.value < 100) setSelectedObjectiveReduction(e.target.value) }}
                            zIndex={1}
                        />
                        %
                    </div>
                </div>
                <div className="set-objective-field">
                    <div className="set-objective-field-title" style={{ width: "10%" }}>{t('table.period')}</div>
                    <div className="set-objective-field-value-date">
                        <div className="set-objective-field-value-date-input">
                            <div className="set-objective-field-value-date-input-title">
                                {t('table.dateStart')}

                            </div>
                            <div className="set-objective-field-value-date-input-value">
                                <CustomDatePicker
                                    startDate={selectedObjectiveDateStart}
                                    selected={selectedObjectiveDateStart}
                                    onChange={setSelectedObjectiveDateStart}
                                    backgroundColor={"#D9D9D9"} />
                            </div>
                        </div>
                        <div className="set-objective-field-value-date-input">
                            <div className="set-objective-field-value-date-input-title">
                                {t('table.dateEnd')}

                            </div>
                            <div className="set-objective-field-value-date-input-value">
                                <CustomDatePicker
                                    startDate={selectedObjectiveTargetDate}
                                    selected={selectedObjectiveTargetDate}
                                    onChange={setSelectedObjectiveTargetDate}
                                    backgroundColor={"#D9D9D9"} />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "20%", height: "200%" }}>
                        <div style={{ height: "10%" }} />
                        <CustomButton width={"120%"} height={"22.5%"} color={"#fff"}
                            backgroundColor={"#72B9A2"}
                            fontSize={"0.9vw"}
                            onClick={() => saveObjective()}
                        >
                            {t('buttons.confirm')}
                        </CustomButton>
                        <div style={{ height: "5%" }} />
                        <CustomButton width={"120%"} height={"22.5%"} color={"#fff"}
                            backgroundColor={"#666666"}
                            fontSize={"0.9vw"}
                            onClick={() => setObjectifToModify(false)}>
                            {t('buttons.cancel')}

                        </CustomButton>
                        <div style={{ height: "5%" }} />
                        <CustomButton width={"120%"} height={"22.5%"} color={"#fff"}
                            backgroundColor={"#EA881F"}
                            fontSize={"0.9vw"}
                            onClick={() => deleteObjective()}>
                            {t('buttons.delete')}

                        </CustomButton>
                    </div>
                </div>
            </div>
        )
    }
    const getViewLine = () => {
        if (objectifToModify)
            return false;
        else return true;
    }
    return (
        <CustomBlock width={'100%'} height={'51vh'}>

            <div style={{ width: '100%', height: '100%', padding: '1%' }}>
                <div className="dashboard-block-title" style={{ paddingLeft: "0.3%" }}>
                    {t('components.objectives')}
                </div>
                <div className="dashboard-block-objectives" style={{ paddingLeft: "0.3%" }}>
                    <div className="dashboard-block-objectives-list">

                        {getObjectives()}
                        {/*    <CustomScrollDiv update={1}>
                        </CustomScrollDiv>*/}
                    </div>
                    <div style={{ width: "5%" }} />
                    <div className="dashboard-block-objectives-graph">
                        {getViewLine() ?
                            <LineChart
                                animate={true}
                                id={"objectives"}
                                data={getData().graph}
                                color={getData().color}
                                selectedAnalysisNumber={props.state.selectedAnalysisNumber}
                                markerValue={getData().markerValue}
                            />
                            :
                            <div style={{
                                height: "100%", width: "100%",
                                display: "flex", justifyContent: "center"
                            }}>
                                {getObjectifFields()}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </CustomBlock>
    )
}

export default Objectives;