import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import { useDispatch } from "react-redux";
import { setSelectedAnalysisNumberClientRestaurant, setSelectedRestaurantClient, setSelectedRestaurantsClient } from "../../../../actions_reducers/actions/client.action";

const CompareRestaurant = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const getColorEvo = (value) => {
        if (value < 0) return '#567A60'
        else if (value > 0) return '#B5243B'
        else return '#666666'
    }
    const getSignEvo = (value) => {
        if (value > 0) return '+'
        else if (value < 0) return '-'
        else return ''
    }
    const getValues = (restaurant) => {
        let evo = 0;
        let massConv = 0;
        let massConvRest = 0;
        let massConvKitch = 0;
        let massConvIni = 0;
        let firstWasted = 0;
        let secondWasted = 0;
        let thirdWasted = 0;
        restaurant.analyzes.sort((a, b) => a.analysisNumber - b.analysisNumber);
        let lastAnalysis = restaurant.analyzes[restaurant.analyzes.length - 1]

        massConvRest = lastAnalysis.trays && parseInt(lastAnalysis.trays) > 0 ? (parseFloat(lastAnalysis.mass) - parseFloat(lastAnalysis.inevitableMass)) / parseInt(lastAnalysis.trays) : 0;
        massConvKitch = lastAnalysis.kitchenTrays && parseInt(lastAnalysis.kitchenTrays) > 0 ? (parseFloat(lastAnalysis.kitchenMass) - parseFloat(lastAnalysis.kitchenInevitableMass)) / parseInt(lastAnalysis.kitchenTrays) : 0;

        let massConvIniRest = restaurant.analyzes[0].trays && parseInt(restaurant.analyzes[0].trays) > 0 ? (parseFloat(restaurant.analyzes[0].mass) - parseFloat(restaurant.analyzes[0].inevitableMass)) / parseInt(restaurant.analyzes[0].trays) : 0
        let massConvIniKitch = restaurant.analyzes[0].kitchenTrays && parseInt(restaurant.analyzes[0].kitchenTrays) ? (parseFloat(restaurant.analyzes[0].kitchenMass) - parseFloat(restaurant.analyzes[0].kitchenInevitableMass)) / parseInt(restaurant.analyzes[0].kitchenTrays) : 0

        massConv = massConvRest + massConvKitch;
        massConvIni = massConvIniRest + massConvIniKitch;
        evo = getSignEvo((massConv - massConvIni) / massConvIni * 100) + Math.abs((massConv - massConvIni) / massConvIni * 100).toFixed(0) + " %"

        lastAnalysis.categories.sort((a, b) => b.mass - a.mass)
        let inevIndex = lastAnalysis.categories.findIndex(a => a.category === "Déchets inévitables");
        if (inevIndex !== -1) lastAnalysis.categories.splice(inevIndex, 1);

        firstWasted = lastAnalysis.categories[0] ? lastAnalysis.categories[0].category : "";
        secondWasted = lastAnalysis.categories[1] ? lastAnalysis.categories[1].category : "";
        thirdWasted = lastAnalysis.categories[2] ? lastAnalysis.categories[2].category : "";
        return {
            color: getColorEvo((massConv - massConvIni) / massConvIni * 100),
            evo: evo,
            massConv: t('mass', { value: { "mass": massConv, "unit": "g" } }),
            firstWasted: firstWasted,
            secondWasted: secondWasted,
            thirdWasted: thirdWasted
        }
    }

    const checkSelectedRestaurants = (e) => {
        let restaurants = JSON.parse(JSON.stringify(props.selectedRestaurants));
        let index = restaurants.findIndex(a => a === e.target.id);
        if (index === -1) {
            let analyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a => a.restaurant_name === e.target.id);
            let kitchenAnalyzes = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a => a.restaurant_name === e.target.id);
            if (analyzes.length > 0) {
                analyzes.sort((a, b) => a.analysisNumber - b.analysisNumber)
                let lastAnalysis = analyzes[analyzes.length - 1].analysisNumber;
                dispatch(setSelectedAnalysisNumberClientRestaurant(lastAnalysis));
                dispatch(setSelectedRestaurantClient(e.target.id))
                if (restaurants.length < 5)
                    restaurants.push(e.target.id);
                else window.alert("Veuillez sélectionner un maximum de 5 restaurants")
            }
            if (kitchenAnalyzes.length > 0 && analyzes.length === 0) {
                kitchenAnalyzes.sort((a, b) => a.analysisNumber - b.analysisNumber)
                let lastAnalysis = kitchenAnalyzes[kitchenAnalyzes.length - 1].analysisNumber;
                dispatch(setSelectedAnalysisNumberClientRestaurant(lastAnalysis));
                dispatch(setSelectedRestaurantClient(e.target.id))
                if (restaurants.length < 5)
                    restaurants.push(e.target.id);
                else window.alert("Veuillez sélectionner un maximum de 5 restaurants")
            }

        }
        else {
            if (props.selectedRestaurant === e.target.id) {
                dispatch(setSelectedRestaurantClient(restaurants[0]));
            }
            restaurants.splice(index, 1);
        }
        dispatch(setSelectedRestaurantsClient(restaurants));
    }
    const getName = (restaurant_name) => {
        let restaurant = props.displayNames.find(a => a.restaurant_name === restaurant_name);
        if (restaurant && restaurant.value) return restaurant.value;
        else return restaurant_name;
    }
    const getRows = () => {
        let result = [];
        let restaurants = [];
        for (const analysis of JSON.parse(JSON.stringify(props.clientAnalyzes))) {
            let restaurant_name = analysis.restaurant_name;
            let analysisTmp = {
                restaurant_name: analysis.restaurant_name,
                analysisNumber: analysis.analysisNumber,
                mass: analysis.mass,
                cost: analysis.cost,
                co2: analysis.co2,
                inevitableMass: analysis.inevitableMass,
                inevitableCost: analysis.inevitableCost,
                inevitableCo2: analysis.inevitableCo2,
                trays: analysis.trays,
                categories: analysis.categories
            }
            let index = restaurants.findIndex(a => a.restaurant_name === restaurant_name);
            if (index === -1) {
                let newRestaurant = {
                    restaurant_name: restaurant_name,
                    analyzes: [analysisTmp]
                }
                restaurants.push(newRestaurant);
            }
            else {
                let indexAnalysis = restaurants[index].analyzes.findIndex(a => a.analysisNumber === analysis.analysisNumber);
                if (indexAnalysis === -1) {
                    restaurants[index].analyzes.push(analysisTmp);
                }
                else {
                    let value = restaurants[index].analyzes[indexAnalysis];
                    value.mass = parseFloat(value.mass) + parseFloat(analysisTmp.mass)
                    value.cost = parseFloat(value.cost) + parseFloat(analysisTmp.cost)
                    value.co2 = parseFloat(value.co2) + parseFloat(analysisTmp.co2)
                    value.inevitableMass = parseFloat(value.inevitableMass) + parseFloat(analysisTmp.inevitableMass)
                    value.inevitableCost = parseFloat(value.inevitableCost) + parseFloat(analysisTmp.inevitableCost)
                    value.inevitableCo2 = parseFloat(value.inevitableCo2) + parseFloat(analysisTmp.inevitableCo2)
                    value.trays = parseInt(value.trays) + parseInt(analysisTmp.trays);
                    for (const cat of analysis.categories) {
                        let indexCat = value.categories.findIndex(a => a.category === cat.category);
                        if (indexCat === -1) {
                            value.categories.push(cat);
                        }
                        else {
                            value.categories[indexCat].mass = parseFloat(value.categories[indexCat].mass) + parseFloat(cat.mass)
                            value.categories[indexCat].cost = parseFloat(value.categories[indexCat].cost) + parseFloat(cat.cost)
                            value.categories[indexCat].co2 = parseFloat(value.categories[indexCat].co2) + parseFloat(cat.co2)
                            value.categories[indexCat].inevitableMass = parseFloat(value.categories[indexCat].inevitableMass) + parseFloat(cat.inevitableMass)
                            value.categories[indexCat].inevitableCost = parseFloat(value.categories[indexCat].inevitableCost) + parseFloat(cat.inevitableCost)
                            value.categories[indexCat].inevitableCo2 = parseFloat(value.categories[indexCat].inevitableCo2) + parseFloat(cat.inevitableCo2)
                            value.categories[indexCat].trays = parseInt(value.categories[indexCat].trays) + parseInt(cat.trays);

                        }
                    }
                }
            }
        }
        for (const analysis of JSON.parse(JSON.stringify(props.clientKitchenAnalyzes))) {
            if (analysis.trays > 0) {
                let restaurant_name = analysis.restaurant_name;
                let analysisTmp = {
                    restaurant_name: analysis.restaurant_name,
                    analysisNumber: analysis.analysisNumber,
                    mass: analysis.mass,
                    cost: analysis.cost,
                    co2: analysis.co2,
                    inevitableMass: analysis.inevitableMass,
                    inevitableCost: analysis.inevitableCost,
                    inevitableCo2: analysis.inevitableCo2,
                    trays: analysis.trays,
                    categories: analysis.categories
                }
                let index = restaurants.findIndex(a => a.restaurant_name === restaurant_name);
                if (index === -1) {
                    let newRestaurant = {
                        restaurant_name: restaurant_name,
                        analyzes: [analysisTmp]
                    }
                    restaurants.push(newRestaurant);
                }
                else {
                    let indexAnalysis = restaurants[index].analyzes.findIndex(a => a.analysisNumber === analysis.analysisNumber);
                    if (indexAnalysis === -1) {
                        restaurants[index].analyzes.push(analysisTmp);
                    }
                    else {
                        let value = restaurants[index].analyzes[indexAnalysis];
                        value.kitchenMass = parseFloat(analysisTmp.mass)
                        value.kitchenCost = parseFloat(analysisTmp.cost)
                        value.kitchenCo2 = parseFloat(analysisTmp.co2)
                        value.kitchenInevitableMass = parseFloat(analysisTmp.inevitableMass)
                        value.kitchenInevitableCost = parseFloat(analysisTmp.inevitableCost)
                        value.kitchenInevitableCo2 = parseFloat(analysisTmp.inevitableCo2)
                        value.kitchenTrays = parseInt(analysisTmp.trays);
                        for (const cat of analysis.categories) {
                            let indexCat = value.categories.findIndex(a => a.category === cat.category);
                            if (indexCat === -1) {
                                value.categories.push(cat);
                            }
                            else {
                                value.categories[indexCat].mass = parseFloat(value.categories[indexCat].mass) + parseFloat(cat.mass)
                                value.categories[indexCat].cost = parseFloat(value.categories[indexCat].cost) + parseFloat(cat.cost)
                                value.categories[indexCat].co2 = parseFloat(value.categories[indexCat].co2) + parseFloat(cat.co2)
                                value.categories[indexCat].inevitableMass = parseFloat(value.categories[indexCat].inevitableMass) + parseFloat(cat.inevitableMass)
                                value.categories[indexCat].inevitableCost = parseFloat(value.categories[indexCat].inevitableCost) + parseFloat(cat.inevitableCost)
                                value.categories[indexCat].inevitableCo2 = parseFloat(value.categories[indexCat].inevitableCo2) + parseFloat(cat.inevitableCo2)
                                value.categories[indexCat].trays = parseInt(value.categories[indexCat].trays) + parseInt(cat.trays);

                            }
                        }
                    }
                }
            }
        }
        restaurants.sort((a, b) => a.restaurant_name.localeCompare(b.restaurant_name))
        for (const [index, restaurant] of restaurants.entries()) {
            let values = getValues(restaurant);

            result.push(
                <div id={restaurant.restaurant_name} style={{ height: "15%", width: "100%", display: "flex", flexDirection: "row", backgroundColor: index % 2 === 0 && "#F0F0F0" }}>
                    <div className="compare-restaurant-table-head">
                        {getName(restaurant.restaurant_name)}
                    </div>
                    <div id={restaurant.restaurant_name} style={{ cursor: "pointer", width: "2%", height: "100%", justifyContent: "center", display: "flex", alignItems: "center" }} onClick={checkSelectedRestaurants}>
                        <input type="checkbox" className="custom-checkbox-dropdown" id={restaurant.restaurant_name}
                            checked={props.selectedRestaurants.indexOf(restaurant.restaurant_name) > -1} />
                    </div>
                    <div id={restaurant.restaurant_name} className="compare-restaurant-table-head" style={{ color: values.color }}>
                        {values.evo}
                    </div>
                    <div id={restaurant.restaurant_name} className="compare-restaurant-table-head">
                        {values.massConv}
                    </div>
                    <div id={restaurant.restaurant_name} className="compare-restaurant-table-head">
                        {values.firstWasted}
                    </div>
                    <div id={restaurant.restaurant_name} className="compare-restaurant-table-head">
                        {values.secondWasted}
                    </div>
                    <div className="compare-restaurant-table-head">
                        {values.thirdWasted}
                    </div>
                    <div id={restaurant.restaurant_name} className="compare-restaurant-table-head">
                        A voir
                    </div>
                </div>
            )
        }
        return result;
    }

    return (
        <CustomBlock width={'100%'} height={'100%'}>

            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "15%" }}>
                <div className="compare-restaurant-table-head">
                    {t('table.restaurants')}
                </div>
                <div style={{ width: "2%" }} />
                <div className="compare-restaurant-table-head">
                    {t(`table.evoWaste`)}
                </div>
                <div className="compare-restaurant-table-head">
                    {t('table.actualMassConv')}
                </div>
                <div className="compare-restaurant-table-head">
                    {t(`table.mostWasted`)}
                </div>
                <div className="compare-restaurant-table-head">
                    {t(`table.secondWasted`)}
                </div>
                <div className="compare-restaurant-table-head">
                    {t(`table.thirdWasted`)}
                </div>
                <div className="compare-restaurant-table-head">
                    {t(`table.commentary`)}
                </div>
            </div>
            <div style={{ width: "100%", height: "85%" }}>
                <CustomScrollDiv update={Math.random()}>
                    {getRows()}
                </CustomScrollDiv>
            </div>
        </CustomBlock>
    )
}
export default CompareRestaurant;