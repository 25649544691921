const initialState = {
    clientName: null,
    loadingClient: false,
    selectedAnalyzesExcel: [],
    clientAnalyzesDates: [],
    clientAnalyzes: [],
    clientKitchenAnalyzes: [],
    clientExtrapolatedAnalyzes: [],
    clientKitchenExtrapolatedAnalyzes: [],
    selectedAnalysisNumber: 1,
    dataTypeClient: "mass",
    has_loaded_client: false,
    selectedRestaurants: [],
    selectedRestaurantClient: null,
    selectedAnalysisNumberClientRestaurant: null,
    analysisNumbersList: [],
    extrapolationValue: "analysis",
    selectedRestaurantPictures: null,
    selectedRestaurantAnalysisPictures: null,
    selectedPicturesDate: null,
    picturesListClient: [],
    restaurantListDataType: "total",
    displayNames: [],
    hasAnalyzes: false
}


const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CLIENT_NAME':
            return {
                ...state,
                clientName: action.clientName
            }
        case "SET_LOADING_CLIENT":
            return {
                ...state,
                loadingClient: action.loadingClient
            }
        case "SET_SELECTED_ANALYZES_EXCEL_CLIENT":
            return {
                ...state,
                selectedAnalyzesExcel: action.selectedAnalyzesExcel
            }
        case 'SET_CLIENT_ANALYZES':
            return {
                ...state,
                clientAnalyzes: action.clientAnalyzes
            }
        case 'SET_CLIENT_KITCHEN_ANALYZES':
            return {
                ...state,
                clientKitchenAnalyzes: action.clientKitchenAnalyzes
            }
        case 'SET_CLIENT_KITCHEN_EXTRAPOLATED_ANALYZES':
            return {
                ...state,
                clientKitchenExtrapolatedAnalyzes: action.clientKitchenExtrapolatedAnalyzes
            }
        case 'SET_CLIENT_ANALYZES_DATES':
            return {
                ...state,
                clientAnalyzesDates: action.clientAnalyzesDates
            }
        case 'SET_DATA_TYPE_CLIENT':
            return {
                ...state,
                dataTypeClient: action.dataTypeClient
            }
        case 'SET_HAS_LOADED_CLIENT':
            return {
                ...state,
                has_loaded_client: action.has_loaded_client
            }
        case 'SET_SELECTED_ANALYSIS_NUMBER_CLIENT':
            return {
                ...state,
                selectedAnalysisNumber: action.selectedAnalysisNumber
            }
        case "SET_SELECTED_RESTAURANTS_CLIENT":
            return {
                ...state,
                selectedRestaurants: action.selectedRestaurants
            }
        case "SET_SELECTED_RESTAURANT_CLIENT":
            return {
                ...state,
                selectedRestaurantClient: action.selectedRestaurantClient
            }
        case "SET_SELECTED_ANALYSIS_NUMBER_CLIENT_RESTAURANT":
            return {
                ...state,
                selectedAnalysisNumberClientRestaurant: action.selectedAnalysisNumberClientRestaurant
            }
        case "SET_ANALYSIS_NUMBERS_LIST":
            return {
                ...state,
                analysisNumbersList: action.analysisNumbersList
            }
        case "SET_EXTRAPOLATION_VALUE_CLIENT":
            return {
                ...state,
                extrapolationValue: action.extrapolationValue
            }
        case "SET_CLIENT_EXTRAPOLATED_ANALYZES":
            return {
                ...state,
                clientExtrapolatedAnalyzes: action.clientExtrapolatedAnalyzes
            }
        case "SET_SELECTED_RESTAURANT_PICTURES":
            return {
                ...state,
                selectedRestaurantPictures: action.selectedRestaurantPictures
            }
        case "SET_SELECTED_RESTAURANT_ANALYSIS_PICTURES":
            return {
                ...state,
                selectedRestaurantAnalysisPictures: action.selectedRestaurantAnalysisPictures
            }
        case "SET_SELECTED_PICTURES_DATE":
            return {
                ...state,
                selectedPicturesDate: action.selectedPicturesDate
            }
        case "SET_PICTURES_LIST_CLIENT":
            return {
                ...state,
                picturesListClient: action.picturesListClient
            }
        case 'SET_RESTAURANT_LIST_DATA_TYPE':
            return {
                ...state,
                restaurantListDataType: action.restaurantListDataType
            }
        case 'SET_DISPLAY_NAMES':
            return {
                ...state,
                displayNames: action.displayNames
            }
        case 'SET_HAS_ANALYZES_CLIENT':
            return {
                ...state,
                hasAnalyzes: action.hasAnalyzes
            }
        case "HARD_RESET_CLIENT":
            return initialState;
        default:
            return state;
    }
}

export default clientReducer;