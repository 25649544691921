import React from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useTranslation } from "react-i18next";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import { downLoadRessourceFile } from "../../../apiRoutes/files.routes";
const convMini = require('../../../assets/convMini.png');
const sortMini = require('../../../assets/sortMini.png');
const scaleMini = require('../../../assets/scaleMini.png');
const camMini = require('../../../assets/camMini.png');
const kikleoMini = require('../../../assets/kikleoMini.png');
const plateformeMini = require('../../../assets/plateformeMini.png');
const balanceMini = require('../../../assets/mini_balance.png');
const organicWasteMini = require('../../../assets/organicWasteMini.png');
const techGuideMini = require('../../../assets/techGuideMini.png');

const convMiniEN = require('../../../assets/convMiniEN.png');
const sortMiniEN = require('../../../assets/sortMiniEN.png');
const scaleMiniEN = require('../../../assets/scaleMiniEN.png');
const camMiniEN = require('../../../assets/camMiniEN.png');
const kikleoMiniEN = require('../../../assets/kikleoMiniEN.png');
const plateformeMiniEN = require('../../../assets/plateformeMiniEN.png');
const balanceMiniEN = require('../../../assets/balanceMiniEN.png');
const organicWasteMiniEN = require('../../../assets/organicWasteMiniEN.png');
const techGuideMiniEN = require('../../../assets/techGuideMiniEN.png');

const RessourcesKikleo = (props) => {
    const { t } = useTranslation();

    const getPath = () => {
        if (props.language === "fr")
            return "kikleo"
        else
            return "kikleoEN"
    }
    const getDescription = (name) => {
        if (name === 'Affichage_convoyeur.pdf' || name === "Dishwashing_with_a_conveyor.pdf")
            return {
                title: t('ressources.kikleoFiles.conv'),
                desc: t('ressources.kikleoFiles.convDesc'),
                picture: <img alt={"convMini"} src={props.language === "fr" ? convMini : convMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };

        else if (name === 'Affichage_table_de_tri.pdf' || name === "Dishwashing_with_Sorting_Table.pdf")
            return {
                title: t('ressources.kikleoFiles.sort'),
                desc: t('ressources.kikleoFiles.sortDesc'),
                picture: <img alt={"sortMini"} src={props.language === "fr" ? sortMini : sortMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Presentation_balance_camera.pdf' || name === "Kikleo_connected_scale_camera_bin.pdf")
            return {
                title: t('ressources.kikleoFiles.scale'),
                desc: t('ressources.kikleoFiles.scaleDesc'),
                picture: <img alt={"scaleMini"} src={props.language === "fr" ? scaleMini : scaleMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'présentation_caméra.pdf' || name === "Smart_Camera_Presentation.pdf")
            return {
                title: t('ressources.kikleoFiles.cam'),
                desc: t('ressources.kikleoFiles.camDesc'),
                picture: <img alt={"camMini"} src={props.language === "fr" ? camMini : camMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Présentation_Kikleo.pdf' || name === "Kikleo_Presentation.pdf")
            return {
                title: t('ressources.kikleoFiles.kikleo'),
                desc: t('ressources.kikleoFiles.kikleoDesc'),
                picture: <img alt={"kikleoMini"} src={props.language === "fr" ? kikleoMini : kikleoMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Présentation_plateforme.pdf' || name === 'Kikleo_Platform_Presentation.pdf')
            return {
                title: t('ressources.kikleoFiles.plateforme'),
                desc: t('ressources.kikleoFiles.plateformeDesc'),
                picture: <img alt={"plateformeMini"} src={props.language === "fr" ? plateformeMini : plateformeMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Affichage_balance_Kikleo.pdf' || name === "Presentation_of_the_connected_scale.pdf")
            return {
                title: t('ressources.kikleoFiles.balance'),
                desc: t('ressources.kikleoFiles.balanceDesc'),
                picture: <img alt={"balanceMini"} src={props.language === "fr" ? balanceMini : balanceMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Les_différents_déchets_alimentaires.pdf' || name === "Organic_waste.pdf")
            return {
                title: t('ressources.kikleoFiles.oranicWaste'),
                desc: t('ressources.kikleoFiles.organicWasteDesc'),
                picture: <img alt={"balanceMini"} src={props.language === "fr" ? organicWasteMini : organicWasteMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };
        else if (name === 'Guide_technique_balance_Kikleo.pdf' || name === "Technical_guide_scale_Kikleo.pdf")
            return {
                title: t('ressources.kikleoFiles.technicalGuide'),
                desc: t('ressources.kikleoFiles.technicalGuideDesc'),
                picture: <img alt={"balanceMini"} src={props.language === "fr" ? techGuideMini : techGuideMiniEN} style={{ height: "90%", width: "35%", boxShadow: "0px 4px 4px #666666" }} />
            };

    }

    const downloadFile = async (file) => {
        let basePath = getPath() + "/"
        await downLoadRessourceFile(basePath + file, file);
    }
    const getRows = () => {
        let path = props.paths.find(a => a.path === getPath());
        let result = [];
        if (path && path.files) {
            let types = path.files;
            for (const type of types) {
                result.push(
                    <div style={{ width: "100%", height: "30%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ width: "80%", height: "80%", backgroundColor: "#fff", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {getDescription(type).picture}
                            </div>
                        </div>
                        <div style={{ width: "55%" }}>
                            <div style={{ width: "100%", height: "40%", fontWeight: "bold", fontSize: "1.2vw", display: "flex", alignItems: "flex-end" }}>
                                {getDescription(type).title}
                            </div>
                            <div style={{ width: "100%", height: "60%", display: "flex", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                {getDescription(type).desc}
                            </div>
                        </div>
                        <div style={{ width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                            <button className='custom-button-gradient'
                                style={{
                                    height: "4vh",
                                    width: "9vw",
                                    background: "#5BB3E4B3",
                                    color: "#fff"
                                }}
                                onClick={() => downloadFile(type)}>
                                {t('buttons.download')}
                            </button>
                        </div>
                    </div>
                )
            }
        }
        return result;
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <div style={{ height: "8%", width: "100%", color: "#68B4E3", fontSize: "1.4vw", display: "flex" }}>
                {t('ressources.learnWaste')}
            </div>
            <CustomBlock width={"100%"} height={"92%"} display={"flex"} flexDirection={"row"} >
                <CustomScrollDiv update={2}>
                    {getRows()}
                </CustomScrollDiv>
            </CustomBlock>
        </div>
    )
}
export default RessourcesKikleo;