const initialState = {
    operations: [],
    selectedClient: null,
    selectedDate: null,
    selectedOperation: null,
    operationViewType: "restaurant",
    weighIns: []
}


const operationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_OPERATIONS':
            return {
                ...state,
                operations: action.operations
            }
        case 'SET_SELECTED_CLIENT_OPERATIONS':
            return {
                ...state,
                selectedClient: action.selectedClient
            }
        case 'SET_SELECTED_DATE_OPERATIONS':
            return {
                ...state,
                selectedDate: action.selectedDate
            }
        case 'SET_SELECTED_OPERATION':
            return {
                ...state,
                selectedOperation: action.selectedOperation
            }
        case 'SET_OPERATION_VIEW_TYPE':
            return {
                ...state,
                operationViewType: action.operationViewType
            }
        case 'SET_WEIGH_INS_OPERATIONS':
            return {
                ...state,
                weighIns: action.weighIns
            }
        default:
            return state;
    }
}

export default operationsReducer;