import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/informations`;

export function getInformationsRestaurant(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/informations", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getAllInformations() {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/allInformations")
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function addInformations(informations) {
    return new Promise((resolve, reject) => {
        axios
            .post(back_url + "/addInformations", {
                informations: informations
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function updateInformations(informations) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateInformations", {
                informations: informations
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getAverageGuestsClient(clientName, login) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getAverageGuestsClient", {
                params: { clientName: clientName, login: login },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });

}
export function getNumberWeeksClient(clientName) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getNumberWeeksClient", {
                params: { clientName: clientName },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getDisplayNames(clientName, login) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getDisplayNames", {
                params: { clientName: clientName, login: login },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function modifyWasteManagementMethod(restaurant_name, wasteManagementMethod) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/modifyWasteManagementMethod", {
                restaurant_name: restaurant_name,
                wasteManagementMethod: wasteManagementMethod
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}