export function setTemplates(templates) {
    return {
        type: 'SET_TEMPLATES',
        templates: templates
    }
}

export function setSelectedTemplate(selectedTemplate) {
    return {
        type: 'SET_SELECTED_TEMPLATE',
        selectedTemplate: selectedTemplate
    }
}

export function setSelectedRestaurantMail(selectedRestaurantMail) {
    return {
        type: 'SET_SELECTED_RESTAURANT_MAIL',
        selectedRestaurantMail: selectedRestaurantMail
    }
}

export function setTemplateHtml(templateHtml) {
    return {
        type: 'SET_TEMPLATE_HTML',
        templateHtml: templateHtml
    }
}

export function setMailVariables(mailVariables) {
    return {
        type: 'SET_MAIL_VARIABLES',
        mailVariables: mailVariables
    }
}

export function setSelectedTemplateId(selectedTemplateId) {
    return {
        type: 'SET_SELECTED_TEMPLATE_ID',
        selectedTemplateId: selectedTemplateId
    }
}

export function setSelectedVersionId(selectedVersionId) {
    return {
        type: 'SET_SELECTED_VERSION_ID',
        selectedVersionId: selectedVersionId
    }
}
export function setTo(to) {
    return {
        type: 'SET_TO',
        to: to
    }
}
export function setCc(cc) {
    return {
        type: "SET_CC",
        cc: cc
    }
}
export function setSubject(subject) {
    return {
        type: "SET_SUBJECT",
        subject: subject
    }
}
export function softRestMails() {
    return {
        type: 'SOFT_RESET_MAILS',
    }
}