import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/analyzesDatas`;

export function getWasteByRestaurantAndAnalysis(restaurant_name, loadAllDatas) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getWasteByRestaurantAndAnalysis", {
                params: { restaurant_name: restaurant_name, loadAllDatas: loadAllDatas },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}