
import env from "react-dotenv";
let type = env.REACT_APP_ENV_TYPE;
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

export const hasKitchen = (userState) => {
    return userState.has_kitchen;
}

export const hasMultipleHours = (userState) => {
    if (userState.has_noon && userState.has_evening)
        return true;
    else return false;
}

export const hasMultipleCams = (userState) => {
    if (userState.cams.length > 1)
        return true;
    else return false;
}

export const getCurrentRestaurantName = (userState) => {
    return userState.restaurant_list[0];
}

export const getHour = (hour) => {
    if (hour < "16:00:00") return 'noon';
    else return 'evening';
}
export const getAllDates = (startDate, endDate) => {
    const startDay = moment(startDate).format("YYYY-MM-DD"); //BEGIN GET ALL DATES
    const endDay = moment(endDate).format("YYYY-MM-DD");
    const allDates = [];
    var tmp = startDay;
    while (moment(tmp).isBefore(endDay)) {
        allDates.push(moment(tmp).format("YYYY-MM-DD"));
        tmp = moment(tmp).add(1, "days");
    }
    allDates.push(endDay);
    return allDates;
};

export const getBaseUrls = () => {
    let url = "https://trays-kikleo.s3.eu-west-3.amazonaws.com/";
    let urlBin = "https://bins-kikleo.s3.eu-west-3.amazonaws.com/";
    if (type === "ovh") {
        url = 'https://trays-kikleo.s3.sbg.io.cloud.ovh.net/'
        urlBin = "";
    }
    return {
        url: url,
        urlBin: urlBin
    }
}

