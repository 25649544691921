import React, { useState } from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomButton from "../../../customComponents/CustomButton";
import DatePicker from "react-date-picker";
import { generateDatasAndAlerts } from "../../../../apiRoutes/dailyFoodWaste.routes";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { setCurrentPage } from "../../../../actions_reducers/actions/navigation.action";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const ClientListRawDatas = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);

    const confirmDatas = async () => {
        let isDateGood = moment(dateStart).isBefore(moment(dateEnd));
        if (isDateGood) {
            await generateDatasAndAlerts(props.selectedRestaurant, dateStart, dateEnd)
        }
        else {
            window.alert("Veuillez sélectionner des dates valides")
        }
    }

    return (
        <div className="main-page-main-div" style={{ height: "85.8vh", display: "flex", justifyContent: "center", color: "#666666" }}>
            <CustomButton position={"absolute"} width={"8vw"} height={"5vh"} top={"3%"} left={"2%"} color={"#fff"} backgroundColor={"#666666"} value={t('buttons.back')}
                onClick={() => {
                    updateArianneThread("clientList", props.arianne, dispatch, "");
                    dispatch(setCurrentPage("clientList"));
                }} />
            <CustomBlock
                width={"70%"}
                height={"40%"}>
                <div style={{ height: "20%", width: "100%" }}>
                    <div style={{ height: "50%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {t('components.generateRawDatas')}
                    </div>
                    <div style={{ height: "50%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold" }}>
                        {props.selectedRestaurant}
                    </div>
                </div>
                <div style={{ height: "60%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "50%", height: "100%" }}>
                        <div style={{ width: "100%", height: "50%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                            {t('components.From')}
                        </div>
                        <div style={{ width: "100%", height: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <DatePicker
                                locale={"fr"}
                                clearIcon={null}
                                onChange={(e) =>
                                    setDateStart(e)
                                } value={dateStart} />
                        </div>
                    </div>
                    <div style={{ width: "50%", height: "100%" }}>
                        <div style={{ width: "100%", height: "50%", display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
                            {t('components.To')}
                        </div>
                        <div style={{ width: "100%", height: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <DatePicker
                                locale={"fr"}
                                clearIcon={null}
                                onChange={(e) =>
                                    setDateEnd(e)
                                } value={dateEnd} />
                        </div>
                    </div>
                </div>
                <div style={{ height: "20%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomButton
                        width={"20%"}
                        height={"50%"}
                        color={"#fff"}
                        onClick={() => confirmDatas()}
                    >
                        {t('buttons.confirm')}
                    </CustomButton>
                </div>
            </CustomBlock>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        selectedRestaurant: state.adminReducer.selectedRestaurantModifs,
        arianne: state.navigationReducer.arianne
    };
}

export default connect(mapStateToProps)(ClientListRawDatas);
