import React from "react";
import { useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../../customComponents/CustomBlock";
import CustomDropdown from "../../../customComponents/CustomDropdown";
import CustomButton from "../../../customComponents/CustomButton";
import CustomSwitch from "../../../customComponents/CustomSwitch";
import { setOperationViewType, setOperations, setSelectedClientOperations, setSelectedDateOperations } from "../../../../actions_reducers/actions/operations.action";
import 'moment/locale/fr';
import { getAllPlanningStatus } from "../../../../apiRoutes/dailyPlanning.routes";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const OperationsSelection = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const updateSelectedClient = (e) => {
        let value = e.target.id;
        if (value === t('components.seeAll'))
            dispatch(setSelectedClientOperations(null));
        else dispatch(setSelectedClientOperations(value));
    }
    const getDisplayedValueClient = () => {
        if (!props.state.selectedClient)
            return t('components.seeAll');
        else return props.state.selectedClient;
    }
    const getClientsDropdown = () => {
        let informations = props.informations;
        let clients = [];
        for (const information of informations) {
            if (!clients.find(a => a === information.clientName) && information.clientName !== "Ministère des armées")
                clients.push(information.clientName);
        }
        clients.sort((a, b) => a.localeCompare(b));
        clients.unshift(t('components.seeAll'))
        return (
            <CustomDropdown
                width={"100%"}
                height={"100%"}
                color={"#fff"}
                arrowColor={"#fff"}
                backgroundColor={"#89CD85"}
                values={clients}
                displayedValue={getDisplayedValueClient()}
                onChange={updateSelectedClient}
                maxHeightDropdown={"50vh"}
            />
        )
    }
    const refreshDataForDate = async () => {
        let allOperationsDay = await getAllPlanningStatus();
        dispatch(setOperations(allOperationsDay))
    }
    const getRefreshButton = () => {
        return (
            <CustomButton
                width={"100%"}
                height={"100%"}
                justifyContent={"center"}
                display={"flex"}
                color={"#fff"}
                backgroundColor={"#68B4E3"}
                onClick={() => refreshDataForDate()}>
                {t('buttons.refresh')}
            </CustomButton>
        )
    }
    const clickViewButton = () => {
        if (props.state.selectedDate === null) {
            dispatch(setSelectedDateOperations(moment(new Date()).format("YYYY-MM-DD")))
        }
        else {
            dispatch(setOperationViewType("restaurant"));
            dispatch(setSelectedDateOperations(null));
        }
    }
    const changeViewType = () => {
        if (props.operationViewType === "restaurant")
            dispatch(setOperationViewType("kitchen"))
        else
            dispatch(setOperationViewType("restaurant"))
    }
    const getViewButton = () => {
        return (
            <CustomButton
                width={"100%"}
                height={"100%"}
                justifyContent={"center"}
                display={"flex"}
                color={"#fff"}
                backgroundColor={"#89CD85"}
                onClick={() => clickViewButton()}>
                {props.state.selectedDate === null ?
                    t('buttons.viewDay') : t('buttons.viewCalendar')}
            </CustomButton>)
    }
    const getAllDatesWeek = () => {
        let startOfWeek = moment(props.state.selectedDate).startOf('week');
        // Tableau pour stocker les dates de la semaine
        let weekDates = [];

        // Ajoutez chaque jour de la semaine au tableau
        for (let i = 0; i < 7; i++) {
            weekDates.push(startOfWeek.clone().add(i, 'days').format('DD-MM-YYYY'));
        }

        return weekDates;
    }
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row" }}>
            <div style={{ width: "20%", height: "100%" }}>

                {getViewButton()}
            </div>
            <div style={{ width: "2%" }} />
            <div style={{ width: "20%", height: "100%" }}>
                {getClientsDropdown()}
            </div>
            <div style={{ width: "2%" }} />
            <div style={{ width: "14%", display: "flex", justifyContent: "center" }} >
                {props.state.selectedDate && <CustomBlock width={"90%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <CustomSwitch height={"50%"} rightText={"Côté salle"} leftText={"Côté balance"} lineHeight={"75%"} onClick={changeViewType} />

                </CustomBlock>}
            </div>
            <div style={{ width: "2%" }} />
            <div style={{ width: "25%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {props.state.selectedDate &&
                    <CustomDropdown
                        width={"85%"}
                        height={"100%"}
                        backgroundColor={"#72B9A2"}
                        color={"#fff"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        values={getAllDatesWeek()}
                        displayedValue={moment(props.state.selectedDate).format("DD-MM-YYYY")}
                        onChange={(e) => { dispatch(setSelectedDateOperations(moment(e.target.id, "DD-MM-YYYY").format('YYYY-MM-DD'))) }}
                    />
                }
            </div>
            <div style={{ width: "15%", height: "100%" }}>
                {getRefreshButton()}
            </div>
        </div>
    )
}

export default OperationsSelection;
