import React from "react";
import { connect, useDispatch } from "react-redux"; // REDUX
import { useTranslation } from "react-i18next";
import CustomBlock from "../../customComponents/CustomBlock";
import { useState } from "react";
import CustomButton from "../../customComponents/CustomButton";
import CustomTextField from "../../customComponents/CustomTextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { updatePasswordForAccount } from "../../../apiRoutes/account.route";
import CustomDropdown from "../../customComponents/CustomDropdown";
import { ReactComponent as FlagFr } from "../../../assets/flagFR.svg";
import { ReactComponent as FlagUK } from "../../../assets/flagUK.svg";
import { setLanguage } from "../../../actions_reducers/actions/navigation.action";



const Accounts = (props) => {
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch();
    const [changePassword, setChangePassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const updatePassword = async () => {
        if (currentPassword === "" || newPassword === "") {
            window.alert(t('errors.enterPassword'))
        }
        else {
            let res = await updatePasswordForAccount(props.emailAccount, currentPassword, newPassword);
            if (res.status === "error") {
                window.alert(t('errors.invalidPassword'))
            }
            else {
                setChangePassword(false);
                setNewPassword("");
                setCurrentPassword("");
                window.alert(t('errors.modifiedPassword'))
            }
        }
    }

    const getValues = () => {
        return [t(`languages.fr`), t(`languages.en`)]
    }
    const getDisplayedValue = () => {
        return t(`languages.${props.language}`)
    }
    const getFlags = () => {
        switch (props.language) {
            case 'fr':
                return <FlagFr style={{width:"40%"}} />;
            case 'en':
                return <FlagUK style={{width:"40%"}} />;
            default:
                return <FlagFr style={{width:"40%"}} />;
        }
    }
    const updateLanguage = (e) => {
        let language = props.language;
        if (e.target.id === t('languages.fr'))
            language = "fr";
        else if (e.target.id === t('languages.en'))
            language = "en";
        else
            language = "fr";
        dispatch(setLanguage(language))
        i18n.changeLanguage(language);
        
    }
    return (
        <div className="main-page-main-div" style={{ height: "85.8vh" }}>
            <div style={{ height: "10%", width: "100%", fontSize: "1.4vw", color: "#68B4E3" }}>
                {t('components.accountOf', { value: props.emailAccount })}
            </div>
            <div style={{ height: "45%", width: "100%", display: "flex", flexDirection: "row" }}>
                <CustomBlock
                    width={"65%"}
                    height={"100%"}
                    paddingLeft={"2%"}
                    paddingRight={"2%"}
                    paddingTop={"1%"}
                    paddingBottom={"1%"}
                >
                    <div style={{ height: "20%", width: "100%", fontWeight: "bold", fontSize: "1.2vw" }}>
                        {t('components.myIds')}
                    </div>
                    <div style={{ height: "40%", width: "70%", display: "flex", alignItems: "center", flexDirection: "column" }}>
                        <div style={{ width: "100%", height: "20%", display: "flex", alignItems: "flex-end" }}>
                            {t('components.userName')}
                        </div>
                        <div style={{ width: "100%", height: "80%", display: "flex", alignItems: "flex-start" }}>
                            <div style={{ width: "90%", height: "40%", backgroundColor: "#D9D9D9", paddingLeft: "1%", paddingRight: "1%", display: "flex", alignItems: "center" }}>
                                {props.emailAccount}
                            </div>
                        </div>
                    </div>
                    {!changePassword ?
                        <div style={{ height: "40%", width: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ height: "100%", width: "100%", flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                <div style={{ width: "100%", height: "20%", display: "flex", alignItems: "flex-end" }}>
                                    {t('components.password')}
                                </div>
                                <div style={{ width: "100%", height: "80%", display: "flex", alignItems: "flex-start", flexDirection: "row" }}>
                                    <div style={{ width: "70%", height: "100%", display: "flex", alignItems: "flex-start" }}>
                                        <div style={{ width: "90%", height: "40%", backgroundColor: "#D9D9D9", paddingLeft: "1%", paddingRight: "1%", display: "flex", alignItems: "center" }}>
                                            {"*******"}
                                        </div>
                                    </div>
                                    <div style={{ width: "30%", height: "100%", display: "flex", alignItems: "flex-start" }}>
                                        <CustomButton height={"40%"} width={"95%"} backgroundColor={"#68B4E3"} color={"#fff"} onClick={() => setChangePassword(true)}>
                                            {t('buttons.modifyPassword')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div style={{ height: "40%", width: "100%", display: "flex", flexDirection: "row" }}>
                            <div style={{ height: "100%", width: "100%", flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                <div style={{ width: "100%", height: "10%", display: "flex", alignItems: "flex-end" }}>
                                    {t('components.actualPassword')}
                                </div>
                                <div style={{ width: "100%", height: "30%", display: "flex", alignItems: "flex-start", flexDirection: "row" }}>
                                    <div style={{ width: "70%", height: "100%", display: "flex", alignItems: "flex-start" }}>
                                        <CustomTextField width={"90%"} height={"100%"}
                                            onChange={(e) => setCurrentPassword(e.target.value)} value={currentPassword}
                                            type={showCurrentPassword ? "text" : "password"}>

                                        </CustomTextField>
                                        <div style={{ position: "absolute", width: "10%", right: "26%", top: "64.5%", cursor: "pointer" }} onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                                            {showCurrentPassword ?
                                                <VisibilityOffIcon />
                                                : <VisibilityIcon />}
                                        </div>
                                    </div>
                                    <div style={{ width: "30%", height: "100%", display: "flex", alignItems: "flex-start" }}>
                                        <CustomButton height={"100%"} width={"95%"} backgroundColor={"#666666"} color={"#fff"} onClick={() => {
                                            setChangePassword(false);
                                            setNewPassword("");
                                            setCurrentPassword("");
                                            setShowCurrentPassword(false);
                                            setShowNewPassword(false);
                                        }}>
                                            {t('buttons.cancel')}
                                        </CustomButton>
                                    </div>
                                </div>
                                <div style={{ height: "20%" }} />
                                <div style={{ width: "100%", height: "10%", display: "flex", alignItems: "flex-end" }}>
                                    {t('components.newPassword')}
                                </div>
                                <div style={{ width: "100%", height: "30%", display: "flex", alignItems: "flex-start", flexDirection: "row" }}>
                                    <div style={{ width: "70%", height: "100%", display: "flex", alignItems: "flex-start" }}>
                                        <CustomTextField width={"90%"} height={"100%"}
                                            onChange={(e) => setNewPassword(e.target.value)} value={newPassword}
                                            type={showNewPassword ? "text" : "password"}>

                                        </CustomTextField>

                                        <div style={{ position: "absolute", width: "10%", right: "26%", top: "86.5%", cursor: "pointer" }} onClick={() => setShowNewPassword(!showNewPassword)}>
                                            {showNewPassword ?
                                                <VisibilityOffIcon />
                                                : <VisibilityIcon />}
                                        </div>
                                    </div>
                                    <div style={{ width: "30%", height: "100%", display: "flex", alignItems: "flex-start" }}>
                                        <CustomButton height={"100%"} width={"95%"} backgroundColor={"#89CD85"} color={"#fff"} onClick={() => updatePassword()}>
                                            {t('buttons.confirm')}
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </CustomBlock>
                <div style={{ width: "5%" }} />
                <CustomBlock
                    width={"30%"}
                    height={"100%"}
                    paddingLeft={"2%"}
                    paddingRight={"2%"}
                    paddingTop={"1%"}
                    paddingBottom={"1%"}>
                    <div style={{ height: "10%", width: "100%", fontWeight: "bold", fontSize: "1.2vw" }}>
                        {t('components.language')}
                    </div>
                    <div style={{ height: "80%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ height: "100%", width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {getFlags()}
                        </div>
                        <div style={{ height: "100%", width: "70%", display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "2%" }}>
                            <CustomDropdown width={"70%"} height={"15%"}
                                backgroundColor={"#D9D9D9"}
                                color={"#666666"}
                                arrowColor={"#6666666"}
                                displayedValue={getDisplayedValue()} values={getValues()}
                                onChange={updateLanguage} />
                        </div>
                    </div>
                    <div style={{ height: "10%" }} />
                </CustomBlock>
            </div>
        </div >
    )
}
function mapStateToProps(state) {
    return {
        emailAccount: state.userReducer.login,
        language: state.navigationReducer.language
    };
}

export default connect(mapStateToProps)(Accounts);