import React from "react";
import ClientWaste from "./ClientWaste";
import ClientLosses from "./ClientLosses";
import ClientEvolution from "./ClientEvolution";
import ClientButtons from "./ClientButtons";
import ClientWasteDetails from "./ClientWasteDetails";
import ClientWasteConv from "./ClientWasteConv";
import 'moment/locale/fr';
import Repartition from "../../dashboard/elementsAllAnalyzes/Repartition";
import DashboardNoAnalyzes from "./DashboardNoAnalyzes";
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);


const separationHeight = "6vh";
const separationWidth = "6%";
const ClientDashboard = (props) => {

    const getMaxValue = () => {
        let max = 1;
        for (const date of props.clientAnalyzesDates) {
            if (date.analysisNumber > max) max = date.analysisNumber
        }
        let hasMax = false;
        if (props.clientAnalyzesDates.length > 0 && props.clientAnalyzes.length > 0)
            while (hasMax === false) {
                let lastDate = props.clientAnalyzesDates.find(a => a.analysisNumber === max);
                let testAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                    moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(lastDate.dateStart)))
                    &&
                    moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(lastDate.dateEnd)))
                )
                let testAnalyzesKitchen = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                    moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(lastDate.dateStart)))
                    &&
                    moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(lastDate.dateEnd)))
                )
                if ((!testAnalyzes || testAnalyzes.length === 0) && (!testAnalyzesKitchen || testAnalyzesKitchen.length === 0)) {
                    max -= 1;
                }
                else hasMax = true;
            }
        return max;
    }

    const hasMultipleDataTypes = () => {
        let max = getMaxValue();
        let dates = props.clientAnalyzesDates.find(a => parseInt(a.analysisNumber) === max);
        let selectedAnalyzes = [];
        let selectedAnalyzesKitchen = [];
        if (dates) {
            selectedAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
                a &&
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
            );
            selectedAnalyzesKitchen = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
                a &&
                moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
                &&
                moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
            );
        }
        if (selectedAnalyzesKitchen.length === 0 || selectedAnalyzes.length === 0)
            return false;
        else
            return true;
    }
    const getAnalyzes = () => {
        let max = getMaxValue();
        let dates = props.clientAnalyzesDates.find(a => parseInt(a.analysisNumber) === max);
        let selectedAnalyzes = JSON.parse(JSON.stringify(props.clientAnalyzes)).filter(a =>
            moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
            &&
            moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
        );
        return selectedAnalyzes;
    }
    const getKitchenAnalyzes = () => {
        let max = getMaxValue();
        let dates = props.clientAnalyzesDates.find(a => parseInt(a.analysisNumber) === max);
        let selectedAnalyzesKitchen = JSON.parse(JSON.stringify(props.clientKitchenAnalyzes)).filter(a =>
            moment(new Date(a.dateStart)).isSameOrAfter(moment(new Date(dates.dateStart)))
            &&
            moment(new Date(a.dateEnd)).isSameOrBefore(moment(new Date(dates.dateEnd)))
        );
        return selectedAnalyzesKitchen;
    }
    if (props.hasAnalyzes) {
        if (hasMultipleDataTypes())
            return (
                <div className="main-page-main-div">
                    <div style={{ display: "flex", flexDirection: "row", height: "60vh", width: "100%" }}>
                        <div style={{ width: "39%" }}>
                            <ClientWasteConv
                                clientAnalyzesDates={props.clientAnalyzesDates}
                                clientAnalyzes={props.clientAnalyzes}
                                clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                                max={getMaxValue()}
                                clientAverageGuests={props.clientAverageGuests} />
                        </div>
                        <div style={{ width: separationWidth }} />
                        <div style={{ width: "55%" }}>
                            <ClientWasteDetails
                                clientAnalyzes={props.clientAnalyzes}
                                selectedAnalysisNumber={props.selectedAnalysisNumber}
                                clientAnalyzesDates={props.clientAnalyzesDates}
                                clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                                clientAnalyzesBase={props.clientAnalyzesBase}
                                clientKitchenAnalyzesBase={props.clientKitchenAnalyzesBase}
                                clientAverageGuests={props.clientAverageGuests} />
                        </div>
                    </div>
                    <div style={{ height: separationHeight }} />
                    <div style={{ height: "10vh", width: "100%" }}>
                        <ClientButtons arianne={props.arianne} />
                    </div>
                    <div style={{ height: separationHeight }} />
                    <div style={{ height: "42vh", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", height: "100%" }}>
                            <Repartition type={"client"} width={"100%"} analyzes={getAnalyzes()} kitchenAnalyzes={getKitchenAnalyzes()} />
                        </div>
                        <div style={{ width: separationWidth }} />
                        <div style={{ width: "44%", height: "100%" }}>
                            <ClientLosses
                                clientAnalyzes={props.clientAnalyzes}
                                clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                                selectedAnalysisNumber={props.selectedAnalysisNumber}
                                clientAnalyzesDates={props.clientAnalyzesDates}
                                clientAverageGuests={props.clientAverageGuests} />

                        </div>
                    </div>
                    <div style={{ height: separationHeight }} />
                    <div style={{ height: "50vh", width: "100%" }}>
                        <ClientEvolution
                            hasKitchenLastAnalysis={true}
                            clientAnalyzes={props.clientAnalyzes}
                            clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                            selectedAnalysisNumber={props.selectedAnalysisNumber}
                            clientAnalyzesDates={props.clientAnalyzesDates}
                            clientAnalyzesBase={props.clientAnalyzesBase}
                            clientKitchenAnalyzesBase={props.clientKitchenAnalyzesBase}
                            clientAverageGuests={props.clientAverageGuests}
                            selected_analyzes={props.selected_analyzes}
                            login={props.login}  
                            selectedClient={props.selectedClient}/>
                    </div>
                </div >
            )
        else
            return (
                <div className="main-page-main-div">
                    <div style={{ display: "flex", flexDirection: "row", height: "50vh", width: "100%" }}>
                        <div style={{ width: "53%" }}>
                            <ClientWaste
                                clientAnalyzes={props.clientAnalyzes}
                                selectedAnalysisNumber={props.selectedAnalysisNumber}
                                clientAnalyzesDates={props.clientAnalyzesDates}
                                clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                                clientAnalyzesBase={props.clientAnalyzesBase}
                                clientKitchenAnalyzesBase={props.clientKitchenAnalyzesBase}
                                clientAverageGuests={props.clientAverageGuests} />
                        </div>
                        <div style={{ width: separationWidth }} />
                        <div style={{ width: "41%" }}>
                            <ClientLosses
                                clientAnalyzes={props.clientAnalyzes}
                                clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                                selectedAnalysisNumber={props.selectedAnalysisNumber}
                                clientAnalyzesDates={props.clientAnalyzesDates}
                                clientAverageGuests={props.clientAverageGuests} />
                        </div>
                    </div>
                    <div style={{ height: separationHeight }} />
                    <div style={{ height: "10vh", width: "100%" }}>
                        <ClientButtons arianne={props.arianne} />
                    </div>
                    <div style={{ height: separationHeight }} />
                    <div style={{ height: "50vh", width: "100%" }}>
                        <ClientEvolution
                            clientAnalyzes={props.clientAnalyzes}
                            clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                            selectedAnalysisNumber={props.selectedAnalysisNumber}
                            clientAnalyzesDates={props.clientAnalyzesDates}
                            clientAnalyzesBase={props.clientAnalyzesBase}
                            clientKitchenAnalyzesBase={props.clientKitchenAnalyzesBase}
                            clientAverageGuests={props.clientAverageGuests}
                            login={props.login} 
                            selectedClient={props.selectedClient}
                            selected_analyzes={props.selected_analyzes}/>
                    </div>
                    {/*<div style={{ height: separationHeight }} />
            <div style={{ height: "45vh", width: "100%" }}>
                <ClientGraph 
                        clientAnalyzes={props.clientAnalyzes}
                        clientAnalyzesDates={props.clientAnalyzesDates} />
    </div>*/}
                </div >
            )
    }
    else {
        return (
            <div className="main-page-main-div" style={{ height: "85.8vh" }}>
                <DashboardNoAnalyzes arianne={props.arianne} />
            </div>
        )
    }
}

export default ClientDashboard;
