import React, { useEffect } from "react";
import ClientDashboard from "./ClientDashboard/ClientDashboard";
import { connect, useDispatch } from "react-redux";
import { updateArianneThread } from "../../arianneThread/ArianneThread";
import ClientCompare from "./ClientCompare/ClientCompare";
import ClientPictures from "./ClientPictures/ClientPictures";
import ClientDetails from "./ClientDetails/ClientDetails";
import { getGeneralWasteForClient } from "../../../apiRoutes/dailyFoodWaste.routes";
import { setAnalysisNumbersList, setClientAnalyzes, setClientAnalyzesDates, setClientExtrapolatedAnalyzes, setClientKitchenAnalyzes, setClientKitchenExtrapolatedAnalyzes, setDisplayNames, setHasAnalyzesClient, setHasLoadedClient, setSelectedAnalysisNumberClientRestaurant } from "../../../actions_reducers/actions/client.action";
import { getClientAnalyzes } from "../../../apiRoutes/clientAnalyzes.routes";
import { extrapolateAnalyzesValues } from "../../customFunctions/extrapolation.function";
import { setFoodList } from "../../../actions_reducers/actions/datas.action";
import { getFoodsOfClient } from "../../../apiRoutes/food.routes";
import { getGeneralKitchenWasteForClient } from "../../../apiRoutes/kitchenWaste.routes";
import CustomLoading from "../../customComponents/CustomLoading";
import { setClientAverageGuests, setCostUnit, setMassUnit, setNumberWeeks } from "../../../actions_reducers/actions/user.action";
import { getAverageGuestsClient, getDisplayNames, getNumberWeeksClient } from "../../../apiRoutes/informations.routes";
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);

const DashboardClient = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        if (!props.has_loaded)
            setAnalysisValues()
    }, []);

    const setAnalysisValues = async () => {
        let login = props.userState.login;
        let values = await getGeneralWasteForClient(props.selectedClient, login);
        let analyzesDates = await getClientAnalyzes(props.selectedClient, login);
        let kitchenValues = await getGeneralKitchenWasteForClient(props.selectedClient, login);
        let foods = await getFoodsOfClient(props.selectedClient, login);
        let averageGuests = await getAverageGuestsClient(props.selectedClient, login);
        let numberWeeks = await getNumberWeeksClient(props.selectedClient, login);
        let displayNames = await getDisplayNames(props.selectedClient, login);
        if ((values.length > 0 || kitchenValues.length > 0) && analyzesDates.length > 0) {
            dispatch(setHasAnalyzesClient(true))
        }
        if (props.selectedClient === "Leanpath") {
            dispatch(setMassUnit("lbs"))
            dispatch(setCostUnit("$"))
        }
        let finalAnalyzesDates = [];
        for (const date of analyzesDates) {
            for (const analysis of values) {
                if (moment(new Date(analysis.dateStart)).isSameOrAfter(moment(new Date(date.dateStart)))
                    && moment(new Date(analysis.dateEnd)).isSameOrBefore(moment(new Date(date.dateEnd)))) {
                    if (!finalAnalyzesDates.find(a => a.analysisNumber === date.analysisNumber))
                        finalAnalyzesDates.push(date)
                }
            }
            for (const kitchenAnalysis of kitchenValues) {
                if (moment(new Date(kitchenAnalysis.dateStart)).isSameOrAfter(moment(new Date(date.dateStart))) &&
                    moment(new Date(kitchenAnalysis.dateEnd)).isSameOrBefore(moment(new Date(date.dateEnd)))) {
                    if (!finalAnalyzesDates.find(a => a.analysisNumber === date.analysisNumber))
                        finalAnalyzesDates.push(date)
                }
            }
        }
        dispatch(setDisplayNames(displayNames));
        dispatch(setNumberWeeks(numberWeeks.numberWeeks))
        dispatch(setClientAverageGuests(averageGuests))

        const analyzesNumbers = [];
        for (const analysis of finalAnalyzesDates) {
            let newDate = moment(analysis.dateEnd).add(-7, "days");
            analyzesNumbers.push({
                number: analysis.analysisNumber,
                date: moment(newDate).format("MM/YY")
            })
        }
        dispatch(setFoodList(foods));
        dispatch(setAnalysisNumbersList(analyzesNumbers))
        dispatch(setSelectedAnalysisNumberClientRestaurant(1))
        updateArianneThread('clientDashboard', props.arianne, dispatch);
        dispatch(setClientAnalyzesDates(finalAnalyzesDates));
        dispatch(setClientAnalyzes(values));
        dispatch(setClientKitchenAnalyzes(kitchenValues))
        let extrapolatedAnalyzes = extrapolateAnalyzesValues(JSON.parse(JSON.stringify(values)), 'analysis', 'client', props.userState.clientAverageGuests);
        dispatch(setClientExtrapolatedAnalyzes(extrapolatedAnalyzes));
        let extrapolatedKitchenAnalyzes = extrapolateAnalyzesValues(JSON.parse(JSON.stringify(kitchenValues)), "analysis", "client", props.userState.clientAverageGuests);
        dispatch(setClientKitchenExtrapolatedAnalyzes(extrapolatedKitchenAnalyzes));
        dispatch(setHasLoadedClient(true));
    }
    if (props.has_loaded) {
        if (!props.arianne[1])
            return <ClientDashboard
                arianne={props.arianne}
                clientAnalyzes={props.clientAnalyzes}
                clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                clientAnalyzesBase={props.clientAnalyzes}
                clientKitchenAnalyzesBase={props.clientKitchenAnalyzes}
                selectedAnalysisNumber={props.selectedAnalysisNumber}
                clientAnalyzesDates={props.clientAnalyzesDates}
                clientAverageGuests={props.clientAverageGuests}
                hasAnalyzes={props.hasAnalyzes}
                selected_analyzes={props.selected_analyzes}
                login={props.userState.user_type} 
                selectedClient={props.selectedClient}/>
        else if (props.arianne[1].value === "clientCompare")
            return <ClientCompare
                displayNames={props.displayNames}
                clientAnalyzes={props.clientAnalyzes}
                selectedAnalysisNumber={props.selectedAnalysisNumber}
                clientAnalyzesDates={props.clientAnalyzesDates}
                dataTypeClient={props.dataTypeClient}
                arianne={props.arianne}
                selectedRestaurants={props.selectedRestaurants}
                selectedRestaurant={props.selectedRestaurant}
                selectedAnalysis={props.selectedAnalysis}
                analysisNumbersList={props.analysisNumbersList}
                clientState={props.clientState}
                clientExtrapolatedAnalyzes={props.clientExtrapolatedAnalyzes}
                clientKitchenExtrapolatedAnalyzes={props.clientKitchenExtrapolatedAnalyzes}
                clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                extrapolationValue={props.extrapolationValue}
                userState={props.userState} />
        else if (props.arianne[1].value === "clientPictures")
            return <ClientPictures
                displayNames={props.displayNames}
                clientAnalyzes={props.clientAnalyzes}
                selectedRestaurantPictures={props.selectedRestaurantPictures}
                selectedAnalysis={props.selectedAnalysisPictures}
                selectedDate={props.selectedDatePictures}
                picturesListClient={props.picturesListClient}
                food_list={props.food_list} />
        else if (props.arianne[1].value === "clientDetailed")
            return <ClientDetails
                displayNames={props.displayNames}
                clientAnalyzes={props.clientAnalyzes}
                selectedAnalysisNumber={props.selectedAnalysisNumber}
                clientAnalyzesDates={props.clientAnalyzesDates}
                clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                dataTypeClient={props.dataTypeClient}
                arianne={props.arianne}
                restaurantListDataType={props.restaurantListDataType}
                userState={props.userState} />
    }
    else
        return (
            <div className="main-page-main-div" style={{ height: "100vh" }}>
                <CustomLoading />
            </div>
        )
}

function mapStateToProps(state) {
    return {
        selectedClient: state.adminReducer.selectedClient,
        userState: state.userReducer,
        dashboardState: state.dashboardReducer,
        objectivesState: state.objectivesReducer,
        arianne: state.navigationReducer.arianne,
        clientAnalyzes: state.clientReducer.clientAnalyzes,
        clientKitchenAnalyzes: state.clientReducer.clientKitchenAnalyzes,
        selectedAnalysisNumber: state.clientReducer.selectedAnalysisNumber,
        clientAnalyzesDates: state.clientReducer.clientAnalyzesDates,
        dataTypeClient: state.clientReducer.dataTypeClient,
        has_loaded: state.clientReducer.has_loaded_client,
        selectedRestaurants: state.clientReducer.selectedRestaurants,
        selectedRestaurant: state.clientReducer.selectedRestaurantClient,
        selectedAnalysis: state.clientReducer.selectedAnalysisNumberClientRestaurant,
        analysisNumbersList: state.clientReducer.analysisNumbersList,
        clientState: state.clientReducer,
        clientExtrapolatedAnalyzes: state.clientReducer.clientExtrapolatedAnalyzes,
        clientKitchenExtrapolatedAnalyzes: state.clientReducer.clientKitchenExtrapolatedAnalyzes,
        extrapolationValue: state.clientReducer.extrapolationValue,
        selectedRestaurantPictures: state.clientReducer.selectedRestaurantPictures,
        selectedAnalysisPictures: state.clientReducer.selectedRestaurantAnalysisPictures,
        selectedDatePictures: state.clientReducer.selectedPicturesDate,
        food_list: state.datasReducer.food_list,
        picturesListClient: state.clientReducer.picturesListClient,
        restaurantListDataType: state.clientReducer.restaurantListDataType,
        clientAverageGuests: state.userReducer.clientAverageGuests,
        displayNames: state.clientReducer.displayNames,
        hasAnalyzes: state.clientReducer.hasAnalyzes,
        selected_analyzes: state.clientReducer.selectedAnalyzesExcel
    };
}

export default connect(mapStateToProps)(DashboardClient);