import CustomBlock from "../../customComponents/CustomBlock";
import { FirstPage } from "./OnBoardingPages/FirstPage";
import { SecondPage } from "./OnBoardingPages/SecondPage";
import { ThirdPage } from "./OnBoardingPages/ThirdPage";
import { FourthPage } from "./OnBoardingPages/FourthPage";

export const FilesOnBoarding = (props) => {
    const getCurrentPage = () => {
        if (props.current_page_on_boarding === 1)
            return <FirstPage
                cams={props.cams}
                onBoardingState={props.onBoardingState}
                on_boarding_done={props.on_boarding_done}
                user_type={props.user_type} />
        else if (props.current_page_on_boarding === 2)
            return <SecondPage onBoardingState={props.onBoardingState}
                on_boarding_done={props.on_boarding_done} />
        else if (props.current_page_on_boarding === 3)
            return <ThirdPage onBoardingState={props.onBoardingState}
                on_boarding_done={props.on_boarding_done} />
        else if (props.current_page_on_boarding === 4)
            return <FourthPage onBoardingState={props.onBoardingState} restaurant_name={props.restaurant_name}
                on_boarding_done={props.on_boarding_done} />
    }
    return (
        <CustomBlock
            width={"100%"}
            height={"100%"}
            paddingLeft={"1%"}
            paddingRight={"1%"}
            paddingTop={"1%"}
            paddingBottom={"1%"}
            backgroundColor={"#fff"}
        >
            {getCurrentPage()}
        </CustomBlock>
    )
}