export function setInitialAnalyzes(initialAnalyzes) {
    return {
        type: 'SET_INITIAL_ANALYZES',
        initialAnalyzes: initialAnalyzes
    }
}
export function setAnalyzesBaseDatas(analyzesBaseDatas) {
    return {
        type: "SET_ANALYZES_BASE_DATAS",
        analyzesBaseDatas: analyzesBaseDatas
    }
}

export function setAnalyzesDatas(analyzesDatas) {
    return {
        type: 'SET_ANALYZES_DATAS',
        analyzesDatas: analyzesDatas,
    }
}

export function setExtrapolatedAnalyzes(extrapolatedAnalyzes) {
    return {
        type: 'SET_EXTRAPOLATED_ANALYZES',
        extrapolatedAnalyzes: extrapolatedAnalyzes
    }
}

export function setAnalyzesTrays(analyzesTrays) {
    return {
        type: "SET_ANALYZES_TRAYS",
        analyzesTrays: analyzesTrays
    }
}

export function setPicturesList(pictures_list) {
    return {
        type: "SET_PICTURES_LIST",
        pictures_list: pictures_list
    }
}

export function setFoodList(food_list) {
    return {
        type: "SET_FOOD_LIST",
        food_list: food_list
    }
}

export function setTraysByDay(trays_by_day) {
    return {
        type: "SET_TRAYS_BY_DAY",
        trays_by_day: trays_by_day
    }
}

export function setKitchenDatas(kitchenDatas) {
    return {
        type: "SET_KITCHEN_DATAS",
        kitchenDatas: kitchenDatas
    }
}

export function setKitchenAnalyzesDatas(kitchenAnalyzesDatas) {
    return {
        type: 'SET_KITCHEN_ANALYZES_DATAS',
        kitchenAnalyzesDatas: kitchenAnalyzesDatas
    }
}

export function setKitchenExtrapolatedAnalyzes(kitchenExtrapolatedAnalyzes) {
    return {
        type: 'SET_KITCHEN_EXTRAPOLATED_ANALYZES',
        kitchenExtrapolatedAnalyzes: kitchenExtrapolatedAnalyzes
    }
}

export function setWeighIns(weighIns) {
    return {
        type: 'SET_WEIGH_INS',
        weighIns: weighIns
    }
}

export function setFoodListKitchen(food_list_kitchen) {
    return {
        type: 'SET_FOOD_LIST_KITCHEN',
        food_list_kitchen: food_list_kitchen
    }
}

export function setSamplesBin(samplesBin) {
    return {
        type: 'SET_SAMPLES_BIN',
        samplesBin: samplesBin
    }
}

export function setDeletedSamplesBin(deletedSamplesBin) {
    return {
        type: 'SET_DELETED_SAMPLES_BIN',
        deletedSamplesBin: deletedSamplesBin
    }
}

export function setUpdatedSamplesBin(updatedSamplesBin) {
    return {
        type: 'SET_UPDATED_SAMPLES_BIN',
        updatedSamplesBin: updatedSamplesBin
    }
}
export function setSelectedAnalysisSamplesBin(selected_analysis_samples_bin) {
    return {
        type: 'SET_SELECTED_ANALYSIS_SAMPLES_BIN',
        selected_analysis_samples_bin: selected_analysis_samples_bin
    }
}
export function setSelectedDaySamplesBin(selected_day_samples_bin) {
    return {
        type: 'SET_SELECTED_DAY_SAMPLES_BIN',
        selected_day_samples_bin: selected_day_samples_bin
    }
}

export function setSelectedImageSamplesBin(selected_image_samples_bin) {
    return {
        type: 'SET_SELECTED_IMAGE_SAMPLES_BIN',
        selected_image_samples_bin: selected_image_samples_bin
    }
}

export function setSelectedTypeDatasRestaurant(selected_type_datas_restaurant) {
    return {
        type: 'SET_SELECTED_TYPE_DATAS_RESTAURANT',
        selected_type_datas_restaurant: selected_type_datas_restaurant
    }
}

export function setSelectedAnalysisMenus(selected_analysis_menus) {
    return {
        type: 'SET_SELECTED_ANALYSIS_MENUS',
        selected_analysis_menus: selected_analysis_menus
    }
}
export function setSelectedDateMenus(selected_date_menus) {
    return {
        type: 'SET_SELECTED_DATE_MENUS',
        selected_date_menus: selected_date_menus
    }
}
export function setSelectedServiceMenus(selected_service_menus) {
    return {
        type: 'SET_SELECTED_SERVICE_MENUS',
        selected_service_menus: selected_service_menus
    }
}
export function setMenusList(menus_list) {
    return {
        type: 'SET_MENUS_LIST',
        menus_list: menus_list
    }
}

export function setAllAnalyzes(all_analyzes) {
    return {
        type: 'SET_ALL_ANALYZES',
        all_analyzes: all_analyzes
    }
}

export function setAllObservations(all_observations) {
    return {
        type: 'SET_ALL_OBSERVATIONS',
        all_observations: all_observations
    }
}

export function setWasteManagementMethod(wasteManagementMethod) {
    return {
        type: 'SET_WASTE_MANAGEMENT_METHOD',
        wasteManagementMethod: wasteManagementMethod
    }
}

export function setFoodValues(food_values) {
    return {
        type: 'SET_FOOD_VALUES',
        food_values: food_values
    }
}
export function setLastSelectedDaySamplesBin(last_selected_day_samples_bin) {
    return {
        type: 'SET_LAST_SELECTED_DAY_SAMPLES_BIN',
        last_selected_day_samples_bin: last_selected_day_samples_bin
    }
}
export function setAllDatesSamplesBin(all_dates_samples_bin) {
    return {
        type: 'SET_ALL_DATES_SAMPLES_BIN',
        all_dates_samples_bin: all_dates_samples_bin
    }
}
export function hardResetDatas() {
    return {
        type: 'HARD_RESET_DATAS'
    }
}