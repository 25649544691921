import { Navigate } from "react-router-dom";
import { setSelectedClient } from "../actions_reducers/actions/admin.action";
import { setCurrentPage } from "../actions_reducers/actions/navigation.action";
import { setSelectedRestaurant } from "../actions_reducers/actions/user.action";
import applicationStore from "../store/store"; //store

const getRedirectionPage = async (props) => {
    if (props.user_type === 'admin') {
        applicationStore.dispatch(setCurrentPage('clientList'));
        props.navigate('/clientConsole')
    }
    else if (props.user_type === 'super-client') {
        applicationStore.dispatch(setSelectedClient(props.client_name))
        applicationStore.dispatch(setCurrentPage('clientDashboard'));
        props.navigate('/clientConsole')
    }
    else if (props.user_type === 'client') {
        applicationStore.dispatch(setSelectedClient(props.client_name))
        applicationStore.dispatch(setCurrentPage('clientDashboard'));
        props.navigate('/clientConsole')
    }
    else if (props.user_type === 'chef') {
        applicationStore.dispatch(setSelectedRestaurant(props.restaurant_name))
        applicationStore.dispatch(setCurrentPage('dashboard'));
        props.navigate('/clientConsole')
    }
}
const LoginRoute = ({ children }) => {
    return applicationStore.getState().userReducer.is_logged_in ? <Navigate to='/clientConsole' /> : children;
}


export { getRedirectionPage, LoginRoute };