const initialState = {
    selected_analysis: null,
    selected_cam: null,
    files_list: [],
    show_on_boarding: false,
    current_page_on_boarding: 1,
    on_boarding_done: false,
    analyzesSynthesis: [],
    modalDownloadOpen: null,
    selectedDocuments: ["synthesis", "sensiAdult"],
    hasKitchenValues: true,
    hasRestaurantValues: true,
    hasAssessment: false,
    selected_analyzes: [],
    loadingFiles: false,
    realTrays: []
}


const filesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_ANALYSIS_FILES':
            return {
                ...state,
                selected_analysis: action.selected_analysis
            }
        case 'SET_FILES_LIST':
            return {
                ...state,
                files_list: action.files_list
            }
        case 'SET_SELECTED_CAM_FILES':
            return {
                ...state,
                selected_cam: action.selected_cam
            }
        case 'SET_SHOW_ON_BOARDING':
            return {
                ...state,
                show_on_boarding: action.show_on_boarding
            }
        case 'SET_CURRENT_PAGE_ON_BOARDING':
            return {
                ...state,
                current_page_on_boarding: action.current_page_on_boarding
            }
        case 'SET_ON_BOARDING_DONE':
            return {
                ...state,
                on_boarding_done: action.on_boarding_done
            }
        case 'SET_ANALYZES_SYNTHESIS':
            return {
                ...state,
                analyzesSynthesis: action.analyzesSynthesis
            }
        case 'SET_SELECTED_DOCUMENTS':
            return {
                ...state,
                selectedDocuments: action.selectedDocuments
            }
        case 'SET_DOWNLOAD_MODAL_OPEN':
            return {
                ...state,
                downloadModalOpen: action.downloadModalOpen
            }
        case 'SET_HAS_KITCHEN_VALUES':
            return {
                ...state,
                hasKitchenValues: action.hasKitchenValues
            }
        case 'SET_HAS_RESTAURANT_VALUES':
            return {
                ...state,
                hasRestaurantValues: action.hasRestaurantValues
            }
        case 'SET_HAS_ASSESSMENT':
            return {
                ...state,
                hasAssessment: action.hasAssessment
            }
        case 'SET_SELECTED_ANALYZES_EXCEL_FILES':
            return {
                ...state,
                selected_analyzes: action.selected_analyzes
            }
        case "SET_LOADING_FILES":
            return {
                ...state,
                loadingFiles: action.loadingFiles
            }
        case "SET_REAL_TRAYS":
            return {
                ...state,
                realTrays: action.realTrays
            }
        case 'HARD_RESET_DATAS':
            return initialState;
        default:
            return state;
    }
}

export default filesReducer;