
import { useRef } from "react";
import { ReactComponent as ArrowDown } from "../../assets/arrowdown2.svg";
import { useState } from "react";
import { useEffect } from "react";
import CustomScrollDiv from "./CustomScrollBar";
const CustomDropdownCheckBoxes = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const dropdownRef = useRef(null);

    const changeDropdownOpen = () => setDropdownOpen(!dropdownOpen)
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && event.target.type != "checkbox") {
                setDropdownOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);
    return (
        <div
            ref={dropdownRef}
            className='custom-dropdown'
            style={{
                height: props.height,
                width: props.width,
                marginLeft: props.marginLeft || 0,
                marginRight: props.marginRight || 0,
                marginTop: props.marginTop || 0,
                marginBottom: props.marginBottom || 0,
                backgroundColor: props.backgroundColor || '#ffffff',
                color: props.color || '#ffffff',
                borderRadius: props.borderRadius && props.borderRadius,
                fontSize: props.fontSize || "1.1vw",
                zIndex: dropdownOpen ? 1000 : 0,
                border: props.border || "none"
            }}
            onClick={(e) => e.target.type !== "checkbox" && changeDropdownOpen()}>
            {props.displayedValue}
            <ArrowDown className='arrow-down-img' fill={props.arrowColor || "#666666"} style={{ width: props.arrowWidth && props.arrowWidth }} />
            {props.maxHeightDropdown ?
                < div className="custom-dropdown-choices"
                    style={{
                        display: dropdownOpen ? 'block' : 'none',
                        backgroundColor: props.backgroundColor || '#ffffff',
                        height: props.maxHeightDropdown
                    }}>
                    <CustomScrollDiv id={props.id + 'dropdown'} height={props.maxHeightDropdown}  update={Math.random()}>
                        {props.values && props.values.map((value) => {
                            return (
                                <div key={value} id={value} className="custom-dropdown-checkbox-choice-div" onClick={(e) => props.onChange(e)} value={value}>
                                    <label style={{ width: "100%", display: "flex", justifyContent: props.justifyContent || "center", paddingLeft: props.insidePaddingLeft }}>
                                        <input type="checkbox" className="custom-checkbox-dropdown" id={value} checked={props.selectedValues && props.selectedValues.indexOf(value) > -1} />
                                        {value}</label>
                                </div>
                            )
                        })
                        }
                    </CustomScrollDiv>

                </div>
                : < div className="custom-dropdown-choices"
                    style={{
                        display: dropdownOpen ? 'block' : 'none',
                        backgroundColor: props.backgroundColor || '#ffffff',
                    }}>
                    {props.values && props.values.map((value) => {
                        return (
                            <div key={value} id={value} className="custom-dropdown-checkbox-choice-div" onClick={(e) => props.onChange(e)} value={value}>
                                <label style={{ width: "100%", display: "flex", justifyContent: props.justifyContent || "center", paddingLeft: props.insidePaddingLeft }}>
                                    <input type="checkbox" className="custom-checkbox-dropdown" id={value} checked={props.selectedValues && props.selectedValues.indexOf(value) > -1} />
                                    {value}</label>
                            </div>
                        )
                    })
                    }

                </div>}
        </div >
    )
}

export default CustomDropdownCheckBoxes;