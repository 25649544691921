import axios from "axios";
import env from "react-dotenv";
import { disconnectUser } from "./disconnectError";
let back_url = `${env.REACT_APP_BACK_URL}/alerts`;

export function getAllDatesAlerts(restaurant_name) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getAllDatesAlerts", {
                params: { restaurant_name: restaurant_name },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function getAlerts(restaurant_name, date) {
    return new Promise((resolve, reject) => {
        axios
            .get(back_url + "/getAlerts", {
                params: { restaurant_name: restaurant_name, date: date },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

export function updateAlerts(alerts) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateAlerts", {
                params: { alerts: alerts },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}
export function updateAlert(alert) {
    return new Promise((resolve, reject) => {
        axios
            .put(back_url + "/updateAlert", {
                params: { alert: alert },
            })
            .then((response) => resolve(response.data))
            .catch((err) => {
                if (err.response.data === "Expired") {
                    window.alert(`Votre session a expiré, veuillez vous reconnecter.`)
                    disconnectUser();
                } else
                    reject('error');
            })
    });
}

