const initialState = {
    userId: null,
    massUnit: 'g',
    costUnit: '€',
    token: null,
    user_type: null,
    is_logged_in: false,
    has_loaded: false,
    login: null,
    restaurant_list: [],
    selected_restaurant: null,
    multiple_cams: false,
    cams: [{ camName: 'total', displayName: "Total" }],
    selected_cams: [{ camName: 'total', displayName: "Total" }],
    analysisDataType: ["total", "restaurant", "kitchen"],
    selectedAnalysisDataType: ["total", "restaurant", "kitchen"],
    hours: ["total", "noon", "evening"],
    selected_hours: ["total", "noon", "evening"],
    has_kitchen: false,
    has_restaurant: true,
    has_synthesis: true,
    has_noon: false,
    has_evening: false,
    has_survey: false,
    has_saturday: false,
    has_sunday: false,
    averageGuests: 1,
    clientAverageGuests: [],
    numberWeeks: 0,
    displayName: null,
    linkToSurvey: null,
    synthesisName: null,
    restaurant_type: null,
    isChatOpen: false,
    hasChangedPassword: true
}


const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_ID':
            return {
                ...state,
                userId: action.userId
            }
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.token
            };

        case 'SET_USER_TYPE':
            return {
                ...state,
                user_type: action.user_type
            };

        case 'SET_IS_LOGGED_IN':
            return {
                ...state,
                is_logged_in: action.is_logged_in
            }
        case 'SET_HAS_LOADED':
            return {
                ...state,
                has_loaded: action.has_loaded
            }
        case 'SET_RESTAURANT_LIST':
            return {
                ...state,
                restaurant_list: action.restaurant_list
            }
        case 'SET_HAS_NOON':
            return {
                ...state,
                has_noon: action.has_noon
            }
        case 'SET_HAS_EVENING':
            return {
                ...state,
                has_evening: action.has_evening
            }
        case 'SET_SELECTED_HOURS':
            return {
                ...state,
                selected_hours: action.selected_hours
            }
        case 'SET_MULTIPLE_CAMS':
            return {
                ...state,
                multiple_cams: action.multiple_cams
            }
        case 'SET_CAMS':
            return {
                ...state,
                cams: action.cams
            }
        case 'SET_SELECTED_CAMS': {
            return {
                ...state,
                selected_cams: action.selected_cams
            }
        }
        case 'SET_HAS_KITCHEN':
            return {
                ...state,
                has_kitchen: action.has_kitchen
            }
        case 'SET_SELECTED_RESTAURANT':
            return {
                ...state,
                selected_restaurant: action.selected_restaurant
            }
        case 'SET_SELECTED_ANALYSIS_DATA_TYPE':
            return {
                ...state,
                selectedAnalysisDataType: action.selectedAnalysisDataType
            }
        case 'SET_LOGIN':
            return {
                ...state,
                login: action.login
            }

        case 'SET_AVERAGE_GUESTS':
            return {
                ...state,
                averageGuests: action.averageGuests
            }
        case 'SET_HAS_SYNTHESIS':
            return {
                ...state,
                has_synthesis: action.has_synthesis,
            }
        case 'SET_HAS_RESTAURANT':
            return {
                ...state,
                has_restaurant: action.has_restaurant
            }
        case 'SET_CLIENT_AVERAGE_GUESTS':
            return {
                ...state,
                clientAverageGuests: action.clientAverageGuests
            }
        case 'SET_NUMBER_WEEKS':
            return {
                ...state,
                numberWeeks: action.numberWeeks
            }
        case 'SET_DISPLAY_NAME':
            return {
                ...state,
                displayName: action.displayName
            }
        case 'SET_HAS_SURVEY':
            return {
                ...state,
                has_survey: action.hasSurvey
            }
        case 'SET_LINK_TO_SURVEY':
            return {
                ...state,
                linkToSurvey: action.linkToSurvey
            }
        case 'SET_SYNTHESIS_NAME':
            return {
                ...state,
                synthesisName: action.synthesisName
            }
        case 'SET_HAS_SATURDAY':
            return {
                ...state,
                has_saturday: action.has_saturday
            }
        case 'SET_HAS_SUNDAY':
            return {
                ...state,
                has_saturday: action.has_sunday
            }
        case 'SET_MASS_UNIT':
            return {
                ...state,
                massUnit: action.massUnit
            }
        case 'SET_COST_UNIT':
            return {
                ...state,
                costUnit: action.costUnit
            }
        case 'SET_RESTAURANT_TYPE':
            return {
                ...state,
                restaurant_type: action.restaurant_type
            }
        case 'SET_IS_CHAT_OPEN':
            return {
                ...state,
                isChatOpen: action.isChatOpen
            }
        case 'SET_HAS_CHANGED_PASSWORD':
            return {
                ...state,
                hasChangedPassword: action.hasChangedPassword
            }
        case 'SOFT_RESET_USER':
            return {
                ...state,
                selected_hours: initialState.hours,
                selected_cams: state.cams,
                selectedAnalysisDataType: initialState.selectedAnalysisDataType,
                /**
                 * 
                 * other items to reset when changing arianne
                 * 
                 */
            }
        case 'MEDIUM_RESET_USER':
            return {
                ...state,
                selected_hours: initialState.hours,
                selected_cams: state.cams,
                selectedAnalysisDataType: initialState.selectedAnalysisDataType,
                has_kitchen: false,
                has_restaurant: false,
                has_synthesis: false,
                has_noon: false,
                has_evening: false,
                has_survey: false
            }
        case 'HARD_RESET_USER':
            return initialState;
        default:
            return state;
    }
}

export default userReducer;