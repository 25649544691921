import React, { useState } from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import CustomButton from "../../../customComponents/CustomButton";
import { useDispatch } from "react-redux";
import CustomTextArea from "../../../customComponents/CustomTextArea";
import { setAllObservations } from "../../../../actions_reducers/actions/datas.action";
import CustomScrollDiv from "../../../customComponents/CustomScrollBar";
import { ReactComponent as Cross } from "../../../../assets/cross.svg";
import { saveObservations } from "../../../../apiRoutes/observations.routes";

const Strategies = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [modify, setModify] = useState(false);

    const getObservationsForAnalysis = () => {
        let observations = JSON.parse(JSON.stringify(props.observations));
        let result = observations.find(a => a.analysisNumber === props.analysisNumber);
        return result;
    }

    const addRow = () => {
        let allObservations = JSON.parse(JSON.stringify(props.observations))
        let observations = allObservations.find(a => a.analysisNumber === props.analysisNumber);
        if (observations && observations.texts) {
            observations.texts.push({ observations: "", suggestions: "" });
            dispatch(setAllObservations(allObservations));
        }
        else {
            let newObservation = {
                analysisNumber: props.analysisNumber,
                restaurant_name: props.userState.selected_restaurant,
                texts: [{ observations: "", suggestions: "" }]
            };
            allObservations.push(newObservation);
            dispatch(setAllObservations(allObservations));
        }
    }

    const confirmChanges = async () => {
        setModify(false)
        let observations = getObservationsForAnalysis();
        await saveObservations(observations)
    }


    const handleChange = (e, index, type) => {
        let allObservations = JSON.parse(JSON.stringify(props.observations))
        let observations = allObservations.find(a => a.analysisNumber === props.analysisNumber);
        observations.texts[index][type] = e;
        dispatch(setAllObservations(allObservations))
    };

    const removeRow = (index) => {
        let allObservations = JSON.parse(JSON.stringify(props.observations))
        let observations = allObservations.find(a => a.analysisNumber === props.analysisNumber);
        observations.texts.splice(index, 1)
        dispatch(setAllObservations(allObservations))
    }
    const getRows = () => {
        let result = [];
        let observations = getObservationsForAnalysis();
        if (observations && observations.texts) {
            for (const [index, obs] of observations.texts.entries()) {
                if (modify)
                    result.push(
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", marginBottom: "2%" }}>
                            <div style={{ width: "48.75%" }}>
                                <CustomTextArea height={"30vh"} id={"mytextarea"} handleChange={(e) => handleChange(e, index, "observations")} value={obs.observations} width={"100%"} />
                            </div>
                            <div style={{ width: "2.5%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                {props.userState.user_type === "admin" && <Cross style={{ width: "80%", height: "5vh", fill: "#EA881F", cursor: "pointer" }} onClick={() => removeRow(index)} />}
                            </div>
                            <div style={{ width: "48.75%" }}>
                                <CustomTextArea height={"30vh"} id={"mytextarea"} handleChange={(e) => handleChange(e, index, "suggestions")} value={obs.suggestions} width={"100%"} />
                            </div>
                        </div>)
                else
                    result.push(
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", marginBottom: "2%" }}>
                            <div style={{ width: "48.75%", height: "auto", backgroundColor: "#D9D9D9", borderRadius: "10px", padding: ".8%", paddingLeft: "1.5%", paddingRight: "1.5%" }} dangerouslySetInnerHTML={{ __html: obs.observations }} />
                            <div style={{ width: "2.5%", display: "flex", justifyContent: "center", alignItems: "center" }} >
                                {props.userState.user_type === "admin" && <Cross style={{ width: "80%", height: "5vh", fill: "#EA881F", cursor: "pointer" }} onClick={() => removeRow(index)} />}
                            </div>
                            <div style={{ width: "48.75%", height: "auto", backgroundColor: "#D9D9D9", borderRadius: "10px", padding: ".8%", paddingLeft: "1.5%", paddingRight: "1.5%" }} dangerouslySetInnerHTML={{ __html: obs.suggestions }} />
                        </div>)
            }
        }
        return result
    }

    return (
        <CustomBlock width={'100%'} height={'100%'}>
            <div style={{ width: '100%', height: '100%', paddingRight: "2%", paddingLeft: "1%", paddingTop: "1%" }}>
                <div className="dashboard-block-title">
                    {t('components.strategie')}
                </div>
                <div className="dashboard-graph-body" style={{ height: props.userState.user_type === "admin" ? "85%" : "95%" }}>
                    <div style={{ height: "12%", width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{
                            width: "48.75%", height: "100%", justifyContent: "flex-start", alignItems: "center", display: "flex"
                            , fontSize: "1.2vw", color: "#90c261", paddingLeft: ".5%"
                        }}>
                            {t('components.observations')}
                        </div>
                        <div style={{ width: "2.5%" }} />
                        <div style={{
                            width: "48.75%", height: "100%", justifyContent: "flex-start", alignItems: "center", display: "flex"
                            , fontSize: "1.2vw", color: "#68B4E3", paddingLeft: ".5%"
                        }}>
                            {t('components.suggestions')}
                        </div>

                    </div>
                    <div style={{ height: "88%" }}>
                        <CustomScrollDiv update={2}>
                            {getRows()}
                        </CustomScrollDiv>
                    </div>
                </div>
                {
                    props.userState.user_type === "admin" &&
                    <div style={{ height: "10%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <CustomButton width={"15%"} height={"70%"} backgroundColor={"#68B4E3"} color={"#fff"} onClick={() => addRow()}>{t('buttons.addRow')}</CustomButton>
                        <div style={{ width: "3%" }} />
                        {!modify ?
                            <CustomButton width={"15%"} height={"70%"} backgroundColor={"#72B9A2"} color={"#fff"} onClick={() => setModify(true)}>{t('buttons.modify')}</CustomButton>
                            :
                            <CustomButton width={"15%"} height={"70%"} backgroundColor={"#72B9A2"} color={"#fff"} onClick={() => confirmChanges()}>{t('buttons.save')}</CustomButton>}
                    </div>
                }
            </div>
        </CustomBlock>
    )
}
export default Strategies;