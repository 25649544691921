import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/ja';
import translationEN from './en/translation.json';
import translationFR from './fr/translation.json';
import 'moment/locale/fr';
import applicationStore from '../store/store';
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);
/*₂*/
export const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
}

const formatNumber = (num) => {
  if (num)
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
  else return num
};
const massConversion = (value) => {
  let unit = value.unit;
  let mass = value.mass;
  let massUnit = applicationStore.getState().userReducer.massUnit || "g";
  let fixed = value.fixed ? value.fixed : 0;
  if (massUnit === "g") {
    if (unit === 'g') {
      return parseFloat(mass).toFixed(value.fixed != null ? value.fixed : 1) + ' g';
    }
    else if (unit === "grammes")
      return parseFloat(mass).toFixed(value.fixed != null ? value.fixed : 1) + ' grammes';
    else if (unit === 'kg') {
      if (Math.abs(mass) / 1000000 > 10)
        return parseFloat(mass / 1000000).toFixed(2) + ' T';
      else
        return parseFloat(mass / 1000).toFixed(fixed) + ' kg';
    }
    else if (unit === 'T') {
      return parseFloat(mass / 1000000).toFixed(2) + ' T';
    }
    else if (unit === 'kg CO2') return (mass / 453.6).toFixed(fixed) + ' kg CO₂ eq.';
  }
  else if (massUnit === "lbs") {
    if (unit === 'g') return (mass / 28.35).toFixed(2) + ' oz';
    else if (unit === 'kg') {
      if (Math.abs(mass) / 453.6 > 10000)
        return parseFloat(mass / 6350.29).toFixed(2) + ' st';
      else
        return parseFloat(mass / 453.6).toFixed(fixed) + ' lbs';
    }
    else if (unit === 'T') {
      return parseFloat(mass / 6350.29).toFixed(fixed) + ' st';
    }

    else if (unit === 'kg CO2') return (mass / 453.6).toFixed(fixed) + ' lbs CO₂ eq.';
  }
}

const getCurrency = () => {
  if (applicationStore.getState().userReducer.costUnit === '$') return 'USD';
  else if (applicationStore.getState().userReducer.costUnit === '€') return 'EUR';
  else return 'EUR';
}

function formatCurrency(value, lng) {
  let valueReturn = value.cost;
  if (parseInt(value.cost) > 1000000) valueReturn = (value.cost / 1000000).toFixed(3)
  let test = Intl.NumberFormat(lng, {
    style: 'currency',
    currency: getCurrency(),
    minimumFractionDigits: parseInt(value.cost) > 1000000 ? 3 : value.fixed ? value.fixed : 0, // set fraction digits to 0 to remove cents
    maximumFractionDigits: parseInt(value.cost) > 1000000 ? 3 : value.fixed ? value.fixed : 0,
  }).format(valueReturn);
  if (value.cost > 1000000) {
    if (lng === "en")
      test = test.replace(`€`, `€ `)
    test = test.replace(`€`, `M€`)
    return test;
  }
  else {
    if (lng === "en")
      test = test.replace(`€`, `€ `)
    return test;
  }
}
function formatDate(value, lng) {
  moment.locale(lng)
  if (lng === 'fr') return moment(value.date).format(value.format || 'DD/MM/YYYY');
  else if (lng === 'en') {
    if (value.format === "DD/MM/YYYY HH:mm") return moment(value.date).format("YYYY/MM/DD HH:mm")
    else
      return moment(value.date).format(value.format || 'YYYY-MM-DD');
  }
}
const formatDateGraph = (value, lng) => {
  if (lng === 'fr') return moment(value, 'YYYY-MM-DD').format(value.format || 'DD-MM-YYYY')
  else if (lng === 'en') return moment(value, 'YYYY-MM-DD').format(value.format || 'YYYY-MM-DD')
}
i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: applicationStore.getState().navigationReducer.language,
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
    format: function (value, format, lng) {
      switch (format) {
        case 'currency':
          return formatCurrency(value, lng);
        case 'mass':
          return massConversion(value);
        case 'date':
          return formatDate(value, lng);
        case 'dateGraph':
          return formatDateGraph(value, lng);
        default:
          return value;
      }
    },
  },
})