import React, { useState } from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { hasMultipleCams, hasMultipleHours } from "../../customFunctions/user.function";
import CustomDropdownCheckBoxes from "../../customComponents/CustomDropdownCheckBoxes";
import CustomDropdown from "../../customComponents/CustomDropdown";
import { setSelectedAnalysisDataType, setSelectedCams, setSelectedHours } from "../../../actions_reducers/actions/user.action";
import { getDatas } from "../../customFunctions/data.function";
import { setExtrapolationValue } from "../../../actions_reducers/actions/dashboard.action";
import { extrapolateAnalyzesValues } from "../../customFunctions/extrapolation.function";
import { setExtrapolatedAnalyzes, setKitchenExtrapolatedAnalyzes } from "../../../actions_reducers/actions/datas.action";

const DataSelection = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [dropdownCamsOpen, setDropdownCamsOpen] = useState(false);
    const [dropdownHoursOpen, setDropdownHoursOpen] = useState(false);
    const [dropdownKitchenOpen, setDropdownKitchenOpen] = useState(false);

    const changeHourOpen = () => setDropdownHoursOpen(!dropdownHoursOpen);
    const changeCamOpen = () => setDropdownCamsOpen(!dropdownCamsOpen);
    const changeKitchenOpen = () => setDropdownKitchenOpen(!dropdownKitchenOpen);

    const getDisplayedExtrapValue = () => {
        switch (t(`extrapButton.${props.dashboardState.extrapolationValue}`)) {
            case (t('extrapButton.day')):
                return t('extrapButton.dayExtrapSmall');
            case (t('extrapButton.week')):
                return t('extrapButton.weekExtrapSmall');
            case (t('extrapButton.month')):
                return t('extrapButton.monthExtrapSmall');
            case (t('extrapButton.year')):
                return t('extrapButton.yearExtrapSmall');
            case (t('extrapButton.analysis')):
                return t('extrapButton.analysisExtrapSmall');
            default: return t('extrapButton.analysisExtrapSmall')
        }
    }
    const getExtrapValue = (value) => {
        switch (value) {
            case (t('extrapButton.day')):
                return 'day';
            case (t('extrapButton.week')):
                return 'week';
            case (t('extrapButton.month')):
                return 'month';
            case (t('extrapButton.year')):
                return 'year';
            case t('extrapButton.analysis'):
                return 'analysis'
            default: return 'analysis';
        }
    }
    const values = [t('extrapButton.analysis'), t('extrapButton.day'), t('extrapButton.week'), t('extrapButton.month'), t('extrapButton.year')];
    const updateValue = (e) => {
        let analyzes = JSON.parse(JSON.stringify(props.analyzesDatas))
        analyzes = extrapolateAnalyzesValues(analyzes, getExtrapValue(e.target.id), 'restaurant', props.userState.averageGuests);
        if (props.userState.has_kitchen) {
            let kitchenAnalyzes = JSON.parse(JSON.stringify(props.kitchenAnalyzesDatas))
            kitchenAnalyzes = extrapolateAnalyzesValues(kitchenAnalyzes, getExtrapValue(e.target.id), 'restaurant', props.userState.averageGuests);
            dispatch(setKitchenExtrapolatedAnalyzes(kitchenAnalyzes));
        }
        dispatch(setExtrapolatedAnalyzes(analyzes));
        dispatch(setExtrapolationValue(getExtrapValue(e.target.id)))
    }
    const getExtrapButton = () => {
        return (
            <CustomDropdown
                width={'100%'}
                height={'100%'}
                backgroundColor={'#949494'}
                displayedValue={t(`extrapButton.extrapolation`)}
                selectedValue={t(`extrapButton.${props.dashboardState.extrapolationValue}`)}
                values={values}
                showSelected={true}
                select={true}
                onChange={updateValue}
                arrowColor={"#D9D9D9"}
                arrowWidth={"10%"}
                fontSize={"0.9vw"}
            />
        )
    }

    const changeCam = (e) => {
        let cam = props.userState.cams.find(a => a.displayName === e.target.id);
        if (cam.camName === "total") {
            if (props.userState.selected_cams.find(a => a.camName === "total"))
                dispatch(setSelectedCams([]));
            else dispatch(setSelectedCams(props.userState.cams));
            getDatas(props.analyzesBaseDatas, props.userState.selected_hours, props.userState.cams, props.dashboardState, dispatch, props.userState.averageGuests);
        }
        else {
            let camsFinal = JSON.parse(JSON.stringify(props.userState.selected_cams));
            let camIndex = camsFinal.findIndex(a => a.displayName === cam.displayName);
            if (camIndex === -1) {
                camsFinal.push(cam);
                if (camsFinal.length === props.userState.cams.length - 1) camsFinal.push({ camName: "total", displayName: "Total" });
            }
            else {
                camsFinal.splice(camIndex, 1);
                let totIndex = camsFinal.findIndex(a => a.camName === 'total');
                camsFinal.splice(totIndex, 1);
            }
            dispatch(setSelectedCams(camsFinal));
            getDatas(props.analyzesBaseDatas, props.userState.selected_hours, camsFinal, props.dashboardState, dispatch, props.userState.averageGuests);
        }
    }
    const getCamsElement = () => {
        let values = props.userState.cams.map(item => item.displayName);
        let selectedCams = props.userState.selected_cams.map(item => item.displayName);
        if (hasMultipleCams(props.userState)) {
            return (
                <CustomDropdownCheckBoxes
                    zIndex={dropdownCamsOpen ? 0 : 2000}
                    width={'80%'}
                    height={'15%'}
                    backgroundColor={'#fff'}
                    color={"#666666"}
                    border={"1px solid #666666"}
                    arrowColor={"#D9D9D9"}
                    arrowWidth={"10%"}
                    displayedValue={t(`components.cams`)}
                    selectedValues={selectedCams}
                    values={values}
                    dropdownUpdate={22}
                    fontSize={"0.9vw"}
                    onChange={changeCam}
                    isOpen={dropdownCamsOpen}
                    onClick={changeCamOpen}
                />)
        }
        else {
            return (
                <CustomBlock
                    width={'100%'}
                    height={'100%'}
                    backgroundColor={'#949494'}
                    border={"none"}
                    boxShadow={"none"}
                >
                    <div className="data-selection-single-block" > {t('components.firstCam')}</div>
                </CustomBlock>
            )
        }
    }
    const getHourValue = (hour) => {
        if (hour === "midi") return "noon";
        else if (hour === "soir") return "evening";
        else return "total";
    }
    const changeHour = (e) => {
        let hour = getHourValue(e.target.id.toLowerCase());
        if (hour === "total") {
            if (props.userState.selected_hours.find(a => a === "total"))
                dispatch(setSelectedHours([]));
            else dispatch(setSelectedHours(props.userState.hours));
            getDatas(props.analyzesBaseDatas, props.userState.hours, props.userState.selected_cams, props.dashboardState, dispatch, props.userState.averageGuests);
        }
        else {
            let hoursFinal = JSON.parse(JSON.stringify(props.userState.selected_hours));
            let hourIndex = hoursFinal.findIndex(a => a === hour);
            if (hourIndex === -1) {
                hoursFinal.push(hour);
                if (hoursFinal.length === props.userState.hours.length - 1) hoursFinal.push("total");
            }
            else {
                hoursFinal.splice(hourIndex, 1);
                let totIndex = hoursFinal.findIndex(a => a === 'total');
                hoursFinal.splice(totIndex, 1);
            }
            dispatch(setSelectedHours(hoursFinal));
            getDatas(props.analyzesBaseDatas, hoursFinal, props.userState.selected_cams, props.dashboardState, dispatch, props.userState.averageGuests);
        }
    }
    const getHoursElement = () => {
        let values = props.userState.hours.map(item => t(`hours.${item}`));
        let selectedHours = props.userState.selected_hours.map(item => t(`hours.${item}`));
        if (hasMultipleHours(props.userState)) {
            return (
                <CustomDropdownCheckBoxes
                    zIndex={dropdownCamsOpen ? 0 : 1001}
                    width={'100%'}
                    height={'100%'}
                    backgroundColor={'#949494'}
                    color={"#fff"}
                    arrowColor={"#D9D9D9"}
                    arrowWidth={"10%"}
                    displayedValue={t(`components.hours`)}
                    selectedValues={selectedHours}
                    values={values}
                    dropdownUpdate={22}
                    fontSize={"0.9vw"}
                    onChange={changeHour}
                    isOpen={dropdownHoursOpen}
                    onClick={changeHourOpen}
                />)
        }
        else {
            return (
                <CustomBlock
                    width={'100%'}
                    height={'100%'}
                    backgroundColor={'transparent'}
                    border={"1px solid #666666"}
                    boxShadow={"none"}
                    fontSize={"0.9vw"}
                >
                    <div className="data-selection-single-block" style={{ fontSize: "0.9vw", color: "#666666" }} > {t('components.noonService')}</div>
                </CustomBlock>
            )
        }
    }
    const getDataTypeName = (value) => {
        if (value === t('components.kitchen')) return "kitchen";
        else if (value === t('components.restaurant')) return "restaurant";
        else if (value === t('components.total')) return "total"
    }

    const getAllPossibleValues = () => {
        let allValues = [];
        let valuesRoom = props.userState.analysisDataType.map(item => t(`components.${item}`));
        let valuesCams = props.userState.cams.map(item => item.displayName);
        if (props.userState.has_kitchen && props.userState.has_restaurant)
            allValues = allValues.concat(valuesRoom)
        if (hasMultipleCams(props.userState)) {
            for (const value of valuesCams) {
                if (value !== "Total" || !allValues.find(a => a === "Total"))
                    allValues.push(value)
            }
        }
        return allValues
    }

    const changeDataType = (e) => {
        let dataTypes = props.userState.selectedAnalysisDataType;
        let camsFinal = JSON.parse(JSON.stringify(props.userState.selected_cams));
        let dataTypeFinal = JSON.parse(JSON.stringify(dataTypes));
        let value = getDataTypeName(e.target.id);
        let cam = props.userState.cams.find(a => a.displayName === e.target.id);
        if (value === "total") {
            if (dataTypes.find(a => a === "total")) {
                dispatch(setSelectedAnalysisDataType([]));
                dispatch(setSelectedCams([]));
            }
            else {
                dispatch(setSelectedAnalysisDataType(props.userState.analysisDataType));
                dispatch(setSelectedCams(props.userState.cams));
            }
        }
        else {
            if (value) {
                let dataTypeIndex = dataTypeFinal.findIndex(a => a === value);
                if (dataTypeIndex === -1) {
                    dataTypeFinal.push(value);
                    if (dataTypeFinal.length === props.userState.analysisDataType.length - 1 && props.userState.selected_cams.length === props.userState.cams.length - 1) dataTypeFinal.push("total");
                }
                else {
                    dataTypeFinal.splice(dataTypeIndex, 1);
                    let totIndex = dataTypeFinal.findIndex(a => a === 'total');
                    dataTypeFinal.splice(totIndex, 1);
                    let totIndexCams = camsFinal.findIndex(a => a.camName === 'total');
                    if (totIndexCams !== -1)
                        camsFinal.splice(totIndexCams, 1)
                }
                dispatch(setSelectedAnalysisDataType(dataTypeFinal));
                dispatch(setSelectedCams(camsFinal));
            }
            else {
                if (cam) {
                    let camIndex = camsFinal.findIndex(a => a.displayName === cam.displayName);
                    if (camIndex === -1) {
                        camsFinal.push(cam);
                        if (camsFinal.length === props.userState.cams.length - 1 &&
                            dataTypes.length == props.userState.analysisDataType.length - 1)
                            camsFinal.push({ camName: "total", displayName: "Total" });
                    }
                    else {
                        camsFinal.splice(camIndex, 1);
                        let totIndex = camsFinal.findIndex(a => a.camName === 'total');
                        camsFinal.splice(totIndex, 1);
                        let totIndexDataType = dataTypeFinal.findIndex(a => a === 'total');
                        if (totIndexDataType !== -1)
                            dataTypeFinal.splice(totIndexDataType, 1);
                    }
                    dispatch(setSelectedCams(camsFinal));
                    dispatch(setSelectedAnalysisDataType(dataTypeFinal));
                    getDatas(props.analyzesBaseDatas, props.userState.selected_hours, camsFinal, props.dashboardState, dispatch, props.userState.averageGuests);
                }
            }

        }
    }
    const getTypesForAnalysis = () => {
        if (props.hasRestaurantAnalysis && props.hasKitchenAnalysis)
            return "all";
        else if (props.hasRestaurantAnalysis && !props.hasKitchenAnalysis)
            return "restaurant";
        else if (!props.hasRestaurantAnalysis && props.hasKitchenAnalysis)
            return "kitchen"
    }
    const getProvenanceElement = () => {
        let values = getAllPossibleValues();
        let selectedValues = [];
        if (props.userState.has_kitchen && props.userState.has_restaurant) {
            selectedValues = selectedValues.concat(props.userState.selectedAnalysisDataType.map(item => t(`components.${item}`)))
        }
        if (hasMultipleCams(props.userState)) {
            selectedValues = selectedValues.concat(props.userState.selected_cams.map(item => item.displayName))
        }
        if ((props.userState.has_kitchen && props.userState.has_restaurant) || hasMultipleCams(props.userState))
            return (
                <CustomDropdownCheckBoxes
                    zIndex={dropdownKitchenOpen ? 0 : 1000}
                    width={'100%'}
                    height={'100%'}
                    backgroundColor={'#949494'}
                    color={"#fff"}
                    arrowColor={"#D9D9D9"}
                    arrowWidth={"10%"}
                    displayedValue={t(`components.origin`)}
                    selectedValues={selectedValues}
                    values={values}
                    dropdownUpdate={22}
                    fontSize={"0.9vw"}
                    onChange={changeDataType}
                    isOpen={dropdownKitchenOpen}
                    onClick={changeKitchenOpen}
                />)
        else {
            return (
                <CustomBlock
                    width={'100%'}
                    height={'100%'}
                    backgroundColor={'transparent'}
                    border={"1px solid #666666"}
                    fontSize={"0.9vw"}
                    boxShadow={"none"}
                >
                    <div className="data-selection-single-block" style={{ fontSize: "0.9vw", color: "#666666" }} >
                        {getTypesForAnalysis() === "restaurant" ?
                            t('dataTypes.restaurant')
                            : t('dataTypes.kitchen')}
                    </div>
                </CustomBlock>
            )
        }
    }


    return (
        <div style={{ width: '100%', height: '100%', display: "flex" }}>
            <div style={{ width: "30%" }}>
                {getProvenanceElement()}
            </div>
            <div style={{ width: "5%" }} />
            <div style={{ width: "30%" }}>
                {getHoursElement()}
            </div>
            <div style={{ width: "5%" }} />
            <div style={{ width: "30%" }}>
                {getExtrapButton()}
            </div>
        </div>
    )
}
export default DataSelection;