import React from "react";
import RestaurantsList from "./RestaurantsList";
import ClientDataType from "./ClientDataType";

const separationHeight = "6vh";
const ClientDetails = (props) => {
    return (
        <div className="main-page-main-div">
            <div style={{ display: "flex", flexDirection: "row", height: "20vh", width: "100%" }}>
                <ClientDataType
                    selectedDataType={props.dataTypeClient}
                    clientAnalyzes={props.clientAnalyzes}
                    clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                    userState={props.userState} />
            </div>
            <div style={{ height: separationHeight }} />
            <div style={{ height: "85.8vh", width: "100%" }}>
                <RestaurantsList
                    displayNames={props.displayNames}
                    clientAnalyzes={props.clientAnalyzes}
                    selectedAnalysisNumber={props.selectedAnalysisNumber}
                    clientAnalyzesDates={props.clientAnalyzesDates}
                    selectedDataType={props.dataTypeClient}
                    arianne={props.arianne}
                    clientKitchenAnalyzes={props.clientKitchenAnalyzes}
                    shownType={props.restaurantListDataType} />
            </div>
        </div >
    )
}

export default ClientDetails;
